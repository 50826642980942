import * as Sentry from '@sentry/react'
import { myEnergySelectors } from 'ducks/myEnergy'
import { viewAsCustomerSelectors } from 'ducks/viewAsCustomer'
import useCheckoutActionCallback from 'myenergy/checkout/useCheckoutActionCallback'
import { getSdk_v1, OSSDK } from 'opensolar-sdk'
import useSendProposalButton, {
  SendProposalResponseType,
} from 'projectSections/sections/proposal/useSendProposalButton'
import useViewAsCustomer from 'projectSections/sections/proposal/useViewAsCustomer'
import { identifierAsId, useDataProvider, useNotify } from 'ra-core'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import { getProposalV2Settings } from 'resources/proposalTemplates/ProposalV2FromContent'
import type { ProposalDataType } from 'types/proposals'
import type { ProposalTemplateSettingsTypeV2 } from 'types/proposalTemplate'
import type { RootState } from 'types/state'

type ProposalDataTypeV2 = ProposalDataType & {
  proposalTemplateSettings: ProposalTemplateSettingsTypeV2
}

type SdkProposalType = {
  proposalData: ProposalDataTypeV2
  onPaymentMethodChange(id: number): void
  onSystemChange(uuid: string): void

  isViewAsCustomer: boolean
  sendButtonOptions?: Omit<SendProposalResponseType, 'onSend'>
  // Actions only available when viewed as customer
  setIsViewAsCustomer?: (value: boolean) => void
  onSendProposal?: () => void
  onSaveTemplateSettings?: (newTemplateSettings: string) => Promise<any>
}

const useSaveTemplateSettings = ({ form }) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()

  return useCallback(
    async (rawTemplateSettings: string) => {
      // Do nothing if not inside project form
      if (!form) return
      const proposalTemplateUrl = form.getState().values.proposal_template

      if (!proposalTemplateUrl) return

      const proposalTemplateId = identifierAsId(proposalTemplateUrl)
      const result = await dataProvider.CUSTOM_PATCH(
        'proposal_templates',
        {
          url: `proposal_templates/${proposalTemplateId}/`,
          data: { settings: JSON.stringify(getProposalV2Settings(rawTemplateSettings)) },
          // previousData should be optional, restClient never handles previousData
          previousData: { id: proposalTemplateId },
        },
        {
          onSuccess: () => {
            notify('Design Saved', 'success')
          },
          onFailure: () => {
            notify('Something went wrong"', 'warning')
            return
          },
        }
      )
      return result
    },
    [dataProvider, form]
  )
}

const useBindProposal = ({
  sdk,
  proposalData,
  setIsViewAsCustomer,
  onPaymentMethodChange,
  sendButtonOptions,
  onSendProposal,
  onSystemChange,
  onSaveTemplateSettings,
  viewAdded,
  isViewAsCustomer,
}: SdkProposalType & { sdk: OSSDK; viewAdded: boolean }) => {
  const notify = useNotify()
  const translate = useTranslate()
  const onCheckoutElementAction = useCheckoutActionCallback()
  const transactionState = useSelector((state: RootState) => state.transaction)
  const CheckoutElementsState = useSelector(myEnergySelectors.getCheckoutElements)

  useEffect(() => {
    if (!viewAdded) return
    sdk.proposal.data.value = proposalData
  }, [proposalData, viewAdded])

  useEffect(() => {
    if (!viewAdded) return
    sdk.proposal.checkoutElements.value = CheckoutElementsState
  }, [CheckoutElementsState, viewAdded])

  useEffect(() => {
    if (!viewAdded) return
    sdk.proposal.transaction.value = transactionState
  }, [transactionState, viewAdded])

  useEffect(() => {
    if (!viewAdded) return
    if (!proposalData.proposalTemplateSettings.proposal_v2_config?.template_settings) return

    try {
      sdk.proposal.templateSettings.value = JSON.parse(
        proposalData.proposalTemplateSettings.proposal_v2_config.template_settings
      )
    } catch (e) {
      sdk.proposal.templateSettings.value = {}
      Sentry.captureException(`Error: Failed to parse template_settings string: ${e}`)
      notify(translate('Failed to load proposal. Please try again later.'), 'error')
    }
  }, [proposalData, viewAdded]) // important to listen to proposalData, until we fix the sdk issue

  useEffect(() => {
    if (!viewAdded) return
    sdk.proposal.isViewAsCustomer.value = isViewAsCustomer
  }, [isViewAsCustomer, viewAdded])

  useEffect(() => {
    if (!viewAdded) return
    sdk.proposal.sendButtonOptions.value = sendButtonOptions || {}
  }, [JSON.stringify(sendButtonOptions), viewAdded])

  // TODO: clear bindings
  sdk.proposal.data.own()
  sdk.proposal.checkoutElements.own()
  sdk.proposal.transaction.own()
  sdk.proposal.templateSettings.own()
  sdk.proposal.isViewAsCustomer.own()
  sdk.proposal.sendButtonOptions.own()
  sdk.proposal.setIsViewAsCustomer.own(async (value) => setIsViewAsCustomer?.(value))
  sdk.proposal.onCheckoutElementAction.own(onCheckoutElementAction)
  sdk.proposal.onSaveTemplateSettings.own(async (args) => onSaveTemplateSettings?.(args))
  sdk.proposal.onSendProposal.own(async () => onSendProposal?.())
  sdk.proposal.onSystemChange.own(async (uuid: string) => onSystemChange(uuid))
  sdk.proposal.onPaymentMethodChange.own(async (id: number) => onPaymentMethodChange(id))
}

const useSdkWithProposalV2 = ({
  proposalData,
  setIsViewAsCustomer,
  onPaymentMethodChange,
  onSystemChange,
  isViewAsCustomer = false,
  ...props
}: SdkProposalType & { viewAdded: boolean }) => {
  const sdk = getSdk_v1(true, false) // TODO: fix me, invalid usage
  useBindProposal({
    sdk,
    proposalData,
    setIsViewAsCustomer,
    onPaymentMethodChange,
    onSystemChange,
    isViewAsCustomer,
    ...props,
  })
}

const PublicProposalV2 = (props: SdkProposalType) => {
  const [viewAdded, setViewAdded] = useState(false)
  const iframeRef = useRef<HTMLIFrameElement | null>(null)

  const viewUrl = useMemo(() => {
    return props.proposalData?.proposalTemplateSettings.proposal_v2_config?.view_config.url
  }, [props.proposalData?.proposalTemplateSettings.proposal_v2_config?.view_config.url])

  console.log(props.proposalData)
  useSdkWithProposalV2({
    ...props,
    viewAdded,
  })
  const sdk = useMemo<OSSDK>(() => getSdk_v1(true, true), [])
  useEffect(() => {
    if (sdk !== undefined && iframeRef.current && viewAdded === false) {
      sdk.addIframeView({ iframe: iframeRef.current })
      setViewAdded(true)
    }
  }, [sdk, viewAdded])

  if (!viewUrl) return null

  return (
    <iframe
      ref={iframeRef}
      style={{
        border: 0,
        width: '100%',
        height: '100%',
        position: 'absolute',
        pointerEvents: 'all',
        backgroundColor: 'white',
        inset: 0,
      }}
      src={viewUrl}
    />
  )
}

export const SEND_ERROR_MESSAGE = 'This project has unsaved changes. Save your project to enable proposal sending.'

const OnlineProposalV2 = (props: SdkProposalType) => {
  // Add some form logic to the public-facing proposal, to allow edits to proposal
  const form = useForm()
  const translate = useTranslate()
  const onSaveTemplateSettings = useSaveTemplateSettings({ form })
  const { onSend, ...sendButtonOptions } = useSendProposalButton({
    unsavedChangesErrorMessage: translate(SEND_ERROR_MESSAGE),
  })

  const isViewAsCustomer = useSelector(viewAsCustomerSelectors.getViewAsCustomer)
  const { toggleViewAsCustomer } = useViewAsCustomer()

  return (
    <PublicProposalV2
      {...props}
      sendButtonOptions={sendButtonOptions}
      onSendProposal={onSend}
      onSaveTemplateSettings={onSaveTemplateSettings}
      isViewAsCustomer={isViewAsCustomer}
      setIsViewAsCustomer={toggleViewAsCustomer}
    />
  )
}

const ProposalV2 = (
  props: SdkProposalType & {
    isNestedInProjectForm: boolean
  }
) => (props.isNestedInProjectForm ? <OnlineProposalV2 {...props} /> : <PublicProposalV2 {...props} />)

export default ProposalV2
