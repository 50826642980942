import { ControlProps, isDateControl, rankWith } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import { format as formatDate } from 'date-fns'
import { DateInput } from 'elements/input/DateInput'
import * as React from 'react'

const tester = rankWith(100, isDateControl)

const Renderer: React.FC<ControlProps> = (props) => {
  const { data, handleChange, path, label, visible, enabled, uischema, required } = props

  const format = uischema?.options?.format || 'MM/dd/yyyy'

  return (
    <DateInput
      openTo="year"
      value={data || null}
      format={format}
      onYearChange={() => {}}
      label={label}
      onChange={(date) => {
        handleChange(path, date ? formatDate(date, format) : null)
      }}
      autoOk
      disableToolbar
      fullWidth
      disabled={!enabled}
      required={required}
      style={{ display: visible ? 'block' : 'none' }}
    />
  )
}

const renderer = withJsonFormsControlProps(Renderer)

export default {
  tester,
  renderer,
}
