import { styled } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import LoadingDots from 'layout/widgets/LoadingDots'
import { useGetConvertToCashFlowSetting } from 'pages/cashFlow/configuration/paymentMilestones/convertExistingProjects/utils'
import { useNotify, useTranslate } from 'ra-core'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import { setBool } from 'storage/appStorage'
import { reloadEntireApp } from 'util/misc'

const restClientInstance = restClient(window.API_ROOT + '/api')

type PropTypes = {
  projectId: number
}

const LoaderWrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 101,
  opacity: 0.9,
}))

const CashFlowConverter: React.FC<PropTypes> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const isPro = !!useSelector(authSelectors.getCurrentRole)
  const orgId = useSelector(orgSelectors.getOrg)?.id

  const conversionSetting = useGetConvertToCashFlowSetting(orgId)

  const notify = useNotify()
  const translate = useTranslate()

  useEffect(() => {
    if (conversionSetting?.settings?.enabled && props.projectId) {
      setBool(`cashflowConversionAttempted-${props.projectId}`, true)
      doConversion()
    }
  }, [conversionSetting?.settings?.enabled, props.projectId])

  const doConversion = () => {
    setIsLoading(true)
    restClientInstance('CUSTOM_POST', 'custom', {
      url: `orgs/${orgId}/projects/${props.projectId}/convert_to_cashflow/`,
    })
      .then((res) => {
        logAmplitudeEvent('cashflow_legacy_project_conversion_success', { projectId: props.projectId, orgId })
        reloadEntireApp()
      })
      .catch((err) => {
        notify(translate('Unable to convert project to cashflow'), 'warning')
        logAmplitudeEvent('cashflow_legacy_project_conversion_failure', { projectId: props.projectId, orgId })
        setIsLoading(false)
      })
  }

  const message = useMemo(() => {
    if (isPro) return 'One moment while we add CashFlow functionality to this project. This may take up to 30 seconds.'
    else return 'One moment while we ensure your project is up-to-date. This may take up to 30 seconds.'
  }, [isPro])

  if (isLoading) {
    return (
      <LoaderWrapper>
        <LoadingDots text={message} />
      </LoaderWrapper>
    )
  }
  return null
}
export default CashFlowConverter
