import { myEnergySelectors } from 'ducks/myEnergy'
import { useState } from 'react'
import { useField, useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import { detectProposalV2ProposalTemplate, getRoleFromState } from 'util/misc'
import SectionContainer from '../../SectionContainer'
import ProposalDrawer from '../ProposalDrawer'
import { Preview } from './Preview'
import { PreviewNotAvailable } from './PreviewNotAvailable'

export default () => {
  const form = useForm()
  const [refreshCount, refresh] = useState(1)
  const [requestedPoll, setRequestedPoll] = useState()
  const isPro = useSelector((state) => Boolean(getRoleFromState(state)))
  const proposalData = useSelector(myEnergySelectors.getProposalData)
  // FIXME: isProposalV2 and ProposalData will always return undefined, support for ProposalV2 has yet to be added to the PDF Proposal tab
  // This will need to be fixed to hide ProposalDrawer and support the ProposalV2ActionBar.tsx.
  const { isDetected: isProposalV2 } = detectProposalV2ProposalTemplate(proposalData?.proposalTemplateSettings)
  const projectId = useSelector((state) => state.projectId)
  const dirtyFields = form.mutators.getFormDirtyFields()
  const systemsReady = !useField('simulate_first_year_only', {
    subscription: { value: true },
  }).input.value

  console.log('systemsReady: ', systemsReady)

  const getPreviewError = (dirtyFields, projectId, systemsReady) => {
    if (isNaN(projectId)) {
      return 'Please create project before proceeding'
    } else if (dirtyFields.length > 0) {
      return 'You have unsaved changes, please save project before proceeding'
    } else if (!systemsReady) {
      if (requestedPoll !== projectId && window.WorkspaceHelper.checkLastCalcStatus(projectId)) {
        setRequestedPoll(projectId)
      }
      return 'Calculations in progress, the PDF will be available when they are complete'
    }
  }

  const error = getPreviewError(dirtyFields, projectId, systemsReady)
  return (
    <SectionContainer section="preview" mode="hidden">
      {error ? <PreviewNotAvailable message={error} /> : <Preview projectId={projectId} />}
      {!isProposalV2 && isPro && <ProposalDrawer handleChange={() => refresh(refreshCount + 1)} />}
    </SectionContainer>
  )
}
