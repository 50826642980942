import { createStyles, LinearProgress, withStyles } from '@material-ui/core'
import { OpenSolarTheme } from 'Themes'

const BorderLinearProgress = withStyles((theme) =>
  createStyles({
    root: {
      height: 10,
      borderRadius: 5,
      border: `1px solid ${OpenSolarTheme.greyMid2}`,
      width: '70%',
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 100 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#113B98',
    },
  })
)(LinearProgress)

const BorderProgress = ({ value }) => {
  return <BorderLinearProgress variant="determinate" value={value} />
}

export default BorderProgress
