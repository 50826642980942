import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import { Button } from 'opensolar-ui'
import { HardwareSupplierFilterKeyType } from 'pages/ordering/type'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import restClient from 'restClient'
import useDistributorAuthenticationStatus from 'services/useDistributorAuthenticationStatus'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { orgSelectors } from '../../ducks/orgs'

const useStyles = makeOpenSolarStyles((theme) => ({
  button: { margin: 5 },
}))

type VtacConnectUserButtonProps = {
  label?: string
  variant: 'outlined' | 'contained' | 'text'
  trackSource: string
  color?: 'default' | 'primary' | 'secondary' | 'warning'
  fullWidth?: boolean
  icon?: React.ReactNode
}

const restClientInstance = restClient(window.API_ROOT + '/api')

const VtacConnectUserButton: React.FC<VtacConnectUserButtonProps> = ({
  label = 'Connect V-TAC',
  variant,
  trackSource,
  color = 'default',
  fullWidth = false,
  icon,
}) => {
  const classes = useStyles()
  const [isConnected, setIsConnected] = useState(false)

  const orgId = useSelector(authSelectors.getOrgId) || 0
  const history = useHistory()
  const country = useSelector(orgSelectors.getOrgIso2) || 'GB'
  const distributor: HardwareSupplierFilterKeyType = country === 'GB' ? 'vtac_uk' : 'vtac_pl'

  const { isAuthenticated, isChecking } = useDistributorAuthenticationStatus({
    orgId,
    distributor,
  })

  useEffect(() => {
    if (isAuthenticated && !isChecking) {
      setIsConnected(true)
    }
  }, [isAuthenticated, isChecking])

  const onClick = () => {
    // Handle button click logic here

    logAmplitudeEvent('hardware_distributor_connect_button_clicked', {
      source: trackSource,
      distributor,
    })
    if (isConnected) {
      restClientInstance('CUSTOM_GET', 'custom', {
        url: 'orgs/' + orgId + `/distributors/${distributor}/disconnect/`,
      })
        .then((response) => {
          setIsConnected(false)
        })
        .catch((err: any) => {
          console.log(err, err)
        })
    } else {
      history.push('/shop/connect_distributor/vtac/email')
    }
  }

  return (
    <Button
      onClick={onClick}
      variant={variant}
      color={color}
      disabled={false}
      className={classes.button}
      fullWidth={fullWidth}
    >
      {icon && icon}
      {isConnected ? 'Disconnect V-TAC' : label}
    </Button>
  )
}

export default VtacConnectUserButton
