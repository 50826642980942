import { FormControlLabel, Grid } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'
import { ArrowDropDown, ArrowRight } from '@material-ui/icons'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import { permissionsSelectors } from 'ducks/permissions'
import { setUtilityApiDialog } from 'ducks/utilityApi'
import CustomField from 'elements/field/CustomField'
import DependentInput from 'elements/input/DependentInput'
import { useConsumptionCurves } from 'hooks/useConsumptionCurves'
import _ from 'lodash'
import { Button, Switch } from 'opensolar-ui'
import parseUsageInitialValues from 'projectSections/sections/energy/parseUsageInitialValues'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  FormDataConsumer,
  NumberInput,
  SelectInput,
  TextInput,
  useDataProvider,
  useNotify,
  useRefresh,
  useTranslate,
} from 'react-admin'
import { useForm } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import UtilityApiDialog from 'resources/projects/elements/UtilityApiDialog'
import restClient from 'restClient'
import { RootState } from 'types/state'
import {
  ar12,
  ar4,
  ar6,
  bimonthlyNames,
  currencySymbolForCountry,
  DEFAULT_USAGE_DATA,
  monthNames,
  numberWithCommas,
  parseUsageJsonString,
  quarterNames,
  reloadEntireApp,
} from 'util/misc'
import { getOrgFromState } from 'util/org'
import { positiveOrZero } from 'validations'
import MonthlyUsageChart from '../MonthlyUsageChart'
import { HourlyCurveSettings } from './HourlyCurveSettings'
import IntervalDataFields from './IntervalDataFields'
import { Meter, ServiceAddress, UsageType, Utility } from './types'

export const usageTypeFromMeterData = (meterData: Meter): UsageType => {
  if (meterData.intervals_csv) {
    return {
      code: 'intervals',
      label: 'Intervals',
    }
  } else if (meterData.bill_count > 0) {
    return {
      code: 'bills',
      label: 'Bills',
    }
  } else {
    return {
      code: null,
      label: 'Not Available',
    }
  }
}

const restClientInstance = restClient(window.API_ROOT + '/api')

const FIELDS_AFFECT_USAGE = [
  'country_iso2',
  'state',
  'is_residential',
  'utility_tariff_current',
  'utility_tariff_current_custom',
  'curve_weekday',
  'curve_weekend',
]
//To Do: use NumberInput for number values

const ElectricityUsageContent = () => {
  const form = useForm()
  const formState = form.getState()

  const currentTariffs = useMemo(() => {
    return [formState.values.utility_tariff_current, formState.values.utility_tariff_current_custom]
  }, [formState.values.utility_tariff_current, formState.values.utility_tariff_current_custom])

  // const currentTariffs = useCurrentTariff()

  const { curves_ready } = useConsumptionCurves()
  const translate = useTranslate()

  const refresh = useRefresh()
  const didMountRef = useRef(false)

  const { address, locality, state, zip } = formState.values
  const email = formState.values.contacts_data?.[0]?.email
  const display = formState.values.contacts_data?.[0]?.display
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const projectCountry = formState.values['country_iso2']
  const isResidential = formState.values['is_residential']
  const currencySymbol = currencySymbolForCountry(projectCountry)
  const dispatch = useDispatch()
  const projectId = useSelector((state: RootState) => state.projectId)
  const orgId = useSelector((state: RootState) => getOrgFromState(state)?.id)
  const enableUtilityApi = useSelector((state: RootState) => getOrgFromState(state)?.enable_utility_api)
  const api_key_pvsell = useSelector(authSelectors.getPvSellKey)
  const [showHourlyCurveSettings, setHourlyCurveSettings] = useState(false)
  const [showMaxDemandSettings, setMaxDemandSettings] = useState(false)
  const [showControlledLoadSettings, setControlledLoadSettings] = useState(false)
  const [loading, setLoading] = useState(false)
  const [utilities, setUtilities] = useState<Utility[]>([])
  const [meters, setMeters] = useState<Meter[]>([])
  const usageData = useMemo(() => parseUsageJsonString(formState.values.usage), [formState.values.usage])
  const dirtyFields = form.mutators.getFormDirtyFields()
  const fullAddress = `${address}, ${locality}, ${state} ${zip}`

  const { allowView, allowEdit } = useSelector(permissionsSelectors.getProjectPermissionByKey('energy_usage_tariff'))
  const disabled = !allowEdit

  const USAGE_DATA_CHOICES = [
    { id: 'Enter Annual kWh', name: 'Annual Consumption (kWh)' },
    { id: 'Enter 1-12 Monthly kWh', name: 'Monthly Consumption (kWh)' },
    { id: 'Enter 1-6 Bi-Monthly kWh', name: 'Bi-Monthly Consumption (kWh)' },
    { id: 'Enter 1-4 Quarterly kWh', name: 'Quarterly Consumption (kWh)' },
    { id: 'Enter Daily kWh for 1-12 Months', name: 'Daily Consumption (kWh)' },
    { id: 'Enter Annual Bill', name: `${translate('Annual Bill')} (${currencySymbol})` },
    { id: 'Enter 1-12 Monthly Bills', name: `${translate('Monthly Bills')} (${currencySymbol})` },
    { id: 'Enter 1-6 Bi-Monthly Bills', name: `${translate('Bi-Monthly Bills')} (${currencySymbol})` },
    { id: 'Enter 1-4 Quarterly Bills', name: `${translate('Quarterly Bills')} (${currencySymbol})` },
    { id: 'Estimate (Low)', name: 'Estimate (Low)' },
    { id: 'Estimate (Medium)', name: 'Estimate (Medium)' },
    { id: 'Estimate (High)', name: 'Estimate (High)' },
    { id: 'Interval Data', name: 'Interval Data' },
  ]
  // This map will be used for sending tracking data to amplitude
  const USAGE_DATA_EVENT_MAP = USAGE_DATA_CHOICES.reduce((map, dataChoice) => {
    map[dataChoice.id] = dataChoice.name
    return map
  }, {})
  const [usageDataSource, setUsageDataSource] = useState<string>(
    _.get(USAGE_DATA_EVENT_MAP, formState.values.usage_data_source)
  )

  const CSV_UPLOAD_URL = useMemo(() => {
    return `${(window as any).API_BASE_URL}orgs/${orgId}/projects/${projectId}/interval_data_upload_to_project/`
  }, [orgId, projectId])

  const getValidUsageData = (data: any): string => {
    const usageData = parseUsageJsonString(data)

    // If "interval_data" is selected as the usage_data_source, make sure it contains the relevant data for this input source.
    // In this particular case it is sufficient to check for "interval_mins". Default back to the default usage data if this check fails.
    if (usageData.usage_data_source === 'interval_data' && !usageData.interval_mins) {
      return JSON.stringify(DEFAULT_USAGE_DATA)
    }

    return JSON.stringify(usageData)
  }

  const onFailure = useCallback((error) => {
    // do we want to update an invalid usage value?
    setLoading(false)
    notify(translate(error.message), 'warning')
  }, [])

  const refreshUsage = useCallback(
    _.debounce(() => {
      const unsavedChange = form ? form.mutators.getUnsavedChangeAffectSystemCalcs() : {}
      // Always use the latest usage data from the form state
      const usageData = unsavedChange.usage === undefined ? form.getState().values.usage : unsavedChange.usage
      const data = { usage: getValidUsageData(usageData), ...unsavedChange }

      restClientInstance('CUSTOM_POST', 'custom', {
        url: `orgs/${orgId}/projects/${projectId}/usage/`,
        data,
      })
        .then((response: any) => {
          if (response?.data) {
            //update usage data here
            //ensure usage data is valid
            form.mutators.updateField('usage', JSON.stringify(response.data.usage))
          }
          setLoading(false)
        })
        .catch((error: any) => {
          onFailure(error)
        })
    }, 2000),
    [orgId, projectId]
  )

  useEffect(() => {
    const {
      usage_data_source,
      curve_weekday,
      curve_weekend,
      scale_weekend,
      kw_monthly_demand,
      demand_60min,
      controlled_load_daily_kwh_0,
      controlled_load_daily_kwh_1,
      controlled_load_daily_kwh_2,
    } = usageData

    if (usage_data_source !== 'default') {
      let controlledLoads = [controlled_load_daily_kwh_0, controlled_load_daily_kwh_1, controlled_load_daily_kwh_2]
      for (let i = 0; i < controlledLoads.length; i++) {
        if (controlledLoads[i] > 0) {
          setControlledLoadSettings(true)
          break
        }
      }
      if (curve_weekday || curve_weekend || scale_weekend) {
        setHourlyCurveSettings(true)
      }
    }

    var showDemand = false

    if (demand_60min) {
      showDemand = true
    }

    if (kw_monthly_demand) {
      for (let i = 0; i < kw_monthly_demand.length; i++) {
        if (kw_monthly_demand[i] !== null) {
          showDemand = true
          break
        }
      }
    }
    if (showDemand) {
      setMaxDemandSettings(true)
    }
  }, [projectId])

  useEffect(() => {
    if (didMountRef.current) {
      onFormChange()
    }
  }, currentTariffs)

  useEffect(() => {
    didMountRef.current = true
    if (dirtyFields.some((field: string) => FIELDS_AFFECT_USAGE.includes(field))) {
      setLoading(true)
      refreshUsage()
    }
    return () => {
      didMountRef.current = false
    }
  }, [])

  const trackFormUpdates = (field: string, action: string, context?: { [key: string]: any }) => {
    logAmplitudeEvent('energy_electricity_usage_interacted', {
      action: `${field}_${action}`,
      ...(context ?? {}),
    })
  }

  const onUsageTypeChange = (e) => {
    // clear usage data, so there's not a mismatch between `usage_data_source` and `usage`
    setUsageDataSource((prevValue) => {
      const currentValue = _.get(USAGE_DATA_EVENT_MAP, e.target.value)
      trackFormUpdates('usage_data_source', 'updated', {
        existing_selection: prevValue,
        inputted_data: currentValue,
      })
      return currentValue
    })

    form.mutators.updateField('usage', null)
    onFormChange()
  }

  const onFormChange = useCallback(() => {
    setLoading(true)
    refreshUsage()
  }, [])

  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false)

  const onSelectSuccessCallback = useCallback(() => {
    setLoading(true)
    const payload = {
      url: `projects/${projectId}/usage/`,
    }
    const options = {
      onSuccess: (response: any) => {
        const currentFormValues = form.getState().values
        //update usage data string
        currentFormValues.usage = JSON.stringify(response.data.usage)
        const newValues = parseUsageInitialValues(currentFormValues)
        form.restart(newValues)
        console.log(newValues)
        // ask for system re-calc
        form.mutators.requireSystemCalcs(true)

        setLoading(false)
      },
      onFailure,
    }
    dataProvider.CUSTOM_GET('projects', payload, options).catch(() => {})
  }, [])

  const removeHourlyCurveSettingsValues = () => {
    form.mutators.updateField('curve_weekday', null)
    form.mutators.updateField('curve_weekend', null)
    form.mutators.updateField('scale_weekend', null)

    // This is not a perfect because the values may not have changed but
    // at least it will force the save button to appear
    form.mutators.markFieldAsDirty('curve_weekday')
    form.mutators.markFieldAsDirty('curve_weekend')
    form.mutators.markFieldAsDirty('scale_weekend')
    form.mutators.markFieldAsDirty('usage')

    onFormChange()
  }

  const removeMaxDemandSettingsValues = () => {
    let len = 0
    for (let i = 0; i <= 11; i++) {
      form.mutators.updateField(`kw_${len}`, null)
      form.mutators.markFieldAsDirty(`kw_${len}`)

      len += 1
    }
    form.mutators.updateField('demand_60min', null)
    form.mutators.markFieldAsDirty('demand_60min')

    // This is not a perfect because the values may not have changed but
    // at least it will force the save button to appear
    form.mutators.markFieldAsDirty('usage')

    onFormChange()
  }

  const removeControlledLoadSettingsValues = () => {
    form.mutators.updateField('controlled_load_daily_kwh_0', null)
    form.mutators.updateField('controlled_load_daily_kwh_1', null)
    form.mutators.updateField('controlled_load_daily_kwh_2', null)

    // This is not a perfect because the values may not have changed but
    // at least it will force the save button to appear
    form.mutators.markFieldAsDirty('controlled_load_daily_kwh_0')
    form.mutators.markFieldAsDirty('controlled_load_daily_kwh_1')
    form.mutators.markFieldAsDirty('controlled_load_daily_kwh_2')
    form.mutators.markFieldAsDirty('usage')

    onFormChange()
  }

  const onSuccess = useCallback(() => {
    notify('Loading Data Complete. Reloading...')
    if (onSelectSuccessCallback && typeof onSelectSuccessCallback === 'function') {
      onSelectSuccessCallback()
    }
    refresh()
    setUtilityApiDialog(false, null, null)
  }, [])

  const selectUtilityApiMeter = useCallback(
    (meterData: Meter) => {
      notify('Loading Data...')
      const meterId = meterData.uid
      const dataFormat = usageTypeFromMeterData(meterData).code
      const payload = {
        orgId,
        projectId,
        url: 'projects/' + projectId + '/import_usage/?meter_id=' + meterId + '&data_format=' + dataFormat,
      }
      const options = {
        onSuccess,
        onFailure,
      }
      dataProvider.CUSTOM_PUT('projects', payload, options)
    },
    [orgId, projectId]
  )

  const addressCompare = (projectAd: string, apiAdes: ServiceAddress[]) => {
    const splitedProjectAddress = projectAd.split(',')
    const projectAddress = splitedProjectAddress[0]
    const projecttLocality = splitedProjectAddress[1]
    const projectState = splitedProjectAddress[2]

    const filteredAddress = apiAdes.filter((apiAd) => {
      const splitedApiAddress = apiAd.service_address.split(',')
      const apiAddress = splitedApiAddress[0]
      const apiLocality = splitedApiAddress[1]
      const apiState = splitedApiAddress[2]
      return !!(apiAddress.match(projectAddress) && apiLocality.match(projecttLocality) && apiState.match(projectState))
    })
    return filteredAddress
  }

  if (!allowView) return <p>{translate('No permission.')}</p>

  return (
    <div style={{ width: '100%' }}>
      <Grid container id={'electricity-usage-content-container'} spacing={3}>
        <Grid item xs={12} sm={6}>
          <SelectInput
            name="usage_data_source"
            id={'energy-usage-data-source-select-input'}
            source="usage_data_source"
            resource={'projects'}
            onChange={onUsageTypeChange}
            choices={USAGE_DATA_CHOICES}
            style={{ width: '100%', margin: '16px 30px 16px 0px' }}
            translateChoice={true}
            disabled={disabled}
          />
        </Grid>
        {enableUtilityApi && (
          <Grid item xs={12} sm={6}>
            <>
              <Button
                variant="outlined"
                style={{ marginTop: 16 }}
                onClick={() => {
                  restClientInstance('CUSTOM_GET', 'custom', {
                    url: 'orgs/' + orgId + '/utility_api_meters/',
                  })
                    .then((response: { data: { results: any; utilities: any } }) => {
                      const { results, utilities } = response.data
                      setMeters(results)
                      setUtilities(utilities)
                      return addressCompare(fullAddress, results)
                    })
                    .then((meterData: Meter[]) => {
                      if (meterData.length > 0) {
                        return meterData
                      } else {
                        dispatch(setUtilityApiDialog(true, projectId, null))
                      }
                    })
                    .then((meterData: Meter[]) => {
                      if (meterData) {
                        let intervalUsageData = meterData.filter((data) => data.intervals_csv)
                        selectUtilityApiMeter(intervalUsageData ? intervalUsageData[0] : meterData[0])
                      }
                    })
                }}
                disabled={disabled}
              >
                <span>{translate('UtilityAPI Import')}</span>
              </Button>
              <UtilityApiDialog
                address={address}
                name={display}
                email={email}
                utilities={utilities}
                meters={meters}
                selectMeter={selectUtilityApiMeter}
              />
            </>
          </Grid>
        )}
        <DependentInput dependsOn="usage_data_source" value={'Enter 1-12 Monthly kWh'}>
          {ar12.map((i) => {
            return (
              <Grid key={'kwh_' + i} item xs={6} sm={3}>
                <TextInput
                  name={'kwh_' + i}
                  key={'kwh_' + i}
                  resource={'projects'}
                  source={'kwh_' + i}
                  onChange={onFormChange}
                  fullWidth
                  style={{ width: '100%' }}
                  label={monthNames[i]}
                  disabled={disabled}
                  validate={positiveOrZero}
                />
              </Grid>
            )
          })}
        </DependentInput>
        <DependentInput dependsOn="usage_data_source" value={'Enter Daily kWh for 1-12 Months'}>
          {ar12.map((i) => {
            return (
              <Grid key={'kwh_' + i} item xs={6} sm={3}>
                <TextInput
                  name={'kwh_' + i}
                  key={'kwh_' + i}
                  resource={'projects'}
                  source={'kwh_' + i}
                  onChange={onFormChange}
                  fullWidth
                  style={{ width: '100%' }}
                  label={monthNames[i]}
                  disabled={disabled}
                  validate={positiveOrZero}
                />
              </Grid>
            )
          })}
        </DependentInput>
        <DependentInput dependsOn="usage_data_source" value={'Enter 1-12 Monthly Bills'}>
          {ar12.map((i) => {
            return (
              <Grid key={'bill_' + i} item xs={6} sm={3}>
                <TextInput
                  name={'bill_' + i}
                  key={'bill_' + i}
                  resource={'projects'}
                  source={'bill_' + i}
                  onChange={onFormChange}
                  fullWidth
                  style={{ width: '100%' }}
                  label={monthNames[i]}
                  disabled={disabled}
                  validate={positiveOrZero}
                />
              </Grid>
            )
          })}
        </DependentInput>
        <DependentInput dependsOn="usage_data_source" value={'Enter 1-6 Bi-Monthly kWh'}>
          {ar6.map((i) => {
            return (
              <Grid key={'kwh_' + i} item xs={6} sm={3}>
                <TextInput
                  name={'kwh_' + i}
                  key={'kwh_' + i}
                  resource={'projects'}
                  source={'kwh_' + i}
                  onChange={onFormChange}
                  fullWidth
                  style={{ width: '100%' }}
                  label={bimonthlyNames[i]}
                  disabled={disabled}
                  validate={positiveOrZero}
                />
              </Grid>
            )
          })}
        </DependentInput>
        <DependentInput dependsOn="usage_data_source" value={'Enter 1-6 Bi-Monthly Bills'}>
          {ar6.map((i) => {
            return (
              <Grid key={'bill_' + i} item xs={6} sm={3}>
                <TextInput
                  name={'bill_' + i}
                  key={'bill_' + i}
                  resource={'projects'}
                  source={'bill_' + i}
                  onChange={onFormChange}
                  fullWidth
                  style={{ width: '100%' }}
                  label={bimonthlyNames[i]}
                  disabled={disabled}
                  validate={positiveOrZero}
                />
              </Grid>
            )
          })}
        </DependentInput>
        <DependentInput dependsOn="usage_data_source" value={'Enter 1-4 Quarterly kWh'}>
          {ar4.map((i) => {
            return (
              <Grid key={'kwh_' + i} item xs={6} sm={3}>
                <TextInput
                  name={'kwh_' + i}
                  key={'kwh_' + i}
                  resource={'projects'}
                  source={'kwh_' + i}
                  onChange={onFormChange}
                  fullWidth
                  style={{ width: '100%' }}
                  label={quarterNames[i]}
                  disabled={disabled}
                  validate={positiveOrZero}
                />
              </Grid>
            )
          })}
        </DependentInput>
        <DependentInput dependsOn="usage_data_source" value={'Enter 1-4 Quarterly Bills'}>
          {ar4.map((i) => {
            return (
              <Grid key={'bill_' + i} item xs={6} sm={3}>
                <TextInput
                  name={'bill_' + i}
                  key={'bill_' + i}
                  resource={'projects'}
                  source={'bill_' + i}
                  onChange={onFormChange}
                  fullWidth
                  style={{ width: '100%' }}
                  label={quarterNames[i]}
                  disabled={disabled}
                  validate={positiveOrZero}
                />
              </Grid>
            )
          })}
        </DependentInput>
        <DependentInput dependsOn="usage_data_source" value={'Enter Annual Bill'}>
          <Grid item xs={12} sm={6}>
            <TextInput
              name={'bill_annual'}
              key={'bill_annual'}
              resource={'projects'}
              source={'bill_annual'}
              onChange={onFormChange}
              fullWidth
              style={{ width: '100%' }}
              disabled={disabled}
              validate={positiveOrZero}
            />
          </Grid>
        </DependentInput>

        <FormDataConsumer>
          {({ formData, ...rest }: { formData: { usage_data_source: string; usage_annual_or_guess: any } }) => {
            if (formData['usage_data_source'] === 'Enter Annual kWh') {
              const usageGuess = numberWithCommas(formData.usage_annual_or_guess, true)
              const placeholder =
                formData.usage_annual_or_guess &&
                `${translate('Default for location:')} ${usageGuess} ${translate('kWh/yr')} `
              return (
                <Grid item xs={12} sm={6}>
                  <NumberInput
                    name={'kwh_annual'}
                    key={'kwh_annual'}
                    resource={'projects'}
                    onChange={onFormChange}
                    options={{
                      placeholder,
                      InputLabelProps: {
                        shrink: true,
                      },
                    }}
                    source={'kwh_annual'}
                    fullWidth
                    style={{ width: '100%' }}
                    disabled={disabled}
                  />
                </Grid>
              )
            } else {
              return null
            }
          }}
        </FormDataConsumer>
        <DependentInput dependsOn="usage_data_source" value={'Interval Data'}>
          {!enableUtilityApi && <Grid item xs={12} sm={6}></Grid>}
          <IntervalDataFields
            uploadURL={CSV_UPLOAD_URL}
            usageData={usageData}
            onChange={onFormChange}
            disabled={disabled}
          />
        </DependentInput>
        <DependentInput dependsOn="usage_data_source" resolve={(v) => v !== 'Interval Data'}>
          <Grid item xs={12} sm={12}>
            <Button
              onClick={() => {
                const updatedState = !showAdvancedSettings
                setShowAdvancedSettings(updatedState)
                trackFormUpdates('advanced_settings', 'clicked', {
                  toggle_state: updatedState.toString(),
                })
              }}
              variant="text"
            >
              {translate('Advanced Settings')}
              {showAdvancedSettings ? <ArrowRight /> : <ArrowDropDown />}
            </Button>
          </Grid>

          {showAdvancedSettings && (
            <>
              <Grid item xs={12} sm={12}>
                <div style={{ marginTop: 16 }}>
                  <FormControlLabel
                    label={translate('Configure Usage Profile Curves')}
                    control={
                      <Switch
                        checked={showHourlyCurveSettings}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          const updatedState = event.target.checked
                          setHourlyCurveSettings(updatedState)
                          trackFormUpdates('configure_usage_profile_curves', 'clicked', {
                            toggle_state: updatedState.toString(),
                          })
                          removeHourlyCurveSettingsValues()
                        }}
                        disabled={disabled}
                      />
                    }
                  />
                </div>
              </Grid>
              {showHourlyCurveSettings && curves_ready && (
                <HourlyCurveSettings
                  isResidential={isResidential}
                  onFormChange={onFormChange}
                  usageData={usageData}
                  projectCountry={projectCountry}
                  loading={loading}
                />
              )}
              <Grid item xs={12} sm={12}>
                <div style={{ marginTop: 16 }}>
                  <FormControlLabel
                    label={translate('Set Maximum Demand in kW')}
                    control={
                      <Switch
                        checked={showMaxDemandSettings}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          const updatedState = event.target.checked
                          setMaxDemandSettings(updatedState)
                          trackFormUpdates('set_maximum_demand_in_kw', 'clicked', {
                            toggle_state: updatedState.toString(),
                          })
                          removeMaxDemandSettingsValues()
                        }}
                        disabled={disabled}
                      />
                    }
                  />
                </div>
              </Grid>

              {showMaxDemandSettings && (
                <Grid item container spacing={3}>
                  <DependentInput dependsOn="usage_data_source" resolve={(value: any) => value && value !== 'Default'}>
                    {ar12.map((i) => {
                      return (
                        <Grid key={'kw_' + i} item xs={6} sm={3}>
                          <TextInput
                            name={'kw_' + i}
                            key={'kw_' + i}
                            resource={'projects'}
                            source={'kw_' + i}
                            onChange={onFormChange}
                            fullWidth
                            style={{ width: '100%' }}
                            label={translate(monthNames[i])}
                            disabled={disabled}
                          />
                        </Grid>
                      )
                    })}
                  </DependentInput>
                  {/*In future we may want to hide the 8760 hourly demand value input if there is no existing data in this field as we want*/}
                  {/*to depreciate this input field but for now we will keep both just in case */}
                  <Grid item xs={12} sm={12}>
                    <TextInput
                      onChange={onFormChange}
                      multiline={true}
                      fullWidth
                      resource={'projects'}
                      key={'demand_60min'}
                      source={'demand_60min'}
                      name={'demand_60min'}
                      // validate={validate8760OrEmpty}
                      disabled={disabled}
                    />
                  </Grid>
                </Grid>
              )}

              <Grid item xs={12} sm={6}>
                <div style={{ marginTop: 16 }}>
                  <FormControlLabel
                    label={translate('Set Controlled Loads')}
                    control={
                      <Switch
                        checked={showControlledLoadSettings}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          const updatedState = event.target.checked
                          setControlledLoadSettings(updatedState)
                          trackFormUpdates('set_controlled_loads', 'clicked', {
                            toggle_state: updatedState.toString(),
                          })
                          removeControlledLoadSettingsValues()
                        }}
                        disabled={disabled}
                      />
                    }
                  />
                </div>
              </Grid>

              {showControlledLoadSettings && (
                <Grid item container spacing={3}>
                  <DependentInput dependsOn="usage_data_source" resolve={(value: any) => value && value !== 'Default'}>
                    <Grid item xs={12} sm={6}>
                      <TextInput
                        onChange={onFormChange}
                        fullWidth
                        resource={'projects'}
                        name="controlled_load_daily_kwh_0"
                        source="controlled_load_daily_kwh_0"
                        endAdornment={<InputAdornment position="end">kWh</InputAdornment>}
                        disabled={disabled}
                      />
                    </Grid>
                  </DependentInput>

                  <DependentInput dependsOn="usage_data_source" resolve={(value: any) => value && value !== 'Default'}>
                    <Grid item xs={12} sm={6}>
                      <TextInput
                        onChange={onFormChange}
                        resource={'projects'}
                        source="controlled_load_daily_kwh_1"
                        fullWidth
                        name="controlled_load_daily_kwh_1"
                        endAdornment={<InputAdornment position="end">kWh</InputAdornment>}
                        disabled={disabled}
                      />
                    </Grid>
                  </DependentInput>

                  <DependentInput dependsOn="usage_data_source" resolve={(value: any) => value && value !== 'Default'}>
                    <Grid item xs={12} sm={6}>
                      <TextInput
                        onChange={onFormChange}
                        resource={'projects'}
                        source="controlled_load_daily_kwh_2"
                        fullWidth
                        name="controlled_load_daily_kwh_2"
                        endAdornment={<InputAdornment position="end">kWh</InputAdornment>}
                        disabled={disabled}
                      />
                    </Grid>
                  </DependentInput>
                </Grid>
              )}
            </>
          )}
        </DependentInput>

        <CustomField
          component={TextInput}
          source="usage"
          name="usage"
          // value={props.usage}
          style={{ width: '100%', opacity: '0.5', display: 'none' }}
          label="Hidden Data Usage"
          disabled={disabled}
        />

        {api_key_pvsell && (
          <div style={{ margin: '0 10px', width: '100%' }}>
            <Button
              key="SunWizImport"
              style={{
                boxSizing: 'border-box',
                marginRight: 50,
                marginTop: 10,
                width: '100%',
              }}
              variant="contained"
              color="default"
              onClick={() => {
                restClientInstance('CUSTOM_GET', 'custom', {
                  url: 'orgs/' + orgId + '/projects/' + projectId + '/signed_reception/request/?fields=usage',
                }).then(
                  (response: { data: { signature: string } }) => {
                    window.open(
                      window.SUNWIZ_API_DOMAIN +
                        '/standalone/project/upload-consumption?project_id=' +
                        projectId +
                        '&org_id=' +
                        orgId +
                        '&signature=' +
                        response.data.signature +
                        '&access-token=' +
                        api_key_pvsell
                    )
                  },
                  () => {
                    return Promise.reject('Failed to launch remote application.')
                  }
                )
              }}
              disabled={disabled}
            >
              <span>{translate('Launch')}</span>
              <span style={{ fontStyle: 'italic', margin: '0 5px', fontSize: 16 }}>
                <b style={{ color: '#f9a03d' }}>Sun</b>
                <b style={{ color: '#1587c2' }}>Wiz</b>
              </span>
              <span style={{ textTransform: 'none' }}>{translate('Interval Meter Data Tool')}</span>
            </Button>
            <p className="small">
              Upload interval meter data CSV/Excel or Select from SunWiz consumption profile library. After making
              updates in the SunWiz Interval Meter Data Tool, please{' '}
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault()
                  reloadEntireApp()
                }}
              >
                refresh this page
              </a>{' '}
              to see data in OpenSolar.
            </p>
          </div>
        )}
      </Grid>

      <div style={{ width: '100%' }}>{usageData && <MonthlyUsageChart loading={loading} usageData={usageData} />}</div>
    </div>
  )
}

export default ElectricityUsageContent
