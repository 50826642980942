import { Grid } from '@material-ui/core'
import React, { useMemo, useState } from 'react'
import OnboardingField from './OnboardingField'
import { FormRouteInfoPropTypes } from './types'

const BlueSnapOnboardingSection: React.FC<FormRouteInfoPropTypes> = (props) => {
  const [isManualAddressEntryEnabled, setIsManualAddressEntryEnabled] = useState(false)
  const sortedFieldsThisPage = useMemo(() => {
    if (props.fieldMap && props.pageNumber) {
      setIsManualAddressEntryEnabled(false)
      return props.fieldMap[props.pageNumber]?.sort((a, b) => {
        return a.priority - b.priority
      })
    }
  }, [props.pageNumber, props.fieldMap])

  return (
    <div data-testid="bluesnap-onboarding-form-section" style={{ width: '100%' }}>
      <Grid container spacing={2}>
        {sortedFieldsThisPage?.map((field) => (
          <OnboardingField
            field={field}
            key={field.field_name}
            fieldPrefixFilter={props.fieldPrefixFilter}
            setIsManualAddressEntryEnabled={setIsManualAddressEntryEnabled}
            isManualAddressEntryEnabled={isManualAddressEntryEnabled}
          />
        ))}
      </Grid>
    </div>
  )
}
export default BlueSnapOnboardingSection
