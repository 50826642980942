import * as Sentry from '@sentry/react'
import { authSelectors } from 'ducks/auth'
import { CheckoutActionCallback } from 'opensolar-checkout'
import { useCallback, useContext } from 'react'
import { useNotify } from 'react-admin'
import { useSelector } from 'react-redux'
import { logAppOpenedEvent } from 'util/events'
import ActiveDialogContext from '../../dialogs/ActiveDialogContext'

const usePlentiApplicationCallback = (): CheckoutActionCallback => {
  const isPro = !!useSelector(authSelectors.getCurrentRole)
  const notify = useNotify()
  const { showDialog } = useContext(ActiveDialogContext)
  return useCallback(async ({ elementData, proposalData, additionalActionData }) => {
    if (elementData.type !== 'LoanApplication') {
      Sentry.captureException(new Error('Invalid element data for LoanApplication callback'))
      return
    }

    const { actionData } = elementData
    const selectedPaymentOptionName = proposalData.selectedPaymentOption.title

    const modifyUrlBasedOnUserType = (url: string) => {
      return url && isPro ? url.replace('workflowType=1', 'workflowType=3') : url
    }

    if (actionData.application_url === undefined) {
      Sentry.captureException(new Error('Plenti application_url is undefined'))
      notify('Unable to apply', 'warning')
      return
    }
    logAppOpenedEvent(actionData, selectedPaymentOptionName)
    showDialog(
      'PlentiLoanApplication',
      {
        url: modifyUrlBasedOnUserType(actionData.application_url),
      },
      true
    )
  }, [])
}

export default usePlentiApplicationCallback
