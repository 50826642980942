import { permissionsSelectors } from 'ducks/permissions'
import CustomField from 'elements/field/CustomField'
import { styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React, { useMemo } from 'react'
import { BooleanInput } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { RowCentered } from '../../../styles'
import ProjectTeamMember from './ProjectTeamMember'
import TeamMemberInput from './TeamMemberInput'

export const PROJECT_ROLES = [
  { title: 'Assigned Team Member', id: 'assigned_role' },
  { title: 'Installer', id: 'assigned_installer_role' },
  { title: 'Designer', id: 'assigned_designer_role' },
  { title: 'Site Inspector', id: 'assigned_site_inspector_role' },
  { title: 'Salesperson', id: 'assigned_salesperson_role' },
]

const ToggleWrapper = styled('div', { name: 'ToggleWrapper' })(({ theme }) => ({
  marginTop: 30,
  padding: 16,
  backgroundColor: theme.palette.grey[50],
  '& p': {
    margin: 0,
    marginRight: 'auto',
  },
}))

const InternalTeams: React.FC = () => {
  const form = useForm()
  const translate = useTranslate()
  const formValues = useFormState().values
  const internalTeam = useMemo(() => formValues.internalTeam, [formValues.internalTeam])
  const rolesAssigned = useMemo(() => internalTeam?.map((x) => x.projectRoleId), [internalTeam])
  const membersAssigned = useMemo(() => internalTeam?.map((x) => x.id), [internalTeam])
  const getRoleChoices = (includeRole = '') =>
    PROJECT_ROLES.filter((x) => !rolesAssigned?.includes(x.id) || x.id === includeRole).map((x) => ({
      ...x,
      title: translate(x.title),
    }))
  const { allowEdit } = useSelector(permissionsSelectors.getProjectPermissionByKey('manage_assigned_users'))

  return (
    <>
      <TeamMemberInput roleChoices={getRoleChoices()} assignedTeamIds={membersAssigned} />
      <CustomField
        source="internalTeam"
        name="internalTeam"
        internalTeam={internalTeam}
        getRoleChoices={getRoleChoices}
        component={ProjectTeamMembers}
      />
      <ToggleWrapper>
        <RowCentered>
          <p>{translate('Allow email notifications')} </p>
          <BooleanInput
            source={`allow_email_notifications`}
            label={''}
            options={{ color: 'primary' }}
            disabled={!allowEdit}
          />
        </RowCentered>
        <RowCentered>
          <p>{translate('Restricted Access')} </p>
          <BooleanInput
            options={{ color: 'primary' }}
            source={'access'}
            value={formValues.access === 2}
            onChange={(value) => {
              form.change('access', value === false ? 1 : 2)
            }}
            label={''}
            style={{ marginTop: 10 }}
            disabled={!allowEdit}
          />
        </RowCentered>
      </ToggleWrapper>
    </>
  )
}

const ProjectTeamMembers = (props) => {
  return (
    <>
      {props.internalTeam?.map((member, i) => (
        <ProjectTeamMember
          key={'member-' + i}
          member={member}
          source={`internalTeam.${i}`}
          role={member.projectRoleId}
          roleChoices={props.getRoleChoices(member.projectRoleId)}
        />
      ))}
    </>
  )
}

export default InternalTeams
