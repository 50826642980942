import { makeStyles } from '@material-ui/core'
import { permissionsSelectors } from 'ducks/permissions'
import moment from 'moment'
import { styled } from 'opensolar-ui'
import { Column, RowCentered } from 'projectSections/sections/manage3/styles'
import { useTranslate } from 'ra-core'
import React from 'react'
import { useSelector } from 'react-redux'
import { StageActionEventType } from 'types/projects'
import { Theme } from 'types/themes'
import { formatDate } from 'util/date'
import ActionButtons from './events/ActionButtons'
import EventIcon from './events/EventIcon'
import TaskStatus from './events/TaskStatus'
import { EVENT_HIDE_NOTES } from './util'
const useStyles = makeStyles((theme: Theme) => ({
  eventWrapperMin: {
    marginBottom: 20,
    '&.last': {
      marginBottom: 0,
    },
  },
  eventTitle: {
    margin: 0,
    fontWeight: 500,
    '&.min': {
      fontWeight: 400,
    },
  },
  fullWidth: {
    width: '100%',
    gap: 6,
    marginLeft: 10,
  },
}))

const SectionTitle = styled('p', { name: 'SectionTitle' })(({ theme }) => ({
  color: theme.palette.grey[810],
  fontWeight: 500,
}))

const EventWrapper = styled('div', { name: 'EventWrapper' })(({ theme }) => ({
  paddingTop: 16,
  paddingBottom: 24,
  borderBottom: theme.mixins.borderStandard.border,
  '&.last': {
    borderBottom: 'none',
  },
  display: 'flex',
  alignItems: 'center',
}))

const TimeStamp = styled('span', { name: 'TimeStamp' })(({ theme }) => ({
  fontSize: 12,
  color: theme.palette.grey[700],
  fontWeight: 400,
  marginTop: 2,
}))

const Notes = styled('div', { name: 'Notes' })(({ theme }) => ({
  display: 'flex',
  maxHeight: 65,
  overflowY: 'auto',
  borderRadius: 8,
  padding: 12,
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.grey[700],
  '& p': {
    margin: 0,
  },
}))

interface DisplayProps {
  title?: string
  events: StageActionEventType[]
  toggleModal: (event: StageActionEventType) => void
  toggleDelete: (event: StageActionEventType) => void
  minDisplay?: boolean
}

const EventsDisplay: React.FC<DisplayProps> = ({ title, events, toggleModal, toggleDelete, minDisplay }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const currentDate = moment()
  const { allowEdit, allowDelete } = useSelector(
    permissionsSelectors.getProjectPermissionByKey('manage_notes_activities_actions')
  )

  return (
    <Column>
      {title && <SectionTitle>{translate(title)}</SectionTitle>}
      {!!events.length &&
        events.map((event, i) => {
          const dayDifference = currentDate.diff(moment(event.created_date), 'days')
          if (minDisplay)
            return (
              <RowCentered
                key={'event-' + i}
                className={`${classes.eventWrapperMin} ${i === events.length - 1 ? 'last' : ''}`}
              >
                <EventIcon event={event} minDisplay={minDisplay} />
                <Column className={classes.fullWidth}>
                  <RowCentered>
                    <Column>
                      <p className={`${classes.eventTitle} min`}>{event.title}</p>
                      <TimeStamp>
                        {dayDifference > 2
                          ? formatDate(moment(event.created_date))
                          : moment(event.created_date).fromNow()}{' '}
                        {event.event_icon && event.who?.display && `by ${event.who.display}`}
                      </TimeStamp>
                    </Column>
                    <ActionButtons
                      minDisplay={minDisplay}
                      toggleDelete={toggleDelete}
                      toggleModal={toggleModal}
                      event={event}
                      disableEdit={!allowEdit}
                      disableDelete={!allowDelete}
                    />
                  </RowCentered>
                  <TaskStatus event={event} minDisplay={minDisplay} />
                  {event.notes && (!EVENT_HIDE_NOTES.includes(event.form_config) || event.event_type_id === 32) && (
                    <Notes>
                      <p style={{ whiteSpace: 'pre-line' }}>{event.notes}</p>
                    </Notes>
                  )}
                </Column>
              </RowCentered>
            )
          return (
            <EventWrapper key={'event-' + i} className={i === events.length - 1 ? 'last' : ''}>
              <EventIcon event={event} minDisplay={minDisplay} />
              <Column className={classes.fullWidth}>
                <RowCentered>
                  <Column>
                    <p className={classes.eventTitle}>{event.title} </p>
                    <TimeStamp>
                      {dayDifference > 2
                        ? formatDate(moment(event.created_date))
                        : moment(event.created_date).fromNow()}{' '}
                      {event.event_icon && event.who?.display && `by ${event.who.display}`}
                    </TimeStamp>
                  </Column>
                  <ActionButtons
                    minDisplay={minDisplay}
                    toggleDelete={toggleDelete}
                    toggleModal={toggleModal}
                    event={event}
                    disableEdit={!allowEdit}
                    disableDelete={!allowDelete}
                  />
                </RowCentered>
                <TaskStatus event={event} />
                {event.notes && (!EVENT_HIDE_NOTES.includes(event.form_config) || event.event_type_id === 32) && (
                  <Notes>
                    <p style={{ whiteSpace: 'pre-line' }}>{event.notes}</p>
                  </Notes>
                )}
              </Column>
            </EventWrapper>
          )
        })}
    </Column>
  )
}

export default EventsDisplay
