import { makeStyles, useMediaQuery } from '@material-ui/core'
import { EditOutlined } from '@material-ui/icons'
import { BaseThemeType, Button, styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React, { ReactElement, ReactNode, useState } from 'react'
import { Theme } from 'types/themes'
import { RowCentered } from '../styles'
import EditModal, { ModalProps } from './EditModal'

const useStyles = makeStyles(
  (theme: Theme) => ({
    editBtn: {
      fontSize: '12px !important',
      height: '10px !important',
      margin: '0 !important',
    },
    editIcon: {
      height: 15,
    },
    hideBtn: {
      opacity: 0,
      [theme.breakpoints.down('xs')]: {
        opacity: 1,
      },
    },
  }),
  { name: 'HoverEditWrapper' }
)

interface PropTypes {
  titleElement: ReactElement
  children: ReactElement
  className?: string
  modalProps: ModalProps
  emptyToggle?: {
    label: ReactNode
    className?: string
    startIcon?: ReactNode
  }
  titleIcon?: ReactNode
  id?: string
  enableFlexGrow?: boolean
}

const divider = 1
const columnGap = 20
const border = 2
const DetailSectionStyled = styled('div', { name: 'DetailSectionStyled' })<{
  isMobile: boolean
  enableFlexGrow: boolean
}>(({ isMobile, theme, enableFlexGrow }) =>
  isMobile
    ? { display: 'flex', justifyContent: 'space-between', maxHeight: 30 }
    : {
        flexGrow: enableFlexGrow ? 1 : 0,
        height: 65,
        marginLeft: columnGap / 2,
        marginRight: columnGap / 2,
        position: 'relative',
        '& p': {
          fontWeight: 500,
          margin: 0,
          color: theme.palette.grey[810],
        },
        '&:after': {
          content: '""',
          position: 'absolute',
          right: '100%',
          top: '50%',
          display: 'inline-block',
          width: divider,
          height: '100%',
          backgroundColor: theme.palette.grey[110],
          transform: 'translateY(-50%)',
          marginRight: (columnGap - divider) / 2 + border,
        },
      }
)

const HoverEditWrapper: React.FC<PropTypes> = ({
  titleElement,
  children,
  className,
  modalProps,
  emptyToggle,
  titleIcon,
  id,
  enableFlexGrow = true,
}) => {
  const classes = useStyles()
  const translate = useTranslate()
  const [showEditBtn, setShowEditBtn] = useState(false)
  const [showEditModal, setShowEditModal] = useState(false)
  const isMobile = useMediaQuery((theme: BaseThemeType) => theme.breakpoints.down('xs'))

  return (
    <>
      <DetailSectionStyled
        enableFlexGrow={enableFlexGrow}
        isMobile={isMobile}
        onMouseOver={() => {
          setShowEditBtn(true)
        }}
        onMouseLeave={() => {
          setShowEditBtn(false)
        }}
        className={className}
      >
        <RowCentered>
          {titleElement}
          {titleIcon}
          <Button
            id={id ? id + '-edit' : 'hover-edit'}
            size="small"
            color="info"
            startIcon={<EditOutlined className={classes.editIcon} />}
            className={`${classes.editBtn} ${!showEditBtn ? classes.hideBtn : ''}`}
            variant="text"
            onClick={() => {
              setShowEditModal(!showEditModal)
            }}
          >
            {translate('Edit')}
          </Button>
        </RowCentered>
        {emptyToggle ? (
          <Button
            variant="text"
            color="info"
            size="small"
            startIcon={emptyToggle.startIcon}
            className={emptyToggle.className}
            onClick={() => {
              setShowEditModal(!showEditModal)
            }}
          >
            {emptyToggle.label}
          </Button>
        ) : (
          children
        )}
      </DetailSectionStyled>
      <EditModal
        open={showEditModal}
        {...modalProps}
        onClose={() => {
          setShowEditModal(false)
        }}
      />
    </>
  )
}

export default HoverEditWrapper
