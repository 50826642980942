import { InputAdornment } from '@material-ui/core'
import { useBlockUpdatesWhileFocused } from 'elements/hooks/useBlockUpdatesWhileFocused'
import { TextFieldProps } from 'opensolar-ui'
import { FieldTitle, InputProps, useInput } from 'ra-core'
import { InputHelperText, ResettableTextField } from 'ra-ui-materialui'

export type JsonInputProps = InputProps<TextFieldProps> &
  Omit<TextFieldProps, 'label' | 'helperText'> & { stringify?: boolean }

const JsonInputField = ({
  stringify,
  validate,
  onChange,
  onBlur,
  onFocus,
  resource,
  source,
  startAdornment,
  endAdornment,
  helperText,
  label,
  InputProps: InputPropsOverride,
  ...props
}) => {
  const validateJsonInput = (value) => {
    if (!stringify && typeof value !== 'string') return validate?.(value)
    try {
      JSON.parse(value)
    } catch (e) {
      return 'Invalid JSON'
    }
    return validate?.(value)
  }

  // These look wrong, but they aren't, `stringify=true` means that the underlying data is a string, so requires no parsing
  // Whereas `stringify=false` means that the underlying data is an object, so requires parsing
  const parseJsonInput = (value) => {
    if (stringify) return value
    try {
      return JSON.parse(value)
    } catch (e) {
      return value
    }
  }
  const formatJsonInput = (value) => {
    if (stringify) return value
    return typeof value !== 'string' ? JSON.stringify(value, null, 2) : value
  }

  const {
    id,
    input: { value: inputValue, onChange: inputOnChange, onFocus: inputOnFocus, onBlur: inputOnBlur, ...input },
    isRequired,
    meta: { error, touched, submitError },
  } = useInput({
    format: formatJsonInput,
    onBlur,
    onChange,
    onFocus,
    parse: parseJsonInput,
    resource,
    source,
    type: 'text',
    validate: validateJsonInput,
  })
  const errorMessage = error || submitError

  const adornment = {
    startAdornment: startAdornment && <InputAdornment position="start">{startAdornment}</InputAdornment>,
    endAdornment: endAdornment && <InputAdornment position="end">{endAdornment}</InputAdornment>,
  }

  const blockedProps = useBlockUpdatesWhileFocused({
    value: inputValue,
    onChange: inputOnChange,
    onFocus: inputOnFocus,
    onBlur: inputOnBlur,
  })

  const InputProps = { className: 'code-block', ...adornment, ...InputPropsOverride }
  return (
    <ResettableTextField
      id={id}
      {...input}
      {...blockedProps}
      label={
        label !== '' &&
        label !== false && <FieldTitle label={label} source={source} resource={resource} isRequired={isRequired} />
      }
      error={!!(touched && errorMessage)}
      helperText={<InputHelperText touched={!!touched} error={errorMessage} helperText={helperText} />}
      InputProps={InputProps}
      multiline
      rows={12}
      InputLabelProps={{
        shrink: true,
      }}
      variant="outlined"
      {...props}
    />
  )
}

export default JsonInputField
