import { Add } from '@material-ui/icons'
import { projectListViewSelectors, ProjectView, ViewType } from 'ducks/projectListViews'
import { BarChartSquareIcon, Button, styled, TableIcon } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import ViewButtonCount from './elements/ViewButtonCount'
import ViewButtonDropdown from './elements/ViewButtonDropdown'

const Wrapper = styled('div', { name: 'Wrapper' })(({ theme }) => ({
  width: '100%',
  margin: '10px 0',
  padding: '10px 0',
  overflowX: 'auto',
  borderBottom: theme.mixins.borderStandard.border,
  display: 'flex',
  alignItems: 'center',
}))

const ViewButton = styled(Button, { name: 'ViewButton' })<{
  isActive: boolean
}>(({ isActive, theme }) => ({
  fontWeight: 450,
  color: isActive ? theme.palette.info.contrastText : theme.palette.grey[700],
}))

const BtnLabel = styled('span', { name: 'BtnLabel' })({
  margin: '0 5px',
})

const ButtonContainer = styled('div', { name: 'ButtonContainer' })({
  position: 'relative',
  marginRight: 15,
  flex: '0 0 auto',
})
const Highlight = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: -10,
  width: '100%',
  borderBottom: '2px solid ' + theme.palette.info.contrastText,
}))

type Props = {
  viewSelected: ProjectView
  handleViewChange: (viewId: number | string) => void
  handleAddView: (viewCount: number) => void
}

const ViewToolbar: FC<Props> = ({ viewSelected, handleViewChange, handleAddView }) => {
  const translate = useTranslate()
  const viewId = useMemo(() => viewSelected?.id, [viewSelected])
  const selectedViewCount = useMemo(() => viewSelected?.view_count || 0, [viewSelected?.view_count])
  const views: ProjectView[] = useSelector(projectListViewSelectors.getProjectListViewData)
  const isUnSavedViewAvailable: boolean = useSelector(projectListViewSelectors.getIsUnsavedViewExist)

  return (
    <Wrapper className="os-scroll">
      {views?.map((view) => {
        const isSelected = view.id === viewId
        return (
          <ButtonContainer key={'filter-' + view.id}>
            <ViewButton
              onClick={() => {
                handleViewChange(view.id)
              }}
              variant="text"
              color="secondary"
              startIcon={
                view.view_type === ViewType.Table ? <TableIcon /> : <BarChartSquareIcon transform={'rotate(180deg)'} />
              }
              isActive={isSelected}
            >
              <BtnLabel>{translate(view.name)}</BtnLabel>
              <ViewButtonCount isSelected={isSelected} count={view.view_count} />
            </ViewButton>
            {isSelected && <Highlight />}
            <ViewButtonDropdown selectedView={view} handleViewChange={handleViewChange} views={views} />
          </ButtonContainer>
        )
      })}
      <Button
        disabled={isUnSavedViewAvailable}
        startIcon={<Add />}
        variant="text"
        color="default"
        onClick={() => {
          handleAddView(selectedViewCount || 0)
        }}
      >
        {translate('Add View')}
      </Button>
    </Wrapper>
  )
}

export default ViewToolbar
