import { TOUR_STEP_END, TOUR_STEP_START, TOUR_STEP_UPDATE } from 'actions/tourActions'
import { Reducer } from 'redux'
import { setQueryParams } from 'util/query'

type ActionTypes = {
  type: string
  payload?: {
    tour: string
    step: string
  }
}

export type TourReducerType = {
  tour: string | null
  step: string | null
}

const defaultState = { tour: null, step: null }

const tourReducer: Reducer<TourReducerType | undefined, ActionTypes> = (
  previousState: TourReducerType = defaultState,
  { payload, type }: any
) => {
  const { tour, step } = payload || {}
  if (type === TOUR_STEP_UPDATE) {
    setQueryParams({ tour: tour, step: step })
    return {
      tour,
      step,
    }
  } else if (type === TOUR_STEP_START) {
    setQueryParams({ tour: tour, step: undefined })
    return {
      tour,
      step: null,
    }
  } else if (type === TOUR_STEP_END) {
    const terminateTour = tour || previousState.tour
    if (terminateTour === previousState.tour) {
      setQueryParams({ tour: undefined, step: undefined })
      return defaultState
    } else {
      return previousState
    }
  } else {
    return previousState
  }
}

export default tourReducer
