import React, { useEffect, useState } from 'react'
import { Box, Grid, IconButton, TextField, AddIcon, MinusIcon, styled } from 'opensolar-ui'

const QuantityButtonContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '36px',
  borderRadius: '6px',
  color: 'white',
  overflow: 'hidden',
}))

const QuantityBox = styled(Grid)(() => ({
  borderTop: `1px solid #CDCDCD`,
  borderBottom: `1px solid #CDCDCD`,
  height: '36px',
  '& p': {
    textAlign: 'center',
    fontSize: '18px',
  },
}))

const StyledIconButton = styled(IconButton)(() => ({
  background: '#4272DD',
  color: 'white',
  padding: 0,
  height: '36px',
  width: '36px',
  borderRadius: 0,
  '&:hover': {
    background: '#113B98',
  },
}))

const StyledTextField = styled(TextField)(() => ({
  width: '100%',
  textAlign: 'center',
  border: 'none',
  background: 'transparent',
  color: 'white',
  padding: '0',

  '& .OSUI-OutlinedInput-notchedOutlinenotchedOutline': {
    border: 'none',
  },
  '&:hover .OSUI-OutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .Mui-focused .OSUI-OutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .OSUI-InputBase-root': {
    background: 'transparent',
  },
  '& fieldset': {
    border: 'none', // Remove the default border
  },
  '& .MuiInput-root': {
    fontSize: '18px',
  },
  '& .MuiInput-underline:hover:not(.Mui-disabled):before ': {
    borderBottom: 'none',
  },
}))

const MAX_QUANTITY = 1000000

const QuantityButton = ({
  onChangeQuantity,
  initialValue,
}: {
  onChangeQuantity: (quantity: number) => void
  initialValue?: number
}) => {
  const handleQuantityChange = (event, qty) => {
    if (event.stopPropagation) event.stopPropagation()

    const newQuantity = qty >= MAX_QUANTITY ? MAX_QUANTITY : qty
    onChangeQuantity(Math.max(0, initialValue + newQuantity))
  }

  const handleInputChange = (event) => {
    if (event.stopPropagation) event.stopPropagation()

    const value = event.target.value
    const newQuantity = value >= MAX_QUANTITY ? MAX_QUANTITY : value

    if (value === '' || Number(value) === 0) {
      onChangeQuantity(0)
      return
    }

    if (/^[0-9]+$/.test(value)) {
      onChangeQuantity(Number(newQuantity))
    }
  }

  const handleBlur = (event) => {
    if (event.stopPropagation) event.stopPropagation()

    if (initialValue === 0) {
      onChangeQuantity(initialValue)
    }
  }

  return (
    <QuantityButtonContainer>
      <Grid container direction="row" justify="space-between" alignItems="center" wrap="nowrap">
        <Grid item>
          <StyledIconButton onClick={(e) => handleQuantityChange(e, -1)}>
            <MinusIcon />
          </StyledIconButton>
        </Grid>
        <QuantityBox container item justify="center" alignContent="center" xs={10}>
          <StyledTextField
            onClick={(e) => e.stopPropagation()}
            value={initialValue}
            onChange={handleInputChange}
            variant="outlined"
            onBlur={handleBlur}
            inputProps={{
              min: 0,
              border: 'none',
              style: { textAlign: 'center' },
            }}
          />
        </QuantityBox>
        <Grid item>
          <StyledIconButton onClick={(e) => handleQuantityChange(e, 1)}>
            <AddIcon />
          </StyledIconButton>
        </Grid>
      </Grid>
    </QuantityButtonContainer>
  )
}

export default QuantityButton
