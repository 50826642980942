import { ModuleLayout, PanelConfiguration } from './items'
import { SignalType } from './signals'

export interface StartableController<StartReturnType> {
  start(...args: unknown[]): StartReturnType
  finish(): void
  readonly active: boolean
}

type GridPresetsType = {
  slope: number
  azimuth: number
  orientation: ModuleLayout
  panelConfiguration: PanelConfiguration
  panelTiltOverride: number | null
  moduleSpacing: [number, number]
}

export interface FingerPaintController extends StartableController<void> {
  readonly activeChanged: SignalType
  readonly panelOrientation: 'landscape' | 'portrait'
  storeGridPresets(presets: Partial<GridPresetsType>): void
  clearGridPresets(): void
  getGridPresets(): Partial<GridPresetsType>
}

export enum MeasurementTypes {
  Length = 'length',
  Height = 'height',
}
type Measurement = { length: number; points: [[number, number, number], [number, number, number]] }

export interface MeasurementController extends StartableController<Promise<Measurement[]>> {
  hasActiveSession(): boolean
  isActive(): boolean
  start(type?: MeasurementTypes, options?: { segmentsLimit: number }): Promise<Measurement[]>
}

export enum ModulePlacementMode {
  AddRemoveModules = 'add_remove_modules',
  AddRemoveBuildables = 'add_remove_buildables',
}

export type ModulePlacementStatus = {
  mode: ModulePlacementMode
  session: {
    startingModule: {
      uuid: string
      active: boolean
      buildable: boolean
    }
    visited: Record<string, boolean>
  }
}

export interface ModulePlacementController {
  setPlacementMode(mode: ModulePlacementMode): void
  setDefaultPlacementMode(): void
  isPainting(): boolean
}
