import lodash from 'lodash'
import { Button, styled } from 'opensolar-ui'
import { FC, useEffect, useMemo, useState } from 'react'
import { doNotTranslate } from 'util/misc'
import { FilterField, FilterStruct, Op, ops as default_ops } from '../../conditions/types'
import { createDefaultCondition } from '../../conditions/utils/utils'
import { FilterConditionBuilder } from './ConditionBuilderRow'

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
})
const Empty = styled('div')({
  display: 'flex',
  padding: 32,
})
const AddButton = styled(Button)({
  margin: 16,
  boxSizing: 'border-box',
  alignSelf: 'center',
})

export type ConditionBuilderProps = {
  value: FilterStruct[]
  onChange: (value: FilterStruct[]) => void
  fields: FilterField[]
  nops?: Op[]
  ops?: Op[]
  emptyText?: string
  fieldFreeSolo?: boolean
  hideOr?: boolean
}

export const ConditionBuilder: FC<ConditionBuilderProps> = ({
  value,
  nops,
  ops,
  fields,
  emptyText,
  fieldFreeSolo,
  hideOr,
  onChange,
}) => {
  const [realValue, setRealValue] = useState(value)

  useEffect(() => {
    if (value !== realValue) {
      // value prop has changed from outside, update realValue
      setRealValue(value)
    }
  }, [value])

  const resolved_ops: Op[] = useMemo(() => {
    let ret: Op[] = ops || default_ops.concat([])
    if (nops) ret = ret.filter((op) => !nops.includes(op))
    return ret
  }, [ops, nops])

  const triggerChange = () => {
    const val = lodash.cloneDeep(realValue)
    setRealValue(val)
    onChange(val)
  }
  const onFilterRemove = (filter: FilterStruct) => {
    const val = realValue.filter((f) => f !== filter)
    setRealValue(val)
    onChange(val)
  }
  const onAddFilter = () => {
    realValue.push({ conditions: [createDefaultCondition(fields[0], resolved_ops[0])] })
    triggerChange()
  }

  return (
    <Wrapper>
      {realValue.length === 0 && (
        <>
          <Empty>{doNotTranslate(emptyText || 'No conditions added')}</Empty>
          <AddButton variant="contained" onClick={onAddFilter}>
            {doNotTranslate('Add Condition')}
          </AddButton>
        </>
      )}
      {realValue.map((filter, index) => (
        <FilterConditionBuilder
          key={index}
          filter={filter}
          ops={resolved_ops}
          onChange={triggerChange}
          onRemove={onFilterRemove}
          fields={fields}
          isLast={index === realValue.length - 1}
          fieldFreeSolo={fieldFreeSolo}
        />
      ))}
      {!hideOr && realValue.length > 0 && (
        <AddButton variant="contained" onClick={onAddFilter}>
          {doNotTranslate('Add OR Condition')}
        </AddButton>
      )}
    </Wrapper>
  )
}
