import { Button } from 'opensolar-ui'
import { useNotify } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'

const AcceptItemsButton = ({
  selectedItemSource,
  recordedItemSource,
  newItemSource,
  handleClick,
}: {
  selectedItemSource: string
  recordedItemSource: string
  newItemSource: string
  handleClick(e): void
}) => {
  const form = useForm()
  const formState = useFormState()
  const notify = useNotify()
  const recordedLineItems = formState.values?.[recordedItemSource] || []
  const newLineItems = formState.values?.[newItemSource] || []
  const selectedLineItems = formState.values?.[selectedItemSource] || new Set()
  const isAllSelected =
    selectedLineItems.size > 0 && selectedLineItems.size === recordedLineItems.length + newLineItems.length

  const acceptItems = (e) => {
    const acceptItemsData = { ...formState.values }
    acceptItemsData['line_items'] = Array.from(acceptItemsData['selected_line_items'] || [])

    const aggregatedItems = Object.values(
      acceptItemsData.line_items.reduce((acc, item) => {
        if (acc[item.code]) {
          acc[item.code].quantity += item.quantity
        } else {
          acc[item.code] = { ...item }
        }
        return acc
      }, {})
    )

    let isValid = true
    aggregatedItems.forEach((newItem: any) => {
      const originalItem = formState.initialValues.line_items.find((initialItem) => initialItem.code === newItem.code)
      if (originalItem && newItem.quantity > originalItem.quantity) {
        notify(
          `${newItem.code} exceeds maximum quantity in this PO. Please reduce total quantity to ${originalItem.quantity}.`,
          'error'
        )
        isValid = false
      }
    })

    if (!isValid) {
      return
    } else {
      handleClick(e)
    }
  }

  return (
    <Button variant="contained" disabled={selectedLineItems.size === 0} color="primary" onClick={acceptItems}>
      {isAllSelected ? 'Accept items' : 'Partially accept items'}
    </Button>
  )
}

export default AcceptItemsButton
