import { createContext } from 'react'
import { useStructContextLogic } from './hooks/useStructContextLogic'
import { StructsContextType } from './types/types'

export const StructsContextCore = createContext<StructsContextType>({
  structs: {},
  add: () => {},
  remove: () => {},
  removeByKey: () => {},
})

type Props = {
  children: React.ReactNode
}

export const StructsContext = ({ children }: Props) => {
  const value = useStructContextLogic()

  return <StructsContextCore.Provider value={value}>{children}</StructsContextCore.Provider>
}
