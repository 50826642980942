import { makeStyles } from '@material-ui/core'
import { Button, DialogCloseButton, DialogContent, DialogTitle, SendOutlineIcon, styled } from 'opensolar-ui'
import React from 'react'
import { useTranslate } from 'react-admin'
import LinkSharing from './LinkSharing'

const useStyles = makeStyles({
  closeIcon: {
    position: 'absolute !important' as any,
    right: 8,
    top: 12,
    cursor: 'pointer',
  },
  divider: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  removeMargin: {
    marginTop: 0,
  },
  headerText: {
    width: '100%',
  },
})

export const HeaderRow = styled('div', { name: 'HeaderRow' })({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
})

const StyledContent = styled(DialogContent, { name: 'StyledContent' })({
  padding: 20,
})

const ChooseText = styled('h2', { name: 'ChooseText' })(({ theme }) => ({
  fontSize: 16,
  fontWeight: 500,
  color: theme.palette.grey[810],
}))

const ItemWrapper = styled('div', { name: 'ItemWrapper' })(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
  backgroundColor: theme.palette.grey[50],
  borderRadius: 4,
  padding: 16,
  margin: '20px 0',
  '& h3': {
    fontWeight: 500,
  },
}))

interface PropTypes {
  onClose: () => void
  setModalMode: (isEmail: boolean) => void
  contactDisplay: string
  shareDisabled: boolean
}

const ShareModalContent: React.FC<PropTypes> = ({ onClose, setModalMode, contactDisplay, shareDisabled }) => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <>
      <DialogTitle>
        <HeaderRow>
          <div className={classes.headerText}>
            {contactDisplay
              ? translate('Send Proposal to %{contacts}', {
                  contacts: contactDisplay,
                })
              : translate('Send Proposal')}
          </div>
          <DialogCloseButton onClick={onClose} />
        </HeaderRow>
      </DialogTitle>

      <StyledContent>
        <ChooseText>{translate('Choose from one of the options below:')}</ChooseText>
        <ItemWrapper>
          <h3 className={classes.removeMargin}>{translate('Send proposal link by email via OpenSolar platform')}</h3>
          <Button
            color="default"
            variant="outlined"
            id="Customise_and_send_Proposal_btn"
            startIcon={<SendOutlineIcon variant={1} size={20} />}
            onClick={() => {
              setModalMode(true)
            }}
            disabled={shareDisabled}
          >
            {translate('Customise and Send Proposal')}
          </Button>
        </ItemWrapper>
        <div className={classes.divider}>{translate('or')}</div>
        <LinkSharing />
      </StyledContent>
    </>
  )
}

export default ShareModalContent
