import { DefaultImageryStrategy } from 'types/imagery'
import { MapDataTypes } from 'types/map'
import { getDefaultImageryOptimizedForAutoDesign } from './getDefaultImageryOptimizedForAutoDesign'
import { getImageryTypeDefault } from './getImageryTypeDefault'

export const extractImageryTypeDefaultIfMatchFound = (
  availableImageryTypes: MapDataTypes[],
  allowSaved = true,
  defaultImageryStrategy: DefaultImageryStrategy
) => {
  // detect from default imagery strategy, if set
  if (defaultImageryStrategy === 'optimize-for-auto-design') {
    let imageryType =
      getDefaultImageryOptimizedForAutoDesign(availableImageryTypes, window.WorkspaceHelper.project.lat) || null
    if (imageryType) return imageryType
  }

  var defaultImageryType = getImageryTypeDefault(allowSaved)
  if (defaultImageryType) {
    for (var passCounter = 1; passCounter <= 2; passCounter++) {
      for (var i = 0; i < availableImageryTypes.length; i++) {
        let imageryType = availableImageryTypes[i]

        if (imageryType.map_type === defaultImageryType.map_type) {
          if (passCounter === 1) {
            // First pass look for a close match with the same map_type AND same variation name
            if (imageryType.variation_name === defaultImageryType.variation_name) {
              return imageryType
            }
          } else {
            // Second, look for a simple match with the same map_type
            // Do we need to compare anything other than map_type? e.g. variation_name? We can't do the whole data because
            // at some point data becomes specific to the location and would never match (e.g. lat/lon)
            return imageryType
          }
        }
      }
    }
  }
  return null
}
