// @ts-nocheck
import { Dialog, DialogContent, DialogTitle, Grid, Tooltip } from '@material-ui/core'
import withWidth from '@material-ui/core/withWidth'
import CloseIcon from '@material-ui/icons/ClearOutlined'
import { IconButton, styled } from 'opensolar-ui'
import React, { useCallback, useState } from 'react'
import { Create, Edit, SimpleForm, useDataProvider, useNotify, useTranslate } from 'react-admin'
import { MaterialUIWidthType } from 'types/misc'
import CRUDRecordDialogFooter from './CRUDRecordDialogFooter'
const DEFAULT_CREATE_EDIT_PROPS = {
  redirect: false,
  hideCancelButton: true,
  hideBreadCrumbs: true,
  style: { padding: '0px' },
}

type PropTypes = {
  isOpen: boolean
  record?: any
  resource: string
  basePath: string
  onDismiss: () => void
  formContent: JSX.Element
  notificationOnSuccess: boolean
  source?: string
  updateFormValsFn?: (savedRecord: any) => void
  customSuccessNotificationText?: string
  removeRecordFromForm?: (idToRemove: number) => void
  hideFooterCancelButton?: boolean
  deleteButtonLabel?: string
  deleteDisabled?: boolean
  saveDisabled?: boolean
  dialogTitle?: string
  width?: MaterialUIWidthType
  hideDeleteBtn?: boolean
}

const getDialogStyles = (theme) => ({
  paddingBottom: '10px !important',
  marginTop: -12,
  '& .MuiCard-root': {
    paddingBottom: '0px !important',
    marginBottom: -10,
    borderColor: 'transparent !important',
  },
  '& .MuiToolbar-root::before': {
    content: '""',
    borderTop: '1px solid',
    borderColor: `${theme.palette.secondary.dark} !important`,
    position: 'absolute',
    left: 0,
    right: 0,
    top: -12,
  },
  '& .MuiCardContent-root::before': {
    content: '""',
    borderTop: '1px solid',
    borderColor: `${theme.palette.secondary.dark} !important`,
    position: 'absolute',
    left: 0,
    right: 0,
    top: 72,
  },
})

const DialogBodyCreate = styled(Create, { name: 'DialogBodyCreate' })(({ theme }) => ({
  ...getDialogStyles(theme),
}))

const DialogBodyEdit = styled(Edit, { name: 'DialogBodyEdit' })(({ theme }) => ({
  ...getDialogStyles(theme),
}))

const DialogTitleStyled = styled('div', { name: 'DialogTitleStyled' })({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  alignItems: 'center',
})

const CRUDRecordDialog: React.FunctionComponent<PropTypes> = (props) => {
  const translate = useTranslate()
  const notify = useNotify()
  const mode = props.record && props.record?.id ? 'edit' : 'create'

  const [fetchingUpdatedRecord, setFetchingUpdatedRecord] = useState<boolean>(false)

  const dataProvider = useDataProvider()

  const onSuccess = (response: any) => {
    if (props.notificationOnSuccess)
      notify(props.customSuccessNotificationText || 'Your changes have been saved', 'success')
    if (!!props.updateFormValsFn && response.data) {
      setFetchingUpdatedRecord(true)
      dataProvider
        .getOne(props.resource, { id: response.data.id })
        .then(({ data }: { data: any }) => {
          if (props.updateFormValsFn) props.updateFormValsFn(data)
          dismiss()
          setFetchingUpdatedRecord(false)
        })
        .catch((error: any) => {
          onFailure(error)
          setFetchingUpdatedRecord(false)
        })
    } else {
      dismiss()
    }
  }

  const onDelete = (deleted: any) => {
    if (props.removeRecordFromForm) props.removeRecordFromForm(props.record?.id)
    props.onDismiss()
  }

  const dismiss = useCallback(() => {
    props.onDismiss()
  }, [])

  const onFailure = useCallback((e: any) => {
    notify(e.message, 'warning')
  }, [])

  const renderCreate = () => {
    return (
      <DialogBodyCreate {...props} {...DEFAULT_CREATE_EDIT_PROPS} hideTitle={true} renderChildrenBeforeRecord={true}>
        <SimpleForm
          {...props}
          redirect={false}
          toolbar={
            //   @ts-ignore
            <CRUDRecordDialogFooter
              {...props}
              onDismiss={dismiss}
              onSuccess={onSuccess}
              onFailure={onFailure}
              onDelete={onDelete}
              mode={mode}
              //@ts-ignore
              style={props.width === 'xs' ? { position: 'absolute', bottom: '0px' } : {}}
            />
          }
          hideCancelButton={!!props.hideFooterCancelButton}
          margin={'dense'}
          initialValues={props.record || {}}
        >
          <Grid container spacing={3} style={{ margin: '0px' }}>
            {React.cloneElement(props.formContent, { ...props })}
          </Grid>
        </SimpleForm>
      </DialogBodyCreate>
    )
  }

  const renderEdit = () => {
    return (
      <DialogBodyEdit
        {...props}
        {...DEFAULT_CREATE_EDIT_PROPS}
        hideTitle={true}
        renderChildrenBeforeRecord={true}
        id={props.record?.id}
      >
        <SimpleForm
          {...props}
          redirect={false}
          toolbar={
            //   @ts-ignore
            <CRUDRecordDialogFooter
              {...props}
              onDismiss={dismiss}
              onSuccess={onSuccess}
              onFailure={onFailure}
              onDelete={onDelete}
              deleteDisabled={props.deleteDisabled}
              mode={mode}
              fetchingUpdatedRecord={fetchingUpdatedRecord}
            />
          }
          hideCancelButton={!!props.hideFooterCancelButton}
          margin={'dense'}
          initialValues={props.record || {}}
        >
          <Grid container spacing={3} style={{ margin: '0px' }}>
            {React.cloneElement(props.formContent, { ...props })}
          </Grid>
        </SimpleForm>
      </DialogBodyEdit>
    )
  }

  return (
    <Dialog open={props.isOpen} maxWidth={props?.width || 'sm'} onBackdropClick={dismiss}>
      <DialogTitle>
        <DialogTitleStyled>
          {props.dialogTitle}

          <IconButton onClick={dismiss}>
            {
              <Tooltip title={translate('Close Dialog')} enterDelay={300}>
                <CloseIcon style={{ color: '#000000' }} />
              </Tooltip>
            }
          </IconButton>
        </DialogTitleStyled>
      </DialogTitle>
      <DialogContent style={{ padding: 0 }}>{mode === 'create' ? renderCreate() : renderEdit()}</DialogContent>
    </Dialog>
  )
}
export default withWidth()(CRUDRecordDialog)
