import { terminateCurrentTour, updateTourStep } from 'actions/tourActions'
import { removeTourBanner } from 'ducks/auth'
import { TourConfig } from 'ducks/tour'
import TourRide from 'persistentContent/tours/tools/TourRide'
import { MANAGE_FUJI_TOUR_STEPS } from 'persistentContent/tours/ui3/manage/steps'
import { FC, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
interface PropTypes {
  toggleEndDialog: () => void
  tourConfig: TourConfig
}
const FujiTour: FC<PropTypes> = ({ toggleEndDialog, tourConfig }) => {
  const { steps } = tourConfig
  const dispatch = useDispatch()
  const { tour, step } = useSelector((state: any) => state.tour)

  const stepIndex = useMemo(() => {
    const findStep = steps.find((x) => x.step === step)
    if (findStep) return steps.indexOf(findStep)
    return 0
  }, [step])

  const handleNext = () => {
    let nextStepIndex = stepIndex + 1
    let element: HTMLElement | null = null

    while (nextStepIndex < steps.length && !element) {
      const configs = steps[nextStepIndex].config
      const spotlights: any = configs.spotlights
      const targetId = spotlights[0].targetId
      element = document.getElementById(targetId)
      if (!element) {
        nextStepIndex += 1
      }
    }
    if (element && nextStepIndex < steps.length) {
      const nextStep = steps[nextStepIndex].step
      tourConfig.handleNext(nextStepIndex, nextStep, openDrawer, closeDrawer)
      dispatch(updateTourStep(tour, nextStep))
    } else if (stepIndex + 1 === steps.length) {
      closeDrawer(stepIndex)
      dispatch(terminateCurrentTour(tour))
      toggleEndDialog()
    }
  }

  const handlePrev = () => {
    let prevStepIndex = stepIndex - 1
    let element: HTMLElement | null = null

    while (prevStepIndex >= 0 && !element) {
      const configs = steps[prevStepIndex].config
      const spotlights: any = configs.spotlights
      const targetId = spotlights[0].targetId
      element = document.getElementById(targetId)
      if (!element) {
        prevStepIndex -= 1
      }
    }

    if (element && prevStepIndex >= 0) {
      const prevStep = steps[prevStepIndex].step
      tourConfig.handlePrev(prevStepIndex, prevStep, openDrawer, closeDrawer)
      dispatch(updateTourStep(tour, prevStep))
    }
  }

  function openDrawer(index) {
    const configs = steps[index].config
    const spotlights: any = configs.spotlights
    const targetId = spotlights[0].targetId
    const id = targetId.split('-Inner')[0]
    const drawer = document.getElementById(id) as HTMLElement
    const drawerF = drawer?.firstElementChild as HTMLElement
    if (drawer && drawerF) {
      drawerF.style.visibility = 'visible'
      drawerF.style.transform = 'translateX(0)'
    }
  }

  function closeDrawer(index) {
    const configs = steps[index].config
    const spotlights: any = configs.spotlights
    const targetId = spotlights[0].targetId
    const id = targetId.split('-Inner')[0]
    const drawer = document.getElementById(id) as HTMLElement
    const drawerF = drawer?.firstElementChild as HTMLElement
    if (drawer && drawerF) {
      drawerF.style.visibility = 'hidden'
    }
  }

  const handleCancel = () => {
    const currStep = steps[stepIndex].step
    tourConfig.handleCancel(currStep, stepIndex, closeDrawer)
    dispatch(removeTourBanner(true, tourConfig.name))
    dispatch(terminateCurrentTour(tour))
  }

  return (
    <TourRide
      steps={steps}
      stepIndex={stepIndex}
      visible={tour === tourConfig.startStep}
      handleNext={handleNext}
      handlePrev={stepIndex === 0 ? undefined : handlePrev}
      handleNextLabel={stepIndex + 1 === MANAGE_FUJI_TOUR_STEPS.length ? 'Finish' : 'Next'}
      handleCancel={handleCancel}
    />
  )
}

export default FujiTour
