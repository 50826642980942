import * as Sentry from '@sentry/react'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import { hideFullScreenLoader, markCreditAppAsOpened, showCheckoutDialog } from 'ducks/myEnergy'
import { CheckoutActionCallback } from 'opensolar-checkout'
import { useCallback } from 'react'
import { useNotify } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import restClient from 'restClient'
import { ProjectType } from 'types/projects'

var restClientInstance = restClient(window.API_ROOT + '/api')

const useOpenApplicationDialog = () => {
  const dispatch = useDispatch()
  const notify = useNotify()
  return useCallback(
    async ({
      org_id,
      project_id,
      system_uuid,
      payment_option_id,
      project,
      pricePayable,
    }: {
      org_id: number
      project_id: number
      system_uuid: string
      payment_option_id: number
      project: ProjectType
      pricePayable: number
    }) => {
      await restClientInstance('CUSTOM_POST', 'custom', {
        url: `orgs/${org_id}/projects/${project_id}/systems/${system_uuid}/${payment_option_id}/phoenix/validate_app/`,
        data: {},
      })
        .then((res) => {
          if (res.data.success) {
            dispatch(
              showCheckoutDialog({
                type: 'PhoenixApplicationDialog',
                config: {
                  orgId: org_id,
                  system_uuid: system_uuid,
                  paymentOptionId: payment_option_id,
                  project: project,
                  existingURL: res.data.existing_url,
                  pricePayable: pricePayable,
                },
              })
            )
            logAmplitudeEvent('phoenix_contact_verification_opened', {
              project_id: project_id,
              system_uuid: system_uuid,
              payment_option_id: payment_option_id,
            })
          }
        })
        .catch((err) => {
          let message = err?.body?.message || 'Something went wrong'
          notify(message, 'warning')
        })
        .finally(() => {
          dispatch(hideFullScreenLoader())
          dispatch(markCreditAppAsOpened())
        })
    },
    []
  )
}

const usePhoenixApplicationCallback = (): CheckoutActionCallback => {
  const openApplicationDialog = useOpenApplicationDialog()
  const isPro = !!useSelector(authSelectors.getCurrentRole)
  const notify = useNotify()

  return useCallback(async ({ elementData, proposalData, additionalActionData }) => {
    if (elementData.type !== 'LoanApplication') {
      Sentry.captureException(new Error('Invalid element data for LoanApplication callback'))
      return
    }
    if (elementData.actionData.payment_method !== 'phoenix_application') {
      Sentry.captureException(new Error('Invalid element data for phoenix application callback'))
      return
    }
    if (isPro) {
      notify('Phoenix applications may only be started by the customer', 'warning')
    }
    const { actionData } = elementData
    const attachedPhoenixPaymentOption = proposalData?.selectedProject?.proposal_data?.systems?.find(
      (sys) => sys?.data?.uuid === proposalData.selectedSystem.uuid
    )?.attached_phoenix_payment_option
    const pricePayable = attachedPhoenixPaymentOption?.pricing?.system_price_payable

    await openApplicationDialog({
      org_id: actionData.org_id,
      project_id: actionData.project_id,
      system_uuid: actionData.system_uuid,
      payment_option_id: actionData.payment_option_id,
      pricePayable,
      project: proposalData.selectedProject as ProjectType,
    })
  }, [])
}

export default usePhoenixApplicationCallback
