import { makeStyles, Tooltip } from '@material-ui/core'
import { EmailOutlined } from '@material-ui/icons'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { paymentOptionSelectionSelectors } from 'ducks/paymentOptionSelection'
import { triggerMilestoneRefresh } from 'ducks/projectMilestones'
import Button from 'elements/button/Button'
import { DisclosureType as DividendDisclosureType } from 'myenergy/selectionComponent/loanApplicationButton/dividend/types'
import { FormJSONType } from 'myenergy/selectionComponent/loanApplicationButton/generic/hostedForms/HostedCreditApplicationDialog'
import { HostedDisclosureType } from 'myenergy/selectionComponent/loanApplicationButton/generic/types'
import { DisclosureType as LoanpalDisclosureType } from 'myenergy/selectionComponent/loanApplicationButton/loanpal/types'
import {
  MosaicDisclosuresType,
  MosaicProductType,
} from 'myenergy/selectionComponent/loanApplicationButton/mosaic/types'
import { DisclosureType as SungageDisclosureType } from 'myenergy/selectionComponent/loanApplicationButton/sungage/types'
import { makeNextPaymentRequest } from 'pages/cashFlow/utils'
import { useNotify, useTranslate } from 'ra-core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { FinanceCtaType } from 'resources/financeCTAs/constants'
import restClient from 'restClient'
import { OpenSolarThemeType } from 'Themes'
import SendInvoiceButton from '../../cashFlowTransactions/invoiceActions/SendInvoiceBtn'
import { useGetCashflowCustomerInfoError, useGetPaymentRequests } from '../../cashFlowTransactions/utils'
import { useSystem } from '../../hooks'
import { CalculatedPaymentOptionMessageType } from '../../paymentOptionSelection/types'
import {
  useGetOrgIdFromPaymentOptions,
  useGetPaymentOptionDataFromSystem,
  useGetShouldHideProApplication,
} from '../../utils'
import EmailApplicationDialog from './EmailApplicationDialog'
import LoadingDialog from './InvoiceLoadingDialog'
import LaunchApplicationDialog from './LaunchApplicationDialog'
import LaunchInvoiceConfirmation from './LaunchInvoiceConfirmation'

const restClientInstance = restClient(window.API_ROOT + '/api')

const INTEGRATIONS_SUPPORTING_EMAIL = ['mosaic', 'sungage', 'sunlight']

type PropTypes = {
  integration: string | undefined | null
  paymentOptionId: number
  systemUuid: string | null
  paymentOptionTitle: string
  isInDialog: boolean
  mostUrgentMessage: CalculatedPaymentOptionMessageType | null
  isGhost?: boolean
  upfrontPaymentString?: string
  hasTaxIdError?: boolean
}

export type HostedAppConfig = {
  sungage?: {
    disclosures: SungageDisclosureType
  }
  mosaic?: {
    disclosures: MosaicDisclosuresType[]
    bundleId: string
    productType: MosaicProductType
  }
  loanpal?: {
    disclosures: LoanpalDisclosureType[]
  }
  dividend?: {
    disclosures: DividendDisclosureType
  }
  sunlight?: {
    url: string
  }
  plenti?: {
    url: string
  }
  brighte?: {
    url: string
  }
  generic?: {
    disclosures: HostedDisclosureType[] | undefined
    ctaType: FinanceCtaType
    url: string | undefined
    form_request_body: any
    logo_url: string | undefined
    integration_display_name: string | undefined
    form_json?: FormJSONType | null
  }
}

const useStyles = makeStyles<OpenSolarThemeType, { isInDialog: boolean; hasCashFlowAndFinance: boolean }>((Theme) => ({
  wrapper: {
    marginTop: '20px',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: ({ isInDialog }) => (isInDialog ? 'row' : 'column'),
    justifyContent: ({ isInDialog, hasCashFlowAndFinance }) =>
      isInDialog || hasCashFlowAndFinance ? 'center' : 'flex-end',
    alignItems: ({ hasCashFlowAndFinance }) => (hasCashFlowAndFinance ? 'center' : 'flex-end'),
  },
  buttonWrapper: {
    margin: ({ isInDialog, hasCashFlowAndFinance }) => (isInDialog || hasCashFlowAndFinance ? '8px' : '2px 0px'),
    flex: 1,
    width: '100%',
  },
  buttonsCollection: {
    display: 'flex',
    flexDirection: ({ hasCashFlowAndFinance }) => (hasCashFlowAndFinance ? 'row' : 'column'),
    justifyContent: ({ isInDialog, hasCashFlowAndFinance }) =>
      isInDialog || hasCashFlowAndFinance ? 'space-between' : 'center',
    alignItems: ({ isInDialog, hasCashFlowAndFinance }) =>
      isInDialog || hasCashFlowAndFinance ? 'center' : 'flex-end',
  },
  ctaSectionLabel: {
    width: '100%',
    textAlign: 'center',
    fontSize: '0.75rem',
    color: Theme.greyMid1,
  },
}))

const CardActions: React.FC<PropTypes> = (props) => {
  const [showEmailDialog, setShowEmailDialog] = useState<boolean>(false)
  const [showLaunchDialog, setShowLaunchDialog] = useState<boolean>(false)
  const [launchLoading, setLaunchLoading] = useState<boolean>(false)
  const [hostedAppConfig, setHostedAppConfig] = useState<HostedAppConfig | {}>({})
  const [showEmailButton, setShowEmailButton] = useState<boolean>(
    props.isGhost || (!!props.integration && INTEGRATIONS_SUPPORTING_EMAIL.includes(props.integration))
  )
  const [invoiceLoading, setInvoiceLoading] = useState<boolean>(false)
  const [disableInvoiceButtons, setDisableInvoiceButtons] = useState<boolean>(false)
  const [showInvoiceConfirmation, setShowInvoiceConfirmation] = useState<boolean>(false)

  const paymentOption = useGetPaymentOptionDataFromSystem(props.systemUuid, props.paymentOptionId)
  const project = useFormState().values

  const system = useSystem(props.systemUuid)

  const paymentRequests = useGetPaymentRequests(project.id, system, paymentOption)

  const hideLaunchCTA = useGetShouldHideProApplication(paymentOption, props.integration)
  const refreshSystemsTrigger = useSelector(paymentOptionSelectionSelectors.getSystemRefreshTrigger)

  const hasCustomerInfoErrors = useGetCashflowCustomerInfoError()

  const firstMilestone = useMemo(() => {
    // if there is currently no upfront payment on this payment option then there isn't a deposit to send. This check is needed
    // to protect against sending a deposit when the results of a recent calculation aren't available
    if (props.upfrontPaymentString && !props.isInDialog) {
      let positiveUpfrontPayment = false
      try {
        const numeric = parseFloat(props.upfrontPaymentString)
        if (numeric > 0) positiveUpfrontPayment = true
      } catch (ex) {}
      if (positiveUpfrontPayment && paymentRequests?.paymentRequests?.length) {
        return paymentRequests.paymentRequests?.filter((milestone) => {
          return milestone.payment_number === 1 && milestone.status !== 'cancelled' && milestone.is_deposit
        })?.[0]
      }
    }
    return null
  }, [paymentRequests, props.upfrontPaymentString, props.isInDialog, refreshSystemsTrigger])

  const hasCashFlowAndFinance = useMemo(() => {
    return Boolean(firstMilestone) && (showEmailButton || !Boolean(hideLaunchCTA))
  }, [firstMilestone, hideLaunchCTA, showEmailButton])

  const classes = useStyles({ isInDialog: props.isInDialog, hasCashFlowAndFinance })
  const form = useForm()
  const dispatch = useDispatch()
  const isPendingSave = form.mutators.getFormDirtyFields()?.length > 0
  const notify = useNotify()
  const translate = useTranslate()
  const orgId = useGetOrgIdFromPaymentOptions(props.systemUuid, project.org_id, props.paymentOptionId)

  const checkProjectCommercialDetails = useMemo(() => {
    return !project.is_residential && !Boolean(project?.invoice_details?.recipient_tax_id)
  }, [project?.is_residential, project?.invoice_details?.recipient_tax_id])

  useEffect(() => {
    setDisableInvoiceButtons(checkProjectCommercialDetails)
  }, [checkProjectCommercialDetails])

  const sendInvoiceUnavailable = useMemo(() => {
    return (
      !!props.mostUrgentMessage ||
      invoiceLoading ||
      isPendingSave ||
      !!firstMilestone?.date_completed ||
      !!hasCustomerInfoErrors ||
      disableInvoiceButtons ||
      props.hasTaxIdError
    )
  }, [
    props.mostUrgentMessage,
    invoiceLoading,
    isPendingSave,
    firstMilestone,
    hasCustomerInfoErrors,
    disableInvoiceButtons,
    props.hasTaxIdError,
  ])

  const onEmailClick = useCallback(() => {
    if (props.isGhost) return
    if (isPendingSave) {
      notify(translate('Please save or discard your changes to the project first'), 'warning')
      return
    }
    setShowEmailDialog(true)
    logAmplitudeEvent('payments_page_email_application_clicked', {
      integration: props.integration,
      project_id: project?.id,
      system_uuid: props.systemUuid,
      payment_option_id: props.paymentOptionId,
    })
  }, [props.isGhost, isPendingSave])

  const onLaunchClick = useCallback(() => {
    if (props.isGhost) return
    if (isPendingSave) {
      notify(translate('Please save or discard your changes to the project first'), 'warning')
      return
    }
    setLaunchLoading(true)
    logAmplitudeEvent('payments_page_launch_application_clicked', {
      integration: props.integration,
      project_id: project?.id,
      system_uuid: props.systemUuid,
      payment_option_id: props.paymentOptionId,
    })
    restClientInstance('CUSTOM_GET', 'custom', {
      url: `orgs/${orgId || project.org_id}/projects/${project.id}/systems/${props.systemUuid}/${
        props.paymentOptionId
      }/${props.integration}/application_disclosures/`,
    })
      .then((res) => {
        if (res.data.success) {
          if (props.integration === 'sungage') {
            setHostedAppConfig({ sungage: { disclosures: res.data.disclosures } })
          } else if (props.integration === 'mosaic') {
            setHostedAppConfig({
              mosaic: {
                disclosures: res.data.disclosures,
                bundleId: res.data.bundle_id,
                productType: res.data.product_type,
              },
            })
          } else if (props.integration === 'loanpal') {
            setHostedAppConfig({ loanpal: { disclosures: res.data.disclosures.data } })
          } else if (props.integration === 'dividend') {
            setHostedAppConfig({ dividend: { disclosures: res.data.html_content } })
          } else if (props.integration === 'sunlight') {
            setHostedAppConfig({ sunlight: { url: res.data.url } })
          } else if (props.integration === 'brighte') {
            setHostedAppConfig({ brighte: { url: res.data.url } })
          } else {
            setHostedAppConfig({
              generic: {
                url: res.data.url,
                form_request_body: res.data.form_request_body,
                disclosures: res.data.disclosures,
                logo_url: res.data.logo_url,
                integration_display_name: res.data.integration_display_name,
                form_json: res.data.form_json,
              },
            })
          }
          setShowLaunchDialog(true)
        }
      })
      .catch((err) => {
        const errorMsg = err?.body?.message || err?.body?.Error
        if (errorMsg) notify(errorMsg, 'warning')
        else notify(translate('We are unable to open this credit application'), 'warning')
      })
      .finally(() => setLaunchLoading(false))
  }, [props.isGhost, isPendingSave, orgId])

  const invoiceReminderAvailable = useMemo(() => {
    if (firstMilestone?.id && firstMilestone?.status !== 'cancelled') {
      return true
    }
    return false
  }, [hideLaunchCTA, firstMilestone?.id, firstMilestone?.status])

  const emailInvoiceButtonLabel = useMemo(() => {
    if (invoiceReminderAvailable) {
      return 'Reminder'
    } else {
      return !hideLaunchCTA ? 'Invoice' : 'Deposit Invoice'
    }
  }, [hideLaunchCTA, invoiceReminderAvailable])

  const confirmLaunchInvoice = () => {
    if (!props.systemUuid) {
      notify(translate('This system is not yet saved. Please save the system and try again.'), 'warning')
      return
    }
    if (firstMilestone && firstMilestone?.payment_collection_url && firstMilestone?.status !== 'cancelled') {
      window.open(firstMilestone?.payment_collection_url, '_blank', 'noopener, noreferrer')
      return
    }
    setShowInvoiceConfirmation(true)
  }

  const onLaunchInvoice = () => {
    if (props.systemUuid) {
      setShowInvoiceConfirmation(false)
      setInvoiceLoading(true)
      makeNextPaymentRequest(orgId || project?.org_id, project?.id, props.systemUuid, props.paymentOptionId, true)
        .then((res) => {
          if (res) {
            let withNotifyParam = res.payment_url + '&notify_pro=true'
            window.open(withNotifyParam, '_blank', 'noopener, noreferrer')
            logAmplitudeEvent('cashflow_invoice_launched', {
              project_id: project?.id,
              org_id: orgId,
              is_deposit: true,
              payment_number: 1,
              source: 'payment option card',
            })
          }
        })
        .catch((err) => {
          notify(err.message, 'warning', { autoHideDuration: undefined })
        })
        .finally(() => {
          setInvoiceLoading(false)
          dispatch(triggerMilestoneRefresh())
        })
    }
  }

  // phoenix does not allow pros to launch the credit app
  if (hideLaunchCTA && !firstMilestone) return null
  if (!props.isGhost && !props.integration && !firstMilestone) return null
  return (
    <div className={classes.wrapper}>
      {firstMilestone && !props.isInDialog && !project.payment_option_sold && props.systemUuid && (
        <div>
          {!hideLaunchCTA && <div className={classes.ctaSectionLabel}>{translate('Payment')}</div>}
          <div className={classes.buttonsCollection}>
            <div className={classes.buttonWrapper}>
              <Tooltip
                title={translate('Project must be saved before this action can be taken')}
                disableHoverListener={
                  !props.mostUrgentMessage && !invoiceLoading && !isPendingSave && !firstMilestone.date_completed
                }
                placement="right"
              >
                <span>
                  <SendInvoiceButton
                    label={emailInvoiceButtonLabel}
                    actionType={invoiceReminderAvailable ? 'reminder' : 'invoice'}
                    projectId={project?.id}
                    disableButtons={sendInvoiceUnavailable}
                    systemUuid={props.systemUuid}
                    setDisableButtons={setDisableInvoiceButtons}
                    paymentRequest={firstMilestone}
                    buttonColor="default"
                    buttonSize={props.isInDialog || !hideLaunchCTA ? 'small' : 'medium'}
                    buttonVariant="outlined"
                    fullWidth={true}
                    startIcon={hideLaunchCTA ? <EmailOutlined /> : null}
                  />
                </span>
              </Tooltip>
            </div>
            <div className={classes.buttonWrapper}>
              <Tooltip
                title={
                  hasCustomerInfoErrors
                    ? translate('Please fill out missing required customer and/or project address details')
                    : translate('Project must be saved before this action can be taken')
                }
                disableHoverListener={
                  !props.mostUrgentMessage &&
                  !invoiceLoading &&
                  !isPendingSave &&
                  !firstMilestone.date_completed &&
                  !hasCustomerInfoErrors
                }
                placement="right"
              >
                <span>
                  <Button
                    color="secondary"
                    label={!hideLaunchCTA ? 'Pay Now' : 'Pay Deposit'}
                    fullWidth
                    onClick={confirmLaunchInvoice}
                    disabled={sendInvoiceUnavailable}
                    size={props.isInDialog || !hideLaunchCTA ? 'small' : 'medium'}
                  />
                </span>
              </Tooltip>
              {showInvoiceConfirmation && (
                <LaunchInvoiceConfirmation
                  open={showInvoiceConfirmation}
                  onClose={() => setShowInvoiceConfirmation(false)}
                  onConfirm={onLaunchInvoice}
                />
              )}
            </div>
            {invoiceLoading && (
              <LoadingDialog
                title={translate('Your Invoice is Being Created')}
                intervalMs={3 * 1000}
                messages={[
                  translate('Validating Pricing...'),
                  translate('Collecting Invoice Information...'),
                  translate('Generating Invoice...'),
                  translate('Building payment page...'),
                ]}
              />
            )}
          </div>
        </div>
      )}
      {firstMilestone && !hideLaunchCTA && !props.isInDialog && (
        <div className={classes.ctaSectionLabel}>{translate('Application')}</div>
      )}
      <div className={classes.buttonsCollection}>
        {showEmailButton && (
          <div className={classes.buttonWrapper}>
            <Tooltip
              title={
                props.mostUrgentMessage
                  ? translate('This payment option has a validation error that needs to be resolved first')
                  : translate('Email application directly for this system and payment option to the customer')
              }
              disableHoverListener={!props.isGhost && !props.mostUrgentMessage}
            >
              <span>
                <Button
                  color="default"
                  label={firstMilestone ? 'Email' : translate('Email Application')}
                  fullWidth
                  startIcon={<EmailOutlined />}
                  onClick={onEmailClick}
                  disabled={launchLoading || props.isGhost || !!props.mostUrgentMessage}
                  size={props.isInDialog || firstMilestone ? 'small' : 'medium'}
                />
              </span>
            </Tooltip>
          </div>
        )}
        {!hideLaunchCTA && (
          <div className={classes.buttonWrapper}>
            <Tooltip
              title={
                props.mostUrgentMessage
                  ? translate('This payment option has a validation error that needs to be resolved first')
                  : translate(
                      'Open application for this system and payment option in a popup window to enable completion of the application on your device'
                    )
              }
              disableHoverListener={!props.isGhost && !props.mostUrgentMessage}
            >
              <span>
                <Button
                  color="secondary"
                  label={firstMilestone ? 'Launch' : 'Launch Application'}
                  fullWidth
                  onClick={onLaunchClick}
                  loading={launchLoading}
                  disabled={launchLoading || props.isGhost || !!props.mostUrgentMessage}
                  size={props.isInDialog || firstMilestone ? 'small' : 'medium'}
                />
              </span>
            </Tooltip>
          </div>
        )}
      </div>
      {showEmailDialog && props.systemUuid && orgId && (
        <EmailApplicationDialog onClose={() => setShowEmailDialog(false)} {...props} orgId={orgId} />
      )}
      {showLaunchDialog && props.systemUuid && orgId && (
        <LaunchApplicationDialog
          onClose={() => setShowLaunchDialog(false)}
          systemUuid={props.systemUuid}
          paymentOptionId={props.paymentOptionId}
          integration={props.integration}
          orgId={orgId}
          hostedAppConfig={hostedAppConfig}
          paymentOptionTitle={props.paymentOptionTitle}
        />
      )}
    </div>
  )
}
export default CardActions
