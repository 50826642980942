import { makeStyles, Popover, useMediaQuery } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import SendIcon from '@material-ui/icons/SendOutlined'
import { Button } from 'opensolar-ui'
import React from 'react'
import { useTranslate } from 'react-admin'
import useSendProposalButton from './useSendProposalButton'

const useErrorMessageStyles = makeStyles((theme: any) => ({
  message: {
    verticalAlign: 'middle',
    marginRight: 10,
    fontStyle: 'italic',
    maxWidth: 500,
    display: 'inline-block',
  },
  errorIcon: {
    verticalAlign: 'middle',
    marginRight: 5,
    color: 'rgb(230, 37, 0)',
    '&:hover': {
      color: 'rgb(197 58 32)',
    },
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
}))

const ErrorMessage = ({ errorMessage }: any) => {
  const classes = useErrorMessageStyles()
  const showIconButton = useMediaQuery((theme: any) => theme.breakpoints.down('md'))
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  return showIconButton ? (
    <>
      {/* @ts-ignore */}
      <InfoIcon
        className={classes.errorIcon}
        aria-owns={open ? 'send-proposal-error-message-popover' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onClick={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
      <Popover
        id="send-proposal-error-message-popover"
        className={classes.popover}
        disableScrollLock={true}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <span className={classes.message}>
          <InfoOutlinedIcon style={{ verticalAlign: 'middle' }} />
          {errorMessage}
        </span>
      </Popover>
    </>
  ) : (
    <span className={classes.message}>{errorMessage}</span>
  )
}

const SendProposalButton = () => {
  const { disableButton, errorMessage, hasUnsavedChanges, onSend } = useSendProposalButton()

  const translate = useTranslate()
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('xs'))

  return (
    <div>
      {disableButton && errorMessage && <ErrorMessage errorMessage={translate(errorMessage)} />}
      <Button
        variant="contained"
        color="secondary"
        disabled={disableButton}
        startIcon={<SendIcon />}
        size="small"
        onClick={onSend}
      >
        <span style={{ textTransform: 'none' }}>
          {isMobile && hasUnsavedChanges ? translate('Send') : translate('Send Proposal to Customer')}
        </span>
      </Button>
    </div>
  )
}

export default SendProposalButton
