import { authSelectors } from 'ducks/auth'
import { FujiTourType } from 'ducks/tour'
import { viewModeSelectors } from 'ducks/viewMode'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from 'types/state'

const disabledWidths = ['sm', 'xs', 'xxs']

// const TOUR_ACTION_NAMES: { [tourType in FujiTourType]: string[] } = {
//   main: ['fuji_tour-finish', 'fuji_tour-tour_hidden'],
//   manage: ['fuji_tour-manage_tour_finish', 'fuji_tour-manage_tour_hidden'],
//   project_list: ['fuji_tour-list_tour_finish', 'fuji_tour-list_tour_hidden'],
//   payments: [],
// }

export const useShowTourBanner = (width: string, tourType: FujiTourType = 'main') => {
  // const dispatch = useDispatch()
  // const { actions, checkAction } = useUserActionsContext()
  // const { tour } = useSelector((state: RootState) => state.tour)
  const fujiTourEnabled = useSelector(viewModeSelectors.fujiTourEnabled)
  const isMobileDevice = useMemo(() => window.Utils?.isTouchDevice(), [])
  const shouldShowTour = useSelector((state: RootState) => authSelectors.getShowTourBanner(state, tourType))
  // const queryIsVisible = () => {
  //   if (tourType) {
  //     const actionNames = TOUR_ACTION_NAMES[tourType]
  //     const hideTourActions = actionNames.filter((x) => checkAction(x))
  //     dispatch(hideTourActions.length ? removeTourBanner(true, tourType) : addTourBanner(tourType))
  //   }
  // }

  // useEffect(() => {
  //   if (fujiTourEnabled && shouldShowTour === undefined && !tour && actions?.length) {
  //     queryIsVisible()
  //   }
  // }, [fujiTourEnabled, shouldShowTour, tour, actions])

  return {
    show:
      //mobile screen check
      !isMobileDevice &&
      !disabledWidths.includes(width) &&
      //is lite check
      fujiTourEnabled &&
      //redux state check
      shouldShowTour?.shouldShow,
    //&& !tour
  }
}
