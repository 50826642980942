import { ButtonProps, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/ClearOutlined'
import Button from 'elements/button/Button'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from 'opensolar-ui'
import React, { FC } from 'react'

interface ConfirmHeaderProps {
  title: string
  onClose?: () => void
  ui3?: boolean
}

const ConfirmHeader: FC<ConfirmHeaderProps> = (props) => {
  return (
    <DialogTitle>
      {props.children && <Typography variant="h6">{props.children}</Typography>}
      {props.title && <>{props.ui3 ? props.title : <Typography variant="h6">{props.title}</Typography>}</>}
      {props.onClose ? (
        <IconButton aria-label="close" onClick={props.onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

export interface ConfirmProps {
  open: boolean
  model?: boolean
  header?: {
    title: string
  }
  content?: string | React.ReactNode
  buttons: ButtonInfo[]
  onClose?: () => void
  ui3?: boolean
}

export type ButtonRender = () => React.ReactNode
export type ButtonInfo = ButtonProps | ButtonRender

const Confirm: FC<ConfirmProps> = (props) => {
  return (
    <div>
      <Dialog open={props.open}>
        {props.header && <ConfirmHeader onClose={props.onClose} {...props.header} ui3={props.ui3} />}
        {props.content && <DialogContent>{props.content}</DialogContent>}
        <DialogActions>
          {props.buttons &&
            props.buttons.map((button, index) =>
              typeof button === 'function' ? button() : <Button key={index} {...button} />
            )}
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default Confirm
