import { styled } from '../../styles/styled';
var AcceptProposalWrapper = styled('div')(function (_a) {
    var theme = _a.theme, textColor = _a.textColor;
    return {
        color: textColor,
        textAlign: 'center',
        margin: '15px 10px',
    };
});
export default AcceptProposalWrapper;
