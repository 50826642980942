import { CircularProgress, DialogActions, makeStyles } from '@material-ui/core'
import { ActionsPropTypes, FormProps, SubFormDialog } from 'elements/react-admin/SubFormDialog'
import Tooltip from 'elements/tooltip/Tooltip'
import { Button, styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React, { useMemo } from 'react'
import { useForm } from 'react-final-form'

const useStyles = makeStyles({
  modal: {
    '& .MuiDialog-paper': {
      width: 'unset',
      minWidth: 624,
    },
  },
  discardBtn: {
    marginRight: '5px !important',
  },
})
const Subheader = styled('h1', { name: 'Subheader' })(({ theme }) => ({
  padding: 20,
  paddingBottom: 10,
  margin: 0,
  color: theme.palette.grey[810],
}))

const Toolbar = styled(DialogActions, { name: 'Toolbar' })(({ theme }) => ({
  borderTop: theme.mixins.borderStandard.border,
  padding: 20,
  marginTop: '0 !important',
}))

const FormWrapper = styled('div', { name: 'FormWrapper' })(({ theme }) => ({
  margin: '0 3px',
  '& .MuiFormLabel-root': {
    fontSize: 14,
    color: theme.palette.grey[810],
  },
}))

export interface ModalProps {
  headerText: string
  subHeaderText?: string
  content: React.ReactNode
  toolbarBtn?: (formProps: FormProps) => React.ReactNode
  discardBtnLabel?: string
  submitBtnLabel?: string
  loading?: boolean
  initialValues?: any
  validate?: (values) => object
}

export interface EditModalProps extends ModalProps {
  open: boolean
  modalClass?: string
  onClose: () => void
}

const EditModal: React.FC<EditModalProps> = (props) => {
  const { open, headerText, subHeaderText, modalClass, onClose, initialValues, validate, ...formProps } = props
  const parentForm = useForm()

  return (
    <SubFormDialog
      open={open}
      title={headerText}
      parentForm={parentForm}
      onClose={onClose}
      customToolbar={(actionProps) => <DialogFormToolbar {...props} {...actionProps} />}
      initialValues={initialValues}
      validate={validate}
      disableClose={true}
    >
      {subHeaderText && <Subheader>{subHeaderText}</Subheader>}
      <FormWrapper>{formProps.content}</FormWrapper>
    </SubFormDialog>
  )
}

const DialogFormToolbar: React.FC<EditModalProps & ActionsPropTypes> = (props) => {
  const { onDiscard, onSave, formState, hasValidationErrors, formDirty, allowInvalidSubmit, form } = props
  const classes = useStyles()
  const translate = useTranslate()
  const saveDisabled = useMemo(() => !formState || !formDirty || (!allowInvalidSubmit && hasValidationErrors), [
    allowInvalidSubmit,
    hasValidationErrors,
    formDirty,
    formState,
  ])

  return (
    <Toolbar>
      {props.toolbarBtn && form && formState && props.toolbarBtn({ form, formState })}
      <Button variant="contained" color="default" className={classes.discardBtn} onClick={onDiscard}>
        {props.discardBtnLabel || translate('Discard')}
      </Button>
      <Tooltip title={saveDisabled ? translate('To proceed, please correct the highlighted errors.') : ''}>
        <div>
          <Button
            startIcon={props.loading ? <CircularProgress size={18} color={'inherit'} /> : undefined}
            disabled={saveDisabled || props.loading}
            variant="contained"
            color="primary"
            onClick={onSave}
          >
            {props.submitBtnLabel || translate('Confirm')}
          </Button>
        </div>
      </Tooltip>
    </Toolbar>
  )
}

export default EditModal
