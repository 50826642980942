import { FilterStruct } from 'conditions/types'
import { useConditionalStructs } from 'contexts/structs/hooks/useConditionalStructs'

export const useSponsoredContentStructs = () => {
  return useConditionalStructs<SponsoredContentType>('sponsored-content', { accept_empty_conditions: true })
}

export type SponsoredContentType = {
  campaign_name: string
  component_codes: string
  creative_name: string
  //   placements: string[] // doesn't appear to be used
  weighting: number
  conditions: FilterStruct[]
}
