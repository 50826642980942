import { useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Add } from '@material-ui/icons'
import { permissionsSelectors } from 'ducks/permissions'
import CustomField from 'elements/field/CustomField'
import Tooltip from 'elements/tooltip/Tooltip'
import { useParsedContactData } from 'hooks/useParsedContactData'
import { BaseThemeType, styled } from 'opensolar-ui'
import CustomerBadgeTooltipContent from 'projectSections/sections/manage3/common/CustomerBadgeTooltipContent'
import HoverEditWrapper from 'projectSections/sections/manage3/common/HoverEditWrapper'
import { useTranslate } from 'ra-core'
import { useEffect, useState } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { Theme } from 'types/themes'
import AddContactBtn from './AddContactButton'
import ContactsFormContent from './ContactsFormContent'

const useStyles = makeStyles((theme: Theme) => ({
  addBtn: {
    margin: '0 !important',
    marginTop: '8px !important',
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
    },
  },
  tooltip: {
    backgroundColor: 'white !important',
    padding: '10px 0px 5px 0px',
    minWidth: '255px',
  },
}))

const ContentWrapper = styled('div', { name: 'ContentWrapper' })(({ theme }) => ({
  minHeight: 30,
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.grey[810],
  marginTop: 8,
  [theme.breakpoints.down('xs')]: {
    marginTop: 0,
  },
}))

const DisplayText = styled('span', { name: 'DisplayText' })(({ theme }) => ({
  color: theme.palette.info.contrastText,
  '& .MuiSvgIcon-root': {
    height: 18,
  },
}))

const Separator = styled('span', { name: 'Separator' })(({ theme }) => ({
  color: theme.palette.info.contrastText,
}))

const validateContactForm = (values) => {
  let errors = {}
  if (values.contacts_data?.length) {
    let contactEmails = values.contacts_data.map((x) => x?.email)
    let tempEmails: string[] = []
    let duplicates: string[] = []
    contactEmails.forEach((email) => {
      if (tempEmails.includes(email)) {
        duplicates.push(email)
      } else {
        tempEmails.push(email)
      }
    })
    // Initialize errors['contacts_data'] as an empty array if not already initialized
    errors['contacts_data'] = errors['contacts_data'] || []
    values.contacts_data.forEach((contact, index) => {
      // Email duplicate error
      if (contact?.email && duplicates.includes(contact?.email)) {
        errors['contacts_data'][index] = errors['contacts_data'][index] || {}
        errors['contacts_data'][index].email = 'Please use unique emails for each contact.'
      }

      // Check for required fields and add error if all fields are empty
      if (!contact?.email && !contact?.family_name && !contact?.first_name && !contact?.phone) {
        errors['contacts_data'][index] = errors['contacts_data'][index] || {}
        errors['contacts_data'][index].required =
          'At least one of the fields (email, last name, first name, or phone) is required.'
      }
    })
  }

  return errors
}

const ProjectContacts = ({ label }: { label?: string }) => {
  const form = useForm()
  const classes = useStyles()
  const translate = useTranslate()
  const project = useFormState().values
  const [activeContact, setActiveContact] = useState<number | null>()
  const { allowView, allowCreate } = useSelector(
    permissionsSelectors.getProjectPermissionByKey('info_contact_info_basic')
  )

  const getContactName = (contact, index) => {
    return !contact || (!contact?.first_name && !contact.family_name)
      ? contact?.id
        ? `${translate('Contact')} ${contact.id}`
        : `${translate('Contact')} #${index + 1}`
      : `${contact?.first_name || ''} ${contact?.family_name || ''}`
  }
  const renderContactName = (contact, index, count) => {
    let fullName =
      contact.first_name && contact.family_name
        ? `${contact.first_name} ${contact.family_name}`
        : getContactName(contact, index)
    return count > 1 ? (fullName.length > 17 ? `${fullName.substring(0, 15)}...` : fullName) : fullName
  }

  useEffect(() => {
    const filteredList = project.contacts_data?.filter((x) => x !== undefined)
    if (!!filteredList?.length && filteredList?.length !== project.contacts_data?.length) {
      form.change('contacts_data', filteredList)
    }
  }, [project.contacts_data])

  const hasContactInfo = (contact) => {
    return contact?.email || contact?.phone
  }
  const contacts = useParsedContactData(project.contacts_data)
  const activeContactInfo = contacts ? contacts[activeContact ?? 0] : {}
  const shouldShowTooltip = hasContactInfo(activeContactInfo)
  const isMobile = useMediaQuery((theme: BaseThemeType) => theme.breakpoints.down('xs'))

  if (!allowView) {
    return null
  }

  return (
    <HoverEditWrapper
      modalProps={{
        initialValues: {
          ...project,
          contacts_data: contacts,
        },
        headerText: translate(label || 'Contacts'),
        content: <CustomField source={'contacts_data'} name={'contacts_data'} component={ContactsFormContent} />,
        validate: validateContactForm,
        toolbarBtn: (formProps) => <AddContactBtn {...formProps} disabled={!allowCreate} />,
      }}
      titleElement={<p>{translate(label || 'Contact')}</p>}
      emptyToggle={
        !!!contacts?.length
          ? {
              label: <>{translate(`Add ${label || 'Contact'}`)}</>,
              startIcon: <Add />,
              className: classes.addBtn,
            }
          : undefined
      }
    >
      <>
        <Tooltip
          title={shouldShowTooltip ? <CustomerBadgeTooltipContent activeContactInfo={activeContactInfo} /> : ''}
          interactive={true}
          classes={{ tooltip: classes.tooltip }}
        >
          <ContentWrapper>
            {contacts && !!contacts.length && (
              <>
                {contacts.length === 1 && (
                  <DisplayText onMouseEnter={() => setActiveContact(0)}>
                    {renderContactName(contacts[0], 0, contacts.length)}
                  </DisplayText>
                )}
                {isMobile ? (
                  <>
                    {contacts.length > 1 && (
                      <>
                        <DisplayText onMouseEnter={() => setActiveContact(0)}>
                          {renderContactName(contacts[0], 0, contacts.length)}
                        </DisplayText>
                        <Separator>,&nbsp;</Separator>
                        <Separator>&nbsp;+&nbsp;</Separator>
                        <DisplayText onMouseEnter={() => setActiveContact(-1)}>{contacts.length - 1}</DisplayText>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {contacts.length === 2 && (
                      <>
                        <DisplayText onMouseEnter={() => setActiveContact(0)}>
                          {renderContactName(contacts[0], 0, contacts.length)}
                        </DisplayText>
                        <Separator>&nbsp;&&nbsp;</Separator>
                        <DisplayText onMouseEnter={() => setActiveContact(1)}>
                          {renderContactName(contacts[1], 1, contacts.length)}
                        </DisplayText>
                      </>
                    )}
                    {contacts.length > 2 && (
                      <>
                        <DisplayText onMouseEnter={() => setActiveContact(0)}>
                          {renderContactName(contacts[0], 0, contacts.length)}
                        </DisplayText>
                        <Separator>,&nbsp;</Separator>
                        <DisplayText onMouseEnter={() => setActiveContact(1)}>
                          {renderContactName(contacts[1], 1, contacts.length)}
                        </DisplayText>
                        <Separator>&nbsp;+&nbsp;</Separator>
                        <DisplayText onMouseEnter={() => setActiveContact(-1)}>{contacts.length - 2}</DisplayText>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </ContentWrapper>
        </Tooltip>
      </>
    </HoverEditWrapper>
  )
}
export default ProjectContacts
