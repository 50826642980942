import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, makeStyles } from '@material-ui/core'
import { CloseOutlined } from '@material-ui/icons'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import LoadingDots from 'layout/widgets/LoadingDots'
import { Button } from 'opensolar-ui'
import { PaymentRequestType } from 'pages/cashFlow/types'
import { useNotify, useTranslate } from 'ra-core'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import { OpenSolarThemeType } from 'Themes'
import { useInvoiceLink } from '../utils'

type PropTypes = {
  paymentRequest?: PaymentRequestType
  invoiceDetails?: { id: number; invoice_num: string }
  type?: 'button' | 'link' | null
  setActionsAreShown?: (val: boolean) => void
}

const useStyles = makeStyles<OpenSolarThemeType, { page }>((theme) => ({
  link: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: ({ page }) => (page === 'payments' ? theme.blue : 'black'),
  },
  titleWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.greyLight1,
  },
  closeButton: {
    color: theme.greyMid1,
  },
  buttonsRow: {
    display: 'flex',
    padding: '16px 24px',
    justifyContent: 'end',
    gap: '10px',
    alignItems: 'center',
    width: '100%',
  },

  pdfContainer: {
    minHeight: '600px',
    height: '100%',
    width: '100%',
  },
}))

const PreviewInvoiceLink: React.FC<PropTypes> = (props) => {
  const restClientInstance = restClient(window.API_ROOT + '/api')
  const orgId = useSelector(authSelectors.getOrgId)
  const invoice_id = props.paymentRequest?.invoice || props.invoiceDetails?.id
  const page = props.paymentRequest ? 'payments' : 'invoices'
  const type = props.type || 'link'

  // const [downloadUrl, setDownloadUrl] = useState<string>()
  const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false)
  const classes = useStyles({ page })
  const notify = useNotify()
  const translate = useTranslate()

  const closeDialog = () => {
    setDialogIsOpen(false)
  }

  const { isFetchingInvoiceURL, downloadUrl, requestURL } = useInvoiceLink(orgId, invoice_id, true, type !== 'button')

  const openInvoiceLink = () => {
    if (type === 'button') {
      const openInvoiceUrl = `${downloadUrl}#toolbar=0`
      window.open(openInvoiceUrl, '_blank')
      props.setActionsAreShown && props.setActionsAreShown(false)
      logAmplitudeEvent('cashflow_invoice_viewed', { orgId, invoice_id })
    } else if (downloadUrl) {
      setDialogIsOpen(true)
    } else {
      requestURL()
    }
  }

  useEffect(() => {
    if (type !== 'button' && downloadUrl) {
      setDialogIsOpen(true)
    }
  }, [type, downloadUrl])

  // if we have a payment request but no associated invoice then it's probably an offline payment
  if (props.paymentRequest?.id && !props.paymentRequest.invoice_number) return <div>{translate('N/A')}</div>
  if (props.invoiceDetails?.id && !props.invoiceDetails?.invoice_num) return <div>{translate('N/A')}</div>

  return type === 'button' ? (
    <Button size="small" onClick={openInvoiceLink}>
      {translate('Preview Invoice')}
    </Button>
  ) : (
    <div>
      <a className={classes.link} onClick={openInvoiceLink}>
        {props.paymentRequest?.invoice_number || props.invoiceDetails?.invoice_num}
      </a>
      {dialogIsOpen && (
        <Dialog open={true} maxWidth="md">
          <DialogTitle>
            <div className={classes.titleWrapper}>
              <div>{translate('Preview Invoice')}</div>
              <div>
                <IconButton onClick={closeDialog}>
                  <CloseOutlined className={classes.closeButton} />
                </IconButton>
              </div>
            </div>
          </DialogTitle>
          <DialogContent>
            {isFetchingInvoiceURL ? (
              <LoadingDots />
            ) : (
              <object
                className={classes.pdfContainer}
                data={`${downloadUrl}#toolbar=0`}
                type="application/pdf"
              ></object>
            )}
          </DialogContent>
          <DialogActions>
            <div className={classes.buttonsRow}>
              <div>
                <Button onClick={closeDialog} color="primary" variant="contained">
                  {translate('Close')}
                </Button>
              </div>
            </div>
          </DialogActions>
        </Dialog>
      )}
    </div>
  )
}
export default PreviewInvoiceLink
