import { useMemo } from 'react'
import { Create, Edit, SimpleForm } from 'react-admin'
import { useLocation } from 'react-router-dom'
import { StructFormContent } from './StructFormContent'

export const StructCreate = (props) => {
  const search = useLocation().search
  const recordStr = search && new URLSearchParams(search).get('record')
  const record = recordStr ? JSON.parse(recordStr) : {}
  const realRecord = useMemo(() => ({ data: {}, ...(record || {}) }), [recordStr])
  return (
    <Create {...props} record={realRecord}>
      <SimpleForm>
        <StructFormContent isCreate={true} />
      </SimpleForm>
    </Create>
  )
}

export const StructEdit = (props) => (
  <Edit {...props} filter={{ include_version_history: true }}>
    <SimpleForm>
      <StructFormContent isCreate={false} />
    </SimpleForm>
  </Edit>
)
