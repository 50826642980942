import { Badge } from '@material-ui/core'
import { ProjectView } from 'ducks/projectListViews'
import { BaseTheme, Button, FilterIcon, SettingsIcon, styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { ListControllerProps } from 'ra-core/src/controller/useListController'
import { FC, useMemo, useState } from 'react'
import FilterDrawer from '../filtering/FilterDrawer'
import SettingsDrawer, { ViewSettings } from './SettingsDrawer'

const FilterBadge = styled(Badge, { name: 'FilterBadge' })(({ theme }) => ({
  '& .MuiBadge-badge': { background: theme.palette.blue[700] },
}))

const SettingsBtn = styled(Button, { name: 'SettingsBtn' })({
  marginRight: 10,
})

interface PropTypes extends ListControllerProps {
  viewSettings: ViewSettings
  viewSelected: ProjectView
}

const SettingsButtons: FC<PropTypes> = (props) => {
  const { filterValues, viewSettings } = props
  const translate = useTranslate()
  const [settingsOpen, setSettingsOpen] = useState(false)
  const [filterOpen, setFilterOpen] = useState(false)

  const activeFiltersCount = useMemo(() => {
    if (filterValues?.q) return Object.keys(filterValues).length - 1
    else return Object.keys(filterValues).length
  }, [filterValues])

  return (
    <>
      <FilterBadge badgeContent={activeFiltersCount} color="primary" invisible={activeFiltersCount === 0}>
        <SettingsBtn
          variant="outlined"
          color="secondary"
          startIcon={<SettingsIcon />}
          onClick={() => {
            setSettingsOpen(!settingsOpen)
          }}
        >
          {translate('View Settings')}
        </SettingsBtn>
        <Button
          variant="outlined"
          color={activeFiltersCount > 0 ? 'primary' : 'secondary'}
          startIcon={<FilterIcon />}
          onClick={() => {
            setFilterOpen(!filterOpen)
          }}
          style={{
            borderColor: activeFiltersCount > 0 ? BaseTheme.palette.blue[700] : undefined,
          }}
        >
          {translate('Sort & Filter')}
        </Button>
      </FilterBadge>
      <FilterDrawer
        open={filterOpen}
        onClose={() => {
          setFilterOpen(false)
        }}
        isTableView={viewSettings?.isTableView}
        {...props}
      />
      <SettingsDrawer
        open={settingsOpen}
        onClose={() => {
          setSettingsOpen(false)
        }}
        {...viewSettings}
      />
    </>
  )
}

export default SettingsButtons
