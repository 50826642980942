import Typography from '@material-ui/core/Typography'
import { styled, Grid } from 'opensolar-ui'
import { ComponentPlaceHolderImage } from 'pages/ordering/catalogv3/ProductTile'
import PercentageDiscountTag from 'pages/ordering/elements/PercentageDiscountTag'
import { format_percentage_value } from 'util/misc'
import ProductPrice from '../detail/content/price/ProductPrice'
import { DistributorDataTypeV2 } from '../../../elements/hardwareSelectorV2/types'
import { useState } from 'react'

export const Wrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-evenly',
  backgroundColor: 'white',
  padding: 20,
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
  borderBottomLeftRadius: 8,
  borderBottomRightRadius: 8,
})

const ImageWrapper = styled('div')({
  position: 'relative',
  width: '60%',
  minHeight: '302px',
  display: 'flex',
  alignContent: 'center',
  justifyContent: 'center',
  flex: '1',
})

const DiscountWrapper = styled('div')({
  position: 'absolute',
  top: '10%',
  left: '25%',
  zIndex: 10,
})

const ProductImage = styled('img')({
  width: '202px',
  height: '302px',
  objectFit: 'contain',
  borderRadius: '8px',
})

const LogoImage = styled('img')<{ hide: boolean }>(({ hide }) => ({
  height: 40,
  width: 80,
  objectFit: 'contain',
  opacity: hide ? 0 : 1
}))

const ProductCard = ({
  selectedDistributorData,
  manufacturerUrl,
  outputPower,
}: {
  selectedDistributorData?: DistributorDataTypeV2 | null
  manufacturerUrl?: string
  outputPower: number
}) => {
  const imageUrl = selectedDistributorData?.image_url
  const shorDescription = selectedDistributorData?.short_description
  const code = selectedDistributorData?.code
  const price = selectedDistributorData?.price_detail.price_ex_vat
  const minimum_quantity = selectedDistributorData?.price_detail.minimum_quantity
  const discountPercentValue = selectedDistributorData?.price_detail.discount_value

  const [manufacturerUrlSrc, setManufacturerUrlSource] = useState<string | undefined>(manufacturerUrl)
  const [productImage, setProductImage] = useState<string | undefined>(imageUrl)

  return (
    <Wrapper>
      <Grid container spacing={2}>
        <Grid container item xs={6} justify="center" alignItems="center">
          <ImageWrapper>
            {productImage ? (
              <>
                {discountPercentValue && (
                  <DiscountWrapper>
                    <PercentageDiscountTag
                      showUpTo={false}
                      percentage={format_percentage_value(discountPercentValue)}
                    />
                  </DiscountWrapper>
                )}
                <ProductImage src={productImage} alt="Product" onError={() => setProductImage(undefined)} />
              </>
            ) : (
              <ComponentPlaceHolderImage />
            )}
          </ImageWrapper>
        </Grid>

        <Grid item xs={6} spacing={2}>
          <Grid container direction="column">
            <Grid item>
              <LogoImage
                src={manufacturerUrlSrc}
                onError={() => setManufacturerUrlSource(undefined)}
                hide={!manufacturerUrlSrc}
              />
            </Grid>

            <Grid item>
              {shorDescription && (
                <Typography variant="h6" gutterBottom>
                  {shorDescription}
                </Typography>
              )}
            </Grid>

            <Grid item>
              <Typography variant="subtitle1">{code}</Typography>
            </Grid>

            <Grid item>
              <ProductPrice
                pricePerItem={price}
                discountSavingPerItem={discountPercentValue}
                outputPower={outputPower}
                quantityPerItem={minimum_quantity}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Wrapper>
  )
}

export default ProductCard
