import { ComponentVersions_3_0 } from 'constants/uxVersions'
import { useUxVersion } from 'hooks/useUxVersion'
import {
  AlertTriangleOutlineIcon,
  BaseTheme,
  Button,
  ComponentVersionsInherit,
  OsButtonProps,
  styled,
} from 'opensolar-ui'
import Confirm from 'projectSections/elements/dialog/Confirm'
import { useTranslate } from 'ra-core'
import React from 'react'
import Notice from './Notice'

//Common Confirm modal using UI3 dialog format/styling
interface DialogProps {
  open: boolean
  setOpen: (open: boolean) => void
  handleSubmit: (e) => void
  title?: string
  mainText?: string
  subText?: string
  submitBtnLabel?: string
  submitBtnProps?: OsButtonProps
}
const CancelButton = styled(Button, { name: 'CancelButton' })({
  marginRight: 5,
})

const ConfirmModal: React.FC<DialogProps> = (props) => {
  const ui3Enabled = useUxVersion() === 3
  if (ui3Enabled)
    return (
      <ComponentVersionsInherit versions={ComponentVersions_3_0}>
        <ConfirmModalContent {...props} />
      </ComponentVersionsInherit>
    )
  return <ConfirmModalContent {...props} />
}

const ConfirmModalContent: React.FC<DialogProps> = ({
  open,
  setOpen,
  handleSubmit,
  title = '',
  mainText = '',
  subText = '',
  submitBtnLabel = '',
  submitBtnProps,
}) => {
  const translate = useTranslate()
  if (!open) return null
  return (
    <Confirm
      ui3={true}
      header={{ title }}
      content={
        <Notice
          icon={<AlertTriangleOutlineIcon size={23} color={BaseTheme.palette.grey[810]} />}
          mainText={mainText}
          subText={subText}
        />
      }
      open={open}
      buttons={[
        () => (
          <CancelButton
            id="confirm-modal-cancel-button"
            variant="outlined"
            color="default"
            onClick={() => {
              setOpen(false)
            }}
          >
            {translate('Cancel')}
          </CancelButton>
        ),
        () => (
          <Button id="confirm-modal-accept-button" onClick={handleSubmit} {...submitBtnProps}>
            {submitBtnLabel}
          </Button>
        ),
      ]}
    />
  )
}

export default ConfirmModal
