import { FormControl, FormHelperText, Grid, MenuItem, Select } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { orgSelectors } from 'ducks/orgs'
import Alert from 'elements/Alert'
import Button from 'elements/button/Button'
import InfoTooltip from 'elements/tooltip/InfoTooltip'
import { styled } from 'opensolar-ui'
import ProjectTablePresenter from 'pages/ordering/projectTable/projectTablePresenter'
import useProjectTable from 'pages/ordering/projectTable/useProjectTable'
import { HardwareSupplierFilterKeyType, SystemDesignType } from 'pages/ordering/type'
import { useSortedProjectErrors } from 'projectSections/errors/useSortedProjectErrors'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useField, useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { OpenSolarThemeType } from 'Themes'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { RootState } from 'types/state'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  orderingButtonsContainer: {
    padding: '10px 0px 5px',
  },
  iconTitleWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  headingTitle: {
    margin: '0 10px',
  },
  selectSystemDropdown: {
    height: '30px',
  },
  orderBtn: {
    marginTop: '10px',
  },
  supplierLogoContainer: {
    textAlign: 'center',
    maxHeight: 60,
  },
  supplierLogo: {
    height: '100%',
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))

const SimplifiedOrderButton = styled(Button, { name: 'SimplifiedOrderButton' })(({ theme }) => ({
  background: `${theme.palette.blue[600]} !important`,
  color: `white !important`,
}))

type AlertInfo = {
  message: string
  level: 'warning' | 'error'
}

const OrderPromoButtons = ({
  supplierName,
  supplierLogo,
  projectId,
  systems,
  simplified,
  onChangeSelectedDistributor,
  managePageSupplier,
}: {
  supplierName: string
  supplierLogo?: string
  projectId: number
  systems: SystemDesignType[]
  simplified?: boolean
  onChangeSelectedDistributor?: () => void
  managePageSupplier?: HardwareSupplierFilterKeyType
}) => {
  const classes = useStyles()
  const history = useHistory()
  const selectedDistributorKey = useSelector(orgSelectors.getSelectedHardwareSupplierFilterKey)
  const [selectedSystemToOrder, setSelectedSystemToOrder] = useState<number>(systems[0].id)
  const [projectTableStore, projectTablePresenter] = useProjectTable()
  const recentProjects = useSelector((state: RootState) => state.recentProjects)
  /**
   *
   * The check for LAST_CALC_ERROR and lastCalc is no longer necessary, these keys and sources will be deprecated on the 1st of March 2025
   *
   */
  const calcErrors = useSortedProjectErrors([{ keys: ['LAST_CALC_ERROR'], sources: ['lastCalc', 'calculations'] }])
  const form = useForm()
  const [requestedPoll, setRequestedPoll] = useState()
  const dirtyFields = form.mutators.getFormDirtyFields()
  const systemsReady = !useField('simulate_first_year_only', {
    subscription: { value: true },
  }).input.value
  const disabled = !systemsReady || calcErrors.length > 0

  useEffect(() => {
    logAmplitudeEvent('generic_element_viewed', {
      source: 'manage_page',
      context: 'hardware_order_promo_buttons',
    })
  }, [])

  /**
   * The checks for project readiness are identical to the checks in the PDF proposal.
   */
  const getDisabledWarning = ({ projectId, dirtyFields, systemsReady, calcErrors }): AlertInfo | null => {
    if (dirtyFields.length > 0) {
      return {
        message:
          'You have unsaved changes. If you have made any recent changes to the bill of materials, please save changes before ordering to ensure the latest bill of materials is used.',
        level: 'warning',
      }
    } else if (!systemsReady) {
      if (requestedPoll !== projectId && window.WorkspaceHelper.checkLastCalcStatus(projectId)) {
        setRequestedPoll(projectId)
      }
      return {
        message:
          'Calculations in progress. If you have made any recent changes to the bill of materials, please wait for calculations to complete before ordering to ensure the latest bill of materials is used.',
        level: 'warning',
      }
    } else if (calcErrors?.length > 0) {
      return {
        message:
          'Calculation error detected for this project. If you have made any recent changes to the bill of materials, please ensure calculations complete successfully before ordering to ensure the latest bill of materials is used.',
        level: 'error',
      }
    }
    return null
  }

  const disabledWarning = useMemo<AlertInfo | null>(
    () => getDisabledWarning({ projectId, dirtyFields, systemsReady, calcErrors }),
    [projectId, dirtyFields, systemsReady, calcErrors]
  )

  const handleClick = useCallback(
    (selectedSystemToOrder: number) => {
      if (selectedDistributorKey === undefined) {
        // TODO: handle case where hardware supplier not selected
        return
      }
      onChangeSelectedDistributor?.()
      const defaultFilter = {
        filterValues: {
          fieldset: 'hardware_ordering',
          filter: recentProjects.map((recentProject) => recentProject.id).join(','),
        },
        displayedFilters: { filter: true },
      }
      projectTablePresenter.populateLineItemsBySystemId({
        selectedSupplierKey: managePageSupplier || selectedDistributorKey,
        projectId,
        systemId: selectedSystemToOrder,
        systemsDesign: systems,
      })
      logAmplitudeEvent('hardware_order_promo_button_clicked', {
        source: 'manage_page',
      })
      history.push({
        pathname: '/shop/order_by_project',
        state: {
          defaultFilter,
        },
      })
    },
    [projectId, systems, onChangeSelectedDistributor]
  )

  if (simplified) {
    return (
      <div className={classes.btnContainer}>
        {disabledWarning && (
          <InfoTooltip
            title={<Alert severity={disabledWarning.level}>{disabledWarning.message}</Alert>}
            severity={disabledWarning.level}
            placement="right"
            style={{ marginRight: 5 }}
          />
        )}
        <SimplifiedOrderButton size="medium" onClick={() => handleClick(selectedSystemToOrder)} disabled={false}>
          Order Hardware
        </SimplifiedOrderButton>
      </div>
    )
  }

  return (
    <Grid container spacing={1} className={classes.orderingButtonsContainer}>
      <Grid item xs={12} className={classes.supplierLogoContainer}>
        <img className={classes.supplierLogo} alt={`${supplierName} logo`} src={supplierLogo} />
      </Grid>
      <Grid item xs={12}>
        <div className={classes.iconTitleWrapper}>
          <img alt="Panels" src={window.PUBLIC_URL + '/images/panels.svg'} />
          <span className={classes.headingTitle}>Shop</span>
        </div>
      </Grid>
      <Grid item xs={12}>
        <FormControl fullWidth>
          <FormHelperText id="select-system-for-order">System</FormHelperText>
          <Select
            id="select-system-for-order"
            variant="outlined"
            fullWidth
            className={classes.selectSystemDropdown}
            defaultValue={systems[0].id}
            onChange={(e) => {
              if (typeof e.target.value === 'number') {
                setSelectedSystemToOrder(e.target.value)
              } else {
                console.error('Type of target value is not a number')
              }
            }}
          >
            {systems.map((system: SystemDesignType) => {
              const systemName = ProjectTablePresenter.getSystemDisplayName(system)
              return (
                <MenuItem value={system.id} key={systemName}>
                  {systemName}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </Grid>
      {disabledWarning && <Alert severity={disabledWarning.level}>{disabledWarning.message}</Alert>}
      <Grid item xs={12} className={classes.orderBtn}>
        <Button fullWidth={true} color="primary" onClick={() => handleClick(selectedSystemToOrder)} disabled={false}>
          Shop
        </Button>
      </Grid>
    </Grid>
  )
}

export default memo(OrderPromoButtons)
