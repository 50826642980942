import StageStepper, { StageBtnState } from 'elements/stageStepper/StageStepper'
import React, { useMemo } from 'react'
import { WorkflowType } from 'types/workflows'
import { urlToId } from 'util/misc'

interface PropTypes {
  workflow?: WorkflowType
  activeStageId?: number
  handleChange?: (workflow) => void
  disabled?: boolean
}
const getStageState = (index, activeIndex) => {
  if (index === activeIndex) return StageBtnState.active
  if (index < activeIndex) return StageBtnState.past
  if (index > activeIndex) return StageBtnState.future
}

const WorkflowStages: React.FC<PropTypes> = ({ workflow, activeStageId, disabled, handleChange }) => {
  const activeStage = useMemo(() => workflow?.workflow_stages?.find((x) => x.id === activeStageId), [
    activeStageId,
    workflow?.workflow_stages,
  ])
  const otherStageSelected = useMemo(() => activeStage?.milestone && activeStage?.milestone > 3, [activeStage])

  const stages = useMemo(() => {
    const workflowStages = workflow?.workflow_stages
      .filter((x) => !x.is_archived || x.id === activeStageId)
      .sort((a, b) => a.order - b.order)
    if (workflowStages && activeStage) {
      const activeStageIndex = workflowStages?.indexOf(activeStage)
      return workflowStages?.map((x, i) => ({
        ...x,
        status: otherStageSelected ? StageBtnState.future : getStageState(i, activeStageIndex),
      }))
    }
  }, [workflow, activeStage])
  const standardStages = useMemo(() => stages?.filter((x) => x.milestone < 4), [stages])
  const otherStages = useMemo(() => stages?.filter((x) => x.milestone > 3), [stages])
  const handleStageChange = (newStageId) => {
    if (handleChange && !disabled)
      handleChange({ workflow_id: workflow?.id, active_stage_id: newStageId, org_id: urlToId(workflow?.org) })
  }

  if (!!!stages?.length || !workflow) return null
  return (
    <StageStepper
      activeStageId={activeStageId}
      handleChange={handleStageChange}
      stages={standardStages || []}
      otherStages={otherStages}
    />
  )
}

export default WorkflowStages
