import { ComponentVersions } from 'opensolar-ui'

/*
Once a version goes live/public, it should be 'frozen'.
This prevents visual regressions in existing views.
*/

export const ComponentVersions_2_1: ComponentVersions = { button: 2 }

export const ComponentVersions_3_0: ComponentVersions = {
  button: 3,
  chip: 2,
  icon_button: 2,
  switch: 2,
  checkbox: 2,
  radio: 2,
  alert: 2,
  table: 2,
  dialog: 2,
  select: 2,
  text_field: 2,
  field_title: 2,
  date_picker: 2,
  date_time_picker: 2,
  tooltip: 2,
}
