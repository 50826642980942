import { Divider } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { StyledDialog, StyledDialogActions, StyledDialogContent, StyledDialogTitle } from 'layout/StyledDialog'
import { Button } from 'opensolar-ui'
import useUpdateTransfer from 'pages/inventory/transfer/hooks/useUpdateTransfer'
import Alert from 'pages/ordering/checkoutv3/preOrder/components/Alert'
import { DISTRBUTOR_KEY_TO_NAME_MAPPING } from 'pages/ordering/checkoutv3/preOrder/constants'
import { refreshView, useTranslate } from 'react-admin'
import { Form } from 'react-final-form'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useFeatureFlag } from 'util/split'
import { ORDER_STATUS } from '../constants'
import InventoryTransferFormCore from '../InventoryTransferFormCore'
import type { Transfer, TransferFormType } from '../type'
import AcceptHardwareItems from './AcceptHardwareItems'
import OrderDistributor from './OrderDistributor'

const EditPurchaseOrderDialog = ({
  isOpen,
  onClose,
  transferType,
  initialValues,
}: {
  isOpen: boolean
  onClose(): void
  transferType: TransferFormType
  initialValues: Transfer
}) => {
  const { updateInventoryTransfer } = useUpdateTransfer()
  const dispatch = useDispatch()
  const translate = useTranslate()
  const history = useHistory()
  const enableMultiDistributorShop = useFeatureFlag('enable_multi_distributor_shop', 'on')

  const acceptTransfer = async (value, form) => {
    const newLineItems = value?.line_items_new
    const lineItems = newLineItems ? value.line_items.concat(newLineItems) : value.line_items
    const dirtyFields = Object.keys(form.getState().dirtyFields)
    const diffData = {}
    dirtyFields.forEach((key) => {
      diffData[key] = value[key]
    })
    if (diffData['is_linked_project'] === 'no') {
      diffData['project_id'] = null
      diffData['projects'] = []
      diffData['projects_data'] = []
    }
    await updateInventoryTransfer({
      transferId: initialValues.id,
      line_items_new: lineItems,
      ...diffData,
    })
    dispatch(refreshView())
    onClose()
  }
  const isAutoOrder = !!initialValues?.order_id

  const handleResendOrder = () => {
    onClose()
    history.push({ pathname: `/checkout`, state: { orderId: initialValues?.order_id } })
  }

  return (
    <Form
      initialValues={initialValues}
      onSubmit={acceptTransfer}
      render={({ handleSubmit, form }) => (
        <StyledDialog maxWidth="lg" open={isOpen} onClose={onClose}>
          <StyledDialogTitle>Edit purchase order</StyledDialogTitle>
          <Divider light />
          <StyledDialogContent style={{ padding: 20 }}>
            {enableMultiDistributorShop && initialValues?.status === ORDER_STATUS.DRAFT && (
              <Alert
                title={translate(
                  `Hmm, something went wrong with your order and we were not able to process your order with ${
                    DISTRBUTOR_KEY_TO_NAME_MAPPING[initialValues?.distributor || '']
                  }. Please resend your order.`
                )}
                severity="error"
                onSubmit={handleResendOrder}
                onSubmitLabel={translate('Resend Order')}
                fontWeight="normal"
              />
            )}
            <OrderDistributor />
            <InventoryTransferFormCore transferType={transferType} intention={'edit_po'} />
            <AcceptHardwareItems
              newItemSource={'line_items_new'}
              selectedItemSource={'selected_line_items'}
              recordedItemSource={'line_items'}
              disabled={true}
              transferType={transferType}
              intention={'edit_po'}
              isAutoOrder={isAutoOrder}
            />
          </StyledDialogContent>
          <StyledDialogActions>
            <Button variant="contained" color="default" onClick={onClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={false}
              color="primary"
              onClick={(event) => {
                handleSubmit(event)
                logAmplitudeEvent('generic_button_clicked', {
                  source: 'save',
                  context: 'edit_incoming_purchase_order_dialog',
                })
              }}
            >
              Save
            </Button>
          </StyledDialogActions>
        </StyledDialog>
      )}
    />
  )
}

export default EditPurchaseOrderDialog
