import { useTranslate } from 'ra-core'

import React, { useMemo } from 'react'

import { FilesContextWrapper } from './files/common/FilesContextWrapper'
import { FilesSection } from './files/section/FilesSection'

import { FileOutlineIcon, styled, SwapHorizIcon, ToggleButton, ToggleButtonGroup } from 'opensolar-ui'

import { useState } from 'react'

//sarai SwapHorizIcon

import { permissionsSelectors } from 'ducks/permissions'
import { useSelector } from 'react-redux'
import { ToolBar } from './FilesAndTransactionsToolBar'
import { TransactionsSection } from './transactions/TransactionsSection'

export const StyledCard = styled('div', { name: 'StyledCard' })(({ theme }) => ({
  maxHeight: 400,
  display: 'flex',
  minWidth: 'calc(50% - 15px)', // set so it takes at least half of container if there's no ad
  flex: 3, // set so it expands to 3/4 of container if there is an add
  flexDirection: 'column',
  borderRadius: 8,
  backgroundColor: theme.palette.background.paper,
  boxSizing: 'border-box',
  width: '40%',
  flexGrow: 1,
  '& > nav': {
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: theme.mixins.borderStandard.border,
    flexWrap: 'wrap', // so buttons fit on screen (this section has a search bar and other sections don't)
    gap: 10, //so rows have a gap if wrapped
  },
  '& > main': {
    overflow: 'auto',
    overflowX: 'hidden',
    marginBottom: 15,
    display: 'grid',
    height: '100%',
    flex: 1,
  },
  '& > main > section': {
    margin: '15px 10px 0px', // set to accomodate for react-admin list/tinny box padding which is '5px 10px 0px 10px' so totals 20px
    display: 'grid',
    alignSelf: 'start',
  },
}))

export const StyledIconContainer = styled('div')(({ theme }) => ({
  marginRight: 5,
  height: 10,
  display: 'flex',
  alignItems: 'center',
}))

export type FilesAndTransactionsTab = 'documents' | 'transactions'

const FilesAndTransactionsSection: React.FC = () => {
  const translate = useTranslate()
  const [tabSelected, setTabSelected] = useState<FilesAndTransactionsTab>('documents')
  const [openNewTransactionDialog, setOpenNewTransactionDialog] = useState(false)
  const [showFilesSearchField, setShowFilesSearchField] = useState(false)
  const [filesSearchTerm, setFilesSearchTerm] = useState('')
  const allowViewTransactions = useSelector(permissionsSelectors.getProjectPermissionByKey('info_transactions'))
    .allowView
  const allowViewDocuments = useSelector(permissionsSelectors.getProjectPermissionByKey('info_documents')).allowView

  useMemo(() => {
    if (!allowViewDocuments && allowViewTransactions) setTabSelected('transactions')
  }, [allowViewDocuments, allowViewTransactions])

  if (!allowViewDocuments && !allowViewTransactions) {
    return null
  }

  return (
    <FilesContextWrapper>
      <StyledCard id={'Project-Docs-Section'}>
        <nav>
          <ToggleButtonGroup exclusive value={tabSelected}>
            {allowViewDocuments && (
              <ToggleButton value="documents" onClick={() => setTabSelected('documents')}>
                <StyledIconContainer>
                  <FileOutlineIcon variant={2} />
                </StyledIconContainer>
                {translate('Documents')}
              </ToggleButton>
            )}
            {allowViewTransactions && (
              <ToggleButton value="transactions" onClick={() => setTabSelected('transactions')}>
                <StyledIconContainer>
                  <SwapHorizIcon />
                </StyledIconContainer>
                {translate('Transactions')}
              </ToggleButton>
            )}
          </ToggleButtonGroup>
          <ToolBar
            tabSelected={tabSelected}
            setTabSelected={setTabSelected}
            setOpenNewTransactionDialog={setOpenNewTransactionDialog}
            showFilesSearchField={showFilesSearchField}
            setShowFilesSearchField={setShowFilesSearchField}
            filesSearchTerm={filesSearchTerm}
            setFilesSearchTerm={setFilesSearchTerm}
          />
        </nav>
        <main>
          <section>{tabSelected === 'documents' && <FilesSection filesSearchTerm={filesSearchTerm} />}</section>
          <section>
            {tabSelected === 'transactions' && (
              <TransactionsSection
                openNewTransactionDialog={openNewTransactionDialog}
                setOpenNewTransactionDialog={setOpenNewTransactionDialog}
              />
            )}
          </section>
        </main>
      </StyledCard>
    </FilesContextWrapper>
  )
}

export default FilesAndTransactionsSection
