import OrderLineItem from 'pages/ordering/OrderLineItem'
import { BomLineItemsHashMap } from 'projectSections/sections/design/sideDrawer/bom/useMapHardwareDetailsToBomLineItems'
import { useMemo } from 'react'
import { isMountingComponent } from 'util/misc'

export const useMapOrderLineItemsToHashMap = (orderLineItems: OrderLineItem[]) => {
  return useMemo(() => {
    const hashMapResult = {
      module: [],
      mounting: [],
      inverter: [],
      battery: [],
      other: [],
      all: [],
    } as BomLineItemsHashMap

    orderLineItems.forEach((lineItem) => {
      if (isMountingComponent(lineItem?.componentTypeV2)) {
        hashMapResult['mounting'].push(lineItem)
        return
      }

      if (lineItem.componentType && lineItem.quantity > 0) {
        if (!hashMapResult[lineItem.componentType]) {
          hashMapResult[lineItem.componentType] = []
        }

        hashMapResult[lineItem.componentType].push(lineItem)
        hashMapResult['all'].push(lineItem)
      }
    })

    return hashMapResult
  }, [orderLineItems])
}
