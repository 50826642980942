import { compare } from 'compare-versions'
import { FilterCondition } from 'conditions/types'
import { useAddRemoveStructs } from 'contexts/structs/hooks/useAddRemoveStructs'
import lodash from 'lodash'
import { Logger } from 'opensolar-sdk'
import { useTranslate } from 'ra-core'
import { useEffect } from 'react'
import { OpenSolarVersionInfo, useOpenSolarVersionStructs } from './useOpenSolarVersionStructs'
import {
  PromoDialog,
  PromoDialogActionRow,
  PromoDialogCountdown,
  PromoDialogGap,
  PromoDialogMedia,
  PromoDialogTypography,
} from './usePromoDialogStructs'

const logger = new Logger('OS.ReleasePromos')

const type = 'promo-dialog'

/**
 * Takes 'opensolar-version' structs and spawns the relevant promo dialogs.
 */
export const useReleasePromos = () => {
  const { structs } = useOpenSolarVersionStructs()
  const { add, removeByKey } = useAddRemoveStructs()
  const translate = useTranslate()

  const removeAllByKeys = (keys: string[]) => {
    for (const key of keys) {
      removeByKey(type, key)
    }
  }

  useEffect(() => {
    const currentSpaVersion = window.SPA_VERSION

    if (!currentSpaVersion) {
      logger.error(
        'SPA_VERSION is not defined, will not add release notifications (set `ENV_APP_SPA_VERSION` in LS to test)'
      )
      return
    }
    for (const struct of structs) {
      const osVersion: OpenSolarVersionInfo = struct.data
      const baseKey = `release-promo-${osVersion.version}-`
      const key_warning2hr = `${baseKey}warning-2hr`
      const key_warning5m = `${baseKey}warning-5m`
      const key_progress = `${baseKey}progress`
      const key_progress_customer = `${baseKey}progress-customer`
      const key_success = `${baseKey}success`
      const key_success_lite = `${baseKey}success-lite`
      const subs = { newVersion: osVersion.version }

      const highlights = osVersion.release_highlights || []

      if (compare(currentSpaVersion, osVersion.version_spa, '>')) {
        // This version is older than the current version, ignore it
        removeAllByKeys([
          key_warning2hr,
          key_warning5m,
          key_progress,
          key_progress_customer,
          key_success,
          key_success_lite,
        ])
        continue
      }
      ReleaseNotesButton.actions[0].href = osVersion.release_notes_url

      // Add success promo
      if (osVersion.show_promo_success) {
        MatchVersionCondition.op = 'eq'
        MatchVersionCondition.value = osVersion.version_spa
        TitleEl.text = translate(SuccessTitle, subs)
        BodyEl1.text = SuccessMessage1
        const extra = osVersion.extra_content_success || []
        if (osVersion.hide_def_content_success) {
          SuccessPromo.elements = [...highlights, ...extra]
        } else {
          if (osVersion.release_notes_url) {
            BodyEl2.text = SuccessMessage2
            SuccessPromo.elements = [TitleEl, ImageEl, BodyEl1, BodyEl2, ...highlights, ...extra, ReleaseNotesButton]
          } else {
            SuccessPromo.elements = [TitleEl, ImageEl, BodyEl1, ...highlights, ...extra]
          }
        }
        let promo = lodash.cloneDeep(SuccessPromo)
        add({ key: key_success, type, data: promo }, { update: true })
      } else {
        removeAllByKeys([key_success])
      }

      // Add Lite success promo
      if (osVersion.show_promo_success_lite) {
        LiteCondition.value = true
        MatchVersionCondition.op = 'eq'
        MatchVersionCondition.value = osVersion.version_spa
        TitleEl.text = translate(LiteSuccessTitle, subs)
        BodyEl1.text = LiteMessage1
        const extra = osVersion.extra_content_success_lite || []
        if (osVersion.hide_def_content_success_lite) {
          SuccessPromo.elements = [...highlights, ...extra]
        } else {
          if (osVersion.release_notes_url) {
            BodyEl2.text = LiteMessage2
            SuccessPromo.elements = [TitleEl, ImageEl, BodyEl1, BodyEl2, ...highlights, ...extra, ReleaseNotesButton]
          } else {
            SuccessPromo.elements = [TitleEl, ImageEl, BodyEl1, ...highlights, ...extra]
          }
        }
        let promo = lodash.cloneDeep(SuccessPromo)
        add({ key: key_success_lite, type, data: promo }, { update: true })
        LiteCondition.value = false
      } else {
        removeAllByKeys([key_success_lite])
      }

      if (compare(currentSpaVersion, osVersion.version_spa, '=')) {
        // This version is the current version, no need to add progress / warnings
        removeAllByKeys([key_warning2hr, key_warning5m, key_progress, key_progress_customer])
        continue
      }

      // Everything subsequent should only match old versions
      MatchVersionCondition.op = 'lt'
      MatchVersionCondition.value = osVersion.version_spa
      const releaseTime = new Date(osVersion.release_time).getTime() / 1000 // must be in seconds not ms

      // Set up warning promos
      TitleEl.text = translate(WarningTitle, subs)
      BodyEl1.text = WarningMessage1
      ReleaseCountdown.countdown_time = releaseTime
      ReleaseCountdown.alert_severity = 'info'
      ReleaseCountdown.countdown_msg = ReleaseCountdown.countdown_overtime_msg = WarningCountdownMessage

      // Add warning 2hr promo
      if (osVersion.show_promo_warning_2hr && !osVersion.release_complete) {
        const extra = osVersion.extra_content_warning_2h || []
        if (osVersion.hide_def_content_warning_2hr) {
          WarningPromo.elements = [...highlights, ...extra]
        } else {
          WarningPromo.elements = [TitleEl, ImageEl, BodyEl1, ...highlights, ...extra, ReleaseCountdown]
        }
        StartTimingCondition.value = releaseTime - 2 * 60 * 60
        EndTimingCondition.value = releaseTime - (osVersion.show_promo_warning_5m ? 5 * 60 : 0)
        let promo = lodash.cloneDeep(WarningPromo)
        add({ key: key_warning2hr, type, data: promo }, { update: true })
      } else {
        removeAllByKeys([key_warning2hr])
      }

      // Add warning 5m promo
      if (osVersion.show_promo_warning_5m && !osVersion.release_complete) {
        const extra = osVersion.extra_content_warning_5m || []
        if (osVersion.hide_def_content_warning_5m) {
          WarningPromo.elements = [...highlights, ...extra]
        } else {
          WarningPromo.elements = [TitleEl, ImageEl, BodyEl1, ...highlights, ...extra, ReleaseCountdown]
        }
        StartTimingCondition.value = releaseTime - 5 * 60
        EndTimingCondition.value = releaseTime
        let promo = lodash.cloneDeep(WarningPromo)
        add({ key: key_warning5m, type, data: promo }, { update: true })
      } else {
        removeAllByKeys([key_warning5m])
      }

      // Add progress / overtime promo (pro facing)
      if (osVersion.show_promo_progress) {
        TitleEl.text = translate(ProgressTitle, subs)
        BodyEl1.text = ProgressMessage1
        const extra = osVersion.extra_content_progress || []
        ReleaseCountdown.countdown_time = releaseTime + osVersion.release_duration_mins * 60
        if (osVersion.release_complete) {
          ReleaseCountdown.alert_severity = 'success'
          ReleaseCountdown.countdown_msg = ReleaseCountdown.countdown_overtime_msg = ProgressCompleteMessage
          if (osVersion.hide_def_content_progress) {
            ProgressPromo.elements = [...highlights, ...extra]
          } else {
            ProgressPromo.elements = [TitleEl, ImageEl, BodyEl1, ...highlights, ...extra, ReleaseCountdown]
          }
        } else {
          ReleaseCountdown.alert_severity = 'warning'
          ReleaseCountdown.countdown_msg = ProgressCountdownMessage
          ReleaseCountdown.countdown_overtime_msg = ProgressOvertimeMessage
          BodyEl2.text = osVersion.release_notes_url ? ProgressMessage2 : ProgressMessage3
          if (osVersion.hide_def_content_progress) {
            ProgressPromo.elements = [...highlights, ...extra]
          } else {
            ProgressPromo.elements = [
              TitleEl,
              ImageEl,
              BodyEl1,
              ...highlights,
              ...extra,
              ReleaseCountdown,
              BodyEl2,
              GapSmall,
            ]
          }

          if (osVersion.release_notes_url) {
            ProgressPromo.elements.push(ReleaseNotesButton)
          }
        }
        StartTimingCondition.value = releaseTime
        let promo = lodash.cloneDeep(ProgressPromo)
        add({ key: key_progress, type, data: promo }, { update: true })

        // Add customer facing promo
        IsProCondition.value = false
        TitleEl.text = ProgressCustomerTitle
        if (!osVersion.release_complete) {
          // Remove the release notes for customers
          if (osVersion.hide_def_content_progress) {
            ProgressPromo.elements = [...extra]
          } else {
            ProgressPromo.elements = [TitleEl, ImageEl, BodyEl1, ...extra, ReleaseCountdown, GapSmall]
          }
        }
        let promo2 = lodash.cloneDeep(ProgressPromo)
        add({ key: key_progress_customer, type, data: promo2 }, { update: true })

        // reset IsProCondition for all other promos
        IsProCondition.value = true
      } else {
        removeAllByKeys([key_progress, key_progress_customer])
      }
    }
  }, [structs])
}

//// Element templates

let GapSmall: PromoDialogGap = {
  type: 'gap',
  gap_size: 1,
}
let TitleEl: PromoDialogTypography = {
  type: 'h4',
  text: '',
}
let BodyEl1: PromoDialogTypography = {
  type: 'body1',
  text: '',
}
let BodyEl2: PromoDialogTypography = {
  type: 'body1',
  text: '',
}
let ImageEl: PromoDialogMedia = {
  type: 'image',
  media_url: 'https://content.opensolar.com/images/update-success.png',
  media_height: 300,
}
let ReleaseNotesButton: PromoDialogActionRow = {
  type: 'action-row',
  actions: [
    {
      label: 'View Release Notes',
      href: '',
    },
  ],
}
let ReleaseCountdown: PromoDialogCountdown = {
  type: 'countdown',
  alert_severity: 'info',
  countdown_time: 0,
  countdown_msg: '',
  countdown_overtime_msg: '',
  rounding: 'up',
}

let MatchVersionCondition: FilterCondition = {
  path: 'runtime.spa_version',
  op: 'in',
  value: ['3.0.0'],
}

//// Condition templates

let IsProCondition: FilterCondition = {
  path: 'auth.is_pro',
  op: 'eq',
  value: true,
}

let LiteCondition: FilterCondition = {
  path: 'role.user_data.is_lite',
  op: 'eq',
  value: false,
}

let StartTimingCondition: FilterCondition = {
  path: 'timing.now',
  op: 'gt',
  value: 0,
}

let EndTimingCondition: FilterCondition = {
  path: 'timing.now',
  op: 'lt',
  value: 0,
}

//// Promo templates

let WarningPromo: PromoDialog = {
  show_again_rule: 'always',
  type: 'standard',
  priority: 20,
  conditions: [
    {
      conditions: [StartTimingCondition, EndTimingCondition, IsProCondition, LiteCondition, MatchVersionCondition],
    },
  ],
  elements: [],
}

let ProgressPromo: PromoDialog = {
  show_again_rule: 'always',
  type: 'blocker',
  priority: 20,
  conditions: [
    {
      conditions: [StartTimingCondition, IsProCondition, MatchVersionCondition],
    },
  ],
  elements: [],
}

let SuccessPromo: PromoDialog = {
  show_again_rule: 'never',
  type: 'standard',
  priority: 20,
  conditions: [
    {
      conditions: [
        IsProCondition,
        LiteCondition,
        {
          path: 'auth.account_age_days',
          op: 'gt',
          value: 3,
        },
        MatchVersionCondition,
      ],
    },
  ],
  elements: [],
}

/// Standard Messages
const SuccessTitle = 'OS %{newVersion} Product Release'
const SuccessMessage1 = 'OpenSolar just got even better with our latest release.'
const SuccessMessage2 = 'Check out the release notes and take advantage of new and improved functionality.'

const LiteSuccessTitle = 'OS Lite %{newVersion} Product Release'
const LiteMessage1 = 'OpenSolar Lite just got better with the latest release.'
const LiteMessage2 = 'Check out the release notes and take advantage of new and improved functionality.'

const WarningTitle = 'OS %{newVersion} Product Update Scheduled'
const WarningMessage1 =
  'OpenSolar is getting even better with our latest release. Please save your changes before the update starts.'
const WarningCountdownMessage = 'Expected %{timeRemaining} remaining before update starts.'

const ProgressTitle = 'OS %{newVersion} Product Update in Progress'
const ProgressMessage1 = 'OpenSolar is getting even better with our latest release.'
const ProgressCompleteMessage = 'Update complete, please reload the page.'
const ProgressCountdownMessage = 'Expected %{timeRemaining} remaining until update complete.'
const ProgressOvertimeMessage = 'Update is taking longer than expected, sorry for the inconvenience.'
const ProgressMessage2 = 'Check out the release notes while you wait.'
const ProgressMessage3 = 'Please wait until the update is complete.'

const ProgressCustomerTitle = 'Update in Progress'
