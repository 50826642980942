import { styled } from 'opensolar-ui'
import React, { useMemo, useState } from 'react'

const UserIconImg = styled('img', { name: 'UserIconImg' })<{
  size: number
}>(({ size }) => ({
  outline: '1px solid white',
  borderRadius: '50%',
  height: size,
  width: size,
  objectFit: 'cover',
}))

const Circle = styled('div', { name: 'Circle' })<{
  isExternal: boolean
}>(({ isExternal, theme }) => ({
  width: 16,
  height: 16,
  padding: 6,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontWeight: 500,
  borderRadius: 50,
  outline: '1px solid white',
  ...(isExternal
    ? { color: theme.palette.success.contrastText, backgroundColor: theme.palette.success.main, marginLeft: 10 }
    : { color: theme.palette.info.contrastText, backgroundColor: theme.palette.info.light }),
}))

interface PropTypes {
  org?: {
    org_name: string
    logo_public_url?: string
  }
  user?: {
    display: string
    portrait_image_public_url: string
  }
  size?: number
  wrapperClass?: string
  circleClass?: string
}

const getInitials = (nameStr) => {
  if (!nameStr) return ''
  const allNames = nameStr.trim().split(' ')
  const initials = allNames.reduce((acc, curr, index) => {
    if (index === 0 || index === allNames.length - 1) {
      acc = `${acc}${curr.charAt(0).toUpperCase()}`
    }
    return acc
  }, '')
  return initials
}

const UserIcon: React.FC<PropTypes> = ({ org, user, size = 32, wrapperClass, circleClass }) => {
  const [renderInitials, setRenderInitials] = useState(false)
  const portraitUrl = useMemo(() => org?.logo_public_url || user?.portrait_image_public_url, [org, user])
  const initials = useMemo(() => getInitials(org?.org_name || user?.display), [user, org])

  if (!org && !user) return null
  return (
    <div className={wrapperClass}>
      {portraitUrl && !renderInitials ? (
        <UserIconImg
          size={size}
          src={portraitUrl}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null
            setRenderInitials(true)
          }}
        />
      ) : (
        <Circle isExternal={!!org} className={circleClass}>
          {initials}
        </Circle>
      )}
    </div>
  )
}

export default UserIcon
