import { logAmplitudeEvent } from 'amplitude/amplitude'
import { Struct } from 'contexts/structs/types/types'
import { useUserActionsContext } from 'contexts/userActions/useUserActionsContext'
import { addDevTool } from 'debug/ostools'
import { authSelectors } from 'ducks/auth'
import { Logger } from 'opensolar-sdk'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { PromoDialog, usePromoDialogStructs } from './usePromoDialogStructs'

const keyPrefix = 'os-iap-'
const getPromoKey = (struct: Struct<PromoDialog>) => `${keyPrefix}${struct.key}`

const logger = new Logger('OS.PromoDialog')

export const usePromoDialogLogic = () => {
  const [open, setOpen] = useState(false)
  const { structs: maybeShownStructs } = usePromoDialogStructs()
  const [currentPromoKey, setCurrentPromoKey] = useState<string>()
  const userId = useSelector(authSelectors.getCurrentUser)?.id || -1

  // Allows for hiding the promo dialog from the console
  const [forceHide, setForceHide] = useState(false)
  addDevTool(
    'promos.forceHide',
    () => {
      setForceHide(true)
    },
    { help: 'Hides the promo dialog' }
  )
  addDevTool(
    'promos.clearForceHide',
    () => {
      setForceHide(false)
    },
    { help: 'Unhides the promo dialog (if there are still promos to show)' }
  )

  // Incrementing forceCheck allows us to 'lock in' the promos that we've seen so that
  // they aren't immediately shown again when the user navigates closes the promo dialog
  const [forceCheck, setForceCheck] = useState(0)

  const { loaded: actionsLoaded, checkAction, recordAction } = useUserActionsContext()

  // both derived from currentPromoKey
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const [currentPromo, setCurrentPromo] = useState<Struct<PromoDialog> | undefined>()

  const hasBeenDismissed = (struct: Struct<PromoDialog>) => {
    const storageKey = getPromoKey(struct)
    if (struct.data.show_again_rule !== 'always' && dismissedThisSession[storageKey]) {
      // Promo has already been shown this session
      logger.debug('\tPromo Filtered, already shown this session: ', struct.key, {
        storageKey: storageKey,
      })
      return true
    }

    if (struct.data.show_again_rule === 'never') {
      if (!actionsLoaded) {
        // Don't show until actions are loaded
        logger.debug('\tPromo Filtered, actions not loaded yet: ', struct.key)
        return true
      }
      if (checkAction(storageKey)) {
        // Promo has already been shown
        logger.debug('\tPromo Filtered, already shown: ', struct.key, { storageKey })
        return true
      }
    }
    return false
  }

  const structs = useMemo(() => {
    return maybeShownStructs.filter((struct) => !hasBeenDismissed(struct))
  }, [maybeShownStructs, actionsLoaded, forceCheck]) // intentionally not including checkAction, allows for outro to complete uninterupted

  // Blocker promos don't show up in the pagination
  const navigableStructs = useMemo(() => {
    return structs.filter((struct) => struct.data.type !== 'blocker')
  }, [structs])

  const backEnabled = useMemo(() => {
    const index = navigableStructs.findIndex((struct) => struct.key === currentPromoKey)
    return !(index === -1 || index === 0)
  }, [currentPromoKey, navigableStructs])

  const nextWillClose = useMemo(() => {
    const index = navigableStructs.findIndex((struct) => struct.key === currentPromoKey)
    return !!(index === -1 || index === navigableStructs.length - 1)
  }, [currentPromoKey, navigableStructs])

  const showPromo = (struct: Struct<PromoDialog>) => {
    setCurrentPromoKey(struct.key)
    const storageKey = getPromoKey(struct)
    if (!hasShownThisSession(storageKey)) {
      trackShownThisSession(storageKey)
      logAmplitudeEvent('in_app_promo_shown', { promo_id: struct.key })
    }
    setOpen(true)
  }

  useEffect(() => {
    const matchingPromo = structs.find((struct) => struct.key === currentPromoKey)
    setCurrentIndex(matchingPromo ? structs.indexOf(matchingPromo) : 0)
    setCurrentPromo(matchingPromo)
    if (!currentPromoKey) setOpen(false)
  }, [currentPromoKey, structs])

  const trackDismissed = (promo: Struct<PromoDialog> | undefined) => {
    if (!promo) return

    const key = getPromoKey(promo)
    if (userId !== -1) recordAction(key)

    trackDismissedThisSession(key)
  }

  const trackAllDismissed = () => {
    structs.forEach(trackDismissed)
  }

  // Checks the current promo against the filtered list of promos and
  // updates the current promo if necessary
  useEffect(() => {
    if (!structs) return
    if (currentPromo) {
      // Check if new promos have been added which are higher priority
      for (const struct of structs) {
        if (struct.key === currentPromo.key) {
          // got to current promo without finding any, break
          break
        }
        if (!hasShownThisSession(getPromoKey(struct))) {
          logger.warn(`Promo (${struct.key}) was added at higher priority, switching to it`)
          showPromo(struct)
          return
        }
      }

      const latestPromo = structs.find((struct) => struct.key === currentPromo.key)
      if (latestPromo) {
        // Promo is still available, all good!
        // Update the current promo to the latest version
        setCurrentPromo(latestPromo)
        return
      } else {
        // Promo was removed, switch to the nearest available promo
        let gotoPromo: Struct<PromoDialog> | undefined = structs[currentIndex]
        if (!gotoPromo) gotoPromo = structs[currentIndex - 1]
        if (!gotoPromo) gotoPromo = structs[0]

        if (gotoPromo) {
          logger.info(`Promo (${currentPromo.key}) was removed, switching to nearest: `, gotoPromo.key)
          setCurrentPromoKey(gotoPromo.key)
        } else {
          logger.info(`Promo (${currentPromo.key}) was removed, clearing`)
          setCurrentPromoKey(undefined)
        }
      }
    }
    if (!currentPromo) {
      if (structs.length) {
        const struct = structs[0]
        logger.info('Selected Promo: ', struct.key)
        showPromo(struct)
      }
    } else {
      logger.info('Retaining current Promo: ', currentPromo.key)
    }
  }, [structs, currentPromo])

  const onBack = () => {
    const newIndex = currentIndex - 1
    if (newIndex >= 0) {
      const struct = navigableStructs[newIndex]
      showPromo(struct)
    }
  }

  const onNext = () => {
    const newIndex = currentIndex + 1
    if (newIndex < navigableStructs.length) {
      const struct = navigableStructs[newIndex]
      showPromo(struct)
    } else {
      // clearing current Promo will close dialog
      trackAllDismissed()
      setOpen(false) // Depends on onCloseComplete to reset the current promo
    }
  }

  const onCloseComplete = () => {
    if (open && forceHide) {
      // Don't clear if this was just the result of forceHide
      return
    }
    setCurrentPromoKey(undefined)
    setForceCheck(forceCheck + 1)
  }

  return {
    open: open && (forceHide === undefined || !forceHide),
    onCloseComplete,
    currentIndex,
    navigableStructs,
    currentPromo,
    trackDismissed,
    onBack,
    onNext,
    backEnabled,
    nextWillClose,
    trackingKey: currentPromo ? getPromoKey(currentPromo) : undefined,
  }
}

//TODO: this should be cleared on logout
const dismissedThisSession: Record<string, true> = {}
const trackDismissedThisSession = (storageKey: string) => {
  dismissedThisSession[storageKey] = true
}

//TODO: this should be cleared on logout
const shownThisSession: Record<string, true> = {}
const trackShownThisSession = (storageKey: string) => {
  shownThisSession[storageKey] = true
}
const hasShownThisSession = (storageKey: string) => {
  return !!shownThisSession[storageKey]
}
