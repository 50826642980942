import { makeStyles, Toolbar } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { sendInvitations } from 'ducks/invitation'
import { orgSelectors } from 'ducks/orgs'
import { paymentOptionSelectionSelectors } from 'ducks/paymentOptionSelection'
import { Button, DialogCloseButton, DialogTitle, styled } from 'opensolar-ui'
import { useGetSoldRecords } from 'projectSections/sections/payments/cashFlowTransactions/utils'
import { useIsProjectUsingCashFlow } from 'projectSections/sections/payments/hooks'
import React, { useMemo, useState } from 'react'
import { SimpleForm, useTranslate } from 'react-admin'
import { useFormState } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { getIntegrationsStringFromActions } from 'util/misc'
import { HeaderRow } from '../ShareModalContent'
import EmailForm from './EmailForm'

const useStyles = makeStyles({
  dialogActions: {
    marginLeft: 'auto',
  },
  submitBtn: {
    marginLeft: '10px !important',
  },
  headerText: {
    width: '100%',
  },
})

const ToolbarStyled = styled(Toolbar, { name: 'ToolbarStyled' })(({ theme }) => ({
  minHeight: 'unset !important',
  borderTop: theme.mixins.borderStandard.border,
  padding: '16px 20px',
}))

interface PropTypes {
  onClose: () => void
  setModalMode: (isEmail: boolean) => void
  contactDisplay: string
}

const EmailInviteContent: React.FC<PropTypes> = ({ onClose, setModalMode, contactDisplay }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const translate = useTranslate()
  const project = useFormState().values
  const org = useSelector(orgSelectors.getOrg)
  const [customizing, setCustom] = useState(false)
  const defaultEmailContent = useMemo(
    () => ({ subject: org?.invitation_email_custom_subject || '', content: org?.invitation_email_custom_message }),
    [org?.invitation_email_custom_message, org?.invitation_email_custom_subject]
  )
  const [soldSystem, soldPaymentOption] = useGetSoldRecords(project)
  const refreshSystemsTrigger = useSelector(paymentOptionSelectionSelectors.getSystemRefreshTrigger)
  const isProjectUsingCashflow = useIsProjectUsingCashFlow(soldSystem, soldPaymentOption, refreshSystemsTrigger)

  const handleInvite = (values) => {
    const customVals = values.isCustomizing ? { isCustom: true, subject: values.subject, content: values.content } : {}
    dispatch(
      sendInvitations(project.id, {
        ...customVals,
        applicationSystemUuid: values.selectedSystemUuid,
        applicationPaymentOptionId: values.selectedPaymentId,
      })
    )
    logAmplitudeEvent('proposal_emailed', {
      project_id: project.id,
      integrated_payment_options: getIntegrationsStringFromActions(project?.available_customer_actions),
      page: 'Project',
      has_cashflow: isProjectUsingCashflow,
    })
    onClose()
  }
  const hasPlentiBNPL = useMemo(() => {
    let foundBNPL = false
    if (project?.available_customer_actions) {
      const findPlentiActions = project?.available_customer_actions.filter(
        (action) => !!action.actions_available?.find((x) => x.payment_method === 'plenti_bnpl_application')
      )
      foundBNPL = !!findPlentiActions.length
    }
    return foundBNPL
  }, [project?.available_customer_actions?.actions_available])
  const availableSystems = useMemo(() => {
    return window.editor.getSystems()?.map((sys) => {
      sys.paymentOptionsData = sys.payment_options
      sys.title = sys.name && sys.name.length > 0 ? sys.name : `${sys.moduleQuantity()} ${translate('Panels System')}`
      return sys
    })
  }, [])
  const defaultSystem = useMemo(() => (!!availableSystems.length ? availableSystems[0] : undefined), [availableSystems])
  return (
    <>
      <DialogTitle>
        <HeaderRow>
          <div className={classes.headerText}>
            {translate('Send Email Invite to %{contacts}', {
              contacts: contactDisplay,
            })}
          </div>
          <DialogCloseButton onClick={onClose} />
        </HeaderRow>
      </DialogTitle>

      <SimpleForm
        toolbar={
          <EmailToolBar
            handleCancel={() => {
              setModalMode(false)
            }}
            isCustom={customizing}
            setCustom={setCustom}
            disableSubmit={!!!availableSystems.length}
          />
        }
        save={handleInvite}
        initialValues={{
          ...defaultEmailContent,
          isCustomizing: customizing,
          sendFinance: !!defaultSystem,
          selectedSystemUuid: defaultSystem?.uuid,
          selectedPaymentId: !!defaultSystem?.payment_options?.length
            ? defaultSystem?.payment_options[0]?.id
            : undefined,
        }}
      >
        <EmailForm hasPlentiBNPL={hasPlentiBNPL} systems={availableSystems} />
      </SimpleForm>
    </>
  )
}

const EmailToolBar = (props) => {
  const { handleCancel, handleSubmit, isCustom, setCustom, disableSubmit } = props
  const classes = useStyles()
  const translate = useTranslate()
  return (
    <ToolbarStyled>
      {!isCustom && (
        <Button
          variant="contained"
          color="default"
          onClick={() => {
            setCustom(true)
          }}
        >
          {translate('Customise')}
        </Button>
      )}
      <div className={classes.dialogActions}>
        <Button variant="contained" color="default" onClick={handleCancel}>
          {translate('Cancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          disabled={disableSubmit}
          className={classes.submitBtn}
        >
          {translate('Confirm and Send Proposal')}
        </Button>
      </div>
    </ToolbarStyled>
  )
}

export default EmailInviteContent
