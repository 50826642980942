import { startTourStep, terminateCurrentTour, updateTourStep } from 'actions/tourActions'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { RootState } from 'types/state'
import { parseQueryStringToDictionary } from 'util/query'

/**
 * Sets up sync from query params -> tour state.
 * Ideally this would only be run on boot and then all in-app
 * changes would happen via redux actions.
 */
export const useTourQuerySync = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const { tour, step } = useSelector((state: RootState) => state.tour)

  useEffect(() => {
    const query: any = parseQueryStringToDictionary(location.search)
    const tourInQuery = query?.tour
    const stepInQuery = query?.step

    if (tourInQuery !== tour) {
      if (!tourInQuery) {
        if (tour) dispatch(terminateCurrentTour(tour))
      } else if (stepInQuery) {
        dispatch(updateTourStep(tourInQuery, stepInQuery))
      } else {
        dispatch(startTourStep(tourInQuery))
      }
    } else if (tourInQuery && stepInQuery && tourInQuery === tour && stepInQuery !== step) {
      dispatch(updateTourStep(tourInQuery, stepInQuery))
    }
  }, [location])
}
