import { CalcOperation, Condition, EnphaseRule, Operator } from './RuleTypes'

/**
 * Validates a single SourceRule object.
 * @param rule - The SourceRule object to validate.
 * @returns True if the rule is valid, false otherwise.
 */
export const isValidRule = (rule: any): rule is EnphaseRule => {
  return (
    validateRequiredFields(rule) &&
    validateOptionalFields(rule) &&
    validateHiddenField(rule.hidden) &&
    validateSubRules(rule.rules)
  )
}

const validateRequiredFields = (rule: any): boolean => {
  const requiredFields = ['id', 'version', 'active', 'displayName', 'section', 'rules']
  let isValid = true

  requiredFields.forEach((field) => {
    if (!(field in rule)) {
      console.warn(`Missing required field '${field}' in rule ID: ${rule.id}`)
      isValid = false
    }
  })

  if (typeof rule.id !== 'string') {
    console.warn(`'id' must be a string in rule:`, rule)
    isValid = false
  }

  if (typeof rule.version !== 'number') {
    console.warn(`'version' must be a number in rule ID: ${rule.id}`)
    isValid = false
  }

  if (typeof rule.active !== 'boolean') {
    console.warn(`'active' must be a boolean in rule ID: ${rule.id}`)
    isValid = false
  }

  if (typeof rule.displayName !== 'string') {
    console.warn(`'displayName' must be a string in rule ID: ${rule.id}`)
    isValid = false
  }

  if (typeof rule.section !== 'string') {
    console.warn(`'section' must be a string in rule ID: ${rule.id}`)
    isValid = false
  }

  if (!Array.isArray(rule.rules) || rule.rules.length === 0) {
    console.warn(`'rules' must be a non-empty array in rule ID: ${rule.id}`)
    isValid = false
  }

  return isValid
}

const validateOptionalFields = (rule: any): boolean => {
  let isValid = true

  if (rule.notes && typeof rule.notes !== 'string') {
    console.warn(`'notes' must be a string in rule ID: ${rule.id}`)
    isValid = false
  }

  if (rule.requiresPhase && ![1, 3].includes(rule.requiresPhase)) {
    console.warn(`'requiresPhase' must be either 1 or 3 in rule ID: ${rule.id}`)
    isValid = false
  }

  if (
    rule.availableIn &&
    (!Array.isArray(rule.availableIn) || !rule.availableIn.every((code: any) => typeof code === 'string'))
  ) {
    console.warn(`'availableIn' must be an array of strings in rule ID: ${rule.id}`)
    isValid = false
  }

  if (
    rule.notAvailableIn &&
    (!Array.isArray(rule.notAvailableIn) || !rule.notAvailableIn.every((code: any) => typeof code === 'string'))
  ) {
    console.warn(`'notAvailableIn' must be an array of strings in rule ID: ${rule.id}`)
    isValid = false
  }

  if (rule.preventWithBattery !== undefined && typeof rule.preventWithBattery !== 'boolean') {
    console.warn(`'preventWithBattery' must be a boolean in rule ID: ${rule.id}`)
    isValid = false
  }

  if (rule.requiresBattery !== undefined && typeof rule.requiresBattery !== 'boolean') {
    console.warn(`'requiresBattery' must be a boolean in rule ID: ${rule.id}`)
    isValid = false
  }

  if (rule.requiresCommercial !== undefined && typeof rule.requiresCommercial !== 'boolean') {
    console.warn(`'requiresCommercial' must be a boolean in rule ID: ${rule.id}`)
    isValid = false
  }

  return isValid
}

const validateHiddenField = (hidden: any): boolean => {
  if (!hidden) return true

  const validOperators: Operator[] = [
    'equals',
    'includes',
    'in',
    'not_includes',
    'matches',
    'greater_than',
    'less_than',
  ]

  let isValid = true

  const validateCondition = (condition: any): boolean => {
    const { field, operator, value, logical, conditions } = condition

    if (logical && conditions) {
      if (!Array.isArray(conditions)) {
        console.warn(`'conditions' must be an array when 'logical' is specified.`, condition)
        return false
      }
      return conditions.every(validateCondition)
    }

    if (typeof field !== 'string') {
      console.warn(`'field' must be a string.`, condition)
      return false
    }

    if (!validOperators.includes(operator)) {
      console.warn(`Invalid 'operator': '${operator}'.`, condition)
      return false
    }

    if (!(typeof value === 'string' || Array.isArray(value))) {
      console.warn(`'value' must be a string or an array of strings.`, condition)
      return false
    }

    return true
  }

  if (hidden.conditions) {
    if (!Array.isArray(hidden.conditions)) {
      console.warn(`'hidden.conditions' must be an array.`)
      return false
    }

    isValid = hidden.conditions.every(validateCondition)
  } else {
    const { field, operator, value } = hidden

    if (typeof field !== 'string') {
      console.warn(`'hidden.field' must be a string.`, hidden)
      isValid = false
    }

    if (!validOperators.includes(operator)) {
      console.warn(`Invalid 'hidden.operator': '${operator}'.`, hidden)
      isValid = false
    }

    if (!(typeof value === 'string' || Array.isArray(value))) {
      console.warn(`'hidden.value' must be a string or an array of strings.`, hidden)
      isValid = false
    }
  }

  return isValid
}

const validateSubRules = (rules: any[]): boolean => {
  let isValid = true

  rules.forEach((subRule) => {
    if (typeof subRule.id !== 'string') {
      console.warn(`'id' must be a string in sub-rule:`, subRule)
      isValid = false
    }

    if (!Array.isArray(subRule.conditions)) {
      console.warn(`'conditions' must be an array in sub-rule ID: ${subRule.id}`)
      isValid = false
    } else {
      subRule.conditions.forEach((condition) => {
        if (!isValidCondition(condition)) {
          isValid = false
        }
      })
    }

    if (subRule.result === undefined || (typeof subRule.result !== 'number' && typeof subRule.result !== 'object')) {
      console.warn(`'result' must be a number or an object in sub-rule ID: ${subRule.id}`)
      isValid = false
    }

    if (typeof subRule.result === 'object' && !isValidCalcOperation(subRule.result)) {
      console.warn(`Invalid 'result' CalcOperation in sub-rule ID: ${subRule.id}`)
      isValid = false
    }
  })

  return isValid
}

/**
 * Validates a Condition object.
 * @param condition - The Condition object to validate.
 * @returns True if the condition is valid, false otherwise.
 */
const isValidCondition = (condition: any): condition is Condition => {
  const validOperators: Operator[] = [
    'equals',
    'includes',
    'in',
    'not_includes',
    'matches',
    'greater_than',
    'less_than',
  ]
  let isValid = true

  if (typeof condition.field !== 'string') {
    console.warn(`'condition.field' must be a string.`, condition)
    isValid = false
  }

  if (!validOperators.includes(condition.operator)) {
    console.warn(`Invalid 'condition.operator': '${condition.operator}'.`)
    isValid = false
  }

  if (
    !(
      typeof condition.value === 'string' ||
      Array.isArray(condition.value) ||
      typeof condition.value === 'number' ||
      typeof condition.value === 'boolean'
    )
  ) {
    console.warn(`'condition.value' must be a string, array of strings, number, or boolean.`)
    isValid = false
  }

  return isValid
}

/**
 * Validates a CalcOperation object.
 * @param operation - The CalcOperation object to validate.
 * @returns True if the CalcOperation is valid, false otherwise.
 */
const isValidCalcOperation = (operation: any): operation is CalcOperation => {
  const operationType = operation.type
  let isValid = true

  switch (operationType) {
    case 'fixed':
      if (typeof operation.value !== 'number') {
        console.warn(`'fixed' operation requires a numeric 'value'.`, operation)
        isValid = false
      }
      break

    case 'field':
      if (typeof operation.field !== 'string') {
        console.warn(`'field' operation requires a string 'field'.`, operation)
        isValid = false
      }
      if (operation.multiply !== undefined && typeof operation.multiply !== 'number') {
        console.warn(`'field' operation's 'multiply' must be a number.`, operation)
        isValid = false
      }
      if (operation.divide !== undefined && typeof operation.divide !== 'number') {
        console.warn(`'field' operation's 'divide' must be a number.`, operation)
        isValid = false
      }
      break

    case 'cable':
      if (typeof operation.cableCode !== 'string') {
        console.warn(`'cable' operation requires a string 'cableCode'.`, operation)
        isValid = false
      }
      break

    case 'unsealed-cables':
      if (
        (operation.extraDrops !== undefined && typeof operation.extraDrops !== 'boolean') ||
        (operation.subtractMicros !== undefined && typeof operation.subtractMicros !== 'boolean')
      ) {
        console.warn(`'unsealed-cables' operation's 'extraDrops' and 'subtractMicros' must be boolean.`, operation)
        isValid = false
      }
      break

    case 'add':
    case 'subtract':
    case 'multiply':
      if (
        !Array.isArray(operation.subOperations) ||
        operation.subOperations.length < 2 ||
        !operation.subOperations.every((op: any) => isValidCalcOperation(op))
      ) {
        console.warn(`'${operation.type}' operation requires at least two valid 'subOperations'.`, operation)
        isValid = false
      }
      break

    case 'value':
      if (typeof operation.value !== 'number') {
        console.warn(`'value' operation requires a numeric 'value'.`, operation)
        isValid = false
      }
      break

    case 'conditional':
      if (typeof operation.condition !== 'object' || !isValidCondition(operation.condition)) {
        console.warn(`'conditional' operation has invalid 'condition'.`, operation)
        isValid = false
      }
      if (!(typeof operation.whenTrue === 'number' || typeof operation.whenTrue === 'object')) {
        console.warn(`'conditional' operation's 'whenTrue' must be a number or a CalcOperation object.`, operation)
        isValid = false
      }
      if (
        operation.whenFalse !== undefined &&
        !(typeof operation.whenFalse === 'number' || typeof operation.whenFalse === 'object')
      ) {
        console.warn(`'conditional' operation's 'whenFalse' must be a number or a CalcOperation object.`, operation)
        isValid = false
      }
      break

    case 'roundUp':
      if (!isValidCalcOperation(operation.subOperation)) {
        console.warn(`'roundUp' operation requires a valid 'subOperation'.`, operation)
        isValid = false
      }
      break

    case 'ET-CLIP-GB':
      if (!operation.portraitPanels || !operation.landscapePanels) {
        console.warn(`'ET-CLIP-GB' operation must have 'portraitPanels' and 'landscapePanels' fields.`)
        return false
      }
      break

    case 'raw-cable':
      if (operation.multiplier && typeof operation.multiplier !== 'number') {
        console.warn(`'raw-cable' operation must have a 'multiplier' field of type number.`)
        return false
      }
      break

    case 'unsealed-cables-US':
    case 'unsealed-cables-BE':
      if (
        (operation.extraDrops && typeof operation.extraDrops !== 'boolean') ||
        (operation.subtractMicros && typeof operation.subtractMicros !== 'boolean')
      ) {
        console.warn(
          `'${operation.type}' operation must have 'extraDrops' and 'subtractMicros' fields of type boolean.`
        )
        return false
      }
      break

    case 'Q-CLIP-US':
      // No specific fields to validate for 'Q-CLIP-US'
      break

    default:
      console.warn(`Unknown CalcOperation type: '${operation.type}'.`, operation)
      isValid = false
  }

  return isValid
}
