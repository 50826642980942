import { orgSelectors } from 'ducks/orgs'
import { useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import TemplateLibraryCard from './TemplateLibraryCard'

const DefaultTemplateCard = (props) => {
  const translate = useTranslate()
  const org = useSelector(orgSelectors.getOrg)
  if (!org) return null

  return (
    <TemplateLibraryCard
      tag="Default"
      header={translate('%{orgName} Proposal', { orgName: org.name })}
      body={translate('Discover what your customer wants and needs though with an interactive proposal')}
      imgSrc={`${window.PUBLIC_URL}/images/proposal/template_default.png`}
      {...props}
    />
  )
}

export default DefaultTemplateCard
