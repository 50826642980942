var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as Sentry from '@sentry/react';
import BrighteApplication from '../brighte/BrighteApplication';
import DividendApplication from '../dividend/DividendApplication';
import GenericIntegratedFinanceApplication from '../genericFinance/GenericIntegratedFinanceApplication';
import LoanpalApplication from '../loanpal/LoanpalApplication';
import MosaicApplication from '../mosaic/MosaicApplication';
import PhoenixApplication from '../phoenix/PhoenixApplication';
import PlentiApplication from '../plenti/PlentiApplication';
import SungageApplication from '../sungage/SungageApplication';
import SunlightApplicationAction from '../sunlight/SunlightApplicationAction';
var LoanApplication = function (props) {
    var actionData = props.elementData.actionData;
    if (actionData.payment_method === 'sunlight_loan_application') {
        return _jsx(SunlightApplicationAction, __assign({}, props));
    }
    else if (actionData.payment_method === 'loanpal_application') {
        return _jsx(LoanpalApplication, __assign({}, props));
    }
    else if (actionData.payment_method === 'mosaic_application') {
        return _jsx(MosaicApplication, __assign({}, props));
    }
    else if (actionData.payment_method === 'sungage_application') {
        return _jsx(SungageApplication, __assign({}, props));
    }
    else if (actionData.payment_method === 'brighte_application') {
        return _jsx(BrighteApplication, __assign({}, props));
    }
    else if (actionData.payment_method === 'dividend_application') {
        return _jsx(DividendApplication, __assign({}, props));
    }
    else if (actionData.payment_method === 'phoenix_application') {
        return _jsx(PhoenixApplication, __assign({}, props));
    }
    else if (actionData.payment_method === 'plenti_loan_application' ||
        actionData.payment_method === 'plenti_bnpl_application') {
        return _jsx(PlentiApplication, __assign({}, props));
    }
    else if (actionData.finance_integration) {
        return _jsx(GenericIntegratedFinanceApplication, __assign({}, props));
    }
    else {
        Sentry.captureException(new Error("Unidentifiable loan application. actionData.payment_method: ".concat(actionData.payment_method)));
        return null;
    }
};
export default LoanApplication;
