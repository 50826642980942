export const LOCALES_SUPPORTED = {
  en: 'English',
  en_US: 'English (US)',
  nl: 'Dutch',
  sv: 'Swedish',
  es: 'Spanish',
  fr: 'French',
  de: 'German',
  pl: 'Polish',
  pt: 'Portuguese',
  it: 'Italian',
  da: 'Danish',
  fi: 'Finnish',
  no: 'Norwegian',
  th: 'Thai',
}

export const LOCALES_SUPPORTED_LEAD_CAPTURE_FORM = {
  en: 'English',
  en_US: 'English (US)',
  nl: 'Dutch',
  sv: 'Swedish',
  es: 'Spanish',
  fr: 'French',
  de: 'German',
  pl: 'Polish',
  pt: 'Portuguese',
  it: 'Italian',
  da: 'Danish',
  fi: 'Finnish',
  no: 'Norwegian',
}

export const LOCALES_SUPPORTED_CUSTOMER_FACING = {
  en: 'English',
  en_US: 'English (US)',
  nl: 'Dutch',
  sv: 'Swedish',
  es: 'Spanish',
  es_MX: 'Spanish (Mexico)',
  fr: 'French',
  de: 'German',
  pl: 'Polish',
  pt: 'Portuguese',
  it: 'Italian',
  da: 'Danish',
  fi: 'Finnish',
  no: 'Norwegian',
  th: 'Thai',
}
