var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from '@mui/material';
import { styled } from '../../styles/styled';
var Wrapper = styled('div')(function (_a) {
    var theme = _a.theme;
    return {
        padding: '0px 10px',
    };
});
var LinkWrapper = styled('div')(function (_a) {
    var theme = _a.theme;
    return {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '8px',
    };
});
var OrRow = styled('div')(function (_a) {
    var theme = _a.theme;
    return {
        height: '1px',
        width: '100%',
        borderBottom: '1px solid #CDCDCD',
        display: 'flex',
        justifyContent: 'center',
        margin: '6px 0px 10px 0px',
    };
});
var Or = styled('span')(function (_a) {
    var theme = _a.theme;
    return {
        marginTop: '-8px',
        backgroundColor: 'white',
        padding: '0px 8px',
        height: '12px',
    };
});
var StyledLink = styled(Link)(function (_a) {
    var theme = _a.theme;
    return {
        textDecoration: 'underline',
        padding: '14px',
        cursor: 'pointer',
        color: 'rgb(24, 144, 255)',
        '&:link, &:visited, &:hover, &:active': {
            color: 'rgb(24, 144, 255)',
        },
    };
});
export var DepositCtaLink = function (_a) {
    var label = _a.label, onClick = _a.onClick;
    return (_jsx(Wrapper, { children: _jsxs(LinkWrapper, { children: [_jsx(OrRow, { children: _jsx(Or, { children: "or" }) }), _jsx("div", { children: _jsx(StyledLink, __assign({ onClick: onClick }, { children: label })) })] }) }));
};
