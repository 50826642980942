import { makeStyles, MenuItem, Select } from '@material-ui/core'
import { permissionsSelectors } from 'ducks/permissions'
import { useCallback } from 'react'
import { useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useFeatureFlag } from 'util/split'

const useStyles = makeStyles({
  select: {
    padding: '6px 14px',
    background: 'rgba(240, 240, 240, 1)',
    '&&': {
      paddingRight: 40, // only way to override
    },
  },
  wrapper: {
    marginLeft: 'auto',
    width: '100px',
    maxWidth: 240,
    flexGrow: 1,
  },
})

const prefix = 'Section: '

const NavigationMobile = () => {
  const accessRightsSetting = useSelector(permissionsSelectors.getProjectPermissionsSetting)
  const history = useHistory()
  const form = useForm()
  const classes = useStyles()
  const projectId = useSelector((state) => state.projectId)
  const section = useSelector((state) => state.project.section)
  const enableSingleLineDiagram = useFeatureFlag('single_line_diagram', 'on')
  const paymentsTabEnabled = useFeatureFlag('payments_tab', 'on')
  const enableProposalV2 = useFeatureFlag('enable_sales_machine', 'on')

  const handleChange = useCallback(
    (e) => {
      const newSection = e.target.value
      form.mutators.recordFormDirtyFields()
      history.push(`/projects/${projectId}/${newSection}`)
    },
    [projectId]
  )
  const translate = useTranslate()
  return (
    <Select
      classes={{ root: classes.select }}
      className={classes.wrapper}
      variant="outlined"
      id="project-form-navigation-mobile"
      value={section || ''}
      onChange={handleChange}
    >
      {section === 'explore' && (
        <MenuItem value={'explore'}>
          {translate(prefix)}
          {translate('Explore')}
        </MenuItem>
      )}
      <MenuItem value={'info'}>
        {translate(prefix)}
        {translate('Info')}
      </MenuItem>
      <MenuItem value={'energy'}>
        {translate(prefix)}
        {translate('Energy')}
      </MenuItem>
      {!!accessRightsSetting?.design?.view && (
        <MenuItem value={'design'}>
          {translate(prefix)}
          {translate('Design')}
        </MenuItem>
      )}
      {!!accessRightsSetting?.design?.view && paymentsTabEnabled && (
        <MenuItem value="payments">
          {translate(prefix)}
          {translate('Payments')}
        </MenuItem>
      )}
      {!!accessRightsSetting?.proposal?.view && (
        <MenuItem value={'proposal'}>
          {translate(prefix)}
          {translate(enableProposalV2 ? 'Proposal' : 'Online Proposal')}
        </MenuItem>
      )}
      {!enableProposalV2 && !!accessRightsSetting?.proposal?.view && (
        (
          <MenuItem value={'preview'}>
            {translate(prefix)}
            {translate('PDF Proposal')}
          </MenuItem>
        )
      )}
      {!!accessRightsSetting?.manage?.view && (
        <MenuItem value={'manage'}>
          {translate(prefix)}
          {translate('Manage')}
        </MenuItem>
      )}
      {!!accessRightsSetting?.design?.view && !!accessRightsSetting?.sld?.create && enableSingleLineDiagram && (
        <MenuItem value={'sld'}>
          {translate(prefix)}
          {translate('SLD')}
        </MenuItem>
      )}
    </Select>
  )
}

export default NavigationMobile
