import { logAmplitudeEvent } from 'amplitude/amplitude'
import LoadingDots from 'layout/widgets/LoadingDots'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

type PropTypes = {
  videoUrl: string
  trackingSource?: string
}

type PlayerType = {
  ready(): Promise<void>
  destroy(): void
  on(method: string, fnc: Function): void
}

declare global {
  interface Window {
    Vimeo: {
      Player: { new (element): PlayerType }
    }
  }
}

const useStyles = makeOpenSolarStyles((theme) => ({
  container: { position: 'relative', width: 'inherit', height: 'inherit' },
}))

const ResponsiveVimeoPlayer: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  const [vimeoScriptLoaded, setVimeoScriptLoaded] = useState<boolean>(() => !!window.Vimeo)
  const [isIframeReady, setIsIframeReady] = useState<boolean>(false)
  const playerRef = useRef<PlayerType | null>(null)
  const containerRef = useRef(null)
  const loadPlayerScript = useCallback(() => {
    if (!window.Vimeo) {
      var script = document.createElement('script')
      script.src = 'https://player.vimeo.com/api/player.js'
      script.onload = () => setVimeoScriptLoaded(true)
      document.head.appendChild(script)
    }
  }, [])

  const createPlayer = useCallback((element): PlayerType => {
    const player = new window.Vimeo.Player(element)
    player.ready().then(function () {
      // The iframe player is ready
      setIsIframeReady(true)
    })
    if (props.trackingSource) {
      player.on('play', function () {
        logAmplitudeEvent('vimeo_video_player_interacted', {
          url: props.videoUrl,
          action: 'played',
          source: props.trackingSource,
        })
      })

      player.on('ended', function () {
        logAmplitudeEvent('vimeo_video_player_interacted', {
          url: props.videoUrl,
          action: 'finished',
          source: props.trackingSource,
        })
      })
    }
    return player
  }, [])

  const destroyPlayer = useCallback(() => {
    if (playerRef.current) {
      playerRef.current.destroy()
    }
  }, [])

  useEffect(() => {
    loadPlayerScript()
    return destroyPlayer
  }, [])

  useEffect(() => {
    if (containerRef.current && vimeoScriptLoaded && !playerRef.current && props.videoUrl) {
      const player = createPlayer(containerRef.current)
      playerRef.current = player
    }
  }, [vimeoScriptLoaded, containerRef.current, props.videoUrl])

  return (
    <div className={classes.container}>
      {(!isIframeReady || !props.videoUrl) && <LoadingDots />}
      {vimeoScriptLoaded && (
        <iframe
          ref={containerRef}
          src={props.videoUrl}
          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
          frameBorder={0}
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        ></iframe>
      )}
    </div>
  )
}
export default ResponsiveVimeoPlayer
