import * as Sentry from '@sentry/react'
import { CheckoutActionCallback } from 'opensolar-checkout'
import useBrighteApplicationCallback from './useBrighteApplicationCallback'
import useDividendApplicationCallback from './useDividendApplicationCallback'
import useGenericApplicationCallback from './useGenericApplicationCallback'
import useLoanpalApplicationCallback from './useLoanpalApplicationCallback'
import useMosaicApplicationCallback from './useMosaicApplicationCallback'
import usePhoenixApplicationCallback from './usePhoenixApplicationCallback'
import usePlentiApplicationCallback from './usePlentiApplicationCallback'
import useSungageApplicationCallback from './useSungageApplicationCallback'
import useSunlightApplicationCallback from './useSunlightApplicationCallback'

const useLoanApplicationCallback = (): CheckoutActionCallback => {
  const sunlightApplicationCallback = useSunlightApplicationCallback()
  const loanpalApplicationCallback = useLoanpalApplicationCallback()
  const mosaicApplicationCallback = useMosaicApplicationCallback()
  const sungageApplicationCallback = useSungageApplicationCallback()
  const brighteApplicationCallback = useBrighteApplicationCallback()
  const dividendApplicationCallback = useDividendApplicationCallback()
  const phoenixApplicationCallback = usePhoenixApplicationCallback()
  const plentiApplicationCallback = usePlentiApplicationCallback()
  const genericApplicationCallback = useGenericApplicationCallback()

  return async (props) => {
    if (props.elementData.type !== 'LoanApplication') {
      Sentry.captureException(new Error('Invalid element data for loan application callback'))
      return
    }
    const { actionData } = props.elementData
    if (actionData.payment_method === 'sunlight_loan_application') {
      await sunlightApplicationCallback(props)
      return
    }
    if (actionData.payment_method === 'loanpal_application') {
      await loanpalApplicationCallback(props)
      return
    }
    if (actionData.payment_method === 'mosaic_application') {
      await mosaicApplicationCallback(props)
      return
    }
    if (actionData.payment_method === 'sungage_application') {
      await sungageApplicationCallback(props)
      return
    }
    if (actionData.payment_method === 'brighte_application') {
      await brighteApplicationCallback(props)
      return
    }
    if (actionData.payment_method === 'dividend_application') {
      await dividendApplicationCallback(props)
      return
    }

    if (actionData.payment_method === 'phoenix_application') {
      await phoenixApplicationCallback(props)
      return
    }

    if (
      actionData.payment_method === 'plenti_bnpl_application' ||
      actionData.payment_method === 'plenti_loan_application'
    ) {
      await plentiApplicationCallback(props)
      return
    }

    if (actionData.finance_integration) {
      await genericApplicationCallback(props)
      return
    }

    Sentry.captureException(
      new Error(`Unidentifiable loan application callback. actionData.payment_method: ${actionData.payment_method}`)
    )
  }
}

export default useLoanApplicationCallback
