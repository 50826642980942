import { authSelectors } from 'ducks/auth'
import Alert from 'elements/Alert'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import React from 'react'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import ConnectBrighteButton from 'resources/externalKeys/brighte/ConnectBrighteButton'

type PropTypes = {
  id: string | number
  brighteConnectedToOrg: boolean
  disconnectBrighte?: () => void
  thisRoleIsConnected?: boolean //used from design page to say that the role is not connected
  isStudioMode?: boolean
  buttonLabel?: string
  onStudioButtonClock?: () => void
  disabled?: boolean
}

const BrightButtonWrapper: React.FunctionComponent<PropTypes> = (props) => {
  const loggedInRole = useSelector(authSelectors.getCurrentRole)
  const form = useForm()
  const brighte_connected_to_role = !!form.getState().values?.brighte_agent_id
  const isLoggedInRole = loggedInRole?.id === form.getState().values?.id

  let buttonLabel = brighte_connected_to_role
    ? "Re-Connect This User's Brighte Account"
    : "Connect This User's Brighte Account"
  if (props.buttonLabel) buttonLabel = props.buttonLabel

  if (!props.brighteConnectedToOrg) return null
  else
    return (
      <>
        {!props.isStudioMode && (
          <>
            <h2>Brighte Integration</h2>
            {!isLoggedInRole && (
              <Alert severity="info">
                The Brighte buttons below will affect this user you are currently editing. It will not affect the user
                you are currently logged in as.
              </Alert>
            )}
            {!brighte_connected_to_role && (
              <p>
                Your company's account has been connected to Brighte. But before this team member can start selling
                Brighte products their user will need to be connected to their Brighte account using the button below
              </p>
            )}
          </>
        )}
        <div style={{ marginBottom: '20px' }}>
          {!props.isStudioMode && (
            <ConnectBrighteButton
              buttonLabel={buttonLabel}
              editRoleMode={true}
              roleId={props.id}
              disabled={props.disabled}
            />
          )}
          {props.isStudioMode && (
            <ConnectBrighteButton
              buttonLabel={buttonLabel}
              editRoleMode={false}
              studioMode={true}
              roleId={props.id}
              onTabOpened={() => (props.onStudioButtonClock ? props.onStudioButtonClock() : () => null)}
              disabled={props.disabled}
            />
          )}
        </div>
        {brighte_connected_to_role && props.disconnectBrighte && (
          <div style={{ margin: '20px 0px' }}>
            <ProUXButton label="Disconnect Brighte" onClick={props.disconnectBrighte} type="secondary" />
          </div>
        )}
      </>
    )
}
export default BrightButtonWrapper
