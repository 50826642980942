import { TourStep } from 'ducks/tour'
import Actions from './steps/Actions'
import Activities from './steps/Activities'
import DocsAndTransactions from './steps/DocsAndTransactions'
import Integrations from './steps/Integrations'
import MoreDetails from './steps/MoreDetails'
import ProjectDetails from './steps/ProjectDetails'
import ProjectHistory from './steps/ProjectHistory'
import Workflows from './steps/Workflows'

export const MANAGE_FUJI_TOUR_STEPS: TourStep[] = [
  {
    step: 'details',
    config: ProjectDetails,
    page: 'manage',
  },
  {
    step: 'history',
    config: ProjectHistory,
    page: 'manage',
  },
  {
    step: 'workflows',
    config: Workflows,
    page: 'manage',
  },
  {
    step: 'actions',
    config: Actions,
    page: 'manage',
  },
  {
    step: 'activities',
    config: Activities,
    page: 'manage',
  },
  {
    step: 'documents',
    config: DocsAndTransactions,
    page: 'manage',
  },
  {
    step: 'integrations',
    config: Integrations,
    page: 'manage',
  },
  {
    step: 'moreDetails',
    config: MoreDetails,
    page: 'manage',
  },
]
