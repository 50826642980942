import { Grid, Typography } from '@material-ui/core'
import { SwapHoriz } from '@material-ui/icons'
import { setSelectComponentDialog } from 'ducks/selectComponent'
import { withHardwareSelectorV2, WithHardwareSelectorV2Props } from 'elements/hardwareSelectorV2/withHardwareSelectorV2'
import LowStockWarning from 'pages/ordering/order/table/quantity/LowStockWarning'
import type LineItemType from 'pages/ordering/OrderLineItem'
import { useDispatch } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { ComponentTypesV2 } from 'types/selectComponent'

type AlternativePickerProps = WithHardwareSelectorV2Props & { component: LineItemType }

const useStyles = makeOpenSolarStyles((theme) => ({
  contentContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 8,
  },
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
    borderRadius: 6,
    border: `1px solid #E6E6E6`,
    padding: '0px 8px',
    color: '#4F4D55',
    cursor: 'pointer',
  },
}))

const AlternativePicker = ({
  component,
  enableHardwareDialogV2,
  handleOpenHardwareSelector,
}: AlternativePickerProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleOpenSelector = () => {
    if (enableHardwareDialogV2) {
      handleOpenHardwareSelector({
        componentTypes: [component?.componentTypeV2 || (component?.componentType as ComponentTypesV2)],
        title: 'Select Component',
      })
    } else {
      dispatch(
        setSelectComponentDialog(
          true,
          window.WorkspaceHelper.project.org_id,
          window.WorkspaceHelper.project.id,
          [component?.uuid],
          component?.componentType
        )
      )
    }
  }

  return (
    <>
      <Grid xs={3}></Grid>
      <Grid xs={9} item container direction="row" alignItems="center" className={classes.contentContainer}>
        <LowStockWarning lineItem={component} />
        <div className={classes.buttonContainer} onClick={handleOpenSelector}>
          <SwapHoriz style={{ marginRight: 8 }} />
          <Typography variant="subtitle2">{`Choose Alternative`}</Typography>
        </div>
      </Grid>
    </>
  )
}

export default withHardwareSelectorV2(AlternativePicker)
