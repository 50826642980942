var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
var Message = function (_a) {
    var elementData = _a.elementData, translate = _a.translate, proposalData = _a.proposalData, config = _a.config, onAction = _a.onAction, options = _a.options;
    var actionData = elementData.actionData;
    return _jsx("p", __assign({ className: "small" }, { children: actionData.message }));
};
export default Message;
