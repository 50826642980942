import { authSelectors } from 'ducks/auth'
import { ProjectView } from 'ducks/projectListViews'
import { List } from 'elements/react-admin/List'
import { Box, styled } from 'opensolar-ui'
import { FC, ReactNode, useMemo } from 'react'
import { FunctionField } from 'react-admin'
import { useSelector } from 'react-redux'
import Pagination from '../projects3/elements/Pagination'
import ProjectActionsButton from './elements/ProjectActionsButton'
import ProjectDatagrid from './elements/ProjectDatagrid'
import { ProjectFiltersType } from './filtering/type'
import KanbanView from './kanban'
import ProjectsHeader from './ProjectsHeader'
import { ProjectsListColumn } from './views/utils'

const ProjectList = styled(List)({
  padding: 0,
  width: '100%',
  '& .Ra-List-Main': {
    overflowX: 'auto',
  },
})

const StyledBox = styled(Box)({
  backgroundColor: 'white',
})

const KanbanBox = styled(Box, { name: 'KanbanBox' })({
  backgroundColor: 'unset',
})

const HeaderWrapper = styled('div', { name: 'HeaderWrapper' })({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
})

export interface ViewComponentProps {
  viewSelected: ProjectView
  isTableView: boolean
  filterValues: ProjectFiltersType
  columns: ProjectsListColumn[]
  toolbars: ReactNode
}

const ProjectsTable: FC<ViewComponentProps> = ({ viewSelected, isTableView, filterValues, columns, ...props }) => {
  const orgId = useSelector(authSelectors.getOrgId)
  const displayCols = useMemo(() => columns?.filter((x) => x.enabled), [columns])
  return (
    <ProjectList
      key={viewSelected?.id}
      id={orgId}
      perPage={10}
      hasCreate={false}
      resource={'projects'}
      basePath={'projects'}
      filterDefaultValues={filterValues || {}}
      title={<ProjectHeader viewSelected={viewSelected} {...props} />}
      breadCrumbs={<></>}
      hasSearch={false}
      component={isTableView ? StyledBox : KanbanBox}
      hasBulkActions={false}
      //@ts-ignore
      pagination={isTableView ? <Pagination /> : <></>}
      //@ts-ignore
      queryOptions={{ blockRequest: !isTableView }} // disable loading when in kanban view
    >
      {isTableView && displayCols ? (
        <ProjectDatagrid>
          {displayCols.map((column: ProjectsListColumn) =>
            column.hideOnTable ? null : (
              <FunctionField key={'col-' + column.id} label={column.display} {...column.props} />
            )
          )}
          <FunctionField source="" textAlign={'right'} render={(record) => <ProjectActionsButton project={record} />} />
        </ProjectDatagrid>
      ) : (
        <KanbanView displayCols={displayCols} viewFilters={filterValues} />
      )}
    </ProjectList>
  )
}
interface HeaderProps {
  toolbars: ReactNode
  viewSelected: ProjectView
}

const ProjectHeader: FC<HeaderProps> = (props) => {
  return (
    <HeaderWrapper>
      <ProjectsHeader {...props} />
      {props.toolbars}
    </HeaderWrapper>
  )
}

export default ProjectsTable
