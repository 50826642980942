import { CircularProgress, Dialog, DialogActions, DialogContent, Theme, useMediaQuery } from '@material-ui/core'
import { CardElement } from '@stripe/react-stripe-js'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { getAvailableActions, markAsSold } from 'ducks/myEnergy'
import { transactionSelectors } from 'ducks/transaction'
import LoadingDots from 'layout/widgets/LoadingDots'
import { ActionDataType } from 'myenergy/selectionComponent/loanApplicationButton/types'
import { Button } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { SimpleForm } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { ProposalDataType } from 'types/proposals'
import IntegratedCheckoutWrapper from '../cashFlow/IntegratedCheckoutWrapper'
import { OFFLINE, STRIPE_PAYMENT_METHOD } from '../constants'
import CheckoutContentsHeader from '../shared/CheckoutContentsHeader'
import CheckoutQuotationTable from '../shared/CheckoutQuotationTable'
import TransactionDialogTitle from '../shared/TransactionDialogTitle'
import { CheckoutFormValues, CheckoutRequestData, StripeTokenType } from '../types'
import { checkout, checkoutStripeWith3DSecure } from './checkoutFunctions'
import NativeCheckoutContents from './NativeCheckoutContents'
import PaymentMethodSelection from './payments/PaymentMethodSelection'
import { StripeRef } from './payments/StripeRef'

const useStyles = makeOpenSolarStyles((theme) => ({
  actionsWrapper: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  errorWrapper: {
    flex: 7,
  },
  errorText: {
    color: 'red',
  },
  submitWrapper: {
    flex: 1,
  },
  loadingWrapper: {
    height: 400,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonLabel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minWidth: '200px',
  },
  buttonLoader: {
    color: '#fff',
  },
}))

type PropTypes = {
  isOpen: boolean
  onClose: () => void
  actionData: ActionDataType
  allActionData?: ActionDataType[]
  proposalData: ProposalDataType
  onSuccess: () => void
}

const CheckoutDialogFormContents: React.FC<PropTypes> = (props) => {
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false)
  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const [isStripeProcessing, setIsStripeProcessing] = useState<boolean>(false)
  const [actionData, setActionData] = useState<ActionDataType>(props.actionData)
  const [showCashFlowDeposit, setShowCashFlowDeposit] = useState<boolean>(false)

  const translate = useTranslate()
  const stripePaymentRef = StripeRef?.ref
  const isMobile = useMediaQuery((theme: Theme) => theme?.breakpoints?.down('md'))

  const transactionRequestData = useSelector(transactionSelectors.getTransactionRequestData)

  const form = useForm()
  const formState = useFormState()
  const classes = useStyles()
  const dispatch = useDispatch()

  const changeOrderMode = !!props.proposalData.changeOrderProposal

  useEffect(() => {
    const unsubscribe = form.subscribe(
      ({ values, invalid }) => {
        const isInvalid = invalid || validateForm(values as CheckoutFormValues)
        setIsDisabled(!!isInvalid)
      },
      { invalid: true, values: true }
    )

    return () => {
      unsubscribe()
    }
  }, [form])

  // This really just controls the header title. We need to refactor this
  // in future so that getButton and the title code are identical.
  useEffect(() => {
    const paymentMethod = form?.getState()?.values.selected_payment_method
    let actionData = props.actionData
    if (paymentMethod === OFFLINE) {
      const offlineActionData = props.allActionData?.find((action) => action.payment_method === OFFLINE)
      if (offlineActionData) actionData = offlineActionData
    }
    setActionData(actionData)
  }, [form?.getState()?.values.selected_payment_method])

  const validateForm = (formVals: CheckoutFormValues) => {
    let newError = undefined as string | undefined
    if (transactionRequestData.collect_signature && !transactionRequestData?.docusign_contract_complete) {
      if (!window.SignaturePad) {
        newError = 'Please provide your signature with finger or mouse.'
      } else {
        const canvas: HTMLCanvasElement = window?.SignaturePad?.refs?.cv
        const context = canvas?.getContext('2d')
        var pixelsFilled = context?.getImageData(0, 0, canvas.width, canvas.height).data.filter((v) => v > 0).length
        if (!pixelsFilled) {
          newError = 'Please provide your signature with finger or mouse.'
        } else if (pixelsFilled < 1000) {
          newError = 'The provided signature appears to be incomplete. Please sign your full name'
        }
      }
    }

    if (
      transactionRequestData.payment_amount &&
      transactionRequestData.payment_stripe_key &&
      !formVals.card_is_complete &&
      formVals.selected_payment_method === STRIPE_PAYMENT_METHOD
    ) {
      newError = 'Please provide all requested credit card details to proceed'
    }

    if (!transactionRequestData?.docusign_contract_complete) {
      let i = 0
      while (i <= 5) {
        if (formVals[`terms_${i}`] === false) {
          newError = 'Please accept terms & conditions to proceed'
          i = 6
          const contentsWrapper = document.getElementById('checkout-form-contents')
          if (contentsWrapper) {
            try {
              contentsWrapper.scrollIntoView({ block: 'end' })
            } catch (ex) {}
          }
        }
        i++
      }
    }
    return newError
  }

  const handleCardError = (errorMessage: string, errorCode: string | undefined, is_stripe_error: boolean = false) => {
    logAmplitudeEvent('stripe_error', {
      type: 'checkout',
      error_code: errorCode || 'none',
      error_message: errorMessage,
      project_id: transactionRequestData?.project_id,
    })
    setErrorMsg(errorMessage)
    setIsLoading(false)
  }

  const isCashFlow = useMemo(() => {
    return !!props.allActionData?.some(
      (action) => action.payment_method === 'cashflow' && action.status_code === 'available'
    )
  }, [props.allActionData])

  const onSuccess = useCallback(() => {
    setIsLoading(false)
    if (!isCashFlow) {
      props.onSuccess()
    } else {
      setShowCashFlowDeposit(true)
    }
  }, [isCashFlow])

  const getButtonLabel = useMemo(() => {
    if (props.actionData.status_code === 'complete' || props.actionData.status_code === 'hidden') return 'Dismiss'

    if (formState?.values?.selected_payment_method === OFFLINE) {
      const offlineActionData = props.allActionData?.find((action) => action.payment_method === OFFLINE)
      return offlineActionData?.action_title || 'Accept Proposal'
    }

    if (props.actionData?.payment_amount) {
      if (props.actionData?.action_title === null) return 'Make Payment'
      if (formState?.values?.selected_payment_method !== OFFLINE) return props.actionData?.action_title || ''
    }

    return props.actionData?.action_title || 'Accept Proposal'
  }, [props.actionData, transactionRequestData?.payment_stripe_key, formState?.values?.selected_payment_method])

  const onSubmit = async () => {
    // if the action is already completed then the user is just viewing their already signed contract. Just dismiss it
    if (props.actionData.status_code === 'complete' || props.actionData.status_code === 'hidden') {
      props.onClose()
      return
    }
    const formVals = form.getState().values as CheckoutFormValues
    let newError = undefined as string | undefined

    if (transactionRequestData) {
      newError = validateForm(formVals)
    }

    setErrorMsg(newError)
    if (!newError) {
      setIsLoading(true)
      var token: StripeTokenType | null = null

      // if stripe is required, get a stripe token to pass to back-end
      if (
        transactionRequestData.payment_amount > 0 &&
        transactionRequestData.payment_method === STRIPE_PAYMENT_METHOD &&
        formVals.selected_payment_method === STRIPE_PAYMENT_METHOD
      ) {
        if (!stripePaymentRef?.current) {
          handleCardError('Credit Card details were not collected and we cannot issue a payment', undefined, true)
          return
        }
        const { stripe, elements } = stripePaymentRef.current as any
        const cardElement = elements.getElement(CardElement)
        token = (await stripe.createToken(cardElement)).token
      } else {
        token = { id: -1 }
      }

      const checkoutData = {
        signature_data: formVals?.signature_data,
        amount: transactionRequestData?.payment_amount,
        payment_option_id: transactionRequestData?.payment_option_id,
        system_uuid: transactionRequestData?.system_uuid,
        payment_method: transactionRequestData?.payment_method,
        customer_name: formVals?.customer_name,
        token,
      } as CheckoutRequestData

      if (token?.card?.country && stripePaymentRef) {
        await checkoutStripeWith3DSecure(
          checkoutData,
          transactionRequestData,
          props.proposalData,
          stripePaymentRef,
          onSuccess,
          handleCardError
        ).catch((e) => {
          console.warn('error when checkout with 3D secure, ', e.message)
        })
        setIsStripeProcessing(true)
      } else {
        //@ts-ignore
        checkoutData.token = token.id
        checkout(checkoutData, transactionRequestData, props.proposalData, onSuccess, handleCardError)
      }
    }
  }

  const closeDialog = useCallback(() => {
    if (isCashFlow) {
      dispatch(markAsSold(props.actionData.system_uuid, props.actionData.payment_option_id))
      dispatch(getAvailableActions(props.actionData.org_id, props.actionData.project_id))
    }
    props.onClose()
  }, [isCashFlow])

  if (!props.proposalData?.fullCalcReady) {
    return (
      <Dialog open={props.isOpen} onClose={props.onClose}>
        <DialogContent>
          <div className={classes.loadingWrapper} data-testid="checkout-dialog-awaiting-full-calcs">
            <LoadingDots
              text={translate('Please wait until the proposal calculations are complete before proceeding')}
            />
          </div>
        </DialogContent>
      </Dialog>
    )
  } else {
    return (
      <Dialog
        open={props.isOpen}
        onClose={closeDialog}
        fullScreen={isFullScreen || (showCashFlowDeposit && isMobile)}
        maxWidth={showCashFlowDeposit ? 'xl' : undefined}
        disableEnforceFocus={isStripeProcessing}
      >
        <TransactionDialogTitle
          onClose={closeDialog}
          actionData={actionData}
          showCashFlowDeposit={showCashFlowDeposit}
        />
        <DialogContent>
          <div>
            {showCashFlowDeposit ? (
              <IntegratedCheckoutWrapper
                projectId={props.actionData.project_id}
                orgId={props.actionData.org_id}
                paymentOptionId={props.actionData.payment_option_id}
                systemUuid={props.actionData.system_uuid}
                showAcceptProposalAccordion={true}
              />
            ) : (
              <>
                {!changeOrderMode && (
                  <>
                    <PaymentMethodSelection proposalData={props.proposalData} />
                    <CheckoutQuotationTable
                      html={
                        props.proposalData?.selectedProject?.proposal_data?.proposal_template_settings
                          ?.quotation_custom_content
                      }
                      proposalData={props.proposalData}
                    />
                  </>
                )}
                <CheckoutContentsHeader changeOrderMode={changeOrderMode} />
                <NativeCheckoutContents
                  proposalData={props.proposalData}
                  toggleFullScreen={() => setIsFullScreen(!isFullScreen)}
                />
              </>
            )}
          </div>
        </DialogContent>
        {!showCashFlowDeposit && (
          <DialogActions>
            <div className={classes.actionsWrapper}>
              <div className={classes.errorWrapper}>
                <span className={classes.errorText} data-testid="checkout-form-error-message">
                  {errorMsg}
                </span>
              </div>
              <div className={classes.submitWrapper}>
                <Button
                  id={'transactionConfirmButton'}
                  onClick={onSubmit}
                  variant="contained"
                  color="secondary"
                  disabled={isLoading || isDisabled}
                  fullWidth={true}
                  data-testid="transaction-confirm-button"
                >
                  <div className={classes.buttonLabel} data-testid="transaction-confirm-button-label">
                    {isLoading ? (
                      <CircularProgress size={24} className={classes.buttonLoader} />
                    ) : (
                      translate(getButtonLabel)
                    )}
                  </div>
                </Button>
              </div>
            </div>
          </DialogActions>
        )}
      </Dialog>
    )
  }
}
const CheckoutDialog: React.FC<PropTypes> = (props) => {
  return (
    <SimpleForm>
      <CheckoutDialogFormContents {...props} />
    </SimpleForm>
  )
}
export default CheckoutDialog
