import appStorage from 'storage/appStorage'

const localStorage_ns = 'query:'
const localStorageTime_ns = 'query-time:'

export function getQueryParams(): Record<string, string | undefined> {
  return parseQueryStringToDictionary(getQueryString())
}

export function clearQueryParam(key: string, opts?: { notLS: boolean }): void {
  if (!opts?.notLS) {
    appStorage.clear(localStorage_ns + key)
    appStorage.clear(localStorageTime_ns + key)
  }

  const reg = new RegExp(`(\\?)&?${key}=[^&#]*`)
  let newLoc = window.location.href.replace(reg, '$1')
  newLoc = newLoc.replace('?#', '#') // If all of query/search was removed, remove the '?'
  if (newLoc === window.location.href) return
  window.location.replace(newLoc)
}
export function getHash(): string {
  return window.location.hash.split('?')[0]
}

// This gets around the issue where the query string is considered part of the hash if it is last
export function getQueryString(): string | undefined {
  let queryString = window.location.search || window.location.hash.split('?')[1]
  if (queryString && queryString.indexOf('?') === -1) {
    return '?' + queryString // Return query string with prepended '?' to make it consistent
  } else {
    return queryString
  }
}

export function setQueryParams(values: Record<string, string | number | undefined>): void {
  const query = getQueryString()
  const parsed = parseQueryStringToDictionary(query)
  for (const key in values) {
    const value = values[key]
    if (value === undefined) {
      delete parsed[key]
    } else {
      parsed[key] = value.toString()
    }
  }
  let newQuery = Object.entries(parsed)
    .map(([k, v]) => `${k}=${v}`)
    .join('&')
  if (newQuery.length) newQuery = '?' + newQuery
  let newLoc = window.location.origin + window.location.pathname + getHash() + newQuery
  window.location.replace(newLoc)
}

export function getQueryVariable(variable: string): string | undefined {
  return getQueryParams()[variable]
}

export function loadQueryVariable(
  variable: string,
  opts?: { clearLS?: boolean; preferLS?: boolean; expiry?: number }
): string | undefined {
  const storageName = localStorage_ns + variable
  const timeName = localStorageTime_ns + variable

  const locationValue = getQueryVariable(variable)
  let storageValue = appStorage.getString(storageName)

  if (opts?.expiry) {
    const storageTime = appStorage.getNumber(timeName)
    if (storageTime && storageTime < Date.now() - opts?.expiry) {
      // Stored value too old, ignore
      storageValue = undefined
    }
  }

  const value = opts?.preferLS ? storageValue || locationValue : locationValue || storageValue

  if (opts?.clearLS) {
    appStorage.clear(storageName)
    appStorage.clear(timeName)
  }
  return value
}

export function saveQueryVariable(variable: string): string | undefined {
  const value = getQueryVariable(variable)
  appStorage.setString(localStorage_ns + variable, value)
  appStorage.setNumber(localStorageTime_ns + variable, value === undefined ? undefined : Date.now())
  return value
}

export function parseQueryStringToDictionary(queryString: string | undefined): Record<string, string | undefined> {
  if (!queryString) return {}

  var dictionary = {}

  // remove the '?' from the beginning of the queryString if it exists
  if (queryString.indexOf('?') === 0) {
    queryString = queryString.substring(1)
  }

  // Step 1: separate out each key/value pair
  var parts = queryString.split('&')

  for (var i = 0; i < parts.length; i++) {
    var p = parts[i]
    // Step 2: Split Key/Value pair
    var keyValuePair = p.split('=')

    // Step 3: Add Key/Value pair to Dictionary object
    var key = keyValuePair[0]
    var value = keyValuePair[1]

    // decode URI encoded string, if it exists
    if (value) {
      value = decodeURIComponent(value)
      value = value.replace(/\+/g, ' ')
    } else {
      value = '' // keys with no value (e.g. '?is_lite&...')
    }

    dictionary[key] = value
  }

  // Step 4: Return Dictionary Object
  return dictionary
}
