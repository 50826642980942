var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { styled } from '../../styles/styled';
var StyledLink = styled('a')(function (_a) {
    var theme = _a.theme, textColor = _a.textColor;
    return {
        '&:link, &:visited, &:hover, &:active': {
            color: textColor,
        },
    };
});
export var PrequalActionLink = function (_a) {
    var textColor = _a.textColor, label = _a.label, onClick = _a.onClick;
    var handleClick = function (e) {
        e.preventDefault();
        onClick();
    };
    return (_jsx(StyledLink, __assign({ href: "#", textColor: textColor, onClick: handleClick }, { children: label })));
};
