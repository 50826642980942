import { Theme, makeStyles, useMediaQuery } from '@material-ui/core'
import { OpenSolarThemeType } from 'Themes'
import { useTranslate } from 'ra-core'
import React, { useMemo } from 'react'
import { useFormState } from 'react-final-form'
import Skeleton from 'react-loading-skeleton'
import { StudioSystemType } from 'types/global'
import { PaymentOptionDataType } from 'types/paymentOptions'
import CashFlowTransactionRow from './CashFlowTransactionRow'
import { useGetPaymentRequests } from './utils'

type PropTypes = {
  soldPaymentOption: PaymentOptionDataType
  soldSystem: StudioSystemType
}

const useStyles = makeStyles<OpenSolarThemeType, { isXs: boolean }>((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  headerRow: {
    backgroundColor: theme.greyLight1,
    color: theme.greyDark2,
  },
  headerLabel: {
    padding: '10px 0px',
    border: `1px solid ${theme.greyLight1}`,
  },
  cellText: {},
  nothingHereYet: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '60px 0px 20px 0px',
    color: theme.greyMid1,
  },
}))

const CashFlowTransactionTable: React.FC<PropTypes> = (props) => {
  const project = useFormState()?.values
  const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
  const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const translate = useTranslate()

  const classes = useStyles({ isXs })
  const { paymentRequests, isLoading } = useGetPaymentRequests(project?.id, props.soldSystem, props.soldPaymentOption)

  const pricePayable = useMemo(() => props.soldPaymentOption?.pricing?.system_price_payable, [
    props.soldPaymentOption?.pricing?.system_price_payable,
  ])

  if (!pricePayable) return null
  else if (isLoading) {
    return (
      <div className={classes.wrapper}>
        <Skeleton height={200} />
      </div>
    )
  } else
    return (
      <div className={classes.wrapper} data-testid="cashflow-transactions-table">
        <table className={classes.table}>
          <thead className={classes.headerRow}>
            <tr>
              {!isMd && <th className={classes.headerLabel}>{translate('Invoice/Payment #')}</th>}
              <th className={classes.headerLabel}>{translate('Milestone')}</th>
              {!isMd && <th className={classes.headerLabel}>{translate('Milestone')} %</th>}
              <th className={classes.headerLabel}>{translate('Status')}</th>
              {!isMd && <th className={classes.headerLabel}>{translate('Payment Method')}</th>}
              {!isXs && <th className={classes.headerLabel}>{translate('Invoice Sent')}</th>}
              {!isMd && <th className={classes.headerLabel}>{translate('Payment Due')}</th>}
              {!isXs && <th className={classes.headerLabel}>{translate('Payment Complete')}</th>}
              <th className={classes.headerLabel}>{translate('Payment Amount')}</th>

              <th className={classes.headerLabel} colSpan={2}>
                {translate('Actions')}
              </th>
            </tr>
          </thead>
          <tbody>
            {paymentRequests?.map((pmtReq, i) => (
              <CashFlowTransactionRow
                key={`${i}-${pmtReq.id}-${pmtReq.status}`}
                pricePayable={pricePayable}
                paymentRequest={pmtReq}
                systemUuid={props.soldSystem?.uuid}
                allPaymentRequests={paymentRequests}
                index={i}
              />
            ))}
          </tbody>
        </table>
        {!paymentRequests?.length && (
          <tr className={classes.nothingHereYet}>{translate('Nothing to show here yet...')}</tr>
        )}
      </div>
    )
}
export default CashFlowTransactionTable
