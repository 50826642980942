import { MilestoneConfigurationType } from './types'

export const NEW_MILESTONE_PLACEHOLDER: MilestoneConfigurationType = {
  title: '',
  description: '',
  percentage: undefined,
  min_fixed: undefined,
  max_fixed: undefined,
  set_value_to_remaining_amount_due: true,
  surcharging_enabled: false,
  accepted_payment_methods: [],
  payment_number: undefined,
  is_archived: false,
  payments_configuration: undefined,
  created_date: undefined,
}

export const US_STATES_FOR_POST_DEPOSIT_DEFAULT_ACH_PAYMENT = ['CT', 'ME', 'MA', 'PR', 'OK']

// a list of countries that allow surcharging generally, but not on debit cards
export const COUNTRIES_WITHOUT_DEBIT_CARD_SURCHARGING = ['US']
