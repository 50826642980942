import { styled } from '@material-ui/core'
import { PromoDialogMedia } from '../usePromoDialogStructs'

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
})

export const PromoImageElement = ({ element }: { element: PromoDialogMedia }) => {
  return (
    <Container>
      <div
        style={{
          width: element.media_width ? element.media_width + 'px' : '100%',
          height: element.media_height ? element.media_height + 'px' : 'unset',
          borderRadius: element.media_width ? 8 : 'unset',
          overflow: 'hidden',
        }}
      >
        <img
          src={element.media_url}
          style={{
            maxWidth: '100%',
            maxHeight: element.media_height ? element.media_height + 'px' : 'unset',
            height: element.media_height ? 'inherit' : 'unset',
          }}
        />
      </div>
    </Container>
  )
}
