import { CircularProgress, styled } from '@material-ui/core'
import { orgSelectors } from 'ducks/orgs'
import {
  Button,
  Dialog,
  DialogActions,
  DialogCloseButton,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  MenuItem,
  Select,
  Switch,
  Typography,
} from 'opensolar-ui'
import { CashFlowConfiguration, ConvertToCashFlowSettings } from 'pages/cashFlow/types'
import { useNotify, useTranslate } from 'ra-core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { saveConvertToCashFlowSetting } from './utils'

type PropTypes = {
  configs: CashFlowConfiguration[]
  existingSettings: ConvertToCashFlowSettings | null
}

const TitleRow = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'flex-start',
})

const ActionsRow = styled('div')({
  display: 'flex',
  justifyContent: 'flex-end',
  width: '100%',
  alignItems: 'flex-end',
  padding: '6px',
})

const ContentWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  margin: '20px 10px',
})

const InputsWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  width: '100%',
  margin: '20px 0px',
})

const InputRow = styled('div')({
  marginBottom: '20px',
  width: '100%',
})

const BannerButton: React.FC<PropTypes> = (props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [selectedConfig, setSelectedConfig] = useState<CashFlowConfiguration | undefined>(undefined)
  const [isEnabled, setIsEnabled] = useState<boolean>(false)

  const orgId = useSelector(orgSelectors.getOrg)?.id

  useEffect(() => {
    if (!selectedConfig && props.configs?.length) {
      let autoApplied = props.configs.find((config) => config.auto_apply)
      if (autoApplied) {
        setSelectedConfig(autoApplied)
      }
    }
  }, [props.configs, selectedConfig])

  useEffect(() => {
    if (props.existingSettings) {
      setIsEnabled(props.existingSettings.enabled)
      if (props.existingSettings.default_configuration_id) {
        setSelectedConfig(
          props.configs.find((config) => config.id === props.existingSettings?.default_configuration_id)
        )
      }
    }
  }, [props.existingSettings])

  const learnMoreLabel = useMemo(() => {
    if (props.existingSettings?.enabled) return 'Update Settings'
    return 'Learn More'
  }, [props.existingSettings?.enabled])

  const translate = useTranslate()
  const notify = useNotify()

  const onConfigSelection = useCallback(
    (e) => {
      let chosenConfig = props.configs?.find((config) => config.id === e.target.value)
      setSelectedConfig(chosenConfig)
    },
    [props.configs]
  )

  const onSave = () => {
    setIsLoading(true)
    saveConvertToCashFlowSetting(orgId, isEnabled, selectedConfig?.id)
      .then((res) => {
        notify('Your changes have been saved', 'success')
        setIsOpen(false)
      })
      .catch((err) => {
        console.log('Somthing went wrong and we were unable to save your changes', 'warning')
      })
      .finally(() => {
        setIsLoading(false)
      })
  }
  return (
    <>
      <Button variant="outlined" color="primary" onClick={() => setIsOpen(true)}>
        {learnMoreLabel}
      </Button>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>
          <TitleRow>
            <Typography textVariant="h4">Want to Convert Old Projects to CashFlow?</Typography>
            <DialogCloseButton onClick={() => setIsOpen(false)} />
          </TitleRow>
        </DialogTitle>
        <DialogContent>
          <ContentWrapper>
            <Typography textVariant="body1">
              Your older projects are not yet on CashFlow and are missing out on:
            </Typography>
            <ul>
              <li>
                <Typography textVariant="body1SemiBold">Embedded payment froms in proposals</Typography>
              </li>
              <li>
                <Typography textVariant="body1SemiBold">Post-deposit invoices</Typography>
              </li>
              <li>
                <Typography textVariant="body1SemiBold">Automated payment reminders</Typography>
              </li>
              <li>
                <Typography textVariant="body1SemiBold">Automated payment life-cycle tracking</Typography>
              </li>
            </ul>
            <Typography textVariant="body1">
              Converting existing projects to CashFlow will not affect pricing or result in any automated customer
              communication. Clicking "Save" will just add CashFlow functionality the next time a proposal or the
              Payments tab is viewed
            </Typography>
            <InputsWrapper>
              <Typography textVariant="subtitle1">Adjust Your Settings</Typography>
              <InputRow>
                <FormControlLabel
                  labelPlacement="end"
                  control={<Switch checked={isEnabled} onChange={() => setIsEnabled(!isEnabled)} />}
                  label="Automatically convert all projects to CashFlow?"
                />
              </InputRow>
              {isEnabled ? (
                <InputRow>
                  <FormControlLabel
                    labelPlacement="top"
                    style={{ marginLeft: '0px', alignItems: 'flex-start' }}
                    control={
                      <Select value={`${selectedConfig?.id}`} onChange={onConfigSelection} variant="outlined">
                        {props.configs
                          .filter((config) => !config.is_archived)
                          .map((config) => (
                            <MenuItem value={config.id} key={config.id}>
                              {config.title}
                            </MenuItem>
                          ))}
                      </Select>
                    }
                    label="What Scheme Should be Used?"
                  />
                </InputRow>
              ) : (
                <></>
              )}
            </InputsWrapper>
          </ContentWrapper>
        </DialogContent>
        <DialogActions>
          <ActionsRow>
            <Button variant="contained" color="primary" onClick={onSave} disabled={isLoading}>
              {isLoading ? <CircularProgress /> : <>Save</>}
            </Button>
          </ActionsRow>
        </DialogActions>
      </Dialog>
    </>
  )
}
export default BannerButton
