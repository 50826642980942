import * as Sentry from '@sentry/react'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { CheckoutActionCallback } from 'opensolar-checkout'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { showTransactionForm as showTransactionFormAction } from '../../ducks/transaction'
import { DialogHelper } from '../../util/misc'

const useAcceptProposalCallback = (): CheckoutActionCallback => {
  const dispatch = useDispatch()
  return useCallback(async ({ elementData, proposalData, additionalActionData }) => {
    DialogHelper.afterOpen()
    let quotationConfiguration = {}
    try {
      quotationConfiguration =
        (proposalData.proposalTemplateSettings &&
          proposalData.proposalTemplateSettings.quotation_table_configuration &&
          proposalData.proposalTemplateSettings.quotation_table_configuration.length > 0 &&
          JSON.parse(proposalData.proposalTemplateSettings.quotation_table_configuration)) ||
        {}
    } catch (e) {
      Sentry.captureException(new Error('Error on parsing quotation table config'))
    }
    if (elementData.type !== 'AcceptProposal') {
      Sentry.captureException(new Error('Invalid element data for AcceptProposal checkout callback'))
      return
    }
    const transactionRequestData = Object.assign({}, elementData.actionData, {
      paymentOptionData: proposalData.selectedPaymentOption,
      pricing: proposalData.selectedSystem.pricing,
      quotationConfiguration: quotationConfiguration,
      systemSize: proposalData.selectedSystem.kw_stc,
      additional_costs: proposalData.selectedSystem.additional_costs,
    })

    dispatch(
      showTransactionFormAction({
        transactionRequestData: transactionRequestData,
      })
    )

    const payload = {
      payment_method: transactionRequestData?.payment_method,
      collect_signature: transactionRequestData?.collect_signature,
      //   payment_option_type: transactionRequestData?.payment_option_type,
    }
    logAmplitudeEvent('transaction_started', payload)
  }, [])
}

export default useAcceptProposalCallback
