import DefaultIcon from '@material-ui/icons/Assignment'
import { Button, ButtonProps } from 'opensolar-ui'
import { useNotify, useTranslate } from 'ra-core'
import { FC, ReactElement } from 'react'
import { useForm } from 'react-final-form'
import { doNotTranslate } from 'util/misc'

export const PasteValuesButton: FC<PasteValuesButtonProps> = (props) => {
  const {
    label = 'Paste from clipboard',
    className,
    variant = 'contained',
    icon = defaultIcon,
    resource,
    translate: translateOverride,
    ...rest
  } = props

  const notify = useNotify()
  const translate = useTranslate()
  const form = useForm()

  const handleClick = async (event) => {
    try {
      let text = await navigator.clipboard.readText()
      const data = JSON.parse(text)

      if (resource !== data.resource) {
        throw new Error('Data of different resource type, aborting')
      }

      for (var i in data.values) {
        form.change(i, data.values[i])
      }

      notify('Form values pasted from clipboard!', 'success')
    } catch (err) {
      console.error(err)
      notify('Failed to paste from clipboard!', 'error')
    }
  }

  const doTranslate = (label: string, options: any) => {
    if (translateOverride) {
      return translateOverride(label, options)
    } else if (translateOverride === false) {
      return doNotTranslate(label)
    } else {
      return translate(label, options)
    }
  }

  const displayedLabel = label && doTranslate(label, { _: label })
  return (
    <Button
      className={className}
      variant={variant}
      name={'raSaveButton'}
      onClick={handleClick}
      color="default"
      aria-label={displayedLabel}
      {...rest}
    >
      {icon}
      <span>{displayedLabel}</span>
    </Button>
  )
}

const defaultIcon = <DefaultIcon />

interface Props {
  resource: string
  className?: string
  variant?: string
  label?: string
  icon?: ReactElement
  translate?: ((label: string, options?: any) => string) | false
}

type PasteValuesButtonProps = Props & Omit<ButtonProps, 'translate'>
