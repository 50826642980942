import { useEffect, useState } from 'react'
import restClient from 'restClient'
import { fetchJson, fetchRequest } from 'util/fetch'
import usePageVisibility from './usePageVisibility'

/*
  Calls an endpoint every Xms and calls onChange with the response.
  If the window is not visible, the polling is disabled.
  It will immediately call the endpoint once the window becomes visible again.

  When 'disable' becomes true, the polling is disabled, and the cache is cleared.
  This means that, when re-enabled, if the same result is returned as previously, the onChange will still get called.
*/
const restClientInstanceJson = restClient(window.API_ROOT + '/api')
const restClientInstanceText = restClient(window.API_ROOT + '/api', fetchRequest)
export const useEndpointPoller = <T = any>(
  endpoint: string,
  poll_time: number | undefined, // leave undefined to disable polling
  onChange: (a: T) => void,
  opts?: { disabled?: boolean; cache_bust?: boolean; format?: 'json' | 'text'; forceUpdate?: string }
) => {
  const [hadError, setHadError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [lastResponse, setLastResponse] = useState<string | undefined>()
  const pageVisible = usePageVisibility()

  const disabled = opts?.disabled || !pageVisible

  const isJson = opts?.format === undefined || opts?.format === 'json'

  const doLoad = () => {
    setLoading(true)

    const url = endpoint + (opts?.cache_bust ? Date.now() : '')
    const isUrlAbsolute = url.startsWith('http://') || url.startsWith('https://')
    let loadProm
    if (isUrlAbsolute) {
      loadProm = isJson ? fetchJson(url, {}) : fetchRequest(url)
    } else {
      loadProm = isJson
        ? restClientInstanceJson('CUSTOM_GET', 'custom', { url })
        : restClientInstanceText('CUSTOM_GET', 'custom', { url })
    }
    loadProm
      .then((res) => {
        // The two different request methods result in different response structures.
        // TODO: Should unify on a single fetch implementation
        const json = isJson ? res.json || res.data : null
        const str = res.body || (isJson ? JSON.stringify(json) : null)

        if (lastResponse === str) return // Don't update if the response hasn't changed
        setHadError(false)
        setLoading(false)
        setLastResponse(str)
        onChange(isJson ? (json as T) : str)
      })
      .catch((err) => {
        if (!hadError) {
          setHadError(true)
          setLoading(false)
          console.warn('Error polling endpoint: ' + endpoint, err)
        }
      })
  }

  useEffect(() => {
    if (disabled) return
    doLoad()
  }, [disabled, opts?.forceUpdate])

  useEffect(() => {
    if (disabled) {
      // Reset the cache when disabled
      setLastResponse(undefined)
    }
  }, [disabled])

  useEffect(() => {
    if (disabled || poll_time === undefined) return
    const interval = setInterval(doLoad, poll_time)

    return () => clearInterval(interval)
  }, [lastResponse, disabled, opts?.forceUpdate, poll_time])

  return { loading, hadError }
}
