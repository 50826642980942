import { InputAdornment } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import lodashDebounce from 'lodash/debounce'
import { BaseTheme, SearchOutlineIcon, styled, TextField } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { useCallback, useMemo, useState } from 'react'

const DEFAULT_DEBOUNCE = 500
const SearchField = styled(TextField, { name: 'SearchField' })({
  margin: 0,
  width: 400,
  maxWidth: '90%',
  marginRight: 10,
})

const SearchInput = (props) => {
  const { setFilters, filterValues } = props
  const translate = useTranslate()
  const [searchText, setSearchText] = useState('')

  const trackValueChanged = useCallback(() => {
    logAmplitudeEvent('generic_filter_interacted', {
      action: 'updated',
      key: 'q',
    })
  }, [])

  const debouncedTrackValueChanged = useMemo(() => {
    return lodashDebounce(trackValueChanged, DEFAULT_DEBOUNCE)
  }, [trackValueChanged])

  const handleSearch = (e) => {
    const queryVal = e?.target?.value
    debouncedTrackValueChanged()
    setFilters({ ...filterValues, q: queryVal })
    setSearchText(queryVal)
  }

  return (
    <SearchField
      label={false}
      size="small"
      value={searchText}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchOutlineIcon color={BaseTheme.palette.grey[700]} />
          </InputAdornment>
        ),
      }}
      onChange={handleSearch}
      variant="outlined"
      placeholder={translate('Search')}
    />
  )
}

export default SearchInput
