import { MenuItem } from '@material-ui/core'
import AddIcon from '@material-ui/icons/AddOutlined'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { DateTimePicker } from '@material-ui/pickers'
import { useBlockUpdatesWhileFocused } from 'elements/hooks/useBlockUpdatesWhileFocused'
import Tooltip from 'elements/tooltip/Tooltip'
import { IconButton, Select, styled, TextField, TrashOutlineIcon, Typography } from 'opensolar-ui'
import { useMemo } from 'react'
import { doNotTranslate } from 'util/misc'
import { FilterCondition, FilterField, FilterStruct, Op } from '../../conditions/types'
import { createDefaultCondition, opToSymbol } from '../../conditions/utils/utils'

const nowish = Date.now()

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
})
const RowWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: 16,
  alignItems: 'center',
  margin: 4,
})
const OpSelect = styled(Select)(({ theme }) => ({
  width: 140,
  [theme.breakpoints.down('md')]: {
    width: 100,
  },
}))
const Value = styled('div')({
  flexGrow: 1,
  flexBasis: '50px',
})
const Separator = styled('div')({
  margin: 16,
  backgroundColor: 'white',
  zIndex: 20,
  alignSelf: 'center',
})

type BaseProps = {
  fields: FilterField[]
  ops: Op[]
  fieldFreeSolo?: boolean
}

type FilterConditionBuilderProps = BaseProps & {
  filter: FilterStruct
  onChange: (filter: FilterStruct) => void
  onRemove: (filter: FilterStruct) => void
  isLast?: boolean
}

export const FilterConditionBuilder = ({
  filter,
  ops,
  onChange,
  onRemove,
  fields,
  isLast,
  fieldFreeSolo,
}: FilterConditionBuilderProps) => {
  const onConditionChange = (condition: FilterCondition) => {
    onChange(filter)
  }
  const onConditionRemove = (condition: FilterCondition) => {
    filter.conditions = filter.conditions.filter((c) => c !== condition)
    if (filter.conditions.length === 0) onRemove(filter)
    else onChange(filter)
  }
  const onAddConditionAfter = (condition: FilterCondition) => {
    const index = filter.conditions.indexOf(condition)
    filter.conditions.splice(index + 1, 0, createDefaultCondition(fields[0], ops[0]))
    onChange(filter)
  }
  return (
    <Wrapper>
      {filter.conditions.map((condition, index) => (
        <ConditionBuilderRow
          key={index}
          condition={condition}
          ops={ops}
          onChange={onConditionChange}
          onRemove={onConditionRemove}
          onAddAfter={onAddConditionAfter}
          fields={fields}
          fieldFreeSolo={fieldFreeSolo}
        />
      ))}
      {!isLast && <Separator>{doNotTranslate('OR')}</Separator>}
    </Wrapper>
  )
}

type ConditionBuilderRowProps = BaseProps & {
  condition: FilterCondition
  onChange: (condition: FilterCondition) => void
  onRemove: (condition: FilterCondition) => void
  onAddAfter: (condition: FilterCondition) => void
}

const ConditionBuilderRow = ({
  condition,
  ops,
  onChange,
  onRemove,
  onAddAfter,
  fields,
  fieldFreeSolo,
}: ConditionBuilderRowProps) => {
  const onFieldSelectChange = (e) => {
    condition.path = e.target.value
    onChange(condition)
  }
  const onFieldInputChange = (e, val) => {
    condition.path = val
    onChange(condition)
  }

  const { onChange: onInputChanged, forceInnerUpdate, ...fieldProps } = useBlockUpdatesWhileFocused({
    value: condition.path,
    onChange: onFieldInputChange,
    noInternalValueUpdates: true,
  })

  // onFieldAutocompleteChange gets fired after onFieldInputChange, so should correct the formatted value sent through there
  const onFieldAutocompleteChange = (_, val) => {
    if (!val?.path) return
    condition.path = val.path
    onChange(condition)
    forceInnerUpdate(condition.path)
  }
  const onOpChange = (e) => {
    condition.op = e.target.value as Op
    onChange(condition)
  }
  const onBooleanChange = (e) => {
    condition.value = e.target.value === 'yes'
    onChange(condition)
  }
  const onValueChange = (e) => {
    condition.value = parseStringValue(e.target.value)
    onChange(condition)
  }
  const onTimestampChange = (date) => {
    condition.value = date.getTime() / 1000
    onChange(condition)
  }
  const onArrayChange = (e) => {
    condition.value = e.target.value.split(',').map(parseStringValue)
    onChange(condition)
  }
  const parseStringValue = (value: string) => {
    if (!value) return null
    if (value === 'true') return true
    if (value === 'false') return false
    const asFloat = parseFloat(value)
    if (!isNaN(asFloat) && asFloat + '' === value.trim()) return asFloat

    return value
  }

  const currentField = useMemo(() => fields.find((field) => field.path === condition.path), [fields, condition.path])
  const arrayMode = useMemo(() => condition.op === 'in' || condition.op === 'nin', [condition.op])

  const showOps = useMemo(() => {
    // 'boolean' types are incompatible with op selector
    const isBoolean = currentField?.type === 'boolean'
    if (isBoolean && condition.op !== 'eq') {
      condition.op = 'eq'
      onChange(condition)
    }

    return !isBoolean && currentField?.type !== 'semver'
  }, [currentField])

  return (
    <RowWrapper>
      <Value>
        {fieldFreeSolo ? (
          <Tooltip title={currentField && doNotTranslate(currentField?.label)} placement="right">
            <Autocomplete
              options={fields}
              groupBy={(option) => option.group || doNotTranslate('Other')}
              getOptionLabel={(option) =>
                !option ? '' : typeof option === 'string' ? option : `${option.label} ${option.path}`
              }
              renderOption={(option) => (
                <div>
                  <Typography textVariant="body1">{option.label}</Typography>
                  <Typography textVariant="caption2">{option.path}</Typography>
                </div>
              )}
              onChange={onFieldAutocompleteChange}
              onInputChange={onInputChanged}
              renderInput={(params) => <TextField {...params} margin="none" variant="outlined" />}
              freeSolo={true}
              {...fieldProps}
            />
          </Tooltip>
        ) : (
          <Select
            margin="none"
            variant="outlined"
            value={condition.path}
            onChange={onFieldSelectChange}
            fullWidth={true}
          >
            {fields.map((field) => (
              <MenuItem key={field.path} value={field.path}>
                {doNotTranslate(field.label)}
              </MenuItem>
            ))}
          </Select>
        )}
      </Value>

      <OpSelect
        margin="none"
        variant="outlined"
        value={condition.op}
        onChange={onOpChange}
        fullWidth={true}
        style={{ opacity: showOps ? 1 : 0, pointerEvents: showOps ? 'auto' : 'none' }}
      >
        {ops.map((op) => (
          <MenuItem key={op} value={op}>
            {doNotTranslate(opToSymbol(op))}
          </MenuItem>
        ))}
      </OpSelect>

      <Value>
        {/* Not found */}
        {!currentField && !fieldFreeSolo && doNotTranslate('Field not found')}

        {/* Boolean */}
        {currentField?.type === 'boolean' ? (
          <Select
            margin="none"
            variant="outlined"
            fullWidth={true}
            value={!!condition.value ? 'yes' : 'no'}
            onChange={onBooleanChange}
          >
            <MenuItem value="yes">{doNotTranslate('Yes')}</MenuItem>
            <MenuItem value="no">{doNotTranslate('No')}</MenuItem>
          </Select>
        ) : arrayMode ? (
          //TODO: replace with non-RA ChipsInput
          <TextField
            margin="none"
            variant="outlined"
            fullWidth={true}
            onChange={onArrayChange}
            defaultValue={valueAsString(condition.value)}
          />
        ) : currentField?.type === 'timestamp' ? (
          <DateTimePicker
            margin="none"
            inputVariant="outlined"
            fullWidth={true}
            value={condition.value * 1000}
            onChange={onTimestampChange}
            defaultValue={nowish}
            emptyLabel={doNotTranslate('Select date')}
          />
        ) : (
          <TextField
            margin="none"
            variant="outlined"
            fullWidth={true}
            value={valueAsString(condition.value)}
            onChange={onValueChange}
          />
        )}
      </Value>

      <Tooltip title={doNotTranslate('Add AND Condition After')}>
        <IconButton size="small" onClick={() => onAddAfter(condition)}>
          <AddIcon />
        </IconButton>
      </Tooltip>

      <IconButton size="small" onClick={() => onRemove(condition)}>
        <TrashOutlineIcon />
      </IconButton>
    </RowWrapper>
  )
}

function valueAsArray(value: any) {
  return Array.isArray(value) ? value : typeof value === 'string' ? value.split(',') : [value]
}

function valueAsString(value: any) {
  return typeof value === 'string' ? value : Array.isArray(value) ? value.join(',') : value + ''
}
