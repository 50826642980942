import { styled } from '@material-ui/core'
import ResponsiveVimeoPlayer from 'elements/ResponsiveVimeoPlayer'
import { PromoDialogMedia } from '../usePromoDialogStructs'

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
})

export const PromoVideoElement = ({
  element,
  trackingSource,
}: {
  element: PromoDialogMedia
  trackingSource?: string
}) => {
  return (
    <Container>
      <div
        style={{
          width: element.media_width ? element.media_width + 'px' : '100%',
          height: element.media_height + 'px',
          borderRadius: element.media_width ? 8 : 'unset',
          overflow: 'hidden',
        }}
      >
        <ResponsiveVimeoPlayer videoUrl={element.media_url} trackingSource={trackingSource} />
      </div>
    </Container>
  )
}
