import { MapDataTypes } from 'types/map'

/**
 * This strategy attempts to pick the best imagery type for auto-design to work on.
 * This isn't available atm through the standard OS UI, only for SDK users through the SDK config.
 * It is possible to debug this mode by setting in local storage:
 * ```js
 * localStorage.setItem('defaultImageryStrategy', 'optimize-for-auto-design')
 * ```
 *
 * If this strategy succeeds, it will block the use of the `getImageryTypeDefault` / `imageryTypeDefault` system.
 *
 * We assume that the array of available imagery is already sorted by date.
 *
 * See #15284
 *
 * ## Strategy
 * 1. If high-quality GSA coverage is available, with building info, use this
 * 2. Otherwise, if NM3D coverage exists in the last 1.5 years, within the hotter months (4-8 or 8-4 hemisphere dependent) use this
 * 3. Otherwise, if NM3D coverage exists that doesn't meet the above criteria, use the most recent
 * 4. Otherwise, it falls back to the standard behaviour
 */

export const getDefaultImageryOptimizedForAutoDesign = (
  available: MapDataTypes[],
  lat: number
): MapDataTypes | null => {
  // 1. If high-quality GSA coverage is available, with building info, use this
  const highestQualityGSA = available.find(
    (imagery) =>
      imagery.map_type === 'Google3D' &&
      imagery.variation_data?.coverage_quality === 'HIGH' &&
      imagery.variation_data?.building_quality === 'HIGH'
  )
  if (highestQualityGSA) return highestQualityGSA

  // 2. Otherwise, if NM3D coverage exists in the last 1.5 years, within the hotter months (4-8 or 8-4 hemisphere dependent) use this
  const isNorthern = lat > 0
  const warmNM = available.find(
    (imagery) =>
      imagery.map_type === 'Nearmap3D' &&
      isDateWarm(isNorthern, imagery.variation_data?.capture_date) &&
      isRecent(imagery.variation_data?.capture_date)
  )
  if (warmNM) return warmNM

  // 3. Otherwise, if NM3D coverage exists that doesn't meet the above criteria, use the most recent
  const mostRecentNM = available.find((imagery) => imagery.map_type === 'Nearmap3D')
  if (mostRecentNM) return mostRecentNM

  // 4. Otherwise, it falls back to the standard behaviour
  return null
}

const isDateWarm = (isNorthern: boolean, date: string | undefined): boolean => {
  if (!date) return false
  const month = new Date(date).getMonth() + 1
  return isNorthern ? month >= 4 && month <= 8 : month <= 4 || month >= 8
}

const YEARS_OLD = 1.5
const isRecent = (date: string | undefined): boolean => {
  if (!date) return false
  return new Date(date).getTime() > Date.now() - 1000 * 60 * 60 * 24 * 365 * YEARS_OLD
}
