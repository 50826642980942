import classnames from 'classnames'
import { authSelectors } from 'ducks/auth'
import Tooltip from 'elements/tooltip/Tooltip'
import { useNotify } from 'ra-core'
import { FC } from 'react'
import { useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import type { RoleType } from 'types/roles'
import { doNotTranslate } from 'util/misc'
import restClient from '../../../restClient'

const useStyles = makeOpenSolarStyles((theme) => ({
  link: { marginTop: 20, marginBottom: 10 },
  disabled: { cursor: 'not-allowed' },
}))

var API_URL = window.API_ROOT + '/api'

var restClientInstance = restClient(API_URL)

type Props = {
  id?: string
  record?: RoleType
}

export const DeleteUser: FC<Props> = ({ id, record }) => {
  const translate = useTranslate()
  const notify = useNotify()
  const classes = useStyles()
  const managedByPartner = record?.managed_by
  const myRole = useSelector(authSelectors.getCurrentRole)
  const myRoleId = myRole?.id
  const disableDeletion = !!managedByPartner
  const disableTooltip = !disableDeletion

  if (!id || !myRoleId || String(myRoleId) !== id) return null

  return (
    <Tooltip
      disableHoverListener={disableTooltip}
      disableFocusListener={disableTooltip}
      disableTouchListener={disableTooltip}
      title={doNotTranslate(`This team member is managed by ${managedByPartner} and must be deleted from that system`)}
    >
      <a
        href="#"
        className={classnames('small', classes.link, disableDeletion ? classes.disabled : '')}
        onClick={(e) => {
          e.preventDefault()
          if (disableDeletion) return
          var userConfirmation = prompt(
            'Warning. This cannot be undone. To delete your user login and all associated roles, type "delete me"'
          )
          restClientInstance('AUTH_USER_DELETE', 'delete_user', {
            url: '/auth/users/delete',
            data: { confirmation: userConfirmation },
          })
            .then((response) => {
              notify('Your user login has been deleted', 'warning')
            })
            .catch((error) => {
              console.log(error)
              notify('Unable to delete.', 'warning')
            })
        }}
      >
        {translate('Delete My User Login and Roles')}
      </a>
    </Tooltip>
  )
}
