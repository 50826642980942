import { MarkerOutlineIcon } from 'opensolar-ui'
import AccordionCard from 'projectSections/sections/info/AccordionCard'
import { useTranslate } from 'ra-core'
import React from 'react'
import { useFormState } from 'react-final-form'
import CommercialSiteFields from './fieldsets/CommercialSiteFields'
import GeneralSiteInfoFields from './fieldsets/GeneralSiteInfoFields'
import LocationStructureFields from './fieldsets/LocationStructureFields'
import UtilityFields from './fieldsets/UtilityFields'
import { InputContainer, SectionHeader } from './styles'

const SiteAccordion: React.FC = (props) => {
  const translate = useTranslate()
  return (
    <AccordionCard
      name={'site'}
      elevation={0}
      title={translate('Site Details')}
      titleIcon={MarkerOutlineIcon}
      content={SiteContent}
      lazyRender={true}
      ux3={true}
    />
  )
}

const SiteContent = (props) => {
  const translate = useTranslate()
  const project = useFormState().values

  return (
    <InputContainer>
      <SectionHeader>{translate('General Information')}</SectionHeader>
      {!project?.is_residential && <CommercialSiteFields countryCode={project?.country_iso2} />}
      <GeneralSiteInfoFields />
      <SectionHeader>{translate('Location and Structural Details')}</SectionHeader>
      <LocationStructureFields />
      <SectionHeader>{translate('Utility Details')}</SectionHeader>
      <UtilityFields />
    </InputContainer>
  )
}

export default SiteAccordion
