import { styled } from 'opensolar-ui'
import { useContext, useMemo } from 'react'
import { ORDER_STATUS } from 'resources/inventoryTransfer/constants'
import { OrderContext } from '../..'
import ProjectContacts from '../../../manage3/details/elements/contacts/ProjectContacts'
import ProjectTeams from '../../../manage3/details/elements/teams/ProjectTeams'
import ProjectInstall from './ProjectInstall'

const divider = 1
const columnGap = 20
const border = 2

const FlexWrapper = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'stretch',
  marginLeft: columnGap / -2,
  overflow: 'hidden',
  rowGap: 10,
})

const StyledDetail = styled('div')(({ theme }) => ({
  height: 65,
  marginLeft: columnGap / 2,
  marginRight: columnGap / 2,
  position: 'relative',
  '& p': {
    fontWeight: 500,
    margin: 0,
    color: theme.palette.grey[810],
  },
  '&:after': {
    content: '""',
    position: 'absolute',
    right: '100%',
    top: '50%',
    display: 'inline-block',
    width: divider,
    height: '100%',
    backgroundColor: theme.palette.secondary.light,
    transform: 'translateY(-50%)',
    marginRight: (columnGap - divider) / 2 + border,
  },
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    height: 30,
    columnGap: 5,
    margin: '5px 0px',
    '&:after': {
      display: 'none !important',
    },
  },
}))

const StyledCustomWidth = styled(StyledDetail)(({ theme }) => ({
  width: 190,
}))

const StyledDetailContent = styled('div')(({ theme }) => ({
  marginTop: 8,
  minHeight: 30,
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.grey[810],
}))

const InfoFooter = () => {
  const { orderData } = useContext(OrderContext)
  const totalPartsOrdered = useMemo(() => {
    let totalOrderItems = 0
    orderData.forEach((order) => {
      totalOrderItems += order.order_item_quantity
    })
    return totalOrderItems
  }, [orderData])

  const totalOrder = useMemo(() => {
    return orderData.filter((order) => order.status !== ORDER_STATUS.QUOTE_SUBMITTED).length
  }, [orderData])

  const totalCost = useMemo(() => {
    let totalCost = 0
    orderData.map((order) => order.line_items?.map((item) => (totalCost += item.quantity * item.unit_cost)))
    return totalCost
  }, [orderData])

  const totalDelivery = useMemo(() => {
    return orderData.filter((order) => order.status === ORDER_STATUS.DELIVERED && order.is_arrived).length
  }, [orderData])

  return (
    <FlexWrapper>
      <ProjectContacts label="Customer Details" />
      <ProjectInstall />
      <ProjectTeams label="Installer Assigned" />
      <StyledCustomWidth>
        <p>{'Total Order(s)'}</p>
        <StyledDetailContent>
          <span>{totalOrder}</span>
        </StyledDetailContent>
      </StyledCustomWidth>
      <StyledCustomWidth>
        <p>{'Total Parts Ordered'}</p>
        <StyledDetailContent>
          <span>{`${totalPartsOrdered} Items`}</span>
        </StyledDetailContent>
      </StyledCustomWidth>
      <StyledCustomWidth>
        <p>{'Order(s) Delivered'}</p>
        <StyledDetailContent>
          <span>{totalDelivery}</span>
        </StyledDetailContent>
      </StyledCustomWidth>
    </FlexWrapper>
  )
}

export default InfoFooter
