var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useEditController } from 'ra-core';
import React, { Children, cloneElement } from 'react';
import Subtitle from '../layout/Subtitle';
import Title from '../layout/Title';
import DefaultBreadCrumbs from './BreadCrumbs';
import DefaultActions from './EditActions';
/**
 * Page component for the Edit view
 *
 * The `<Edit>` component renders the page title and actions,
 * fetches the record from the data provider.
 * It is not responsible for rendering the actual form -
 * that's the job of its child component (usually `<SimpleForm>`),
 * to which it passes pass the `record` as prop.
 *
 * The <Edit> component accepts the following props:
 *
 * - actions
 * - aside
 * - component
 * - successMessage
 * - title
 * - undoable
 *
 * @example
 *
 * // in src/posts.js
 * import React from 'react';
 * import { Edit, SimpleForm, TextInput } from 'react-admin';
 *
 * export const PostEdit = (props) => (
 *     <Edit {...props}>
 *         <SimpleForm>
 *             <TextInput source="title" />
 *         </SimpleForm>
 *     </Edit>
 * );
 *
 * // in src/App.js
 * import React from 'react';
 * import { Admin, Resource } from 'react-admin';
 *
 * import { PostEdit } from './posts';
 *
 * const App = () => (
 *     <Admin dataProvider={...}>
 *         <Resource name="posts" edit={PostEdit} />
 *     </Admin>
 * );
 * export default App;
 */
var getBasePath = function (location) { return location.pathname.split('/').slice(0, -1).join('/'); };
var Edit = function (_a) {
    var _b = _a.basePath, basePath = _b === void 0 ? undefined : _b, _c = _a.id, id = _c === void 0 ? undefined : _c, _d = _a.hasDelete, hasDelete = _d === void 0 ? true : _d, _e = _a.undoable, undoable = _e === void 0 ? undefined : _e, props = __rest(_a, ["basePath", "id", "hasDelete", "undoable"]);
    // set basePath and id automatically
    // disable undoable behavior
    var actionsUndoable = false;
    var newProps = __assign(__assign({}, props), { basePath: basePath || getBasePath(props.location), id: id || decodeURIComponent(props.match.params.id), undoable: undoable || actionsUndoable, hasDelete: hasDelete });
    return _jsx(EditView, __assign({}, newProps, useEditController(newProps)));
};
Edit.propTypes = {
    actions: PropTypes.element,
    aside: PropTypes.element,
    children: PropTypes.node,
    classes: PropTypes.object,
    className: PropTypes.string,
    hasCreate: PropTypes.bool,
    hasEdit: PropTypes.bool,
    hasShow: PropTypes.bool,
    hasList: PropTypes.bool,
    id: PropTypes.any,
    resource: PropTypes.string.isRequired,
    title: PropTypes.node,
    successMessage: PropTypes.string,
};
export var EditView = function (props) {
    var _a;
    var actions = props.actions, aside = props.aside, basePath = props.basePath, children = props.children, classesOverride = props.classes, className = props.className, Content = props.component, defaultTitle = props.defaultTitle, hasList = props.hasList, hasDelete = props.hasDelete, hasShow = props.hasShow, record = props.record, redirect = props.redirect, resource = props.resource, save = props.save, saving = props.saving, title = props.title, breadCrumbs = props.breadCrumbs, hideBreadCrumbs = props.hideBreadCrumbs, hideTitle = props.hideTitle, options = props.options, subtitle = props.subtitle, undoable = props.undoable, version = props.version, renderChildrenBeforeRecord = props.renderChildrenBeforeRecord, rest = __rest(props, ["actions", "aside", "basePath", "children", "classes", "className", "component", "defaultTitle", "hasList", "hasDelete", "hasShow", "record", "redirect", "resource", "save", "saving", "title", "breadCrumbs", "hideBreadCrumbs", "hideTitle", "options", "subtitle", "undoable", "version", "renderChildrenBeforeRecord"]);
    var classes = useStyles(props);
    var finalActions = typeof actions === 'undefined' && hasList ? _jsx(DefaultActions, {}) : actions;
    var finalTitle = title || (options.edit && options.edit.title);
    var finalSubtitle = subtitle || (options.edit && options.edit.subtitle);
    if (!children) {
        return null;
    }
    return (_jsxs("div", __assign({ className: classnames('edit-page', classes.root, className) }, sanitizeRestProps(rest), { children: [!hideBreadCrumbs &&
                resource &&
                React.cloneElement(breadCrumbs, {
                    options: options,
                    resource: resource,
                    hasList: hasList,
                    detail: 'edit',
                }), _jsxs("div", __assign({ className: classnames(classes.main, (_a = {},
                    _a[classes.noActions] = !finalActions,
                    _a)) }, { children: [_jsxs(Content, __assign({ className: classes.card }, { children: [!hideTitle && (_jsxs("div", __assign({ style: { padding: '0px 16px 0 16px' } }, { children: [_jsxs("div", __assign({ style: {
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            minHeight: 68,
                                            alignItems: 'center',
                                        } }, { children: [_jsx(Title, { title: finalTitle, record: record, defaultTitle: defaultTitle }), finalActions &&
                                                cloneElement(finalActions, __assign({ basePath: basePath, data: record, hasShow: hasShow, hasList: hasList, hasDelete: hasDelete, resource: resource }, finalActions.props))] })), finalSubtitle && _jsx(Subtitle, { subtitle: finalSubtitle })] }))), record || renderChildrenBeforeRecord ? (cloneElement(Children.only(children), {
                                basePath: basePath,
                                record: record,
                                redirect: typeof children.props.redirect === 'undefined' ? redirect : children.props.redirect,
                                resource: resource,
                                save: save,
                                saving: saving,
                                undoable: undoable,
                                version: version,
                            })) : (_jsx(CardContent, { children: "\u00A0" }))] })), aside &&
                        React.cloneElement(aside, {
                            basePath: basePath,
                            record: record,
                            resource: resource,
                            version: version,
                            save: save,
                            saving: saving,
                        })] }))] })));
};
EditView.propTypes = {
    actions: PropTypes.element,
    aside: PropTypes.element,
    basePath: PropTypes.string,
    children: PropTypes.element,
    classes: PropTypes.object,
    className: PropTypes.string,
    // component: PropTypes.oneOfType([ComponentPropType, UndefinedPropType]),
    defaultTitle: PropTypes.any,
    hasList: PropTypes.bool,
    hasShow: PropTypes.bool,
    record: PropTypes.object,
    redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    resource: PropTypes.string,
    save: PropTypes.func,
    title: PropTypes.node,
    version: PropTypes.number,
    renderChildrenBeforeRecord: PropTypes.bool,
};
EditView.defaultProps = {
    classes: {},
    options: {},
    component: Card,
    breadCrumbs: _jsx(DefaultBreadCrumbs, {}),
    renderChildrenBeforeRecord: false,
};
var useStyles = makeStyles({
    root: {},
    main: {
        display: 'flex',
    },
    noActions: {
    // marginTop: '12px',
    },
    card: {
        marginTop: '12px',
        flex: '1 1 auto',
    },
}, { name: 'RaEdit' });
var sanitizeRestProps = function (_a) {
    var data = _a.data, hasCreate = _a.hasCreate, hasEdit = _a.hasEdit, hasList = _a.hasList, hasShow = _a.hasShow, id = _a.id, loading = _a.loading, loaded = _a.loaded, saving = _a.saving, resource = _a.resource, title = _a.title, version = _a.version, match = _a.match, location = _a.location, history = _a.history, options = _a.options, locale = _a.locale, permissions = _a.permissions, undoable = _a.undoable, successMessage = _a.successMessage, translate = _a.translate, accessRights = _a.accessRights, rest = __rest(_a, ["data", "hasCreate", "hasEdit", "hasList", "hasShow", "id", "loading", "loaded", "saving", "resource", "title", "version", "match", "location", "history", "options", "locale", "permissions", "undoable", "successMessage", "translate", "accessRights"]);
    return rest;
};
export default Edit;
