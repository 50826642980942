import CalendarIcon from '@material-ui/icons/DateRangeOutlined'
import { authSelectors } from 'ducks/auth'
import useTrackComponent from 'hooks/useTrackComponent'
import { Button, styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { showNotification } from 'react-admin'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { handleCsvDownload } from '../util'

const SecondaryButton = styled(Button)({
  marginRight: 10,
})

const MainHeader = styled('h1', { name: 'MainHeader' })(({ theme }) => ({
  fontWeight: 600,
  fontSize: 24,
  marginTop: 0,
  marginBottom: 10,
  color: theme.palette.primary.contrastText,
}))

const Subheader = styled('p')(({ theme }) => ({
  margin: 0,
  fontSize: 16,
  fontWeight: 400,
  color: theme.palette.grey[900],
}))

const LeftDiv = styled('div')({
  marginRight: 'auto',
  display: 'flex',
  flexDirection: 'column',
})
const RightDiv = styled('div')({
  marginLeft: 'auto',
  display: 'flex',
  flexWrap: 'wrap',
  rowGap: 10,
})

const FullRow = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  flexWrap: 'wrap',
  rowGap: 10,
})

const ProjectsHeader = (props) => {
  const history = useHistory()
  const translate = useTranslate()
  const orgId = useSelector(authSelectors.getOrgId)

  const { trackInteraction } = useTrackComponent({ eventKey: 'export_to_csv', eventName: 'Export to CSV' })

  const handleCSVDownload = () => {
    handleCsvDownload(
      `${window.API_BASE_URL}orgs/${orgId}/projects/?fieldset=csv&format=csv&ordering=-id`,
      showNotification,
      props.filterValues,
      'OpenSolarProjects'
    )
    trackInteraction('click', { uiKey: 'projects.buttons.export_to_csv' })
  }
  return (
    <FullRow>
      <LeftDiv>
        <MainHeader>{translate('Projects')}</MainHeader>
        <Subheader>{translate('View and manage your projects')}</Subheader>
      </LeftDiv>
      <RightDiv>
        <SecondaryButton href="#/calendar" variant="outlined" color="secondary" startIcon={<CalendarIcon />}>
          {translate('Calendar')}
        </SecondaryButton>
        <SecondaryButton variant="outlined" color="secondary" onClick={handleCSVDownload}>
          {translate('Export to CSV')}
        </SecondaryButton>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            history.push('/home')
          }}
        >
          {translate('Create Project')}
        </Button>
      </RightDiv>
    </FullRow>
  )
}

export default ProjectsHeader
