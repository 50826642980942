import { useTranslateOrTranslatableText } from 'hooks/translation/useTranslateOrTranslatableText'
import { usePerformDynamicAction } from 'hooks/usePerformDynamicAction'
import { Button } from 'opensolar-ui'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { DynamicActionInfo } from 'types/ui/DynamicActionInfo'

/**
 * Used for building a row of buttons from an external json config
 */

const useStyles = makeOpenSolarStyles((theme) => ({
  button: {
    fontSize: 14,
    margin: 5,
  },
}))

export const DynamicButtonRow = ({ buttons }: { buttons: DynamicActionInfo[] }) => {
  const classes = useStyles()
  const translate = useTranslateOrTranslatableText()
  const performAction = usePerformDynamicAction()

  return (
    <>
      {buttons?.map((action) => {
        const label = translate(action.label)
        return (
          <Button
            key={label}
            classes={{ root: classes.button }}
            variant={action.variant || 'contained'}
            color={action.color}
            size={action.size}
            onClick={() => performAction(action)}
          >
            <span>{label}</span>
          </Button>
        )
      })}
    </>
  )
}
