import { CircularProgress, Dialog } from '@material-ui/core'
import { Button, DialogTitle, styled } from 'opensolar-ui'
import { SimpleForm, Toolbar, useTranslate } from 'react-admin'

const DiscardBtn = styled(Button, { name: 'DiscardBtn' })({
  marginRight: '5px !important',
})

const FormWrapper = styled('div', { name: 'FormWrapper' })(({ theme }) => ({
  margin: '0 3px',
  '& .MuiFormLabel-root': {
    fontSize: 14,
    color: theme.palette.grey[810],
  },
}))
export interface EventDialogProps {
  open: boolean
  onClose: () => void
  headerText?: string
  content: React.ReactNode
  initialValues: object | null | undefined
  handleSubmit: (values) => void
  formatValues?: (values) => object | null | undefined
  validate?: (values) => object | undefined
  toolbarBtn?: React.ReactNode
  discardBtnLabel?: string
  submitBtnLabel?: string
  loading?: boolean
}

const EventFormDialog: React.FC<EventDialogProps> = (props) => {
  const { open, headerText } = props
  return (
    <Dialog open={open}>
      <DialogTitle>{headerText}</DialogTitle>
      <EventFormContent {...props} />
    </Dialog>
  )
}

const EventFormContent = (props) => {
  const { handleSubmit, initialValues, content, validate } = props
  return (
    <SimpleForm
      toolbar={<DialogFormToolbar {...props} />}
      save={handleSubmit}
      initialValues={initialValues}
      formatSubmitValues={props.formatValues}
      validate={validate}
    >
      <FormWrapper>{content}</FormWrapper>
    </SimpleForm>
  )
}
const StyledToolbar = styled(Toolbar, { name: 'StyledToolbar' })(({ theme }) => ({
  borderTop: theme.mixins.borderStandard.border,
  padding: '0 20px',
  marginTop: '0 !important',
  backgroundColor: 'white',
}))

const DialogFormToolbar = (props) => {
  const translate = useTranslate()

  return (
    <StyledToolbar>
      <>
        {props.toolbarBtn}
        <DiscardBtn variant="contained" color="default" onClick={props.onClose}>
          {props.discardBtnLabel || translate('Discard')}
        </DiscardBtn>
        <Button
          startIcon={props.loading ? <CircularProgress size={18} color={'inherit'} /> : undefined}
          disabled={props.pristine || props.loading || props.invalid}
          variant="contained"
          color="primary"
          onClick={props.handleSubmit}
        >
          {props.submitBtnLabel || translate('Confirm')}
        </Button>
      </>
    </StyledToolbar>
  )
}

export default EventFormDialog
