import { tourSelectors } from 'ducks/tour'
import { useSelector } from 'react-redux'
import { PromoDialogView } from './PromoDialogView'
import { usePromoDialogLogic } from './usePromoDialogLogic'

export const PromoDialogManager = () => {
  const {
    navigableStructs,
    currentPromo,
    onBack,
    onNext,
    nextWillClose,
    backEnabled,
    trackingKey,
    open,
    onCloseComplete,
  } = usePromoDialogLogic()
  const tour = useSelector(tourSelectors.tour)
  const step = useSelector(tourSelectors.step)
  const showingTour = !!(tour && step)

  const currentIsBlocker = currentPromo?.data.type === 'blocker'
  const showPagination = !currentIsBlocker && navigableStructs.length > 1
  const nextEnabled = currentPromo?.data.type !== 'tour'

  const showActionsBar = !currentIsBlocker && (showPagination || nextEnabled)

  return (
    <PromoDialogView
      open={!showingTour && open}
      showActionsBar={showActionsBar}
      showPagination={showPagination}
      onCloseComplete={onCloseComplete}
      elements={currentPromo?.data.elements}
      totalPages={navigableStructs.length}
      currentPage={currentPromo ? navigableStructs.indexOf(currentPromo) : -1}
      backEnabled={backEnabled}
      nextEnabled={nextEnabled}
      trackingKey={trackingKey}
      nextWillClose={nextWillClose}
      onBack={onBack}
      onNext={onNext}
    />
  )
}
