import { Typography } from '@material-ui/core'
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined'
import { authSelectors } from 'ducks/auth'
import { StyledDialog, StyledDialogContent, StyledDialogTitle } from 'layout/StyledDialog'
import { Button, IconButton } from 'opensolar-ui'
import { HardwareSupplierPresetConfig } from 'pages/ordering/configs'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { CityPlumbingConnectUserButton } from 'services/cityPlumbing/CityPlumbingConnectUserButton'
import HdmConnectUserButton from 'services/hdm/HdmConnectUserButton'
import SegenConnectUserButton from 'services/segen/SegenConnectUserButton'
import VtacConnectUserButton from 'services/vtac/VtacConnectUserButton'
import { OpenSolarThemeType } from 'Themes'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import DistributorChip from './DistributorChip'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  container: {
    paddingTop: 10,
    paddingBottom: 60,
    paddingLeft: 40,
    paddingRight: 40,
  },
  dialogTitle: {
    display: 'flex',
    flexDirection: 'column',
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 15,
  },
  mainLogo: {
    width: 140,
    height: 56,
    objectFit: 'fill',
  },
  textWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
    padding: 5,
    maxWidth: '100%',
  },
  closeIcon: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  chipContainer: {
    minWidth: 280,
  },
}))

const DistributorConnectButton = ({ config }) => {
  const classes = useStyles()
  const history = useHistory()
  const orgId = useSelector(authSelectors.getOrgId)

  const directToEnable = () => {
    history.push(`/external_api_keys/${orgId}`)
  }

  if (!config.isVisible) {
    return null
  }

  if (!config.isEnabled) {
    const enableButton = {
      segen: (
        <Button color="default" onClick={() => directToEnable()}>
          Enable Segen
        </Button>
      ),
      city: (
        <Button color="default" onClick={() => directToEnable()}>
          Enable City Plumbing
        </Button>
      ),
      hdm: (
        <Button color="default" onClick={() => directToEnable()}>
          Enable HDM
        </Button>
      ),
      vtac: (
        <Button color="default" onClick={() => directToEnable()}>
          Enable V-TAC
        </Button>
      ),
      trustsolar: (
        <Button color="default" onClick={() => directToEnable()}>
          Enable Trust Solar
        </Button>
      ),
    }[config.logoUrl.match(/(segen|city|hdm|vtac|trustsolar)/)[0]]

    return (
      <div className={classes.chipContainer}>
        <DistributorChip isWrapped={true} config={config} button={enableButton} />
      </div>
    )
  }

  if (!config.isConnected) {
    const connectUserButton = {
      segen: (
        <SegenConnectUserButton
          label="Connect to Segen"
          variant={'text'}
          layout={null}
          allowOrdering={false}
          trackSource={'manage_page'}
        />
      ),
      city: (
        <CityPlumbingConnectUserButton
          label="Connect to City Plumbing"
          variant={'text'}
          layout={null}
          allowOrdering={false}
          trackSource={'manage_page'}
        />
      ),
      hdm: <HdmConnectUserButton label="Connect to HDM" variant={'text'} trackSource={'manage_page'} />,
      vtac: <VtacConnectUserButton label="Connect to V-TAC" variant={'text'} trackSource={'manage_page'} />,
      trustsolar: (
        <Button
          onClick={() => {
            history.push('/shop/connect_distributor/trustsolar/email')
          }}
          variant={'text'}
          color={'default'}
        >
          Connect to Trust Solar
        </Button>
      ),
    }[config.logoUrl.match(/(segen|city|hdm|vtac|trustsolar)/)[0]]

    return (
      <div className={classes.chipContainer}>
        <DistributorChip isWrapped={true} config={config} button={connectUserButton} />
      </div>
    )
  }

  return <></>
}

const ConnectModal = ({
  open,
  distributorConfigs,
  onClose,
}: {
  open: boolean
  distributorConfigs: HardwareSupplierPresetConfig[]
  onClose: () => void
}) => {
  const classes = useStyles()

  return (
    <StyledDialog
      open={open}
      onClose={() => {
        onClose()
      }}
      onBackdropClick={() => {
        onClose()
      }}
    >
      <section className={classes.closeIcon}>
        <IconButton
          onClick={() => {
            onClose()
          }}
        >
          <CloseOutlinedIcon />
        </IconButton>
      </section>
      <section className={classes.container}>
        <StyledDialogTitle style={{ borderBottom: 'none' }} className={classes.dialogTitle}>
          <img src={`${window.PUBLIC_URL}/images/logo.svg`} alt="OPENSOLAR_LOGO" className={classes.mainLogo} />
          <Typography variant="h6" className={classes.textWrapper}>
            Connect Your Hardware Supplier
          </Typography>
          <Typography variant="subtitle1" className={classes.textWrapper}>
            Gain access to discounted trade prices and stock availability with our renewable energy distributors for
            your projects
          </Typography>
        </StyledDialogTitle>
        <StyledDialogContent className={classes.dialogContent}>
          {distributorConfigs?.map((config) => (
            <DistributorConnectButton key={config.filterKey} config={config} />
          ))}
        </StyledDialogContent>
      </section>
    </StyledDialog>
  )
}

export default ConnectModal
