import { makeStyles } from '@material-ui/core'
import { Help } from '@material-ui/icons'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import { paymentOptionSelectionSelectors } from 'ducks/paymentOptionSelection'
import { Button, styled, Switch, TextField, Tooltip } from 'opensolar-ui'
import { useGetSoldRecords } from 'projectSections/sections/payments/cashFlowTransactions/utils'
import { useIsProjectUsingCashFlow } from 'projectSections/sections/payments/hooks'
import React, { useMemo, useState } from 'react'
import { useNotify, useTranslate } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import ConfirmModal from '../../../common/ConfirmModal'
import { InputRow } from '../../../styles'
const restClientInstance = restClient(window.API_ROOT + '/api')
var QRCode = require('qrcode-svg')
const useStyles = makeStyles({
  marginRight: {
    marginRight: 10,
  },
  marginLeft: {
    marginLeft: 'auto',
  },
  switchStyle: {
    marginLeft: 'auto',
    marginRight: '0 !important',
  },
  linkInput: {
    marginRight: 10,
  },
  qrText: {
    maxWidth: 300,
  },
})

const StyledButton = styled(Button)({
  height: 40,
  width: 120,
})

const InfoIcon = styled(Help, { name: 'InfoIcon' })(({ theme }) => ({
  color: theme.palette.grey[700],
  height: 18,
  width: 18,
}))

const RowWrapper = styled('div', { name: 'RowWrapper' })(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.palette.grey[50],
  borderRadius: 4,
  padding: 16,
  margin: '20px 0',
  '& h3': {
    fontWeight: 500,
  },
}))
const LinkSharing: React.FC = (props) => {
  const form = useForm()
  const notify = useNotify()
  const classes = useStyles()
  const translate = useTranslate()
  const project = useFormState().values
  const orgId = useSelector(authSelectors.getOrgId)
  const shareEnabled = useMemo(() => !!project.share_link, [project.share_link])

  const [soldSystem, soldPaymentOption] = useGetSoldRecords(project)
  const refreshSystemsTrigger = useSelector(paymentOptionSelectionSelectors.getSystemRefreshTrigger)
  const isProjectUsingCashflow = useIsProjectUsingCashFlow(soldSystem, soldPaymentOption, refreshSystemsTrigger)

  const copyUrl = () => {
    navigator.clipboard.writeText(project.share_link).then(
      function () {
        notify(translate('Link copied to clipboard'), 'success')
      },
      function (err) {
        notify(translate('Failed to copy link to clipboard'), 'warning')
      }
    )
  }
  const [openDisableModal, setDisableModal] = useState(false)
  const handleSharing = (newValue) => {
    logAmplitudeEvent(newValue ? 'link_sharing_enabled' : 'link_sharing_disabled', {
      project_id: project.id,
      page: 'project/info',
      has_cashflow: isProjectUsingCashflow,
    })
    restClientInstance('CUSTOM_PUT', 'custom', {
      url: `orgs/${orgId}/projects/${project.id}/share_link/`,
      data: {
        enable: newValue,
      },
    })
      .then((res: { data: { url: React.SetStateAction<string> } }) => {
        form.mutators.updateFieldSilently('share_link', res?.data?.url || '')
      })
      .catch((err) => {
        notify('Failed to toggle sharing: ' + err)
      })
    if (openDisableModal) setDisableModal(false)
  }
  const handleToggle = (e) => {
    const newValue = e.target.checked
    if (!newValue) {
      setDisableModal(true)
    } else {
      handleSharing(newValue)
    }
  }

  const ShareQR = useMemo(
    () =>
      project.share_link
        ? new QRCode({
            content: project.share_link,
            padding: 4,
            width: 100,
            height: 100,
            color: '#000000',
            background: 'none',
            join: true,
          }).svg()
        : undefined,
    [project.share_link]
  )

  return (
    <>
      <RowWrapper>
        <h3 className={classes.marginRight}>{translate('Enable Link Sharing')}</h3>
        <Tooltip
          PopperProps={{ style: { zIndex: 2001 } }}
          title={translate(
            'If your customer accesses the proposal via this link, you will receive notifications that your proposal has been accessed, but they will not indicate who has accessed the proposal (because this link is anonymous). If you would like to track who is accessing the proposal, please toggle to "email" above.'
          )}
        >
          <InfoIcon />
        </Tooltip>
        <Switch className={classes.switchStyle} checked={shareEnabled} onChange={handleToggle} />
      </RowWrapper>
      {shareEnabled && (
        <>
          <InputRow>
            <TextField
              variant="outlined"
              value={project.share_link}
              className={classes.linkInput}
              label={false}
              fullWidth
            />
            <StyledButton variant="contained" color="default" onClick={copyUrl}>
              {translate('Copy Link')}
            </StyledButton>
          </InputRow>
          {ShareQR && (
            <RowWrapper>
              <h3 className={classes.qrText}>
                {translate('You may also scan this QR code on your phone to access the proposal, or to share via SMS.')}
              </h3>
              <div
                className={classes.marginLeft}
                dangerouslySetInnerHTML={{
                  __html: ShareQR,
                }}
              />
            </RowWrapper>
          )}
        </>
      )}
      <ConfirmModal
        open={openDisableModal}
        setOpen={setDisableModal}
        handleSubmit={(e) => {
          handleSharing(false)
        }}
        title={translate('Disable Link Sharing')}
        mainText={translate('Are you sure you want to disable link sharing?')}
        subText={translate(
          'This will invalidate the existing shareable link permanently. Any customer who has access to the link will no longer be able to access the online proposal.'
        )}
        submitBtnLabel={translate('Yes, disable')}
        submitBtnProps={{ variant: 'contained', color: 'error' }}
      />
    </>
  )
}

export default LinkSharing
