import inflection from 'inflection';
import { useCallback } from 'react';
import { CRUD_GET_ONE, CRUD_UPDATE } from '../actions';
import { useGetOne, useUpdate } from '../dataProvider';
import { useTranslate } from '../i18n';
import { useNotify, useRedirect, useRefresh } from '../sideEffect';
import { useCheckMinimumRequiredProps } from './checkMinimumRequiredProps';
import useVersion from './useVersion';
/**
 * Prepare data for the Edit view
 *
 * @param {Object} props The props passed to the Edit component.
 *
 * @return {Object} controllerProps Fetched data and callbacks for the Edit view
 *
 * @example
 *
 * import { useEditController } from 'react-admin';
 * import EditView from './EditView';
 *
 * const MyEdit = props => {
 *     const controllerProps = useEditController(props);
 *     return <EditView {...controllerProps} {...props} />;
 * }
 */
var useEditController = function (props) {
    useCheckMinimumRequiredProps('Edit', ['basePath', 'resource'], props);
    var basePath = props.basePath, id = props.id, resource = props.resource, successMessage = props.successMessage, _a = props.undoable, undoable = _a === void 0 ? true : _a, redirectOverride = props.redirect;
    var translate = useTranslate();
    var notify = useNotify();
    var redirect = useRedirect();
    var refresh = useRefresh();
    var version = useVersion();
    var _b = useGetOne(resource, id, {
        version: version,
        action: CRUD_GET_ONE,
        onFailure: function () {
            notify('ra.notification.item_doesnt_exist', 'warning');
            var redirectTo = redirectOverride || DefaultRedirect;
            redirect(redirectTo, basePath);
            refresh();
        },
        filter: props.filter,
    }), record = _b.data, loading = _b.loading, loaded = _b.loaded;
    var resourceName = translate("resources.".concat(resource, ".name"), {
        smart_count: 1,
        _: inflection.humanize(inflection.singularize(resource)),
    });
    var defaultTitle = translate('ra.page.edit_title', {
        name: "".concat(resourceName),
        id: id,
        record: record,
    });
    var _c = useUpdate(resource, id, {}, // set by the caller
    record), update = _c[0], saving = _c[1].loading;
    var save = useCallback(function (data, redirectTo, _a) {
        if (redirectTo === void 0) { redirectTo = redirectOverride || DefaultRedirect; }
        var _b = _a === void 0 ? {} : _a, onSuccess = _b.onSuccess, onFailure = _b.onFailure;
        return update({ payload: { data: data } }, {
            action: CRUD_UPDATE,
            onSuccess: onSuccess
                ? onSuccess
                : function () {
                    notify(successMessage || 'ra.notification.updated', 'info', {
                        messageArgs: { smart_count: 1 },
                        undoable: undoable,
                    });
                    redirect(redirectTo, basePath, data.id, data);
                },
            onFailure: onFailure
                ? onFailure
                : function (error) {
                    notify(typeof error === 'string' ? error : error.message || 'ra.notification.http_error', 'warning');
                    if (undoable) {
                        refresh();
                    }
                },
            undoable: undoable,
        });
    }, [update, undoable, notify, successMessage, redirect, basePath, refresh]);
    var hasDelete = typeof props.hasDelete === 'function' ? props.hasDelete(record) : Boolean(props.hasDelete);
    return {
        loading: loading,
        loaded: loaded,
        saving: saving,
        defaultTitle: defaultTitle,
        hasDelete: hasDelete,
        save: save,
        resource: resource,
        basePath: basePath,
        record: record,
        redirect: redirectOverride || DefaultRedirect,
        version: version,
    };
};
export default useEditController;
var DefaultRedirect = 'list';
