import { Checkbox, FormControlLabel, Typography } from '@material-ui/core'
import { useState } from 'react'
import { OpenSolarThemeType } from 'Themes'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import VtacConnectForm from './VtacConnectForm'
import VtacSignupForm from './VtacSignupForm'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  container: {
    width: '100%',
    padding: 20,
    boxSizing: 'border-box',
  },
  contentWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexWrap: 'nowrap',
    [theme.breakpoints.down('md')]: {
      flexWrap: 'wrap',
    },
  },
}))
const VtacConnectPage = () => {
  const classes = useStyles()
  const [isNewAccount, setIsNewAccount] = useState(false)

  return (
    <div className={classes.container}>
      <Typography variant="h5">Connect to V-TAC</Typography>
      <Typography variant="body1">
        {isNewAccount
          ? 'Enter your details to create your V-TAC trade account.'
          : 'Use the email address and password associated with your V-TAC trade account to connect and get your trade pricing.'}
      </Typography>
      <FormControlLabel
        style={{ marginTop: 20, marginLeft: 0 }}
        control={
          <Checkbox
            id="elevationAutoCheckbox"
            checked={isNewAccount}
            onChange={() => setIsNewAccount(!isNewAccount)}
            style={{ padding: 0, paddingRight: 9 }}
          />
        }
        label={"I don't have a V-TAC trade account"}
      />
      {isNewAccount ? <VtacSignupForm /> : <VtacConnectForm />}
    </div>
  )
}

export default VtacConnectPage
