import { myEnergySelectors } from 'ducks/myEnergy'
import { LoanApplicationType, ProposalDataType } from 'opensolar-checkout'
import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import useForceOpenApplication from '../forceOpen/useForceOpenLoanApplication'

const useSpyForceOpenLoanApplicationEffect = ({
  elementData,
  proposalData,
}: {
  elementData: LoanApplicationType
  proposalData: ProposalDataType
}) => {
  const forceOpenCreditApp = useSelector(myEnergySelectors.getShouldForceOpenCreditApp)
  const forceOpenApplicationDialog = useForceOpenApplication()

  const elementDataRef = useRef(elementData)
  elementDataRef.current = elementData
  const proposalDataRef = useRef(proposalData)
  proposalDataRef.current = proposalData

  useEffect(() => {
    const proposalData = proposalDataRef.current
    const elementData = elementDataRef.current
    if (forceOpenCreditApp && proposalData.selectedPaymentOptionId === proposalData.selectedPaymentOption?.id) {
      forceOpenApplicationDialog({ elementData, proposalData })
    }
  }, [forceOpenCreditApp])
}

export default useSpyForceOpenLoanApplicationEffect
