import DateFnsUtils from '@date-io/date-fns'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { useEffect, useState } from 'react'
import { Admin, DEFAULT_LOCALE, polyglotI18nProvider, resolveBrowserLocale, Resource } from 'react-admin'
import './App.css'
import './Containers.css'
import accessReducer from './ducks/access'
import actionListenerReducer from './ducks/action_listener'
import activitiesReducer from './ducks/activities'
import appStateReducer from './ducks/app'
import authReducer from './ducks/auth'
import autoDesignReducer from './ducks/autoDesign'
import eagleViewOrderFormReducer from './ducks/eagleViewOrderForm'
import generateDocumentReducer from './ducks/generateDocument'
import inlineFormReducer from './ducks/inlineForm'
import lastListReducer from './ducks/lastList'
import loadCountReducer from './ducks/loadCount'
import myEnergyReducer, { myEnergySagas } from './ducks/myEnergy'
import orderComponentsReducer, { orderLineItemSagas } from './ducks/orderComponents'
import projectIdReducer from './ducks/projectId'
import projectMilestonesReducer from './ducks/projectMilestones'
import setQuickStartReducer from './ducks/quickStart'
import recentProjectsReducer from './ducks/recentProjects'
import selectComponentReducer from './ducks/selectComponent'
import selectTariffReducer from './ducks/selectTariff'
import splitReducer from './ducks/split'

import starsReducer from './ducks/stars'
import studioReducer from './ducks/studio'
import studioModeReducer from './ducks/studioMode'
import studioPromptReducer from './ducks/studioPrompt'
import themeReducer from './ducks/theme'
import transactionReducer from './ducks/transaction'
import uploadLogoReducer from './ducks/uploadLogo'
import utilityApiReducer from './ducks/utilityApi'
import setViewAsCustomer from './ducks/viewAsCustomer'
import welcomeReducer from './ducks/welcome'
import zoneReducer from './ducks/zone'
import designerReducer from './reducer/designer'
import projectReducer from './reducer/project'
import tourReducer from './reducer/tour'
// import walletTransactionsReducer from './ducks/walletTransactions'
import helpCenterReducer from 'ducks/helpCenter'
import inventoryFilterReducer from 'ducks/inventoryFilter'
import learningReducer from 'ducks/learning'
import liteInterfaceReducer from 'ducks/liteInterface'
import projectViewSettingsReducer from 'ducks/projectViewSettings'
import lodash from 'lodash'
import inventoryLocations from 'resources/inventoryLocations'
import electricalReducer from './ducks/electrical'
import featureConfigReducer, { featureConfigSagas } from './ducks/featureConfig'
import formDialogReducer from './ducks/formDialog'
import ironRidgeReducer from './ducks/ironRidge'
import loadZoneDataReducer, { loadZoneDataSagas } from './ducks/loadZoneData'
import paymentOptionSelectionReducer from './ducks/paymentOptionSelection'
import paymentsPageReducer from './ducks/paymentsPage'
import premiumProductsReducer, { premiumProductsSagas } from './ducks/premiumProducts'
import pvsellReducer from './ducks/pvsell'
import renusolReducer from './ducks/renusol'
import segenReducer from './ducks/segen'
import viewModeReducer from './ducks/viewMode'
import walletReducer, { walletSagas } from './ducks/wallet'
import proposalViewers from './resources/proposalViewers'

import authClient from './authClient'
import defaultTranslations, { templateTranslations } from './i18n'

import PersistentContent from './persistentContent/PersistentContent'
import myApiRestClient from './restClient'
import { NearmapTheme, OpenSolarTheme } from './Themes'

import {
  activeBatteryComponents,
  activeInverterComponents,
  activeModuleComponents,
  activeOtherComponents,
} from './resources/activeComponents'
import Adders from './resources/adders'
import BatterySchemes from './resources/batterySchemes'
import commissions from './resources/commissions'
import { batteryComponents, inverterComponents, moduleComponents, otherComponents } from './resources/components'
import connectedOrgs from './resources/connectedOrgs'
import contacts from './resources/contacts'
import contracts from './resources/contracts'
import costings from './resources/costings'
import countries from './resources/countries'
import { csvFileUploadLogs, csvFileUploadRequests } from './resources/csvFileUpload'
import customFields from './resources/customFields'
import { CustomRoles_orgs, CustomRoles_user } from './resources/customRoles'
import distributors from './resources/distributors'
import documentTemplates from './resources/documentTemplates'
import events from './resources/events'
import exhibitContents from './resources/exhibitContents'
import globalContacts from './resources/globalContacts'
import globalOrgs from './resources/globalOrgs'
import globalProjects from './resources/globalProjects'
import globalRoles from './resources/globalRoles'
import globalUsers from './resources/globalUsers'
import incentives from './resources/incentives'
import leadCaptureForm from './resources/leadCaptureForm'
import leadRecipient from './resources/leadRecipients'
import manufacturers from './resources/manufacturers'
import myrole from './resources/myrole'
import orgContent from './resources/orgContent'
import orgs from './resources/orgs'
import paymentMethods from './resources/paymentMethods'
import paymentOptions from './resources/paymentOptions'
import PricingSchemes from './resources/pricingSchemes'
import privateFiles from './resources/privateFiles'
import projectConfigurations from './resources/projectConfigurations'
import projects from './resources/projects'
import proposalTemplates from './resources/proposalTemplates'
import publicFiles from './resources/publicFiles'
import roles from './resources/roles'
import roofTypes from './resources/roofTypes'
import salesTerritories from './resources/salesTerritories'
import structs from './resources/structs'
import tags from './resources/tags'
import testimonials from './resources/testimonials'
import transactions from './resources/transactions'
import utilities from './resources/utilities'
import utilityTariffs, { customTariffCurrent, customTariffProposed } from './resources/utilityTariffs'
import { SHOPIFY_STOREFRONT_API_VERSION } from './storeFrontQuery'

import landingPages from './resources/landingPages'
import orderingOrders from './resources/orderingOrders'
import orderingQuotes from './resources/orderingQuotes'
import premiumProducts from './resources/premiumProducts'
import wallet from './resources/wallet'
import walletProducts from './resources/walletProducts'
import walletTransactions from './resources/walletTransactions'

import PageRegister from 'pages/auth'
import CustomLayout from './layout/CustomLayout'

import customRoutes from './routes'

import authSaga from './sagas/authSaga'
import designerSideEffects from './sagas/designerSaga'
import projectSaga from './sagas/projectSaga'
import restSaga from './sagas/restSaga'

import { createHashHistory } from 'history'
//storefrontcheckout
import ApolloClient from 'apollo-boost'
import { ApolloProvider } from 'react-apollo'

//sentry.io
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import { MsalProvider } from '@azure/msal-react'
import { rolesSagas } from 'ducks/auth_roles'
import consumptionCurvesReducer, { consumptionCurvesSagas } from 'ducks/consumptionCurves'
import exhibitorsReducer, { exhibitorsSagas } from 'ducks/exhibitors'
import orgsReducer, { orgsSagas } from 'ducks/orgs'
import { permissionsReducer } from 'ducks/permissions'
import projectListViewsReducer, { projectListViewsSagas } from 'ducks/projectListViews'
import { tariffSagas } from 'ducks/tariff'
import { walletTransactionSagas } from 'ducks/walletTransactions'
import wsReducer, { wsSagas } from 'ducks/websocket'

import { LOCALES_SUPPORTED } from 'constants/locales'
import { TrackingProvider } from 'contexts/eventsTracking/TrackingContext'
import { CustomAdminUI } from 'layout/CustomAdminUI'
import { OsUiRootContext } from 'opensolar-ui'
import actions from 'resources/actions'
import customForms from 'resources/customForms'
import financeCTAs from 'resources/financeCTAs'
import financeIntegrations from 'resources/financeIntegrations'
import financeProducts from 'resources/financeProducts'
import financeProductTerms from 'resources/financeProductTerms'
import financeValidationSets from 'resources/financeValidationSets'
import paymentFactors from 'resources/paymentFactors'
import workflows from 'resources/workflows'
import { getMsalSegenSingletonInstance } from 'services/identity/getMsalSingletonInstance'
import appStorage, { getLocale, setLocale } from 'storage/appStorage'
import 'storage/customLocalStorage' // Sets up window.getStorage()

const showTranslationIcons = 'showTranslationIcons'
const isLocal = !window.ENV || window.ENV === 'local'
if (isLocal && !appStorage.has(showTranslationIcons)) appStorage.setBool(showTranslationIcons, true)

const outletStorefrontClient = new ApolloClient({
  uri: `${window.OUTLET_STOREFRONT_DOMAIN}/api/${SHOPIFY_STOREFRONT_API_VERSION}/graphql.json`,
  headers: {
    'X-Shopify-Storefront-Access-Token': window.OUTLET_STOREFRONT_ACCESS_TOKEN,
  },
})

const stripTrailingSlash = (str) => {
  if (str.endsWith('/')) return str.slice(0, -1)
  else return str
}

// The absolute URL to the root of the API server
// In most scnearios this doesn't include `/api`.
// In Review Instances, this will have `/api` at the end, which will get another `/api` appended to it in `API_BASE_URL`.
// This is expected
window.API_ROOT_ABSOLUTE = stripTrailingSlash(
  window.API_ROOT.startsWith('http:') || window.API_ROOT.startsWith('https:')
    ? window.API_ROOT
    : window.location.origin + window.API_ROOT
)

const API_URL = window.API_ROOT + '/api'

//TODO: Remove trailing slash from API_BASE_URL
window.API_BASE_URL = API_URL + '/'

// Should preferentially use API_BASE_URL
// Only when creating a URL which needs to be used outside of OS should we rely on API_BASE_URL_ABSOLUTE
window.API_BASE_URL_ABSOLUTE = window.API_ROOT_ABSOLUTE + '/api/'

const history = createHashHistory()

history.listen(function (location) {
  window.GA4?.recordPageView()

  if (window && window.RUNNING_AS_APP) {
    var pagePathLocal = location.pathname + location.search
    window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'navigationChangeHack', pagePath: pagePathLocal }))
  }
})

window.reactReduxHistory = history

// Mobile only" We inject these lines so we can auto-detect in build.py
// e.g. Sample output
// var injectedMessagesByLocale = {
//   en: require('./i18n/en.js'),
// }

var injectedMessagesByLocale = {
  /*build.py require locales */
}

export const loadMessagesForLocale = async (locale) => {
  var injectedMessages = injectedMessagesByLocale[locale]

  if (injectedMessages) {
    return injectedMessages
  } else {
    return new Promise((resolve, reject) => {
      import('./i18n/' + locale + '.js')
        .then((messages) => {
          resolve({
            ...messages,
            default: {
              ...templateTranslations,
              ...messages.default,
            },
          })
        })
        .catch(reject)
    })
  }
}

export const getPolyglotI18nProvider = (initialLocale, initialTranslation) => {
  const ret = polyglotI18nProvider(
    (locale) => {
      if (locale === initialLocale) {
        // initial call, must return synchronously
        return initialTranslation
      }

      return loadMessagesForLocale(locale)
        .then((messages) => messages.default)
        .catch((error) => {
          console.warn('getLocale error', error)
          return defaultTranslations[DEFAULT_LOCALE]
        })
    },
    initialLocale,
    {
      allowMissing: true,
      onMissingKey: (key, options) => {
        // Still resolve variables when term isn't found
        if (typeof key === 'string')
          for (const i in options) {
            key = key.replace(new RegExp(`%{${i}}`), options[i])
          }
        return key
      },
    }
  )

  const trans = ret.translate
  ret.translate = (key, options = {}) => {
    if (!key) return ''
    else if (appStorage.getBool(showTranslationIcons)) {
      const ret = trans(key, options)
      const icon = lodash.get(templateTranslations, key) ? '✅' : '❌'
      if (!ret) return icon
      else if (typeof ret === 'string') return icon + ' ' + ret
      else return [icon, ret]
    } else return trans(key, options)
  }

  return ret
}

if (window.SENTRY_IO_APP_DSN && !isLocal) {
  const BLACKLISTED_FETCH_URLS = [
    'https://api.amplitude.com',
    'https://maps.googleapis.com',
    'https://sdk.split.io',
    'https://auth.split.io',
  ]
  const maxMessageLength = 1200

  // TODO: remove once we can import Sentry directly into Studio
  window.captureException = Sentry.captureException

  Sentry.init({
    release: window.RELEASE_IDENTIFIER,
    dsn: window.SENTRY_IO_APP_DSN,
    environment: window.ENV,
    normalizeDepth: 5, // Controls the amount of depth that serialised objects will have. Defaults to 3.
    maxBreadcrumbs: 50,
    sampleRate: 1,
    tracesSampleRate: 0.01,
    integrations: [
      // We cannot only target window.API_ROOT beacuse it will also try to trace there results
      // after redirection too (e.g. refresh_download_url which redirects directly to a Google endpoint
      // which cannot support OPTIONS requests)
      // e.g. http://localhost:8000/api/orgs/1/maps/refresh_download_url/google/?url={base64-encoded-google-endpoint}
      //
      // Workaround is to exclude endpoints which we know may redirect to URLs not in our domain

      // Adapted from https://stackoverflow.com/a/18067509/1767169
      // Include API_ROOT but exclude certain paths after that including:
      //  - refresh_download_url

      // Their example matches entire domain except for /forms:
      // Sample Regex: https?://[^/]+($|/(?!forms)/?.*$)
      // Explanation: After the www.domain.com bit, it's looking for either the end of the string, or for a slash and then something that ISN'T forms.

      // Our implementation:
      // Regex: window.API_ROOT + '[^/]+($|/?.*(?!refresh_download_url)?.*$)'
      // Explanation:
      //    Starts with API Root
      //    Then either end of string OR
      //    Anything that does NOT contain refresh_download_url

      new Integrations.BrowserTracing({
        tracingOrigins: [window.API_ROOT + '[^/]+($|/?.*(?!refresh_download_url)?.*$)'],
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
    ],

    beforeBreadcrumb(breadcrumb, hint) {
      // Don't capture certain fetch/xhr requests, mainly to keep breadcrumbs relevant
      for (const url of BLACKLISTED_FETCH_URLS) {
        if (breadcrumb.data?.url?.indexOf(url) === 0) return null
      }

      // Reduce epicly long console logs
      if (
        breadcrumb.message &&
        typeof breadcrumb.message === 'string' &&
        breadcrumb.message?.length > maxMessageLength
      ) {
        breadcrumb.message = breadcrumb.message.slice(0, maxMessageLength) + '...'
        if (breadcrumb.category === 'console') breadcrumb.data.arguments = [] // Arguments is just a copy of the message, discard in this case
      }

      return breadcrumb
    },
  })
} else {
  console.warn('Sentry not initialized, no SENTRY_IO_APP_DSN')
}

const App = () => {
  const [initialLocale, setInitialLocale] = useState(null)
  const [initialTranslation, setInitialTranslation] = useState(null)
  const [invalidUsageReason, setInvalidUsageReason] = useState(undefined)
  const themes = { opensolar: OpenSolarTheme, nearmap: NearmapTheme }
  const customSagas = [
    ...designerSideEffects,
    tariffSagas,
    projectSaga,
    myEnergySagas,
    orderLineItemSagas,
    walletTransactionSagas,
    walletSagas,
    featureConfigSagas,
    premiumProductsSagas,
    orgsSagas,
    exhibitorsSagas,
    consumptionCurvesSagas,
    projectListViewsSagas,
    wsSagas,
    rolesSagas,
    authSaga(authClient),
    restSaga(myApiRestClient(API_URL)),
    loadZoneDataSagas,
  ]

  useEffect(() => {
    const initialization = async () => {
      let locale = getLocale() || resolveBrowserLocale()
      //format locale e.g. en-US => en_US
      locale = locale.replace('-', '_')
      if (Object.keys(LOCALES_SUPPORTED).indexOf(locale) === -1) {
        //use standard language if specific locale not found
        locale = locale.split('_')[0]
        if (Object.keys(LOCALES_SUPPORTED).indexOf(locale) === -1) {
          //use default locale if language not found
          locale = DEFAULT_LOCALE
        }
      }
      setLocale(locale)
      const message = await loadMessagesForLocale(locale)
        .then((messages) => messages.default)
        .catch((error) => {
          console.warn('getLocale error', error)
          locale = DEFAULT_LOCALE
          return defaultTranslations[DEFAULT_LOCALE]
        })
      setInitialLocale(locale)
      setInitialTranslation(message)
    }

    initialization()
  }, [])

  useEffect(() => {
    if (invalidUsageReason) Sentry.captureException(invalidUsageReason)
  }, [invalidUsageReason])

  if (!initialLocale || !initialTranslation) {
    // we couldn't detect theme color at this stage as redux is not ready yet
    const loaderColor = '#e2dede'
    return (
      <div className="loader-container">
        <div className="loader" style={{ color: loaderColor }} />
      </div>
    )
  }

  //TODO move this to redux
  window.setInvalidUsageReason = setInvalidUsageReason

  if (invalidUsageReason) {
    return (
      <>
        <div>Invalid usage, please check client key or contact OpenSolar support.</div>
        <div>{invalidUsageReason}</div>
      </>
    )
  }

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      {/* Todo: Ideally, we'd like to inject generic msal instance which support multiple partners */}
      <MsalProvider instance={getMsalSegenSingletonInstance()}>
        <ApolloProvider client={outletStorefrontClient}>
          <OsUiRootContext>
            <TrackingProvider>
              <Admin
                title="OpenSolar"
                dataProvider={myApiRestClient(API_URL)}
                customReducers={{
                  studio: studioReducer,
                  studioMode: studioModeReducer,
                  designer: designerReducer,
                  project: projectReducer,
                  inlineForm: inlineFormReducer,
                  viewAsCustomer: setViewAsCustomer,
                  theme: themeReducer,
                  zone: zoneReducer,
                  projectId: projectIdReducer,
                  actionListener: actionListenerReducer,
                  eagleViewOrderForm: eagleViewOrderFormReducer,
                  autoDesign: autoDesignReducer,
                  recentProjects: recentProjectsReducer,
                  stars: starsReducer,
                  tour: tourReducer,
                  auth: authReducer,
                  permissions: permissionsReducer,
                  split: splitReducer,
                  lastList: lastListReducer,
                  loadCount: loadCountReducer,
                  app: appStateReducer,
                  generateDocument: generateDocumentReducer,
                  selectTariff: selectTariffReducer,
                  selectComponent: selectComponentReducer,
                  utilityApi: utilityApiReducer,
                  transaction: transactionReducer,
                  quickStart: setQuickStartReducer,
                  // menu: setMenuReducer,
                  welcome: welcomeReducer,
                  uploadLogo: uploadLogoReducer,
                  studioSavePrompt: studioPromptReducer,
                  access: accessReducer,
                  myEnergy: myEnergyReducer,
                  orderComponents: orderComponentsReducer,
                  activities: activitiesReducer,
                  pvsell: pvsellReducer,
                  ironRidge: ironRidgeReducer,
                  renusol: renusolReducer,
                  segen: segenReducer,
                  featureConfig: featureConfigReducer,
                  wallet: walletReducer,
                  viewMode: viewModeReducer,
                  // walletTransactions: walletTransactionsReducer,
                  premiumProducts: premiumProductsReducer,
                  learning: learningReducer,
                  orgs: orgsReducer,
                  exhibitors: exhibitorsReducer,
                  consumption_curves: consumptionCurvesReducer,
                  websocket: wsReducer,
                  liteInterface: liteInterfaceReducer,
                  helpCenter: helpCenterReducer,
                  inventoryFilter: inventoryFilterReducer,
                  projectViewSettings: projectViewSettingsReducer,
                  electrical: electricalReducer,
                  formDialog: formDialogReducer,
                  paymentOptionSelection: paymentOptionSelectionReducer,
                  paymentsPage: paymentsPageReducer,
                  projectMilestones: projectMilestonesReducer,
                  loadZoneData: loadZoneDataReducer,
                  projectListViews: projectListViewsReducer,
                }}
                themePackages={themes}
                authProvider={authClient}
                customRoutes={customRoutes}
                customSagas={customSagas}
                loginPage={PageRegister}
                i18nProvider={getPolyglotI18nProvider(initialLocale, initialTranslation)}
                ui={CustomAdminUI}
                layout={CustomLayout}
                persistentContent={<PersistentContent API_URL={API_URL} />}
              >
                {(permissions) => {
                  return [
                    <Resource name="projects" {...projects} />,
                    <Resource name="projects___aliases" />,
                    <Resource name="orgs" {...orgs} />,
                    <Resource name="roles" {...roles} />,
                    <Resource name="roles___aliases" />,
                    <Resource name="permissions_role___aliases" />,
                    <Resource name="myroles" {...myrole} />,
                    <Resource name="lead_capture_forms" {...leadCaptureForm} />,
                    <Resource name="lead_recipients" {...leadRecipient} />,
                    <Resource name="tags" {...tags} />,
                    <Resource name="workflows" {...workflows} />,
                    <Resource name="actions" {...actions} />,
                    <Resource name="stages" {...{}} />,
                    <Resource name="countries" {...countries} />,
                    <Resource name="commissions" {...commissions} />,
                    <Resource name="public_orgs" />,
                    <Resource name="my_projects" />,
                    <Resource name="file_tags" />,
                    <Resource name="inventory_transfers" />,
                    <Resource name="inventory_transfer/incoming" />,
                    <Resource name="inventory_transfer/outgoing" />,
                    <Resource name="systems" />,
                    <Resource name="components" />,
                    <Resource name="componentsv2" />,
                    <Resource name="components___aliases" />,
                    <Resource name="transition_to_utility_tariffs" />,
                    <Resource name="custom_tariff_proposed" {...customTariffProposed} />,
                    <Resource name="custom_tariff_current" {...customTariffCurrent} />,
                    <Resource name="events" {...events} />,
                    <Resource name="content" {...orgContent} />,
                    <Resource name="contacts" {...contacts} />,
                    <Resource name="manufacturers" {...manufacturers} />,
                    <Resource name="roof_types" {...roofTypes} />,
                    <Resource name="adders" {...Adders} />,
                    <Resource name="battery_schemes" {...BatterySchemes} />,
                    <Resource name="pricing_schemes" {...PricingSchemes} />,
                    <Resource name="custom_fields" {...customFields} />,
                    <Resource name="custom_forms" {...customForms} />,
                    <Resource name="permissions_role" {...CustomRoles_user} />,
                    <Resource name="connected_orgs_roles" {...CustomRoles_orgs} />,
                    <Resource name="costings" {...costings} />,
                    <Resource name="sales_territories" {...salesTerritories} />,
                    <Resource name="transactions" {...transactions} />,
                    <Resource name="testimonials" {...testimonials} />,
                    <Resource name="inventory_locations" {...inventoryLocations} />, // no entry point
                    <Resource name="contracts" {...contracts} />,
                    <Resource name="global_contracts" />,
                    <Resource name="component_content" {...exhibitContents} />,
                    <Resource name="document_templates" {...documentTemplates} />,
                    <Resource name="global_document_templates" />,
                    <Resource name="all_available_document_templates" />,
                    <Resource name="distributors" {...distributors} />,
                    <Resource name="csv_file_upload_requests" {...csvFileUploadRequests} />,
                    <Resource name="csv_file_upload_logs" {...csvFileUploadLogs} />,
                    <Resource name="utilities" {...utilities} />,
                    <Resource name="utility_tariffs" {...utilityTariffs} />,
                    <Resource name="component_modules" {...moduleComponents} />,
                    <Resource name="component_others" {...otherComponents} />,
                    <Resource name="component_inverters" {...inverterComponents} />,
                    <Resource name="component_batteries" {...batteryComponents} />,
                    <Resource name="incentives" {...incentives} />,
                    <Resource name="proposal_templates" {...proposalTemplates} />,
                    <Resource name="project_configurations" {...projectConfigurations} />,
                    <Resource name="component_module_activations" {...activeModuleComponents} />,
                    <Resource name="component_inverter_activations" {...activeInverterComponents} />,
                    <Resource name="component_battery_activations" {...activeBatteryComponents} />,
                    <Resource name="component_other_activations" {...activeOtherComponents} />,
                    <Resource name="payment_methods" {...paymentMethods} />,
                    <Resource name="payment_options" {...paymentOptions} />,
                    <Resource name="public_files" {...publicFiles} />,
                    <Resource name="private_files" {...privateFiles} />,
                    <Resource name="global_orgs" {...globalOrgs} />,
                    <Resource name="global_projects" {...globalProjects} />,
                    <Resource name="global_contacts" {...globalContacts} />,
                    <Resource name="global_roles" {...globalRoles} />,
                    <Resource name="global_users" {...globalUsers} />,
                    <Resource name="ordering_orders" {...orderingOrders} />,
                    <Resource name="ordering_quotes" {...orderingQuotes} />,
                    <Resource name="wallet_transactions" {...walletTransactions} />,
                    <Resource name="global_wallet_transactions" {...walletTransactions} />,
                    <Resource name="wallets" {...wallet} />,
                    <Resource name="wallet_products" {...premiumProducts} />,
                    <Resource name="landing_pages" {...landingPages} />,
                    <Resource name="superuser_wallet_products" {...walletProducts} />,
                    <Resource name="finance_partners" {...financeIntegrations} />,
                    <Resource name="payment_factors" {...paymentFactors} />,
                    <Resource name="finance_validation_sets" {...financeValidationSets} />,
                    <Resource name="finance_products" {...financeProducts} />,
                    <Resource name="finance_product_terms" {...financeProductTerms} />,
                    <Resource name="finance_ctas" {...financeCTAs} />,
                    <Resource name="proposal_viewers" {...proposalViewers} />,
                    <Resource name="connected_orgs" {...connectedOrgs} />,
                    <Resource name="cashflow_projects" />,
                    <Resource name="cashflow_invoices" />,
                    <Resource name="structs" {...structs} />,
                    <Resource name="struct-types" />,
                  ]
                }}
              </Admin>
            </TrackingProvider>
          </OsUiRootContext>
        </ApolloProvider>
      </MsalProvider>
    </MuiPickersUtilsProvider>
  )
}

export default App
