import { useState } from 'react'
import { StipulationActionType } from './types'
import UploadStipulationDialog from './UploadStipulationDialog'

export type UploadStipulationDialogConfig = {
  stipulation: StipulationActionType
}

const useUploadStipulationDialog = () => {
  const [state, seState] = useState<
    { open: false; config: undefined } | { open: true; config: UploadStipulationDialogConfig }
  >({
    open: false,
    config: undefined,
  })

  const openDialog = (config: UploadStipulationDialogConfig) => {
    seState({
      open: true,
      config,
    })
  }

  const closeDialog = () => {
    if (state.open === false) {
      return
    }
    seState({
      open: false,
      config: undefined,
    })
  }

  const dialogNode = state.open ? (
    <UploadStipulationDialog open={true} stipulation={state.config.stipulation} onClose={closeDialog} />
  ) : null
  return { isOpen: state.open, dialogNode, openDialog, closeDialog, type: 'UploadStipulationDialog' as const }
}

export default useUploadStipulationDialog
