import Tooltip from '@material-ui/core/Tooltip'
import HelpOutlineIcon from '@material-ui/icons/HelpOutline'
import { IconButton } from 'opensolar-ui'
import React from 'react'
import { doNotTranslate } from 'util/misc'

interface TooltipAdornmentProps {
  tooltipText: string
}

const TooltipAdornment: React.FC<TooltipAdornmentProps> = ({ tooltipText }) => {
  return (
    <Tooltip title={doNotTranslate(tooltipText)}>
      <IconButton size="small">
        <HelpOutlineIcon fontSize="small" />
      </IconButton>
    </Tooltip>
  )
}

export default TooltipAdornment
