export const ops = ['in', 'nin', 'eq', 'neq', 'gt', 'lt', 'gte', 'lte', 'ends-with', 'starts-with'] as const
export type Op = typeof ops[number]

export type FilterField = {
  label: string
  path: string
  type: 'string' | 'integer' | 'float' | 'boolean' | 'timestamp' | 'semver'
  group?: string
}

export type FilterStruct = {
  conditions: FilterCondition[]
}

export type FilterCondition = {
  path: string
  op: Op
  value: any
}
