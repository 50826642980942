import { ComponentVersions_3_0 } from 'constants/uxVersions'
import { useShowTourBanner } from 'hooks/useShowTourBanner'
import { ComponentVersionsInherit, styled } from 'opensolar-ui'
import TourBanner from 'persistentContent/tours/ui3/TourBanner'
import ViewPortal from './ViewPortal'

export const MainWrapper = styled('div')({
  padding: 24,
  height: '100%',
  backgroundColor: '#F6F9FC',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

const ProjectsList = (props) => {
  const shouldShowBanner = useShowTourBanner(props.width, 'project_list')
  return (
    <ComponentVersionsInherit versions={ComponentVersions_3_0}>
      <MainWrapper>
        {shouldShowBanner.show && <TourBanner tourType="project_list" />}
        <ViewPortal {...props} />
      </MainWrapper>
    </ComponentVersionsInherit>
  )
}

export default ProjectsList
