import { useForm } from 'react-final-form'

import { myEnergySelectors, toggleSendProposalDialog } from 'ducks/myEnergy'
import { InviteDialog } from 'elements/button/InviteButton'
import { useDispatch, useSelector } from 'react-redux'
import useSendProposalButton from './useSendProposalButton'

const SendProposalDialog = () => {
  const { disableButton } = useSendProposalButton()

  const dispatch = useDispatch()
  const form = useForm()
  const formValue = form.getState().values

  const isSendProposalDialogShown = useSelector(myEnergySelectors.getIsSendProposalDialogShown)

  if (disableButton || !isSendProposalDialogShown) return null

  return (
    <InviteDialog
      contacts={formValue.contacts_data}
      projectId={formValue.id}
      handleClose={() => dispatch(toggleSendProposalDialog(false))}
      project={formValue}
    />
  )
}

export default SendProposalDialog
