import * as Sentry from '@sentry/react'
import { CheckoutActionCallback } from 'opensolar-checkout'
import { useNotify, useTranslate } from 'react-admin'

import { useCallback, useContext } from 'react'
import restClient from 'restClient'
import { logAppOpenedEvent } from '../../util/events'
import ActiveDialogContext from '../dialogs/ActiveDialogContext'

var restClientInstance = restClient(window.API_ROOT + '/api')

const usePrequalifyCallback = (): CheckoutActionCallback => {
  const { showDialog } = useContext(ActiveDialogContext)
  const translate = useTranslate()

  const notify = useNotify()
  return useCallback(async ({ elementData, proposalData, additionalActionData }) => {
    if (elementData.type !== 'Prequal') {
      Sentry.captureException(new Error('Invalid element data for pre-qualify callback'))
      return
    }
    logAppOpenedEvent(elementData.actionData, proposalData.selectedPaymentOption.title)
    const { org_id, project_id, system_uuid, payment_option_id } = elementData.actionData

    if (elementData.actionData.application_url) {
      // URL already generated which means a sunlight account has been made. But the url might be stale so we will refresh it and use that for the dialog
      await restClientInstance('CUSTOM_POST', 'custom', {
        url:
          'orgs/' +
          org_id +
          '/projects/' +
          project_id +
          '/refresh_sunlight_iframe_url/' +
          system_uuid +
          '/' +
          payment_option_id +
          '/sunlight_loan_prequal/',
      }).then(
        (response) => {
          if (response.data.status && response.data.url) {
            showDialog('SunlightLoanApplication', { url: response.data.url })
          } else {
            notify(translate('Unable to open Sunlight Prequalification Form'), 'error')
          }
        },
        (reject) => {
          console.log(reject)
          notify(window.translate(reject.message), 'error')
        }
      )
    } else {
      // Request the email send (which will automatically record an incomplete transaction)
      await restClientInstance('CUSTOM_POST', 'custom', {
        url:
          'orgs/' +
          org_id +
          '/projects/' +
          project_id +
          '/apply_for_finance/' +
          system_uuid +
          '/' +
          payment_option_id +
          '/sunlight_loan_prequal/',
      }).then(
        (response) => {
          if (response.data && response.data.data && response.data.data.application_url) {
            showDialog('SunlightLoanApplication', { url: response.data.data.application_url })
          }
        },
        (reject) => {
          notify(window.translate(reject.message), 'error')
        }
      )
    }
  }, [])
}

export default usePrequalifyCallback
