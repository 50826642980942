import CustomForm from 'customForm/CustomForm'
import { DynamicIcon } from 'elements/DynamicIcon'
import { useUxVersion } from 'hooks/useUxVersion'
import AccordionCard from 'projectSections/sections/info/AccordionCard'
import React from 'react'
import { useForm, useFormState } from 'react-final-form'
import { StudioSystemType } from 'types/global'
import { FormPlacementInfo } from '../util/findFormPlacements'

type PropTypes = {
  customForm: FormPlacementInfo
  system?: StudioSystemType
}

export const CustomFormAccordionCard: React.FC<PropTypes> = ({ customForm, system }) => {
  const configuration = customForm.form.configuration
  const placement = customForm.placements[0]
  const parentForm = useForm()
  const parentFormState = useFormState()
  const ui3Enabled = useUxVersion() === 3

  if (!configuration) {
    return <></>
  } else {
    return (
      <AccordionCard
        elevation={ui3Enabled ? 0 : undefined}
        ux3={true}
        title={customForm.form.title}
        name={`custom-form-card-${customForm.form.id}`}
        disabled={false}
        render={() => (
          <CustomForm
            key={`custom-form-${customForm.form.id}`}
            customForm={customForm.form}
            isLoading={!configuration}
            selectedSystem={system}
            parentForm={parentForm}
            parentFormState={parentFormState}
            unlinkValidation={true}
          />
        )}
        titleIcon={() => placement.icon && <DynamicIcon icon={placement.icon} />}
        lazyRender={true}
        defaultExpanded={placement.start_open}
      />
    )
  }
}
