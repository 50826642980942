import Fade from '@material-ui/core/Fade'
import Grow from '@material-ui/core/Grow'
import withWidth, { WithWidth } from '@material-ui/core/withWidth'
import AvPlayCircleOutline from '@material-ui/icons/PlayCircleOutlineOutlined'
import RemoveIcon from '@material-ui/icons/RemoveOutlined'
import { startTourStep } from 'actions/tourActions'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { removeTourBanner } from 'ducks/auth'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import appStorage from 'storage/appStorage'
import { RootState } from 'types/state'

type PropTypes = WithWidth

const TourBanner: React.FunctionComponent<PropTypes> = (props) => {
  const [isFocused, setIsFocused] = useState<Boolean>(false)
  const [hideBanner, setHideBanner] = useState<boolean>(appStorage.getString('hide_fuji_banner') === 'yes')

  const translate = useTranslate()
  const dispatch = useDispatch()

  const isMobileDevice = useMemo(() => window.Utils?.isTouchDevice(), [])
  const { tour } = useSelector((state: RootState) => state.tour)

  const showBanner = !(
    hideBanner ||
    tour ||
    props.width === 'sm' ||
    props.width === 'xs' ||
    //@ts-ignore - doesn't look like a valid breakpoint, but will leave for now in case
    props.width === 'xxs' ||
    isMobileDevice
  )

  useEffect(() => {
    if (showBanner) logAmplitudeEvent('fuji_banner_shown', {})
  }, [showBanner])

  const handleClick = () => {
    dispatch(removeTourBanner(false))
    dispatch(startTourStep('fuji'))
  }
  const handleHide = () => {
    appStorage.setString('hide_fuji_banner', 'yes')
    setHideBanner(true)
    logAmplitudeEvent('fuji_banner_hidden', {})
    dispatch(removeTourBanner(true))
    // recordUserAction(userId, 'tour_hidden')
  }
  if (!showBanner) return null
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        bottom: 150,
        left: '50%',
        transform: 'translateX(-50%)',
        position: 'absolute',
      }}
    >
      <Grow in={true}>
        <div
          style={{
            position: 'relative',
            boxShadow: '3px 10px 10px rgba(0, 0, 0, 0.10), -3px -3px 10px rgba(0, 0, 0, 0.10)',
            borderRadius: '5px',
            padding: '20px 40px',
          }}
          onMouseEnter={() => setIsFocused(true)}
          onMouseLeave={() => setIsFocused(false)}
        >
          {isFocused && (
            <Fade in={true}>
              <div
                style={{
                  position: 'absolute',
                  right: '5px',
                  top: '5px',
                  cursor: 'pointer',
                  border: '1px solid #a2a2a2',
                  borderRadius: '20px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '18px',
                  width: '18px',
                }}
                onClick={handleHide}
              >
                <RemoveIcon htmlColor="#a2a2a2" />
              </div>
            </Fade>
          )}
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <span style={{ marginBottom: '15px' }}>{translate('Take the OpenSolar 2.0 Tour')}</span>
            <ProUXButton type="primary" onClick={handleClick} label="Start Tour" endIcon={<AvPlayCircleOutline />} />
          </div>
        </div>
      </Grow>
    </div>
  )
}
export default withWidth()(TourBanner)
