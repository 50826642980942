import { Dialog, DialogContent, useMediaQuery } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import type { CTAConfigJson } from 'opensolar-checkout'
import { Button, Checkbox, ComponentVersionsInherit } from 'opensolar-ui'
import React, { useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import { OpenSolarThemeType } from 'Themes'
export type { CTAConfigJson } from 'opensolar-checkout'

type PropTypes = {
  open: boolean
  onClose: () => void
  project_id: number
  paymentOptionId: number
  systemUuid: string
  orgId: number
  isFullWidth: boolean
  ctaDialogConfigJson: CTAConfigJson
}

const useStyles = makeStyles((theme: OpenSolarThemeType) => ({
  continueButton: {
    background: `#1751D0`,
    color: `${theme.white}`,

    '&:disabled': {
      background: `#F1F1F1`,
      color: `#C9C9CC`,
    },
    '&:hover': {
      background: `#1751D0`,
    },
  },
  cancelButton: {
    background: `#F1F1F1 !important`,
    border: 'none',

    '&:hover': {
      border: 'none',
    },
  },
  logo: {
    height: '40px',
    width: '145px',
  },
  alignCenter: {
    textAlign: 'center',
  },
  marginTop50: {
    marginTop: '50px',
  },
  mainText: {
    margin: '35px 55px 0',
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: 400,
  },
  subText: {
    margin: '35px 60px 0',
    textAlign: 'center',
    fontSize: '12px',
    fontWeight: 500,
  },
  acceptanceText: {
    margin: '15px 40px 0',
    textAlign: 'center',
    fontSize: '12px',
    fontWeight: 400,
  },
}))

const DisclaimerRedirectDialog: React.FunctionComponent<PropTypes> = (props) => {
  const classes = useStyles()
  const [acceptTerms, setAcceptTerms] = useState<boolean>(false)

  const translate = useTranslate()
  const isMobile = useMediaQuery('(max-width:800px)')
  const projectId = props.project_id
  const ctaConfigJson = props.ctaDialogConfigJson

  useEffect(() => {
    logAmplitudeEvent('integrated_finance_application_opened', {
      integration: 'selina',
      project_id: props.project_id,
      payment_option_id: props?.paymentOptionId,
    })
  }, [])

  const submitApplication = () => {
    const url = `${ctaConfigJson.redirect_url}${projectId}`
    window.open(url, '_blank')
    setAcceptTerms(false)
    props.onClose()
  }

  const confirmBeforeClose = () => {
    if (window.confirm(translate('Are you sure you want to cancel?'))) props.onClose()
  }

  const handleCheckboxChange = () => {
    setAcceptTerms((acceptTerms) => !acceptTerms)
  }

  const renderContent = () => {
    return (
      <>
        <div className={`${classes.alignCenter} ${classes.marginTop50}`}>
          <img src={window.PUBLIC_URL + `${ctaConfigJson.logo_url}`} className={classes.logo} />
        </div>
        <div className={classes.mainText}>{ctaConfigJson.main_text}</div>
        <div className={classes.subText}>{ctaConfigJson.sub_text}</div>
        <div className={classes.acceptanceText}>
          <Checkbox checked={acceptTerms} onChange={handleCheckboxChange} />
          By checking here, you confirm that you agree with the{' '}
          <a
            href={ctaConfigJson.terms_url}
            style={{ color: '#4272DD', textDecoration: 'none' }}
            target="_blank"
            rel="noopener noreferrer"
          >
            terms & conditions
          </a>{' '}
          and{' '}
          <a
            href={ctaConfigJson.privacy_policy_url}
            style={{ color: '#4272DD', textDecoration: 'none' }}
            target="_blank"
            rel="noopener noreferrer"
          >
            privacy policy
          </a>
        </div>
        <div style={{ margin: '15px 0', display: 'flex', gap: '30px', justifyContent: 'center' }}>
          <Button
            variant={'contained'}
            onClick={submitApplication}
            color={'primary'}
            className={classes.continueButton}
            disabled={!acceptTerms}
          >
            Continue
          </Button>
          <Button variant={'outlined'} onClick={confirmBeforeClose} color={'primary'} className={classes.cancelButton}>
            Cancel
          </Button>
        </div>
      </>
    )
  }

  return (
    <ComponentVersionsInherit versions={{ checkbox: 2 }}>
      <Dialog
        maxWidth={props.isFullWidth ? 'xl' : 'sm'}
        open={props.open}
        onClose={confirmBeforeClose}
        fullScreen={isMobile || props.isFullWidth}
        fullWidth={isMobile || props.isFullWidth}
      >
        <DialogContent dividers>{renderContent()}</DialogContent>
      </Dialog>
    </ComponentVersionsInherit>
  )
}

export default DisclaimerRedirectDialog
