import { useTranslate } from 'ra-core'
import { useCallback } from 'react'
import { useTranslatableText } from './useTranslatableText'

export const useTranslateOrTranslatableText = (opts?: { locale?: string }) => {
  const translate = useTranslate()
  const translatableText = useTranslatableText(opts)

  return useCallback(
    (data: string | Record<string, string>, subs?: Record<string, string | number>) => {
      if (typeof data === 'string') {
        return translate(data, subs)
      } else {
        return translatableText(data, subs)
      }
    },
    [translate, translatableText]
  )
}
