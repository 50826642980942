import { makeStyles } from '@material-ui/core'
import { JsonFormsInput } from 'elements/jsonForms/JsonFormsInput'
import { FormToolsPanel } from 'elements/react-admin/FormToolsPanel'
import { useEffect, useState } from 'react'
import { SelectInput, TextInput } from 'react-admin'
import { useFormState } from 'react-final-form'
import { required } from 'validations'
import { StructType, useStructTypes } from './hooks/useStructTypes'
import { StructCustomUI } from './StructCustomUI'
import { StructVersionList } from './StructVersionList'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    gap: 16,
    flexWrap: 'wrap',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  main: { flex: 1 },
  jsonInput: {
    '& textarea': {
      maxHeight: 'calc(100vh - 535px)',
      height: 5000,
      minHeight: 300,
    },
  },
  rightColumn: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    maxHeight: 'calc(100vh - 380px)',
    overflowY: 'auto',
    paddingRight: 8,

    [theme.breakpoints.down('md')]: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      maxHeight: 'unset',
    },
  },
}))

export const StructFormContent = ({ isCreate }: { isCreate: boolean }) => {
  const structTypes = useStructTypes()
  const classes = useStyles()
  const record = useFormState().values

  const formState = useFormState()
  const [currentType, setCurrentType] = useState<StructType | undefined>()
  const structTypeKey = formState.values.type

  useEffect(() => {
    if (!structTypes || !structTypeKey) return
    setCurrentType(structTypes.find((t) => t.id === structTypeKey))
  }, [structTypeKey, structTypes])

  return (
    <div className={classes.wrapper}>
      <div className={classes.main}>
        <SelectInput
          alwaysOn={true}
          source="type"
          optionText="title"
          optionValue="id"
          choices={structTypes}
          allowEmpty={false}
          disabled={!isCreate}
          validate={required}
        />
        <TextInput
          source="key"
          disabled={!isCreate}
          required={true}
          validate={required}
          format={(v) =>
            // Should match the validation in struct/models.py
            v
              ?.replace(' ', '_')
              .replace(/[^a-zA-Z0-9_-]/g, '')
              .toLowerCase()
          }
        />
        <TextInput label="Description" source="description" />
        <JsonFormsInput
          label="Data"
          source="data"
          fullWidth={true}
          validate={required}
          //@ts-ignore - prevent the textarea from being a fixed height
          rows={'unset'}
          fallbackProps={{ InputProps: { className: classes.jsonInput + ' code-block' } }}
          schema={currentType?.uischema ? currentType?.schema : undefined}
          uischema={currentType?.uischema}
        />
      </div>
      <div className={classes.rightColumn}>
        <StructCustomUI />
        <FormToolsPanel omitProps={['version_history']} resource={'struct_' + formState.values.type} />
        <StructVersionList versions={record.version_history} />
      </div>
    </div>
  )
}
