import { Portal } from '@material-ui/core'
import { useUxVersion } from 'hooks/useUxVersion'
import ProposalActionBar from 'pages/home/proposalActionBar/index'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { useFeatureFlag } from 'util/split'
import ProjectNav from '../header3/ProjectNav'
import ExplorePageSubHeader from './ExplorePageSubHeader'
import ProjectSubHeader from './ProjectSubHeader'

type PropsType = {
  submitting: boolean
}

const SubHeader: FC<PropsType> = ({ submitting }) => {
  const ui3Enabled = useUxVersion() === 3
  const section = useSelector((state: any) => state.project?.section)
  const enableProposalV2 = useFeatureFlag('enable_sales_machine', 'on')

  const isExplorePage = section === 'explore'
  const isProposal = ['preview', 'proposal'].includes(section)

  return (
    <Portal container={() => document.getElementById('secondary-appbar')}>
      {isExplorePage ? (
        <ExplorePageSubHeader />
      ) : ui3Enabled ? (
        <ProjectNav submitting={submitting} />
      ) : (
        <ProjectSubHeader submitting={submitting} />
      )}
      {isProposal && enableProposalV2 && <ProposalActionBar />}
    </Portal>
  )
}

export default SubHeader
