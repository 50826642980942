import { BaseTheme, styled } from 'opensolar-ui'
import AlertsPopover from 'projectSections/sections/common/AlertsPopover'
import { useTranslate } from 'ra-core'
import { MouseEventHandler, useCallback, useMemo } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { NavLink } from 'react-router-dom'

const Wrapper = styled('div', { name: 'Wrapper' })({
  position: 'relative',
})

const LinkStyled = styled(NavLink, { name: 'LinkStyled' })(({ theme }) => ({
  fontWeight: 500,
  padding: '15px 20px',
  textDecoration: 'none',
  '&:link': {
    color: theme.palette.grey[700],
  },
  '&:visited': {
    color: theme.palette.grey[700],
  },
  borderBottom: '4px solid transparent',
}))

interface PropTypes {
  name: string
  label: string
  redirect: string
  onClick: MouseEventHandler
}

const navActive = { color: BaseTheme.palette.primary.contrastText, borderBottomColor: BaseTheme.palette.primary.main }

const ProjectNavLink: React.FC<PropTypes> = ({ name, label, redirect, onClick }) => {
  const form = useForm()
  const translate = useTranslate()
  const project = useFormState().values
  const projectId = useMemo(() => project.id ?? 'new', [project.id])
  const handleClick = useCallback((e) => {
    form.mutators.recordFormDirtyFields()
  }, [])
  return (
    <Wrapper onClick={handleClick}>
      <LinkStyled activeStyle={navActive} key={name} to={`/projects/${projectId}/${redirect}`} onClick={onClick}>
        {translate(label)}
      </LinkStyled>
      <AlertsPopover
        section={name}
        wrapperStyle={{
          position: 'absolute',
          top: -15,
          right: -10,
        }}
      />
    </Wrapper>
  )
}
export default ProjectNavLink
