import { useEndpointPoller } from 'hooks/useEndpointPoller'
import { Logger } from 'opensolar-sdk'
import { useEffect, useRef, useState } from 'react'
import { fetchRequest } from 'util/fetch'
import { Struct, StructsMap } from '../types/types'

const logger = new Logger('OS.StructWatcher')

// Polls a small versions CSV file and then loads the struct types that have new versions.
export const useStructVersionWatcher = (base_url: string, pollTime: number): StructsMap => {
  const lastVersions = useRef<Record<string, string>>({})
  const [versions, setVersions] = useState<Record<string, string>>({})
  const [value, setValue] = useState<StructsMap>({})

  const loadStructType = async (type: string) => {
    setValue((prev) => ({
      ...prev,
      [type]: {
        loadState: 'reloading',
        structs: [],
      },
    }))
    const url = base_url + `${type}/all.json?cache_bust=` + Date.now()
    let data
    try {
      // Don't use fetchJson directly, as it causes CORS issues in real envs (by including Content-Type header)
      data = await fetchRequest(url)
    } catch (e) {
      logger.error('Failed to load struct type: ', type, e)
      setValue((prev) => {
        const prevStructs = prev[type]?.structs || []
        return {
          ...prev,
          [type]: {
            loadState: prevStructs.length ? 'loaded' : 'not-loaded',
            structs: prevStructs,
          },
        }
      })
      return
    }
    logger.debug('Loaded struct type: ', type, data)
    setValue((prev) => ({
      ...prev,
      [type]: {
        loadState: 'loaded',
        structs: data.json as Struct<any>[],
      },
    }))
  }

  const versionsUrl = base_url + `struct_versions.csv?cache_bust=`
  useEndpointPoller(
    versionsUrl,
    pollTime,
    (data: string) => {
      const csv = data.split('\n').reduce((acc, line) => {
        if (!line) return acc
        const [type, version] = line.split(',')
        acc[type] = version
        return acc
      }, {} as Record<string, string>)

      // Add any missing struct types in not-loaded state
      setValue((prev) => {
        const structs = Object.keys(csv).reduce((acc, key) => {
          if (!acc[key]) acc[key] = { loadState: 'not-loaded', structs: [] }
          return acc
        }, prev)

        return structs
      })
      logger.log('Updated struct versions: ', csv)
      setVersions(csv)
    },
    { cache_bust: true, format: 'text' }
  )

  // Trigger the loading of any struct types that have new versions
  useEffect(() => {
    const keys = Object.keys(versions)

    for (const key of keys) {
      const version = versions[key]
      const lastVersion = lastVersions.current[key]
      if (version === lastVersion) continue

      lastVersions.current[key] = version
      if (!version) continue // Not published yet

      logger.log('New version detected for struct, reloading: ', key)

      loadStructType(key)
    }
  }, [versions])

  return value
}
