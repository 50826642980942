var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Card as Mui4Card } from '@material-ui/core';
import { default as Mui5Card } from '@mui/material/Card';
import { forwardRef } from 'react';
import { Inspector } from '../debug/Inspector';
import { useComponentVersion } from '../hooks';
export var Card = forwardRef(function (props, ref) {
    return _jsx(Inspector, { name: "Card", propDefs: CardProps, showExample: true, component: OsCard, props: __assign(__assign({}, props), { ref: ref }) });
});
export var OsCardVersion;
(function (OsCardVersion) {
    OsCardVersion[OsCardVersion["V1"] = 1] = "V1";
    OsCardVersion[OsCardVersion["V2"] = 2] = "V2";
})(OsCardVersion || (OsCardVersion = {}));
var OsCard = forwardRef(function (props, ref) {
    if (ref === void 0) { ref = null; }
    var version = useComponentVersion('card');
    switch (version) {
        case OsCardVersion.V2:
            //@ts-ignore
            return _jsx(Mui5Card, __assign({ ref: ref }, props));
        default:
            return _jsx(Mui4Card, __assign({ ref: ref }, props));
    }
});
var CardProps = {};
