import { InverterConfig, InverterLimits } from '../EnphaseTypes'
// Usage examples:
// "IQ8PLUS-72-M-INT [25 year warranty]" -> "IQ8PLUS-72-M-INT"
// "IQ8PLUS-72-M-INT-25YR" -> "IQ8PLUS-72-M-INT"
// "IQ8PLUS-80-2-US" -> "IQ8PLUS-72-M-INT"

//Data source: https://docs.google.com/spreadsheets/d/1MTUoQmFBevsaK4KMejlyqaqYmD58MYyCtfGEfrn-aNk/edit?gid=0#gid=0

export const getMatchingEnphaseInverterConfig = (code: string): string => {
  // Remove warranty info and spaces
  code = code.replace(/\s*\[.*?\]\s*/, '').trim()

  // Match pattern: IQ[7/8] + Model Type (PLUS/A/MC/AC/HC/X/P) + Optional -72/-80/-60
  const corePattern = /(IQ[78](?:PLUS|A|MC|AC|HC|X|P))-?(?:\d{2})?/
  const match = code.match(corePattern)

  if (!match) return code

  // Find matching config using core pattern
  const matchingConfig = Object.keys(INVERTER_CONFIGS).find((configCode) => {
    const configMatch = configCode.match(corePattern)
    return configMatch && configMatch[1] === match[1]
  })

  return matchingConfig || code
}

export const INVERTER_CONFIGS: { [key: string]: InverterConfig } = {
  'IQ7A-72-M-INT': {
    code: 'IQ7A-72-M-INT',
    defaultLimits: {
      '1P': { maxUnitsPerCircuit: 10, maxUnitsPerCable: 10 },
      '3P': { maxUnitsPerCircuit: 30, maxUnitsPerCable: 18 },
    },
    countrySpecificLimits: {
      BE: { '1P': { maxUnitsPerCircuit: 10, maxUnitsPerCable: 8 } },
      NL: { '1P': { maxUnitsPerCircuit: 10, maxUnitsPerCable: 8 } },
      AU: {
        '1P': { maxUnitsPerCircuit: 11, maxUnitsPerCable: 11 },
        '3P': { maxUnitsPerCircuit: 33, maxUnitsPerCable: 8 },
      },
      NZ: {
        '1P': { maxUnitsPerCircuit: 11, maxUnitsPerCable: 11 },
        '3P': { maxUnitsPerCircuit: 33, maxUnitsPerCable: 8 },
      },
      US: {
        '1P': { maxUnitsPerCircuit: 12, maxUnitsPerCable: 8 },
        '3P': { maxUnitsPerCircuit: 36, maxUnitsPerCable: 18 },
      },
    },
  },
  'IQ7-60-M-INT': {
    code: 'IQ7-60-M-INT',
    defaultLimits: {
      '1P': { maxUnitsPerCircuit: 15, maxUnitsPerCable: 15 },
      '3P': { maxUnitsPerCircuit: 45, maxUnitsPerCable: 24 },
    },
  },
  'IQ7-60-2-INT': {
    code: 'IQ7-60-2-INT',
    defaultLimits: {
      '1P': { maxUnitsPerCircuit: 16, maxUnitsPerCable: 15 },
      '3P': { maxUnitsPerCircuit: 45, maxUnitsPerCable: 24 },
    },
    countrySpecificLimits: {
      AU: { '1P': { maxUnitsPerCircuit: 16, maxUnitsPerCable: 15 } },
      NZ: { '1P': { maxUnitsPerCircuit: 16, maxUnitsPerCable: 15 } },
    },
  },
  'IQ7PLUS-72-M-INT': {
    code: 'IQ7PLUS-72-M-INT',
    defaultLimits: {
      '1P': { maxUnitsPerCircuit: 12, maxUnitsPerCable: 12 },
      '3P': { maxUnitsPerCircuit: 36, maxUnitsPerCable: 21 },
    },
    countrySpecificLimits: {
      AU: {
        '1P': { maxUnitsPerCircuit: 13, maxUnitsPerCable: 13 },
        '3P': { maxUnitsPerCircuit: 39, maxUnitsPerCable: 21 },
      },
      NZ: {
        '1P': { maxUnitsPerCircuit: 13, maxUnitsPerCable: 13 },
        '3P': { maxUnitsPerCircuit: 39, maxUnitsPerCable: 21 },
      },
    },
  },
  'IQ7PLUS-72-2-INT': {
    code: 'IQ7PLUS-72-2-INT',
    defaultLimits: {
      '1P': { maxUnitsPerCircuit: 13, maxUnitsPerCable: 13 },
      '3P': { maxUnitsPerCircuit: 39, maxUnitsPerCable: 21 },
    },
    countrySpecificLimits: {
      AU: { '1P': { maxUnitsPerCircuit: 13, maxUnitsPerCable: 13 } },
      NZ: { '1P': { maxUnitsPerCircuit: 13, maxUnitsPerCable: 13 } },
    },
  },
  'IQ7X-96-2-INT': {
    code: 'IQ7X-96-2-INT',
    defaultLimits: {
      '1P': { maxUnitsPerCircuit: 12, maxUnitsPerCable: 12 },
      '3P': { maxUnitsPerCircuit: 36, maxUnitsPerCable: 21 },
    },
    countrySpecificLimits: {
      AU: { '1P': { maxUnitsPerCircuit: 12, maxUnitsPerCable: 12 } },
      NZ: { '1P': { maxUnitsPerCircuit: 12, maxUnitsPerCable: 12 } },
    },
  },
  'IQ7X-96-x-US': {
    code: 'IQ7X-96-x-US',
    defaultLimits: {
      '1P': { maxUnitsPerCircuit: 10, maxUnitsPerCable: 10 },
      '3P': { maxUnitsPerCircuit: 30, maxUnitsPerCable: 18 },
    },
    countrySpecificLimits: {
      US: { '1P': { maxUnitsPerCircuit: 10, maxUnitsPerCable: 10 } },
      CA: { '1P': { maxUnitsPerCircuit: 10, maxUnitsPerCable: 10 } },
      MX: { '1P': { maxUnitsPerCircuit: 10, maxUnitsPerCable: 10 } },
    },
  },
  'IQ8-60-M-US': {
    code: 'IQ8-60-M-US',
    defaultLimits: {
      '1P': { maxUnitsPerCircuit: 16, maxUnitsPerCable: 16 },
      '3P': { maxUnitsPerCircuit: 30, maxUnitsPerCable: 18 },
    },
    countrySpecificLimits: {
      US: { '1P': { maxUnitsPerCircuit: 16, maxUnitsPerCable: 16 } },
      CA: { '1P': { maxUnitsPerCircuit: 16, maxUnitsPerCable: 16 } },
    },
  },
  'IQ8-60-2-US': {
    code: 'IQ8-60-2-US',
    defaultLimits: {
      '1P': { maxUnitsPerCircuit: 16, maxUnitsPerCable: 16 },
      '3P': { maxUnitsPerCircuit: 30, maxUnitsPerCable: 18 },
    },
    countrySpecificLimits: {
      US: { '1P': { maxUnitsPerCircuit: 16, maxUnitsPerCable: 16 } },
      MX: { '1P': { maxUnitsPerCircuit: 16, maxUnitsPerCable: 16 } },
    },
  },
  'IQ8A-72-M-US': {
    code: 'IQ8A-72-M-US',
    defaultLimits: {
      '1P': { maxUnitsPerCircuit: 11, maxUnitsPerCable: 11 },
      '3P': { maxUnitsPerCircuit: 30, maxUnitsPerCable: 18 },
    },
    countrySpecificLimits: {
      US: { '1P': { maxUnitsPerCircuit: 11, maxUnitsPerCable: 11 } },
      CA: { '1P': { maxUnitsPerCircuit: 11, maxUnitsPerCable: 11 } },
    },
  },
  'IQ8A-72-2-US': {
    code: 'IQ8A-72-2-US',
    defaultLimits: {
      '1P': { maxUnitsPerCircuit: 11, maxUnitsPerCable: 11 },
      '3P': { maxUnitsPerCircuit: 30, maxUnitsPerCable: 18 },
    },
    countrySpecificLimits: {
      US: { '1P': { maxUnitsPerCircuit: 11, maxUnitsPerCable: 11 } },
      CA: { '1P': { maxUnitsPerCircuit: 11, maxUnitsPerCable: 11 } },
    },
  },
  'IQ8AC-72-M-US': {
    code: 'IQ8AC-72-M-US',
    defaultLimits: {
      '1P': { maxUnitsPerCircuit: 9, maxUnitsPerCable: 9 },
      '3P': { maxUnitsPerCircuit: 30, maxUnitsPerCable: 18 },
    },
    countrySpecificLimits: {
      US: { '1P': { maxUnitsPerCircuit: 9, maxUnitsPerCable: 9 } },
      CA: { '1P': { maxUnitsPerCircuit: 9, maxUnitsPerCable: 9 } },
    },
  },
  'IQ8AC-72-M-INT': {
    code: 'IQ8AC-72-M-INT',
    defaultLimits: {
      '1P': { maxUnitsPerCircuit: 10, maxUnitsPerCable: 8 },
      '3P': { maxUnitsPerCircuit: 30, maxUnitsPerCable: 18 },
    },
    countrySpecificLimits: {
      AU: {
        '1P': { maxUnitsPerCircuit: 11, maxUnitsPerCable: 8 },
        '3P': { maxUnitsPerCircuit: 39, maxUnitsPerCable: 18 },
      },
      GB: {
        '1P': { maxUnitsPerCircuit: 10, maxUnitsPerCable: 8 },
        '3P': { maxUnitsPerCircuit: 30, maxUnitsPerCable: 18 },
      },
      IT: {
        '1P': { maxUnitsPerCircuit: 10, maxUnitsPerCable: 8 },
        '3P': { maxUnitsPerCircuit: 30, maxUnitsPerCable: 18 },
      },
    },
  },
  'IQ8HC-72-M-INT': {
    code: 'IQ8HC-72-M-INT',
    defaultLimits: {
      '1P': { maxUnitsPerCircuit: 9, maxUnitsPerCable: 8 },
      '3P': { maxUnitsPerCircuit: 27, maxUnitsPerCable: 18 },
    },
    countrySpecificLimits: {
      AU: {
        '1P': { maxUnitsPerCircuit: 10, maxUnitsPerCable: 8 },
        '3P': { maxUnitsPerCircuit: 36, maxUnitsPerCable: 18 },
      },
      NZ: {
        '1P': { maxUnitsPerCircuit: 10, maxUnitsPerCable: 8 },
        '3P': { maxUnitsPerCircuit: 36, maxUnitsPerCable: 18 },
      },
      GB: {
        '1P': { maxUnitsPerCircuit: 9, maxUnitsPerCable: 8 },
        '3P': { maxUnitsPerCircuit: 27, maxUnitsPerCable: 18 },
      },
    },
  },
  'IQ8M-72-2-US': {
    code: 'IQ8M-72-2-US',
    defaultLimits: {
      '1P': { maxUnitsPerCircuit: 11, maxUnitsPerCable: 11 },
      '3P': { maxUnitsPerCircuit: 30, maxUnitsPerCable: 18 },
    },
    countrySpecificLimits: {
      US: { '1P': { maxUnitsPerCircuit: 11, maxUnitsPerCable: 11 } },
      CA: { '1P': { maxUnitsPerCircuit: 11, maxUnitsPerCable: 11 } },
    },
  },
  'IQ8M-72-M-INT': {
    code: 'IQ8M-72-M-INT',
    defaultLimits: {
      '1P': { maxUnitsPerCircuit: 11, maxUnitsPerCable: 8 },
      '3P': { maxUnitsPerCircuit: 33, maxUnitsPerCable: 18 },
    },
    countrySpecificLimits: {
      AT: {
        '1P': { maxUnitsPerCircuit: 11, maxUnitsPerCable: 8 },
        '3P': { maxUnitsPerCircuit: 33, maxUnitsPerCable: 18 },
      },
      BE: {
        '1P': { maxUnitsPerCircuit: 11, maxUnitsPerCable: 8 },
        '3P': { maxUnitsPerCircuit: 33, maxUnitsPerCable: 18 },
      },
      CH: {
        '1P': { maxUnitsPerCircuit: 11, maxUnitsPerCable: 8 },
        '3P': { maxUnitsPerCircuit: 33, maxUnitsPerCable: 18 },
      },
      DE: {
        '1P': { maxUnitsPerCircuit: 11, maxUnitsPerCable: 8 },
        '3P': { maxUnitsPerCircuit: 33, maxUnitsPerCable: 18 },
      },
      DK: {
        '1P': { maxUnitsPerCircuit: 11, maxUnitsPerCable: 8 },
        '3P': { maxUnitsPerCircuit: 33, maxUnitsPerCable: 18 },
      },
      ES: {
        '1P': { maxUnitsPerCircuit: 11, maxUnitsPerCable: 8 },
        '3P': { maxUnitsPerCircuit: 33, maxUnitsPerCable: 18 },
      },
      FR: {
        '1P': { maxUnitsPerCircuit: 11, maxUnitsPerCable: 8 },
        '3P': { maxUnitsPerCircuit: 33, maxUnitsPerCable: 18 },
      },
      GR: {
        '1P': { maxUnitsPerCircuit: 11, maxUnitsPerCable: 8 },
        '3P': { maxUnitsPerCircuit: 33, maxUnitsPerCable: 18 },
      },
      IT: {
        '1P': { maxUnitsPerCircuit: 11, maxUnitsPerCable: 8 },
        '3P': { maxUnitsPerCircuit: 33, maxUnitsPerCable: 18 },
      },
      NL: {
        '1P': { maxUnitsPerCircuit: 11, maxUnitsPerCable: 8 },
        '3P': { maxUnitsPerCircuit: 33, maxUnitsPerCable: 18 },
      },
      PT: {
        '1P': { maxUnitsPerCircuit: 11, maxUnitsPerCable: 8 },
        '3P': { maxUnitsPerCircuit: 33, maxUnitsPerCable: 18 },
      },
      SE: {
        '1P': { maxUnitsPerCircuit: 11, maxUnitsPerCable: 8 },
        '3P': { maxUnitsPerCircuit: 33, maxUnitsPerCable: 18 },
      },
    },
  },
  'IQ8MC-72-M-INT': {
    code: 'IQ8MC-72-M-INT',
    defaultLimits: {
      '1P': { maxUnitsPerCircuit: 11, maxUnitsPerCable: 8 },
      '3P': { maxUnitsPerCircuit: 33, maxUnitsPerCable: 18 },
    },
    countrySpecificLimits: {
      AT: {
        '1P': { maxUnitsPerCircuit: 11, maxUnitsPerCable: 8 },
        '3P': { maxUnitsPerCircuit: 33, maxUnitsPerCable: 18 },
      },
      BE: {
        '1P': { maxUnitsPerCircuit: 11, maxUnitsPerCable: 8 },
        '3P': { maxUnitsPerCircuit: 33, maxUnitsPerCable: 18 },
      },
      CH: {
        '1P': { maxUnitsPerCircuit: 11, maxUnitsPerCable: 8 },
        '3P': { maxUnitsPerCircuit: 33, maxUnitsPerCable: 18 },
      },
      DE: {
        '1P': { maxUnitsPerCircuit: 11, maxUnitsPerCable: 8 },
        '3P': { maxUnitsPerCircuit: 33, maxUnitsPerCable: 18 },
      },
      DK: {
        '1P': { maxUnitsPerCircuit: 11, maxUnitsPerCable: 8 },
        '3P': { maxUnitsPerCircuit: 33, maxUnitsPerCable: 18 },
      },
      ES: {
        '1P': { maxUnitsPerCircuit: 11, maxUnitsPerCable: 8 },
        '3P': { maxUnitsPerCircuit: 33, maxUnitsPerCable: 18 },
      },
      FR: {
        '1P': { maxUnitsPerCircuit: 11, maxUnitsPerCable: 8 },
        '3P': { maxUnitsPerCircuit: 33, maxUnitsPerCable: 18 },
      },
      GR: {
        '1P': { maxUnitsPerCircuit: 11, maxUnitsPerCable: 8 },
        '3P': { maxUnitsPerCircuit: 33, maxUnitsPerCable: 18 },
      },
      NL: {
        '1P': { maxUnitsPerCircuit: 11, maxUnitsPerCable: 8 },
        '3P': { maxUnitsPerCircuit: 33, maxUnitsPerCable: 18 },
      },
      PT: {
        '1P': { maxUnitsPerCircuit: 11, maxUnitsPerCable: 8 },
        '3P': { maxUnitsPerCircuit: 33, maxUnitsPerCable: 18 },
      },
      SE: {
        '1P': { maxUnitsPerCircuit: 11, maxUnitsPerCable: 8 },
        '3P': { maxUnitsPerCircuit: 33, maxUnitsPerCable: 18 },
      },
      IT: {
        '1P': { maxUnitsPerCircuit: 11, maxUnitsPerCable: 8 },
        '3P': { maxUnitsPerCircuit: 33, maxUnitsPerCable: 18 },
      },
    },
  },
  'IQ8P-72-2-INT': {
    code: 'IQ8P-72-2-INT',
    defaultLimits: {
      '1P': { maxUnitsPerCircuit: 7, maxUnitsPerCable: 7 },
      '3P': { maxUnitsPerCircuit: 21, maxUnitsPerCable: 21 },
    },
    countrySpecificLimits: {
      IN: { '1P': { maxUnitsPerCircuit: 7, maxUnitsPerCable: 7 } },
      AU: { '1P': { maxUnitsPerCircuit: 7, maxUnitsPerCable: 7 } },
      NZ: { '1P': { maxUnitsPerCircuit: 7, maxUnitsPerCable: 7 } },
    },
  },
  'IQ8X-80-M-INT': {
    code: 'IQ8X-80-M-INT',
    defaultLimits: {
      '1P': { maxUnitsPerCircuit: 9, maxUnitsPerCable: 8 },
      '3P': { maxUnitsPerCircuit: 27, maxUnitsPerCable: 18 },
    },
    countrySpecificLimits: {
      AU: {
        '1P': { maxUnitsPerCircuit: 10, maxUnitsPerCable: 8 },
        '3P': { maxUnitsPerCircuit: 36, maxUnitsPerCable: 18 },
      },
      GB: {
        '1P': { maxUnitsPerCircuit: 9, maxUnitsPerCable: 8 },
        '3P': { maxUnitsPerCircuit: 27, maxUnitsPerCable: 18 },
      },
      IT: {
        '1P': { maxUnitsPerCircuit: 9, maxUnitsPerCable: 8 },
        '3P': { maxUnitsPerCircuit: 27, maxUnitsPerCable: 18 },
      },
    },
  },
  'IQ8PLUS-72-M-US': {
    code: 'IQ8PLUS-72-M-US',
    defaultLimits: {
      '1P': { maxUnitsPerCircuit: 13, maxUnitsPerCable: 13 },
      '3P': { maxUnitsPerCircuit: 30, maxUnitsPerCable: 18 },
    },
    countrySpecificLimits: {
      US: { '1P': { maxUnitsPerCircuit: 13, maxUnitsPerCable: 13 } },
    },
  },
  'IQ8PLUS-72-2-US': {
    code: 'IQ8PLUS-72-2-US',
    defaultLimits: {
      '1P': { maxUnitsPerCircuit: 13, maxUnitsPerCable: 13 },
      '3P': { maxUnitsPerCircuit: 30, maxUnitsPerCable: 18 },
    },
    countrySpecificLimits: {
      US: { '1P': { maxUnitsPerCircuit: 13, maxUnitsPerCable: 13 } },
      MX: { '1P': { maxUnitsPerCircuit: 13, maxUnitsPerCable: 13 } },
    },
  },
  'IQ8PLUS-72-M-INT': {
    code: 'IQ8PLUS-72-M-INT',
    defaultLimits: {
      '1P': { maxUnitsPerCircuit: 12, maxUnitsPerCable: 8 },
      '3P': { maxUnitsPerCircuit: 36, maxUnitsPerCable: 21 },
    },
    countrySpecificLimits: {
      AT: {
        '1P': { maxUnitsPerCircuit: 11, maxUnitsPerCable: 8 },
        '3P': { maxUnitsPerCircuit: 33, maxUnitsPerCable: 18 },
      },
      BE: {
        '1P': { maxUnitsPerCircuit: 11, maxUnitsPerCable: 8 },
        '3P': { maxUnitsPerCircuit: 33, maxUnitsPerCable: 18 },
      },
      CH: {
        '1P': { maxUnitsPerCircuit: 11, maxUnitsPerCable: 8 },
        '3P': { maxUnitsPerCircuit: 33, maxUnitsPerCable: 18 },
      },
      DE: {
        '1P': { maxUnitsPerCircuit: 11, maxUnitsPerCable: 8 },
        '3P': { maxUnitsPerCircuit: 33, maxUnitsPerCable: 18 },
      },
      DK: {
        '1P': { maxUnitsPerCircuit: 11, maxUnitsPerCable: 8 },
        '3P': { maxUnitsPerCircuit: 33, maxUnitsPerCable: 18 },
      },
      ES: {
        '1P': { maxUnitsPerCircuit: 11, maxUnitsPerCable: 8 },
        '3P': { maxUnitsPerCircuit: 33, maxUnitsPerCable: 18 },
      },
      FR: {
        '1P': { maxUnitsPerCircuit: 11, maxUnitsPerCable: 8 },
        '3P': { maxUnitsPerCircuit: 33, maxUnitsPerCable: 18 },
      },
      GR: {
        '1P': { maxUnitsPerCircuit: 11, maxUnitsPerCable: 8 },
        '3P': { maxUnitsPerCircuit: 33, maxUnitsPerCable: 18 },
      },
      IT: {
        '1P': { maxUnitsPerCircuit: 11, maxUnitsPerCable: 8 },
        '3P': { maxUnitsPerCircuit: 33, maxUnitsPerCable: 18 },
      },
      NL: {
        '1P': { maxUnitsPerCircuit: 11, maxUnitsPerCable: 8 },
        '3P': { maxUnitsPerCircuit: 33, maxUnitsPerCable: 18 },
      },
      PT: {
        '1P': { maxUnitsPerCircuit: 11, maxUnitsPerCable: 8 },
        '3P': { maxUnitsPerCircuit: 33, maxUnitsPerCable: 18 },
      },
      SE: {
        '1P': { maxUnitsPerCircuit: 11, maxUnitsPerCable: 8 },
        '3P': { maxUnitsPerCircuit: 33, maxUnitsPerCable: 18 },
      },
    },
  },
  'IQ8X-80-M-US': {
    code: 'IQ8X-80-M-US',
    defaultLimits: {
      '1P': { maxUnitsPerCircuit: 9, maxUnitsPerCable: 9 },
      '3P': { maxUnitsPerCircuit: 30, maxUnitsPerCable: 18 },
    },
    countrySpecificLimits: {
      US: { '1P': { maxUnitsPerCircuit: 9, maxUnitsPerCable: 9 } },
      CA: { '1P': { maxUnitsPerCircuit: 9, maxUnitsPerCable: 9 } },
      MX: { '1P': { maxUnitsPerCircuit: 9, maxUnitsPerCable: 9 } },
    },
  },
  'IQ8X-BAT-US': {
    code: 'IQ8X-BAT-US',
    defaultLimits: {
      '1P': { maxUnitsPerCircuit: 12, maxUnitsPerCable: 12 },
      '3P': { maxUnitsPerCircuit: 30, maxUnitsPerCable: 18 },
    },
    countrySpecificLimits: {
      US: { '1P': { maxUnitsPerCircuit: 12, maxUnitsPerCable: 12 } },
      CA: { '1P': { maxUnitsPerCircuit: 12, maxUnitsPerCable: 12 } },
    },
  },
}

export const DEFAULT_INVERTER_LIMITS: Record<string, InverterLimits> = {
  '1P_AU': { maxUnitsPerCircuit: 11, maxUnitsPerCable: 8 },
  '1P_IT': { maxUnitsPerCircuit: 10, maxUnitsPerCable: 8 },
  '1P_OTHER': { maxUnitsPerCircuit: 10, maxUnitsPerCable: 8 },
  '3P_AU': { maxUnitsPerCircuit: 39, maxUnitsPerCable: 18 },
  '3P_IT': { maxUnitsPerCircuit: 31, maxUnitsPerCable: 18 },
  '3P_OTHER': { maxUnitsPerCircuit: 30, maxUnitsPerCable: 18 },
}
