import useTranslateWithVariable from 'projectSections/hooks/useTranslateWithVariable'
import { SystemParams } from '../EnphaseTypes'

const getSystemType = (params: SystemParams): string => {
  const hasSolar = params.numberOfPanels > 0
  const hasBattery = params.system.batteries().length > 0

  if (hasSolar && hasBattery) return 'Solar and Battery'
  if (hasSolar) return 'Solar Only'
  if (hasBattery) return 'Battery Only'
  return 'Unknown'
}

const styles = {
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '32px',
  },
  heading: {
    fontSize: '20px',
    fontWeight: 500,
    marginBottom: '16px',
  },
  subheading: {
    fontSize: '18px',
    fontWeight: 500,
    marginBottom: '12px',
  },
  infoGrid: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gap: '8px 16px',
    alignItems: 'baseline',
  },
  label: {
    color: '#666',
    fontSize: '14px',
  },
  value: {
    fontSize: '14px',
  },
}

export const SiteSummary = ({ params }: { params: SystemParams }) => {
  const moduleType = params.system.moduleType()
  const extraCableDrops = Math.floor(params.numberOfPanels / 20) + 1
  const translate = useTranslateWithVariable()

  return (
    <div>
      <div style={styles.grid}>
        <div>
          <h3 style={styles.subheading}>{translate('System Summary')}</h3>
          <div style={styles.infoGrid}>
            <span style={styles.label}>{translate('Micro Inverter')}</span>
            <span style={styles.value}>{params.system.inverters()[0].code}</span>

            <span style={styles.label}>{translate('Phases')}</span>
            <span style={styles.value}>{translate('%{quantity} Phase', { quantity: params.numberOfPhases })}</span>

            <span style={styles.label}>{translate('Orientation')}</span>
            <span style={styles.value}>
              {[
                params.numberOfPanelsPortrait > 0
                  ? translate('%{quantity} Portrait', { quantity: params.numberOfPanelsPortrait })
                  : null,
                params.numberOfPanelsLandscape > 0
                  ? translate('%{quantity} Landscape', { quantity: params.numberOfPanelsLandscape })
                  : null,
              ]
                .filter(Boolean)
                .join(', ')}
            </span>

            <span style={styles.label}>{translate('Extra Cable Drops')}</span>
            <span style={styles.value}>{extraCableDrops}</span>
          </div>
        </div>

        {params.numberOfPanels > 0 && (
          <div>
            <h3 style={styles.subheading}>{translate('Module Details')}</h3>
            <div style={styles.infoGrid}>
              <span style={styles.label}>{translate('Module')}</span>
              <span style={styles.value}>{moduleType.code}</span>

              <span style={styles.label}>{translate('Module wattage')}</span>
              <span style={styles.value}>{(moduleType.kw_stc ?? 0) * 1000} W</span>

              <span style={styles.label}>{translate('Count')}</span>
              <span style={styles.value}>{params.numberOfPanels}</span>

              <span style={styles.label}>{translate('Configuration')}</span>
              <span style={{ display: 'flex', alignItems: 'center' }}>{translate(getSystemType(params))}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
