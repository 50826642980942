import { toggleSendProposalDialog } from 'ducks/myEnergy'
import { useCallback, useMemo } from 'react'
import { useForm } from 'react-final-form'
import { useDispatch } from 'react-redux'
import {
  useGetCashflowCustomerInfoError,
  useGetCommercialRequirementsInfoError,
} from '../payments/cashFlowTransactions/utils'
import { useGetProposalHasCashFlowDeposit } from './utils'

interface UseSendProposalButtonParams {
  unsavedChangesErrorMessage?: string
}

export type SendProposalResponseType = {
  disableButton: boolean
  errorMessage?: string
  hasUnsavedChanges: boolean
  onSend(): void
}

const useSendProposalButton = ({
  unsavedChangesErrorMessage,
}: UseSendProposalButtonParams | undefined = {}): SendProposalResponseType => {
  const form = useForm()
  const hasCashFlowDeposit = useGetProposalHasCashFlowDeposit()

  const dispatch = useDispatch()

  const hasMissingRequiredCustomerInfo = useGetCashflowCustomerInfoError()
  const hasUnsavedChanges = form.mutators.getFormDirtyFields()?.length !== 0
  const hasCommercialRequirementsInfo = useGetCommercialRequirementsInfoError()

  const validation = useCallback(() => {
    //only show one message at a time util figure out the UI for multiple messages
    if (hasUnsavedChanges) {
      return unsavedChangesErrorMessage || 'Save changes to enable send to customer.'
    } else if (hasMissingRequiredCustomerInfo) {
      return 'Please fill out missing required customer and/or project address details to enable send to customer.'
    } else if (hasCommercialRequirementsInfo && hasCashFlowDeposit) {
      return "Please provide the invoice recipient's tax id via the Edit Invoice Details button in the Payments tab"
    }
  }, [hasUnsavedChanges, hasCashFlowDeposit, hasCommercialRequirementsInfo])

  const errorMessage = useMemo(() => validation(), [validation])
  const disableButton = errorMessage !== undefined

  return {
    disableButton,
    errorMessage,
    hasUnsavedChanges,
    onSend: () => {
      if (disableButton) {
        console.error(errorMessage)
      } else dispatch(toggleSendProposalDialog(true))
    },
  }
}

export default useSendProposalButton
