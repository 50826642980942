import { FilterStruct } from 'conditions/types'
import { useConditionalStructs } from 'contexts/structs/hooks/useConditionalStructs'
import { Struct } from 'contexts/structs/types/types'
import { useMemo } from 'react'
import { DynamicActionInfo } from 'types/ui/DynamicActionInfo'

export const usePromoDialogStructs = () => {
  const ret = useConditionalStructs<PromoDialog>('promo-dialog', { accept_empty_conditions: true, recheck_secs: 10 })

  return useMemo(() => {
    return {
      ...ret,
      structs: prioritisePromoDialogs(ret.structs),
    }
  }, [ret])
}

const TYPE_ORDER = ['blocker', 'onboarding', 'standard', 'tour']
const prioritisePromoDialogs = (structs: Struct<PromoDialog>[]) => {
  return structs.sort((a, b) => {
    const order1 = TYPE_ORDER.indexOf(a.data.type)
    const order2 = TYPE_ORDER.indexOf(b.data.type)
    if (order1 !== order2) {
      return order1 - order2
    } else {
      const prioDiff = (b.data.priority || 0) - (a.data.priority || 0)
      if (prioDiff !== 0) return prioDiff

      // Fall back to something deterministic, but not necessarily meaningful
      return a.key.localeCompare(b.key)
    }
  })
}

export type PromoDialog = {
  type: PromoDialogType
  priority?: number
  show_again_rule: 'never' | 'always' | 'once-per-session'
  conditions: FilterStruct[]
  elements: PromoDialogElement[]
}

export type PromoDialogType = 'blocker' | 'onboarding' | 'standard' | 'tour'

export type PromoDialogElement =
  | PromoDialogTypography
  | PromoDialogGap
  | PromoDialogLink
  | PromoDialogMedia
  | PromoDialogHtml
  | PromoDialogCountdown
  | PromoDialogActionRow
  | PromoDialogComponent

export type PromoDialogTypography = {
  type: 'h3' | 'h4' | 'h5' | 'body1' | 'body2' | 'subtitle1' | 'subtitle2' | 'caption1' | 'caption2'
  text: TranslatableText
}

export type PromoDialogGap = {
  type: 'gap'
  gap_size: 1 | 2 | 3 | 4
}

export type PromoDialogLink = {
  type: 'link'
  text: TranslatableText
  href?: string
  onClick?: () => void
  target?: string
}

export type PromoDialogMedia = {
  type: 'video' | 'image'
  media_url: string
  media_height: number
  media_width?: number
}

export type PromoDialogHtml = {
  type: 'html'
  html: TranslatableText
}

export type PromoDialogCountdown = {
  type: 'countdown'
  alert_severity: 'info' | 'warning' | 'error' | 'success' | 'basic'
  countdown_time: number | string
  countdown_msg: TranslatableText
  countdown_overtime_msg: TranslatableText
  rounding?: 'up' | 'down' | 'nearest'
}

export type PromoDialogActionRow = {
  type: 'action-row'
  actions: DynamicActionInfo[]
}

// For use in runtime-added promos
export type PromoDialogComponent = {
  type: 'react-component'
  render: (props: { element: PromoDialogComponent; key: string; onNext: () => void }) => JSX.Element
}

export type TranslatableText = string | Record<string, string>
