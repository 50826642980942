var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { default as Mui5Button } from '@mui/material/Button';
import { useState } from 'react';
import { styled } from '../styles/styled';
var StyledLink = styled('div')(function (_a) {
    var theme = _a.theme, highlightColor = _a.highlightColor;
    return {
        marginTop: '10px',
        color: highlightColor,
        textAlign: 'center',
        cursor: 'pointer',
        textDecoration: 'underline',
    };
});
var Wrapper = styled('div')(function (_a) {
    var theme = _a.theme;
    return {
        marginTop: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
    };
});
var ActionWrapper = styled('div')(function (_a) {
    var theme = _a.theme;
    return {
        display: 'flex',
        justifyContent: 'center',
    };
});
var DismissButtonWrapper = styled('div')(function (_a) {
    var theme = _a.theme;
    return {
        margin: '10px',
    };
});
var StartButtonWrapper = styled('div')(function (_a) {
    var theme = _a.theme;
    return {
        margin: '10px',
        position: 'relative',
    };
});
var CircularProgressWrapper = styled('div')(function (_a) {
    var theme = _a.theme;
    return {
        position: 'absolute',
        left: 'calc(50% - 12px)',
        top: 'calc(50% - 12px)',
    };
});
export var ReapplicationConfirm = function (_a) {
    var highlightColor = _a.highlightColor, onConfirm = _a.onConfirm, loading = _a.loading, extraMessage = _a.extraMessage;
    var _b = useState(false), showReapplyDialog = _b[0], setShowReapplyDialog = _b[1];
    return (_jsxs(Wrapper, { children: [extraMessage && _jsx("div", __assign({ style: { textAlign: 'center' } }, { children: extraMessage })), _jsx(StyledLink, __assign({ highlightColor: highlightColor, onClick: function () { return setShowReapplyDialog(true); } }, { children: "Want to Re-Apply?" })), _jsxs(Dialog, __assign({ open: showReapplyDialog }, { children: [_jsx(DialogTitle, { children: "Re-Applying for Financing" }), _jsx(DialogContent, { children: "Our records show that you have already applied for this financing product and were previously not approved for financing. Before continuing, please confirm that you understand you will be submitting a new application." }), _jsx(DialogActions, { children: _jsxs(ActionWrapper, { children: [_jsx(DismissButtonWrapper, { children: _jsx(Mui5Button, __assign({ onClick: function () { return setShowReapplyDialog(false); } }, { children: 'Dismiss' })) }), _jsxs(StartButtonWrapper, { children: [_jsx(Mui5Button, __assign({ onClick: onConfirm }, { children: 'I want to start a new application' })), loading && (_jsx(CircularProgressWrapper, { children: _jsx(CircularProgress, { size: 24 }) }))] })] }) })] }))] }));
};
