import { DefaultImageryStrategy } from 'types/imagery'
import { MapDataTypes } from 'types/map'
import { Location4326 } from 'types/studio/structs'

export const DETECT_IMAGERY = 'DETECT_IMAGERY'
export const DETECT_IMAGERY_LOADING = 'DETECT_IMAGERY_LOADING'
export const DETECT_IMAGERY_SUCCESS = 'DETECT_IMAGERY_SUCCESS'
export const DETECT_IMAGERY_FAILURE = 'DETECT_IMAGERY_FAILURE'
export const DETECT_IMAGERY_CLEAR = 'DETECT_IMAGERY_CLEAR'
export const DETECT_IMAGERY_REMOVE = 'DETECT_IMAGERY_REMOVE'
export const SET_IMAGERY_BLOCK_LIST = 'SET_IMAGERY_BLOCK_LIST'
export const SET_DEFAULT_IMAGERY_STRATEGY = 'SET_DEFAULT_IMAGERY_STRATEGY'

export const PRELOAD_3D = 'PRELOAD_3D'

export const LOAD_PROGRESS_ORTHO = 'LOAD_PROGRESS_ORTHO'
export const LOAD_PROGRESS_DSM = 'LOAD_PROGRESS_DSM'
export const LOAD_PROGRESS_RENDER = 'LOAD_PROGRESS_RENDER'

export const preload3D = (dsm, ortho) => ({
  type: PRELOAD_3D,
  payload: {
    dsm,
    ortho,
  },
})

export const detectImagery = (
  location4326: Location4326,
  country_iso2: string,
  state: string,
  address: string,
  premiumImgIsAvailable: boolean,
  preloadTerrainProvider: string | null,
  is_lite: boolean,
  onComplete?: (res: { availableMapTypes: MapDataTypes[]; timezoneOffset: number } | undefined, e: any) => void
) => ({
  type: DETECT_IMAGERY,
  payload: {
    location4326,
    country_iso2,
    state,
    address,
    premiumImgIsAvailable,
    preloadTerrainProvider,
    is_lite,
    onComplete,
  },
})

export const clearImagery = () => ({
  type: DETECT_IMAGERY_CLEAR,
})

export const setMapTypeBlockingList = (blockedMapTypes: string[] = []) => ({
  type: SET_IMAGERY_BLOCK_LIST,
  payload: {
    blockedMapTypes,
  },
})

export const setDefaultImageryStrategy = (defaultImageryStrategy?: DefaultImageryStrategy) => ({
  type: SET_DEFAULT_IMAGERY_STRATEGY,
  payload: {
    defaultImageryStrategy,
  },
})

export const storeAvailableImagery = (availableMapTypes, timezoneOffset) => ({
  type: DETECT_IMAGERY_SUCCESS,
  payload: {
    availableMapTypes: availableMapTypes,
    timezoneOffset: timezoneOffset,
  },
})

export const removeUnavailableImagery = (map_type: String, variation_name: String) => ({
  type: DETECT_IMAGERY_REMOVE,
})
