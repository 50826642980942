import lodash from 'lodash'

/**
 * This provides a way to trigger dev actions via the console.
 * Systems throughout the app can add tools to this object to expose
 * them in the console.
 *
 * ```js
 * addTool("section.myTool", () => console.log("my tool"), { help: "Some help content" })
 * ```
 */

const ostools: any = {}

//@ts-ignore
window.ostools = ostools

const helpContent = {
  help: 'Prints this help',
}

export const addDevTool = (name: string, f: Function, opts?: { help?: string }) => {
  lodash.set(ostools, name, f)
  helpContent[name] = opts?.help || ''
}

export const addDevToolShowHideToggle = (codeName: string, readableName: string, f: (value: boolean) => void) => {
  addDevTool(
    codeName.replace('{showhide}', 'show'),
    function () {
      f(true)
    },
    { help: readableName.replace('{showhide}', 'Show') }
  )
  addDevTool(
    codeName.replace('{showhide}', 'hide'),
    function () {
      f(false)
    },
    { help: readableName.replace('{showhide}', 'Hide') }
  )
}

ostools.help = () => {
  console.log('Available tools:')
  const rows: any = {}
  lodash.forIn(helpContent, (v, k) => {
    rows[`ostools.${k}()`] = v
  })
  console.table(rows)
}
