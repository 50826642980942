var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { default as Mui5Button } from '@mui/material/Button';
import { LoadingDots } from '../../core/LoadingDots';
import { styled } from '../../styles/styled';
var styles = {
    acceptButton: {
        border: '1px solid #30862C',
        fontWeight: 'normal',
        margin: 0,
        lineHeight: 'normal',
        boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.25)',
        borderRadius: '3px',
        color: '#FFFFFF',
        minWidth: 155,
        background: 'linear-gradient(180deg, #46BC41 0%, #34A02F 100%)',
    },
    acceptButtonDisabled: {
        border: '1px solid #b7b7b7',
        fontWeight: 'normal',
        margin: 0,
        lineHeight: 'normal',
        boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.25)',
        borderRadius: '3px',
        color: '#FFFFFF',
        minWidth: 155,
        background: '#dcdcdc',
        pointerEvents: 'none',
    },
};
var LoanAgreementLabel = styled('div')(function (_a) {
    var theme = _a.theme;
    return {
        padding: 0,
        width: '100%',
        textTransform: 'none',
        whiteSpace: 'normal',
    };
});
var LoanAgreementButton = styled(Mui5Button)(function (_a) {
    var theme = _a.theme, disabled = _a.disabled;
    return __assign(__assign({ height: 'unset', minHeight: 40, alignItems: 'center', display: 'flex', overflow: 'auto', padding: 5, fontSize: 16, wordWrap: 'break-word', width: 'calc(100% - 2px)', letterSpacing: 'normal', marginBottom: '10px' }, styles.acceptButton), { '&.Mui-disabled': __assign({}, styles.acceptButtonDisabled) });
});
export var LoanAgreementActionButton = function (_a) {
    var isLoading = _a.isLoading, label = _a.label, disabled = _a.disabled, onClick = _a.onClick;
    return (_jsx(LoanAgreementButton, __assign({ disabled: disabled, onClick: function (e) {
            e.preventDefault();
            onClick === null || onClick === void 0 ? void 0 : onClick();
        } }, { children: isLoading ? _jsx(LoadingDots, { color: "#fff" }) : _jsx(LoanAgreementLabel, { children: label }) })));
};
export default LoanAgreementActionButton;
