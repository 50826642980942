import * as Sentry from '@sentry/react'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { hideCheckoutDialog, showCheckoutDialog } from 'ducks/myEnergy'
import { CheckoutActionCallback } from 'opensolar-checkout'
import { useCallback, useContext } from 'react'
import { useNotify, useTranslate } from 'react-admin'
import { useDispatch } from 'react-redux'
import restClient from 'restClient'
import { logAppOpenedEvent } from 'util/events'
import ActiveDialogContext from '../../dialogs/ActiveDialogContext'

var restClientInstance = restClient(window.API_ROOT + '/api')

const useRequestLoanDocs = () => {
  const notify = useNotify()
  const translate = useTranslate()
  return useCallback(async ({ org_id, project_id }: { org_id: number; project_id: number }) => {
    await restClientInstance('CUSTOM_GET', 'custom', {
      url: 'orgs/' + org_id + '/projects/' + project_id + '/send_loan_docs/',
    }).then(
      (response) => {
        notify(translate('Loan Docs are on their way to your email! It can take up to two minutes for them to arrive.'))
      },
      (reject) => {
        console.log(reject)
        notify(translate('Error Sending Loan Docs'), 'error')
      }
    )
  }, [])
}

const useRefreshApplication = () => {
  const { showDialog } = useContext(ActiveDialogContext)
  const notify = useNotify()
  const dispatch = useDispatch()
  const translate = useTranslate()
  return useCallback(
    async ({
      org_id,
      project_id,
      system_uuid,
      payment_option_id,
      isFullWidth,
    }: {
      org_id: number
      project_id: number
      system_uuid: string
      payment_option_id: number
      isFullWidth?: boolean
    }) => {
      const requestStart = new Date()
      await restClientInstance('CUSTOM_POST', 'custom', {
        url:
          'orgs/' +
          org_id +
          '/projects/' +
          project_id +
          '/refresh_sunlight_iframe_url/' +
          system_uuid +
          '/' +
          payment_option_id +
          '/sunlight_loan_application/',
      }).then(
        (response) => {
          if (response.data.status && response.data.url) {
            showDialog('SunlightLoanApplication', { url: response.data.url }, isFullWidth)
            const requestEnd = new Date()
            let secondsToOpen = 'error'
            try {
              secondsToOpen = `${((requestEnd.getTime() - requestStart.getTime()) / 1000).toFixed(0)}`
            } catch (ex) {}
            logAmplitudeEvent('integrated_finance_application_opened', {
              integration: 'sunlight',
              seconds_to_open: secondsToOpen,
            })
          } else if (!response.data.status && response.data.message) {
            notify(translate(response.data.message), 'error')
          } else {
            notify(translate('Unable to open Sunlight Credit Application'), 'error')
          }
        },
        (reject) => {
          console.log(reject)
          notify(translate(reject.message), 'error')
          dispatch(hideCheckoutDialog())
        }
      )
    },
    []
  )
}

const useStartApplication = () => {
  const dispatch = useDispatch()
  const { showDialog } = useContext(ActiveDialogContext)
  const notify = useNotify()
  const translate = useTranslate()

  return useCallback(
    async ({
      org_id,
      project_id,
      system_uuid,
      payment_option_id,
      isFullWidth,
    }: {
      org_id: number
      project_id: number
      system_uuid: string
      payment_option_id: number
      isFullWidth?: boolean
    }) => {
      const requestStart = new Date()
      dispatch(
        showCheckoutDialog({
          type: 'SunlightLoanApplication',
          config: {
            type: 'credit',
          },
        })
      )
      await restClientInstance('CUSTOM_POST', 'custom', {
        url:
          'orgs/' +
          org_id +
          '/projects/' +
          project_id +
          '/start_sunlight_application/' +
          system_uuid +
          '/' +
          payment_option_id +
          '/sunlight_loan_application/',
      })
        .then(
          (response) => {
            if (response.data && response.data.data && response.data.data.application_url) {
              showDialog('SunlightLoanApplication', { url: response.data.data.application_url }, isFullWidth)
              const requestEnd = new Date()
              let secondsToOpen = 'error'
              try {
                secondsToOpen = `${((requestEnd.getTime() - requestStart.getTime()) / 1000).toFixed(0)}`
              } catch (ex) {}
              logAmplitudeEvent('integrated_finance_application_opened', {
                integration: 'sunlight',
                project_id: project_id,
                is_prequal: 'no',
                seconds_to_open: secondsToOpen,
              })
            }
          },
          (reject) => {
            console.log(reject)
            notify(translate(reject.message), 'error')
          }
        )
        .finally(() => dispatch(hideCheckoutDialog()))
    },
    []
  )
}

const useSunlightApplicationCallback = (): CheckoutActionCallback => {
  const notify = useNotify()
  const translate = useTranslate()
  const handRequestLoanDocs = useRequestLoanDocs()
  const refreshApplication = useRefreshApplication()
  const startApplication = useStartApplication()

  return useCallback(async ({ elementData, proposalData, additionalActionData }) => {
    if (elementData.type !== 'LoanApplication') {
      Sentry.captureException(new Error('Invalid element data for LoanApplication callback'))
      return
    }
    if (elementData.actionData.payment_method !== 'sunlight_loan_application') {
      Sentry.captureException(new Error('Invalid element data for sunlight_loan_application callback'))
      return
    }
    const { actionData } = elementData

    if (actionData.status_code !== 'available') {
      return
    }

    if (additionalActionData?.action === 'requestLoanDocs') {
      await handRequestLoanDocs({ org_id: actionData.org_id, project_id: actionData.project_id })
      return
    }

    let hasValidContact = false
    if (proposalData.selectedProject?.contacts?.length > 0) {
      let firstContact = proposalData.selectedProject.contacts[0]
      if (firstContact?.first_name && firstContact?.family_name && firstContact?.phone && firstContact?.email) {
        hasValidContact = true
      }
    }
    if (!hasValidContact) {
      notify(
        translate('Project must have a contact with a first name, last name, and valid phone and email address'),
        'error'
      )
      return
    }
    logAppOpenedEvent(actionData, proposalData.selectedPaymentOption.title)
    if (actionData.application_url) {
      await refreshApplication({
        org_id: actionData.org_id,
        project_id: actionData.project_id,
        system_uuid: actionData.system_uuid,
        payment_option_id: actionData.payment_option_id,
        isFullWidth: !!additionalActionData?.showFullWidthDialog,
      })
    } else {
      await startApplication({
        org_id: actionData.org_id,
        project_id: actionData.project_id,
        system_uuid: actionData.system_uuid,
        payment_option_id: actionData.payment_option_id,
        isFullWidth: !!additionalActionData?.showFullWidthDialog,
      })
    }
  }, [])
}

export default useSunlightApplicationCallback
