import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles } from '@material-ui/core'
import { Check, ChevronRight, VerifiedUser } from '@material-ui/icons'
import { authSelectors } from 'ducks/auth'
import ResponsiveVimeoPlayer from 'elements/ResponsiveVimeoPlayer'
import LoadingDots from 'layout/widgets/LoadingDots'
import { Button } from 'opensolar-ui'
import { useNotify, useRedirect, useTranslate } from 'ra-core'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import useTranslateParse from 'util/useTranslateParse'
import PaymentChannels from './components/PaymentChannels'
import { useQuickSubmit } from './utils'

const useStyles = makeOpenSolarStyles((theme) => ({
  row: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    margin: 10,
    paddingBottom: '1rem',
    borderBottom: '1px #DADBE9 solid',
  },
  title: {
    marginRight: '10px',
  },

  mainContainer: {
    margin: ' 0 12px',
  },

  headerContainer: {
    textAlign: 'center',
  },

  header: {
    color: '#2C499D',
    fontSize: 15,
    fontWeight: 500,
  },

  container: {
    background: '#EDF3FF',
    borderRadius: '8px',
    padding: '1rem',
    minHeight: '70%',
  },

  shieldIcon: {
    height: '1.25rem',
    color: '#019939',
    padding: '10px',
    background: '#DAE5FF',
    borderRadius: 7,
  },

  listContainer: {
    display: 'flex',
    gap: 15,
    margin: 18,
    lineHeight: 1.5,
  },

  checkIcon: {
    fontSize: 18,
    color: '#2C499D',
    marginTop: '2.5px',
  },
  channelsWrapper: {
    margin: '1rem',
  },
  dialog: {
    width: '100%',
    height: '100%',
    maxWidth: 840,
    maxHeight: 472,
  },
  buttonRow: {
    display: 'flex',
    justifyContent: 'end',
    width: '100%',
    alignItems: 'center',
    padding: '0px 10px',
  },
  buttonWrapper: {
    padding: '10px 0 15px 10px',
  },
  submitButton: {
    width: '100%',
    fontSize: 13,
  },
}))

const useDialogStyles = makeStyles((theme) => ({
  root: {
    '&.MuiDialog-root': {
      zIndex: '10 !important',
    },
  },
}))

type PropTypes = {
  setShowDialog: (boolean) => void
}

const MarketingPage: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  const notify = useNotify()
  const redirect = useRedirect()
  const dialogClasses = useDialogStyles()
  const quickSubmit = useQuickSubmit()
  const isStaff = useSelector(authSelectors.getIsStaff)
  const isSuperUser = useSelector(authSelectors.getIsSuperUser)

  const translate = useTranslate()
  const translateParse = useTranslateParse()

  const [isOpen, setIsOpen] = useState<boolean>(true)
  const [isloading, setIsloading] = useState<boolean>(false)

  const onNext = () => {
    setIsOpen(false)
    props.setShowDialog(true)
  }

  const onQuickSubmit = async () => {
    setIsloading(true)
    const submitted = await quickSubmit()
    setIsloading(false)

    if (!submitted) {
      notify('Error while sumitting quick onboarding', 'error')
      return
    }

    notify('Org successfully onboarded. Please sync PSP Webhooks from dev!', 'warning')
    redirect('/configuration')
  }

  return (
    <Dialog open={isOpen} maxWidth="lg" classes={dialogClasses}>
      <DialogTitle>
        <div className={classes.row}>
          <span className={classes.title}>
            {translate('Welcome to CashFlow, the only payments system built for solar professionals')}
          </span>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className={classes.mainContainer}>
          <Grid container spacing={1}>
            <Grid item xs={8}>
              <div className={classes.dialog}>
                <ResponsiveVimeoPlayer
                  videoUrl="https://player.vimeo.com/video/986846106?h=a7b7fca7f0"
                  trackingSource={'cashflow_onboarding'}
                />
              </div>
            </Grid>
            <Grid item xs={4}>
              <div className={classes.container}>
                <div className={classes.headerContainer}>
                  <VerifiedUser className={classes.shieldIcon} />
                  <h2 className={classes.header}>{translate('Effortless cash collection and accounting')}</h2>
                </div>
                <div className={classes.listContainer}>
                  <Check className={classes.checkIcon} />
                  <span>
                    {translateParse('<strong> Save money <strong> on credit card fees', {
                      strong: (label: string) => <strong>{label}</strong>,
                    })}
                  </span>
                </div>
                <div className={classes.listContainer}>
                  <Check className={classes.checkIcon} />
                  <span>
                    {translateParse('<strong> Save time <strong> and hassle processing all payments', {
                      strong: (label: string) => <strong>{label}</strong>,
                    })}
                  </span>
                </div>
                <div className={classes.listContainer}>
                  <Check className={classes.checkIcon} />
                  <span>
                    {translateParse('Present a <strong> secure and easy-to-use <strong> payment interface', {
                      strong: (label: string) => <strong>{label}</strong>,
                    })}
                  </span>
                </div>

                <div className={classes.channelsWrapper}>
                  <PaymentChannels />
                </div>
              </div>
            </Grid>
          </Grid>
        </div>
      </DialogContent>

      {isloading ? (
        <LoadingDots text="Please wait while we’re quickly submitting the Org onboarding..." />
      ) : (
        <DialogActions>
          <div className={classes.buttonRow}>
            <div className={classes.buttonWrapper}>
              <Button onClick={onNext} variant={'contained'} color={'primary'} className={classes.submitButton}>
                {translate("Let's Go")} <ChevronRight />
              </Button>
            </div>
            {!['production', 'staging4'].includes(window.ENV) && (isStaff || isSuperUser) && (
              <div className={classes.buttonWrapper}>
                <Button
                  onClick={onQuickSubmit}
                  variant={'contained'}
                  color={'primary'}
                  className={classes.submitButton}
                >
                  {'Onboarding Quick Submit'} <ChevronRight />
                </Button>
              </div>
            )}
          </div>
        </DialogActions>
      )}
    </Dialog>
  )
}
export default MarketingPage
