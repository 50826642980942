import {
  AccountManagerContext as ProjectHardwareAccountManagerContext,
  ContactInfoContext as ProjectHardwareContactInfoContext,
  DeliveryAddressInfoContext as ProjectHardwareDeliveryAddressInfoContext,
  DistributorDataContext as ProjectHardwareDistributorDataContext,
  PreOrderPresenterContext as ProjectHardwarePreOrderPresenterContext,
  SystemContext as ProjectHardwareSystemContext,
  TotalItemCountContext as ProjectHardwareTotalItemCountContext,
} from 'projectSections/sections/hardware/preOrder/PreOrderProvider'

import {
  AccountManagerContext as ResendOrderAccountManagerContext,
  ContactInfoContext as ResendOrderContactInfoContext,
  DeliveryAddressInfoContext as ResendOrderDeliveryAddressInfoContext,
  DistributorDataContext as ResendOrderDistributorDataContext,
  PreOrderPresenterContext as ResendOrderPreOrderPresenterContext,
  TotalItemCountContext as ResendOrderTotalItemCountContext,
} from '../resendOrder/ResendOrderProvider'

import { OrderContext } from 'projectSections/sections/hardware'
import {
  AccountManagerContext,
  ContactInfoContext,
  DeliveryAddressInfoContext,
  DistributorDataContext,
  PreOrderPresenterContext,
  TotalItemCountContext,
} from './PreOrderProvider'

export const SOURCE_CONTEXT_MAPPING = {
  project_hardware: {
    presenterContext: ProjectHardwarePreOrderPresenterContext,
    distributorDataContext: ProjectHardwareDistributorDataContext,
    contactInfoContext: ProjectHardwareContactInfoContext,
    deliveryAddressInfoContext: ProjectHardwareDeliveryAddressInfoContext,
    accountManagerContext: ProjectHardwareAccountManagerContext,
    totalItemCountContext: ProjectHardwareTotalItemCountContext,
    systemContext: ProjectHardwareSystemContext,
    orderContext: undefined,
  },
  resend_order: {
    presenterContext: ResendOrderPreOrderPresenterContext,
    distributorDataContext: ResendOrderDistributorDataContext,
    contactInfoContext: ResendOrderContactInfoContext,
    deliveryAddressInfoContext: ResendOrderDeliveryAddressInfoContext,
    accountManagerContext: ResendOrderAccountManagerContext,
    totalItemCountContext: ResendOrderTotalItemCountContext,
    orderContext: OrderContext,
  },
  global: {
    presenterContext: PreOrderPresenterContext,
    distributorDataContext: DistributorDataContext,
    contactInfoContext: ContactInfoContext,
    deliveryAddressInfoContext: DeliveryAddressInfoContext,
    accountManagerContext: AccountManagerContext,
    totalItemCountContext: TotalItemCountContext,
    orderContext: undefined,
  },
}

export const DISTRBUTOR_KEY_TO_NAME_MAPPING = {
  city_plumbing: 'City Plumbing',
  segen: 'Segen',
  hdm: 'HDM',
  vtac_uk: 'V-TAC',
  vtac_pl: 'V-TAC',
  trust_solar: 'Trust Solar',
  outlet: 'Solar Outlet',
}

export const DISTRIBUTOR_DEFAULT_VALUES = {
  city_plumbing: {
    shippingInfo: {
      deliveryDate: '',
      deliveryInstructions: '',
      deliveryMethod: 'delivery',
      deliveryTime: '',
      fee: 'TBC',
      deliveryBranch: {
        name: '',
        manager: '',
        email: '',
        contactNumber: '',
        address: '',
        weekdayHours: '',
        saturdayHours: '',
        sundayHours: '',
      },
      paymentMethod: '',
    },
    isLoading: false,
  },
  vtac_uk: {
    shippingInfo: {
      distributor: '',
      deliveryInstructions: '',
      fee: 'TBC',
    },
    dealerId: '',
    isLoading: false,
  },
  vtac_pl: {
    shippingInfo: {
      distributor: '',
      deliveryInstructions: '',
      fee: 'TBC',
    },
    dealerId: '',
    isLoading: false,
  },
  hdm: {
    shippingInfo: {
      deliveryInstructions: '',
      fee: 'TBC',
    },
    isLoading: false,
  },
  segen: {
    shippingInfo: {
      dispatchDate: '',
      deliveryOption: '',
      deliveryMethod: 'delivery',
      fee: 'TBC',
    },
    quoteId: undefined,
    authorization: '',
    hasLiftingEquipment: true,
    isLoading: false,
  },
  outlet: {
    shippingInfo: {
      deliveryMethod: '',
      warehouse: '',
      fee: 'TBC',
    },
    paymentMethod: 'solar_juice_credit',
    usingCustomPricing: false,
    isLoading: false,
  },
}

export const DISTRIBUTOR_ACCOUNT_MANAGER = {
  city_plumbing: {
    name: 'John Smith',
    contactNumber: '+44 (0)800 6888 388',
    email: 'solarpv@cityplumbing.co.uk',
  },
  segen: {},
  vtac_uk: {},
  vtac_pl: {},
  hdm: {},
  outlet: {},
}
