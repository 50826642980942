import { Tooltip } from '@material-ui/core'
import { LockOutlined } from '@material-ui/icons'
import { styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React, { useMemo } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { WorkflowType } from 'types/workflows'
import { urlToId } from 'util/misc'
import SelectChipField from '../common/SelectChipField'
import { handleImplicitToggles, isPricingLockedForStage } from './util'

export interface WorkflowUpdate {
  workflow_id: number
  active_stage_id: number
  org_id: number
}

const LockPricing = styled('div', { name: 'LockPricing' })(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginLeft: 'auto',
  '& p': {
    margin: 0,
    color: theme.palette.grey[810],
  },
}))

const LockIcon = styled(LockOutlined, { name: 'LockIcon' })(({ theme }) => ({
  color: theme.palette.red[800],
  height: 15,
}))
interface PropTypes {
  selectedWorkflowId?: number
  workflows: WorkflowType[]
  handleChange: (workflow: WorkflowUpdate) => void
  disabled?: boolean
}

const FlexRow = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: '10px',
  flex: 1,
})

const WorkflowSelect: React.FC<PropTypes> = ({ workflows, selectedWorkflowId, handleChange, disabled }) => {
  const form = useForm()
  const translate = useTranslate()
  const project = useFormState().values
  const priceLock = useMemo(() => isPricingLockedForStage(project, workflows), [workflows, project.workflow])

  const onWorkflowChange = (e) => {
    const newId = e.target.value

    //check if new workflow has already been assigned to the project
    const projectWorkflow = project?.workflows?.find((x) => x.workflow_id === newId)
    const workflow_stages = workflows.find((x) => x.id === newId)?.workflow_stages

    if (projectWorkflow) {
      handleImplicitToggles(
        {
          workflow_stages: workflow_stages,
          active_stage_id: projectWorkflow.active_stage_id,
          org_id: projectWorkflow.org_id,
        },
        project,
        form
      )

      handleChange({
        org_id: projectWorkflow.org_id,
        workflow_id: projectWorkflow.workflow_id,
        active_stage_id: projectWorkflow.active_stage_id,
      })
    } else {
      const findWorkflow = workflows.find((x) => x.id === newId)

      if (findWorkflow === undefined) {
        return
      }

      const org_id = urlToId(findWorkflow.org)
      const active_stage_id = !!findWorkflow.workflow_stages.length ? findWorkflow.workflow_stages[0].id : undefined

      if (org_id === undefined || findWorkflow.id === undefined || active_stage_id === undefined) {
        return
      }

      handleImplicitToggles(
        {
          ...findWorkflow,
          active_stage_id: active_stage_id,
        },
        project,
        form
      )
      handleChange({
        workflow_id: findWorkflow.id,
        active_stage_id: Number(active_stage_id),
        org_id,
      })
    }
  }

  return (
    <FlexRow>
      <SelectChipField
        choices={workflows.filter((workflow) => !workflow.is_archived)}
        chipLabel=""
        optionValue={'id'}
        optionText={'title'}
        value={selectedWorkflowId}
        disabled={disabled}
        onChange={onWorkflowChange}
        renderEmpty={translate('Select workflow')}
      />
      {priceLock && (
        <LockPricing>
          <Tooltip
            title={translate(
              'Based on the Stage of the project, the pricing, incentives and payment options are locked on this project.'
            )}
            PopperProps={{ style: { zIndex: 2001 } }}
          >
            <LockIcon />
          </Tooltip>
          <p>{translate('Pricing is locked')}</p>
        </LockPricing>
      )}
    </FlexRow>
  )
}

export default WorkflowSelect
