import { logAmplitudeEvent } from 'amplitude/amplitude'
import type { CheckoutElementDataType } from 'opensolar-checkout'
import { CheckoutActionElementOptions, FinanceCtaType, LoanApplicationType } from 'opensolar-checkout'
import { useNotify, useTranslate } from 'ra-core'
import { useEffect, useMemo, useState } from 'react'
import restClient from 'restClient'
import { ProposalDataType } from 'types/proposals'

var restClientInstance = restClient(window.API_ROOT + '/api')

const useGenericFinanceCheckoutElementOptions = ({
  elementsData,
  proposalData,
}: {
  elementsData: CheckoutElementDataType[]
  proposalData: ProposalDataType
}): {
  ctaDialogConfigJson: CheckoutActionElementOptions['ctaDialogConfigJson']
  transactionActionButtonDisabled: boolean
} => {
  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const [ctaDialogConfigJson, setCtaDialogConfigJson] = useState<any>(undefined)
  const translate = useTranslate()
  const notify = useNotify()
  const genericFinance = useMemo(() => {
    return elementsData.find((data) => data.type === 'LoanApplication' && data.actionData.finance_integration) as
      | LoanApplicationType
      | undefined
  }, [elementsData])

  // if the user just returned from docusign but the sold_pmt_option param that we add for OpenSolar contracts isn't present
  // then we can safely say the user just signed a lender contract in DocuSign
  // so forcibly disable the button since we probably haven't heard from the lender that the doc is completed yet
  useEffect(() => {
    if (window.location.href.includes('signing_complete') && !window.location.href.includes('sold_pmt_option')) {
      setIsDisabled(true)
    }
    if (genericFinance?.actionData.cta_type === FinanceCtaType.DISCLAIMER_BEFORE_REDIRECT) {
      populateCtaDialogConfigJson() // repopulate on generic finance id changed
    }
  }, [genericFinance?.actionData.payment_option_id])

  const populateCtaDialogConfigJson = () => {
    if (genericFinance === undefined) return
    const orgId = proposalData.selectedPaymentOption?.org_id
      ? proposalData.selectedPaymentOption.org_id
      : genericFinance.actionData.org_id

    restClientInstance('CUSTOM_GET', 'custom', {
      url: `orgs/${orgId}/projects/${genericFinance.actionData.project_id}/${genericFinance.actionData.payment_option_id}/application_cta/`,
    })
      .then(
        (response) => {
          setCtaDialogConfigJson(response.data.cta_dialog_config_json)
        },
        (reject: any) => {
          console.log(reject)
          notify(translate(reject.message), 'warning')
        }
      )
      .catch((err) => {
        if (err?.body?.message) {
          notify(translate(err.body.message), 'warning')
          logAmplitudeEvent('integrated_finance_application_open_error', {
            integration: genericFinance.actionData?.finance_integration,
          })
        }
      })
  }

  return useMemo(
    () => ({
      ctaDialogConfigJson,
      transactionActionButtonDisabled: isDisabled,
    }),
    [ctaDialogConfigJson, isDisabled]
  )
}

export default useGenericFinanceCheckoutElementOptions
