import { myEnergySelectors } from 'ducks/myEnergy'
import ClassicProposalActionBar from 'pages/home/proposalActionBar/ClassicProposalActionBar'
import ProposalV2ActionBar from 'pages/home/proposalActionBar/ProposalV2ActionBar'
import { useSelector } from 'react-redux'
import { detectProposalV2ProposalTemplate } from 'util/misc'

export const PROPOSAL_ACTION_BAR_HEIGHT = 48

const ProposalActionBar = () => {
  const proposalData = useSelector(myEnergySelectors.getProposalData)
  const { isDetected: isProposalV2 } = detectProposalV2ProposalTemplate(proposalData?.proposalTemplateSettings)

  return isProposalV2 ? (
    <ProposalV2ActionBar proposalTitle={proposalData?.proposalTemplateSettings.title || 'Proposal'} />
  ) : (
    <ClassicProposalActionBar />
  )
}

export default ProposalActionBar
