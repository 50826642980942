import { useState } from 'react'
import { ProjectType } from 'types/projects'
import PhoenixApplicationDialog from './PhoenixApplicationDialog'

export type PhoenixApplicationDialogConfig = {
  orgId: number
  system_uuid: string
  paymentOptionId: number
  project: ProjectType
  existingURL?: string
  pricePayable: number
}

const usePhoenixApplicationDialog = () => {
  const [state, seState] = useState<
    { open: false; config: undefined } | { open: true; config: PhoenixApplicationDialogConfig }
  >({
    open: false,
    config: undefined,
  })

  const openDialog = (config: PhoenixApplicationDialogConfig) => {
    seState({
      open: true,
      config,
    })
  }

  const closeDialog = () => {
    if (state.open === false) {
      return
    }
    seState({
      open: false,
      config: undefined,
    })
  }

  const dialogNode = state.open ? (
    <PhoenixApplicationDialog
      isOpen={true}
      onClose={closeDialog}
      project={state.config.project}
      systemUUID={state.config.system_uuid}
      paymentOptionId={state.config.paymentOptionId}
      existingURL={state.config.existingURL}
      pricePayable={state.config.pricePayable}
      orgId={state.config.orgId}
    />
  ) : null
  return { isOpen: state.open, dialogNode, openDialog, closeDialog, type: 'PhoenixApplicationDialog' as const }
}

export default usePhoenixApplicationDialog
