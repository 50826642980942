import { useMediaQuery } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { Struct } from 'contexts/structs/types/types'
import { adsSelectors } from 'ducks/ads'
import useAdStructs, { BannerConfig } from 'elements/ads/useAdStructs'
import React, { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { useSelector } from 'react-redux'
import { useFeatureFlag } from 'util/split'
/*
  display: 'block' is required to remove extra padding around iframes
  */
const ALL_BANNERS = {
  overflow: 'hidden',
}
const BANNER_STYLES = {
  triple_widescreen: { ...ALL_BANNERS, width: 278, height: 400 },
  leaderboard: { ...ALL_BANNERS, width: 728, height: 90 },
  mobileLeaderboard: { ...ALL_BANNERS, width: 325, height: 50 },
}

const getRandomString = () => Math.floor(Math.random() * 10000000)

const AdIframe = ({ bannerConfig, placement, rounded }) => {
  const elementIdentifier = 'ad_iframe_' + getRandomString()

  useEffect(() => {
    function iframeListener(message: MessageEvent) {
      // Messages bet sent to all ads on the page. Ensure only the corresponding iframe responds.
      // @TODO: Is accessing window.name safe for all CORS scenarios?
      if (message.data?.elementIdentifier === elementIdentifier) {
        if (message.data?.type === 'AD_CLICK') {
          handleBannerClick(bannerConfig, placement)
        }
      } else {
        // console.log(
        //   `Ignore event triggered by different iframe message.data?.elementIdentifier:${message.data?.elementIdentifier} !== elementIdentifier:${elementIdentifier}`
        // )
      }
    }

    if (bannerConfig) {
      window.addEventListener('message', iframeListener)
      return () => window.removeEventListener('message', iframeListener)
    } else {
      // no bannerConfig found, do not show any ad
    }
  }, [bannerConfig])

  const { ref } = useInView({
    triggerOnce: true,
    threshold: 0.8,
    onChange: (inView) => {
      if (inView && bannerConfig) {
        handleAdImpression(bannerConfig, placement)
      }
    },
  })

  return (
    <div style={{ width: '100%', textAlign: 'center' }} ref={ref}>
      <iframe
        id="iframeBannerAd"
        name={elementIdentifier}
        src={bannerConfig.iframeUrl}
        width={BANNER_STYLES[bannerConfig.format]?.width ? BANNER_STYLES[bannerConfig.format].width + 'px' : undefined}
        style={{ ...BANNER_STYLES[bannerConfig.format], border: 0, borderRadius: rounded ? 8 : undefined }}
        frameBorder={0}
        scrolling="no"
      ></iframe>
    </div>
  )
}

const AdTextLink = ({ bannerConfig, placement }) => {
  /*
  Currently this is limited to a single line of text, any overflow will get converted to ellipsis
  */
  const { ref } = useInView({
    triggerOnce: true,
    threshold: 0.8,
    onChange: (inView) => {
      if (inView && bannerConfig) {
        handleAdImpression(bannerConfig, placement)
      }
    },
  })

  return (
    <div
      style={{
        textAlign: 'left',
        margin: '10px 10px 10px 0px',
      }}
      ref={ref}
    >
      <a
        href="#"
        onClick={(event) => {
          event.preventDefault()
          handleBannerClick(bannerConfig, placement)
          return false
        }}
        style={{
          fontSize: 14,
          fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
          textOverflow: 'ellipsis',
          color: '#6F737E',
          display: 'block',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        {bannerConfig.textLinkLabel}
      </a>
    </div>
  )
}

/*
Options: 'GA4', 'AMPLITUDE'
*/
const trackingPlatforms = ['AMPLITUDE']

const handleBannerClick = (bannerConfig, placement) => {
  let clickUrl = bannerConfig?.clickUrl || ''

  if (!bannerConfig || !clickUrl) {
    console.warn('Invalid clickUrl')
    return
  }

  if (clickUrl.startsWith('http')) {
    window.open(clickUrl, '_blank')
  } else if (clickUrl) {
    window.location.href = clickUrl
  }

  var eventParameters = {
    campaign_name: bannerConfig.campaign_name,
    creative_name: bannerConfig.creative_name,
    format: bannerConfig.format,
    placement: placement,
  }

  if (trackingPlatforms.includes('GA4')) {
    window.GA4?.recordEvent('ad_click', eventParameters)
  }
  if (trackingPlatforms.includes('AMPLITUDE')) {
    logAmplitudeEvent('ad_click', eventParameters)
  }
}

const handleAdImpression = (bannerConfig, placement) => {
  const eventParameters = {
    campaign_name: bannerConfig.campaign_name,
    creative_name: bannerConfig.creative_name,
    format: bannerConfig.format,
    placement: placement,
  }

  if (trackingPlatforms.includes('GA4')) {
    window.GA4?.recordEvent('ad_impression', eventParameters)
  }
  if (trackingPlatforms.includes('AMPLITUDE')) {
    logAmplitudeEvent('ad_impression', eventParameters)
  }
}

export const filterAvailableAds = (structs: Struct<BannerConfig>[], adFilters: BannerFilters) => {
  if (window.debugBannerAds && structs.length) console.log('Filtering banner ads: ', structs, adFilters)
  const ret = structs
    .filter((struct) => {
      const promoConfig = struct.data
      // check ad formats
      const ret = !!(
        adFilters.formats?.length > 0 &&
        (adFilters.formats?.includes(promoConfig?.format) || adFilters.formats?.includes('**'))
      )
      if (!ret && window.debugBannerAds)
        console.log('\tBanner format mismatch:', struct.key, promoConfig?.format, adFilters.formats)
      return ret
    })
    .filter((struct) => {
      const promoConfig = struct.data
      // check matching placements
      const ret = promoConfig.placements?.includes('**') || promoConfig.placements?.includes(adFilters.placement)

      if (!ret && window.debugBannerAds)
        console.log('\tBanner placement mismatch:', struct.key, promoConfig.placements, adFilters.placement)
      return ret
    })
  if (window.debugBannerAds && structs.length) console.log('Filtered banner ads: ', ret)
  return ret
}

export type BannerFilters = {
  formats: string[]
  placement: string
}

export const weightedRandom = (structs: Struct<BannerConfig>[]) => {
  if (!structs.length) return undefined
  let totalWeight = structs.reduce((acc, struct) => acc + (struct.data?.weighting || 0), 0)
  let random = Math.random() * totalWeight
  return structs.find((struct) => {
    random -= struct.data?.weighting || 0
    return random <= 0
  })
}

export function weightedRandomMultiple(structs: Struct<BannerConfig>[], howMany: number) {
  let results: Struct<BannerConfig>[] = []
  let promoConfigsRemaining = structs.slice()
  for (let i = 0; i < howMany; i++) {
    let result = weightedRandom(promoConfigsRemaining)
    if (result) {
      results.push(result)
    }
    promoConfigsRemaining = promoConfigsRemaining.filter((promoConfig) => promoConfig !== result)
  }
  return results
}

type BannerAdPropTypes = {
  formats: string[] // Todo use union type
  placement: string
  bannerConfigOverride?: any
  containerStyle?: any
  requiredWindowSize?: { width?: number; height?: number }
  rounded?: boolean
  className?: string
}

const BannerAd: React.FunctionComponent<BannerAdPropTypes> = (props) => {
  // Banner size stats: https://www.match2one.com/blog/standard-banner-sizes/
  // 300×250 Medium Rectangle 40%
  // 728×90 Leaderboard 25%
  // 320×50 Mobile Leaderboard 12%
  // 160×600 Wide Skyscraper; 12%
  const enableAdsFeatureFlag = useFeatureFlag('ads', 'on')
  const enableAdsForOrg = useSelector(adsSelectors.getEnableAdsForOrg)
  const enableAds = enableAdsFeatureFlag && enableAdsForOrg
  const promoConfigs = useAdStructs()
  const [bannerConfig, setBannerConfig] = useState<BannerConfig | null>(props.bannerConfigOverride || null)

  const adjustedHeight = props.requiredWindowSize?.height || 0
  const heightCheck = useMediaQuery('(min-height:' + adjustedHeight + 'px)')
  const adjustedWidth = props.requiredWindowSize?.width || 0
  const widthCheck = useMediaQuery('(min-width:' + adjustedWidth + 'px)')
  const heightIsSufficient = !props.requiredWindowSize?.height || heightCheck
  const widthIsSufficient = !props.requiredWindowSize?.width || widthCheck

  const adFilters: BannerFilters = { formats: props.formats, placement: props.placement }

  useEffect(() => {
    if (!bannerConfig) {
      const availableAds = filterAvailableAds(promoConfigs, adFilters)
      const adConfig = weightedRandom(availableAds)
      if (adConfig?.data) {
        setBannerConfig(adConfig.data)
      }
    }
  }) // This intentionally has no dependency array, it gets run on every render
  //TODO: rework this to use a concise dependency array

  if (!enableAds) {
    return null
  } else if (!widthIsSufficient || !heightIsSufficient) {
    return null
  }

  if (bannerConfig?.iframeUrl) {
    return (
      <div className={props.className} style={props.containerStyle || {}}>
        <AdIframe bannerConfig={bannerConfig} placement={props.placement} rounded={props.rounded} />
      </div>
    )
  } else if (bannerConfig?.textLinkLabel) {
    return (
      <div className={props.className} style={props.containerStyle || {}}>
        <AdTextLink bannerConfig={bannerConfig} placement={props.placement} />
      </div>
    )
  } else {
    //console.warn('no ad shown', bannerConfig)
    return null
  }
}

export default BannerAd

declare global {
  interface Window {
    debugBannerAds: boolean
  }
}
