import { useTranslateOrTranslatableText } from 'hooks/translation/useTranslateOrTranslatableText'
import moment from 'moment'
import { Alert, styled, Typography } from 'opensolar-ui'
import { useEffect, useMemo, useState } from 'react'
import { PromoDialogCountdown } from '../usePromoDialogStructs'

const recheckTimeInterval = 30 * 1000

const Container = styled('div')({
  padding: '8px 24px',
  display: 'flex',
  justifyContent: 'center',
})

export const PromoCountdownElement = ({ element }: { element: PromoDialogCountdown }) => {
  const translate = useTranslateOrTranslatableText()

  const [mins, setMins] = useState(0)
  useEffect(() => {
    const checkTime = () => {
      var now = moment()
      var countdown_time =
        typeof element.countdown_time === 'number'
          ? moment(element.countdown_time * 1000)
          : moment(element.countdown_time)
      var diff = countdown_time.diff(now, 'minutes', true)
      switch (element.rounding) {
        case 'up':
          diff = Math.ceil(diff)
          break
        case 'down':
          diff = Math.floor(diff)
          break
        default:
          diff = Math.round(diff)
          break
      }
      setMins(diff)
    }

    const interval = setInterval(checkTime, recheckTimeInterval)
    checkTime()
    return () => clearInterval(interval)
  }, [element.countdown_time])

  const subs = useMemo(() => {
    return {
      timeRemaining: mins ? translate('%{smart_count} minutes', { smart_count: mins }) : '',
      minsRemaining: mins,
    }
  }, [mins])

  const message = useMemo(() => {
    if (mins <= 0) {
      return translate(element.countdown_overtime_msg, subs)
    } else {
      return translate(element.countdown_msg, subs)
    }
  }, [translate, mins, element.countdown_msg, element.countdown_overtime_msg])

  const dontShow = !element.countdown_overtime_msg && mins <= 0
  if (dontShow) return <></>

  return (
    <Container>
      {element.alert_severity === 'basic' ? (
        <Typography textVariant="body1">{message}</Typography>
      ) : (
        <Alert severity={element.alert_severity}>{message}</Alert>
      )}
    </Container>
  )
}
