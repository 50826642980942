import * as Sentry from '@sentry/react'
import { showCheckoutDialog } from 'ducks/myEnergy'
import { CheckoutActionCallback } from 'opensolar-checkout'
import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

const useShowCashflowDepositDialogCallback = (): CheckoutActionCallback => {
  const dispatch = useDispatch()
  return useCallback(async ({ elementData, proposalData, additionalActionData }) => {
    if (elementData.type !== 'CashFlowDeposit') {
      Sentry.captureException(new Error('Invalid element data for cash flow deposit callback'))
      return
    }
    dispatch(
      showCheckoutDialog({
        type: 'CashFlowDeposit',
        config: {
          systemUuid: elementData.actionData.system_uuid,
          projectId: elementData.actionData.project_id,
          paymentOptionId: elementData.actionData.payment_option_id,
          orgId: elementData.actionData.org_id,
        },
      })
    )
  }, [])
}

export default useShowCashflowDepositDialogCallback
