import { Link, makeStyles, Popover, Theme, Tooltip, useMediaQuery } from '@material-ui/core'
import { triggerMilestoneRefresh } from 'ducks/projectMilestones'
import { Button } from 'opensolar-ui'
import { PaymentRequestType } from 'pages/cashFlow/types'
import { pauseEmailReminders } from 'pages/cashFlow/utils'
import { useNotify, useRefresh, useTranslate } from 'ra-core'
import React, { useMemo, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { OpenSolarThemeType } from 'Themes'
import { ProjectId } from 'types/projects'
import CancelInvoiceButton from './CancelInvoiceBtn'
import EditPaymentOptionsButton from './EditPaymentOptionsButton'
import MarkPaidOfflineButton from './MarkPaidOffine'
import PreviewInvoiceLink from './PreviewInvoiceLink'
import SendInvoiceButton from './SendInvoiceBtn'
import UndoMarkPaidOfflineButton from './UndoMarkPaidOffline'

type PropTypes = {
  paymentRequest: PaymentRequestType
  allPaymentRequests: PaymentRequestType[] // If empty array is received, send invoice feature is disabled
  systemUuid: string
  index?: number // if undefined is received, send invoice feature is disabled
  projectId?: ProjectId
}

const useStyles = makeStyles<OpenSolarThemeType>((theme) => ({
  actionsMenu: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: '150px',
  },
  actionRow: {
    cursor: 'pointer',
    padding: '10px',
  },
}))

const InvoiceActions: React.FC<PropTypes> = (props) => {
  const buttonRef = useRef<HTMLDivElement>(null)
  const translate = useTranslate()
  const classes = useStyles()
  const notify = useNotify()
  const dispatch = useDispatch()
  const refresh = useRefresh()

  const [actionsAreShown, setActionsAreShown] = useState<boolean>(false)
  const [disableButtons, setDisableButtons] = useState<boolean>(false)

  const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  const refreshMilestones = () => {
    dispatch(triggerMilestoneRefresh())
  }

  const isNext = useMemo(() => {
    // if it's already sent then just return false
    if (props.paymentRequest?.id && props.paymentRequest?.status !== 'cancelled') return false
    // if it's not sent but it's the first payment then it has to be next
    if (props.paymentRequest.payment_number === 1 && props.paymentRequest.status === 'unsent') return true

    // otherwise go see if there is a non-cancelled request for any of the previous milestones
    const previousUnsentRequest = props.allPaymentRequests?.find(
      (prevPaymentRequest) =>
        !prevPaymentRequest.is_standalone &&
        prevPaymentRequest.payment_number < props.paymentRequest.payment_number &&
        !prevPaymentRequest.id
    )
    // if any previous milestone has not yet been sent then this can't be the next payment so return false
    return previousUnsentRequest ? false : true
  }, [props.paymentRequest, props.allPaymentRequests])

  const isMostRecentRowForThisMilestone = useMemo(() => {
    // if it is next, that doesn't necessarily mean this particular row can be sent
    // because we may have multiple payment requests for the same milestone (for example, it was sent, cancelled then re-sent)

    if (props.index === undefined) return false // If index is not provided, disable this feature

    let isMostRecentForThisPaymentNumber = false
    let nextPaymentRequest = props.allPaymentRequests?.[props.index + 1]

    if (
      !nextPaymentRequest ||
      (nextPaymentRequest && nextPaymentRequest?.payment_number > props.paymentRequest.payment_number)
    )
      isMostRecentForThisPaymentNumber = true

    return isMostRecentForThisPaymentNumber
  }, [props.paymentRequest, props.allPaymentRequests, props.index])

  const showCancel = useMemo(() => {
    if (props.paymentRequest?.status === 'payment failed') return true
    return (
      props.paymentRequest?.id &&
      props.paymentRequest.status !== 'cancelled' &&
      props.paymentRequest.status !== 'pending' &&
      !props.paymentRequest.date_completed
    )
  }, [props.paymentRequest])

  const showMarkPaidOffline = useMemo(() => {
    // Similar logic for "Mark Paid Offline" as "Cancel", except that the PaymentRequest doesn't need to even exist in the DB yet.
    if (props.paymentRequest?.status === 'payment failed') return true
    return (
      props.paymentRequest.status !== 'cancelled' &&
      props.paymentRequest.status !== 'pending' &&
      !props.paymentRequest.date_completed
    )
  }, [props.paymentRequest])

  const showUndoMarkPaidOffline = useMemo(() => {
    return !!(!props.paymentRequest.payment_method && props.paymentRequest.date_completed)
  }, [props.paymentRequest])

  const showSendReminder = useMemo(() => {
    const { status, id, date_due, date_completed } = props.paymentRequest || {}

    if (status === 'payment failed') return true
    if (status === 'cancelled' || status === 'completed') return false
    return id && date_due && !date_completed
  }, [props.paymentRequest])

  const remindersAreMuted = useMemo(() => {
    return props.paymentRequest?.invoice_email_reminder_status === 'paused' ? true : false
  }, [props.paymentRequest])

  const handleClickReminders = () => {
    if (props.paymentRequest?.invoice) {
      pauseEmailReminders(props.paymentRequest?.org, props.paymentRequest?.invoice, remindersAreMuted)
        .then(() => {
          refreshMilestones()
          notify(
            translate(
              `Automated email reminders for ${props.paymentRequest.invoice_number} ${
                remindersAreMuted ? 'resumed' : 'stopped'
              }`
            ),
            'success'
          )
        })
        .catch((err) => {
          notify(err, 'warning', { autoHideDuration: undefined })
        })
        .finally(() => {
          refresh()
        })
    }
  }

  const showAnyActions = isMd || showCancel || showMarkPaidOffline || showUndoMarkPaidOffline || showSendReminder

  if (!props.projectId) return null
  if (!showAnyActions) return null

  return (
    <>
      <Button
        onClick={() => setActionsAreShown(true)}
        variant="contained"
        size="small"
        data-testid="invoice-actions-button"
      >
        <div ref={buttonRef}>{translate('Actions')}</div>
      </Button>
      <Popover
        open={actionsAreShown}
        anchorEl={buttonRef?.current}
        anchorOrigin={{ horizontal: 'center', vertical: 'center' }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        onClose={() => setActionsAreShown(false)}
      >
        <div className={classes.actionsMenu}>
          {isMd && props.paymentRequest.invoice && (
            <div className={classes.actionRow}>
              <PreviewInvoiceLink
                paymentRequest={props.paymentRequest}
                type="button"
                setActionsAreShown={setActionsAreShown}
              />
            </div>
          )}
          {isNext && isMostRecentRowForThisMilestone && (
            <div className={classes.actionRow}>
              <SendInvoiceButton
                label="invoice"
                actionType="invoice"
                projectId={props.projectId}
                disableButtons={disableButtons}
                systemUuid={props.systemUuid}
                setDisableButtons={setDisableButtons}
                setActionsAreShown={setActionsAreShown}
                paymentRequest={props.paymentRequest}
              />
            </div>
          )}

          {showSendReminder && remindersAreMuted && (
            <div className={classes.actionRow}>
              <Tooltip title="Resume automated reminders. Email reminders will start from wherever they are in the reminder schedule.">
                <Button
                  size="small"
                  className={classes.btn}
                  onClick={handleClickReminders}
                  disabled={disableButtons}
                  data-testid="toggle-reminders-button"
                >
                  {translate('Resume Automated Reminders')}
                </Button>
              </Tooltip>
            </div>
          )}

          {showSendReminder && !remindersAreMuted && (
            <>
              <div className={classes.actionRow}>
                <SendInvoiceButton
                  label="reminder"
                  actionType="reminder"
                  projectId={props.projectId}
                  disableButtons={disableButtons}
                  systemUuid={props.systemUuid}
                  setDisableButtons={setDisableButtons}
                  setActionsAreShown={setActionsAreShown}
                  paymentRequest={props.paymentRequest}
                />
              </div>

              <div className={classes.actionRow}>
                <Tooltip title="Turn off email reminders for customers on this project. You can turn them back on later, and they'll pick up where they left off.">
                  <Button
                    size="small"
                    className={classes.btn}
                    onClick={handleClickReminders}
                    disabled={disableButtons}
                    data-testid="toggle-reminders-btn"
                  >
                    {translate('Stop Automated Reminders')}
                  </Button>
                </Tooltip>
              </div>
            </>
          )}
          {showMarkPaidOffline && (
            <div className={classes.actionRow}>
              <MarkPaidOfflineButton
                paymentRequest={props.paymentRequest}
                systemUuid={props.systemUuid}
                disableButtons={disableButtons}
                setDisableButtons={setDisableButtons}
                setActionsAreShown={setActionsAreShown}
              />
            </div>
          )}
          {showUndoMarkPaidOffline && (
            <div className={classes.actionRow}>
              <UndoMarkPaidOfflineButton
                paymentRequest={props.paymentRequest}
                systemUuid={props.systemUuid}
                disableButtons={disableButtons}
                setDisableButtons={setDisableButtons}
                setActionsAreShown={setActionsAreShown}
              />
            </div>
          )}
          {showCancel && (
            <>
              <div className={classes.actionRow}>
                <EditPaymentOptionsButton
                  paymentRequest={props.paymentRequest}
                  setActionsAreShown={setActionsAreShown}
                />
              </div>
            </>
          )}
          {showCancel && (
            <>
              <div className={classes.actionRow}>
                <CancelInvoiceButton
                  paymentRequest={props.paymentRequest}
                  systemUuid={props.systemUuid}
                  disableButtons={disableButtons}
                  setDisableButtons={setDisableButtons}
                  setActionsAreShown={setActionsAreShown}
                />
              </div>
              {props.paymentRequest.payment_collection_url && (
                <div className={classes.actionRow}>
                  <Link href={props.paymentRequest.payment_collection_url} referrerPolicy="no-referrer" target="_blank">
                    <Button size="small" disabled={disableButtons} data-testid="view-payment-page-btn">
                      {translate('View Payment Page')}
                    </Button>
                  </Link>
                </div>
              )}
            </>
          )}
        </div>
      </Popover>
    </>
  )
}

export default InvoiceActions
