import { styled } from 'opensolar-ui'
import { memo } from 'react'
import { useTranslate } from 'react-admin'
import { doNotTranslate, format_percentage_value } from 'util/misc'

const Subheader = styled('div', { name: 'ShadeMetricsAccordionSubheader' })({
  fontSize: '12px',
  color: 'rgb(108, 108, 108)',
  overflow: 'hidden',
  lineHeight: '14px',
  display: 'inline-block',
})

const FigureNotAvailableWrapper = styled('span', { name: 'FigureNotAvailable' })({
  margin: '0 5px',
})

const FigureNotAvailable = () => {
  // Note: didn’t use a skeleton here because there’s a chance that shadeMetrics might not be available with SAM enabled.
  return <FigureNotAvailableWrapper>--</FigureNotAvailableWrapper>
}

const ShadeMetricsAccordionHeader = memo(
  ({
    sunAccessFactor,
    totalSolarResourceFraction,
  }: {
    sunAccessFactor?: number
    totalSolarResourceFraction?: number
  }) => {
    const translate = useTranslate()
    return (
      <div>
        <div>{translate('Shading')}</div>
        <Subheader>
          <span>
            {translate('Annual Sun Access')}:&nbsp;
            {sunAccessFactor !== undefined ? `${format_percentage_value(sunAccessFactor)}%` : <FigureNotAvailable />}
          </span>
          <span>{', '}</span>
          <span>
            {doNotTranslate('TSRF')}:&nbsp;
            {totalSolarResourceFraction !== undefined ? (
              `${format_percentage_value(totalSolarResourceFraction)}%`
            ) : (
              <FigureNotAvailable />
            )}
          </span>
        </Subheader>
      </div>
    )
  }
)

export default ShadeMetricsAccordionHeader
