import { starAdd, starRemove } from 'ducks/stars'
import { BaseTheme, IconButton, StarIcon, StarOutlineIcon } from 'opensolar-ui'
import { useRefresh } from 'ra-core'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { ProjectType } from 'types/projects'

interface PropTypes {
  record: ProjectType
}

const FavoriteButton: React.FC<PropTypes> = ({ record }) => {
  const refresh = useRefresh()
  const dispatch = useDispatch()
  const starred = useMemo(() => !!record.stars?.length, [record.stars])
  const handleStarToggle = () => {
    if (!starred) {
      dispatch(starAdd(record.id))
    } else {
      dispatch(starRemove(record.id))
    }
    refresh()
  }

  return (
    <IconButton onClick={handleStarToggle}>
      {starred ? (
        <StarIcon color={BaseTheme.palette.orange[500]} />
      ) : (
        <StarOutlineIcon color={BaseTheme.palette.grey[700]} />
      )}
    </IconButton>
  )
}

export default FavoriteButton
