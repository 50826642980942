import { ProjectErrorIdent, ProjectErrorType } from 'reducer/project/projectErrorsReducer'

export const PROJECT_ERRORS_ADD = 'PROJECT_ERRORS_ADD'
export const PROJECT_ERRORS_DELETE = 'PROJECT_ERRORS_DELETE'
export const PROJECT_ERRORS_CLEAR = 'PROJECT_ERRORS_CLEAR'

export const PROJECT_ERROR_PROMPT_ADD = 'PROJECT_ERROR_PROMPT_ADD'
export const PROJECT_ERROR_PROMPT_REMOVE = 'PROJECT_ERROR_PROMPT_REMOVE'
export const PROJECT_ERROR_PROMPT_CLEAR = 'PROJECT_ERROR_PROMPT_CLEAR'

export enum ProjectErrorsActions {
  PROJECT_ERRORS_ADD = 'PROJECT_ERRORS_ADD',
  PROJECT_ERRORS_DELETE = 'PROJECT_ERRORS_DELETE',
  PROJECT_ERRORS_CLEAR = 'PROJECT_ERRORS_CLEAR',

  PROJECT_ERROR_PROMPT_ADD = 'PROJECT_ERROR_PROMPT_ADD',
  PROJECT_ERROR_PROMPT_REMOVE = 'PROJECT_ERROR_PROMPT_REMOVE',
  PROJECT_ERROR_PROMPT_CLEAR = 'PROJECT_ERROR_PROMPT_CLEAR',
}

type ProjectErrorActionType = {
  type: ProjectErrorsActions
  payload: ProjectErrorType
}
type ProjectErrorRemoveActionType = {
  type: ProjectErrorsActions
  payload: ProjectErrorIdent
}

export const addProjectError = (error: ProjectErrorType): ProjectErrorActionType => {
  return {
    type: ProjectErrorsActions.PROJECT_ERRORS_ADD,
    payload: error,
  }
}

export const removeProjectError = (error: ProjectErrorIdent): ProjectErrorRemoveActionType => {
  return {
    type: ProjectErrorsActions.PROJECT_ERRORS_DELETE,
    payload: error,
  }
}

export const clearProjectErrors = (filter?: (error: ProjectErrorType) => boolean) => {
  return {
    type: ProjectErrorsActions.PROJECT_ERRORS_CLEAR,
    payload: { filter },
  }
}

export const addProjectErrorPrompt = (
  promptId: number,
  message: string,
  systemId: string,
  integration: string | undefined | null,
  pmtId: number | undefined | null
) => {
  return {
    type: PROJECT_ERROR_PROMPT_ADD,
    payload: {
      message,
      promptId,
      integration,
      systemId,
      pmtId,
    },
  }
}
