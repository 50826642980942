import { FileCheckOutlineIcon, styled } from 'opensolar-ui'
import AccordionCard from 'projectSections/sections/info/AccordionCard'
import { complianceForms } from 'projectSections/sections/info/permitsAndServices/constants'
import { ComplianceFormType, RecordType } from 'projectSections/sections/info/permitsAndServices/types'
import { useTranslate } from 'ra-core'
import React, { useMemo } from 'react'
import { useFormState } from 'react-final-form'
import { Column } from '../styles'
import FormDisplay from './FormDisplay'
import { SectionHeader } from './styles'

const NoContentMessage = styled('p', { name: 'NoContentMessage' })(({ theme }) => ({
  textAlign: 'center',
  padding: '16px',
  color: theme.palette.grey[900],
}))

const NoteText = styled('h3', { name: 'NoteText' })(({ theme }) => ({
  color: theme.palette.grey[810],
}))

const FormRow = styled('div', { name: 'FormRow' })(({ theme }) => ({
  display: 'flex',
  '& .fileCol': {
    width: '35%',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    '& .fileCol': {
      width: 'unset',
    },
  },
}))

const ServicesAccordion: React.FC = () => {
  const translate = useTranslate()
  return (
    <AccordionCard
      name={'services'}
      elevation={0}
      title={translate('Permits & Services')}
      titleIcon={() => <FileCheckOutlineIcon variant={2} />}
      content={ServicesContent}
      lazyRender={true}
      ux3={true}
    />
  )
}

const ServicesContent = (props) => {
  const translate = useTranslate()
  const project = useFormState().values as RecordType
  const notAvailableMessage = useMemo(() => {
    if (!project?.systems || project?.systems?.length === 0) {
      return translate('Compliance forms available after system design')
    } else if (project?.systems?.length > 1 && !project?.system_installed) {
      return translate(
        'When a project has multiple systems, compliance forms are only available once the installed system is selected.'
      )
    } else {
      return null
    }
  }, [project?.systems, project?.system_installed])
  const checkGeoFilters = (item: ComplianceFormType): boolean => {
    if (
      project.country_iso2 &&
      project.country_iso2.length > 0 &&
      item.countries &&
      item.countries.indexOf(project.country_iso2) === -1
    ) {
      return false
    }

    // if (project.country_iso2 === 'NZ' && enableDocsForNZ === false) {
    //   return false
    // }

    if (project.state && project.state.length > 0 && item.states && item.states.indexOf(project.state) === -1) {
      return false
    }

    if (project.zip && project.zip.length > 0 && item.zips && item.zips.indexOf(project.zip) === -1) {
      return false
    }

    var tariff_data = project.utility_tariff_proposed_data
      ? project.utility_tariff_proposed_data
      : project.utility_tariff_current_data

    if (
      tariff_data &&
      tariff_data.distributor_name &&
      tariff_data.distributor_name.length > 0 &&
      item.distributors &&
      item.distributors.indexOf(tariff_data.distributor_name) === -1
    ) {
      return false
    }

    return true
  }
  const filterByGeo = (items: ComplianceFormType[]): any[] =>
    items.filter((item: ComplianceFormType) => checkGeoFilters(item))
  const countFiles = (record: RecordType, file_tag: any) =>
    record && record.private_files_data && record.private_files_data.length
      ? record.private_files_data.filter(
          (private_file_data) =>
            private_file_data.file_tags_data.filter((file_tag_data) => file_tag_data.title === file_tag).length > 0
        ).length
      : 0
  const filteredForms = useMemo(() => filterByGeo(complianceForms), [project])
  if (notAvailableMessage) return <p>{notAvailableMessage}</p>
  if (filteredForms.length === 0) {
    return <NoContentMessage>{translate('No Permit & Services content available')}</NoContentMessage>
  }
  return (
    <Column style={{ width: '100%' }}>
      {filterByGeo(complianceForms).map((item, i) => (
        <div key={'form-' + i}>
          <SectionHeader>{translate(item.heading)}</SectionHeader>
          {item.notes && <NoteText>{item.notes}</NoteText>}
          {!!item.groups.length && (
            <FormRow>
              <div className={'fileCol'}>
                {filterByGeo(item.groups)
                  ?.filter((x) => x.heading !== 'Attached Files')
                  ?.map((itemData, j) => (
                    <FormDisplay
                      key={'formItem-' + j}
                      item={itemData}
                      filterByGeo={filterByGeo}
                      countFiles={(file_tag) => {
                        return countFiles(project, file_tag)
                      }}
                    />
                  ))}
              </div>
              <Column>
                {filterByGeo(item.groups)
                  ?.filter((x) => x.heading === 'Attached Files')
                  ?.map((itemData, k) => (
                    <FormDisplay
                      key={'formItem-' + k}
                      item={itemData}
                      filterByGeo={filterByGeo}
                      countFiles={(file_tag) => {
                        return countFiles(project, file_tag)
                      }}
                    />
                  ))}
              </Column>
            </FormRow>
          )}
        </div>
      ))}
    </Column>
  )
}

export default ServicesAccordion
