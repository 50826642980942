import React, { FC, useCallback, useState } from 'react'
import { SaveButton, Toolbar, useNotify, useRedirect, useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
// import SaveButton from 'elements/proUXButtons/SaveButton'
import { Dialog, DialogContent, DialogTitle, Fab, makeStyles, Theme, useMediaQuery } from '@material-ui/core'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ContentAdd from '@material-ui/icons/AddOutlined'
import AlertError from '@material-ui/icons/ErrorOutlineOutlined'
import classnames from 'classnames'
import { authSelectors } from 'ducks/auth'
import { Button } from 'opensolar-ui'
import { getOrgFromState } from '../../util/org'
import { ProposalTemplateCreate } from './ProposalTemplates'
import { ProposalTemplateTypeEnum } from './types'

const useStyles = makeStyles(
  () => ({
    floating: {
      margin: 0,
      top: 'auto',
      right: 20,
      bottom: 110,
      left: 'auto',
      position: 'fixed',
      zIndex: 1000,
    },
  }),
  { name: 'ProposalTemplateCreateButton' }
)

type DialogPropsType = {
  dialogMode: string
  setDialogMode: Function
}

const CustomizedToolbar = (props: any) => {
  const translate = useTranslate()
  const notify = useNotify()
  const redirect = useRedirect()
  const onSuccess = useCallback((response) => {
    const record = response.data
    if (record) {
      notify('ra.notification.created', 'info')
      redirect('edit', '/proposal_templates', record.id, record)
    }
  }, [])
  return (
    <Toolbar
      style={{ zIndex: 1, left: 0, position: 'absolute', bottom: 0, boxSizing: 'border-box', width: '100%' }}
      {...props}
    >
      <div>
        <Button
          variant="contained"
          color="default"
          style={{ margin: 10 }}
          onClick={() => props.setDialogMode('')}
          startIcon={<AlertError />}
        >
          <span>{translate('Cancel')}</span>
        </Button>
        <SaveButton
          {...props}
          redirect={'edit'}
          submitOnEnter={true}
          onSave={(values: object, redirect: string, form: any, defaultSaveFunc: Function) => {
            defaultSaveFunc(values, redirect, { onSuccess })
          }}
        />
      </div>
    </Toolbar>
  )
}

const ProposalTemplateDialog: FC<DialogPropsType> = ({ dialogMode, setDialogMode }) => {
  const translate = useTranslate()
  return (
    <div>
      <Dialog id="ProposalTemplateDialog" open={!!dialogMode}>
        <DialogTitle>{translate('Create Proposal Template')}</DialogTitle>
        <DialogContent style={{ padding: '0 20px 70px' }}>
          <ProposalTemplateCreate
            resource="proposal_templates"
            basePath="/proposal_templates/create"
            mode={dialogMode}
            hideBreadCrumbs={true}
            hideTitle={true}
            noBorder={true}
            toolbar={<CustomizedToolbar setDialogMode={setDialogMode} />}
          />
        </DialogContent>
      </Dialog>
    </div>
  )
}

const CreateButton = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const translate = useTranslate()
  const [dialogMode, setDialogMode] = useState<string>('')
  const api_key_pvsell = useSelector(authSelectors.getPvSellKey)
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const classes = useStyles()
  const open = Boolean(anchorEl)
  const bespoke_enabled = useSelector((state: any) => getOrgFromState(state)?.enable_bespoke_proposal)
  // const enableProposalV2 = useFeatureFlag('enable_sales_machine', 'on')

  const options = [
    {
      name: 'OpenSolar Template',
      mode: ProposalTemplateTypeEnum.opensolar,
    },
  ]

  // TODO: Uncomment when multiple SM templates are supported. This is hidden temporarily for Phase 1.
  // if (enableProposalV2) {
  //   options.push({
  //     name: 'OpenSolar Proposal V2 Template',
  //     mode: ProposalTemplateType.proposalV2,
  //   })
  // }

  if (Boolean(api_key_pvsell)) {
    options.push({
      name: 'PVProsell Template',
      mode: ProposalTemplateTypeEnum.pvprosell,
    })
  }

  if (Boolean(bespoke_enabled)) {
    options.push({
      name: 'Bespoke Template',
      mode: ProposalTemplateTypeEnum.bespoke,
    })
  }
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (options.length > 1) {
      setAnchorEl(event.currentTarget)
    } else {
      setDialogMode(options[0].mode)
    }
  }
  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  return (
    <div>
      {isSmall ? (
        <Fab
          id="pvprosell-create-button"
          aria-controls="pvprosell-create-menu"
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          //@ts-ignore
          // variant="contained"
          style={{
            width: 70,
            height: 70,
            borderRadius: '50%',
          }}
          color="default"
          className={classnames(classes.floating)}
          aria-label="Add Proposal Template"
        >
          <ContentAdd />
        </Fab>
      ) : (
        <Button
          id="pvprosell-create-button"
          aria-controls="pvprosell-create-menu"
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          variant="outlined"
          color="default"
          startIcon={<ContentAdd />}
          style={{ margin: '0 5px' }}
        >
          <span>{translate('Proposal Template')}</span>
        </Button>
      )}
      <Menu
        id="pvprosell-create-menu"
        aria-labelledby="pvprosell-create-button"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        open={open}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        {options.map((option) => (
          <MenuItem
            onClick={() => {
              handleMenuClose()
              setDialogMode(option.mode)
            }}
          >
            {translate(option.name)}
          </MenuItem>
        ))}
      </Menu>
      <ProposalTemplateDialog dialogMode={dialogMode} setDialogMode={setDialogMode} />
    </div>
  )
}

export default CreateButton
