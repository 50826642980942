var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { CardContent as Mui4CardContent } from '@material-ui/core';
import { default as Mui5CardContent } from '@mui/material/CardContent';
import { forwardRef } from 'react';
import { Inspector } from '../debug/Inspector';
import { useComponentVersion } from '../hooks';
import { OsCardVersion } from './Card';
export var CardContent = forwardRef(function (props, ref) {
    return (_jsx(Inspector, { name: "CardContent", propDefs: CardContentProps, showExample: true, component: OsCardContent, props: __assign(__assign({}, props), { ref: ref }) }));
});
var OsCardContent = forwardRef(function (props, ref) {
    if (ref === void 0) { ref = null; }
    var version = useComponentVersion('card');
    switch (version) {
        case OsCardVersion.V2:
            //@ts-ignore
            return _jsx(Mui5CardContent, __assign({ ref: ref }, props));
        default:
            return _jsx(Mui4CardContent, __assign({ ref: ref }, props));
    }
});
var CardContentProps = {};
