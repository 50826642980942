import { StructsLoadState } from 'contexts/structs/types/types'
import { SponsoredContentType } from 'elements/ads/useSponsoredContentStructs'
import type {
  BatterySalesAssistantFlowStoreType,
  RecommendBatterySetType,
} from 'elements/hardwareSelectorV2/batteryWizard/utility/types'
import { useEffect, useMemo, useState } from 'react'
import { useDebouncedCallback } from 'util/Debounce'
import useFetchRecommendedBatterySets from './useFetchRecommendedBatterySets'

const getSponsoredBatteryCodesWeightMap = ({ sponsoredContents }: { sponsoredContents: SponsoredContentType[] }) => {
  const sponsoredBatteryCodesWeightMap = {}
  sponsoredContents
    .filter((content) => content.component_codes)
    .forEach((sponsoredContent) => {
      sponsoredContent.component_codes
        .split(',')
        .forEach((code) => (sponsoredBatteryCodesWeightMap[code] = sponsoredContent!.weighting))
    })

  if (Object.keys(sponsoredBatteryCodesWeightMap).length) {
    // TODO: move this map to BE once this PR is merged https://github.com/open-solar/sunsuite/pull/8215
    return sponsoredBatteryCodesWeightMap
  }
}

const useRecommendedBatterySets = ({
  flowStore,
  sponsoredContents,
  loadState,
}: {
  flowStore: BatterySalesAssistantFlowStoreType
  sponsoredContents: SponsoredContentType[]
  loadState: StructsLoadState
}) => {
  const [isLoading, setIsLoading] = useState(true)
  const [isLoaded, setIsLoaded] = useState(false)
  const [recommendedBatterySets, setRecommendedBatterySets] = useState<RecommendBatterySetType[]>([])

  const fetchRecommendedBatterySets = useFetchRecommendedBatterySets({
    projectId: flowStore.projectId,
    systemUuid: flowStore.systemUuid,
  })

  const settings = useMemo(() => {
    const sponsoredBatteryCodesWeightMap = getSponsoredBatteryCodesWeightMap({ sponsoredContents })

    return {
      customer_goal: flowStore.customerPriority,
      backup_hours: isNaN(Number(flowStore.backupHours)) ? undefined : Number(flowStore.backupHours),
      continuous_power_kw: isNaN(Number(flowStore.averageContinuousPower))
        ? undefined
        : Number(flowStore.averageContinuousPower),
      peak_power_kw: isNaN(Number(flowStore.totalPeakPower)) ? undefined : Number(flowStore.totalPeakPower),
      codes: flowStore.limitedCodes,
      ...(sponsoredBatteryCodesWeightMap && { sponsored_batteries: sponsoredBatteryCodesWeightMap }),
    }
  }, [flowStore, sponsoredContents])

  const loadRecommendedBatterySets = useDebouncedCallback((settings) => {
    fetchRecommendedBatterySets(settings).then((data) => {
      setRecommendedBatterySets(data)
      setIsLoading(false)
      setIsLoaded(true)
    })
  }, 100)

  useEffect(() => {
    if (loadState === 'not-loaded') return
    if (isLoaded) return
    loadRecommendedBatterySets(settings)
  }, [loadState, isLoaded, settings])

  return { isLoading, isLoaded, recommendedBatterySets }
}

export default useRecommendedBatterySets
