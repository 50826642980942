import { useStructs } from 'contexts/structs/hooks/useStructs'
import { PromoDialogElement } from './usePromoDialogStructs'

export const useOpenSolarVersionStructs = () => {
  return useStructs<OpenSolarVersionInfo>('opensolar-version')
}

export type OpenSolarVersionInfo = {
  version: string
  version_spa: string
  version_api: string
  release_time: string
  release_notes_url: string
  release_complete: boolean
  release_duration_mins: number

  show_promo_warning_2hr: boolean
  show_promo_warning_5m: boolean
  show_promo_progress: boolean
  show_promo_success: boolean
  show_promo_success_lite: boolean

  release_highlights: PromoDialogElement[]
  extra_content_warning_2h: PromoDialogElement[]
  extra_content_warning_5m: PromoDialogElement[]
  extra_content_progress: PromoDialogElement[]
  extra_content_success: PromoDialogElement[]
  extra_content_success_lite: PromoDialogElement[]

  hide_def_content_warning_2hr: boolean
  hide_def_content_warning_5m: boolean
  hide_def_content_progress: boolean
  hide_def_content_success: boolean
  hide_def_content_success_lite: boolean
}
