import * as Sentry from '@sentry/react'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { hideFullScreenLoader, markCreditAppAsOpened, showCheckoutDialog } from 'ducks/myEnergy'
import { CheckoutActionCallback } from 'opensolar-checkout'
import { useCallback } from 'react'
import { useNotify, useTranslate } from 'react-admin'
import { useDispatch } from 'react-redux'
import restClient from 'restClient'
import { ProjectType } from 'types/projects'

var restClientInstance = restClient(window.API_ROOT + '/api')

const useOpenApplicationDialog = () => {
  const dispatch = useDispatch()
  const notify = useNotify()
  const translate = useTranslate()
  return useCallback(
    async ({
      org_id,
      project_id,
      system_uuid,
      payment_option_id,
      project,
      showFullWidthDialog,
    }: {
      org_id: number
      project_id: number
      system_uuid: string
      payment_option_id: number
      project: ProjectType
      showFullWidthDialog?: boolean
    }) => {
      await restClientInstance('CUSTOM_GET', 'custom', {
        url: 'orgs/' + org_id + '/projects/' + project_id + '/dividend/disclosures/',
      })
        .then(
          (response: any) => {
            if (response?.data?.html_content) {
              dispatch(
                showCheckoutDialog({
                  type: 'DividendApplicationDialog',
                  config: {
                    orgId: org_id,
                    system_uuid: system_uuid,
                    paymentOptionId: payment_option_id,
                    project: project,
                    disclosureText: response.data.html_content,
                    isFullWidth: showFullWidthDialog,
                  },
                })
              )
            }
          },
          (reject: any) => {
            console.log(reject)
            notify(translate(reject.message), 'error')
          }
        )
        .catch((err) => {
          if (err?.body?.message) {
            notify(translate(err.body.message), 'error')
            logAmplitudeEvent('integrated_finance_application_open_error', { integration: 'dividend' })
          }
        })
        .finally(() => {
          dispatch(hideFullScreenLoader())
          dispatch(markCreditAppAsOpened())
        })
    },
    []
  )
}

const useDividendApplicationCallback = (): CheckoutActionCallback => {
  const openApplicationDialog = useOpenApplicationDialog()

  return useCallback(async ({ elementData, proposalData, additionalActionData }) => {
    if (elementData.type !== 'LoanApplication') {
      Sentry.captureException(new Error('Invalid element data for LoanApplication callback'))
      return
    }
    if (elementData.actionData.payment_method !== 'dividend_application') {
      Sentry.captureException(new Error('Invalid element data for dividend application callback'))
      return
    }
    const { actionData } = elementData

    await openApplicationDialog({
      org_id: actionData.org_id,
      project_id: actionData.project_id,
      system_uuid: actionData.system_uuid,
      payment_option_id: actionData.payment_option_id,
      project: proposalData.selectedProject as ProjectType,
      showFullWidthDialog: additionalActionData?.showFullWidthDialog,
    })
  }, [])
}

export default useDividendApplicationCallback
