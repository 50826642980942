import { authSelectors } from 'ducks/auth'
import { permissionsSelectors } from 'ducks/permissions'
import {
  loadProjectViews,
  projectListViewSelectors,
  removeProjectView,
  updateProjectView,
  ViewType,
} from 'ducks/projectListViews'
import BulkDeleteWithConfirmButton from 'elements/button/BulkDeleteWithConfirmButton'
import UiContainer from 'elements/UiContainer'
import { Button, DeleteOutlineIcon, styled } from 'opensolar-ui'
import ConfirmModal from 'projectSections/sections/manage3/common/ConfirmModal'
import { useNotify, useTranslate } from 'ra-core'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SearchInput from './elements/SearchInput'
import WorkflowDropdown from './elements/WorkflowDropdown'
import { checkOSProjectFiltersEqual, editViews, mapFilterArrayToFilterObject, saveViews } from './utils'
import SettingsButtons from './views/SettingsButtons'

const uiKey = 'projects.search'
const Wrapper = styled('div', { name: 'Wrapper' })({
  width: '100%',
  margin: '10px 0',
  flexWrap: 'wrap',
  rowGap: 10,
  display: 'flex',
  alignItems: 'center',
})

const LeftContainer = styled('div', { name: 'LeftContainer' })({
  marginRight: 'auto',
  flexWrap: 'wrap',
  rowGap: 10,
  display: 'flex',
  alignItems: 'center',
})

const ButtonWrapper = styled('div', { name: 'SearchToolbarFilterBatch' })({
  flexDirection: 'row',
  display: 'flex',
  alignSelf: 'flex-end',
  gap: 10,
})

const SearchToolbar = (props) => {
  const { viewSettings, filterValues, viewSelected, selectedIds, handleViewChange, setFilters } = props
  const { isTableView } = viewSettings
  const notify = useNotify()
  const dispatch = useDispatch()
  const translate = useTranslate()
  const orgId = useSelector(authSelectors.getOrgId)
  const { allowDelete } = useSelector(permissionsSelectors.getPermissionByKey('project'))
  const defaultView = useSelector(projectListViewSelectors.getDefaultViewId)

  const handleSetWorkflow = (id) => {
    props.setFilters({ ...filterValues, workflow_id: id.toString() })
  }

  const [isVisibleDiscardModal, setIsVisibleDiscardModal] = useState(false)
  function deleteView() {
    if (viewSelected) {
      dispatch(removeProjectView(viewSelected.id))
      if (defaultView) handleViewChange(defaultView)
    }
    setIsVisibleDiscardModal(false)
  }
  const [currentViewUnsavedFilters, setCurrentViewUnsavedFilters] = useState<{
    filters: any[]
    viewType?: number
  } | null>(null)

  useEffect(() => {
    const viewTypeEqual =
      (viewSettings?.isTableView === true && viewSelected?.view_type === ViewType.Table) ||
      (viewSettings?.isTableView === false && viewSelected?.view_type === ViewType.Kanban)
    const currentViewfilters = viewSelected?.view_settings?.filters
    const equalData = checkOSProjectFiltersEqual(filterValues, currentViewfilters)
    const unequal = !equalData.equal || viewTypeEqual === false
    if (unequal) {
      let unsavedFilters = { filters: equalData.newFilters }
      if (viewTypeEqual === false)
        unsavedFilters['viewType'] = viewSettings?.isTableView ? ViewType.Table : ViewType.Kanban
      setCurrentViewUnsavedFilters(unsavedFilters)
    } else {
      setCurrentViewUnsavedFilters(null)
    }
  }, [viewSettings.selectedView, filterValues, viewSettings.isTableView])

  const isUnSavedViewAvailable: boolean = useSelector(projectListViewSelectors.getIsUnsavedViewExist)
  useEffect(() => {
    // This is to handle unsaved view data
    const handleBeforeUnload = (event) => {
      if (isUnSavedViewAvailable) {
        event.preventDefault()
        event.returnValue = ''
      }
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => window.removeEventListener('beforeunload', handleBeforeUnload)
  }, [isUnSavedViewAvailable])

  const saveNewViews = (payload) => {
    saveViews(payload, orgId)
      .then((response) => {
        dispatch(loadProjectViews(orgId))
      })
      .catch((error) => {
        notify('Failed to save view. Please try again later.', 'error')
      })
  }

  const editView = (payload, viewId) => {
    editViews(payload, viewId, orgId)
      .then((response) => {
        dispatch(loadProjectViews(orgId))
      })
      .catch((error) => {
        notify('Failed to save filters. Please try again later.', 'error')
      })
  }
  const handleDiscard = () => {
    if (viewSelected.not_saved) {
      setIsVisibleDiscardModal(true)
    } else if (currentViewUnsavedFilters) {
      const currentViewfilters = viewSelected?.view_settings?.filters
      viewSettings.setTableView(viewSelected?.view_type === ViewType.Table)
      setFilters({ ...mapFilterArrayToFilterObject(currentViewfilters) })
    }
  }

  const handleSave = () => {
    if (viewSelected.not_saved) {
      let newView = { ...viewSelected }
      delete newView.not_saved
      saveNewViews(newView)
    } else if (currentViewUnsavedFilters) {
      let newView = {
        ...viewSelected,
        view_settings: {
          ...viewSelected.view_settings,
          filters: currentViewUnsavedFilters.filters,
        },
      }
      if (currentViewUnsavedFilters.viewType !== undefined)
        newView.view_settings.view_type = currentViewUnsavedFilters.viewType
      if (newView.not_saved) {
        dispatch(updateProjectView(newView))
      } else {
        editView(newView, newView.id)
      }
    }
  }

  return (
    <UiContainer uiKey={uiKey}>
      <Wrapper>
        <LeftContainer>
          <SearchInput {...props} />
          {!isTableView && (
            <WorkflowDropdown workflow={Number(filterValues?.workflow_id)} setWorkflow={handleSetWorkflow} />
          )}
          <SettingsButtons {...props} />
        </LeftContainer>
        {allowDelete && !!selectedIds?.length && (
          <BulkDeleteWithConfirmButton
            label="Delete Projects"
            variant={'outlined'}
            color={'secondary'}
            startIcon={<DeleteOutlineIcon />}
            {...props}
            style={{ marginRight: 10 }}
          />
        )}
        {(viewSelected.not_saved || currentViewUnsavedFilters) && (
          <ButtonWrapper>
            <Button variant="outlined" color="secondary" onClick={handleDiscard}>
              {translate('Discard')}
            </Button>
            <Button variant="contained" color="primary" onClick={handleSave}>
              {translate('Save')}
            </Button>
          </ButtonWrapper>
        )}
      </Wrapper>
      <ConfirmModal
        open={isVisibleDiscardModal}
        setOpen={setIsVisibleDiscardModal}
        handleSubmit={deleteView}
        title={translate('Discard Changes')}
        mainText={translate('Are you sure you want to discard changes?')}
        subText={translate('You will lose all your unapplied changes. Do you wish to continue?')}
        submitBtnLabel={translate('Yes, discard')}
        submitBtnProps={{ variant: 'contained', color: 'error' }}
      />
    </UiContainer>
  )
}

export default SearchToolbar
