import { Grid, styled, Typography } from 'opensolar-ui'
import { useEffect, useState } from 'react'
import {
  ComponentLikeType,
  DistributorDataTypeV2,
  StockLevelTypeV2,
} from '../../../../elements/hardwareSelectorV2/types'
import { AddToCartButton } from '../../catalogv3/AddToCartButton'
import DistributorChips from '../../catalogv3/DistributorsChip'
import { getQuantity, PricingText } from '../../catalogv3/ProductTile'
import QuantityButton from '../../catalogv3/QuantityButton'
import { getSupplierConfigByFilterKey } from '../../configs'
import { HardwareSupplierEnum } from '../../type'
import { sortByPriceOrderingV2 } from '../../utils'
import { useGlobalCart } from '../../../../hooks/useGlobalCart'
import OrderLineItem from '../../OrderLineItem'
import { useSelector } from 'react-redux'
import { orgSelectors } from '../../../../ducks/orgs'
import { currencySymbolForCountry, formatCurrencyNumber } from '../../../../util/misc'

export const Wrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-evenly',
  backgroundColor: 'white',
  padding: 20,
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
  borderBottomLeftRadius: 8,
  borderBottomRightRadius: 8,
  height: 305,
})

const Divider = styled('div')(({ theme }) => ({
  borderBottom: '1px solid ' + theme.palette.grey[130],
}))

const getStockLevelLabel = (stockLevel: StockLevelTypeV2 | null, distributor?: DistributorDataTypeV2) => {
  if (!distributor || !stockLevel) {
    return 'Out of stock'
  }

  const distributoConfig = getSupplierConfigByFilterKey(distributor.distributor)
  if (distributoConfig.type === HardwareSupplierEnum.SolarOutlet) {
    return stockLevel.quantity > 0 ? 'In Stock' : 'Out of stock'
  }

  return stockLevel?.quantity && Number(stockLevel.quantity) > 0 ? `In Stock` : 'Out of stock'
}

const getComputeCostLabel = (computedCost?: string, quantity?: number, pricePerUnit?: number) => {
  if (!quantity || !pricePerUnit) return
  return `Total Cost ${quantity} * ${pricePerUnit} = ${computedCost}`
}

const calculateCost = (quantity, price) => {
  const computedCost = quantity * price
  return formatCurrencyNumber(computedCost, 2)
}

export const ProductDetailCheckoutMultiDistributor = ({ component }: { component: ComponentLikeType | undefined }) => {
  const [selectedDistributor, setSelectedDistributor] = useState<DistributorDataTypeV2 | null>(null)
  const [cartItem, setCartItem] = useState<OrderLineItem | null>(null)

  const lowestPriceDistributor = component
    ? sortByPriceOrderingV2(component.ordering_v2).find((ordering) => ordering.price)
    : component

  const displayDetailsDistributor = selectedDistributor || lowestPriceDistributor
  const [computedCost, setComputedCost] = useState<string>()

  const { addLineItemFromV2Component, updateLineItemQtyByCode, removeLineItem, orderableLineItems } = useGlobalCart()

  const distributoConfig = !!displayDetailsDistributor
    ? getSupplierConfigByFilterKey(displayDetailsDistributor.distributor)
    : undefined

  const isAUorOutlet =
    useSelector(orgSelectors.getOrgIso2) === 'AU' || distributoConfig?.type === HardwareSupplierEnum.SolarOutlet

  const isSegen = displayDetailsDistributor
    ? getSupplierConfigByFilterKey(displayDetailsDistributor.distributor).type === HardwareSupplierEnum.Segen
    : false

  const stockLevel: StockLevelTypeV2 | null = getQuantity(displayDetailsDistributor?.stock_levels)
  const hasStocks = (stockLevel?.quantity && Number(stockLevel.quantity) > 0)

  // Segen special case for stock
  const isSegenHasNoStocks = isSegen && (!stockLevel  || stockLevel?.planned_date || Number(stockLevel.quantity) === 0)

  const currencySymbol = useSelector((state: any) => {
    return currencySymbolForCountry(orgSelectors.getOrgIso2(state))
  })

  useEffect(() => {
    const cartOrderItem = orderableLineItems.find((lineItem: OrderLineItem) => lineItem.code === component?.code)
    if (!cartOrderItem) {
      setCartItem(null)
      return
    }
    setComputedCost(calculateCost(cartOrderItem.quantity, cartOrderItem.pricePerUnit))
    setCartItem(cartOrderItem)
  }, [orderableLineItems])

  useEffect(() => {
    setComputedCost(calculateCost(cartItem?.quantity, displayDetailsDistributor?.price_detail?.price_ex_vat))
  }, [displayDetailsDistributor])

  if (!component) {
    return <></>
  }

  const handleQuantityChange = (quantity: number) => {
    // Update the calculated cost
    if (displayDetailsDistributor?.price_detail?.price_ex_vat) {
      setComputedCost(calculateCost(quantity, displayDetailsDistributor?.price_detail?.price_ex_vat))
    }

    if (quantity === 0 || !quantity) {
      removeLineItem(cartItem?.uuid)
      setCartItem(null)
      return
    }
    // Update the quantity in the cart
    updateLineItemQtyByCode(component.code, quantity)
  }

  const handleAddToCart = () => {
    if (!displayDetailsDistributor || Object.keys(displayDetailsDistributor).length === 0) {
      return
    }
    addLineItemFromV2Component(component, displayDetailsDistributor)
  }

  const handleDistributorSelect = (e, distributor) => {
    setSelectedDistributor(distributor)
  }

  return (
    <Wrapper>
      <Grid item container justify="space-evenly" direction="column" spacing={1}>
        <Grid item>
          <Typography textVariant="body2" colorHex={hasStocks || isSegenHasNoStocks ? '#008000' : '#D32F2F'}>
            {isSegen && 'In Stock'}
            {!isSegen && getStockLevelLabel(stockLevel, displayDetailsDistributor)}
          </Typography>

          <Typography textVariant="helper1">{isSegenHasNoStocks && 'Stock Availability on Request! Contact Segen!'}</Typography>
          {!isAUorOutlet && stockLevel?.quantity && (
            <Typography textVariant="helper1"> {`${stockLevel?.quantity} Available`} </Typography>
          )}
        </Grid>
        <Grid item>
          {(selectedDistributor?.price || lowestPriceDistributor?.price) && (
            <PricingText
              hasOneDistributor={component.ordering_v2.length === 1}
              selectedDistributor={selectedDistributor}
              lowestPriceDistributor={lowestPriceDistributor}
              totalCost={getComputeCostLabel(
                computedCost,
                cartItem?.quantity,
                displayDetailsDistributor?.price_detail.price_ex_vat
              )}
              currencySymbol={currencySymbol}
            />
          )}
          <Typography textVariant="body1SemiBold"></Typography>
        </Grid>
        <Grid item>
          <Typography textVariant="helper1" colorHex="#757575">
            SELECT AVAILABLE DISTRIBUTOR
          </Typography>
          <DistributorChips
            ordering_v2={component.ordering_v2}
            onSelect={handleDistributorSelect}
            selectedDistributor={selectedDistributor}
          />
        </Grid>

        <br />
        <Divider />
        <Grid item container direction="column" justify="center" alignItems="center">
          {!!cartItem ? (
            <QuantityButton onChangeQuantity={handleQuantityChange} initialValue={cartItem?.quantity} />
          ) : (
            <AddToCartButton onClick={handleAddToCart} />
          )}

          <Typography textVariant="helper1" colorHex="#757575">
            Shipping options will be available during checkout.{' '}
          </Typography>
        </Grid>
      </Grid>
    </Wrapper>
  )
}
