import CircularProgress from '@material-ui/core/CircularProgress'
import { getSectionShouldBeHidden } from 'myenergy/utils'
import { PureComponent } from 'react'
import { withTranslate } from 'react-admin'
import { formatCurrencyWithSymbol } from '../../util/misc'
import CashFlowChart from './CashFlowChart'

const mergeFirstNumbersInArray = (arr) => [arr[0] + arr[1]].concat(arr.slice(2))

// const IncentivesText = ({ selectedSystem, currencySymbol, translate }) => (
//   <div className="small">
//     {'Includes ' + incentivesAsString(selectedSystem.pricing.incentive_to_installer, translate, currencySymbol)}
//   </div>
// )

class FinancialBenefits extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      displayDetail: false,
      showInflation: true,
    }
  }

  displayDetailToggle = (payload) => {
    this.setState({
      displayDetail: payload,
    })
  }

  handlehideInflation = () => {
    this.setState({ showInflation: false })
  }

  handleshowInflation = () => {
    this.setState({ showInflation: true })
  }

  render() {
    const props = this.props
    const { displayDetail } = this.state
    const { translate } = this.props
    const { language } = this.props.selectedProject
    const hideFinancialImpact = getSectionShouldBeHidden(
      this.props.proposalTemplateSettings,
      'heading_net_financial_impact',
      this.props.selectedPaymentOption?.id
    )
    const showFinancialBenefitsDetailed =
      !props.hasPhoenix && props.sectionsData.indexOf('financial_benefits_detailed') !== -1
    const scalingFactorsLabel = props.systems[0].bills.scaling_factors_label

    const convertToMonths = (value) => {
      let decimal = value % 1
      let months = Math.round(decimal * 12)
      return months
    }

    const cashFlowsLabel = this.props.hasPhoenix
      ? 'Estimated annual savings from going solar*'
      : 'Annual Savings From Going Solar'
    const cumulativeChartLabel = this.props.hasPhoenix
      ? 'Estimated Cumulative savings from going Solar*'
      : 'Cumulative Savings From Going Solar'

    const discountedPaybackPeriod = props.selectedPaymentOption.discounted_payback_period
    const discountedPaybackPeriodToYears = Math.floor(discountedPaybackPeriod)
    const discountedPaybackPeriodToMonths = convertToMonths(discountedPaybackPeriod)

    if (hideFinancialImpact) return null
    return (
      <div>
        <h1 className="mye-section-title">{props.proposalTemplateSettings.heading_net_financial_impact}</h1>
        {showFinancialBenefitsDetailed && (
          <div style={{ textAlign: 'center', display: 'flex', justifyContent: 'center' }}>
            <button
              className={'switch-button ' + (displayDetail ? '' : 'switch-button-active')}
              style={{
                borderRadius: '10px 0 0 10px',
              }}
              onClick={() => this.displayDetailToggle(false)}
            >
              {translate('Simple Financials')}
            </button>
            <button
              className={'switch-button ' + (displayDetail ? 'switch-button-active' : '')}
              style={{
                borderRadius: '0 10px 10px 0',
              }}
              onClick={() => this.displayDetailToggle(true)}
            >
              {translate('Detailed Financials')}
            </button>
          </div>
        )}
        {!displayDetail && !props.hasPhoenix && (
          <div style={{ height: 130 }}>
            <div className="Financial-Benefits" style={{ top: showFinancialBenefitsDetailed ? '' : 10 }}>
              {props.selectedPaymentOption.payment_type !== 'regular_payment' && (
                <div
                  style={{ flex: '1 0 auto', textAlign: 'center', width: '25%' }}
                  className={props.layout === 1 ? 'smallFF' : ''}
                >
                  {props.fullCalcReady ? (
                    <div>
                      <div className={props.layout <= 2 ? 'feature-figure-small' : 'feature-figure'}>
                        {formatCurrencyWithSymbol(
                          props.selectedPaymentOption.utility_cost_total_current -
                            props.selectedPaymentOption.utility_cost_total_proposed,
                          props.currencySymbol,
                          language,
                          0
                        )}
                      </div>
                      <div>{translate('Utility Bill Savings')}</div>
                    </div>
                  ) : (
                    <div
                      className="loading-feature-figure"
                      style={{ margin: props.layout === 'small' ? '10px auto' : '14px auto' }}
                    />
                  )}
                </div>
              )}

              {props.selectedPaymentOption.payment_type !== 'regular_payment' && (
                <div
                  className={props.layout <= 2 ? 'feature-figure-small' : 'feature-figure'}
                  style={{
                    flex: '1 0 auto',
                    width: '30px',
                    textAlign: 'center',
                  }}
                >
                  -
                </div>
              )}

              {props.selectedPaymentOption.payment_type !== 'regular_payment' && (
                <div
                  style={{ flex: '1 0 auto', textAlign: 'center', width: '25%' }}
                  className={props.layout === 1 ? 'smallFF' : ''}
                >
                  {props.fullCalcReady ? (
                    <div>
                      <div className={props.layout <= 2 ? 'feature-figure-small' : 'feature-figure'}>
                        {formatCurrencyWithSymbol(
                          props.selectedPaymentOption.net_cost_to_customer,
                          props.currencySymbol,
                          language,
                          0
                        )}
                      </div>
                      <div>
                        {props.selectedPaymentOption.regular_payment > 0
                          ? translate('Total Payments over years', {
                              years: props.selectedPaymentOption.loan_term
                                ? window.roundToDecimalPlaces(props.selectedPaymentOption.loan_term, 0, language)
                                : window.roundToDecimalPlaces(props.selectedPaymentOption.term_years, 0, language),
                            })
                          : translate('Net System Cost')}
                      </div>
                    </div>
                  ) : (
                    <div
                      className="loading-feature-figure"
                      style={{ margin: props.layout === 'small' ? '10px auto' : '14px auto' }}
                    />
                  )}
                </div>
              )}

              {props.selectedPaymentOption.payment_type !== 'regular_payment' && (
                <div
                  className={props.layout <= 2 ? 'feature-figure-small' : 'feature-figure'}
                  style={{
                    flex: '1 0 auto',
                    width: '30px',
                    textAlign: 'center',
                  }}
                >
                  =
                </div>
              )}
              <div
                style={{ flex: '1 0 auto', textAlign: 'center', width: '25%' }}
                className={props.layout === 1 ? 'smallFF' : ''}
              >
                {props.fullCalcReady ? (
                  <div>
                    <div className={props.layout <= 2 ? 'feature-figure-small' : 'feature-figure'}>
                      <span className={props.selectedPaymentOption.net_profit > 0 ? 'green' : ''}>
                        {formatCurrencyWithSymbol(
                          Math.abs(props.selectedPaymentOption.net_profit),
                          props.currencySymbol,
                          language,
                          0
                        )}
                      </span>
                    </div>
                    <div>
                      {props.selectedPaymentOption.net_profit > 0 && (
                        <span className="green">
                          {translate('Estimated Net Savings')}
                          {props.selectedPaymentOption.payment_type === 'regular_payment' && (
                            <span>
                              {' '}
                              {'over ' +
                                window.roundToDecimalPlaces(
                                  props.selectedPaymentOption.cash_flows.length - 1,
                                  0,
                                  language
                                ) +
                                ' years'}
                            </span>
                          )}
                        </span>
                      )}
                      {props.selectedPaymentOption.net_profit <= 0 && (
                        <span className="">{translate('Clean Energy Premium')}</span>
                      )}
                    </div>
                  </div>
                ) : (
                  <div
                    className="loading-feature-figure"
                    style={{ margin: props.layout === 'small' ? '10px auto' : '14px auto' }}
                  />
                )}
              </div>
            </div>
          </div>
        )}
        {showFinancialBenefitsDetailed && displayDetail && (
          <div
            style={{
              width: '100%',
              minWidth: 300,
              display: 'flex',
              flexDirection: 'row',
              margin: 'auto',
              fontSize: 12,
              height: 130,
              position: 'relative',
            }}
            className={props.layout === 1 ? 'smallFF' : ''}
          >
            <div
              style={{
                flex: '1 0 0',
                textAlign: 'center',
                padding: '35px 5px 0 5px',
                display: 'flex',
                overflow: 'visible',
                justifyContent: 'center',
                flexDirection: 'row',
                alignSelf: 'flex-start',
              }}
            >
              <div style={{ overflow: 'visible' }}>
                {props.fullCalcReady ? (
                  <div>
                    <div className={props.layout <= 2 ? 'feature-figure-small' : 'feature-figure'}>
                      {formatCurrencyWithSymbol(
                        props.selectedPaymentOption.net_present_value,
                        props.currencySymbol,
                        language,
                        0
                      )}
                    </div>
                    <div>{translate('Net Present Value')}</div>
                  </div>
                ) : (
                  <div
                    className="loading-feature-figure"
                    style={{ margin: props.layout === 'small' ? '10px auto' : '14px auto' }}
                  />
                )}
              </div>
            </div>
            {discountedPaybackPeriod > 0 && (
              <div
                style={{
                  flex: '1 0 0',
                  textAlign: 'center',
                  padding: '35px 5px 0 5px',
                  display: 'flex',
                  overflow: 'visible',
                  justifyContent: 'center',
                  flexDirection: 'row',
                  alignSelf: 'flex-start',
                }}
              >
                <div style={{ overflow: 'visible' }}>
                  {props.fullCalcReady ? (
                    <div>
                      <div
                        className={props.layout <= 2 ? 'feature-figure-small' : 'feature-figure'}
                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                      >
                        {discountedPaybackPeriodToYears}
                        <div style={{ marginLeft: 5 }}>
                          <div style={{ fontSize: 17, textAlign: 'left' }}>
                            {discountedPaybackPeriodToYears > 1 ? translate('Years') : translate('Year')}
                          </div>
                          {discountedPaybackPeriodToMonths > 0 && (
                            <div style={{ fontSize: 13, textAlign: 'left' }}>
                              {discountedPaybackPeriodToMonths}{' '}
                              {discountedPaybackPeriodToMonths > 1 ? translate('Months') : translate('Month')}
                            </div>
                          )}
                        </div>
                      </div>
                      <div>{translate('Discounted Payback Period')}</div>
                    </div>
                  ) : (
                    <div
                      className="loading-feature-figure"
                      style={{ margin: props.layout === 'small' ? '10px auto' : '14px auto' }}
                    />
                  )}
                </div>
              </div>
            )}
            {props.selectedPaymentOption.total_return_on_investment > 0 && (
              <div
                style={{
                  flex: '1 0 0',
                  textAlign: 'center',
                  padding: '35px 5px 0 5px',
                  display: 'flex',
                  overflow: 'visible',
                  justifyContent: 'center',
                  flexDirection: 'row',
                  alignSelf: 'flex-start',
                }}
              >
                <div style={{ overflow: 'visible' }}>
                  {props.fullCalcReady ? (
                    <div>
                      <div className={props.layout <= 2 ? 'feature-figure-small' : 'feature-figure'}>
                        {window.roundToDecimalPlaces(
                          100 * props.selectedPaymentOption.total_return_on_investment,
                          0,
                          language
                        )}
                        %
                      </div>
                      <div>{translate('Total Return on Investment')}</div>
                    </div>
                  ) : (
                    <div
                      className="loading-feature-figure"
                      style={{ margin: props.layout === 'small' ? '10px auto' : '14px auto' }}
                    />
                  )}
                </div>
              </div>
            )}
            {props.selectedPaymentOption.internal_rate_of_return > 0 && (
              <div
                style={{
                  flex: '1 0 0',
                  textAlign: 'center',
                  padding: '35px 5px 0 5px',
                  display: 'flex',
                  overflow: 'visible',
                  justifyContent: 'center',
                  flexDirection: 'row',
                  alignSelf: 'flex-start',
                }}
              >
                <div style={{ overflow: 'visible' }}>
                  {props.fullCalcReady ? (
                    <div>
                      <div className={props.layout <= 2 ? 'feature-figure-small' : 'feature-figure'}>
                        {window.roundToDecimalPlaces(
                          100 * props.selectedPaymentOption.internal_rate_of_return,
                          1,
                          language
                        )}
                        %
                      </div>
                      <div>{translate('Rate of Return on Investment')}</div>
                    </div>
                  ) : (
                    <div
                      className="loading-feature-figure"
                      style={{ margin: props.layout === 'small' ? '10px auto' : '14px auto' }}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        )}

        {/* (props.selectedPaymentOption.description && props.selectedPaymentOption.description.length > 0) === true && (
      <h1>Payment: {props.selectedPaymentOption.name}</h1>
    )}
    {(props.selectedPaymentOption.description && props.selectedPaymentOption.description.length > 0) === true && (
      <span style={{ whiteSpace: 'pre-line' }}>{props.selectedPaymentOption.description}</span>
    ) */}

        <div style={{ textAlign: 'center' }}>
          <button
            className={'switch-button ' + (props.paymentChart === 'bank_balance' ? 'switch-button-active' : '')}
            style={{
              borderRadius: '10px 0 0 10px',
            }}
            onClick={() => {
              props.selectPaymentChart('bank_balance')
              this.handleshowInflation()
            }}
          >
            {translate('Cumulative')}
          </button>
          <button
            className={'switch-button ' + (props.paymentChart === 'cash_flows' ? 'switch-button-active' : '')}
            style={{
              borderRadius: '0 10px 10px 0',
            }}
            onClick={() => {
              props.selectPaymentChart('cash_flows')
              this.handleshowInflation()
            }}
          >
            {translate('Annual')}
          </button>
        </div>
        {props.fullCalcReady ? (
          <div>
            <div style={{ display: 'flex', flexDirection: props.layout === 1 ? 'column-reverse' : 'column' }}>
              <div style={{ display: 'flex' }}>
                <div style={{ display: 'flex', width: 12 }}>
                  <p
                    style={{
                      fontSize: 11,
                      textAlign: 'center',
                      writingMode: 'vertical-rl',
                      transform: 'rotate(180deg)',
                      margin: '0 0 0 0',
                      color: '#9a9a9a',
                    }}
                  >
                    {props.paymentChart === 'cash_flows' ? translate(cashFlowsLabel) : translate(cumulativeChartLabel)}
                  </p>
                </div>
                <CashFlowChart
                  myeStyles={props.myeStyles}
                  selectedSystem={props.selectedSystem}
                  systems={props.systems || []}
                  cashFlows={
                    props.paymentChart === 'cash_flows'
                      ? mergeFirstNumbersInArray(props.cashFlows[props.paymentChart])
                      : props.cashFlows[props.paymentChart].slice(1)
                  }
                  translate={translate}
                  chartType={props.paymentChart}
                  paybackYear={props.selectedPaymentOption.payback_year}
                  currencySymbol={props.currencySymbol}
                  paymentType={props.selectedPaymentOption.payment_type}
                  YAxisRange={props.YAxisRangeAllSystemsAndPaymentOptions[props.paymentChart]}
                  showInflation={this.state.showInflation}
                  hasPhoenix={props.hasPhoenix}
                  lockYAxisAcrossAllOptions={props.proposalTemplateSettings.lock_y_axis_across_all_options}
                />
              </div>
              {scalingFactorsLabel && this.state.showInflation && (
                <div style={{ position: 'relative', textAlign: 'center', marginLeft: 50 }}>
                  <div
                    style={{
                      marginRight: 10,
                      width: 10,
                      height: 10,
                      display: 'inline-block',
                      backgroundColor: '#dddddd',
                    }}
                  />
                  <div
                    style={{
                      marginRight: 20,
                      fontSize: 10,
                      display: 'inline-block',
                    }}
                    className="small"
                  >
                    {scalingFactorsLabel}
                  </div>
                </div>
              )}
            </div>
            {props.sectionsData.map((FinancialBenefitSection) => {
              switch (FinancialBenefitSection) {
                case 'financial_benefits_detailed':
                  return (
                    <div key={'financial_benefits_detailed'}>
                      <p style={{ textAlign: 'center', marginTop: 0, fontSize: 12 }}>
                        <a
                          className="EnergyConsumptionButtons"
                          onClick={() => props.showDialog('FinancialDetail')}
                          style={{ textDecorationLine: 'underline', cursor: 'pointer' }}
                        >
                          {translate('Detailed Annual Financial Table')}
                        </a>
                      </p>
                    </div>
                  )
                default:
                  return props.getDefaultSection(
                    props.sectionsData,
                    FinancialBenefitSection,
                    'financial_benefits',
                    props.proposalTemplateSettings
                  )
              }
            })}
            {props.hasPhoenix && (
              <p className="small">
                Estimated cumulative savings and annual savings are based on the following assumptions: 1) Your Solar PV
                system will continue to perform each year as in year one, less any annual degradation factor, 2) Your
                utility tariff rate for imported electricity will remain at the rate assumed for this proposal and will
                be subject to a forecast {props.selectedSystem?.bills?.current?.utility_inflation_annual || '5'}% annual
                inflation (Source: Office for National Statistics
                https://www.ons.gov.uk/economy/inflationandpriceindices/timeseries/d7i7/mm23). Please remember these
                forecast electricity savings outlined above are estimates and are not guaranteed in any way
              </p>
            )}
          </div>
        ) : (
          <div style={{ position: 'relative', height: 300, width: 50, margin: '20px auto 0 auto' }}>
            <CircularProgress
              size={40}
              top={130}
              left={0}
              status="loading"
              style={{
                display: 'inline-block',
                position: 'absolute',
                top: '50%',
                transform: 'translateY(-50%)',
                animation: 'none',
              }}
            />
          </div>
        )}
      </div>
    )
  }
}

export default withTranslate(FinancialBenefits)
