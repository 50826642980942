import { Drawer, Grid, Typography } from '@material-ui/core'
import { orgSelectors } from 'ducks/orgs'
import { useGlobalCart } from 'hooks/useGlobalCart'
import { useMapOrderLineItemsToHashMap } from 'hooks/useMapOrderLineItemsToHashMap'
import type LineItemType from 'pages/ordering/OrderLineItem'
import ProjectOrderPresenter from 'pages/ordering/ProjectOrderPresenter/projectOrderPresenter'
import { HardwareSupplierFilterKeyType } from 'pages/ordering/type'
import { getOrderCost } from 'pages/ordering/utils'
import BomComponentDetail from 'projectSections/sections/design/sideDrawer/bom3/BomComponentDetail'
import { useEffect, useMemo, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { setToggleGlobalCart } from 'reducer/designer/view'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { RootState } from 'types/state'
import { currencySymbolForCountry, formatCurrencyWithSymbol, isMountingComponent } from 'util/misc'
import { useFeatureFlag } from 'util/split'
import Button from './button/Button'
import useFetchComponentsData from './hardwareSelectorV2/fetch/useFetchComponentsData'
import { ComponentLikeType } from './hardwareSelectorV2/types'
import { ComponentTypes } from '../types/selectComponent'

const useStyles = makeOpenSolarStyles((theme) => ({
  drawer: {
    width: 450,
  },
  drawerPaper: {
    top: 51,
    zIndex: 1499,
    minWidth: 450,
    maxWidth: 450,
  },
  container: {
    height: 'calc(100% - 51px)',
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  content: {
    flex: 1,
    padding: 24,
    overflow: 'auto',
    flexShrink: 1,
  },
  footer: {
    padding: 24,
    borderTop: `1px solid ${theme.palette.divider}`,
  },
}))

const GlobalCartWrapper = () => {
  const enableMultiDistributorShop = useFeatureFlag('enable_multi_distributor_shop', 'on')

  if (!enableMultiDistributorShop) return null

  return <GlobalCart />
}

const GlobalCart = () => {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()
  const globalCartToggle = useSelector((state: RootState) => state.designer.view.globalCartToggle)
  const currencySymbol = useSelector((state: any) => {
    return currencySymbolForCountry(orgSelectors.getOrgIso2(state))
  })
  const [componentsData, setComponentsData] = useState<ComponentLikeType[]>([])
  const [componentType, setComponentType] = useState<ComponentTypes | 'mounting'>('module')

  const { orderableLineItems, changeLineItemSupplier, removeLineItem, updateLineItemQty } = useGlobalCart()
  const previousOrderableLineItemsRef = useRef(orderableLineItems)
  const { fetchComponentsData } = useFetchComponentsData()
  const lineItemsHashMap = useMapOrderLineItemsToHashMap(orderableLineItems)

  useEffect(() => {
    if (orderableLineItems?.length > 0) {
      const addedItems = orderableLineItems.filter((currentItem) => {
        const prevItem = previousOrderableLineItemsRef.current.find(
          (item) => item.code === currentItem.code
        )
        return (
          !prevItem || (prevItem.quantity < currentItem.quantity)
        )
      })
      if (addedItems.length > 0) {
        setComponentType(
          isMountingComponent(addedItems[0]?.componentTypeV2)
            ? 'mounting'
            : addedItems[0].componentType || 'module'
        )
      }
      const codes = orderableLineItems.map((lineItem) => lineItem.code)
      fetchComponentsData({ parameters: { codes }, onReady: (data) => setComponentsData(data) })
    }
    previousOrderableLineItemsRef.current = orderableLineItems
  }, [orderableLineItems])

  const [availableLineItems, unavailableLineItems] = useMemo(() => {
    const availableLineItems: LineItemType[] = []
    const unavailableLineItems: LineItemType[] = []
    orderableLineItems.forEach((lineItem: LineItemType) => {
      const stockLevelStatus = ProjectOrderPresenter.getStockLevelStatusFromLineItem(lineItem)
      if (stockLevelStatus !== 'not_available') {
        availableLineItems.push(lineItem)
      } else {
        unavailableLineItems.push(lineItem)
      }
    })
    return [availableLineItems, unavailableLineItems]
  }, [orderableLineItems])

  const totalItems = useMemo(() => orderableLineItems.reduce((count, lineItem) => count + lineItem?.quantity, 0), [
    orderableLineItems,
  ])

  const cost = getOrderCost(availableLineItems, true)

  const handleUpdateSupplier = (
    selectedSupplier: HardwareSupplierFilterKeyType,
    code: string,
    price: number | undefined
  ) => {
    const componentData = componentsData?.find((data) => data.code === code)
    const orderingData = componentData?.ordering_v2?.find((data) => data.distributor === selectedSupplier)
    changeLineItemSupplier(orderingData, selectedSupplier, code)
  }

  const handleRemoveLineItem = (uuid: string) => {
    removeLineItem(uuid)
  }

  const onCheckout = () => {
    dispatch(setToggleGlobalCart(!globalCartToggle))
    history.push('/checkout')
  }

  return (
    <Drawer
      className={classes.drawer}
      variant="persistent"
      anchor="right"
      open={globalCartToggle}
      classes={{ paper: classes.drawerPaper }}
    >
      <div className={classes.container}>
        <div className={classes.content}>
          <Typography variant="h5" style={{ marginBottom: 24 }}>
            {`Your Cart (${totalItems} items)`}
          </Typography>
          <BomComponentDetail
            title={'Panels'}
            lineItems={lineItemsHashMap.module}
            componentType={'module'}
            location={'cart'}
            handleUpdateSupplier={handleUpdateSupplier}
            handleRemoveLineItem={handleRemoveLineItem}
            updateLineItemQty={updateLineItemQty}
            isExpanded={componentType === 'module'}
          />
          <BomComponentDetail
            title={'Mounting'}
            lineItems={lineItemsHashMap.mounting}
            componentType={'mounting'}
            location={'cart'}
            handleUpdateSupplier={handleUpdateSupplier}
            handleRemoveLineItem={handleRemoveLineItem}
            updateLineItemQty={updateLineItemQty}
            isExpanded={componentType === 'mounting'}
          />
          <BomComponentDetail
            title={'Inverters'}
            lineItems={lineItemsHashMap.inverter}
            componentType={'inverter'}
            location={'cart'}
            handleUpdateSupplier={handleUpdateSupplier}
            handleRemoveLineItem={handleRemoveLineItem}
            updateLineItemQty={updateLineItemQty}
            isExpanded={componentType === 'inverter'}
          />
          <BomComponentDetail
            title={'Batteries'}
            lineItems={lineItemsHashMap.battery}
            componentType={'battery'}
            location={'cart'}
            handleUpdateSupplier={handleUpdateSupplier}
            handleRemoveLineItem={handleRemoveLineItem}
            updateLineItemQty={updateLineItemQty}
            isExpanded={componentType === 'battery'}
          />
          <BomComponentDetail
            title={'Others'}
            lineItems={lineItemsHashMap.other}
            componentType={'other'}
            location={'cart'}
            handleUpdateSupplier={handleUpdateSupplier}
            handleRemoveLineItem={handleRemoveLineItem}
            updateLineItemQty={updateLineItemQty}
            isExpanded={componentType === 'other'}
          />
        </div>
        <div className={classes.footer}>
          <Grid container alignItems="center" justify="space-between">
            <Grid item>
              <Typography variant="subtitle2">{`Total (Excluding VAT)`}</Typography>
              <Typography variant="subtitle1">{formatCurrencyWithSymbol(cost, currencySymbol)}</Typography>
            </Grid>
            <Grid item>
              <Button color="primary" disabled={orderableLineItems?.length === 0} onClick={onCheckout}>
                {`Checkout (${totalItems} items)`}
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </Drawer>
  )
}

export default GlobalCartWrapper
