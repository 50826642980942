import { orderSelectors } from 'ducks/orderComponents'
import { orgSelectors } from 'ducks/orgs'
import { HardwareSupplierFilterKeyType } from 'pages/ordering/type'
import { memo, useContext } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, useLocation } from 'react-router-dom'
import { parseQueryStringToDictionary } from 'util/misc'
import PostCheckoutPage from '../PostCheckoutPage'
import OrderPage from './OrderPage'
import VtacCheckoutProvider, { OrderStateContext } from './VtacCheckoutProvider'

const Checkout = memo(() => {
  const location = useLocation()
  const params = parseQueryStringToDictionary(location.search)
  const lineItemsConfirmed = useSelector(orderSelectors.getOrderableLineItems)
  const { orderResult } = useContext(OrderStateContext)
  const orgCountry = useSelector(orgSelectors.getOrg)?.country.iso2
  const distributor: HardwareSupplierFilterKeyType = orgCountry === 'GB' ? 'vtac_uk' : 'vtac_pl'

  if (lineItemsConfirmed.length === 0) {
    return <Redirect to={'/shop'} />
  }

  if (params.step === 'post-order') {
    return <PostCheckoutPage distributor={distributor} quoteOrOrderType={'order'} orderId={orderResult?.order_id} />
  }

  return <OrderPage />
})

const VtacCheckout = () => {
  return (
    <VtacCheckoutProvider>
      <Checkout />
    </VtacCheckoutProvider>
  )
}

export default VtacCheckout
