import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { permissionsSelectors } from 'ducks/permissions'
import VirtualBoard from 'elements/dnd/board/VirtualBoard'
import { styled } from 'opensolar-ui'
import { useNotify } from 'ra-core'
import { useEffect, useMemo, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import { ProjectType } from 'types/projects'
import KanbanSkeleton from './KanbanSkeleton'
import ProjectCard from './ProjectCard'
import StageColumns from './StageColumns'

const PageWrapper = styled('div', { name: 'PageWrapper' })(({ theme }) => ({
  overflow: 'hidden',
  height: 'calc(100vh - 150px)',
  [theme.breakpoints.down('sm')]: {
    height: 'calc(100vh - 300px)',
  },
}))

const ScrollContainer = styled('div', { name: 'ScrollContainer' })({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  overflowX: 'auto',
})
export interface ProjectStageUpdateInitial {
  projectId: number
  stageId: number
  prevStageId: number
  doRequest: boolean
}
export interface ProjectStageUpdateFinal extends ProjectStageUpdateInitial {
  project: ProjectType
}
const restClientInstance = restClient(window.API_ROOT + '/api')
const KanbanView = (props) => {
  const { displayCols, viewFilters } = props
  const filterValues = viewFilters
  const notify = useNotify()
  const orgId = useSelector(authSelectors.getOrgId)
  const orgWorkflows = useSelector(orgSelectors.getWorkflows)
  const workflow = useMemo(() => orgWorkflows.find((x) => x.id === Number(filterValues?.workflow_id)), [
    orgWorkflows,
    filterValues?.workflow_id,
  ])

  const stages = useMemo(() => workflow?.workflow_stages, [workflow])
  const { allowEdit } = useSelector(permissionsSelectors.getPermissionByKey('manage_workflow_stages'))
  const columnsEnabled = useMemo(() => displayCols.filter((x) => !x.hideOnKanban).map((x) => x.id), [displayCols])

  const [stageUpdateInitial, setStageUpdateInitial] = useState<ProjectStageUpdateInitial | undefined>()
  const [stageUpdateFinal, setStageUpdateFinal] = useState<ProjectStageUpdateFinal | undefined>()

  const updateStage = (projectId, stageId, prevStageId) => {
    if (projectId && stageId) {
      restClientInstance('CUSTOM_PATCH', 'custom', {
        url: `orgs/${orgId}/projects/${projectId}/`,
        data: {
          active_stage_id: stageId,
        },
      })
        .then(() => {
          notify('ra.notification.updated', 'info')
        })
        .catch((e) => {
          notify(`Failed to update stage for project: ${projectId}`, 'error')
          // Revert change in FE
          setStageUpdateInitial({ projectId, prevStageId, stageId, doRequest: false })
        })
    }
  }

  useEffect(() => {
    if (stageUpdateFinal?.doRequest) {
      updateStage(stageUpdateFinal.projectId, stageUpdateFinal.stageId, stageUpdateFinal.prevStageId)
    }
  }, [stageUpdateFinal])

  const onDragComplete = (result) => {
    if (result?.draggableId) {
      const projectId = Number(result?.draggableId)
      const prevStageId = Number(result?.source?.droppableId)
      const stageId = Number(result?.destination?.droppableId)
      setStageUpdateInitial({ projectId, stageId, prevStageId, doRequest: true })
    }
  }
  if (!workflow || !stages?.length) return <KanbanSkeleton />
  return (
    <PageWrapper id="Project-List-Section">
      <ScrollContainer>
        <VirtualBoard
          columnRender={(render, isDragDisabled) => (
            <StageColumns
              isDragDisabled={isDragDisabled}
              renderCard={render}
              workflowStages={stages}
              filterValues={filterValues}
              updateFields={{
                stageUpdateInitial,
                setStageUpdateInitial,
                stageUpdateFinal,
                setStageUpdateFinal,
              }}
              imageEnabled={columnsEnabled.includes('site_photo')}
            />
          )}
          groups={{}}
          render={(item: ProjectType | undefined, index: number) =>
            item ? (
              <ProjectCard item={item} colsEnabled={columnsEnabled} />
            ) : (
              <Skeleton key={'empty-' + index} width={325} height={335} />
            )
          }
          onDragComplete={onDragComplete}
          // isDragDisabled={() => !allowEdit}
        />
      </ScrollContainer>
    </PageWrapper>
  )
}

export default KanbanView
