import { Grid } from '@material-ui/core'
import { permissionsSelectors } from 'ducks/permissions'
import ChipsInput from 'elements/input/ChipsInput'
import DateInput from 'elements/input/DateInput'
import { UiKey } from 'elements/UiKey'
import CustomFields from 'projectSections/sections/manage3/common/CustomFields'
import { FC } from 'react'
import { ReferenceArrayInput, SelectInput, TextInput } from 'react-admin'
import { useSelector } from 'react-redux'
import { GENDER_CHOICES } from 'resources/projects/choices'
import { dateParser } from 'util/misc'

import { ContactFormProps } from './ContactForms'
import { useContactFormStyles } from './useContactFormStyles'

export type ContactFieldsProps = Pick<ContactFormProps, 'id' | 'uiKey' | 'prefix' | 'contact'>

export const ContactFields: FC<ContactFieldsProps> = (props) => {
  const classes = useContactFormStyles()

  const prefix = props.prefix || ''

  const inputProps = { id: props.id ? props.id + '' : undefined, resource: 'contacts' }

  const { allowEdit: allowEditBasicInfo } = useSelector(
    permissionsSelectors.getPermissionByKey('info_contact_info_basic')
  )
  const { allowEdit: allowEditRestInfo } = useSelector(
    permissionsSelectors.getPermissionByKey('info_contact_info_full')
  )

  return (
    <Grid item xs={12}>
      <Grid container spacing={3}>
        <UiKey uiKey={props.uiKey + '.info_contact_info_basic'}>
          <>
            <UiKey uiKey={props.uiKey + '.info_contact_info_basic.first_name'}>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <TextInput
                  fullWidth
                  source={`${prefix}first_name`}
                  label="resources.contacts.fields.first_name"
                  disabled={!allowEditBasicInfo}
                  initialValue={props.contact ? props.contact.first_name : ''}
                  {...inputProps}
                />
              </Grid>
            </UiKey>
            <UiKey uiKey={props.uiKey + '.info_contact_info_basic.family_name'}>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <TextInput
                  fullWidth
                  source={`${prefix}family_name`}
                  label="resources.contacts.fields.family_name"
                  disabled={!allowEditBasicInfo}
                  initialValue={props.contact ? props.contact.family_name : ''}
                  {...inputProps}
                />
              </Grid>
            </UiKey>
            <UiKey uiKey={props.uiKey + '.info_contact_info_basic.phone'}>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <TextInput
                  fullWidth
                  source={`${prefix}phone`}
                  label="resources.contacts.fields.phone"
                  disabled={!allowEditBasicInfo}
                  initialValue={props.contact ? props.contact.phone : ''}
                  {...inputProps}
                />
              </Grid>
            </UiKey>
            <UiKey uiKey={props.uiKey + '.info_contact_info_basic.email'}>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <TextInput
                  fullWidth
                  source={`${prefix}email`}
                  label="resources.contacts.fields.email"
                  disabled={!allowEditBasicInfo}
                  initialValue={props.contact ? props.contact.email : ''}
                  {...inputProps}
                />
              </Grid>
            </UiKey>
          </>
        </UiKey>
        <UiKey uiKey={props.uiKey + '.info_contact_info_full'}>
          <>
            <UiKey uiKey={props.uiKey + '.info_contact_info_full.middle_name'}>
              <Grid item xs={12} md={6} lg={4} xl={3}>
                <TextInput
                  fullWidth
                  source={`${prefix}middle_name`}
                  label="resources.contacts.fields.middle_name"
                  disabled={!allowEditRestInfo}
                  {...inputProps}
                />
              </Grid>
            </UiKey>

            <UiKey uiKey={props.uiKey + '.info_contact_info_full.integrations'}>
              <>
                <UiKey uiKey={props.uiKey + '.info_contact_info_full.integrations.passport_number'}>
                  <Grid item xs={12} md={6} lg={4} xl={3}>
                    <TextInput
                      fullWidth
                      source={`${prefix}passport_number`}
                      label="resources.contacts.fields.passport_number"
                      disabled={!allowEditRestInfo}
                      {...inputProps}
                    />
                  </Grid>
                </UiKey>
                <UiKey uiKey={props.uiKey + '.info_contact_info_full.integrations.licence_number'}>
                  <Grid item xs={12} md={6} lg={4} xl={3}>
                    <TextInput
                      fullWidth
                      source={`${prefix}licence_number`}
                      label="resources.contacts.fields.licence_number"
                      disabled={!allowEditRestInfo}
                      {...inputProps}
                    />
                  </Grid>
                </UiKey>
                <UiKey uiKey={props.uiKey + '.info_contact_info_full.integrations.gender'}>
                  <Grid item xs={12} md={6} lg={4} xl={3}>
                    <SelectInput
                      fullWidth
                      source={`${prefix}gender`}
                      choices={GENDER_CHOICES}
                      label="resources.contacts.fields.gender"
                      disabled={!allowEditRestInfo}
                      className={classes.selectInput}
                      {...inputProps}
                    />
                  </Grid>
                </UiKey>
                <UiKey uiKey={props.uiKey + '.info_contact_info_full.integrations.date_of_birth'}>
                  <Grid item xs={12} md={6} lg={4} xl={3}>
                    <DateInput
                      source={`${prefix}date_of_birth`}
                      parse={dateParser}
                      label="resources.contacts.fields.date_of_birth"
                      disabled={!allowEditRestInfo}
                      className={classes.dateInput}
                      {...inputProps}
                    />
                  </Grid>
                </UiKey>
              </>
            </UiKey>
          </>
        </UiKey>
        <UiKey uiKey={props.uiKey + '.linked_projects'}>
          <Grid item xs={12} md={6} className={classes.projectsInputContainer}>
            {
              // @ts-ignore
              <ReferenceArrayInput
                source="projects"
                reference="projects"
                optionValueField="url"
                allowEmpty
                fullWidth
                {...inputProps}
              >
                {
                  // @ts-ignore
                  <ChipsInput fullWidth source="address" optionText="title" optionValue="url" />
                }
              </ReferenceArrayInput>
            }
          </Grid>
        </UiKey>
        <UiKey uiKey={props.uiKey + '.custom_fields'}>
          <Grid item xs={12}>
            <CustomFields model={'Contact'} source={prefix} />
          </Grid>
        </UiKey>
      </Grid>
    </Grid>
  )
}
