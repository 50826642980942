import { styled } from 'opensolar-ui'
import { FC } from 'react'
import Skeleton from 'react-loading-skeleton'

const Container = styled('div', { name: 'Container' })({
  display: 'flex',
  flexDirection: 'column',
  gap: 25,
  width: '100%',
})
const RowWrapper = styled('div', { name: 'RowWrapper' })({
  display: 'flex',
  alignItems: 'center',
  gap: 20,
})

const SkeletonWrapper = styled('div', { name: 'SkeletonWrapper' })({
  width: '100%',
})

const pageBlocks = [
  {
    width: [400],
    height: 25,
  },
  {
    width: Array(10).fill(130),
    height: 25,
  },
  {
    width: [400, 200, 200],
    height: 35,
  },
  {
    width: '100%',
    height: 650,
  },
]

const ProjectsSkeleton: FC = () => {
  return (
    <Container>
      {pageBlocks.map((block) => {
        if (Array.isArray(block.width)) {
          return (
            <RowWrapper>
              {block.width.map((x) => (
                <div style={{ width: x, height: block.height }}>
                  <Skeleton height={block.height} />
                </div>
              ))}
            </RowWrapper>
          )
        }
        return (
          <SkeletonWrapper style={{ height: block.height }}>
            <Skeleton height={block.height} />
          </SkeletonWrapper>
        )
      })}
    </Container>
  )
}
export default ProjectsSkeleton
