import type { PERFORMANCE_CALCULATOR_ID } from 'constants/calculators'
import { StdFieldErrorType } from 'reducer/project/projectErrorsReducer'
import type { BillType } from 'types/bills'
import type { ExternalDataType } from 'types/components'
import type { IntegrationData, MountingID, MountingSystemType, PartnerAccessoriesID } from 'types/mounting'
import type { OtherComponentTypes } from 'types/otherComponent'
import type { PaymentOptionDataType } from 'types/paymentOptions'
import type { PricingDataType } from 'types/pricing'
import type { ComponentTypes, ComponentTypesV2 } from 'types/selectComponent'
import type {
  MilestonePaymentOverridesMapType,
  PaymentOptionSettingsOverridesType,
  SwedenGreenIncentiveDataType,
} from 'types/systems'

export interface StudioItem<T = unknown> {
  type: string
  uuid: string
  code: string
  applyUserData(): void
  userData: T
  manufacturer_name: string
  getSystem(): StudioSystemType
}
export interface StudioComponentItem<T = unknown, C = unknown> extends StudioItem<T> {
  getComponentData(): C
  code: string
}

declare global {
  class OsInverter {
    constructor(options: { inverter_id: number })
  }

  class OsBattery {
    constructor(options: { battery_id: number })
  }

  class OsString {
    constructor()
  }

  class OsMppt {
    constructor()
  }

  class OsSystem {
    static getComponentTypeFromSlotKey(slotKey: string): ComponentTypesV2
  }
}

type CustomData = {
  [key: string]: any
}

export type LightReachAddersType = {
  backupBatteryCost?: number
  arbitrageBatteryIncluded?: boolean
  backupBatteryIncluded?: boolean
  backupBatteryKwhIncluded?: boolean
  electricalUpgradeIncluded?: boolean
}
export type LightReachIntegrationJson = {
  account_id?: string
  panelManufacturer?: string | undefined
  panelModel?: string | undefined
  storageManufacturer?: string | undefined
  storageModel?: string | undefined
  inverterManufacturer?: string | undefined
  inverterModel?: string | undefined
  mountingManufacturer?: string | undefined
  salesperson_license_number?: string | undefined
  prepricing_errors?: string[]
  adders?: LightReachAddersType
}

export type MountingIntegrationJson = {
  [key in MountingID]?: IntegrationData
}

export type IntegrationJsonType = MountingIntegrationJson & { lightreach?: LightReachIntegrationJson }

declare global {
  class Adder {
    constructor(adder_data)
    static addToSystem(system: StudioSystemType, adder: Adder): void
  }
}
export interface AdderData {
  adder_id: number
  type: number
  label: string
  value: number
  cost_value: number
  formula: any
  tax_included: number
  show_customer: boolean
  value_includes_tax: boolean
  quantity: number
  unit_label: string | null
  dealer_fee_interaction_type: number
  price_treatment: number
  show_customer_cost: boolean
}

interface OrgSharedTime {
  shared_time: string
  from_org_name: string
  to_org_name: string
}

export interface AdderRecord {
  id: number
  title: string
  url: string
  auto_apply_enabled: boolean
  auto_apply_only_specified_states: string | null
  auto_apply_only_specified_zips: string | null
  auto_apply_component_codes: string | null
  auto_apply_min_system_size: string | null
  auto_apply_max_system_size: string | null
  auto_apply_sector: number
  type: 0 | 1 | 2
  value: number
  formula: 0 | 1 | 2
  tax_included: number
  show_customer: boolean
  cost_value: number
  value_includes_tax: boolean
  unit_label: null
  dealer_fee_interaction_type: number
  price_treatment: number
  show_customer_cost: boolean
  share_with_orgs: string[]
  org: string
  org_id: number
  org_shared_time: OrgSharedTime[]
}

export type ShadeMetricsDataType =
  | { sun_access_factor: number }
  | ({
      items: {
        [key: string]: ShadeMetricsLikeType
      }
    } & ShadeMetricsLikeType)

export interface ShadeMetricsLikeType {
  sun_access_factor: number
  tilt_orientation_factor: number
  total_poa: number
  total_poa_beam: number
  total_poa_beam_shaded: number
  total_poa_diffuse: number
  total_poa_optimal: number
  total_poa_shaded: number
  total_solar_resource_fraction: number
}

export interface StudioSystemType extends StudioItem<StudioSystemDataType> {
  partner_accessories_input: any
  output?: {
    annual: number
    hashed_args: string | undefined
    calculation_errors: StdFieldErrorType[] | []
    shade_metrics?: ShadeMetricsDataType
  }
  is_current: boolean
  system_lifetime: number
  system_efficiency?: number
  system_efficiency_override?: number
  is_battery_dc_connected?: boolean
  is_battery_dc_connected_auto?: boolean
  calculator: PERFORMANCE_CALCULATOR_ID
  moduleId: number
  moduleType(): ModuleType
  moduleGrids(): ModuleGridType[]
  getModules(): StudioModuleType[]
  children: ModuleGridType[]
  mounting?: MountingID
  mounting_type?: MountingSystemType
  partner_accessories?: PartnerAccessoriesID[]
  integration_json?: IntegrationJsonType
  reloadModuleSpecs(): void
  inverters(): StudioInverterType[]
  batteries: () => StudioBatteryType[]
  others: () => StudioOtherType[]
  dcOptimizer(otherId?: number): StudioOtherType
  milestone_payment_overrides?: MilestonePaymentOverridesMapType
  force_enable_cashflow?: boolean
  paymentOptionsData: PaymentOptionDataType[]
  payment_options: PaymentOptionDataType[]
  payment_options_override: number[]
  payment_options_settings_overrides: PaymentOptionSettingsOverridesType
  pricing: PricingDataType
  title: string
  name: string
  moduleQuantity(): number
  lender_battery_price_override?: number
  load_offsettable_by_battery_fraction?: unknown
  load_offsettable_by_battery_cap?: unknown
  non_solar_price_included?: number
  show_customer: boolean
  battery_control_scheme: string | null
  consumption?: {
    consumption_offset_percentage: number
    grid_independence_percentage_no_battery: number
    self_consumption_percentage_no_battery: number
    usage_annual_proposed?: number
    grid_independence_percentage_with_battery: number
  }
  line_items: AdderData[]

  getName(): string
  kwStc(): number
  batteryTotalKwh(): number
  refreshComponentCostOverrides(bomLineItems): void
  compatibleBatteryCodes(): string[]
  compatibleChargerCodes(): string[]
  clearHashedArgs: () => void

  hasBuildablePanels: () => boolean
  allActivePanelsAreBuildable: () => boolean
  setAllPanelsAsBuildable: () => void
  clearBuildablePanels: () => void
  refreshDesignComponentSpecs(): void
  isSpecsReloadRequired(): boolean

  componentCodes(): string[]

  autoSync?: {
    pricingScheme: boolean
    costing: boolean
    adders: boolean
    commission: boolean
  }

  getComponentsForSlot(slotKey: string): ComponentType[]
  bills: {
    current: BillType
    proposed: Record<string, BillType>
  }
  order: number
  override_price_locking: boolean
  custom_data: CustomData

  inverterRange: string
  unstrungModulesInverterEfficiency: string
  hasIncompleteStringing: Function

  //TODO: should be made private at some point
  assessSlots(): void
  sweden_green_deduction_incentive: SwedenGreenIncentiveDataType
  refreshUserData(): any

  getSystemPanelPlacement(): 'Roof' | 'Ground' | 'Roof & Ground'
  mcs_self_consumption_calculator_override?: number
}

export interface StudioSystemDataType {
  shadingByPanelGroup: PanelGroupShading[]
  payment_options: PaymentOptionDataType[]
  slots: any[]
  id: number
  site: {
    is_commercial: boolean
    longitude: number
    latitude: number
    timezoneOffset: number
    zip: string
    country: string
  }
  thumbnail?: string // base64 encoded image

  incentive_to_installer: IncentiveStudioTotal[]
  incentive_to_customer: IncentiveStudioTotal[]
}

export type IncentiveStudioTotal = {
  incentives: IncentiveStudioItem[]
  total: number
  new?: boolean // emphemeral flag to indicate new incentives, gets stripped out before saving
}

// also see: spa/app/src/resources/incentives/IncentiveConstants.ts
export type IncentiveStudioItem = {
  incentive_id: number | null
  incentive_type: number
  title: string
  inc_tax: number
  settings: {
    value: number
    price_based_incentive_type:
      | 'price_including_tax'
      | 'price_excluding_tax'
      | 'price_payable_including_tax'
      | 'price_payable_excluding_tax'
  }
}

export interface PanelGroupShading {
  uuid: string
  slope: number
  azimuth: number
  beam_access: number[]
  module_quantity: number
}
export interface StudioModuleDataType {}
export interface StudioModuleType extends StudioItem<StudioModuleDataType> {}

export interface StudioInverterType extends StudioComponentItem<StudioInverterDataType, InverterComponentType> {
  code: string
  current_isc_max: number
  inverter_id: number
  manufacturer_name: string
  microinverter: boolean
  mppt_voltage_max: number
  mppt_voltage_min: number
  voltage_max: number
  voltage_minimum: number
  inbuilt_dc_isolator: string
  mppts(): MpptType[]
  moduleQuantity(): number
  getPanelsPower(): number
  getSystem(): StudioSystemType
}
export interface StudioInverterDataType {
  microinverter: unknown
  string_isolation: 'seperately' | 'parallel'
}

export interface StudioBatteryType extends StudioComponentItem<unknown, BatteryComponentType> {
  battery_id: number
}
export interface StudioOtherType extends StudioComponentItem<unknown, OtherComponentType> {
  slotKey: string
  other_component_type?: OtherComponentTypes
  quantity: number
  external_data?: {
    lyra?: {
      support_status: boolean
      uuid?: string
    }
  }
}
export interface MpptType extends StudioItem {
  parent: StudioInverterType
  strings(): StringType[]
  moduleQuantity(): number
  getPanelsPower(): number
  getSystem(): StudioSystemType
}
export interface StringType extends StudioItem {
  moduleQuantity(): number
  getPanelsPower(): number
  getSystem(): StudioSystemType
  modules: any[]
}

export interface ComponentType {
  uuid: string
  code: string
  quantity: number
  component_type: ComponentTypes
  other_component_type?: OtherComponentTypes
  external_data: ExternalDataType | null
}
export interface OtherComponentType extends ComponentType {
  compatibleBatteryCodes?: () => string[]
  id: number
  other_id: unknown
  manufacturer_name: string
  show_customer: boolean
}
export interface InverterComponentType extends ComponentType {
  mppt_quantity: number
  compatibleBatteryCodes(): string[]
}
export interface BatteryComponentType extends ComponentType {
  compatibleChargerCodes(): string[]
}

export type PanelPlacement = 'roof' | 'ground'
export type ModuleLayout = 'portrait' | 'landscape'
export type PanelConfiguration = 'STANDARD' | 'TILT_RACK' | 'DUAL_TILT_RACK'
export enum ModuleGridAzimuthalSubsets {
  Front = 'FRONT',
  Back = 'BACK',
  FrontAndBack = 'FRONT_AND_BACK',
}

export type ModuleGridDimensions = {
  width: number
  height: number
  moduleCountX: number
  moduleCountY: number
  moduleSpanX: number
  moduleSpanY: number
}

export interface ModuleGridUserData {
  moduleLayout: ModuleLayout
  slope: number
  azimuth: number
}
export interface ModuleGridType extends StudioItem<ModuleGridUserData> {
  type: 'OsModuleGrid'
  getSystem(): StudioSystemType
  getPanelConfiguration(): PanelConfiguration
  getSlope(): number
  getAzimuth(): number
  getGroundClearance(): number
  getModules(): ModuleType[]
  moduleQuantity(): number
  moduleLayout(setTo?: ModuleLayout): ModuleLayout
  getBounds(): [number, number, number, number]
  getRows(): number
  getCols(): number
  getPanelTilt(): number
  modulesPerRow(value: number): void
  modulesPerCol(value: number): void
  modulesPerRow(): number
  modulesPerCol(): number
  moduleLayoutOffset(): boolean
  moduleLayoutOffset(value: boolean): void
  trackingMode(): string
  isUsingTiltRacks(): boolean
  calculateGroundCoverageRatio(): number
  getDimensions(): ModuleGridDimensions

  cellsActive: string[]
  name: string
  quantity: number
  cost: number
  slopeAuto: boolean
  azimuthAuto: boolean
  elevationAuto: boolean
  facet: RoofFacetType
  diffuseShading: any
  shadingOverride: any
  panelTiltOverride: number | null
  panelPlacement: 'ground' | 'roof'

  moduleSpacing: [number, number]
  groupSpacing: [number, number]
  moduleSpacingApplied: [number, number]
  groupSpacingApplied: [number, number]

  hasBuildablePanels: () => boolean
  setAllPanelsAsBuildable: () => void
  clearBuildablePanels: () => void
}

export interface RoofFacetType {}
