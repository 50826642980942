import { orgSelectors } from 'ducks/orgs'
import CheckboxMultiSelectInput from 'elements/input/CheckboxMultiSelectInput'
import DateTimeInput from 'elements/input/DateTimeInput'
import { styled } from 'opensolar-ui'
import React, { useEffect, useMemo, useState } from 'react'
import { SelectInput, TextInput } from 'react-admin'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import { getModelByFieldName } from 'resources/customFields/PredefinedModels'
import { validateDateTimeField, validateMultiSelectField, validateSelectField } from 'resources/customFields/validation'
import { CustomFieldType, CustomFieldVariants } from 'types/orgs'
import StyledDateInput from './StyledDateInput'

type ModelType = 'Project' | 'Contact' | 'COGS'

interface PropTypes {
  model: ModelType
  source?: string
  inputClass?: string
  inputVariant?: 'outlined' | 'standard' | 'filled'
  disabled?: boolean
  shrinkLabel?: boolean
}

const FullWidthSelect = styled(SelectInput)({ width: '100%' })
export const getCustomFields = (customFields: CustomFieldType[] | undefined, model: ModelType) => {
  return customFields
    ? customFields.filter((config: CustomFieldType) => getModelByFieldName(config.field) === model)
    : []
}
const CustomFields: React.FC<PropTypes> = (props) => {
  const org = useSelector(orgSelectors.getOrg)
  const [isSafeToRender, setIsSafeToRender] = useState(false)
  const customFields = useMemo(() => getCustomFields(org?.custom_fields, props.model), [])
  const form = useForm()
  useEffect(() => {
    const formValues = form.getState().values
    //handle field type changed
    form.batch(() => {
      customFields.forEach((config) => {
        //@ts-ignore
        const value = formValues[config.field]
        let hasInvalidValue = false
        switch (config.field_type) {
          case CustomFieldVariants.text:
            break
          case CustomFieldVariants.dropdown:
            if (validateSelectField(value, config.select_field_choices?.split(',') || [])) {
              hasInvalidValue = true
            }
            break
          case CustomFieldVariants.dateTime:
            if (validateDateTimeField(value)) {
              hasInvalidValue = true
            }
            break
          case CustomFieldVariants.date:
            if (validateDateTimeField(value)) {
              hasInvalidValue = true
            }
            break
          case CustomFieldVariants.multiSelect:
            if (validateMultiSelectField(value, config.select_field_choices?.split(',') || [])) {
              hasInvalidValue = true
            }
            break
          default:
            break
        }

        if (hasInvalidValue) {
          form.change(config.field, '')
        }
      })
    })
    setIsSafeToRender(true)
  }, [])
  return (
    <>
      {isSafeToRender &&
        customFields?.map((config: CustomFieldType, i) => {
          return <RenderField key={'field-' + i} config={config} {...props} />
        })}
    </>
  )
}

interface FieldProps extends PropTypes {
  config: CustomFieldType
}

const RenderField: React.FC<FieldProps> = ({ config, source, inputClass, inputVariant, disabled, shrinkLabel }) => {
  const fieldSource = useMemo(() => (source ? `${source}.${config.field}` : config.field), [source, config.field])
  const selectChoices = useMemo(
    () =>
      (config.select_field_choices?.split(',') || []).map((value: string) => ({
        id: value,
        name: value,
      })),
    [config.select_field_choices]
  )

  switch (config.field_type) {
    case CustomFieldVariants.text:
      return (
        <TextInput
          fullWidth
          margin="normal"
          source={fieldSource}
          variant={inputVariant}
          label={config.label}
          className={inputClass}
          disabled={disabled}
          options={{ InputLabelProps: { shrink: shrinkLabel } }}
        />
      )
    case CustomFieldVariants.dropdown:
      return (
        <FullWidthSelect
          variant={inputVariant}
          source={fieldSource}
          className={inputClass}
          label={config.label}
          choices={selectChoices}
          disabled={disabled}
          options={{ InputLabelProps: { shrink: shrinkLabel } }}
          allowEmpty
        />
      )
    case CustomFieldVariants.dateTime:
      return (
        // @ts-ignore
        <DateTimeInput
          source={fieldSource}
          options={{
            inputVariant: inputVariant,
            InputLabelProps: { shrink: shrinkLabel },
          }}
          label={config.label}
          disabled={disabled}
          fullWidth
        />
      )
    case CustomFieldVariants.date:
      return (
        <StyledDateInput
          inputVariant={inputVariant}
          source={fieldSource}
          label={config.label}
          disabled={disabled}
          options={{ InputLabelProps: { shrink: shrinkLabel } }}
        />
      )
    case CustomFieldVariants.multiSelect:
      return (
        <CheckboxMultiSelectInput
          source={fieldSource}
          choices={selectChoices}
          className={inputClass}
          variant={inputVariant}
          label={config.label}
          style={{ width: '100%' }}
          color={'secondary'}
          disabled={disabled}
        />
      )
    default:
      return (
        <TextInput
          source={`${source}.${config.field}`}
          variant={inputVariant}
          label={config.label}
          className={inputClass}
          disabled={disabled}
        />
      )
  }
}
export default CustomFields
