import Tooltip from 'elements/tooltip/Tooltip'
import { Button, styled } from 'opensolar-ui'
import React, { ReactNode } from 'react'
import { BlackHeader, CustomWrapper, useStyles } from './styles'

interface PropTypes {
  title: string
  description: ReactNode
  WarningMsgComponent?: ReactNode
  tooltip?: string
  btnLabel: string
  onClick: () => void
  logo?: ReactNode
  disabled?: boolean
}

const IntegrationDesc = styled('p', { name: 'IntegrationDesc' })(({ theme }) => ({
  fontSize: 12,
  marginTop: 8,
  color: theme.palette.grey[810],
  lineHeight: '18px',
}))

const IconWrapper = styled('div', { name: 'IconWrapper' })(({ theme }) => ({
  marginLeft: 'auto',
  border: theme.mixins.borderStandard.border,
  borderRadius: 6,
  padding: 3,
  alignSelf: 'flex-start',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const ActionButton = styled(Button, { name: 'ActionButton' })<{
  disabled: boolean
}>(({ disabled, theme }) =>
  disabled
    ? {
        backgroundColor: `${theme.palette.secondary.dark} !important`,
        color: `${theme.palette.grey[350]} !important`,
      }
    : { backgroundColor: theme.palette.blue[600] + ' !important', color: '#fff !important' }
)

const IntegrationRow: React.FC<PropTypes> = ({
  title,
  description,
  btnLabel,
  onClick,
  logo,
  disabled,
  WarningMsgComponent,
  tooltip,
}) => {
  const classes = useStyles()

  return (
    <CustomWrapper>
      <div className={classes.innerWrapper}>
        <div className={classes.detailWrapper}>
          <BlackHeader>{title}</BlackHeader>
          <IntegrationDesc>{description}</IntegrationDesc>
        </div>
        {logo && <IconWrapper>{logo}</IconWrapper>}
      </div>
      <div>
        {WarningMsgComponent}
        <Tooltip title={tooltip} color={'secondary'}>
          <span>
            <ActionButton disabled={!!disabled} onClick={onClick}>
              {btnLabel}
            </ActionButton>
          </span>
        </Tooltip>
      </div>
    </CustomWrapper>
  )
}

export default IntegrationRow
