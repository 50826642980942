var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { styled } from '../styles/styled';
var Wrapper = styled('div')(function (_a) {
    var theme = _a.theme, textColor = _a.textColor;
    return {
        color: textColor,
        display: 'block',
        textAlign: 'center',
        margin: '15px 0px 15px 0px',
    };
});
var Label = styled('span')(function (_a) {
    var theme = _a.theme;
    return {
        backgroundColor: '#ffffff',
        padding: 10,
    };
});
var Break = styled('hr')(function (_a) {
    var theme = _a.theme;
    return {
        marginTop: -7,
        borderBottom: 'none',
        borderTop: '1px solid #d3d3d3',
    };
});
export var Divider = function (_a) {
    var textColor = _a.textColor, label = _a.label;
    return (_jsxs(Wrapper, __assign({ textColor: textColor }, { children: [!!label && _jsx(Label, { children: label }), _jsx(Break, {})] })));
};
