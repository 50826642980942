import { logAmplitudeEvent } from 'amplitude/amplitude'
import { getAvailableActions } from 'ducks/myEnergy'
import type { CheckoutElementDataType, PhoenixStatusType } from 'opensolar-checkout'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import restClient from 'restClient'
import { ProposalDataType } from 'types/proposals'
const STATUSES_TO_POLL_FOR = ['created', 'sent', 'Pending']
const MAX_POLL_COUNT = 20

const restClientInstance = restClient(window.API_ROOT + '/api')

const usePhoenixStatus = ({
  elementsData,
  proposalData,
}: {
  elementsData: CheckoutElementDataType[]
  proposalData: ProposalDataType
}): PhoenixStatusType => {
  const [currentStatus, setCurrentStatus] = useState<PhoenixStatusType>(undefined)
  const [pollCount, setPollCount] = useState<number>(0)
  const dispatch = useDispatch()
  const checkPhoenixStatus = useMemo(() => {
    return elementsData.some(
      (elementsData) =>
        (elementsData.type === 'LoanApplication' && elementsData.actionData.payment_method === 'phoenix_application') ||
        elementsData.type === 'PhoenixApplication'
    )
  }, [elementsData])

  const available_customer_actions = proposalData.selectedProject.available_customer_actions
  useEffect(() => {
    if (available_customer_actions && available_customer_actions.length > 0) {
      let phoenixAction = available_customer_actions[0]?.actions_available?.find(
        (act) => act.payment_method === 'phoenix_application'
      )
      let phoenixStatusTitle = phoenixAction?.status_title as PhoenixStatusType
      setCurrentStatus(phoenixStatusTitle)
    }
  }, [available_customer_actions])

  useEffect(() => {
    if (!checkPhoenixStatus) {
      return
    }

    if (currentStatus && STATUSES_TO_POLL_FOR.includes(currentStatus) && pollCount < MAX_POLL_COUNT) {
      doPoll(pollCount)
    }
  }, [currentStatus, pollCount, checkPhoenixStatus])

  const doPoll = (currentPollCount: number) => {
    const orgId = proposalData.selectedPaymentOption?.org_id
    const projectId = proposalData.selectedProject.id
    const systemUuid = proposalData.selectedSystem.uuid
    const paymentOptionId = proposalData.selectedPaymentOption.id
    if (!checkPhoenixStatus) return
    if (orgId === undefined) return
    // if this is the first poll we only wait 10 seconds. Just in case they're submitted their app and are coming back to the proposal before our scheduled job updated things
    // then we wait 3 minutes before the second poll. If we didn't get an update on the first poll it means they need to either submit their app or we're waiting for a decision.
    // neither is likely to happen soon
    // after that 3 minute wait we move to 20 second increments in the hopes we can get a decision once they are done with their application
    let waitMs = 1000 * 10
    if (currentPollCount === 1) waitMs = 1000 * 60 * 3
    else waitMs = 1000 * 20

    setTimeout(() => {
      restClientInstance('CUSTOM_GET', 'custom', {
        url: `orgs/${orgId}/projects/${projectId}/phoenix/app_status/`,
      })
        .then((res) => {
          if (res.data.success) {
            if (res.data.decision === currentStatus && !STATUSES_TO_POLL_FOR.includes(res.data.decision))
              setPollCount(currentPollCount + 1)
            else {
              // decision changed, so the poll was successful and we need to refresh available actions to update the UI with the new decision
              setPollCount(MAX_POLL_COUNT + 1)
              setCurrentStatus(res.data.decision)
              setTimeout(() => {
                dispatch(getAvailableActions(orgId, projectId))
              }, 2000)
              logAmplitudeEvent('integrated_finance_application_submitted', {
                project_id: projectId,
                system_uuid: systemUuid,
                payment_option_id: paymentOptionId,
                decision: res.data.decision,
                integration: 'phoenix',
              })
            }
          }
        })
        .catch((err) => {
          setPollCount(MAX_POLL_COUNT + 1)
        })
    }, waitMs)
  }

  return undefined
}

export default usePhoenixStatus
