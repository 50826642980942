import { styled } from 'opensolar-ui'
import React from 'react'

const InputWrapper = styled('div', { name: 'InputWrapper' })({
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  '& .OSUI-OutlinedInput-notchedOutline': {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  '&>:last-child': {
    '& .OSUI-OutlinedInput-notchedOutline': {
      borderTopRightRadius: 8,
      borderBottomRightRadius: 8,
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
    },
  },
})

const GroupedInput: React.FC = ({ children }) => {
  return <InputWrapper>{children}</InputWrapper>
}

export default GroupedInput
