import { Accordion, AccordionDetails, Grid } from '@material-ui/core'
import { orgSelectors } from 'ducks/orgs'
import { getSupplierConfig } from 'pages/ordering/configs'
import type { HardwareSupplierFilterKeyType, SystemDesignType } from 'pages/ordering/type'
import React, { Suspense } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'
import { useFeatureFlag } from 'util/split'
import OrderPromoButtons from './OrderPromoButtons'

const LazyOrderPromoButtons = React.lazy(() => import('./OrderPromoButtons'))

const HardwarePromo = ({
  projectId,
  soldSystemId,
  systems,
  simplified,
  onChangeSelectedDistributor,
  managePageSupplier,
}: {
  projectId?: number
  soldSystemId?: number
  systems?: SystemDesignType[]
  simplified?: boolean
  onChangeSelectedDistributor?: () => void
  managePageSupplier?: HardwareSupplierFilterKeyType
}) => {
  const enableHardwareOrdering = useFeatureFlag('hardware_ordering', 'on')
  const selectedSupplier = useSelector(orgSelectors.getSelectedHardwareSupplier)
  const supplierConfig = getSupplierConfig(selectedSupplier)

  if (!enableHardwareOrdering || supplierConfig == null || projectId == null || systems == null) return null

  const systemsToShow = soldSystemId == null ? systems : systems.filter((system) => system.id === soldSystemId)

  if (simplified) {
    return (
      <>
        {systemsToShow.length > 0 && (
          <OrderPromoButtons
            supplierName={supplierConfig.name}
            supplierLogo={supplierConfig.logoUrl}
            projectId={projectId}
            systems={systemsToShow}
            simplified={simplified}
            onChangeSelectedDistributor={onChangeSelectedDistributor}
            managePageSupplier={managePageSupplier}
          />
        )}
      </>
    )
  }

  return (
    <Accordion key="promo" expanded={true}>
      <AccordionDetails>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Suspense fallback={<Skeleton width="100%" height="40px" />}>
              {systemsToShow.length > 0 && (
                <LazyOrderPromoButtons
                  supplierName={supplierConfig.name}
                  supplierLogo={supplierConfig.logoUrl}
                  projectId={projectId}
                  systems={systemsToShow}
                  simplified={simplified}
                />
              )}
            </Suspense>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default HardwarePromo
