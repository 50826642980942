import { styled } from 'opensolar-ui'
import { SectionCardBottom, SectionCardTop } from '../styles'
import DetailFooter from './DetailFooter'
import DetailHeader from './DetailHeader'

const PaddedTop = styled(SectionCardTop, { name: 'PaddedTop' })({
  padding: 20,
  display: 'block',
})

const Wrapper = styled('div', { name: 'Wrapper' })({
  width: '100%',
})

const ProjectDetails = () => {
  return (
    <Wrapper id="Project-Details-Section">
      <PaddedTop>
        <DetailHeader />
      </PaddedTop>
      <SectionCardBottom>
        <DetailFooter />
      </SectionCardBottom>
    </Wrapper>
  )
}

export default ProjectDetails
