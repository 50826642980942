import { useState } from 'react'
import SunlightLoanApplication from '../../SunlightApplicationLoading'

export type SunlightLoanApplicationConfig = {
  type: 'prequal' | 'credit'
}

const useSunlightLoanApplication = () => {
  const [state, seState] = useState<
    { open: false; config: undefined } | { open: true; config: SunlightLoanApplicationConfig }
  >({
    open: false,
    config: undefined,
  })

  const openDialog = (config: SunlightLoanApplicationConfig) => {
    seState({
      open: true,
      config,
    })
  }

  const closeDialog = () => {
    if (state.open === false) {
      return
    }
    seState({
      open: false,
      config: undefined,
    })
  }

  const dialogNode = state.open ? <SunlightLoanApplication visible={true} type={state.config.type} /> : null
  return { isOpen: state.open, dialogNode, openDialog, closeDialog, type: 'SunlightLoanApplication' as const }
}

export default useSunlightLoanApplication
