export const FAKE_PROPOSAL_V2_SETTINGS = {
  version: 1,
  name: 'default',
  designConfig: {
    assets: [],
    styles: [
      { selectors: ['#io69j'], style: { padding: '10px' } },
      { selectors: ['proposal-background'], style: { 'background-color': '#F6F9FC' } },
      { selectors: ['full-width'], style: { width: '100%' } },
      { selectors: ['two-equal-columns__column'], style: { width: '50%' } },
      {
        selectors: ['#next-steps__stepper'],
        style: { 'align-items': 'center', display: 'flex', padding: '0px 100px' },
      },
      {
        selectors: ['#next-steps__cover'],
        style: {
          'align-items': 'center',
          display: 'flex',
          'flex-direction': 'column',
          gap: '64px',
          'justify-content': 'center',
          padding: '0px 32px',
        },
      },
      {
        selectors: ['cover-page-content-layout-container'],
        style: {
          display: 'flex',
          'flex-direction': 'column',
          'justify-content': 'center',
          'align-items': 'center',
          'box-sizing': 'border-box',
          height: '100%',
          'min-height': '100%',
          width: '100%',
        },
        group: 'cmp:CoverPage',
      },
      {
        selectors: ['cover-page-content-layout-cover-image-container'],
        style: { width: '100%', 'flex-basis': '50%', 'flex-shrink': '0' },
        group: 'cmp:CoverPage',
      },
      {
        selectors: ['cover-page-content-layout-content-container'],
        style: {
          width: '100%',
          'flex-basis': '50%',
          'flex-shrink': '0',
          display: 'flex',
          'flex-direction': 'column',
          'justify-content': 'center',
          'align-items': 'center',
        },
        group: 'cmp:CoverPage',
      },
      {
        selectors: [],
        selectorsAdd: '.cover-page-content-layout-content-container > *',
        style: {
          'flex-shrink': '0',
          'flex-grow': '1',
          display: 'flex',
          'justify-content': 'center',
          'align-items': 'center',
          width: '100%',
        },
        group: 'cmp:CoverPage',
      },
      {
        selectors: ['cover-page-content-customer'],
        style: { 'font-weight': '600', 'font-size': '36px', 'line-height': '60px' },
        group: 'cmp:CoverPage',
      },
      {
        selectors: ['cover-page-content-layout-address'],
        style: { 'font-weight': '400', 'font-size': '20px', 'line-height': '28px', color: 'rgb(98, 104, 110)' },
        group: 'cmp:CoverPage',
      },
      {
        selectors: ['cover-page-content-layout-business'],
        style: {
          display: 'flex',
          'flex-direction': 'row',
          'justify-content': 'center',
          'font-weight': '400',
          'font-size': '16px',
          'line-height': '20px',
          gap: '2px',
        },
        group: 'cmp:CoverPage',
      },
      {
        selectors: [],
        selectorsAdd: '.cover-page-content-layout-business > span',
        style: { 'margin-top': '0px', 'margin-right': '10px', 'margin-bottom': '0px', 'margin-left': '10px' },
        group: 'cmp:CoverPage',
      },
      {
        selectors: ['cover-page-content-layout-date'],
        style: { 'font-weight': '400', 'font-size': '16px', 'line-height': '20px', color: 'rgb(146, 154, 161)' },
        group: 'cmp:CoverPage',
      },
      { selectors: ['#isyzi'], style: { padding: '10px' } },
      { selectors: ['#ihude'], style: { padding: '10px' } },
      { selectors: ['#iw7xk'], style: { padding: '10px' } },
      { selectors: ['#i4704'], style: { padding: '10px' } },
      { selectors: ['#iph2d'], style: { padding: '10px' } },
      { selectors: ['#i512j'], style: { padding: '10px' } },
    ],
    views: [
      {
        type: 'custom-view',
        name: 'cover-page',
        design: {
          frames: [
            {
              component: {
                type: 'wrapper',
                badgable: false,
                stylable: [
                  'background',
                  'background-color',
                  'background-image',
                  'background-repeat',
                  'background-attachment',
                  'background-position',
                  'background-size',
                ],
                highlightable: false,
                selectable: false,
                components: [
                  {
                    tagName: 'fullscreen',
                    type: 'FullScreen',
                    components: [
                      {
                        tagName: 'div',
                        type: 'FullScreen',
                        classes: ['editor-editable-comp-fullscreen'],
                        components: [
                          {
                            tagName: 'custompagelayout',
                            type: 'CustomPageLayout',
                            components: [
                              {
                                tagName: 'defaultcoverpage',
                                type: 'DefaultCoverPage',
                                components: [
                                  {
                                    classes: ['cover-page__container', 'proposal-background'],
                                    components: [
                                      {
                                        type: 'image',
                                        resizable: { ratioDefault: 1 },
                                        classes: ['cover-page__image'],
                                        attributes: { alt: 'house-cover', src: '/house-cover.png' },
                                      },
                                      {
                                        classes: ['cover-page__texts'],
                                        components: [
                                          {
                                            classes: ['cover-page__text-container'],
                                            components: [
                                              {
                                                tagName: 'p',
                                                type: 'text',
                                                classes: ['cover-page__title'],
                                                components: [{ type: 'textnode', content: 'Michelle and Michael' }],
                                              },
                                              {
                                                tagName: 'p',
                                                type: 'text',
                                                classes: ['cover-page__address'],
                                                components: [{ type: 'textnode', content: '230 Valparaiso Ave' }],
                                              },
                                            ],
                                          },
                                          {
                                            classes: ['cover-page__text-container'],
                                            components: [
                                              {
                                                classes: ['cover-page__pro-info'],
                                                components: [
                                                  {
                                                    tagName: 'p',
                                                    type: 'text',
                                                    components: [
                                                      { type: 'textnode', content: 'Your solar pro: Ray Light' },
                                                    ],
                                                  },
                                                  {
                                                    tagName: 'span',
                                                    type: 'text',
                                                    classes: ['cover-page__pro-info__divider'],
                                                    components: [{ type: 'textnode', content: '|' }],
                                                  },
                                                  {
                                                    tagName: 'p',
                                                    type: 'text',
                                                    components: [{ type: 'textnode', content: '835-795-3209' }],
                                                  },
                                                  {
                                                    tagName: 'span',
                                                    type: 'text',
                                                    classes: ['cover-page__pro-info__divider'],
                                                    components: [{ type: 'textnode', content: '|' }],
                                                  },
                                                  {
                                                    tagName: 'p',
                                                    type: 'text',
                                                    components: [{ type: 'textnode', content: 'jdoe@email.com' }],
                                                  },
                                                ],
                                              },
                                              {
                                                tagName: 'p',
                                                type: 'text',
                                                classes: ['cover-page__date'],
                                                components: [{ type: 'textnode', content: 'Created August 29, 2023' }],
                                              },
                                            ],
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                          { type: 'textnode', content: '\n' },
                        ],
                      },
                    ],
                  },
                ],
                head: { type: 'head' },
                docEl: { tagName: 'html' },
              },
              id: 'l81yMhxVSOZyrlbm',
            },
          ],
          type: 'main',
          id: 'cover-page',
        },
      },
      { type: 'fixed-views', name: 'home', story: 'home' },
      { type: 'fixed-views', name: 'system-hardware', story: 'system-hardware' },
      { type: 'fixed-views', name: 'energy', story: 'energy' },
      { type: 'fixed-views', name: 'savings', story: 'savings' },
      {
        type: 'custom-view',
        name: 'next-steps',
        design: {
          frames: [
            {
              component: {
                type: 'wrapper',
                badgable: false,
                stylable: [
                  'background',
                  'background-color',
                  'background-image',
                  'background-repeat',
                  'background-attachment',
                  'background-position',
                  'background-size',
                ],
                highlightable: false,
                selectable: false,
                components: [
                  { tagName: 'proposal-v2-background', type: 'proposal-v2-background' },
                  {
                    tagName: 'fullscreen',
                    type: 'FullScreen',
                    components: [
                      {
                        tagName: 'div',
                        type: 'FullScreen',
                        classes: ['editor-editable-comp-fullscreen'],
                        components: [
                          {
                            tagName: 'twoequalcolumns',
                            type: 'TwoEqualColumns',
                            classes: ['flexbox-2-columns'],
                            attributes: {},
                            components: [
                              {
                                classes: ['two-equal-columns__column'],
                                attributes: { id: 'next-steps__stepper' },
                                components: [{ tagName: 'go-next-steps', type: 'go-next-steps' }],
                              },
                              {
                                classes: ['two-equal-columns__column'],
                                attributes: { id: 'next-steps__cover' },
                                components: [
                                  { classes: ['full-width'], tagName: 'house-model', type: 'house-model' },
                                  {
                                    classes: ['cover-page__texts'],
                                    components: [
                                      {
                                        classes: ['cover-page__text-container'],
                                        components: [
                                          {
                                            tagName: 'p',
                                            type: 'text',
                                            classes: ['cover-page__title'],
                                            components: [{ type: 'textnode', content: 'Michelle and Michael' }],
                                          },
                                          {
                                            tagName: 'p',
                                            type: 'text',
                                            classes: ['cover-page__address'],
                                            components: [{ type: 'textnode', content: '230 Valparaiso Ave' }],
                                          },
                                        ],
                                      },
                                      {
                                        classes: ['cover-page__text-container'],
                                        components: [
                                          {
                                            classes: ['cover-page__pro-info'],
                                            components: [
                                              {
                                                tagName: 'p',
                                                type: 'text',
                                                components: [
                                                  { type: 'textnode', content: 'Your solar pro: Ray Light' },
                                                ],
                                              },
                                              {
                                                tagName: 'span',
                                                type: 'text',
                                                classes: ['cover-page__pro-info__divider'],
                                                components: [{ type: 'textnode', content: '|' }],
                                              },
                                              {
                                                tagName: 'p',
                                                type: 'text',
                                                components: [{ type: 'textnode', content: '835-795-3209' }],
                                              },
                                              {
                                                tagName: 'span',
                                                type: 'text',
                                                classes: ['cover-page__pro-info__divider'],
                                                components: [{ type: 'textnode', content: '|' }],
                                              },
                                              {
                                                tagName: 'p',
                                                type: 'text',
                                                components: [{ type: 'textnode', content: 'jdoe@email.com' }],
                                              },
                                            ],
                                          },
                                          {
                                            tagName: 'p',
                                            type: 'text',
                                            classes: ['cover-page__date'],
                                            components: [{ type: 'textnode', content: 'Created August 29, 2023' }],
                                          },
                                        ],
                                      },
                                    ],
                                  },
                                ],
                              },
                            ],
                          },
                          { type: 'textnode', content: '\n' },
                        ],
                      },
                    ],
                  },
                ],
                head: { type: 'head' },
                docEl: { tagName: 'html' },
              },
              id: 'u9tP8J5TVwdy0s2C',
            },
          ],
          type: 'main',
          id: 'next-steps',
        },
      },
    ],
    globalState: {},
    pageStates: {},
    sections: [
      { sectionId: 'CoverPage', views: ['cover-page'] },
      { sectionId: 'Home', views: ['home', 'system-hardware'] },
      { sectionId: 'Energy', views: ['energy'] },
      { sectionId: 'Savings', views: ['savings'] },
      { sectionId: 'Go', views: ['next-steps'] },
    ],
  },
}
