import { addProjectError } from 'actions/project'
import { SystemContext } from 'Designer/designRules/types'
import lodash from 'lodash'
import { StdErrorType } from 'reducer/project/projectErrorsReducer'
import { DynamicActionInfo } from 'types/ui/DynamicActionInfo'
import { createSystemEffect, EffectFactory, SystemEffectDef } from '.'

export type AddSystemErrorDef = {
  type: 'add-system-error'
  payload: SystemErrorDef
}
type SystemErrorDef = Omit<StdErrorType, 'systemId' | 'messageType' | 'source' | 'options'> & {
  options?: {
    actions?: SystemEffectButtonInfo[]
  }
}
type SystemEffectButtonInfo = DynamicActionInfo & {
  effects?: SystemEffectDef[]
}

export const effect_addSystemError: EffectFactory<AddSystemErrorDef> = (def) => {
  return {
    execute: async (context: SystemContext) => {
      const error = lodash.cloneDeep(def.payload)

      // For any buttons in the error, wire them up to the effects system
      const actions = error.options?.actions
      if (actions) {
        for (const action of actions) {
          if (action.effects) {
            const effects = action.effects.map((e) => createSystemEffect(e))
            action.callback = () => {
              effects.forEach((e) => e.execute(context))
            }
          }
        }
      }

      context.dispatch(
        addProjectError({
          ...error,
          source: 'design-rule',
          messageType: 'text',
          systemId: context.system.uuid,
        })
      )
    },
  }
}
