import { makeStyles } from '@material-ui/core'
import {
  PhotoSizeSelectSmallOutlined,
  ReplayOutlined,
  StraightenOutlined,
  StreetviewOutlined,
} from '@material-ui/icons'
import { debounce } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
import { ProjectType } from 'types/projects'
import { MeasurementTypes } from 'types/studio/controllers'
import ToolButton from '../tools/widgets/ToolButton'
import { handleRestart } from '../util/handleRestart'
import { openStreetView } from '../util/openStreetView'

const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: 'flex',
      height: 35,
      position: 'absolute',
      bottom: 17,
      right: 90,

      [theme.breakpoints.down('sm')]: {
        bottom: 4,
        right: 76,
      },
    },
    icon: {
      width: 24,
      height: 24,
      margin: 0,
    },
  }
})

const AdvancedTools: React.FC = () => {
  const translate = useTranslate()
  const classes = useStyles()
  const [selectedView, setSelectedView] = useState<string | undefined>()

  useEffect(() => {
    const checkView = () => {
      setSelectedView(window.ViewHelper.getViewByUuid(window.ViewHelper.selectedViewUuid())?.mapData.mapType)
    }
    checkView()

    const onViewsChanged = debounce(checkView, 100, { trailing: true })

    window.editor.signals.viewsChanged.add(onViewsChanged)
    return () => window.editor.signals.viewsChanged.remove(onViewsChanged)
  }, [])

  const form = useForm()
  const project = form.getState().values as ProjectType
  const [measureDistanceActive, setMeasureDistanceActive] = useState(false)

  return (
    <div className={classes.root}>
      <ToolButton id="RestartProjectButton" title={translate('Restart Project')} onClick={handleRestart}>
        <ReplayOutlined classes={{ root: classes.icon }} />
      </ToolButton>
      <ToolButton
        id="StreetViewButton"
        title={translate('Street View')}
        onClick={() => {
          openStreetView(project)
        }}
      >
        <StreetviewOutlined classes={{ root: classes.icon }} />
      </ToolButton>

      <ToolButton
        id="MeasureDistanceButton"
        title={translate('Measure Distance')}
        selected={measureDistanceActive}
        onClick={() => {
          if (!measureDistanceActive) {
            window.editor.controllers.Measurement.start(MeasurementTypes.Length).finally(() => {
              setMeasureDistanceActive(false)
            })
            setMeasureDistanceActive(true)
          } else {
            window.editor.controllers.Measurement.finish()
          }
        }}
      >
        <StraightenOutlined classes={{ root: classes.icon }} />
      </ToolButton>

      {selectedView === 'Nearmap' ? (
        <ToolButton
          id="ScreenshotButton"
          title={translate('Screenshot')}
          onClick={() => {
            window.WorkspaceHelper.snapshotAndDownload()
          }}
        >
          <PhotoSizeSelectSmallOutlined classes={{ root: classes.icon }} />
        </ToolButton>
      ) : (
        ''
      )}
    </div>
  )
}

export default AdvancedTools
