import { useState } from 'react'
import BrighteApplicationDialog from './BrighteApplicationDialog'

export type BrighteApplicationDialogConfig = {
  url: string
}

const useBrighteApplicationDialog = () => {
  const [state, seState] = useState<
    { open: false; config: undefined } | { open: true; config: BrighteApplicationDialogConfig }
  >({
    open: false,
    config: undefined,
  })

  const openDialog = (config: BrighteApplicationDialogConfig) => {
    seState({
      open: true,
      config,
    })
  }

  const closeDialog = () => {
    if (state.open === false) {
      return
    }
    seState({
      open: false,
      config: undefined,
    })
  }

  const dialogNode = state.open ? (
    <BrighteApplicationDialog onClose={closeDialog} isOpen={true} url={state.config.url} isFullWidth={true} />
  ) : null
  return { isOpen: state.open, dialogNode, openDialog, closeDialog, type: 'BrighteApplicationDialog' as const }
}

export default useBrighteApplicationDialog
