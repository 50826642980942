import { BaseTheme, FileOutlineIcon } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React, { useState } from 'react'
import DownloadBOMDialog from 'resources/integrations/ironridge/DownloadBOMDialogContent'
import { ProjectType } from 'types/projects'
import IntegrationRow from '../IntegrationRow'

interface PropTypes {
  project: ProjectType
  hasIronRidge: boolean
}

const IronRidgeCTA: React.FC<PropTypes> = ({ project, hasIronRidge }) => {
  const translate = useTranslate()
  const [showDialog, setShowDialog] = useState(false)

  return (
    <>
      {hasIronRidge ? (
        <IntegrationRow
          title={'IronRidge'}
          description={translate(
            'We have partnered with IronRidge to help you automatically generate a Bill of Materials (BOM) and include IronRidge racking products in all of your designs and proposals.'
          )}
          btnLabel={translate('Download BOM')}
          onClick={() => {
            setShowDialog(true)
          }}
          logo={<img src={`${window.PUBLIC_URL}/images/ironridge_logo.png`} width="94px" height="18px" />}
        />
      ) : (
        <IntegrationRow
          title={translate('Bill of Materials')}
          description={translate(
            'Click the button below to generate and download the Bill of Materials for your selected system design.'
          )}
          btnLabel={translate('Download BOM')}
          onClick={() => {
            setShowDialog(true)
          }}
          logo={<FileOutlineIcon variant={2} size={20} color={BaseTheme.palette.grey[700]} />}
        />
      )}
      {showDialog && (
        <DownloadBOMDialog
          showDialog={showDialog}
          onClose={() => setShowDialog(false)}
          project={project}
          mode="button"
          preselected_uuid={undefined}
          isIronridge={hasIronRidge}
        />
      )}
    </>
  )
}

export default IronRidgeCTA
