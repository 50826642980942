import { and, ControlProps, rankWith, uiTypeIs } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import { FilterField } from 'conditions/types'
import { ConditionBuilder, ConditionBuilderProps } from 'elements/conditions/ConditionBuilder'

const tester = rankWith(100, and(uiTypeIs('ConditionBuilder')))

const ConditionBuilderRenderer = ({ uischema, data, handleChange, path }: ControlProps) => {
  let fields = uischema['fields'] as ConditionBuilderProps['fields'] | undefined
  let fields_preset = uischema['fields_preset'] as string | undefined
  let fields_options = (uischema['options'] || {}) as Partial<ConditionBuilderProps>

  if (fields_preset === 'standard_pro_org_conditions' && !fields) {
    fields = standard_pro_org_conditions
  }
  let emptyText = uischema['emptyText']

  if (!fields?.length) {
    console.error('ConditionBuilderRenderer: no fields provided')
    fields = []
  }

  return (
    <ConditionBuilder
      value={data || []}
      onChange={(newValue) => {
        handleChange(path, newValue)
      }}
      fields={fields}
      emptyText={emptyText}
      fieldFreeSolo={true}
      {...fields_options}
    />
  )
}

const renderer = withJsonFormsControlProps(ConditionBuilderRenderer)

export default {
  tester,
  renderer,
}

//TODO: move somewhere better
const standard_pro_org_conditions_org: FilterField[] = [
  // org
  { label: 'Org ID', path: 'org.id', type: 'integer' },
  { label: 'Org Country', path: 'org.country.iso2', type: 'string' },
  { label: 'Org Name', path: 'org.name', type: 'string' },
  { label: 'Bluesnap Onboarding Status', path: 'org.bluesnap_onboarding_status', type: 'string' },
  { label: 'Cashflow is active', path: 'org.cashflow_is_active', type: 'boolean' },
  { label: 'Docusign Connected', path: 'org.docusign_config.docusign_account_connected', type: 'boolean' },
  {
    label: 'Docusign Contract KBA Enabled',
    path: 'org.docusign_config.docusign_contract_kba_enabled',
    type: 'boolean',
  },
  {
    label: 'Docusign Contract SMS Auth Enabled',
    path: 'org.docusign_config.docusign_contract_sms_auth_enabled',
    type: 'boolean',
  },
  {
    label: 'Contracts in Docusign Enabled',
    path: 'org.docusign_config.enable_contracts_in_docusign',
    type: 'boolean',
  },
  {
    label: 'Org Acceptance files enabled',
    path: 'org.email_acceptance_files_enabled',
    type: 'boolean',
  },
  {
    label: 'Bespoke Proposals enabled',
    path: 'org.enable_bespoke_proposal',
    type: 'boolean',
  },
  {
    label: 'Brighte finance enabled',
    path: 'org.enable_brighte',
    type: 'boolean',
  },
  {
    label: 'Centrix Credit Check enabled',
    path: 'org.enable_centrix_credit_check',
    type: 'boolean',
  },
  {
    label: 'Checkout enabled',
    path: 'org.enable_checkout',
    type: 'boolean',
  },
  {
    label: 'City Plumbling distributor enabled',
    path: 'org.enable_city_plumbing',
    type: 'boolean',
  },
  {
    label: 'Commonbond finance enabled',
    path: 'org.enable_commonbond',
    type: 'boolean',
  },
  {
    label: 'Cost and margin for non-admins enabled',
    path: 'org.enable_cost_and_margin_for_non_admins',
    type: 'boolean',
  },
  {
    label: 'Dividend finance enabled',
    path: 'org.enable_dividend',
    type: 'boolean',
  },
  {
    label: 'Eagleview design enabled',
    path: 'org.enable_eagleview',
    type: 'boolean',
  },
  {
    label: 'Energy Ease finance enabled',
    path: 'org.enable_energy_ease',
    type: 'boolean',
  },
  {
    label: 'Exhibit/Showcase enabled',
    path: 'org.enable_exhibit',
    type: 'boolean',
  },
  {
    label: 'Google Calendar enabled',
    path: 'org.enable_google_calendar',
    type: 'boolean',
  },
  {
    label: 'Handypay finance enabled',
    path: 'org.enable_handypay',
    type: 'boolean',
  },
  {
    label: 'HDM distributor enabled',
    path: 'org.enable_hdm',
    type: 'boolean',
  },
  {
    label: 'Humm finance enabled',
    path: 'org.enable_humm',
    type: 'boolean',
  },
  {
    label: 'Lead distribution enabled',
    path: 'org.enable_lead_distribution',
    type: 'boolean',
  },
  {
    label: 'Loanpal finance enabled',
    path: 'org.enable_loanpal',
    type: 'boolean',
  },
  {
    label: 'Mosaic finance enabled',
    path: 'org.enable_mosaic',
    type: 'boolean',
  },
  {
    label: 'Nearmap enabled',
    path: 'org.enable_nearmap',
    type: 'boolean',
  },
  {
    label: 'Other imagery enabled',
    path: 'org.enable_other_imagery',
    type: 'boolean',
  },
  {
    label: 'Solar Outlet distributor enabled',
    path: 'org.enable_outlet',
    type: 'boolean',
  },
  {
    label: 'Phoenix finance enabled',
    path: 'org.enable_phoenix',
    type: 'boolean',
  },
  {
    label: 'Plenti finance enabled',
    path: 'org.enable_plenti',
    type: 'boolean',
  },
  {
    label: 'Premium imagery enabled',
    path: 'org.enable_premium_imagery',
    type: 'boolean',
  },
  {
    label: 'Segen distributor enabled',
    path: 'org.enable_segen',
    type: 'boolean',
  },
  {
    label: 'Sungage finance enabled',
    path: 'org.enable_sungage',
    type: 'boolean',
  },
  {
    label: 'Sunlight finance enabled',
    path: 'org.enable_sunlight',
    type: 'boolean',
  },
  {
    label: 'Postcoder / UK address lookup enabled',
    path: 'org.enable_uk_address_lookup',
    type: 'boolean',
  },
  {
    label: 'Utility API enabled',
    path: 'org.enable_utility_api',
    type: 'boolean',
  },
  {
    label: 'External Org ID',
    path: 'org.external_account_id',
    type: 'integer',
  },
  {
    label: 'Hide Loan Application Until Prequal',
    path: 'org.hide_loan_application_until_prequal',
    type: 'boolean',
  },
  {
    label: 'Accept Credit Card Stripe',
    path: 'org.payment_settings.accept_credit_card_stripe',
    type: 'boolean',
  },
  {
    label: 'Accept Offline Payments',
    path: 'org.payment_settings.accept_offline',
    type: 'boolean',
  },
  {
    label: 'Org requires MFA',
    path: 'org.require_mfa',
    type: 'boolean',
  },
  {
    label: 'Show talk to us',
    path: 'org.show_talk_to_us',
    type: 'boolean',
  },
  {
    label: 'Org Location: Locality',
    path: 'org.locality',
    type: 'string',
  },
  {
    label: 'Org Location: State',
    path: 'org.state',
    type: 'string',
  },
  {
    label: 'Org Location: ZIP / Postcode',
    path: 'org.zip',
    type: 'string',
  },
]

const standard_pro_org_conditions_role: FilterField[] = [
  { label: 'Role ID', path: 'role.id', type: 'integer' },
  { label: 'Is Admin', path: 'role.is_admin', type: 'boolean' },
  { label: 'Is User Lite', path: 'role.user_data.is_lite', type: 'boolean' },
]

const standard_pro_org_conditions_user: FilterField[] = [
  // auth
  { label: 'Is Customer', path: 'auth.is_customer', type: 'boolean' },
  { label: 'Is OpenSolar Staff', path: 'auth.is_staff', type: 'boolean' },
  { label: 'Is Superuser', path: 'auth.is_superuser', type: 'boolean' },
  { label: 'Is Pro', path: 'auth.is_pro', type: 'boolean' },
  { label: 'Accepted T&Cs Date', path: 'auth.terms_accepted_date', type: 'timestamp' },
  { label: 'Account Age (days)', path: 'auth.account_age_days', type: 'float' },

  // user
  { label: 'User ID', path: 'user.id', type: 'integer' },
  { label: 'Email Marketing Opted-in', path: 'user.email_marketing_opt_in', type: 'boolean' },
  { label: 'Is Anonymous User', path: 'user.is_anonymous_share_user', type: 'boolean' },
  { label: 'IRDA User ID', path: 'user.paired_partners.ironridge.partner_user_uuid', type: 'string' },
]

const standard_pro_org_conditions_runtime: FilterField[] = [
  { label: 'Env', path: 'runtime.env', type: 'string' },
  { label: 'Is Mobile App', path: 'runtime.is_mobile_app', type: 'boolean' },
  { label: 'SPA Version', path: 'runtime.spa_version', type: 'semver' },
  { label: 'Browser Platform', path: 'runtime.navigator_platform', type: 'string' },
]

const standard_pro_org_conditions_location: FilterField[] = [
  { label: 'Pathname', path: 'location.pathname', type: 'string' },
  { label: 'Search (query string)', path: 'location.search', type: 'string' },
  { label: 'Hash (deep link)', path: 'location.hash', type: 'string' },
]

const standard_pro_org_conditions_featureflags: FilterField[] = [
  { label: 'Feature Flag (replace * with flag key)', path: 'feature_flags.*', type: 'boolean' },
]

const standard_pro_org_conditions_timing: FilterField[] = [{ label: 'Time', path: 'timing.now', type: 'timestamp' }]

const standard_pro_org_conditions: FilterField[] = [
  ...groupFields(standard_pro_org_conditions_timing, 'Timing'),
  ...groupFields(standard_pro_org_conditions_org, 'Org'),
  ...groupFields(standard_pro_org_conditions_role, 'Role'),
  ...groupFields(standard_pro_org_conditions_user, 'User'),
  ...groupFields(standard_pro_org_conditions_runtime, 'Runtime'),
  ...groupFields(standard_pro_org_conditions_location, 'Browser Location'),
  ...groupFields(standard_pro_org_conditions_featureflags, 'Features'),
]

function groupFields(fields: FilterField[], group: string): FilterField[] {
  return fields.map((field) => ({ ...field, group }))
}
