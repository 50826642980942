import { addAccordionFormsAndSort } from 'customForm/card/addAccordionFormsAndSort'
import useGetCustomForms from 'customForm/hooks/useGetCustomForms'
import { UiKey } from 'elements/UiKey'
import { NotFound } from 'ra-ui-materialui'
import { FC, useMemo } from 'react'
import { FormPlacementEnum } from 'resources/customForms/types'
import type { RoleType } from 'types/roles'
import { DeleteUser } from './DeleteUser'
import { RoleAccessFields } from './RoleAccessFields'
import { RoleContactFields } from './RoleContactFields'
import { RoleIntegrations } from './RoleIntegrations'
import { RoleProfileFields } from './RoleProfileFields'

type Props = {
  id?: string
  disabled?: boolean
  allowView?: boolean
  allowEdit?: boolean
  record?: RoleType
}

const uiKey = 'control.company.team.fields'

export const RoleFormFields: FC<Props> = ({ id, allowView = true, allowEdit = true, record }) => {
  const panelProps = useMemo(
    () => ({
      id,
      record,
      disabled: !allowEdit,
    }),
    [id, record, allowEdit]
  )

  const customForms = useGetCustomForms({ location: FormPlacementEnum.role_form, isCreateForm: !id })

  const sortAndRenderComponents = useMemo(() => {
    let toRender = [
      {
        component: RoleContactFields,
        shouldRender: true,
        priority: 10,
        props: panelProps,
      },
      {
        component: RoleAccessFields,
        shouldRender: true,
        priority: 20,
        props: panelProps,
      },
      {
        component: RoleProfileFields,
        shouldRender: true,
        priority: 30,
        props: panelProps,
      },
      {
        component: RoleIntegrations,
        shouldRender: true,
        priority: 40,
        props: panelProps,
      },
      {
        component: DeleteUser,
        shouldRender: true,
        priority: 50,
        props: panelProps,
      },
    ]

    if (!allowView) {
      return <NotFound />
    }

    return addAccordionFormsAndSort(toRender, customForms)
  }, [allowEdit, customForms, panelProps])

  return (
    <UiKey uiKey={uiKey}>
      <>{sortAndRenderComponents}</>
    </UiKey>
  )
}
