import { makeStyles } from '@material-ui/core'
import CustomField from 'elements/field/CustomField'
import { Alert, FieldTitle, styled } from 'opensolar-ui'
import React, { useMemo } from 'react'
import { BooleanInput, SelectInput, TextInput, useTranslate } from 'react-admin'
import { useFormState } from 'react-final-form'
import { DraftEditorWithContent } from 'resources/proposalTemplates/ProposalTemplates'
import { StudioSystemType } from 'types/global'
import { InputRow, InputWrapper } from '../../../../styles'

const useStyles = makeStyles({
  wrapper: {
    padding: '20px 10px',
  },
  alertWrapper: {
    marginBottom: 20,
  },
  disabledContent: {
    border: '1px solid rgb(204 204 204)',
    borderRadius: 10,
    padding: 20,
    minHeight: 100,
    maxHeight: 300,
    overflow: 'scroll',
    cursor: 'not-allowed',
    opacity: 0.38,
  },
})

const FinText = styled('p', { name: 'FinText' })(({ theme }) => ({
  fontSize: 13,
  color: theme.palette.grey[810],
  marginBottom: 30,
  marginTop: 10,
}))

const FinanceToggle = styled('div', { name: 'FinanceToggle' })({
  display: 'flex',
  alignItems: 'center',
  gap: 10,
  marginTop: 10,
  '& p': {
    paddingBottom: 3,
    margin: 0,
    marginLeft: 10,
  },
})

interface PropTypes {
  hasPlentiBNPL: boolean
  systems: StudioSystemType[]
}

const EmailForm: React.FC<PropTypes> = ({ hasPlentiBNPL, systems }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const formValues = useFormState().values
  const availablePaymentOptions = useMemo(() => {
    const system = systems.find((x) => x.uuid === formValues.selectedSystemUuid)
    return system?.payment_options
  }, [systems, formValues.selectedSystemUuid])

  return (
    <InputWrapper className={classes.wrapper}>
      {hasPlentiBNPL && (
        <div className={classes.alertWrapper}>
          <Alert severity="info">
            {translate(
              "This project has a Plenti Buy Now Pay Later product. This product currently does not support direct customer application from the interactive proposal. You can however submit the application through the Online Proposal page when logged in as your org's team member."
            )}
          </Alert>
        </div>
      )}
      <TextInput
        variant="outlined"
        label={'Email Subject'}
        source={'subject'}
        disabled={!formValues.isCustomizing}
        fullWidth
      />

      <FieldTitle label={translate('Project Specific Email Custom Content')} />
      {formValues.isCustomizing ? (
        <CustomField component={DraftEditorWithContent} label={''} source={'content'} name={'content'} />
      ) : (
        <div className={classes.disabledContent}>
          <div
            dangerouslySetInnerHTML={{
              __html: formValues.content,
            }}
          />
        </div>
      )}

      {!!systems.length && (
        <>
          <FinanceToggle>
            <BooleanInput source="sendFinance" label={''} options={{ color: 'primary' }} />
            <p>{translate('Send a link directly to a finance application')}</p>
          </FinanceToggle>
          <FinText>
            {translate(
              'Enabling this feature will result in the proposal that your customer is sent automatically opening the application form for the system and payment option you select below'
            )}
          </FinText>
          <InputRow>
            <SelectInput
              label={'System'}
              source={'selectedSystemUuid'}
              fullWidth
              variant="outlined"
              choices={systems}
              optionValue={'uuid'}
              optionText={'title'}
            />
            <SelectInput
              label={'Payment Option'}
              source={'selectedPaymentId'}
              fullWidth
              variant="outlined"
              choices={availablePaymentOptions}
              optionValue={'id'}
              optionText={'title'}
            />
          </InputRow>
        </>
      )}
    </InputWrapper>
  )
}

export default EmailForm
