import { EditOutlined } from '@material-ui/icons'
import CustomField from 'elements/field/CustomField'
import LocalizedCurrencyInput from 'elements/input/LocalizedCurrencyInput'
import { useTranslate } from 'ra-core'
import React, { useMemo } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { PricingDataType } from 'types/pricing'
import { currencySymbolForCountry, formatCurrencyWithSymbol } from 'util/misc'
import PriceLockTooltip from './PriceLockTooltip'

type PropTypes = {
  pricingData: PricingDataType
  editMode: 'milestones' | 'price'
  setEditMode: (mode: 'milestones' | 'price') => void
  projectCountryIso2: string
  pricingIsLocked: boolean
}

const useStyles = makeOpenSolarStyles((theme) => ({
  row: {
    marginTop: '10px',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  pricingCol: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  label: {
    fontWeight: 600,
    marginRight: '20px',
  },
  inputWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  priceEditWrapper: {
    width: '20px',
    height: '100%',
    paddingBottom: '10px',
    display: 'flex',
    alignItems: 'center',
    marginLeft: '10px',
  },
  editIcon: {
    cursor: 'pointer',
    color: theme.greyMid1,
  },
  fieldRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginTop: '10px',
  },
  immutableField: {
    paddingRight: '25px',
  },
}))

const MilestoneOverridePriceSummary: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  const translate = useTranslate()
  const currencySymbol = currencySymbolForCountry(props.projectCountryIso2)

  const totalApplicableIncentives = useMemo(() => {
    return props.pricingData?.incentive_to_installer?.total
  }, [props.pricingData?.incentive_to_installer])

  return (
    <div className={classes.row}>
      <div className={classes.pricingCol}>
        <div className={classes.inputWrapper}>
          <div className={classes.label} style={{ marginTop: '16px' }}>
            {translate('System Price (Inc. Tax)')}:
          </div>

          <CustomField
            style={{ width: '140px', margin: '0px', display: 'flex', aligItems: 'center', marginTop: '0px' }}
            name={'system_price_including_tax'}
            component={LocalizedCurrencyInput}
            currencySymbol={currencySymbol}
            disabled={props.editMode !== 'price' || props.pricingIsLocked}
            initialValue={props.pricingData.system_price_including_tax?.toFixed(2)}
            data-testid="edit-system-price-field"
          />
          <div className={classes.priceEditWrapper}>
            {props.pricingIsLocked && <PriceLockTooltip />}
            {props.editMode === 'milestones' && !props.pricingIsLocked && (
              <EditOutlined
                className={classes.editIcon}
                onClick={() => props.setEditMode('price')}
                data-testid="edit-system-price-btn"
              />
            )}
          </div>
        </div>
        {props.pricingData?.discount !== 0 && (
          <div className={classes.fieldRow}>
            <div className={classes.label}>{translate('Discount')}:</div>
            <div className={classes.immutableField}>
              {formatCurrencyWithSymbol(props.pricingData.discount, currencySymbol)}
            </div>
          </div>
        )}
        <div className={classes.fieldRow}>
          <div className={classes.label}>{translate('Incentives')}:</div>
          <div className={classes.immutableField}>
            {formatCurrencyWithSymbol(totalApplicableIncentives, currencySymbol)}
          </div>
        </div>
        <div className={classes.fieldRow}>
          <div className={classes.label}>{translate('Total Amount Invoiced')}:</div>
          <div className={classes.immutableField}>
            {formatCurrencyWithSymbol(props.pricingData.system_price_payable, currencySymbol)}
          </div>
        </div>
      </div>
    </div>
  )
}
export default MilestoneOverridePriceSummary
