import DefaultIcon from '@material-ui/icons/FileCopy'
import lodash from 'lodash'
import { Button, ButtonProps, IconButton, IconButtonProps } from 'opensolar-ui'
import { useNotify, useTranslate } from 'ra-core'
import { FC, ReactElement } from 'react'
import { useFormState } from 'react-final-form'
import { doNotTranslate } from 'util/misc'

export const CopyValuesButton: FC<CopyValuesButtonProps> = (props) => {
  const {
    label = 'Copy to clipboard',
    className,
    variant = 'contained',
    icon = defaultIcon,
    resource,
    values,
    translate: translateOverride,
    ...rest
  } = props

  const notify = useNotify()
  const translate = useTranslate()
  const formState = useFormState()

  const handleClick = async () => {
    const valuesCopy = lodash.cloneDeep(values || formState.values)
    const omitProps = ['id', 'url'].concat(props.omitProps || [])
    for (let prop of omitProps) {
      lodash.unset(valuesCopy, prop)
    }
    const data = { resource, values: valuesCopy }
    const text = JSON.stringify(data, null, 2)

    try {
      await navigator.clipboard.writeText(text)
      notify('Form values copied to clipboard!', 'success')
    } catch (err) {
      notify('Failed to copy to clipboard!', 'error')
    }
  }

  const doTranslate = (label: string, options: any) => {
    if (translateOverride) {
      return translateOverride(label, options)
    } else if (translateOverride === false) {
      return doNotTranslate(label)
    } else {
      return translate(label, options)
    }
  }

  const displayedLabel = label && doTranslate(label, { _: label })
  return displayedLabel ? (
    <Button
      className={className}
      variant={variant}
      name={'raSaveButton'}
      onClick={handleClick}
      color="default"
      aria-label={displayedLabel}
      {...rest}
    >
      {icon}
      <span>{displayedLabel}</span>
    </Button>
  ) : (
    <IconButton
      className={className}
      name={'raSaveButton'}
      onClick={handleClick}
      color="default"
      aria-label={displayedLabel}
      {...rest}
    >
      {icon}
    </IconButton>
  )
}

const defaultIcon = <DefaultIcon />

interface Props {
  resource: string
  className?: string
  variant?: string
  label?: string
  icon?: ReactElement
  omitProps?: string[]
  values?: any
  size?: IconButtonProps['size']
  translate?: ((label: string, options?: any) => string) | false
}

type CopyValuesButtonProps = Omit<ButtonProps, 'translate'> & Props
