import { useCallback, useEffect, useMemo } from 'react'
import { useForm } from 'react-final-form'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'

import { setViewAsCustomer as setViewAsCustomerAction } from 'ducks/viewAsCustomer'
import { clearQueryParam, parseQueryStringToDictionary } from 'util/query'

const useViewAsCustomer = () => {
  const form = useForm()
  const location = useLocation()
  const { search } = location
  const dispatch = useDispatch()
  const history = useHistory()

  const toggleViewAsCustomer = useCallback(
    (isChecked: boolean) => {
      if (isChecked) {
        // add mode=customerView in query
        history.push({
          pathname: location.pathname,
          search: search ? `${search}&mode=customerView` : 'mode=customerView',
        })
      } else {
        // remove mode=customerView from query
        clearQueryParam('mode')
      }
    },
    [search]
  )

  const queryIsViewAsCustomer = useMemo(() => {
    const query = parseQueryStringToDictionary(search)
    return !!(query.mode === 'customerView')
  }, [search])

  useEffect(() => {
    form.mutators.recordFormDirtyFields()
    dispatch(setViewAsCustomerAction(queryIsViewAsCustomer))
  }, [search])

  useEffect(() => {
    return () => {
      if (!queryIsViewAsCustomer) dispatch(setViewAsCustomerAction(false))
    }
  }, [])

  return { toggleViewAsCustomer }
}

export default useViewAsCustomer
