import { CurrencySymbolContext } from 'contexts/CurrencySymbolContext'
import { StructsContext } from 'contexts/structs/StructsContext'
import { UserActionsContext } from 'contexts/userActions/UserActionsContext'
import { CustomFormContext } from 'customForm/CustomFormContext'
import { orgSelectors } from 'ducks/orgs'
import { ComponentVersionsStoredRoot, ThemeContext } from 'opensolar-ui'
import { AdminUIProps } from 'ra-core'
import { FC } from 'react'
import { AdminUI } from 'react-admin'
import { useSelector } from 'react-redux'
import useAuthRedirect from 'redirections/useAuthRedirect'
import OidcWrapper from 'services/cityPlumbing/OidcWrapper'
import RefreshViewWrapper from './RefreshViewWrapper'

export const CustomAdminUI: FC<AdminUIProps> = (props) => {
  const orgCurrencySymbol = useSelector(orgSelectors.getCurrencySymbolFromOrg)
  useAuthRedirect()

  return (
    <RefreshViewWrapper>
      <ThemeContext>
        <UserActionsContext>
          <StructsContext>
            <ComponentVersionsStoredRoot>
              <OidcWrapper>
                <CustomFormContext.Provider value={undefined}>
                  <CurrencySymbolContext.Provider value={orgCurrencySymbol}>
                    <AdminUI {...props} />
                  </CurrencySymbolContext.Provider>
                </CustomFormContext.Provider>
              </OidcWrapper>
            </ComponentVersionsStoredRoot>
          </StructsContext>
        </UserActionsContext>
      </ThemeContext>
    </RefreshViewWrapper>
  )
}
