"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.proposal = void 0;
var action_1 = require("../core/action");
var state_1 = require("../core/state");
exports.proposal = {
    // State
    data: new state_1.DeepState({}),
    templateSettings: new state_1.DeepState({}),
    checkoutElements: new state_1.DeepState({}),
    transaction: new state_1.DeepState({}),
    isViewAsCustomer: new state_1.Value(false),
    sendButtonOptions: new state_1.DeepState({}),
    // Actions
    setIsViewAsCustomer: new action_1.Action(),
    onCheckoutElementAction: new action_1.Action(),
    onSystemChange: new action_1.Action(),
    onPaymentMethodChange: new action_1.Action(),
    onSendProposal: new action_1.Action(),
    onSaveTemplateSettings: new action_1.Action(),
};
