import { ComponentVersions_2_1, ComponentVersions_3_0 } from 'constants/uxVersions'
import useElementBox from 'hooks/useElementBox'
import { useUxVersion } from 'hooks/useUxVersion'
import { Button, ComponentVersionsInherit, Dialog, DialogContent, styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { PromoContentElements } from './elements/PromoContentElements'
import { PaginationDots } from './PaginationDots'
import { PromoDialogElement } from './usePromoDialogStructs'

const StyledDialogContent = styled(DialogContent, { name: 'StyledDialogContent' })({
  padding: '0 !important',
  width: '100%',
  maxHeight: 'calc(100vh - 196px)', // An unfortunate hack to make the scrollbar work well with the vertical transition
})
const StyledDialogActions = styled('div', { name: 'StyledDialogActions' })({
  display: 'flex',
  flexDirection: 'column',
  padding: '20px 0 24px',
})
const OuterContainer = styled('div', { name: 'OuterContainer' })({
  transition: 'height 0.7s',
  overflow: 'hidden',
})
const InnerContainer = styled('div', { name: 'InnerContainer' })({
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  gap: 2,
})

const ActionsBar = styled('div', { name: 'ActionsBar' })({
  display: 'flex',
  width: '100%',
  padding: '0 20px',
  boxSizing: 'border-box',
  justifyContent: 'end',
  gap: 12,
})

type Props = {
  elements: PromoDialogElement[] | undefined
  open: boolean
  showActionsBar: boolean
  showPagination: boolean
  onCloseComplete: () => void
  totalPages: number
  currentPage: number
  backEnabled: boolean
  nextEnabled: boolean
  trackingKey?: string
  nextWillClose: boolean
  onBack: () => void
  onNext: () => void
  onBackdropClick?: () => void
}

export const PromoDialogView = ({
  elements,
  open,
  showActionsBar,
  showPagination,
  onCloseComplete,
  totalPages,
  currentPage,
  backEnabled,
  nextEnabled,
  trackingKey,
  nextWillClose,
  onBack,
  onNext,
  onBackdropClick,
}: Props) => {
  const translate = useTranslate()

  const ux3 = useUxVersion() === 3

  const elementBox = useElementBox({ watchLevelsUp: 2, checkEvery: 1000 })

  return (
    <ComponentVersionsInherit versions={ux3 ? ComponentVersions_3_0 : ComponentVersions_2_1}>
      <Dialog
        open={open}
        maxWidth={'md'}
        PaperProps={{ style: { width: 770, padding: '0' } }}
        TransitionProps={{ onExited: onCloseComplete }}
        onBackdropClick={onBackdropClick}
      >
        <OuterContainer style={{ height: elementBox.documentBox.height + 'px' }}>
          <StyledDialogContent className="os-scroll">
            <InnerContainer ref={elementBox.setTarget}>
              {elements && <PromoContentElements elements={elements} trackingSource={trackingKey} onNext={onNext} />}
            </InnerContainer>
          </StyledDialogContent>
        </OuterContainer>
        {showActionsBar && (
          <StyledDialogActions>
            {showPagination && <PaginationDots count={totalPages} activeIndex={currentPage} />}
            <ActionsBar>
              {backEnabled && (
                <Button variant="contained" color="default" onClick={onBack}>
                  {translate('Back')}
                </Button>
              )}
              {nextEnabled && (
                <Button variant="contained" color="primary" onClick={onNext}>
                  {translate(nextWillClose ? 'Close' : 'Next')}
                </Button>
              )}
            </ActionsBar>
          </StyledDialogActions>
        )}
      </Dialog>
    </ComponentVersionsInherit>
  )
}
