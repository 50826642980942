import { createContext } from 'react'

// Todo: remove checkout dialog from active dialog
type ActiveDialogName =
  | 'SunlightLoanApplication'
  | 'PlentiLoanApplication'
  | 'CaseStudies'
  | 'AboutUs'
  | 'NextSteps'
  | 'GenerateProposal'
  | 'EditUsage'
  | 'DailyEnergyFlowsDialog'
  | 'ImageDialog'
  | 'FinancialDetail'
  | 'Video'
  | 'Detail'

type ActiveDialogState = {
  activeDialog: ActiveDialogName | null
  dialogData: unknown
  dialogIsFullWidth: Boolean
  showDialog: (dialogName: ActiveDialogName, dialogData: unknown, isFullWidth?: boolean) => void
}

const ActiveDialogContext = createContext<ActiveDialogState>({
  activeDialog: null,
  showDialog: () => {},
  dialogData: null,
  dialogIsFullWidth: false,
})

export default ActiveDialogContext
