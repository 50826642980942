import classNames from 'classnames'
import { Card, CardContent, CardMedia, Chip, styled, Typography } from 'opensolar-ui'
import { useTranslate } from 'react-admin'

type PropTypes = {
  tag: string
  imgSrc: string
  header: string
  body: string
  selected: boolean
  disabled: boolean
  onSelect: () => Promise<void>
}

const StyledCard = styled(Card)({
  position: 'relative',
  width: '264px',
  height: '254px',
  borderRadius: '12px',
  border: '1px solid #DCDCDE',
  '&.selected': {
    border: '1px solid #4272DD',
    boxShadow: '0 0 0 1px #4272DD',
  },
  '&.disabled': {
    opacity: 0.5,
    cursor: 'not-allowed',
  },
  '&:hover': {
    border: '1px solid #94B0EC',
    boxShadow: '0 0 0 1px #94B0EC',
  },
  '.MuiCardMedia-root': {
    height: '148px',
  },
  '.MuiCardContent-root': {
    padding: '12px',
    '.MuiTypography-body1:first-of-type': {
      fontWeight: '600',
      lineHeight: '20px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '.MuiTypography-body1:last-of-type': {
      fontWeight: '400',
      lineHeight: '20px',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      '-webkit-line-clamp': '3',
      '-webkit-box-orient': 'vertical',
    },
  },
})

const Tag = styled(Chip)({
  position: 'absolute',
  top: '8px',
  right: '8px',
  borderRadius: '6px',
  backgroundColor: '#FFFFFFF2',
  height: '28px',
  '& .MuiChip-label, .OSUI-Chip-label': {
    padding: '8px',
  },
})

const TemplateLibraryCard: React.FC<PropTypes> = ({ imgSrc, header, body, tag, selected, disabled, onSelect }) => {
  const translate = useTranslate()
  const showTag = ['Classic', 'Default'].includes(tag)

  return (
    <StyledCard
      className={classNames({ selected, disabled })}
      onClick={async () => {
        if (!disabled) await onSelect()
      }}
      elevation={0}
    >
      {showTag && <Tag label={translate(tag)} />}
      <CardMedia image={imgSrc} />
      <CardContent>
        <Typography variant="body1" colorHex="#4C4B53">
          {header}
        </Typography>
        <Typography colorHex="#6B6A76">{body}</Typography>
      </CardContent>
    </StyledCard>
  )
}

export default TemplateLibraryCard
