import { useCallback, useEffect, useRef } from 'react'

const useInterval = (callback, delay) => {
  const savedCallback = useRef()
  const timer = useRef()

  const clear = useCallback(() => {
    clearInterval(timer.current)
  }, [])

  // Remember the latest function.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current(clear)
    }
    if (delay !== null) {
      timer.current = setInterval(tick, delay)
      return () => clearInterval(timer.current)
    }
  }, [delay])

  return {
    clear
  }
}

export default useInterval
