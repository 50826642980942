import { Divider, Grid, MenuItem, Select } from '@material-ui/core'
import { AddOutlined } from '@material-ui/icons'
import { orgSelectors } from 'ducks/orgs'
import { Button, styled } from 'opensolar-ui'

import LoadingDots from 'layout/widgets/LoadingDots'
import CashFlowSetupChecklist from 'pages/cashFlow/sharedComponents/CashFlowSetupChecklist'
import { CashFlowConfiguration } from 'pages/cashFlow/types'
import { useTranslate } from 'ra-core'
import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { checkAutoApplyConfigExists, useGetCashFlowConfigurations } from '../utils'
import ConvertExistingProjectsBanner from './convertExistingProjects/Banner'
import CreateConfiguration from './CreateConfiguration'
import UpdateConfiguration from './UpdateConfiguration'

type PropTypes = {}

const ContainerWrapper = styled('div')({
  overflow: 'hidden',
})

const Header = styled('div')({
  fontSize: 18,
  margin: '10px 0',
  fontWeight: 400,
})

const Container = styled('div')(({ theme }) => ({
  background: theme.palette.background.paper,
  padding: '16px 24px',
  border: '1px solid #e7e7e7',
  borderRadius: '5px',
  margin: '1rem 0',
}))

const HeaderRow = styled('div')({
  marginTop: '10px',
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
  justifyContent: 'space-between',
  marginBottom: '1rem',
})

const Row = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
})

const useStyles = makeOpenSolarStyles((theme) => ({
  divider: {
    margin: '24px 0',
  },
  justifyCenter: {
    justifyContent: 'center',
  },
}))

const PaymentMilestonesPage: React.FC<PropTypes> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [showCreate, setShowCreate] = useState<boolean>(false)
  const [isUpdated, setIsUpdated] = useState<boolean>()
  const [selectedConfig, setSelectedConfig] = useState<CashFlowConfiguration | undefined>(undefined)
  const [newConfig, setNewConfig] = useState<CashFlowConfiguration | undefined>(undefined)
  const orgId = useSelector(orgSelectors.getOrg)?.id
  const configs = useGetCashFlowConfigurations(orgId, 1, 10, isUpdated).filter(
    (config) => config.deposit_only === false
  )
  const autoAppliedConfigId = checkAutoApplyConfigExists(configs)

  const onConfigSelection = useCallback(
    (e) => {
      let chosenConfig = configs?.find((config) => config.id === e.target.value)
      setSelectedConfig(chosenConfig)
      if (showCreate) setShowCreate(false)
      setIsUpdated(false)
    },
    [configs]
  )

  useEffect(() => {
    if (newConfig) {
      setShowCreate(false)
      setIsUpdated(false)
      setSelectedConfig(newConfig)
    }
  }, [newConfig])

  // load auto-applied config by default. If none are auto-applied just load the first in the list
  useEffect(() => {
    if (!showCreate && !selectedConfig) {
      if (autoAppliedConfigId) {
        let chosenConfig = configs?.find((config) => config.id === autoAppliedConfigId)
        setSelectedConfig(chosenConfig)
      } else if (configs?.length) {
        setSelectedConfig(configs[0])
      }
      setIsLoading(false)
    }
  }, [autoAppliedConfigId, configs, showCreate, selectedConfig])

  const classes = useStyles()
  const translate = useTranslate()

  const onAddPaymentScheme = () => {
    setShowCreate(true)
    setSelectedConfig(undefined)
  }

  return (
    <ContainerWrapper>
      {isLoading ? (
        <LoadingDots />
      ) : (
        <Grid container spacing={2} className={classes.justifyCenter}>
          <Grid item xs={11} lg={8}>
            <Container>
              <ConvertExistingProjectsBanner configs={configs} />
              <HeaderRow>
                <Header>{translate('Payment Milestones')}</Header>
                <Button variant="outlined" onClick={onAddPaymentScheme} startIcon={<AddOutlined />}>
                  {translate('Scheme')}
                </Button>
              </HeaderRow>

              <p>
                {translate(
                  'Payment Milestone Schemes determine how the total project cost is broken down into payments tied to key project milestones.'
                )}
              </p>
              <p>
                {translate(
                  "Select a scheme from the dropdown below to view options to customize your organization's payment milestones."
                )}
              </p>

              <Divider className={classes.divider} />

              <Row>
                <p>{translate('Select Scheme')}</p>
                <Select
                  value={`${selectedConfig?.id}`}
                  onChange={onConfigSelection}
                  variant="outlined"
                  style={{ minWidth: '25%' }}
                >
                  {configs
                    .filter((config) => !config.is_archived)
                    .map((config) => (
                      <MenuItem value={config.id} key={config.id}>
                        {config.title}
                      </MenuItem>
                    ))}
                  {configs
                    .filter((config) => config.is_archived)
                    .map((config) => (
                      <MenuItem value={config.id} key={config.id}>
                        {config.title} (Archived)
                      </MenuItem>
                    ))}
                </Select>
              </Row>
            </Container>

            {selectedConfig && !showCreate && (
              <UpdateConfiguration
                config={selectedConfig}
                setIsUpdated={setIsUpdated}
                setNewConfig={setNewConfig}
                autoAppliedConfigId={autoAppliedConfigId}
              />
            )}
            {showCreate && <CreateConfiguration setIsUpdated={setIsUpdated} setNewConfig={setNewConfig} />}
          </Grid>
          <Grid item xs={11} lg={3}>
            <CashFlowSetupChecklist activeStep={1} />
          </Grid>
        </Grid>
      )}
    </ContainerWrapper>
  )
}

export default PaymentMilestonesPage
