import { StructCreate, StructEdit } from './StructCreateEdit'
import { StructList } from './StructList'

export default {
  list: StructList,
  create: StructCreate,
  edit: StructEdit,

  options: {
    list: {
      title: 'Structured Configs',
    },
    create: {
      title: 'Create Structured Config',
    },
    edit: {
      title: 'Edit Structured Config',
    },
  },
}
