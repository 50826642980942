// Sample promoConfigs banners. For testing, you can manually run this code in the console then view a page where a banner may display.
/*
  window.promoConfigs = [
    {
      id: 'abc',
      org_country_iso2: ['AU', 'GB'],
      targetOrgFields: [{"fieldPath": "enable_segen", "value": false}],
      targetRoleFields: [{"fieldPath": "is_admin", "value": true}],
      bannerConfig: {
        creative_name: "simple-leaderboard-banner-v1",
        campaign_name: "brand-launch-may-2023",
        placements: ['control_top', 'home_cluster', 'hardware_selection_module_bottom', 'hardware_selection_inverter_bottom', 'hardware_selection_battery_bottom', 'hardware_selection_other_bottom', ],
        format: 'leaderboard',
        iframeUrl: 'http://localhost/sample_ads/ad2.html',
        clickUrl: 'http://localhost/',
        weighting: 1,
      },
    },

    {
      id: 'def',
      org_country_iso2: ['AU', 'GB'],
      targetOrgFields: [{"fieldPath": "enable_segen", "value": false}],
      targetRoleFields: [{"fieldPath": "is_admin", "value": true}],
      bannerConfig: {
        creative_name: "simple-leaderboard-banner-v1",
        campaign_name: "brand-launch-may-2023",
        placements: ['control_bottom'],
        format: 'leaderboard',
        iframeUrl: 'http://localhost/sample_ads/ad2.html',
        clickUrl: '/#/home',
        weighting: 1,
      },
    },

    {
      id: 'ghi',
      org_country_iso2: ['AU', 'GB'],
      targetOrgFields: [{"fieldPath": "enable_segen", "value": false}],
      targetRoleFields: [{"fieldPath": "is_admin", "value": true}],
      bannerConfig: {
        creative_name: "simple-text-link-v3",
        campaign_name: "brand-launch-may-2023",
        placements: ['home_cluster'],
        format: 'text',
        clickUrl: 'https://qcells.com/au/get-started/complete-energy-solution/ess',
        weighting: 1,
        textLinkLabel: 'QCELLS launches Q.HOME residential energy storage'
      },
    },

    {
      id: 'eee',
      org_country_iso2: ['AU', 'GB'],
      targetOrgFields: [{"fieldPath": "enable_segen", "value": false}],
      targetRoleFields: [{"fieldPath": "is_admin", "value": true}],
      bannerConfig: {
        creative_name: "simple-text-link-v4",
        campaign_name: "brand-launch-may-2023",
        placements: ['home_cluster'],
        format: 'text',
        clickUrl: 'https://rec.com',
        weighting: 1,
        textLinkLabel: 'REC launches new TwinPeaks Rance'
      },
    },
  ]
  */

import { FilterStruct } from 'conditions/types'
import { useConditionalStructs } from 'contexts/structs/hooks/useConditionalStructs'

const useAdStructs = () => {
  return useConditionalStructs<BannerConfig>('banner-ad', { recheck_secs: 20, accept_empty_conditions: true }).structs
}

export default useAdStructs

export type BannerConfig = {
  campaign_name: string
  creative_name: string
  format: 'text' | 'leaderboard' | 'triple_widescreen' | 'mobileLeaderboard'
  placements: string[]
  clickUrl: string
  weighting: number
  iframeUrl?: string
  textLinkLabel?: string
  conditions: FilterStruct[]
}
