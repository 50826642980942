// @ts-nocheck
import { call, cancel, fork, put, take, takeEvery } from 'redux-saga/effects'

import {
  CALCULATE_SYSTEM_IMMEDIATE,
  CALCULATION_PROCESS_START,
  CALCULATION_PROCESS_STATUS_UPDATE,
  clearCalculationStatus,
  SYSTEM_CALCULATION_REQUEST,
} from 'actions/designer'
import { PERFORMANCE_CALCULATORS_3RD_PARTY } from 'constants/calculators'
import { showNotification } from 'react-admin'

export interface CalculationRequestSideEffect {
  payload: { uuid: string; callback: Function; showSuccessNotification: boolean }
}

interface CalculateSystemSideEffect {
  payload: { uuid: string; callback: Function; full_calcs?: boolean; showSuccessNotification: boolean }
}

export function* handleCalculationRequest({ payload }: CalculationRequestSideEffect) {
  const system = window.editor.objectByUuid(payload.uuid)
  if (system) {
    try {
      yield put({ type: CALCULATION_PROCESS_START, payload: { uuid: payload.uuid } })
      yield put({ type: CALCULATE_SYSTEM_IMMEDIATE, payload })
    } catch (e) {
      const detail = e.message
      yield put(
        showNotification(window.translate('System calculations could not be applied') + ': ' + detail, 'warning')
      )
    }
  } else {
    yield put(showNotification('System no longer available', 'error'))
  }
}

export const calculateShadingQueue = (uuid: string) => window.ShadeHelper.calculateShadingQueue(uuid, true)
export const requestSystemCalculation = (uuid: string, full_calcs: boolean) =>
  window.SceneHelper.requestSystemCalculation(uuid, full_calcs)

export function* handleCalculateSystem({ payload }: CalculateSystemSideEffect) {
  let { uuid, callback, full_calcs } = payload || {}
  const editor = window.editor
  let system_uuid = uuid
  try {
    if (typeof system_uuid === 'undefined') {
      if (!editor.selectedSystem) {
        throw new Error('Create and/or Select a system to calculate.')
      }
      system_uuid = editor.selectedSystem?.uuid
    }

    var system = editor.objectByUuid(system_uuid)

    if (!system) {
      //@TODO: We should clear associated, pending timeouts whenever a system is deleted
      throw new Error('calculateSystem aborted... system not found.')
    }

    system.refreshAutomatedComponents()

    // If state is invalid, prevent calculations and show warning message instead
    // Be careful not to spam user with too many messages?
    var errors = system.validate()
    if (errors.length > 0) {
      throw new Error('Unable to run calculations: ' + errors.join(','))
    }

    // Re-populate generation_override if we have enough data available
    //
    // We could use either editor.scene.autoFacetsGeoJson or editor.scene.autoDesignGeoJson
    // For now we use autoFacetsGeoJson to avoid needing to calculate the center of each panel from autoDesignGeoJson
    // Requires: output_samples which we can extract from editor.scene.autoFacetsGeoJson/autoDesignGeoJson
    // In future we may store these in a stand-alone format but for now autoFacetsGeoJson works just fine
    // Points are in 3857 so we need to convert which is pretty slow and could be optimized at some stage
    // For now, convert the module positions to 3857 because there are fewer than the actual points
    // Only auto-populate generation_override if we are using 3rd party performance calculator (which is used for auto-design)
    if (
      !system.generation_override &&
      editor.scene.autoFacetsGeoJson &&
      system.calculator === PERFORMANCE_CALCULATORS_3RD_PARTY
    ) {
      var moduleKwStc = system.moduleType().kw_stc
      var outputSamples = editor.scene.autoFacetsGeoJson.features.filter((f) => f.geometry.type === 'Point')
      var annual_kwh = 0
      system.getModules().forEach((m) => {
        var outputSample = OsModule.getClosestOutputSample(m, outputSamples)
        annual_kwh += outputSample.properties.kwh_per_kw * moduleKwStc
      })
      editor.execute(
        new SetValueCommand(system, 'generation_override', [annual_kwh], Utils.generateCommandUUIDOrUseGlobal(), true)
      )
    }

    //@TODO: Only calculate if something has changed
    //   yield call(window.ShadeHelper.calculateShadingQueue, system_uuid, true) // useOldResultsIfAvailable: true
    yield call(calculateShadingQueue, system_uuid)
    // Beware: sometimes an earlier promise may return while another is processing in which case shading
    // in this async call will be incomplete. Abandon this call if shading incomplete and let the other call
    // trigger the save to backend
    if (system.calculator === 2 && window.ShadeHelper.panelsMissingShading(system, false, false).length > 0) {
      throw new Error(
        'Notice: Aborting SceneHelper.calculateSystem() because shading incomplete, wait until the next call'
      )
    }

    // If currently selected a ModuleGrid we should refresh shading annotations after shading recalc
    if (editor.selected && editor.selected.type === 'OsModuleGrid') {
      editor.signals.objectAnnotationChanged.dispatch(editor.selected)
      editor.signals.shadingUpdated.dispatch()
    }

    yield put({ type: CALCULATION_PROCESS_STATUS_UPDATE, payload: { uuid: system_uuid, status: 'waitingCalculation' } })

    //@ts-ignore
    const response = yield call(requestSystemCalculation, system_uuid, full_calcs)

    if (editor.history?.redos.length > 0) {
      throw new Error('Calculation did not finish before undo. Using previous calculation result.')
    }

    yield put({
      type: CALCULATION_PROCESS_STATUS_UPDATE,
      payload: { uuid: system_uuid, status: 'handlingCalculationSuccess' },
    })

    //@ts-ignore
    const result = yield call(() => window.SceneHelper.handleCalculationSuccess(response))
    const isSuccess = result.success

    if (isSuccess) {
      // refresh price lock warning
      yield call(() => window.WorkspaceHelper?.refreshPriceLockError(system))
      yield call(() => window.WorkspaceHelper?.refreshPaymentOptionWarnings(system))
      yield call(() => window.WorkspaceHelper?.updateEphemeralCalculationError(result.warnings))
      if (result.warnings?.length) {
        yield put(showNotification('System calculations applied with warnings', 'warning'))
      } else if (payload.showSuccessNotification) yield put(showNotification('System calculations applied', 'info'))
    } else {
      throw new Error('Unable to update calculation result')
    }

    // update redux so the payment options page can be aware of the list of currently calculating systems
    window.reduxStore.dispatch({
      type: 'MARK_SYSTEM_CALC_AS_COMPLETE',
      payload: {
        uuid: system_uuid,
      },
    })

    if (callback) yield call(() => callback(true))
  } catch (e) {
    console.warn(e)
    const detail = e.message || window.Designer.getErrorDetail(e, 'Unspecified Error.')
    console.warn('Error:' + e)
    yield put(showNotification(window.translate('System calculations could not be applied') + ': ' + detail, 'warning'))
    if (callback) yield call(() => callback(false))
  } finally {
    yield put(clearCalculationStatus(payload.uuid))
  }
}

export function* watchSystemCalculationRequest() {
  //@ts-ignore
  yield takeEvery(SYSTEM_CALCULATION_REQUEST, handleCalculationRequest)
}

const takeLatestBySystemUuid = (pattern: any, saga: any, ...args: any) =>
  fork(function* () {
    // hold a reference to each forked saga identified by the type property
    let queue: { [key: string]: any } = {}
    while (true) {
      const action = yield take(pattern)
      const { type, payload } = action
      if (queue[payload.uuid]) {
        yield cancel(queue[payload.uuid])
      }

      queue[payload.uuid] = yield fork(saga, ...args.concat(action))
    }
  })

export function* watchCalculateSystem() {
  //@ts-ignore
  yield takeLatestBySystemUuid(CALCULATE_SYSTEM_IMMEDIATE, handleCalculateSystem)
}
