import { useCallback, useMemo, useState } from 'react'

type Props<T> = {
  value: T
  onFocus?: Function
  onBlur?: Function
  onChange?: Function
  allowFocusedUpdates?: boolean
  noInternalValueUpdates?: boolean
}

type Ret<T> = {
  value: T
  onFocus: any
  onBlur: any
  onChange: any
  forceInnerUpdate: (value: T) => void
}

/**
 * Adds a mechanism to prevent updates to `onChange' while an input is focused.
 * The last update will be applied when the input is blurred.
 *
 * - noInternalValueUpdates: If true, the internal value will not be updated while focused. Important for <AutoComplete> components.
 */

export function useBlockUpdatesWhileFocused<T>({
  value,
  onBlur,
  onChange,
  onFocus,
  allowFocusedUpdates,
  noInternalValueUpdates,
}: Props<T>): Ret<T> {
  const [focused, setFocused] = useState(false)
  const [innerValue, setInnerValue] = useState<T>(value)

  const realValue = useMemo(() => {
    return !focused || allowFocusedUpdates ? value : innerValue
  }, [value, focused, allowFocusedUpdates, innerValue])

  const handleChange = useCallback(
    (e) => {
      // Get random change events from Autocomplete component
      if (!e && !focused) return
      // Cheap way of accepting event or value (as underlying final-form does also)
      const value = e?.target ? e.target.value : e
      if (focused && !noInternalValueUpdates) setInnerValue(value)
      if (onChange && (!focused || allowFocusedUpdates)) onChange(e, value)
    },
    [onChange, focused, allowFocusedUpdates]
  )

  const handleFocus = useCallback(
    (event) => {
      setInnerValue(value)
      setFocused(true)
      onFocus && onFocus(event)
    },
    [onFocus, value]
  )

  const handleBlur = useCallback(
    (event) => {
      setFocused(false)
      let dispatchValue = innerValue
      if (noInternalValueUpdates) {
        dispatchValue = event.target.value
      }
      if (onChange && !allowFocusedUpdates && dispatchValue !== value) {
        onChange(event, dispatchValue)
      }
      onBlur && onBlur(event)
    },
    [onBlur, allowFocusedUpdates, innerValue, realValue, onChange]
  )

  const forceInnerUpdate = useCallback((value: T) => {
    setInnerValue(value)
  }, [])

  return {
    value: realValue,
    onFocus: handleFocus,
    onBlur: handleBlur,
    onChange: handleChange,
    forceInnerUpdate,
  }
}
