import { Tooltip } from '@material-ui/core'
import { Help, MailOutlineOutlined } from '@material-ui/icons'
import { orgSelectors } from 'ducks/orgs'
import UserIcon from 'elements/UserIcon'
import { Chip, IconButton, styled } from 'opensolar-ui'
import { getNotifyEmails, openMailTo } from 'projectSections/sections/info/orgSharing/util'
import { useNotify, useTranslate } from 'ra-core'
import React, { useEffect, useMemo } from 'react'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import { ProjectType } from 'types/projects'
import { RowCentered } from '../../../styles'
import OwnerOrgContent from './OwnerOrgContent'

const OrgName = styled('h3', { name: 'OrgName' })({
  margin: '0 10px',
})

const MailBtn = styled(IconButton, { name: 'MailBtn' })({
  marginLeft: 'auto !important',
})

const HelpIcon = styled(Help, { name: 'HelpIcon' })(({ theme }) => ({
  height: 20,
  marginRight: 15,
  fill: theme.palette.grey[810],
}))

const ChipStyled = styled(Chip, { name: 'ChipStyled' })(({ theme }) => ({
  height: 'unset',
  backgroundColor: theme.palette.info.main,
  color: theme.palette.info.contrastText,
  fontWeight: 500,
  '& .MuiChip-label': {
    padding: '5px 8px',
  },
}))
interface PropTypes {
  project: ProjectType
}

const ExternalTeams: React.FC<PropTypes> = ({ project }) => {
  const form = useForm()
  const notify = useNotify()
  const translate = useTranslate()
  const org = useSelector(orgSelectors.getOrg)
  const orgId = useMemo(() => org?.id, [org])
  const connectedOrgs = useMemo(() => org?.connected_orgs?.filter((x) => x.is_active) || [], [org])
  const isOwnerOrg = useMemo(() => project.org_id === orgId, [orgId, project.org_id])
  const ownerOrg = useMemo(() => connectedOrgs.find((o) => o.org_id === project.org_id), [
    project.org_id,
    connectedOrgs,
  ])

  useEffect(() => {
    form.registerField('shared_with', () => {}, {})
  }, [])

  if (!!!connectedOrgs.length) return null
  return (
    <>
      {isOwnerOrg && org && orgId ? (
        <OwnerOrgContent connectedOrgs={connectedOrgs} project={project} orgId={orgId} orgName={org?.name} />
      ) : (
        <RowCentered>
          {ownerOrg && <UserIcon org={ownerOrg} />}
          <OrgName>{ownerOrg?.org_name}</OrgName>
          <ChipStyled label="Project Owner" color="info" />
          <Tooltip title={translate('This Organisation has shared this project with your own Organisation.')}>
            <HelpIcon />
          </Tooltip>
          <MailBtn
            color="secondary"
            variant="squared"
            onClick={() => {
              if (!ownerOrg || orgId === undefined || org?.name === undefined) return
              getNotifyEmails(orgId)
                .then((res) => {
                  openMailTo(res.data, ownerOrg.org_id, project, org?.name, notify)
                })
                .catch((err) => {
                  notify('Failed to retrieve connected org data.', 'warning')
                })
            }}
          >
            <MailOutlineOutlined />
          </MailBtn>
        </RowCentered>
      )}
    </>
  )
}

export default ExternalTeams
