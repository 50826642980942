import { useState } from 'react'
import type { LoanAgreementDialogPropTypes } from './LoanAgreementButton'
import { LoanAgreementDialog } from './LoanAgreementButton'

export type LoanAgreementDialogConfig = Omit<LoanAgreementDialogPropTypes, 'showDialog' | 'closeDialog'>

const useLoanAgreementDialog = () => {
  const [state, seState] = useState<
    { open: false; config: undefined } | { open: true; config: LoanAgreementDialogConfig }
  >({
    open: false,
    config: undefined,
  })

  const openDialog = (config: LoanAgreementDialogConfig) => {
    seState({
      open: true,
      config,
    })
  }

  const closeDialog = () => {
    if (state.open === false) {
      return
    }
    seState({
      open: false,
      config: undefined,
    })
  }

  const dialogNode = state.open ? (
    <LoanAgreementDialog
      showDialog={true}
      closeDialog={closeDialog}
      loanType={state.config.loanType}
      paymentOptionData={state.config.paymentOptionData}
      actionData={state.config.actionData}
      initialState={state.config.initialState}
    />
  ) : null
  return { isOpen: state.open, dialogNode, openDialog, closeDialog, type: 'LoanAgreementDialog' as const }
}

export default useLoanAgreementDialog
