import { useStandardProOrgConditionChecker } from 'conditions/hooks/useStandardProOrgConditionChecker'
import { FilterStruct } from 'conditions/types'
import lodash from 'lodash'
import { useEffect, useState } from 'react'
import { Struct, StructsLoadInfo } from '../types/types'
import { useStructs } from './useStructs'

type ConditionalStructsRes<T extends Record<string, any>> = StructsLoadInfo<T> & { unfilteredStructs: Struct<T>[] }

export const useConditionalStructs = <T extends Record<string, any> = Record<string, any>>(
  type: string,
  opts?: {
    condition_path?: string
    accept_empty_conditions?: boolean
    recheck_secs?: number
  }
): ConditionalStructsRes<T> => {
  const options = {
    condition_path: 'conditions',
    accept_empty_conditions: false,
    recheck_secs: 60,
    ...opts,
  }
  const { loadState, structs } = useStructs<T>(type)
  const checker = useStandardProOrgConditionChecker()
  const [forceCheck, setForceCheck] = useState(0)
  const [res, setRes] = useState<ConditionalStructsRes<T>>({
    loadState,
    structs: [],
    unfilteredStructs: structs,
  })

  useEffect(() => {
    if (options.recheck_secs) {
      const interval = setInterval(() => {
        setForceCheck((prev) => prev + 1)
      }, options.recheck_secs * 1000)
      return () => clearInterval(interval)
    }
  }, [options.recheck_secs])

  useEffect(() => {
    setRes((prev) => {
      const res = {
        loadState: loadState,
        structs: structs.filter((struct) => {
          const filters: FilterStruct[] | undefined = struct.data[options.condition_path]
          if (!filters?.length) return options.accept_empty_conditions
          return filters && checker(filters)
        }),
        unfilteredStructs: structs,
      }

      // Return old value if no change
      if (lodash.isEqual(prev, res)) return prev

      return res
    })
  }, [loadState, structs, structs.length, checker, forceCheck])

  return res
}
