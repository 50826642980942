import { jsx as _jsx } from "react/jsx-runtime";
import AcceptProposal from './acceptProposal/AcceptProposal';
import CheckoutAlertCta from './alert/CheckoutAlertCta';
import MakeCashflowDeposit from './cashflowDeposit/MakeCashflowDeposit';
import CheckoutActionDivider from './divider/CheckoutActionDivider';
import LoanAgreementAction from './loanAgreement/LoanAgreementAction';
import LoanApplication from './loanApplication/LoanApplication';
import AlertMessage from './message/Alert';
import Message from './message/Message';
import PhoenixApplication from './phoenix/PhoenixApplication';
import StipulationUpload from './stipulation/StipulationUpload';
import SunlightPrequalify from './sunlightPrequalify/SunlightPrequalify';
// TODO: clean up and support all CheckoutActionType before rolling out
var ActionTypeAndElementMapper = {
    AcceptProposal: AcceptProposal,
    CashFlowDeposit: MakeCashflowDeposit,
    Prequal: SunlightPrequalify,
    Divider: CheckoutActionDivider,
    AlertCTA: CheckoutAlertCta,
    LoanApplication: LoanApplication,
    FinanceStipulations: StipulationUpload,
    LoanAgreement: LoanAgreementAction,
    PhoenixApplication: PhoenixApplication,
    Message: Message,
    Alert: AlertMessage,
};
export var createActionElement = function (_a) {
    var config = _a.config, elementData = _a.elementData, proposalData = _a.proposalData, onAction = _a.onAction, translate = _a.translate, options = _a.options;
    var type = elementData.type;
    var ActionElement = ActionTypeAndElementMapper[type];
    if (ActionElement === undefined) {
        throw new Error('Invalid checkout action type');
    }
    return (_jsx(ActionElement, { config: config, options: options, elementData: elementData, proposalData: proposalData, translate: translate, onAction: onAction }));
};
export var SUPPORTED_ELEMENT_TYPES = [
    'AcceptProposal',
    'CashFlowDeposit',
    'Prequal',
    'Divider',
    'LoanApplication',
    'PhoenixApplication',
    'AlertCTA',
    'FinanceStipulations',
    'LoanAgreement',
    'Message',
    'Alert',
];
