import { Dialog } from '@material-ui/core'
import { viewModeSelectors } from 'ducks/viewMode'
import ResponsiveVimeoPlayer from 'elements/ResponsiveVimeoPlayer'
import { ORIENTATION_VIDEO_URLS } from 'persistentContent/promoDialog/useUserAgreementPromo'
import { FC } from 'react'
import { useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

const useStyles = makeOpenSolarStyles((theme) => ({
  dialog: {
    width: '100%',
    height: '100%',
    maxWidth: 840,
    maxHeight: 472,
  },
}))

type PropsType = {
  isOpen: boolean
  closeDialog: () => void
}

const OrientationVideoDialog: FC<PropsType> = ({ isOpen, closeDialog }) => {
  const classes = useStyles()
  const isUserLite = useSelector(viewModeSelectors.isUserLite)

  return (
    <Dialog open={isOpen} onClose={closeDialog} id="orientation-video-dialog" classes={{ paper: classes.dialog }}>
      <ResponsiveVimeoPlayer
        videoUrl={isUserLite ? ORIENTATION_VIDEO_URLS.lite : ORIENTATION_VIDEO_URLS.full}
        trackingSource="orientation"
      />
    </Dialog>
  )
}

export default OrientationVideoDialog
