import { projectListViewSelectors, ViewType } from 'ducks/projectListViews'
import TourTooltip from 'elements/tooltip/TourTooltip'
import { TourConfigType } from 'persistentContent/tours/types'
import { useTranslate } from 'ra-core'
import { FC, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'

const StepTooltip: FC = (props) => {
  const params: { id: string } = useParams()
  const translate = useTranslate()
  const views = useSelector(projectListViewSelectors.getProjectListViewData)
  const viewType = useMemo(
    () => (params?.id ? views?.find((x) => x.id === Number(params?.id))?.view_type : ViewType.Table),
    [views]
  )
  return (
    <TourTooltip
      title={translate(viewType === ViewType.Table ? 'New Table View' : 'Keep Your Projects Flowing')}
      subtext={translate(
        viewType === ViewType.Table
          ? 'Gain insights into the status of your projects using our updated project list page.'
          : 'Visualize progress at a glance—Kanban View keeps your projects flowing.'
      )}
      {...props}
    />
  )
}

const ProjectList: TourConfigType = {
  spotlights: [{ targetId: 'Project-List-Section' }],
  tooltips: [
    {
      targetId: 'Project-List-Section',
      placement: 'top-start',
      component: StepTooltip,
    },
  ],
}

export default ProjectList
