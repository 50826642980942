import * as Sentry from '@sentry/react'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { showCheckoutDialog } from 'ducks/myEnergy'
import type { StipulationActionType } from 'myenergy/selectionComponent/financeStipulations/types'
import { CheckoutActionCallback } from 'opensolar-checkout'
import { useCallback, useContext } from 'react'
import { useNotify, useTranslate } from 'react-admin'
import { useDispatch } from 'react-redux'
import ActiveDialogContext from '../dialogs/ActiveDialogContext'

const useShowUploadStipulationDialogCallback = (): CheckoutActionCallback => {
  const { showDialog } = useContext(ActiveDialogContext)
  const translate = useTranslate()
  const dispatch = useDispatch()
  const notify = useNotify()
  return useCallback(async ({ elementData, proposalData, additionalActionData }) => {
    if (elementData.type !== 'FinanceStipulations') {
      Sentry.captureException(new Error('Invalid element data for show upload stipulation dialog callback'))
      return
    }
    const action = additionalActionData?.action
    if ((action !== 'uploadMoreStipulation' && action !== 'uploadStipulation') || additionalActionData === undefined) {
      Sentry.captureException(
        new Error(`Invalid additionalActionData for show upload stipulation dialog callback. action: ${action}`)
      )
      return
    }
    const stipulation = additionalActionData.stipulation as StipulationActionType
    if (action === 'uploadMoreStipulation') {
      logAmplitudeEvent('stip_upload_form_viewed_again', { integration: 'loanpal', page: 'my_energy' })
    }

    logAmplitudeEvent('stip_upload_form_viewed', { mode: 'proposal', page: 'my_energy' })

    dispatch(
      showCheckoutDialog({
        type: 'UploadStipulationDialog',
        config: {
          stipulation,
        },
      })
    )
  }, [])
}

export default useShowUploadStipulationDialogCallback
