import { Filter, updateProjectView } from 'ducks/projectListViews'
import lodash from 'lodash'
import restClient from 'restClient'
import { ProjectFiltersType } from './filtering/type'

const restClientInstance = restClient(window.API_ROOT + '/api')
export function checkOSProjectFiltersEqual(filters, savedFiltersArray) {
  //Temporarily remove search query for filter check, we should remove this logic in the event that we decide to save search queries to view data
  let filterWithoutQuery = { ...filters }
  if ('q' in filterWithoutQuery) delete filterWithoutQuery.q
  const filterWithoutQueryArrayCheck = mapFilterObjectToFilterArray(filterWithoutQuery)
  const orderedFiltersWithoutQueryArray = orderFiltersByField(filterWithoutQueryArrayCheck)

  const filtersArray = mapFilterObjectToFilterArray(filters)
  const orderedFiltersArray = orderFiltersByField(filtersArray)
  const orderedSavedFiltersArray = orderFiltersByField(savedFiltersArray)
  return {
    equal: lodash.isEqual(orderedFiltersWithoutQueryArray, orderedSavedFiltersArray),
    newFilters: orderedFiltersArray,
  }
}

export function mapFilterArrayToFilterObject(filtersArray: Filter[]): ProjectFiltersType {
  return filtersArray?.reduce((acc, filter) => {
    if (filter.field === 'milestone') {
      if (Array.isArray(filter.value)) {
        acc[filter.field] = filter.value.join(',')
      } else {
        acc[filter.field] = filter.value
      }
    } else {
      acc[filter.field] = filter.value
    }
    return acc
  }, {})
}

export function mapFilterObjectToFilterArray(filters: { [key: string]: string }): any[] {
  return Object.entries(filters).map(([key, value]) => {
    if (key === 'milestone') {
      return {
        field: key,
        operator: typeof value === 'string' ? 'in' : '=',
        value: typeof value === 'string' ? value.split(',').map(Number) : value,
      }
    }

    return {
      field: key,
      operator: '=',
      value,
    }
  })
}

function orderFiltersByField(array) {
  return array?.sort((a, b) => a.field.localeCompare(b.field))
}

export const saveViews = (view, orgId): Promise<any> => {
  return restClientInstance('CUSTOM_POST', 'custom', {
    url: `orgs/${orgId}/views/`,
    data: view,
  })
    .then((response) => {
      return response
    })
    .catch((e) => {
      throw e
    })
}

export const editViews = (view, viewId, orgId): Promise<any> => {
  return restClientInstance('CUSTOM_PUT', 'custom', {
    url: `orgs/${orgId}/views/${viewId}/`,
    data: view,
  })
    .then((response) => {
      return response
    })
    .catch((e) => {
      throw e
    })
}

export const deleteViews = (viewId, orgId): Promise<any> => {
  return restClientInstance('CUSTOM_DELETE', 'custom', {
    url: `orgs/${orgId}/views/${viewId}/`,
  })
    .then((response) => {
      return response
    })
    .catch((e) => {
      throw e
    })
}

export const getViewCount = (view, dispatch, notify) => {
  const filterObject = mapFilterArrayToFilterObject(view.view_settings?.filters)
  restClientInstance('GET_LIST', 'projects/view_count', {
    filter: filterObject,
  })
    .then((response) => {
      const newCount = response?.data?.count
      if (newCount !== undefined) dispatch(updateProjectView({ ...view, view_count: newCount }))
    })
    .catch((e) => {
      notify('Error retrieving view count: ' + e, 'warning')
    })
}
