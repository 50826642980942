import { authSelectors } from 'ducks/auth'
import { useEndpointPoller } from 'hooks/useEndpointPoller'
import { recordGenericUserAction } from 'persistentContent/tours/tools/common'
import { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { UserAction } from './types'

export type PromosContextType = {
  actions: UserAction[]
  checkAction: (action_name: string) => boolean
  recordAction: (action_name: string) => void
  loaded: boolean
}

export type PromosLoadState = 'not-loaded' | 'loaded' | 'reloading'

const POLL_TIME = 5 * 60 * 1000 // 5m
export const UserActionsCore = createContext<PromosContextType>({
  actions: [],
  checkAction: () => false,
  recordAction: () => {},
  loaded: false,
})

export const UserActionsContext = ({ children }) => {
  const userId = useSelector(authSelectors.getCurrentUserId)
  const [actions, setActions] = useState<UserAction[]>([])
  const [loaded, setLoaded] = useState(false)

  const checkAction = useCallback((action_name: string) => actions.some((a) => a.action_name === action_name), [
    actions,
  ])

  const recordAction = useCallback(
    (action_name: string) => {
      if (!userId) {
        throw new Error('UserActionsContext: recordAction called without a userId')
      }
      recordGenericUserAction(userId, action_name).then(() => {
        setActions((actions) => [...actions, { action_name, created_date: new Date().toISOString() }])
      })
    },
    [userId]
  )

  // Clear actions when user logs out
  useEffect(() => {
    if (!userId) {
      setActions([])
      setLoaded(false)
    }
  }, [userId])

  const onActionsChange = (actions) => {
    setActions(actions)
    setLoaded(true)
  }

  const endpoint = `users/${userId}/actions/`
  useEndpointPoller(endpoint, POLL_TIME, onActionsChange, { disabled: !userId })

  const value = useMemo(() => ({ actions, checkAction, recordAction, loaded }), [
    actions,
    checkAction,
    recordAction,
    loaded,
  ])
  return <UserActionsCore.Provider value={value}>{children}</UserActionsCore.Provider>
}
