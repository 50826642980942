import { makeStyles } from '@material-ui/core'
import { styled } from 'opensolar-ui'
import React, { ReactNode } from 'react'

const useStyles = makeStyles({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    padding: '40px 24px',
  },
  mainText: {
    fontWeight: 500,
    fontSize: 16,
  },
  subText: {
    margin: 0,
  },
})

const NoticeIcon = styled('div', { name: 'NoticeIcon' })(({ theme }) => ({
  width: 25,
  height: 'unset',
  padding: 15,
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.grey[810],
  borderRadius: 8,
}))

interface PropTypes {
  icon?: ReactNode
  mainText?: string
  subText?: string | ReactNode
}

const Notice: React.FC<PropTypes> = ({ icon, mainText, subText }) => {
  const classes = useStyles()
  if (!icon && !mainText && !subText) return null
  return (
    <div className={classes.content}>
      {icon && <NoticeIcon>{icon}</NoticeIcon>}
      {mainText && <p className={classes.mainText}>{mainText}</p>}
      {subText && <>{typeof subText === 'string' ? <p className={classes.subText}>{subText}</p> : subText}</>}
    </div>
  )
}
export default Notice
