import { useTranslate } from 'react-admin'
import TemplateLibraryCard from './TemplateLibraryCard'

const ClassicTemplateCard = (props) => {
  const translate = useTranslate()

  return (
    <TemplateLibraryCard
      tag="Classic"
      header={translate('Classic OpenSolar Proposal')}
      body={translate('OpenSolar Legacy Template')}
      imgSrc={`${window.PUBLIC_URL}/images/proposal/template_classic.png`}
      {...props}
    />
  )
}

export default ClassicTemplateCard
