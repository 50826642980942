import * as Sentry from '@sentry/react'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { showCheckoutDialog } from 'ducks/myEnergy'
import { ActionDataType } from 'myenergy/selectionComponent/loanApplicationButton/types'
import { CheckoutActionCallback, LoanAgreementType, ProposalDataType } from 'opensolar-checkout'
import { useCallback } from 'react'
import { useNotify } from 'react-admin'
import { useDispatch } from 'react-redux'
import restClient from 'restClient'
import { PaymentOptionType } from 'types/paymentOptions'

var restClientInstance = restClient(window.API_ROOT + '/api')

const useMosaicCallback = () => {
  const dispatch = useDispatch()
  return useCallback(
    async ({
      actionData,
      proposalData,
    }: {
      actionData: LoanAgreementType['actionData']
      proposalData: ProposalDataType
    }) => {
      logAmplitudeEvent('integrated_finance_loan_agreement_started', { integration: 'mosaic', screen: 'my_energy' })
      await restClientInstance('CUSTOM_GET', 'custom', {
        url:
          'orgs/' +
          actionData.org_id +
          '/projects/' +
          actionData.project_id +
          '/systems/' +
          actionData.system_uuid +
          '/' +
          proposalData.selectedPaymentOption.id +
          '/mosaic/loan_agreement/?method=Link',
      })
        .then((res: any) => {
          console.log('res', res)
          if (res.data?.success && res.data?.url) {
            dispatch(
              showCheckoutDialog({
                type: 'LoanAgreementDialog',
                config: {
                  loanType: 'mosaic_application',
                  paymentOptionData: proposalData.selectedPaymentOption as PaymentOptionType,
                  actionData: actionData as ActionDataType,
                  initialState: {
                    docUrl: res.data.url,
                  },
                },
              })
            )
            logAmplitudeEvent('integrated_finance_loan_agreement_ready', { integration: 'mosaic', screen: 'my_energy' })
          }
        })
        .catch((err: any) => {
          console.log('err', err)
          const errorMessage = err?.body?.message
            ? err.body.message
            : 'Something went wrong and we are unable to bring you to your Loan Agreement'
          dispatch(
            showCheckoutDialog({
              type: 'LoanAgreementDialog',
              config: {
                loanType: 'mosaic_application',
                paymentOptionData: proposalData.selectedPaymentOption as PaymentOptionType,
                actionData: actionData as ActionDataType,
                initialState: {
                  errorMsg: errorMessage,
                },
              },
            })
          )

          logAmplitudeEvent('integrated_finance_loan_agreement_error', { integration: 'mosaic', screen: 'my_energy' })
        })
    },
    []
  )
}

const useLoanpalCallback = () => {
  const notify = useNotify()
  return useCallback(
    async ({
      actionData,
      proposalData,
    }: {
      actionData: LoanAgreementType['actionData']
      proposalData: ProposalDataType
    }) => {
      await restClientInstance('CUSTOM_GET', 'custom', {
        url:
          'orgs/' +
          actionData.org_id +
          '/projects/' +
          actionData.project_id +
          '/systems/' +
          actionData.system_uuid +
          '/' +
          proposalData.selectedPaymentOption.id +
          '/loanpal_docs/',
      })
        .then((res: any) => {
          console.log('res', res)
          if (res.data?.success) {
            logAmplitudeEvent('integrated_finance_loan_agreement_sent', { integration: 'loanpal', screen: 'my_energy' })
            notify(
              'Your Loan Documents will be sent to your email address shortly. This may take up to two minutes.',
              'success'
            )
          }
        })
        .catch((err: any) => {
          console.log('err', err)
          if (err?.body?.message) {
            notify(err.body.message, 'warning')
          } else {
            notify('Something went wrong and we are unable to send your Loan Agreement', 'warning')
          }
          logAmplitudeEvent('integrated_finance_loan_agreement_error', { integration: 'loanpal', screen: 'my_energy' })
        })
    },
    []
  )
}

const useDividendCallback = () => {
  const dispatch = useDispatch()
  const notify = useNotify()
  return useCallback(
    async ({
      actionData,
      proposalData,
    }: {
      actionData: LoanAgreementType['actionData']
      proposalData: ProposalDataType
    }) => {
      await restClientInstance('CUSTOM_GET', 'custom', {
        url:
          'orgs/' +
          actionData.org_id +
          '/projects/' +
          actionData.project_id +
          '/systems/' +
          actionData.system_uuid +
          '/' +
          proposalData.selectedPaymentOption.id +
          '/dividend/loan_docs/',
      })
        .then((res: any) => {
          console.log('res', res)
          if (res.data?.success) {
            dispatch(
              showCheckoutDialog({
                type: 'LoanAgreementDialog',
                config: {
                  loanType: 'dividend_application',
                  paymentOptionData: proposalData.selectedPaymentOption as PaymentOptionType,
                  actionData: actionData as ActionDataType,
                  initialState: {
                    pollCount: 1,
                  },
                },
              })
            )
          } else {
            notify('Something went wrong and we are unable to send your Loan Agreement', 'warning')
            logAmplitudeEvent('integrated_finance_loan_agreement_error', {
              integration: 'dividend',
              screen: 'my_energy',
            })
          }
        })
        .catch((err: any) => {
          console.log('err', err)
          if (err?.body?.message) {
            notify(err.body.message, 'warning')
          } else {
            notify('Something went wrong and we are unable to send your Loan Agreement', 'warning')
          }
          logAmplitudeEvent('integrated_finance_loan_agreement_error', { integration: 'dividend', screen: 'my_energy' })
        })
    },
    []
  )
}

const useLoanAgreementActionCallback = (): CheckoutActionCallback => {
  const handleMosaicClick = useMosaicCallback()
  const handleLoanpalClick = useLoanpalCallback()
  const handleDividendClick = useDividendCallback()

  return useCallback(async ({ elementData, proposalData, additionalActionData }) => {
    if (elementData.type !== 'LoanAgreement') {
      Sentry.captureException(new Error('Invalid element data for LoanAgreement callback'))
      return
    }

    const { actionData, loanType } = elementData

    if (loanType === 'mosaic_application') await handleMosaicClick({ actionData, proposalData })
    else if (loanType === 'loanpal_application') await handleLoanpalClick({ actionData, proposalData })
    else if (loanType === 'dividend_application') await handleDividendClick({ actionData, proposalData })

    Sentry.captureException(new Error(`Invalid loanType on calling LoanAgreementActionCallback. loanType: ${loanType}`))
    return
  }, [])
}

export default useLoanAgreementActionCallback
