import { PROJECT_ERRORS_ADD, PROJECT_ERRORS_CLEAR, PROJECT_ERRORS_DELETE } from 'actions/project'
import { Severities } from 'elements/notificationVariants'
import remove from 'lodash/remove'
import { Reducer } from 'redux'
import { RootState } from 'types/state'
import { DynamicActionInfo } from 'types/ui/DynamicActionInfo'

export type StdErrorSource =
  | 'output'
  | 'plugin'
  | 'design'
  | 'autoApply'
  | 'utility-message'
  | 'buildablePanels'
  | 'design-rule'
  | 'lyra-export'
  | 'payments'
  | 'calculations'
  | 'lastCalc'
  | 'payment_cashflow'
  | 'sharing'
  | 'commercial_requirements'

export type ProjErrorCategory =
  | 'system'
  | 'module'
  | 'inverter'
  | 'battery'
  | 'other'
  | 'mounting'
  | 'structural'
  | 'price'
  | 'incentive'
  | 'payment_option'
  | 'standalone'
  | 'utility-tariff'
  | 'proposal-header'
  | 'payment_cashflow'
  | 'battery_control_scheme'
  | 'commercial_requirements' // commercial_requirements

export type ProjectErrorIdent = {
  key: string
  systemId?: string
  componentId?: string
  source: StdErrorSource
}

type BaseErrorType = ProjectErrorIdent & {
  severity: SeverityType
  category: ProjErrorCategory | ProjErrorCategory[]
  // filter?: Function
  options?: {
    actions?: DynamicActionInfo[]
  }
  field?: string
  suppresses?: string[]
}
export type StdEntityErrorType = BaseErrorType & {
  source: StdErrorSource
  messageType: 'entityError'
  message: EntityErrorInfo
  messageParams?: { [key in string]: string | number }
}
export type StdErrorType = BaseErrorType & {
  source: StdErrorSource
  messageType: 'text'
  message: string
  messageParams?: { [key in string]: string | number }
}

export type StdFieldErrorType = BaseErrorType & {
  source: StdErrorSource
  messageType: 'fieldError'
  message: Record<string, string[]>
}

export type SharingErrorType = BaseErrorType & {
  source: 'sharing'
  messageType: 'sharingError'
  message: SharingErrorMsg
}
export type CommercialRequirementsErrorType = BaseErrorType & {
  source: 'commercial_requirements'
  messageType: 'commercial_requirements'
  message: string
}

export type ProjectErrorType =
  | SharingErrorType
  | StdFieldErrorType
  | StdErrorType
  | StdEntityErrorType
  | CommercialRequirementsErrorType
/**
 * @deprecated Please use `ProjectErrorType` instead of `ErrorType`
 */
export type ErrorType = ProjectErrorType

export type ProjErrorSource = ProjectErrorType['source']

export interface SharingErrorMsg {
  payment_options?: EntityErrorInfo[] | EntityErrorInfo
  pricing_schemes?: EntityErrorInfo[] | EntityErrorInfo
  components?: {
    batteries: EntityErrorInfo[] | EntityErrorInfo
    inverters: EntityErrorInfo[] | EntityErrorInfo
    module: EntityErrorInfo[] | EntityErrorInfo
    others: EntityErrorInfo[] | EntityErrorInfo
  }
  tariffs?: string
}

export interface EntityErrorInfo {
  title: string
  url: string
}

export type SeverityType = Severities | 'basic'
export const SeverityOrder: SeverityType[] = ['error', 'warning', 'info', 'success', 'basic']

const errorMatchesIdent = (error: ProjectErrorType, ident: ProjectErrorIdent) => {
  return (
    (ident.key === 'any' || error.key === ident.key) &&
    (ident.systemId === 'any' || error.systemId === ident.systemId) &&
    (ident.componentId === 'any' || error.componentId === ident.componentId) &&
    error.source === ident.source
  )
}

const isErrorAlreadyExist = (payload: ProjectErrorType, previousState: ProjectErrorType[]) => {
  return !!previousState.find(
    (error: ProjectErrorType) => errorMatchesIdent(error, payload) && error.message === payload.message
  )
}

export const projectErrorsReducer: Reducer<ProjectErrorType[]> = (
  previousState: ProjectErrorType[] = [],
  { type, payload }
) => {
  if (type === PROJECT_ERRORS_ADD) {
    if (isErrorAlreadyExist(payload, previousState)) {
      return previousState
    } else {
      const newState = [...previousState]
      //add/update error
      remove(newState, (error: ProjectErrorType) => errorMatchesIdent(error, payload))
      return [...newState, payload]
    }
  } else if (type === PROJECT_ERRORS_DELETE) {
    const isErrorExist = previousState.find((error) => errorMatchesIdent(error, payload))

    if (isErrorExist) {
      const newState = [...previousState]
      remove(newState, (error: ProjectErrorType) => errorMatchesIdent(error, payload))
      return newState
    } else {
      return previousState
    }
  } else if (type === PROJECT_ERRORS_CLEAR) {
    if (payload && typeof payload.filter === 'function') {
      const newState = [...previousState]
      remove(newState, payload.filter)
      return newState
    } else {
      //clear all
      return []
    }
  } else {
    return previousState
  }
}

export const projectErrorSelector = {
  getAllErrors: (state: RootState): ProjectErrorType[] => {
    return state.project?.errors
  },
}
/**
 * Maintaining temporary legacy support for older calculation error format (last_calculation_error, lastCalc)
 * This will only remaining to support the old error handling until the 1st of March 2025
 * Errors should be handled as the new format
 * @deprecated
 */
const legacyCalcSource = 'lastCalc'

export const isCalcError = (error: ProjectErrorType): error is StdFieldErrorType => {
  return ('source' in error && error.source === 'calculations') || error.source === legacyCalcSource
}
