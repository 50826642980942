import { Box, makeStyles } from '@material-ui/core'
import { OpenSolarThemeType } from 'Themes'
import { orgSelectors } from 'ducks/orgs'
import ProjectName from 'elements/ProjectName'
import React from 'react'
import { Datagrid, FunctionField, List } from 'react-admin'
import { Form } from 'react-final-form'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { currencySymbolForCountry, formatCurrencyWithSymbol } from 'util/misc'
import MilestoneStepper from './MilestoneStepper'
import ProjectsFilter from './ProjectsFilter'
import ProjectsTableHeader from './ProjectsTableHeader'

type PropTypes = {}

const useStyles = makeStyles<OpenSolarThemeType>((theme) => ({
  projectCell: {
    lineHeight: 1.5,
    margin: '8px 0',
  },
  projectAddress: {
    fontWeight: 'bold',
  },
  contact: {
    fontSize: 'small',
  },

  bold: {
    fontWeight: 500,
  },

  amountPayable: {
    color: theme.greyMid1,
  },
}))

const Projects: React.FC<PropTypes> = (props) => {
  const classes = useStyles()
  const countryIso2 = useSelector(orgSelectors.getOrgIso2)
  const currencySymbol = currencySymbolForCountry(countryIso2)

  return (
    <Form
      onSubmit={() => {}}
      render={() => (
        <Box>
          <List
            hasSearch={true}
            sort={{ field: 'milestone', order: 'ASC' }}
            perPage={20}
            toolbar={<div></div>}
            filters={<ProjectsFilter />}
            hideBreadCrumbs={true}
            resource="cashflow_projects"
            basePath="cashflow_projects"
            id="cashflow_projects"
            hideTitle={true}
          >
            <Datagrid
              // @ts-ignore
              header={(stuff) => <ProjectsTableHeader />}
            >
              <FunctionField
                source="address"
                sortable={false}
                label={'Project'}
                render={(record) => (
                  <Link
                    to={`/projects/${record?.id}/payments`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.bold}
                  >
                    <ProjectName address={record?.address} />
                  </Link>
                )}
              />

              <FunctionField
                source="assigned_role"
                sortable={true}
                label={'Assigned Team Member'}
                render={(record) => <div>{record?.assigned_role?.display}</div>}
              />

              <FunctionField
                source="payment_type"
                sortable={true}
                label={'Payment Type'}
                render={(record) => <div>{record?.payment_type}</div>}
              />

              <FunctionField
                source="expected_milestone_payments"
                sortable={true}
                label={'Milestone'}
                render={(record) => (
                  <MilestoneStepper
                    milestones={record?.expected_milestone_payments}
                    address={record.address}
                    projectId={record.id}
                  />
                )}
              />

              <FunctionField
                source="amount"
                sortable={true}
                label={'Amount'}
                render={(record) => (
                  <div>
                    <span className={classes.bold}>
                      {formatCurrencyWithSymbol(record.total_amount_paid, currencySymbol)} /{' '}
                    </span>
                    <span className={classes.amountPayable}>
                      {formatCurrencyWithSymbol(record.total_amount_payable, currencySymbol)}
                    </span>
                  </div>
                )}
              />
            </Datagrid>
          </List>
        </Box>
      )}
    />
  )
}

export default Projects
