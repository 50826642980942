import { useFeatureFlag } from 'util/split'
import { useUxVersion } from './useUxVersion'

//this and all its implementations should ideally be removed once all UI 3 flags are centralized/applied throughout the app
export const useNewProjectsList = () => {
  const ui3Enabled = useUxVersion() === 3
  const projList3Enabled = useFeatureFlag('project_list_v3', 'on')
  if (projList3Enabled === undefined) return undefined
  return ui3Enabled && projList3Enabled
}
