import { useTranslate } from 'ra-core'
import React, { useMemo } from 'react'
import {
  EdgeStage,
  OtherWrapper,
  StageBtn,
  StageBtnLabel,
  StageWrapper,
  StyledMenuItem,
  StyledSelect,
  Wrapper,
} from './styles'

export const enum StageBtnState {
  past = 0,
  active = 1,
  future = 2,
}

interface PropTypes {
  activeStageId?: number | string
  stages: {
    id: number | string
    title: string
    status: StageBtnState | undefined
  }[]
  otherStages?: any[]
  handleChange: (stageId: number | string) => void
}

const StageStepper: React.FC<PropTypes> = ({ activeStageId, stages, handleChange, otherStages }) => {
  const translate = useTranslate()
  const otherActive = useMemo(
    () => (activeStageId && otherStages ? otherStages.find((x) => x.id === activeStageId) : undefined),
    [activeStageId, otherStages]
  )
  if (!stages || !stages.length) return null
  return (
    <Wrapper className="os-scroll">
      {stages.map((stage, i) => {
        const atEdge = !(i !== 0 && i !== stages.length - 1)
        return (
          <StageWrapper key={'stage-' + stage.id}>
            {atEdge && <EdgeStage isStart={i === 0} state={stage.status} />}
            <StageBtn
              disableRipple
              variant="outlined"
              color="default"
              onClick={() => {
                handleChange(stage.id)
              }}
              state={stage.status}
            >
              <StageBtnLabel isFirst={i === 0}>{stage.title}</StageBtnLabel>
            </StageBtn>
          </StageWrapper>
        )
      })}
      {!!otherStages?.length && (
        <OtherWrapper>
          <StyledSelect
            value={activeStageId}
            variant="outlined"
            displayEmpty
            isActive={!!otherActive}
            renderValue={(value) => {
              let emptyText = translate('Other Stages')
              const selectedStage = otherStages?.find((x) => x.id === value)
              if (!value) return emptyText
              return selectedStage ? selectedStage.title : emptyText
            }}
          >
            {otherStages?.map((stage) => (
              <StyledMenuItem
                key={'stage-' + stage.id}
                value={stage.id}
                onClick={() => {
                  handleChange(stage.id)
                }}
              >
                {stage.title}
              </StyledMenuItem>
            ))}
          </StyledSelect>
        </OtherWrapper>
      )}
    </Wrapper>
  )
}

export default StageStepper
