"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OsView = void 0;
var logger_1 = require("../util/logger");
var OsView = /** @class */ (function () {
    function OsView(sdk, config, origConfig) {
        var _this = this;
        this.sdk = sdk;
        this.config = config;
        this.origConfig = origConfig;
        this.logger = new logger_1.Logger('OSSDK.View');
        this.readyPromise = new Promise(function (resolve) {
            _this.readyResolve = resolve;
        });
    }
    OsView.prototype.spawn = function (parent) {
        // Some props we don't want to pass to the SPA
        var config = __assign(__assign({}, this.origConfig), { master: !this.config.master || this.sdk.peers.hasPeers.value ? undefined : false });
        var iframeSrc = this.config.hostname_spa + '?config=' + encodeURIComponent(JSON.stringify(config));
        // if this iFrame already exists we will not add it again.
        // this check should theoreretically not be required and it may cause problems if you really do
        // want to have the exact same project embeded more than once on the page, but for are trying to avoi
        // problems with multiple iframes being added to the page, possibly due to hot-reload or other reasons.
        var existingIframe = document.querySelector("iframe[src=\"".concat(iframeSrc, "\"]"));
        var element;
        if (existingIframe) {
            this.logger.warn('Skip adding iframe, use existing iframe because it already exists with exact same src.');
            element = existingIframe;
        }
        else {
            element = document.createElement('iframe');
            element.src = this.config.hostname_spa + '?config=' + encodeURIComponent(JSON.stringify(config));
            element.width = '100%';
            element.height = '100%';
            element.style.setProperty('border', 'none');
            parent.appendChild(element);
        }
        this.assignElement(element);
    };
    OsView.prototype.assignElement = function (element) {
        var _this = this;
        this.element = element;
        this.peerCleanup = this.sdk.peers.addPeer(element.contentWindow, false, false, function (loaded, ready) {
            if (loaded && ready)
                _this.readyResolve();
        });
    };
    OsView.prototype.ready = function () {
        return this.readyPromise;
    };
    OsView.prototype.onReadinessChange = function (loaded, ready) { };
    OsView.prototype.destroy = function () {
        this.peerCleanup();
    };
    OsView.prototype.setOverrides = function (overrides) {
        this.send({
            type: 'ViewSetViewInfo',
            overrides: overrides,
        });
    };
    OsView.prototype.send = function (msg) {
        this.sdk.peers.send(this.element.contentWindow, msg);
    };
    return OsView;
}());
exports.OsView = OsView;
