import { MenuItem, Select } from '@material-ui/core'
import { Button, styled } from 'opensolar-ui'
import { StageBtnState } from './StageStepper'

export const Wrapper = styled('div', { name: 'Wrapper' })({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  overflowX: 'auto',
  overflowY: 'hidden',
  height: 60,
})

export const StageWrapper = styled('div', { name: 'StageWrapper' })({
  flexGrow: 1,
  display: 'flex',
  position: 'relative',
  flexBasis: '100%',
})

const getEdgeBgColor = (state: StageBtnState | undefined, theme) => {
  switch (state) {
    case StageBtnState.active:
      return theme.palette.info.main
    case StageBtnState.future:
      return theme.palette.secondary.light
    case StageBtnState.past:
      return theme.palette.blue[600]
    default:
      return undefined
  }
}
export const EdgeStage = styled('div', { name: 'EdgeStage' })<{
  isStart: boolean
  state?: StageBtnState
}>(({ state, theme, isStart = false }) => ({
  position: 'absolute',
  width: 25,
  height: '100%',
  boxSizing: 'border-box',
  zIndex: 1,
  borderRadius: 3,
  left: isStart ? 0 : undefined,
  right: isStart ? undefined : -1,
  backgroundColor: getEdgeBgColor(state, theme),
  ...(state === StageBtnState.active
    ? {
        border: `1px solid ${theme.palette.info.contrastText}`,
        ...(isStart
          ? {
              borderRight: 'none',
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            }
          : {
              borderLeft: 'none',
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }),
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none !important',
        },
      }
    : {}),
}))

const getStageColor = (state: StageBtnState | undefined, theme) => {
  switch (state) {
    case StageBtnState.active:
      return theme.palette.info.contrastText + '!important'
    case StageBtnState.past:
      return '#fff !important'
    default:
      return undefined
  }
}
export const StageBtn = styled(Button, { name: 'StageBtn' })<{
  state?: StageBtnState
}>(({ state, theme }) => ({
  fontSize: 14,
  height: '50px !important',
  flexGrow: 1,
  border: 'none !important',
  margin: '0 5px !important',
  color: getStageColor(state, theme),
  '&:before': {
    content: `''`,
    position: 'absolute',
    top: 0,
    height: '51%',
    width: '100%',
    transform: 'skew(25deg, 0deg)',
    borderRadius: 3,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    boxSizing: 'border-box',
    ...(state === StageBtnState.active
      ? {
          backgroundColor: theme.palette.info.main,
          border: `1px solid ${theme.palette.info.contrastText}`,
          borderBottom: 'none !important',
        }
      : {}),
    ...(state === StageBtnState.past
      ? {
          backgroundColor: theme.palette.blue[600],
          border: '1px solid ' + theme.palette.blue[600] + ' !important',
        }
      : {}),
    ...(state === StageBtnState.future
      ? {
          backgroundColor: theme.palette.secondary.light,
          border: theme.mixins.borderStandard.border + ' !important',
        }
      : {}),
  },
  '&:after': {
    content: `''`,
    position: 'absolute',
    bottom: 0,
    height: '51%',
    width: '100%',
    transform: 'skew(-25deg, 0deg)',
    borderRadius: 3,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    boxSizing: 'border-box',
    ...(state === StageBtnState.active
      ? {
          backgroundColor: theme.palette.info.main,
          border: `1px solid ${theme.palette.info.contrastText}`,
          borderTop: 'none !important',
        }
      : {}),
    ...(state === StageBtnState.past
      ? {
          backgroundColor: theme.palette.blue[600],
          border: '1px solid ' + theme.palette.blue[600] + ' !important',
        }
      : {}),
    ...(state === StageBtnState.future
      ? {
          backgroundColor: theme.palette.secondary.light,
          border: theme.mixins.borderStandard.border + ' !important',
        }
      : {}),
  },
}))

export const StageBtnLabel = styled('span', { name: 'StageBtnLabel' })<{
  isFirst: boolean
}>(({ isFirst = false }) => ({
  zIndex: 2,
  maxHeight: 50,
  overflow: 'hidden',
  lineHeight: '125%',
  paddingLeft: isFirst ? 0 : 6,
}))

export const OtherWrapper = styled('div', { name: 'OtherWrapper' })({
  marginLeft: 'auto',
  padding: '0 10px',
})

export const StyledSelect = styled(Select, { name: 'StyledSelect' })<{
  isActive: boolean
}>(({ isActive = false, theme }) => ({
  fontSize: 14,
  height: '50px !important',
  flexGrow: 1,
  ...(isActive
    ? {
        color: theme.palette.info.contrastText + ' !important',
        '& .MuiOutlinedInput-notchedOutline': {
          border: 'none',
        },
        backgroundColor: theme.palette.info.main + ' !important',
        border: `1px solid ${theme.palette.info.contrastText} !important`,
      }
    : {
        color: theme.palette.grey[700] + ' !important',
        '& .MuiOutlinedInput-notchedOutline': {
          border: '1px solid ' + theme.palette.grey[110],
        },
      }),
}))

export const StyledMenuItem = styled(MenuItem, { name: 'StyledSelect' })({
  fontSize: 14,
  padding: 16,
  '&.MuiListItem-root.Mui-selected': {
    backgroundColor: 'unset',
  },
  '&.MuiListItem-root.Mui-selected:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
  },
})
