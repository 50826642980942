var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import TransactionActionButton from '../TransactionActionButton';
import ProposalAcceptedLink from '../TransactionActionLink';
import AcceptProposalWrapper from './AcceptProposalWrapper';
export var ProposalAccepted = function (_a) {
    var myeStyles = _a.myeStyles, isLoading = _a.isLoading, buttonLabel = _a.buttonLabel, acceptedLinkLabel = _a.acceptedLinkLabel, onAction = _a.onAction;
    return (_jsxs(AcceptProposalWrapper, __assign({ textColor: myeStyles.textColor }, { children: [_jsx(TransactionActionButton, { isLoading: isLoading, label: buttonLabel, disabled: true, onClick: function () { } }), _jsx(ProposalAcceptedLink, { isLoading: isLoading, textColor: myeStyles.highlightColor, label: acceptedLinkLabel, onClick: onAction })] })));
};
