// @ts-nocheck
import { logAmplitudeEvent } from 'amplitude/amplitude'
import ProUXButton from 'elements/proUXButtons/ProUXButton'
import { List } from 'elements/react-admin/List'
import React, { ChangeEvent, useEffect, useState } from 'react'
import { Datagrid, PaginationComp, useDataProvider, useNotify, useTranslate } from 'react-admin'
import restClient from 'restClient'
import SwapPaymentOptionsRow from './SwapPaymentOptionsRow'
import SwapPaymentOptionsTableHeader from './SwapPaymentOptionsTableHeader'
import { CalculationStatusType, DataGridProjectDataType, ProjectRecordType } from './types'

const API_URL = window.API_ROOT + '/api'
const TAG_TITLE = 'Batch Update'

type PropTypes = {
  oldPaymentOption: number | undefined
  newPaymentOption: number | undefined
  orgId: number
}

const SwapPaymentOptionsProjectList: React.FC<PropTypes> = (props) => {
  const translate = useTranslate()
  const notify = useNotify()
  const dataProvider = useDataProvider()

  const [selectedProjectIds, setSelectedProjectIds] = useState<number[]>([])
  const [calculationStatusMap, setCalculationStatusMap] = useState<CalculationStatusType>({})
  const [lastNewPaymentOptionChosen, setLastNewPaymentOptionChosen] = useState<number | undefined>(undefined)
  const [isCalculating, setIsCalculating] = useState<boolean>(false)
  const [isSaving, setIsSaving] = useState<boolean>(false)
  const [startedCalcs, setStartedCalcs] = useState<boolean>(false)
  const [selectedAll, setSelectedAll] = useState<boolean>(false)
  const [savedIds, setSavedIds] = useState<number[]>([])
  const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (errorMsg) notify(errorMsg, 'warning')
    setErrorMsg(undefined)
  }, [errorMsg])

  // when the old payment option is cleared out or edited we reset all state
  useEffect(() => {
    if (selectedProjectIds?.length > 0 || savedIds?.length > 0 || Object.keys(calculationStatusMap)?.length > 0) {
      resetState()
    }
  }, [props.oldPaymentOption])

  // when the new payment option is edited we check to see if we've already updated temporary designs in state. If so we re-update everything
  useEffect(() => {
    if (Object.keys(calculationStatusMap)?.length > 0 && props.newPaymentOption && lastNewPaymentOptionChosen) {
      let newCalcMap = { ...calculationStatusMap } as CalculationStatusType
      Object.keys(calculationStatusMap)?.forEach((projectId) => {
        if (newCalcMap[projectId]?.new_design) {
          let newDesign = replacePmtInDesign(
            lastNewPaymentOptionChosen,
            props.newPaymentOption,
            newCalcMap[projectId].new_design
          )
          newCalcMap[projectId] = {
            new_design: newDesign,
            status: 'pending',
            error_msg: undefined,
          }
        } else {
          newCalcMap[projectId] = {
            new_design: undefined,
            status: 'error',
            error_msg:
              'We were unable to update this project when you changed the new payment option. Please unselect and reselect to fix this.',
          }
        }
      })
      setStartedCalcs(false)
      setCalculationStatusMap(newCalcMap)
    }
    if (props.newPaymentOption) setLastNewPaymentOptionChosen(props.newPaymentOption)
  }, [props.newPaymentOption])

  const resetState = () => {
    setIsCalculating(false)
    setIsSaving(false)
    setSelectedProjectIds([])
    setCalculationStatusMap({})
    setStartedCalcs(false)
    setSelectedAll(false)
    setLastNewPaymentOptionChosen(undefined)
    setErrorMsg(undefined)
  }

  const replacePmtInDesign = (
    oldPmtId: number | undefined,
    newPmtId: number | undefined,
    oldCompressedDesign: string | undefined
  ) => {
    if (!oldPmtId || !newPmtId || !oldCompressedDesign) return null
    else {
      let design = JSON.parse(window.CompressionHelper.decompress(oldCompressedDesign))
      if (design) {
        design?.object?.children
          ?.filter((child) => child.type === 'OsSystem')
          ?.forEach((sys) => {
            if (sys.userData.payment_options_override) {
              sys.userData.payment_options_override = sys.userData.payment_options_override?.map((pmtId) => {
                if (pmtId === oldPmtId) {
                  return newPmtId
                } else return pmtId
              })
            } else {
              sys.userData.payment_options_override = []
              sys.userData.payment_options?.forEach((pmt) => {
                if (pmt.id !== oldPmtId) sys.userData.payment_options_override.push(pmt.id)
                else sys.userData.payment_options_override.push(newPmtId)
              })
            }
          })
      }
      // now that we have the updated design string add it to the calc status map and make sure we mark this project as pending
      return window.CompressionHelper.compress(JSON.stringify(design))
    }
  }

  const selectProject = (project: ProjectRecordType) => {
    if (selectedProjectIds?.length >= 10) {
      setErrorMsg(
        'You can only select up to ten projects at a time. If you would like to update more you can do it in batches'
      )
    } else {
      setSelectedProjectIds([...selectedProjectIds, project.id])
      setStartedCalcs(false)
      try {
        let updatedDesign = replacePmtInDesign(props.oldPaymentOption, props.newPaymentOption, project.design)
        let newCalculationStatusMap = { ...calculationStatusMap }
        newCalculationStatusMap[project.id] = {
          new_design: updatedDesign,
          status: 'pending',
        }
        setCalculationStatusMap(newCalculationStatusMap)
      } catch (ex) {
        console.log('ex', ex)
      }
      logAmplitudeEvent('bulk_update_project_selected', {
        project_id: project.id,
        old_pmt: props.oldPaymentOption,
        new_pmt: props.newPaymentOption,
      })
    }
  }

  const unselectProject = (projectId: number) => {
    setSelectedProjectIds(selectedProjectIds.filter((pid) => pid !== projectId))
    let newCalculationStatusMap = { ...calculationStatusMap }
    if (newCalculationStatusMap[projectId]) {
      delete newCalculationStatusMap[projectId]
      setCalculationStatusMap(newCalculationStatusMap)
    }
    if (selectedAll) setSelectedAll(false)
  }

  const runPreview = () => {
    let newStatusMap = { ...calculationStatusMap }
    // mark all of the system's we're about to calculate as pending
    selectedProjectIds.forEach((pid) => {
      newStatusMap[pid] = { status: 'running', new_design: undefined }
    })
    setCalculationStatusMap(newStatusMap)

    setIsCalculating(true)
    setStartedCalcs(true)
    Object.keys(calculationStatusMap)?.forEach((projectId: number, i: number) => {
      let design = calculationStatusMap[projectId].new_design
      const restClientInstance = restClient(API_URL)
      let url = `orgs/${props.orgId}/projects/${projectId}/system_calcs/?full_calcs=false${
        localStorage.getItem('calc_version') ? '&calc_version=' + localStorage.getItem('calc_version') : ''
      }`
      restClientInstance('CUSTOM_POST', 'custom', {
        url,
        data: { design },
      })
        .then((res) => {
          if (res?.data) {
            let newDesign = JSON.parse(window.CompressionHelper.decompress(res.data))
            newStatusMap = { ...newStatusMap }
            newStatusMap[projectId] = {
              status: 'complete',
              new_design: window.CompressionHelper.compress(JSON.stringify(newDesign)),
            }
            setCalculationStatusMap(newStatusMap)
            logAmplitudeEvent('bulk_update_projects_previewed', {
              project_id: projectId,
              old_pmt_id: props.oldPaymentOption,
              new_pmt_id: props.newPaymentOption,
            })
          }
        })
        .catch((err) => {
          let errorMsg = err?.body?.message || 'Something went wrong and we were unable to recalculate this system'
          newStatusMap = { ...newStatusMap }
          newStatusMap[projectId] = {
            status: 'error',
            error_msg: errorMsg,
            new_design: undefined,
          }
          setCalculationStatusMap(newStatusMap)
          logAmplitudeEvent('bulk_update_projects_preview_error', {
            project_id: projectId,
            error_msg: errorMsg,
            old_pmt_id: props.oldPaymentOption,
            new_pmt_id: props.newPaymentOption,
          })
        })
        .finally(() => {
          if (i + 1 === Object.keys(calculationStatusMap).length) setIsCalculating(false)
        })
    })
  }

  const saveAll = async () => {
    let newCalculationStatusMap = { ...calculationStatusMap }
    let newSavedIds = [...savedIds]
    setIsSaving(true)
    selectedProjectIds.forEach(async (projectId, i) => {
      try {
        let result = await onSave(projectId)
        if (result?.success) {
          newSavedIds.push(projectId)
          newCalculationStatusMap[projectId].status = 'saved'
          logAmplitudeEvent('bulk_update_projects_saved', {
            project_id: projectId,
            old_pmt_id: props.oldPaymentOption,
            new_pmt_id: props.newPaymentOption,
          })
        } else {
          newCalculationStatusMap[projectId].status = 'error'
          newCalculationStatusMap[projectId].error_msg = result?.error || 'we were unable to save this project'
          logAmplitudeEvent('bulk_update_projects_save_error', {
            project_id: projectId,
            old_pmt_id: props.oldPaymentOption,
            new_pmt_id: props.newPaymentOption,
            error_msg: newCalculationStatusMap[projectId].error_msg,
          })
        }
      } catch (ex) {
        newCalculationStatusMap[projectId].status = 'error'
        newCalculationStatusMap[projectId].error_msg = ex?.error ? ex.error : 'we were unable to save this project'
        logAmplitudeEvent('bulk_update_projects_save_error', {
          project_id: projectId,
          old_pmt_id: props.oldPaymentOption,
          new_pmt_id: props.newPaymentOption,
          error_msg: newCalculationStatusMap[projectId].error_msg,
        })
      }
      if (i + 1 === selectedProjectIds.length) {
        setCalculationStatusMap({ ...newCalculationStatusMap })
        setSavedIds([...newSavedIds])
        setIsSaving(false)
        setSelectedProjectIds([])
        setStartedCalcs(false)
        setIsCalculating(false)
      }
    })
  }

  const onIndividualSaveClicked = async (projectId: number) => {
    try {
      let result = await onSave(projectId)
      if (result?.success) {
        let newCalculationStatusMap = { ...calculationStatusMap }
        newCalculationStatusMap[projectId].status = 'saved'
        setCalculationStatusMap(newCalculationStatusMap)

        let newSavedIds = [...savedIds, projectId]
        setSavedIds(newSavedIds)
      } else {
        let newCalculationStatusMap = { ...calculationStatusMap }
        newCalculationStatusMap[projectId].status = 'error'
        newCalculationStatusMap[projectId].error_msg = result?.error || 'we were unable to save this project'
        setCalculationStatusMap(newCalculationStatusMap)
      }
    } catch (ex) {
      let newCalculationStatusMap = { ...calculationStatusMap }
      newCalculationStatusMap[projectId].status = 'error'
      newCalculationStatusMap[projectId].error_msg = 'we were unable to save this project'
      setCalculationStatusMap(newCalculationStatusMap)
    }
  }

  // don't reject if it fails, just pass undefined and let things proceed without the tag
  const getTagUrl = () => {
    return new Promise((resolve, reject) => {
      const restClientInstance = restClient(API_URL)
      restClientInstance('CUSTOM_GET', 'custom', {
        url: `orgs/${props.orgId}/tags/?fieldset=list&limit=50`,
      })
        .then((tagRes) => {
          let existingTag = tagRes.data?.find((tag) => tag.title === TAG_TITLE)
          if (existingTag) resolve(existingTag.url)
          if (!existingTag) {
            restClientInstance('CUSTOM_POST', 'custom', {
              url: `orgs/${props.orgId}/tags/`,
              data: {
                org_id: props.orgId,
                title: TAG_TITLE,
              },
            })
              .then((tagPostRes) => {
                resolve(tagPostRes.data.url)
              })
              .catch((tagPostErr) => {
                resolve(undefined)
              })
          }
        })
        .catch((tagErr) => {
          resolve(undefined)
        })
    })
  }

  const onSave = (projectId: number) => {
    return new Promise((resolve, reject) => {
      getTagUrl().then((tagUrl: string) => {
        if (calculationStatusMap[projectId]?.new_design && calculationStatusMap[projectId]?.status === 'complete') {
          const restClientInstance = restClient(API_URL)
          let design = calculationStatusMap[projectId].new_design
          let url = `orgs/${props.orgId}/projects/${projectId}/`
          let existingTags = window?.reduxStore?.getState()?.admin?.resources?.projects?.data[projectId]?.tags || []
          existingTags = existingTags?.filter((tag) => tag !== tagUrl)
          restClientInstance('CUSTOM_PATCH', 'custom', {
            url,
            data: {
              design,
              tags: tagUrl ? [...existingTags, tagUrl] : existingTags,
            },
          })
            .then((res) => {
              resolve({ success: true })
            })
            .catch((err) => {
              reject({ success: false, error: err?.body?.detail || 'unable to save' })
            })
        } else {
          reject({ success: false, error: 'unable to save' })
        }
      })
    })
  }

  const onSelectAll = (e: ChangeEvent, projects: DataGridProjectDataType) => {
    setSelectedAll(e.target.checked)
    if (e.target.checked) {
      let newSelectedProjectIds: number[] = []
      let newCalculationStatusMap = {} as CalculationStatusType
      Object.values(projects)?.forEach((project) => {
        if (savedIds?.includes(project.id)) return
        newSelectedProjectIds.push(project.id)
        try {
          let updatedDesign = replacePmtInDesign(props.oldPaymentOption, props.newPaymentOption, project.design)

          // now that we have the updated design string add it to the calc status map and make sure we mark this project as pending
          newCalculationStatusMap[project.id] = {
            new_design: updatedDesign,
            status: 'pending',
          }
        } catch (ex) {
          console.log('ex', ex)
        }
      })
      setCalculationStatusMap(newCalculationStatusMap)
      setSelectedProjectIds(newSelectedProjectIds)
    } else {
      setSelectedProjectIds([])
      setCalculationStatusMap({})
    }
  }

  return (
    <div>
      {props.oldPaymentOption && (
        <div>
          <h2>Step 2: Select Projects and Preview Changes</h2>
          <div>
            <div>
              <p>
                Select the project(s) you would like to transfer to the new payment option and then click "Preview
                Changes" to see what the price difference would be.{' '}
                <strong>
                  Previewing changes will not save anything, this step is just intended to show you the price difference
                </strong>
                . After you preview changes you will then be able to choose which projects you would like to save.
              </p>
              <ProUXButton
                disabled={!selectedProjectIds || selectedProjectIds.length === 0 || !props.newPaymentOption}
                onClick={runPreview}
                label="Preview Changes"
                type={startedCalcs ? 'secondary' : 'primary'}
                loading={isCalculating}
              />
            </div>
          </div>
          {startedCalcs && (
            <div>
              <h2>Step 3: Save Changes</h2>
              <p>
                Once all calculations have finished review the pricing differences and please double check that you want
                to save changes for all the projects you have selected. Click the undo button or unselect any projects
                you prefer to not save. Once you're ready click you can click the "Save All Changes" button or you can
                save projects indivdually.
              </p>
              <p>
                <strong>Please note that saving changes will immediately impact proposals for selected projects</strong>
              </p>
              <ProUXButton
                label="Save All Changes"
                type="primary"
                disabled={isCalculating}
                onClick={saveAll}
                loading={isSaving}
              />
            </div>
          )}
          <List
            {...props}
            empty={!props.oldPaymentOption}
            title={' '}
            hideBreadCrumbs={true}
            resource="projects"
            pagination={<PaginationComp rowsPerPageOptions={[10]} />}
            toolbar={<div></div>}
            filter={{
              org_id: props.orgId,
              system_sold: 'None',
              payment_option_sold: 'None',
              payment_option: props.oldPaymentOption,
              fieldset: 'payment_option_refresh',
            }}
          >
            <Datagrid
              header={(stuff) => (
                <SwapPaymentOptionsTableHeader onSelectAll={onSelectAll} selectedAll={selectedAll} {...stuff} />
              )}
            >
              <SwapPaymentOptionsRow
                selectProject={selectProject}
                unselectProject={unselectProject}
                selectedProjectIds={selectedProjectIds}
                calculationStatusMap={calculationStatusMap}
                oldPaymentOption={props.oldPaymentOption}
                newPaymentOptionId={props.newPaymentOption}
                onSave={onIndividualSaveClicked}
              />
            </Datagrid>
          </List>
        </div>
      )}
    </div>
  )
}

export default SwapPaymentOptionsProjectList
