import { DynamicButtonRow } from 'elements/button/DynamicButtonRow'
import { styled } from 'opensolar-ui'
import { PromoDialogActionRow } from '../usePromoDialogStructs'

const Container = styled('div')({
  padding: '8px 24px',
  display: 'flex',
  gap: 16,
  justifyContent: 'center',
})

export const PromoActionRowElement = ({ element }: { element: PromoDialogActionRow }) => {
  return (
    <Container>
      <DynamicButtonRow buttons={element.actions} />
    </Container>
  )
}
