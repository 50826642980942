import { OperationExecutor } from './OperationExecutor'
import { EnphaseRule } from './RuleTypes'

/**
 * Class to evaluate Enphase rules.
 */
export class RuleEvaluator {
  constructor(private executor: OperationExecutor) {}

  /**
   * Evaluates an Enphase rule.
   * @param rule - The Enphase rule to evaluate.
   * @returns The result of the rule evaluation.
   */
  evaluate(rule: EnphaseRule): number {
    // Check if rule should be hidden
    if (rule.hidden) {
      const isHidden = rule.hidden.conditions
        ? rule.hidden.conditions.every((condition) => this.executor.evaluateCondition(condition))
        : this.executor.evaluateCondition({
            field: rule.hidden.field!,
            operator: rule.hidden.operator!,
            value: rule.hidden.value!,
          })
      if (isHidden) {
        return 0
      }
    }

    // Check battery conditions
    if (rule.preventWithBattery || rule.requiresBattery) {
      const systemHasBattery = this.executor.evaluateCondition({
        field: 'getBatteryCount()',
        operator: 'greater_than',
        value: 0,
      })
      if (rule.preventWithBattery && systemHasBattery) {
        return 0
      }
      if (rule.requiresBattery && !systemHasBattery) {
        return 0
      }
    }

    // Handle country availability
    if (rule.availableIn?.length) {
      const isAvailable = this.executor.evaluateCondition({
        field: 'countryCode',
        operator: 'in',
        value: rule.availableIn,
      })
      if (!isAvailable) {
        return 0
      }
    }

    // Handle country non-availability
    if (rule.notAvailableIn?.length) {
      const notAvailable = this.executor.evaluateCondition({
        field: 'countryCode',
        operator: 'in',
        value: rule.notAvailableIn,
      })
      if (notAvailable) {
        return 0
      }
    }

    // Evaluate the rule
    const firstRule = rule.rules[0]
    if (!firstRule) {
      return 0
    }

    // Check conditions for the rule
    const conditionsMet = firstRule.conditions.every((condition) => this.executor.evaluateCondition(condition))
    if (!conditionsMet) {
      return 0
    }

    // Execute the rule's result
    const result = typeof firstRule.result === 'number' ? firstRule.result : this.executor.execute(firstRule.result)
    return result
  }
}
