import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { createContext, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { HdmShippingAddressType } from 'services/hdm/type'
import useHdmService from 'services/hdm/useHdmService'
import HdmCheckoutPresenter from './HdmCheckoutPresenter'

export type HdmOrderModeType = 'order_from_credit' | 'order' | 'order_disconnected'

export type HdmOrderState = {
  submitting: boolean
  isReady?: boolean
  orderType?: HdmOrderModeType
  orderResult?: any
}

const defaultOrderState: HdmOrderState = {
  submitting: false,
  isReady: false,
}

const defaultShippingAddress = {
  companyName: '',
  contactName: '',
  contactPhone: '',
  address1: '',
  address2: '',
  town: '',
  county: '',
  country: '',
  postCode: '',
  contactFirstName: '',
  contactLastName: '',
}

export const CheckoutPresenterContext = createContext<HdmCheckoutPresenter | undefined>(undefined)
export const ShippingAddressContext = createContext<HdmShippingAddressType>(defaultShippingAddress)
export const OrderStateContext = createContext<HdmOrderState>(defaultOrderState)

/**
 * Follows a similar pattern than Segen but it's more lightweight
 * as there is less complexity in the API.
 */
const HdmCheckoutProvider = ({ children }) => {
  const [orderState, setOrderState] = useState<HdmOrderState>(defaultOrderState)
  const org = useSelector(orgSelectors.getOrg) || { name: 'Unknown' }
  const user = useSelector(authSelectors.getCurrentUser)
  const role = useSelector(authSelectors.getCurrentRole)
  let contactName = `${role?.first_name} ${role?.family_name}`
  if (contactName.trim() === '') {
    contactName = user?.email || 'Unknown name'
  }

  const [shippingAddress, setShippingAddress] = useState<HdmShippingAddressType>({
    ...defaultShippingAddress,
    companyName: org.name,
    contactName: contactName,
    contactPhone: role?.phone,
    contactFirstName: role?.first_name,
    contactLastName: role?.family_name,
  })

  const hdmService = useHdmService()

  const hdmCheckoutPresenter = useMemo(
    () => new HdmCheckoutPresenter(hdmService, setShippingAddress, setOrderState),
    []
  )

  return (
    <OrderStateContext.Provider value={orderState}>
      <ShippingAddressContext.Provider value={shippingAddress}>
        <CheckoutPresenterContext.Provider value={hdmCheckoutPresenter}>{children}</CheckoutPresenterContext.Provider>
      </ShippingAddressContext.Provider>
    </OrderStateContext.Provider>
  )
}

export default HdmCheckoutProvider
