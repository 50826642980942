import * as Sentry from '@sentry/react'
import { hideFullScreenLoader, markCreditAppAsOpened } from 'ducks/myEnergy'
import useBrighteApplicationCallback from 'myenergy/checkout/loanApplication/useBrighteApplicationCallback'
import useDividendApplicationCallback from 'myenergy/checkout/loanApplication/useDividendApplicationCallback'
import useGenericApplicationCallback from 'myenergy/checkout/loanApplication/useGenericApplicationCallback'
import useLoanpalApplicationCallback from 'myenergy/checkout/loanApplication/useLoanpalApplicationCallback'
import useMosaicApplicationCallback from 'myenergy/checkout/loanApplication/useMosaicApplicationCallback'
import usePlentiApplicationCallback from 'myenergy/checkout/loanApplication/usePlentiApplicationCallback'
import useSungageApplicationCallback from 'myenergy/checkout/loanApplication/useSungageApplicationCallback'
import useSunlightApplicationCallback from 'myenergy/checkout/loanApplication/useSunlightApplicationCallback'
import { removeCreditAppParams } from 'myenergy/selectionComponent/loanApplicationButton/util'
import { LoanApplicationType, ProposalDataType } from 'opensolar-checkout'
import { useDispatch } from 'react-redux'

const useForceOpenLoanApplication = () => {
  const dispatch = useDispatch()

  const forceOpenSunlight = useSunlightApplicationCallback()
  const forceOpenBrighte = useBrighteApplicationCallback()
  const forceOpenLoanpal = useLoanpalApplicationCallback()
  const forceOpenMosaic = useMosaicApplicationCallback()
  const forceOpenSungage = useSungageApplicationCallback()
  const forceOpenDividend = useDividendApplicationCallback()
  const forceOpenPlenti = usePlentiApplicationCallback()
  const genericApplicationCallback = useGenericApplicationCallback()

  return ({ elementData, proposalData }: { elementData: LoanApplicationType; proposalData: ProposalDataType }) => {
    if (elementData.type !== 'LoanApplication') {
      Sentry.captureException(new Error('Invalid element data for auto force open application'))
      return
    }

    const paymentMethod = elementData.actionData.payment_method
    if (paymentMethod === 'sunlight_loan_application') {
      forceOpenSunlight({ elementData, proposalData, additionalActionData: { showFullWidthDialog: true } })
      dispatch(hideFullScreenLoader())
      dispatch(markCreditAppAsOpened())
    } else if (paymentMethod === 'loanpal_application') {
      forceOpenLoanpal({ elementData, proposalData, additionalActionData: { showFullWidthDialog: true } })
    } else if (paymentMethod === 'mosaic_application') {
      forceOpenMosaic({ elementData, proposalData, additionalActionData: { showFullWidthDialog: true } })
    } else if (paymentMethod === 'sungage_application') {
      forceOpenSungage({ elementData, proposalData, additionalActionData: { showFullWidthDialog: true } })
    } else if (paymentMethod === 'brighte_application') {
      forceOpenBrighte({ elementData, proposalData })
    } else if (paymentMethod === 'dividend_application') {
      forceOpenDividend({ elementData, proposalData, additionalActionData: { showFullWidthDialog: true } })
    } else if (paymentMethod === 'phoenix_application') {
      // No longer a valid payment method
    } else if (paymentMethod === 'plenti_bnpl_application' || paymentMethod === 'plenti_loan_application') {
      forceOpenPlenti({ elementData, proposalData })
      dispatch(hideFullScreenLoader())
      dispatch(markCreditAppAsOpened())
    } else if (elementData.actionData.finance_integration) {
      genericApplicationCallback({ elementData, proposalData })
    } else {
      // No valid payment method
      Sentry.captureException(new Error('No valid payment method to force open loan application'))
      return
    }
    removeCreditAppParams()
  }
}

export default useForceOpenLoanApplication
