// @ts-nocheck
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Step from '@material-ui/core/Step'
import StepConnector from '@material-ui/core/StepConnector'
import StepLabel from '@material-ui/core/StepLabel'
import Stepper from '@material-ui/core/Stepper'
import { makeStyles, withStyles } from '@material-ui/core/styles'
import { authSelectors } from 'ducks/auth'
import { Button } from 'opensolar-ui'
import React, { FC, memo, useMemo } from 'react'
import { useTranslate } from 'react-admin'
import Draggable from 'react-draggable'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { recordUserAction, TOUR_Z_INDEX } from '../tools/common'
import { StepType } from '../types'
import { GROUPS } from './steps'
import useGetFUJITourSteps from './useGetFUJITourSteps'

type PropsType = {
  open: boolean
  handleClose: () => void
  handleStopTour: (e?: React.MouseEvent<HTMLButtonElement>) => void
}
const AlertDialog: FC<PropsType> = ({ open, handleClose, handleStopTour }) => {
  const translate = useTranslate()

  return (
    <div>
      <Dialog
        style={{ zIndex: TOUR_Z_INDEX }}
        maxWidth="xs"
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{translate('Are you sure you want to quit the tour?')}</DialogTitle>
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            style={{ fontWeight: 'lighter', color: 'rgba(0,0,0,0.87)', lineHeight: '1.4em' }}
          >
            {translate(
              'This tour is the best way for you to quickly learn your way around OpenSolar. It only takes 4 minutes. (And if you hurry you can do it in 2!) Are you sure you want to quit?'
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            onTouchStart={(e) => {
              e.stopPropagation()
              handleClose()
            }}
            color="rgba(0,0,0,0.87)"
          >
            <span>{translate('Cancel')}</span>
          </Button>
          <Button
            onClick={handleStopTour}
            onTouchStart={(e) => {
              e.stopPropagation()
              handleStopTour()
            }}
            color="rgba(0,0,0,0.87)"
            autoFocus
          >
            <span>{translate('Quit')}</span>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const useStyle = makeStyles({
  stepper: {
    borderRadius: 8,
    padding: '10px 0',
    cursor: 'move',
    boxShadow: 'rgb(0 0 0 / 12%) 0px 1px 6px, rgb(0 0 0 / 12%) 0px 1px 4px',
  },
  stickTop: {
    position: 'fixed',
    zIndex: TOUR_Z_INDEX - 1,
    top: 50,
    width: 910,
    left: '50%',
    transform: 'translate(-50%, 0px)',
    alignItems: 'center',

    '& .MuiStepIcon-active': {
      color: 'rgba(15, 183, 0, 1)',
    },
  },
  stickBottom: {
    position: 'fixed',
    zIndex: TOUR_Z_INDEX - 1,
    bottom: 50,
    width: 910,
    left: '50%',
    transform: 'translate(-50%, 0px)',
    alignItems: 'center',

    '& .MuiStepIcon-active': {
      color: 'rgba(15, 183, 0, 1)',
    },
  },
  horizontal: {
    padding: '0 4px',
  },
  stopButton: {
    color: 'rgba(0, 148, 255, 1)',
    background: 'white',
    margin: 'auto 10px',
    minWidth: 80,
    padding: '4px 8px',
    boxShadow: 'none',
    '&.MuiButton-contained:hover': {
      boxShadow: 'none',
    },
  },
})

const StyledStepLabel = withStyles({
  labelContainer: {
    '& .MuiStepLabel-alternativeLabel': {
      marginTop: 10,
      fontSize: '11px',
    },
  },
})(StepLabel)

const StyledConnector = withStyles({
  active: {
    '& $line': {
      backgroundImage: 'linear-gradient( 95deg,rgba(0, 148, 255, 1) 0%,rgba(15, 183, 0, 1) 100%)',
    },
  },
  completed: {
    '& $line': {
      background: 'rgba(0, 148, 255, 1)',
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
})(StepConnector)

const Steppers = memo(() => {
  const { tour, step } = useSelector((state: any) => state.tour)
  const [openAlertDialog, setOpenAlertDialog] = React.useState(false)
  const userId = useSelector(authSelectors.getCurrentUserId)

  const handleAlertDialogOpen = () => {
    setOpenAlertDialog(true)
  }

  const handleAlertDialogClose = () => {
    setOpenAlertDialog(false)
  }

  const classes = useStyle()
  const location = useLocation()
  const history = useHistory()
  const translate = useTranslate()
  const STEPS = useGetFUJITourSteps()
  const currentGroup = useMemo(() => STEPS.find((s: StepType) => s.step === step)?.group, [step])
  const activeStep = GROUPS.indexOf(currentGroup || '')
  const handleStopTour = () => {
    setOpenAlertDialog(false)
    history.push(location.pathname)
    //TODO: should this be done for other tours?
    if (tour === 'fuji') recordUserAction(userId, 'tour_hidden')
  }
  return currentGroup && tour === 'fuji' ? (
    <>
      <AlertDialog open={openAlertDialog} handleClose={handleAlertDialogClose} handleStopTour={handleStopTour} />
      <div
        className={
          ['proposal_toolbar', 'payment_option_dialog'].includes(step) ? classes.stickTop : classes.stickBottom
        }
      >
        <Draggable>
          <Stepper
            classes={{ root: classes.stepper }}
            id="fuji-tour-stepper"
            activeStep={activeStep}
            connector={<StyledConnector />}
            alternativeLabel
          >
            {GROUPS.map((label) => (
              <Step key={label} classes={{ root: classes.horizontal }}>
                <StyledStepLabel>{translate(label)}</StyledStepLabel>
              </Step>
            ))}
            <Button
              variant="contained"
              size="small"
              classes={{ root: classes.stopButton }}
              disabled={false}
              onClick={handleAlertDialogOpen}
              onTouchStart={(e) => {
                e.stopPropagation()
                handleAlertDialogOpen()
              }}
            >
              <span>{translate('STOP TOUR')}</span>
            </Button>
          </Stepper>
        </Draggable>
      </div>
    </>
  ) : null
})

export default Steppers
