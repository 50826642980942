import { permissionsSelectors } from 'ducks/permissions'
import { SPLIT_NAMES } from 'ducks/split'
import lodash from 'lodash'
import { useGetFujiTourNextStepQuery } from 'persistentContent/tours/fuji/useGetFUJITourSteps'
import { useSelector } from 'react-redux'
import { RootState } from 'types/state'
import { useFeatureFlag, useFeatureFlagDynamic } from 'util/split'
interface NavLink {
  id: string
  label: string
  accessRights?: string[]
  addFujiQuery?: boolean
  featureFlag?: SPLIT_NAMES
}
const PROJECT_NAV_LINKS: NavLink[] = [
  {
    id: 'manage',
    label: 'Manage',
    accessRights: ['manage.view'],
    addFujiQuery: false,
  },
  {
    id: 'energy',
    label: 'Energy',
    addFujiQuery: true,
  },
  {
    id: 'design',
    label: 'Design',
    accessRights: ['design.view'],
    addFujiQuery: true,
  },
  {
    id: 'payments',
    label: 'Payments',
    accessRights: ['design.view'],
    featureFlag: 'payments_tab',
    addFujiQuery: true,
  },
  {
    id: 'proposal',
    label: 'Online Proposal',
    accessRights: ['proposal.view'],
    addFujiQuery: true,
  },
  {
    id: 'proposal',
    label: 'Proposal',
    accessRights: ['proposal.view'],
    featureFlag: 'enable_sales_machine',
    addFujiQuery: true,
  },
  {
    id: 'preview',
    label: 'PDF Proposal',
    accessRights: ['proposal.view'],
    addFujiQuery: true,
  },
  {
    id: 'hardware',
    label: 'Hardware',
    accessRights: ['design.view'],
    featureFlag: 'hardware_3_0_projects_tab',
    addFujiQuery: false,
  },
  {
    id: 'sld',
    label: 'SLD',
    accessRights: ['design.view', 'sld.create'],
    featureFlag: 'single_line_diagram',
    addFujiQuery: false,
  },
]

export const useProjectNavItems = () => {
  const isFeatureEnabledHook = useFeatureFlagDynamic()
  const { tour, step } = useSelector((state: RootState) => state.tour)
  const nextStep = useGetFujiTourNextStepQuery(step || '')
  const fujiTourQuery = tour === 'fuji' ? nextStep : ''
  const accessRightsSetting = useSelector(permissionsSelectors.getProjectPermissionsSetting)
  const enableProposalV2 = useFeatureFlag('enable_sales_machine', 'on')

  const filteredLinks = PROJECT_NAV_LINKS.filter((navLink) => {
    let returnVal = true
    if (!!navLink.accessRights?.length) {
      navLink.accessRights.forEach((setting) => {
        const allowVal = lodash.get(accessRightsSetting, setting)
        if (allowVal === 0) returnVal = false
      })
    }
    if (navLink.featureFlag && !isFeatureEnabledHook(navLink.featureFlag, 'on')) returnVal = false
    if (['Online Proposal', 'PDF Proposal'].includes(navLink.label) && enableProposalV2) returnVal = false
    return returnVal
  })
  return filteredLinks.map((navLink) => ({
    id: navLink.id,
    label: navLink.label,
    redirect: !!navLink.addFujiQuery ? navLink.id + fujiTourQuery : navLink.id,
  }))
}
