import { Typography } from '@material-ui/core'
import TinyBox from 'elements/TinyBox'
import { styled } from 'opensolar-ui'
import { doNotTranslate } from 'util/misc'
import { CopyValuesButton } from './CopyValuesButton'
import { PasteValuesButton } from './PasteValuesButton'

const Buttons = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
})

export const FormToolsPanel = ({ resource, omitProps }: { resource: string; omitProps?: string[] }) => {
  return (
    <div>
      <Typography variant="h6">{doNotTranslate('Import / Export')}</Typography>
      <TinyBox>
        <Buttons>
          <CopyValuesButton fullWidth omitProps={omitProps} size="small" resource={resource} translate={false} />
          <PasteValuesButton fullWidth size="small" resource={resource} translate={false} />
        </Buttons>
      </TinyBox>
    </div>
  )
}
