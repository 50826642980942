import { styled } from 'opensolar-ui'
import { useMemo } from 'react'
import { Datagrid, DatagridBody, DatagridCell, DatagridHeaderCell, DatagridRow } from 'react-admin'
import EmptyResultsPage from './EmptyResultsPage'

const StickyDataCell = styled(DatagridCell)({
  zIndex: 10,
  position: 'sticky',
  backgroundColor: 'white',
})

const StickyDataHeaderCell = styled(DatagridHeaderCell)({
  zIndex: 10,
  position: 'sticky',
  backgroundColor: 'rgb(230,230,230)',
})

//column index: width of prev column
const stickyCols = {
  0: 0,
  1: 64,
}
const ModifiedDatagridCell = (props) => {
  const { lastIndex, index, header = false } = props
  const isLastColumn = useMemo(() => index === lastIndex, [index, lastIndex])
  const stickyIndexes = useMemo(() => Object.keys(stickyCols).map((x) => parseInt(x)), [])

  const DefaultCell = useMemo(() => (header ? DatagridHeaderCell : DatagridCell), [header])
  const StickyCell = useMemo(() => (header ? StickyDataHeaderCell : StickyDataCell), [header])

  if (stickyIndexes.includes(index) || isLastColumn)
    return (
      <StickyCell
        {...props}
        style={{ left: isLastColumn ? undefined : stickyCols[index], right: isLastColumn ? 0 : undefined }}
      />
    )
  return <DefaultCell {...props} />
}

const DatagridStyled = styled(Datagrid)({
  tableLayout: 'auto',
})

const DatagridRowStyled = styled(DatagridRow, { name: 'DatagridRowStyled' })(({ theme }) => ({
  ':hover': {
    '& td': {
      backgroundColor: theme.palette.grey[60] + ' !important',
    },
  },
}))

const ProjectDatagrid = (props) => {
  const { total, loading, filterValues } = props

  if (total === 0 && !loading)
    return (
      <div id={'Project-List-Section'}>
        <EmptyResultsPage filteredSearch={!!Object.keys(filterValues).length} />
      </div>
    )
  return (
    <DatagridStyled
      id={'Project-List-Section'}
      {...props}
      headerCell={<ModifiedDatagridCell header={true} />}
      //@ts-ignore
      body={<DatagridBody row={<DatagridRowStyled cell={<ModifiedDatagridCell />} />} />}
    >
      {props.children}
    </DatagridStyled>
  )
}

export default ProjectDatagrid
