import { MenuItem, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { CloseRounded, Help, MailOutlineOutlined } from '@material-ui/icons'
import UserIcon from 'elements/UserIcon'
import { Alert, Button, IconButton, Select, styled } from 'opensolar-ui'
import { getNotifyEmails, getUrl, openMailTo } from 'projectSections/sections/info/orgSharing/util'
import { useNotify, useTranslate } from 'ra-core'
import React, { useMemo, useState } from 'react'
import { BooleanInput } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { ConnectedOrgBrief } from 'types/orgs'
import { ProjectType } from 'types/projects'
import { Theme } from 'types/themes'
import { RowCentered } from '../../../styles'
import SharePermissionInput from './SharePermissionInput'

const useStyles = makeStyles((theme: Theme) => ({
  orgName: {
    margin: '0 10px',
  },
  mailBtn: {
    marginLeft: 'auto !important',
    marginRight: '5px !important',
  },
  marginBottom: {
    marginBottom: 20,
  },
  visibilityToggle: {
    marginLeft: 'auto',
  },
}))

const VisibilityWrapper = styled('div', { name: 'VisibilityWrapper' })(({ theme }) => ({
  margin: '20px 0',
  padding: '8px 16px',
  backgroundColor: theme.palette.grey[50],
  '& p': {
    margin: 0,
    fontWeight: 500,
  },
  display: 'flex',
  alignItems: 'center',
}))

const ActionLabel = styled('div', { name: 'ActionLabel' })(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.error.contrastText,
  '& .MuiSvgIcon-root': {
    height: 20,
    marginRight: 5,
  },
}))

const HelpIcon = styled(Help, { name: 'HelpIcon' })(({ theme }) => ({
  height: 20,
  marginRight: 15,
  fill: theme.palette.grey[810],
}))

const StyledRow = styled('div')({
  marginBottom: 20,
  display: 'flex',
  alignItems: 'center',
  gap: 10,
})

interface PropTypes {
  project: ProjectType
  connectedOrgs: ConnectedOrgBrief[]
  orgId: number
  orgName: string
}

const OwnerOrgContent: React.FC<PropTypes> = ({ project, connectedOrgs, orgId, orgName }) => {
  const form = useForm()
  const notify = useNotify()
  const classes = useStyles()
  const translate = useTranslate()
  const formValues = useFormState<ProjectType>().values
  const initialValues = form.getState().initialValues
  const [searchOrg, setSearchOrg] = useState<number | null>(null)
  const sharedWith = useMemo(() => formValues.shared_with, [formValues.shared_with])
  const handleShare = () => {
    if (searchOrg) {
      let saveNewOrg = true
      if (!!initialValues.shared_with?.length) {
        const initialOrg = initialValues.shared_with[0]
        if (initialOrg.org_id === searchOrg) {
          saveNewOrg = false
          form.change('shared_with', initialValues.shared_with)
        }
      }
      if (saveNewOrg) {
        const findItem = connectedOrgs.find((x) => x.org_id === searchOrg)
        if (findItem) {
          const shareInstance = {
            org_id: searchOrg,
            org_name: findItem.org_name,
            permission: findItem.permission_url?.replace('/permissions_role/', `/orgs/${orgId}/permissions_role/`),
            is_shared: false,
            permission_role_title: findItem.permission_role_title,
          }
          form.change('shared_with', [...(formValues.shared_with || []), shareInstance])
        }
      }
      setSearchOrg(null)
    }
  }
  const handleRoleChange = (value, index) => {
    form.change(`shared_with[${index}].permission`, value)
    if (value) {
      getUrl(value).then((res) => {
        form.change(`shared_with[${index}].permission_role_title`, res.data.title)
      })
    } else {
      form.change(`shared_with[${index}].permission_role_title`, value)
    }
  }

  const handleUnshare = (orgId) => {
    const newVal = formValues.shared_with ? [...formValues.shared_with.filter((x) => x.org_id !== orgId)] : []
    form.change('shared_with', newVal)
    setSearchOrg(null)
  }
  return (
    <>
      {!!sharedWith?.length ? (
        <>
          {sharedWith.map((sharedOrg, i) => (
            <>
              <RowCentered key={'shared-' + sharedOrg.org_id}>
                <UserIcon org={sharedOrg} />
                <h3 className={classes.orgName}>{sharedOrg?.org_name}</h3>
                <Tooltip
                  title={translate(
                    "Assigning a connected Org allows you to use the other Org's components and settings in your project's design, and/or grant the Org access to the project"
                  )}
                >
                  <HelpIcon />
                </Tooltip>
                <IconButton
                  color="secondary"
                  variant="squared"
                  className={classes.mailBtn}
                  onClick={() => {
                    if (sharedOrg.org_id === undefined) return
                    getNotifyEmails(orgId)
                      .then((res) => {
                        openMailTo(res.data, sharedOrg.org_id, project, orgName, notify)
                      })
                      .catch((err) => {
                        notify('Failed to retrieve connected org data.', 'warning')
                      })
                  }}
                >
                  <MailOutlineOutlined />
                </IconButton>
                <SharePermissionInput
                  onChange={(value) => {
                    handleRoleChange(value, i)
                  }}
                  optionValue={'url'}
                  variant="outlined"
                  actions={[
                    {
                      label: (
                        <ActionLabel>
                          <CloseRounded />
                          {translate('Remove Organisation')}
                        </ActionLabel>
                      ),
                      onClick: (e) => {
                        handleUnshare(sharedOrg.org_id)
                      },
                    },
                  ]}
                  source={`shared_with.${i}.permission`}
                />
              </RowCentered>
              <VisibilityWrapper>
                <p>{translate('Project Visible to %{org_name}', { org_name: sharedOrg?.org_name })} </p>
                <BooleanInput
                  className={classes.visibilityToggle}
                  source={`shared_with.${i}.is_shared`}
                  label={''}
                  options={{ color: 'primary' }}
                />
              </VisibilityWrapper>
              <Alert severity="warning">
                {translate(
                  'Data such as pricing, payment options (and more) may be visible to the connected org when you share it with them.'
                )}
              </Alert>
            </>
          ))}
        </>
      ) : (
        <>
          <StyledRow>
            <Select
              fullWidth
              value={searchOrg}
              variant="outlined"
              onChange={(e: any) => {
                const value: number = e.target.value
                setSearchOrg(value)
              }}
              endAdornment={
                <Tooltip
                  title={translate(
                    "Assigning a connected Org allows you to use the other Org's components and settings in your project's design, and/or grant the Org access to the project"
                  )}
                >
                  <HelpIcon />
                </Tooltip>
              }
            >
              {connectedOrgs?.map((option) => (
                <MenuItem key={option.org_id} value={option.org_id}>
                  {option.org_name}
                </MenuItem>
              ))}
            </Select>

            <Button variant="contained" color="primary" onClick={handleShare}>
              {translate('Share')}
            </Button>
          </StyledRow>

          <Alert severity="info">{translate('This project is not shared to any organization.')}</Alert>
        </>
      )}
    </>
  )
}

export default OwnerOrgContent
