import { Tooltip } from '@material-ui/core'
import { HelpOutline } from '@material-ui/icons'
import { useTranslate } from 'ra-core'
import React from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'

type PropTypes = {}

const useStyles = makeOpenSolarStyles((theme) => ({
  helpIcon: {
    color: theme.greyLight2,
    paddingTop: '10px',
    cursor: 'pointer',
    width: '20px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
}))
const PriceLockTooltip: React.FC<PropTypes> = ({ children }) => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <Tooltip
      title={translate(
        'Price Lock is enabled for this system. Unlock pricing in the Design tab before a price change can be made.'
      )}
    >
      {children ? <div>{children}</div> : <HelpOutline className={classes.helpIcon} />}
    </Tooltip>
  )
}

export default PriceLockTooltip
