import { makeStyles } from '@material-ui/core/styles'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { projectViewSettingsActions, projectViewSettingsSelectors } from 'ducks/projectViewSettings'
import { splitSelectors } from 'ducks/split'
import { DataOutlineIcon, styled } from 'opensolar-ui'
import { useGetIsCashFlowVisible } from 'pages/cashFlow/utils'
import { useProjectEntityPermissions } from 'projectSections/sections/manage/projectManagement/workflow/hooks'
import { useNotify, useTranslate } from 'ra-core'
import { useEffect, useMemo, useState } from 'react'
import { useForm, useFormState } from 'react-final-form'
import Skeleton from 'react-loading-skeleton'
import { useDispatch, useSelector } from 'react-redux'
import { Theme } from 'types/themes'
import { ExternalWorkflow, WorkflowType } from 'types/workflows'
import { urlToId } from 'util/misc'
import { useFeatureFlag } from 'util/split'
import SelectChipField from '../common/SelectChipField'
import ShopAccordion from '../hardware/ShopAccordion'
import { iconStyles, RowCentered, SectionCardTop } from '../styles'
import CashFlowWidget from './CashFlowWidget'
import { useProjectExternalOrg } from './hooks'
import { retrieveExternalWorkflows, updateProjectWorkflows } from './util'
import Workflow from './Workflow'
import WorkflowSelect, { WorkflowUpdate } from './WorkflowSelect'

const useStyles = makeStyles(
  (theme: Theme) => ({
    wrapper: {
      width: '100%',
    },
    header: {
      fontSize: 16,
      padding: '20px 0',
      margin: 0,
      marginLeft: 2,
      fontWeight: 500,
    },
    fullWidth: {
      width: '100%',
    },
    marginLeft: {
      marginLeft: 'auto',
    },
    sectionContentWrapper: {
      marginLeft: 'auto',
      paddingRight: 10,
    },
    selectView: {
      marginLeft: 20,
    },
  }),
  { name: 'WorkflowSection' }
)

const getOrgWorkflow = (workflowId, orgWorkflows) => {
  if (!workflowId) return undefined
  return orgWorkflows?.find((workflow) => workflow.id === workflowId)
}
const RestyledSectionTop = styled(SectionCardTop, { name: 'RestyledSectionTop' })({
  flexWrap: 'wrap',
})

const WorkflowSection = (props) => {
  const form = useForm()
  const notify = useNotify()
  const classes = useStyles()
  const dispatch = useDispatch()
  const translate = useTranslate()
  const project = useFormState().values
  const org = useSelector(orgSelectors.getOrg)
  const externalOrg = useProjectExternalOrg()
  const currOrgId = useSelector(authSelectors.getOrgId)
  const orgWorkflows = useSelector(orgSelectors.getWorkflows)
  const { view } = useSelector(projectViewSettingsSelectors.getProjectWorkflowView)
  const externalDisplay = useMemo(() => view === 'external', [view])
  const [externalWorkflows, setExternalWorkflows] = useState<ExternalWorkflow[] | undefined | any[]>(undefined)
  const enableManageShop = useFeatureFlag('manage_shop', 'on')
  const splitIsReady = useSelector(splitSelectors.getSplitIsReady)
  const isCashFlowVisible = useGetIsCashFlowVisible()

  useEffect(() => {
    retrieveExternalWorkflows(project, currOrgId, notify, setExternalWorkflows)
  }, [])

  //display currently only supports 1 external org at a time
  const externalWorkflow = useMemo(() => (!!externalWorkflows?.length ? externalWorkflows[0] : undefined), [
    externalWorkflows,
  ])
  const noSharedWorkflows = useMemo(() => externalDisplay && !!!externalWorkflows?.length, [
    externalWorkflows,
    externalDisplay,
  ])

  const currOrgSelectedWorkflow = useMemo(
    () => project.workflows?.find((workflow) => workflow.org_id === currOrgId && workflow.is_selected),
    [currOrgId, project.workflows]
  )

  const [internalSelectedWorkflow, setInternalSelectedWorkflow] = useState<WorkflowType | undefined>(() => {
    return getOrgWorkflow(currOrgSelectedWorkflow?.workflow_id, orgWorkflows)
  })

  // workflow to display in the workflow select component
  const selectedWorkflow: WorkflowType | undefined = useMemo(() => {
    if (!project.shared_with || view === 'internal') {
      return internalSelectedWorkflow
    }

    return externalWorkflow?.workflows?.find(
      (workflow) => workflow.id === externalWorkflow?.selectedWorkflow?.workflow_id
    )
  }, [project.shared_with, view, externalWorkflow, internalSelectedWorkflow])

  const { allowView, allowEdit } = useProjectEntityPermissions(
    selectedWorkflow?.org ? urlToId(selectedWorkflow?.org) : undefined,
    'manage_workflow_stages'
  )

  const handleWorkflowChange = (workflow: WorkflowUpdate) => {
    if (externalDisplay && externalWorkflow) {
      updateProjectWorkflows(externalWorkflow?.selectedWorkflow, workflow, project, form, 'external_workflow')
      let updatedExternal = externalWorkflows ? [...externalWorkflows] : []
      // TODO Setting selectedWorkflow here is not setting the correct type for ExternalWorkflow.selectedWorkflow - it
      // should be a ProjectWorkflowType type. For this reason we have made the type of externalWorkflows include any[].
      updatedExternal[0] = { ...externalWorkflow, selectedWorkflow: { ...workflow } }
      setExternalWorkflows(updatedExternal)
    } else {
      const orgWorkflow = getOrgWorkflow(workflow?.workflow_id, orgWorkflows)
      setInternalSelectedWorkflow(orgWorkflow)
      updateProjectWorkflows(internalSelectedWorkflow, workflow, project, form, 'workflow')
    }
  }

  useEffect(() => {
    const fields = [
      'project_sold',
      'project_installed',
      'installation_date',
      'contract_date',
      'external_workflow',
      'workflow',
    ]
    fields.forEach((field) => {
      form.registerField(field, () => {}, {})
    })
  }, [])

  const availableWorkflows: WorkflowType[] =
    externalDisplay && externalWorkflow ? externalWorkflow.workflows : orgWorkflows

  const StyledDataIcon = styled(DataOutlineIcon, { name: 'StyledDataIcon' })({
    ...iconStyles,
  })
  return (
    <div className={classes.wrapper} id="Project-Workflows-Section">
      <RestyledSectionTop>
        <RowCentered>
          <StyledDataIcon size={18} variant={3} />
          <p className={classes.header}>{translate('Workflow')}</p>
        </RowCentered>
        {allowView && !noSharedWorkflows && (
          <WorkflowSelect
            workflows={availableWorkflows}
            selectedWorkflowId={selectedWorkflow?.id}
            handleChange={handleWorkflowChange}
            disabled={!allowEdit}
          />
        )}
        <RowCentered className={classes.sectionContentWrapper}>
          {!!project.shared_with?.length && (
            <RowCentered className={classes.marginLeft}>
              <SelectChipField
                className={classes.selectView}
                choices={[
                  { value: 'internal', title: org?.name },
                  { value: 'external', title: externalOrg },
                ]}
                chipLabel={translate('View As')}
                optionValue={'value'}
                optionText={'title'}
                value={view}
                onChange={(e) => {
                  const value: any = e.target.value
                  dispatch(
                    projectViewSettingsActions.setWorkflowView({
                      view: value,
                      externalWorkflow: value === 'internal' ? undefined : externalWorkflow,
                    })
                  )
                }}
              />
            </RowCentered>
          )}
        </RowCentered>
      </RestyledSectionTop>
      <Workflow
        selectedWorkflow={selectedWorkflow}
        externalWorkflow={externalDisplay ? externalWorkflow : undefined}
        handleChange={handleWorkflowChange}
        noSharedWorkflows={noSharedWorkflows}
        availableWorkflows={availableWorkflows}
      />
      {isCashFlowVisible && (
        <>
          <CashFlowWidget project={project} />
        </>
      )}
      {enableManageShop ? splitIsReady ? <ShopAccordion project={project} /> : <Skeleton /> : null}
    </div>
  )
}

export default WorkflowSection
