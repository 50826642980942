import { Struct } from 'contexts/structs/types/types'
import { Button } from 'opensolar-ui'
import { PromoDialogView } from 'persistentContent/promoDialog/PromoDialogView'
import { PromoDialog } from 'persistentContent/promoDialog/usePromoDialogStructs'
import { useState } from 'react'
import { useFormState } from 'react-final-form'
import { doNotTranslate } from 'util/misc'

// Show some strct-type specific UI here
export const StructCustomUI = () => {
  const formValues = useFormState<Struct<PromoDialog>>().values
  const structType = formValues.type

  const [open, setOpen] = useState(false)

  switch (structType) {
    case 'promo-dialog':
      const blocker = formValues.data.type === 'blocker'
      return (
        <>
          <PromoDialogView
            elements={formValues.data.elements}
            open={open}
            backEnabled={false}
            nextEnabled={true}
            onCloseComplete={() => setOpen(false)}
            showActionsBar={!blocker}
            showPagination={!blocker}
            totalPages={3}
            currentPage={1}
            nextWillClose={true}
            onBack={() => {}}
            onNext={() => setOpen(false)}
            onBackdropClick={() => setOpen(false)}
          />
          <Button variant="contained" onClick={() => setOpen(!open)}>
            {doNotTranslate('Preview')}
          </Button>
        </>
      )

    default:
      return <></>
  }
}
