import { ops } from 'conditions/types'
import { checkOp } from 'conditions/utils/utils'
import lodash from 'lodash'
import { handleAddComponent as addComponent } from 'projectSections/sections/design/util/handleAddComponent'
import { showNotification, SHOW_NOTIFICATION } from 'ra-core'
import { NonModuleComponentWithQty, ParentComponents } from 'types/associatedComponents'
import { AnyComponentType } from 'types/components'
import { StudioComponentItem } from 'types/global'
import { ComponentTypes, NonModuleComponentTypes } from 'types/selectComponent'
import { StudioItem, StudioSystemType } from 'types/studio/items'
import { SystemContext } from './types'

export const findComponentIn = (components: AnyComponentType[], code: string) => {
  return components.find((component) => upperCaseSafe(component.code) === upperCaseSafe(code))
}

const upperCaseSafe = (value: string | undefined) => {
  return value ? value.toUpperCase() : undefined
}

export function setSystemCustomData(
  context: SystemContext,
  prop: string | string[],
  value: unknown,
  key?: string
): void {
  if (!key) key = context.systemRuleKey
  const newSystemData = { ...(context.system.custom_data[key] || {}) }
  lodash.set(newSystemData, prop, value)
  const newCustomData = { ...context.system.custom_data, [key]: newSystemData }
  window.editor.execute(new window.SetValueCommand(context.system, 'custom_data', newCustomData))
}

// Returns null if any of the parents are not added to the system
export function getRequiredQty(
  parentComponents: ParentComponents,
  componentToAdd: NonModuleComponentWithQty,
  system: StudioSystemType
): number | null {
  let qtyRequired: number = 0
  let useRelativeQtys: boolean = false
  for (const parentComponent of parentComponents) {
    let parentQtyInSystem: number = 0
    if (parentComponent.componentType === 'module') {
      if (parentComponent.code && system.moduleType().code !== parentComponent.code) return null
      parentQtyInSystem = system.moduleQuantity()
    } else {
      if (parentComponent.code) {
        parentQtyInSystem =
          getSystemItemsByCode(parentComponent.componentType, parentComponent.code, system)
            ?.map((component) => component['quantity'] || 1)
            .reduce((acc, cur) => acc + cur, 0) || 0
        // If no component code is specified, return total qty of the component type
      } else {
        if (parentComponent.componentType === 'inverter') {
          parentQtyInSystem = system.inverters().length
        } else if (parentComponent.componentType === 'battery') {
          parentQtyInSystem = system.batteries().length
        } else if (parentComponent.componentType === 'other') {
          parentQtyInSystem = system.others().length
        }
      }
    }
    if (parentQtyInSystem === 0) return null
    // If the parentComponent qty is defined, we calculate the relative qty of componentToAdd required
    if (parentComponent.qty) {
      useRelativeQtys = true
      let qty = Math.floor(parentQtyInSystem / parentComponent.qty) * componentToAdd.qty
      if (!qtyRequired || qtyRequired > qty) qtyRequired = qty
    }
  }
  // If all parentComponents have no qty and are present in the system, use the static qty of componentToAdd
  if (!useRelativeQtys) {
    qtyRequired = componentToAdd.qty
  }
  return qtyRequired
}

export const getSystemItemsByCode = (type: ComponentTypes, code: string, system: StudioSystemType): StudioItem[] => {
  const components = getSystemItemsByType(type, system)
  return components.filter((component) => component.code === code)
}

const getSystemItemsByType = (type: ComponentTypes, system: StudioSystemType): StudioItem[] => {
  switch (type) {
    case 'other':
      return system.others()
    case 'inverter':
      return system.inverters()
    case 'battery':
      return system.batteries()
    case 'module':
      return system.getModules()
  }
}
export const getComponentQuantityInSystem = (system: StudioSystemType, type: ComponentTypes, code: string): number => {
  if (type === 'module') {
    if (system.moduleType().code === code) return system.moduleQuantity()
    else return 0
  }
  // Currently only the other component supports quantity
  return getComponentInSystem(system, type, code).reduce((total, c) => (total += c['quantity'] || 1), 0)
}

export const getComponentInSystem = (
  system: StudioSystemType,
  type: NonModuleComponentTypes,
  code: string
): StudioComponentItem[] => {
  let components: StudioComponentItem[]
  switch (type) {
    case 'inverter':
      components = system.inverters()
      break
    case 'battery':
      components = system.batteries()
      break
    case 'other':
      components = system.others()
      break
    default:
      throw new Error(`Unknown component type: ${type}`)
  }

  return components.filter((c) => c.code === code)
}

export const getActiveComponentsByType = (
  type: ComponentTypes | string,
  context: SystemContext
): AnyComponentType[] => {
  switch (type) {
    case 'other':
      return context.activeComponents.getOthers()
    case 'inverter':
      return context.activeComponents.getInverters()
    case 'battery':
      return context.activeComponents.getBatteries()
    case 'module':
      return context.activeComponents.getModules()
    default:
      throw new Error(`Unknown component type: ${type}`)
  }
}

export const getActiveComponentByCode = (
  type: ComponentTypes,
  code: string,
  context: SystemContext
): AnyComponentType | undefined => {
  const components = getActiveComponentsByType(type, context)
  return findComponentIn(components, code)
}

// This activates the component if required
// Then adds, removes or adjusts the quantity of the component
// to match quantityAdjustment
export const activateAddAndAdjustQuantity = (
  code: string,
  type: NonModuleComponentTypes,
  context: SystemContext,
  quantityAdjustment: number,

  opts: { noNotifications?: boolean; noActivate?: boolean }
) => {
  const component = getActiveComponentByCode(type, code, context)
  if (!component) {
    // Component not activated on org
    if (opts.noActivate || quantityAdjustment <= 0 || !context.role?.is_admin) {
      if (window.debugDesignRules)
        console.debug(`\tDesignRules: Skipping adjustment of un-activated component: code: ${code}`)
      return
    } else {
      // Attempt to activate then add
      activateComponentForAccount(code, () => {
        const component = getActiveComponentByCode(type, code, context)
        if (!component) {
          console.error(`\tDesignRules: Failed to activate component: code: ${code}`)
          return
        }
        addRemoveOrAdjustComponentQuantity(component, type, context.system, quantityAdjustment)
      })

      if (!opts.noNotifications)
        context.dispatch(showNotification('Component activated on account & assigned to system.'))
    }
  } else {
    // Component is activated on org
    addRemoveOrAdjustComponentQuantity(component, type, context.system, quantityAdjustment)

    if (!opts.noNotifications)
      context.dispatch({
        type: SHOW_NOTIFICATION,
        payload: {
          message: 'Component assigned to system',
        },
      })
  }
}

// Then adds, removes or adjusts the quantity of the components added to the system
// to match quantityAdjustment
export const addRemoveOrAdjustComponentQuantity = (
  component: AnyComponentType,
  type: NonModuleComponentTypes,
  system: StudioSystemType,
  quantityAdjustment: number
) => {
  const existingItems = getComponentInSystem(system, type, component.code)
  // Currently only the other component supports quantity
  while (quantityAdjustment !== 0 && existingItems.length && (quantityAdjustment < 0 || type === 'other')) {
    for (const item in existingItems) {
      const existingQuantity = existingItems[item]['quantity'] || 1
      if (quantityAdjustment < 0) {
        if (existingQuantity <= -quantityAdjustment) {
          if (window.debugDesignRules)
            console.debug(
              `\tDesignRules: addRemoveOrAdjustComponentQuantity - remove component entirely: ${component.code}`
            )
          // Remove all of this item
          window.editor.deleteObject(existingItems[item])
        } else {
          if (window.debugDesignRules)
            console.debug(
              `\tDesignRules: addRemoveOrAdjustComponentQuantity - adjusting quantity [1]: ${component.code} (${quantityAdjustment})`
            )
          // Adjust quantity (only other components should make it here)
          window.editor.execute(
            new window.SetValueCommand(
              existingItems[item],
              'quantity',
              existingQuantity + quantityAdjustment,
              window.Utils.generateCommandUUIDOrUseGlobal()
            )
          )
        }
        quantityAdjustment += existingQuantity
      } else {
        if (window.debugDesignRules)
          console.debug(
            `\tDesignRules: addRemoveOrAdjustComponentQuantity - adjusting quantity [2]: ${component.code} (${quantityAdjustment})`
          )
        // Only 'other' components will get here
        window.editor.execute(
          new window.SetValueCommand(
            existingItems[item],
            'quantity',
            existingQuantity + quantityAdjustment,
            window.Utils.generateCommandUUIDOrUseGlobal()
          )
        )
        return
      }
    }
  }
  if (quantityAdjustment <= 0) return
  // Add remainder
  if (type === 'other') {
    if (window.debugDesignRules)
      console.debug(
        `\tDesignRules: addRemoveOrAdjustComponentQuantity - add with quantity: ${component.code} (${quantityAdjustment})`
      )
    addComponent(type, system.uuid, { [type + 'Id']: component.id, quantity: quantityAdjustment })
  } else {
    if (window.debugDesignRules)
      console.debug(
        `\tDesignRules: addRemoveOrAdjustComponentQuantity - add components without quantity: ${component.code} (${quantityAdjustment})`
      )
    for (var i = 0; i < quantityAdjustment; i++) {
      addComponent(type, system.uuid, { [type + 'Id']: component.id })
    }
  }
}

const activateComponentForAccount = (code: string, callback: () => void) => {
  window.AccountHelper.activateComponents([code], callback)
}

export const ValidCheckOp = (op: string) => ops.includes(op as CheckOperator)
export type CheckScopeDef = {
  prop: string
  value: any
  op?: CheckOperator
}
export type CheckOperator = typeof ops[number]

export const checkScope = (scope: any, def: CheckScopeDef): boolean => {
  return checkOp(scope, def.prop, def.op || 'eq', def.value)
}
