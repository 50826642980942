import { adsSelectors } from 'ducks/ads'
import useAdStructs from 'elements/ads/useAdStructs'
import BannerAd, { filterAvailableAds } from 'elements/BannerAd'
import { useSelector } from 'react-redux'
import { useFeatureFlag } from 'util/split'

// TODO: further refactor BannerAd
// Create single source of truth that a ad banner would show
const useAdBanner = ({
  formats,
  placement,
  requiredWindowSize,
}: {
  formats: string[]
  placement: string
  requiredWindowSize?: { width: number; height: number }
}) => {
  const promoConfigs = useAdStructs()
  const availableAds = filterAvailableAds(promoConfigs, { formats, placement })
  const enableAdsFeatureFlag = useFeatureFlag('ads', 'on')
  const enableAdsForOrg = useSelector(adsSelectors.getEnableAdsForOrg)
  const enableAds = enableAdsFeatureFlag && enableAdsForOrg
  if (enableAds && availableAds && availableAds.length > 0 && placement) {
    return <BannerAd formats={formats} placement={placement} requiredWindowSize={requiredWindowSize} />
  }
  return null
}

export default useAdBanner
