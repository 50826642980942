import { LOCALES_SUPPORTED_CUSTOMER_FACING } from 'constants/locales'
import { authSelectors } from 'ducks/auth'
import CustomField from 'elements/field/CustomField'
import { useBulkActionButtons } from 'elements/hooks/useBulkActionButtons'
import { useEditShareable } from 'elements/react-admin/EditShareable'
import { List } from 'elements/react-admin/List'
import TextFieldWithSharedEntityIcon from 'elements/TextFieldWithSharedEntityIcon'
import inflection from 'inflection'
import { useMemo } from 'react'
import {
  Create,
  Datagrid,
  FilterComp,
  FunctionField,
  SelectInput,
  SimpleForm,
  TextInput,
  useTranslate,
} from 'react-admin'
import { useFormState } from 'react-final-form'
import { connect, useSelector } from 'react-redux'
import SharedFromInput from 'resources/connectedOrgs/filters/SharedFromInput'
import SharedWithInput from 'resources/connectedOrgs/filters/SharedWithInput'
import ShareabilitySelector from 'resources/connectedOrgs/ShareabilitySelector'
import { styles as standardStyles } from 'styles'
import { getRoleFromState, renderDraftEditor, renderRawHtmlEditor } from 'util/misc'
import { duplicate as duplicateAction } from '../../actions/restActions'
import EditOrImportButton from '../../elements/button/EditOrImportButton'
import DependentInput from '../../elements/input/DependentInput'
import OverviewInputs from '../../elements/input/OverviewInputs'
import EditorComponent from '../../elements/wysiwyg/EditorComponentWrapper'
import { DocumentFormatField } from './DocumentFormatField'
import DocumentTemplateFilterFields from './DocumentTemplateFilterFields'
import DocusignSettings, { IntegrationField } from './DocusignSettings'
import TemplateWizard from './TemplateWizard'

export const DOCUMENT_TEMPLATE_CHOICES = [
  { id: 0, name: 'Owners Manual' },
  { id: 1, name: 'Generic Document' },
  { id: 2, name: 'Installation Instructions' },
  { id: 4, name: 'Energy Yield Report' },
  { id: 5, name: 'Shade Report' },
  { id: 6, name: 'PV (Solar) Site Information Plan' },
  { id: 7, name: 'Structural Report (MCS)' },
  { id: 8, name: 'Change Order' },
  { id: 9, name: 'LightReach Sales Design Details' },
]

const TYPE_TO_MENTION_CONTEXT = {
  8: 'change_order',
  default: 'owners_manual',
}

export const EXCLUDE_DOCUMENT_TEMPLATES_FOR_PREFILL = [5, 7]

const DraftEditorWithContent = (props) => {
  const docusign_account_connected = useSelector(authSelectors.getDocusignAccountConnected)

  const mentionContext = useMemo(() => {
    return TYPE_TO_MENTION_CONTEXT[props?.record?.type] || TYPE_TO_MENTION_CONTEXT['default']
  }, [props?.record?.type])

  if (!props.record || !props.record.id) {
    return null
  }

  return (
    <EditorComponent
      label={props.label}
      fieldName={props.fieldName}
      allowUploadImage={true}
      mentionContext={mentionContext}
      docusign_account_connected={docusign_account_connected}
      {...props}
    />
  )
}

const documentTemplateTypesAsDict = {}
DOCUMENT_TEMPLATE_CHOICES.forEach((item) => {
  documentTemplateTypesAsDict[item.id] = item.name
})

DraftEditorWithContent.defaultProps = {
  addField: true,
}

export const DocumentTemplateFilter = (props) => (
  <FilterComp {...props}>
    <SelectInput
      options={{ style: standardStyles.FILTER_FIELD_STYLE }}
      source="type"
      choices={DOCUMENT_TEMPLATE_CHOICES}
    />
    <SharedFromInput source="owned_by" label={'Shared From'} />
    <SharedWithInput source="shared_with" label={'Shared With'} />
  </FilterComp>
)

export const _DocumentTemplateList = ({
  accessRights: { allowView, allowCreate, allowEdit, allowDelete },
  ...props
}) => {
  const translate = useTranslate()
  const isAdmin = useSelector((state) => Boolean(getRoleFromState(state)?.is_admin))
  const isFirstOrg = useSelector(authSelectors.getOrgId) === 1
  const bulkActionButtons = useBulkActionButtons(props, allowEdit, allowDelete)

  const resourceName = translate(`resources.${props.resource}.name`, {
    smart_count: 1,
    _: inflection.humanize(inflection.singularize(props.resource)),
  })

  return (
    <List
      hasSearch={true}
      extraCreateButtonLabel={isAdmin ? translate('ra.page.empty_new', { name: resourceName }) : null}
      filters={<DocumentTemplateFilter />}
      {...props}
      hasCreate={allowCreate}
      bulkActionButtons={bulkActionButtons}
    >
      <Datagrid duplicate={props.duplicate} cardTextStyle={{ padding: 15, height: 150, overflow: 'auto' }}>
        <TextFieldWithSharedEntityIcon source="title" label="Title" />
        <FunctionField
          source="type"
          displayInCard={true}
          render={(record) => documentTemplateTypesAsDict[record.type]}
          style={{ width: 20 }}
        />
        {isFirstOrg ? (
          <FunctionField
            source="language"
            displayInCard={true}
            render={(record) => LOCALES_SUPPORTED_CUSTOMER_FACING[record.locale]}
            style={{ width: 20 }}
          />
        ) : (
          ''
        )}
        {isFirstOrg ? (
          <FunctionField
            source="countries_included"
            displayInCard={true}
            render={(record) => record.countries_include}
            style={{ width: 20 }}
          />
        ) : (
          ''
        )}
        {isFirstOrg ? (
          <FunctionField
            source="countries_excluded"
            displayInCard={true}
            render={(record) => record.countries_exclude}
            style={{ width: 20 }}
          />
        ) : (
          ''
        )}

        <EditOrImportButton
          sortable={false}
          source="actions"
          label="Actions"
          org_id={props.org_id}
          resource="document_templates"
          duplicate={props.duplicate}
          isAdmin={isAdmin}
          allowEdit={allowEdit}
          allowDelete={allowDelete}
        />
      </Datagrid>
    </List>
  )
}

export const DocumentTemplateList = connect(
  (state) => {
    return {
      org_id: state.auth ? state.auth.org_id : null,
    }
  },
  { duplicate: duplicateAction }
)(_DocumentTemplateList)

export const ContentFields = (props) => {
  const formValues = useFormState().values
  return props.record && props.record.id > 0 && (props.record.content || props.record.content === '') ? (
    <div>
      <div className="document_template">
        <DependentInput dependsOn={'content'} resolve={renderDraftEditor}>
          <CustomField
            key={`content_fields_${formValues.integration}`}
            component={DraftEditorWithContent}
            //label={props.translate('Content')}
            fieldName="content"
            source="content"
            name="content"
            {...props}
            docusign_selected={formValues.integration === 1}
            hasCountersignEmail={formValues.docusign_designated_countersigner_email}
          />
        </DependentInput>
        <DependentInput dependsOn={'content'} resolve={renderRawHtmlEditor}>
          <CustomField
            component={TextInput}
            fullWidth={true}
            multiline={true}
            //label={props.translate('Content')}
            fieldName="content"
            source="content"
            name="content"
            {...props}
          />
        </DependentInput>
      </div>
    </div>
  ) : null
}
const inputStyle = {
  width: 256,
}

export const DocumentTemplateEdit = ({ accessRights, ...props }) => {
  const translate = useTranslate()

  const docusignConnected = useSelector(authSelectors.getDocusignAccountConnected)

  return useEditShareable({
    accessRights,
    editProps: props,
    render: ({ access }) => (
      <SimpleForm
        validate={(values) => validateDocument(values, translate, false)}
        toolbar={access.allowEdit ? undefined : false}
        disabled={!access.allowEdit}
      >
        <OverviewInputs>
          <CustomField
            inputStyle={inputStyle}
            component={TextInput}
            name="title"
            source="title"
            disabled={!access.allowEdit}
          />
          <CustomField
            component={SelectInput}
            name="type"
            source="type"
            choices={DOCUMENT_TEMPLATE_CHOICES}
            on
            disabled={true}
          />

          <DependentInput dependsOn="type" value={1} resolve={(val) => [1, 8].includes(val)}>
            <DocumentFormatField />
          </DependentInput>

          <CustomField
            component={TextInput}
            fullWidth={true}
            multiline={true}
            name="description"
            source="description"
            disabled={!access.allowEdit}
          />
          <DocumentTemplateFilterFields inputStyle={inputStyle} isEditForm={true} disabled={!access.allowEdit} />
          <DependentInput dependsOn="type" resolve={(val) => [1, 8].includes(val)}>
            <IntegrationField disabled={!access.allowEdit} />
          </DependentInput>
        </OverviewInputs>
        <DependentInput dependsOn="integration" value={1}>
          <DocusignSettings disabled={!access.allowEdit} />
        </DependentInput>
        <DependentInput dependsOn="format" value={1}>
          <ContentFields
            translate={translate}
            disabled={!access.allowEdit}
            docusign_account_connected={docusignConnected}
          />
        </DependentInput>
        <DependentInput dependsOn="format" value={2}>
          <TemplateWizard />
        </DependentInput>
        <ShareabilitySelector />
      </SimpleForm>
    ),
  }).editPage
}

export const DocumentTemplateCreate = (props) => {
  const translate = useTranslate()
  return (
    <Create {...props} record={{ format: 1, type: 1 }}>
      <SimpleForm validate={(values) => validateDocument(values, translate, true)}>
        <OverviewInputs noBorder={true}>
          <CustomField inputStyle={inputStyle} component={TextInput} name="title" source="title" />
          <DocumentTemplateFilterFields inputStyle={inputStyle} isEditForm={false} />
          <DependentInput dependsOn="type" value={1} resolve={(val) => [1, 8].includes(val)}>
            <DocumentFormatField />
          </DependentInput>
        </OverviewInputs>
      </SimpleForm>
    </Create>
  )
}

const validateDocument = (values, translate, isCreate) => {
  const errors = {}
  if (!values.title) {
    errors.title = 'Title is required.'
  }
  if (values.format === 2) {
    if (!values.file_contents_url && !isCreate) {
      errors.file_contents_url = translate('File is required.')
    } else {
      try {
        let tags = JSON.parse(values.content)?.tags
        let hasDocusignTag = false
        tags?.forEach((tag) => {
          try {
            if (tag?.value?.includes('/DS-')) hasDocusignTag = true
          } catch (ex) {}
        })
        if (hasDocusignTag && values.integration === 0)
          errors.integration = translate(
            'Template has Docusign Tags but is not marked as using the Docusign Integration'
          )
        else if (!hasDocusignTag && values.integration === 1)
          errors.integration = translate(
            'Template is marked as using the Docusign Integration but has no Docusign tags'
          )
      } catch (ex) {
        console.log('ex', ex)
      }
    }
  } else if (!values.type && values.type !== 0) {
    errors.type = translate('Document Type is required.')
  }
  return errors
}
