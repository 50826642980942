import { HardwareSupplierFilterKeyType } from 'pages/ordering/type'
import { useEffect, useState } from 'react'
import restClient from 'restClient'
import useSegenAuthenticationStatus from './segen/useSegenAuthenticationStatus'

const restClientInstance = restClient(window.API_ROOT + '/api')

const useDistributorAuthenticationStatus = ({
  orgId,
  distributor,
}: {
  orgId: number
  distributor: HardwareSupplierFilterKeyType
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)
  const [isChecking, setIsChecking] = useState<boolean>(false)
  const segenConnectStatus = useSegenAuthenticationStatus()

  useEffect(() => {
    setIsChecking(true)
    // This can happen when there are no enabled distributors
    if (!distributor) {
      setIsAuthenticated(false)
      setIsChecking(false)
    } else if (distributor === 'segen') {
      setIsAuthenticated(segenConnectStatus === 'authenticated')
      setIsChecking(false)
    } else if (distributor === 'outlet') {
      setIsAuthenticated(true)
      setIsChecking(false)
    } else {
      setIsChecking(true)
      restClientInstance('CUSTOM_GET', 'custom', {
        url: `orgs/${orgId}/distributors/${distributor}/authentication_status/`,
      })
        .then((response) => {
          if (response?.data?.result) {
            setIsAuthenticated(response?.data?.result?.is_authenticated)
          }
        })
        .catch((err: any) => {
          console.log(err, err)
        })
        .finally(() => {
          setIsChecking(false)
        })
    }
  }, [orgId, distributor])

  return { isAuthenticated, isChecking }
}

export default useDistributorAuthenticationStatus
