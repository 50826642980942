import useHardwareSelectorFetchEngine from 'elements/hardwareSelectorV2/fetch/useHardwareSelectorFetchEngine'
import { getSupplierConfig } from 'pages/ordering/configs'
import useEnabledHardwareSuppliers from 'pages/ordering/hooks/useEnabledHardwareSuppliers'
import OrderLineItem from 'pages/ordering/OrderLineItem'
import { HardwareSupplierFilterKeyType } from 'pages/ordering/type'
import { useComponentCost } from 'projectSections/hooks/useComponentCost'
import { useMemo } from 'react'
import { isMountingComponent } from 'util/misc'
import useHardwareDetailsDynamic from '../bom/useHardwareDetailsDynamic'
import { BomLineItemsHashMap } from '../bom/useMapHardwareDetailsToBomLineItems'

const useMapHardwareDetailsToBomLineItemsV3 = () => {
  const hardwareDetails = useHardwareDetailsDynamic()
  const enabledHardwareSuppliersEnum = useEnabledHardwareSuppliers()
  const enabledDistributors = enabledHardwareSuppliersEnum.map((item) => getSupplierConfig(item)?.filterKey)
  const hardwareCodes = hardwareDetails.map((hardwareDetail) => hardwareDetail.code)
  const { componentCosts } = useComponentCost()
  const { componentsData, setFilters, loading } = useHardwareSelectorFetchEngine({
    componentTypes: [],
    limit: 200,
    defaultFilterValues: {
      codes: Array.from(new Set(hardwareCodes)).join(','),
      require_distributor: '',
    },
  })

  return useMemo(() => {
    const bomLineItemsHashMap: BomLineItemsHashMap = {
      module: [],
      mounting: [],
      inverter: [],
      battery: [],
      other: [],
      all: [],
    }

    hardwareDetails.forEach((hardwareDetail) => {
      const componentCostData = componentCosts.find((item) => item.code === hardwareDetail.code)
      if (!enabledDistributors.includes(componentCostData?.source as HardwareSupplierFilterKeyType)) {
        return
      }

      const componentData = componentsData.find((item) => item.code === hardwareDetail.code)
      const orderingData = componentData?.ordering_v2?.find(
        (ordering) => ordering.distributor === componentCostData?.source
      )
      const bomComponentType = isMountingComponent(componentData?.other_component_type)
        ? 'mounting'
        : hardwareDetail.type

      const orderLineItem = new OrderLineItem({
        componentType: hardwareDetail.type,
        componentTypeV2: componentData?.other_component_type,
        status: 'loaded',
        code: hardwareDetail.code,
        selectedDistributor: componentCostData?.source as HardwareSupplierFilterKeyType,
        data: {
          code: hardwareDetail.code,
          component_type: hardwareDetail.type,
          description: orderingData?.description,
          short_description: orderingData?.short_description || '',
          distributors: orderingData ? [orderingData] : [],
          data: JSON.stringify({}),
          id: componentData?.id,
          manufacturer_name: orderingData?.manufacturer,
          title: orderingData?.title || '',
          image_url: orderingData?.image_url,
        },
        confirmed: true,
        quantity: hardwareDetail.quantity,
      })

      const existingLineItem = bomLineItemsHashMap['all']?.find((l) => l.code === orderLineItem.code)

      if (existingLineItem) {
        orderLineItem.quantity = existingLineItem.quantity += orderLineItem.quantity

        const existingAllIndex = bomLineItemsHashMap['all'].indexOf(existingLineItem)
        bomLineItemsHashMap['all'].splice(existingAllIndex, 1)

        const existingComponentTypeIndex = bomLineItemsHashMap[bomComponentType].indexOf(existingLineItem)
        bomLineItemsHashMap[bomComponentType].splice(existingComponentTypeIndex, 1)
      }

      bomLineItemsHashMap['all'].push(orderLineItem)
      bomLineItemsHashMap[bomComponentType].push(orderLineItem)
    })

    return bomLineItemsHashMap
  }, [hardwareDetails, componentsData, componentCosts])
}

export default useMapHardwareDetailsToBomLineItemsV3
