import { MathOperationType } from './TemplateWizard'

export const DOC_TEMPLATE_FORMAT = [
  { id: 1, name: 'Text Editor' },
  { id: 2, name: 'PDF Drag & Drop' },
]

export const staticPlaceholders = {
  popular: {
    DJANGO_TAGS_request_or_embed_signature: 'Embed Signature',
    DJANGO_TAGS_embed_initials: 'Embed Initials',
    DJANGO_TAGS_embed_date_signed: 'Embed Date Signed',
    'systems.0.data.kw_stc': 'System Capacity (kW STC)',
    'systems.0.data.module_quantity': 'Module Quantity',
    'systems.0.moduleManufacturer': 'Module Manufacturer',
    'systems.0.moduleCode': 'Module Model Number',
    'system.systemPanelPlacement': 'Panel Placement',
    'systems.0.inverterManufacturer': 'Inverter Manufacturer',
    'systems.0.inverterCodes': 'Inverter Model Number',
    'systems.0.batteryManufacturer': 'Battery Manufacturer',
    'systems.0.batteryCodes': 'Battery Model Number',
    'system.data.battery_total_kwh': 'System Battery kWh (Total)',
    'system.systemBatteryUsableKwh': 'System Battery kWh (Usable)',
    DJANGO_TAGS_battery_count: 'System Battery Quantity',
    'systems.0.data.payment_options.0.pricing.battery_price': 'System Battery price',
    'systems.0.otherComponentCodes': 'Other Components',
    'systems.0.systemOutputAnnualkWh': 'Annual Production (kWh)',
    'systems.0.data.payment_options.0.pricing.incentive_to_installer.total': 'Total Incentives to Installer',
    'systems.0.data.payment_options.0.pricing.incentive_to_customer.total': 'Total Incentives to Customer',
    DJANGO_TAGS_project_full_address: 'Full Project Address',
    'project.address': 'Project Street',
    'project.locality': 'Project City',
    'project.state': 'Project State',
    'project.zip': 'Project Zip Code',
    'systems.0.data.pricing.system_price_including_tax': 'Purchase Price (including tax)',
    'systems.0.data.payment_options.0.down_payment': 'Down Payment',
    'systems.0.data.payment_options.0.lender_name': 'Lender Name',
    'system.data.payment_options.0.title': 'Payment Option Name',
    'project.usage_annual_or_guess': 'Project Energy Usage Annual (kWh)',
    'system.data.consumption.consumption_offset_percentage': 'System Consumption Offset Percentage (%)',
    DJANGO_TAGS_price_per_watt_total: 'Price Per Watt (Total Price)',
    DJANGO_TAGS_adder_table_inc_tax: 'Adders Table (including tax)',
    DJANGO_TAGS_adder_table_ex_tax: 'Adders Table (excluding tax)',
    DJANGO_TAGS_adders_list: 'Adders List',
    'project.contract_date': 'Contract Date',
    'contacts.0.full_name': 'Customer 1 - Full Name',
    'contacts.0.first_name': 'Customer 1 - First Name',
    'contacts.0.family_name': 'Customer 1 - Last Name',
    'contacts.0.email': 'Customer 1 - Email',
    'contacts.0.phone': 'Customer 1 - Phone',
    'contacts.1.full_name': 'Customer 2 - Full Name',
    'contacts.1.first_name': 'Customer 2 - First Name',
    'contacts.1.family_name': 'Customer 2 - Last Name',
    'contacts.1.email': 'Customer 2 - Email',
    'contacts.1.phone': 'Customer 2 - Phone',
    'assigned_role.full_name': 'Assigned Team Member - Full Name',
    'assigned_role.first_name': 'Assigned Team Member - First Name',
    'assigned_role.family_name': 'Assigned Team Member - Last Name',
    'assigned_role.email': 'Assigned Team Member - Email',
    'assigned_role.phone': 'Assigned Team Member - Phone',
    'org.name': 'Business Name',
    'org.sales_phone_number': 'Business Sales Phone Number',
    'org.company_email': 'Business Email',
    'org.address': 'Business Street Address',
    'org.locality': 'Business City',
    'org.state': 'Business State',
    'org.zip': 'Business Zip',

    //DJANGO_TAGS_contract_plus_3_days: '3 Days After Contract',
  },
  custom_content: {
    FREE_TEXT: 'Enter any static text you want',
    DJANGO_TAGS: 'Custom Django Tags',
  },
  'systems.0': {
    test: 'test',
    pricing: {
      test: 'test',
    },
  },
  'systems.0.data.payment_options.0': {
    title: 'title',
  },
  docusign: {
    signature: 'First Customer Signature',
    initials: 'First Customer Initials',
    date_signed: 'First Customer Date Signed',
    full_name: 'First Customer Full Name',
    email: 'First Customer Email',
    phone: 'First Customer Phone',
    checkbox: 'First Customer Required Checkbox',
    checkbox_optional: 'First Customer Optional Checkbox',
    text: 'First Customer Required Text',
    text_optional: 'First Customer Optional Text',
    signature_secondary: 'Second Customer Signature',
    initials_secondary: 'Second Customer Initials',
    date_signed_secondary: 'Second Customer Date Signed',
    full_name_secondary: 'Second Customer Full Name',
    email_secondary: 'Second Customer Email',
    phone_secondary: 'Second Customer Phone',
    checkbox_secondary: 'Second Customer Required Checkbox',
    checkbox_optional_secondary: 'Second Customer Optional Checkbox',
    text_secondary: 'Second Customer Required Text',
    text_optional_secondary: 'Second Customer Optional Text',
    signature_counter: 'Countersigner Signature',
    initials_counter: 'Countersigner Initials',
    date_signed_counter: 'Countersigner Date Signed',
    full_name_counter: 'Countersigner Full Name',
    email_counter: 'Countersigner Email',
    phone_counter: 'Countersigner Phone',
    checkbox_counter: 'Countersigner Required Checkbox',
    checkbox_optional_counter: 'Countersigner Optional Checkbox',
    text_counter: 'Countersigner Required Text',
    text_optional_counter: 'Countersigner Optional Text',
  },
}

export const pathToDjangoMap = {
  //'popular.DJANGO_TAGS_contract_plus_3_days': '{{ project.contract_date|plus_days:3 }}',
  'popular.DJANGO_TAGS_battery_count': '{{ systems.0.data.batteries|length }}',
  'popular.DJANGO_TAGS_request_or_embed_signature': '{{request_or_embed_signature}}',
  'popular.DJANGO_TAGS_embed_initials':
    '{{ contacts.0.first_name|make_list|first }}{{ contacts.0.family_name|make_list|first }}',
  'popular.DJANGO_TAGS_embed_date_signed': '{{ project.contract_date }}',
  'popular.DJANGO_TAGS_project_full_address':
    '{{ project.address }}, {{ project.locality }}, {{ project.state}} {{ project.zip }}',
  'popular.DJANGO_TAGS_price_per_watt_total':
    '{{ system.data|get_price_per_watt_purchase_price|divide:1000|floatformat:2 }}',
  'popular.DJANGO_TAGS_adder_table_inc_tax':
    '{% for adder in systems.0.data.pricing.line_items %}{% if adder.show_customer %}{{ adder.label }}: {{ adder.inc_tax|currency:2 }}\n{% endif %}{% endfor %}{% if systems.0.data.pricing.show_customer_adders !== 0 %}Total: {{ systems.0.data.pricing.show_customer_adders|currency:2 }}{% endif %}',
  'popular.DJANGO_TAGS_adder_table_ex_tax':
    '{% for adder in systems.0.data.pricing.line_items %}{% if adder.show_customer %}{{ adder.label }}: {{ adder.ex_tax|currency:2 }}\n{% endif %}{% endfor %}{% if systems.0.data.pricing.show_customer_adders !== 0 %}Total: {{ systems.0.data.pricing.show_customer_adders|currency:2 }}{% endif %}',
  'popular.DJANGO_TAGS_adders_list': '{% for adder in systems.0.data.line_items %}{{ adder.label }}, {% endfor %}',
}

export const docusignTagMap = {
  signature: '/DS-sig-',
  initials: '/DS-initials-',
  date_signed: '/DS-date-',
  full_name: '/DS-name-',
  email: '/DS-email-',
  phone: '/DS-phone-',
  checkbox: '/DS-checkbox-',
  checkbox_optional: '/DS-checkboxoptional-',
  text: '/DS-text-',
  text_optional: '/DS-textoptional-',
  signature_secondary: '/DS-sig2-',
  initials_secondary: '/DS-initials2-',
  date_signed_secondary: '/DS-date2-',
  full_name_secondary: '/DS-name2-',
  email_secondary: '/DS-email2-',
  phone_secondary: '/DS-phone2-',
  checkbox_secondary: '/DS-checkbox2-',
  checkbox_optional_secondary: '/DS-checkboxoptional2-',
  text_secondary: '/DS-text2-',
  text_optional_secondary: '/DS-textoptional2-',
  signature_counter: '/DS-sigcounter-',
  initials_counter: '/DS-initialscounter-',
  date_signed_counter: '/DS-datecounter-',
  full_name_counter: '/DS-namecounter-',
  email_counter: '/DS-emailcounter-',
  phone_counter: '/DS-phonecounter-',
  checkbox_counter: '/DS-checkboxcounter-',
  checkbox_optional_counter: '/DS-checkboxoptionalcounter-',
  text_counter: '/DS-textcounter-',
  text_optional_counter: '/DS-textoptionalcounter-',
}

export const getDocusignLabelFromTag = (tagVal: string) => {
  let reversedDocusignTagMap: { [key: string]: string } = {}
  Object.keys(docusignTagMap).forEach((tagKey) => (reversedDocusignTagMap[docusignTagMap[tagKey]] = tagKey))
  let tagWithoutSuffix = tagVal.substring(0, tagVal.lastIndexOf('-') + 1)
  if (!tagWithoutSuffix) return tagVal
  let labelKey = reversedDocusignTagMap[tagWithoutSuffix]
  if (!labelKey) return tagVal
  return staticPlaceholders.docusign[labelKey]
}

export const fieldPathToDisplayMap = {
  'project.lat': 'Latitude',
  'project.lon': 'Longitude',
  org: 'Your Company',
  customer: 'Primary Customer',
  contacts: 'All Customers',
  assigned_role: 'Assigned Team Member',
  'systems.0': 'System',
  original_system: 'Original System',
  'systems.0.data.payment_options.0': 'Payment Option',
  'popular.systems.0.data.kw_stc': 'System Size',
  'customer.custom_contact_info_1': 'Custom Field 1',
  'customer.custom_contact_info_2': 'Custom Field 2',
  'contacts.0.custom_contact_info_1': 'Custom Field 1',
  'contacts.0.custom_contact_info_2': 'Custom Field 2',
  'contacts.1.custom_contact_info_1': 'Custom Field 1',
  'contacts.1.custom_contact_info_2': 'Custom Field 2',
  'contacts.2.custom_contact_info_1': 'Custom Field 1',
  'contacts.2.custom_contact_info_2': 'Custom Field 2',
  'contacts.3.custom_contact_info_1': 'Custom Field 1',
  'contacts.3.custom_contact_info_2': 'Custom Field 2',
  'popular.DJANGO_TAGS_price_per_watt_total': 'Price Per Watt (Total Price)',
  'popular.DJANGO_TAGS_adder_table_inc_tax': 'Adders Table (including tax)',
  'popular.DJANGO_TAGS_adder_table_ex_tax': 'Adders Table (excluding tax)',
  'popular.DJANGO_TAGS_adders_list': 'Adders List',
}

export const indexToWord = {
  '0': '1st',
  '1': '2nd',
  '2': '3rd',
  '3': '4th',
  '4': '5th',
  '5': '6th',
  '6': '6th',
  '7': '7th',
  '8': '8th',
  '9': '9th',
  '10': '10th',
  '11': '11th',
  '12': '12th',
  '13': '13th',
  '14': '14th',
  '15': '15th',
  '16': '16th',
  '17': '17th',
  '18': '18th',
  '19': '19th',
  '20': '20th',
  '21': '21th',
  '22': '22th',
  '23': '23th',
  '24': '24th',
  '25': '25th',
  '26': '26th',
  '27': '27th',
  '28': '28th',
  '29': '29th',
  '30': '30th',
  '31': '31th',
  '32': '32th',
  '33': '33th',
  '34': '34th',
  '35': '35th',
  '36': '36th',
  '37': '37th',
  '38': '38th',
  '39': '39th',
  '40': '40th',
}

export const EMPTY_FORMULA_ROW: MathOperationType = {
  operator: '+',
  operand_type: 'number',
  operand_value: 0,
}

export const placeholderPathsToHide = ['systems']
