import { TextFieldProps } from '@material-ui/core'
import ChipsInput from 'elements/input/ChipsInput'
import React, { useMemo } from 'react'
import { InputProps, ReferenceArrayInput } from 'react-admin'

//Hooks up ReferenceArrayInput to a ChipsInput field

interface PropTypes {
  source: string
  resource: string
  reference: string
  optionValue?: string
  optionText?: string
  variant?: string
  className?: string
  chipsInputProps?: InputProps<TextFieldProps>
  onChange?: (values) => void
}
const ChipArrayInput: React.FC<PropTypes> = (props) => {
  const { source, reference, resource } = props
  return (
    <ReferenceArrayInput
      renderChildrenBeforeRecord
      source={source}
      component={ReferenceArrayInput}
      reference={reference}
      resource={resource}
      fullWidth
      id={undefined}
      onBlur={undefined}
      onChange={undefined}
      onFocus={undefined}
      validate={undefined}
      parse={undefined}
      format={undefined}
    >
      <ModifiedChipsInput {...props} />
    </ReferenceArrayInput>
  )
}

const ModifiedChipsInput = (props) => {
  const { optionText, optionValue, variant, source, chipsInputProps, input, onChange, choices } = props
  const handleChange = (values) => {
    input.onChange(values)
    if (onChange)
      onChange(
        values.map((x) => {
          const findItem = choices.find((item) => (optionValue ? item[optionValue] : item.url) === x)
          if (findItem) return findItem
          else return undefined
        })
      )
  }
  const inputProps = useMemo(() => ({ ...input, onChange: handleChange }), [input])
  return (
    <ChipsInput
      optionText={optionText || 'title'}
      optionValue={optionValue || 'url'}
      translateChoice={false}
      fullWidth
      variant={variant || 'outlined'}
      source={source}
      {...props}
      {...chipsInputProps}
      input={inputProps}
      label={null}
    />
  )
}

export default ChipArrayInput
