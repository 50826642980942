import { SystemParams } from './EnphaseTypes'
import {
  calculateInverterLimits,
  getBestCableTypes,
  getMicroInverterQuantity,
  isComponentRelevant,
} from './enphase_bom_generator/EnphaseCalculationHelpers'
import { OperationExecutor } from './enphase_bom_generator/enphase_bom_rules/types/OperationExecutor'
import { RuleEvaluator } from './enphase_bom_generator/enphase_bom_rules/types/RuleEvalutator'

import { EnphaseRule } from './enphase_bom_generator/enphase_bom_rules/types/RuleTypes'
import { isValidRule } from './enphase_bom_generator/enphase_bom_rules/types/RuleValidator'

/**
/**
 * Calculates the recommended quantities of Enphase system components based on the provided system parameters.
 * @param params - The system parameters.
 * @returns A record mapping each component ID to its recommended quantity.
 */
export const calculateEnphaseComponents = (
  params: SystemParams,
  rules: Record<string, EnphaseRule>
): Record<string, number> => {
  if (!rules || typeof rules !== 'object') {
    console.error('Invalid Enphase rules:', rules)
    return {}
  }
  const moduleType = params.system.moduleType()
  const defaultDimensions = { width: 1.0, height: 1.5 }
  const limits = calculateInverterLimits(params.system.inverters()[0].code, params.numberOfPhases, params.countryCode)
  const numberOfBranches = Math.ceil(params.numberOfPanels / limits.maxUnitsPerCircuit)

  const getInverterCount = () => {
    const inverter = params.system.inverters()[0]
    return getMicroInverterQuantity(params.system.moduleQuantity() - params.system.getStrungModules().length, inverter)
  }

  const numberOfExtraDrops = Math.ceil(getInverterCount() / 20)

  const context: Record<string, any> = {
    numberOfPhases: params.numberOfPhases,
    isCommercial: params.isCommercial,
    countryCode: params.countryCode,
    numberOfBranches,
    numberOfPanels: params.numberOfPanels,
    numberOfPanelsLandscape: params.numberOfPanelsLandscape,
    numberOfPanelsPortrait: params.numberOfPanelsPortrait,
    numberOfExtraDrops: numberOfExtraDrops,
    getBestCableTypes: (isThreePhase: boolean) =>
      getBestCableTypes(
        params.countryCode,
        isThreePhase,
        moduleType.width || defaultDimensions.width,
        moduleType.height || defaultDimensions.height,
        params.numberOfPanelsPortrait,
        params.numberOfPanelsLandscape
      ),
    getInverterLimits: () => limits,
    getBatteryCode: () => params.system.batteries()[0]?.code || '',
    getBatteryCount: () => params.system.batteries().length,
    getInverterCount: () => getInverterCount(),
  }

  const executor = new OperationExecutor(context)
  const evaluator = new RuleEvaluator(executor)

  // Aggregate rules by id, selecting the highest version
  const aggregatedRules = Object.values(rules)
    .filter((rule: EnphaseRule) => rule.active)
    .reduce((aggregatedAccumulator, currentRule) => {
      if (!isValidRule(currentRule)) {
        console.warn(`Enphase Bom Generator - Skipping invalid rule with ID: ${currentRule}`)
        return aggregatedAccumulator // Skip invalid rules
      }

      const existingAggregatedRule = aggregatedAccumulator[currentRule.id]
      if (!existingAggregatedRule || currentRule.version > existingAggregatedRule.version) {
        aggregatedAccumulator[currentRule.id] = {
          id: currentRule.id,
          version: currentRule.version,
          active: currentRule.active,
          displayName: currentRule.displayName,
          section: currentRule.section,
          notes: currentRule.notes,
          requiresPhase: currentRule.requiresPhase,
          availableIn: currentRule.availableIn,
          notAvailableIn: currentRule.notAvailableIn,
          preventWithBattery: currentRule.preventWithBattery,
          requiresBattery: currentRule.requiresBattery,
          requiresCommercial: currentRule.requiresCommercial,
          hidden: currentRule.hidden
            ? {
                conditions: currentRule.hidden.conditions
                  ? currentRule.hidden.conditions.map((c) => ({ ...c }))
                  : undefined,
                field: currentRule.hidden.field,
                operator: currentRule.hidden.operator,
                value: currentRule.hidden.value,
              }
            : undefined,
          rules: currentRule.rules,
        }
      }
      return aggregatedAccumulator
    }, {} as Record<string, EnphaseRule>)

  return Object.entries(aggregatedRules).reduce((result, [id, rule]) => {
    result[id] = isComponentRelevant(rule.id, params, rules) ? evaluator.evaluate(rule) : 0
    return result
  }, {} as Record<string, number>)
}

export const getRecommendedQuantities = calculateEnphaseComponents
