import { useContext, useMemo } from 'react'
import { StructsContextCore } from '../StructsContext'
import { StructsLoadInfo } from '../types/types'

export const useStructs = <T extends Record<string, any> = Record<string, any>>(type: string): StructsLoadInfo<T> => {
  const structs = useContext(StructsContextCore)
  const ret = structs.structs[type] as StructsLoadInfo<T> | undefined

  return useMemo(() => {
    return ret ? ret : { loadState: 'not-loaded', structs: [] }
  }, [ret])
}
