import { clearLoanDecisionRendered } from 'ducks/myEnergy'
import { CheckoutActionCallback, SUPPORTED_ELEMENT_TYPES } from 'opensolar-checkout'
import { useCallback, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import useLoanApplicationCallback from './loanApplication/useLoanApplicationCallback'
import usePhoenixApplicationCallback from './loanApplication/usePhoenixApplicationCallback'
import useAcceptProposalCallback from './useAcceptProposalCallback'
import useLoanAgreementActionCallback from './useLoanAgreementActionCallback'
import usePrequalifyCallback from './usePrequalifyCallback'
import useShowCashflowDepositDialogCallback from './useShowCashflowDepositDialogCallback'
import useShowUploadStipulationDialogCallback from './useShowUploadStipulationDialogCallback'

const useCheckoutActionCallback = (): CheckoutActionCallback => {
  const dispatch = useDispatch()
  const acceptProposalCallback = useAcceptProposalCallback()
  const showCashflowDeposit = useShowCashflowDepositDialogCallback()
  const prequalifyCallback = usePrequalifyCallback()
  const loanApplicationCallback = useLoanApplicationCallback()
  const phoenixApplicationCallback = usePhoenixApplicationCallback()
  const voidCallback = useCallback(async () => {}, [])
  const showUploadStipulationDialogCallback = useShowUploadStipulationDialogCallback()
  const loanAgreementActionCallback = useLoanAgreementActionCallback()

  useEffect(() => {
    dispatch(clearLoanDecisionRendered())
    return () => {
      dispatch(clearLoanDecisionRendered())
    }
  }, [])

  // This hashmap forces the compiler to check that all supported element types are handled
  const callbackHashMap: { [key in typeof SUPPORTED_ELEMENT_TYPES[number]]: CheckoutActionCallback } = {
    AcceptProposal: acceptProposalCallback,
    CashFlowDeposit: showCashflowDeposit,
    Prequal: prequalifyCallback,
    Divider: voidCallback,
    LoanApplication: loanApplicationCallback,
    PhoenixApplication: phoenixApplicationCallback,
    AlertCTA: voidCallback,
    FinanceStipulations: showUploadStipulationDialogCallback,
    LoanAgreement: loanAgreementActionCallback,
    Message: voidCallback,
    Alert: voidCallback,
  }

  return async ({ elementData, proposalData, additionalActionData }) => {
    const callback = callbackHashMap[elementData.type]
    if (!callback) {
      throw new Error('Invalid element data for checkout callback')
    }
    return callback({ elementData, proposalData, additionalActionData })
  }
}

export default useCheckoutActionCallback
