import { Paper, Typography } from '@material-ui/core'
import { styled } from 'opensolar-ui'
import { ExtendedHardwareSupplierConfig } from 'projectSections/sections/manage3/hardware/DistributorChip'
import ConnectButton from './ConnectButton'

const FullSizePaper = styled(Paper)({
  width: '100%',
  height: '100%',
  borderRadius: 8,
})

const FlexWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '24px 24px 0px 24px',
  minHeight: 83,
  flexWrap: 'wrap',
})

const Wrapper = styled('div')({
  padding: '12px 24px 24px 24px',
})

const DistributorConnectionCard = ({
  config,
  location = 'shop'
}: {
  config: ExtendedHardwareSupplierConfig,
  location?: 'shop' | 'design'
}) => {
  const getDescription = () => {
    switch (config.filterKey) {
      case 'segen':
        return 'The UK’s largest renewable energy distributor with a wide product range. Get next-day delivery, dedicated sales managers, a tech team, and installer events. Open an account today!'
      case 'city_plumbing':
        return 'The UK’s leading supplier of renewable technology. 1000s of products in stock from leading manufacturers. Full support of 360 branches, AM delivery options, 20+ years experience, award-winning training.'
      case 'hdm':
        return 'We’re The Installer’s Wholesaler, a trusted partner for installers everywhere, providing dedicated products and services for trade customers to help them be successful.'
      case 'vtac_uk':
        return 'V-TAC specialises in renewable energy products, offering innovative solutions for sustainable power generation. Their commitment to efficiency and eco-friendliness supports a greener future for all.'
      case 'vtac_pl':
        return 'V-TAC specialises in renewable energy products, offering innovative solutions for sustainable power generation. Their commitment to efficiency and eco-friendliness supports a greener future for all.'
      case 'outlet':
        return 'Solar Outlet is the largest online distributor for solar panels, inverters, batteries and complete kits.'
      default:
        return ''
    }
  }

  return (
    <FullSizePaper variant="outlined">
      <FlexWrapper>
        <img src={config.logoUrl} alt="logo" style={{ width: 'auto', height: 56, objectFit: 'fill' }} />
        <ConnectButton
          key={config.filterKey}
          config={config}
          fromDesign={location === 'design'}
        />
      </FlexWrapper>
      <Wrapper>
        <Typography variant="h6" gutterBottom>
          {config.name}
        </Typography>
        <Typography variant="body1" gutterBottom color="textSecondary">
          {getDescription()}
        </Typography>
      </Wrapper>
    </FullSizePaper>
  )
}

export default DistributorConnectionCard
