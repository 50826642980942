import { and, ControlProps, rankWith, uiTypeIs } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import ClearIcon from '@material-ui/icons/Clear'
import { LOCALES_SUPPORTED } from 'constants/locales'
import { IconButton, MenuItem, Select, styled, TextField } from 'opensolar-ui'
import { CSSProperties, useCallback, useMemo, useState } from 'react'
import { doNotTranslate } from 'util/misc'

const tester = rankWith(100, and(uiTypeIs('TranslatableText')))

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: 8,
  width: '100%',
  alignItems: 'end',
})

const TranslatableTextRenderer = (props: ControlProps) => {
  const multiline = !!props.uischema.options?.multi

  const renderTextField = ({ label, value, onChangeCurrent, clearButton }) => {
    const onValueChange = (event) => {
      onChangeCurrent(event.target.value)
    }

    return (
      <TextField
        label={label}
        value={value || ''}
        onChange={onValueChange}
        fullWidth
        InputProps={{
          endAdornment: clearButton,
        }}
        multiline={multiline}
        variant={multiline ? 'outlined' : undefined}
      />
    )
  }
  return (
    <TranslatableTextWrapper
      style={{ padding: multiline ? '10px 0' : undefined, boxSizing: 'border-box' }}
      {...props}
      render={renderTextField}
      renderClear={false}
    />
  )
}

type RenderFunc = (props: {
  label: string | undefined
  value: string
  onChangeCurrent: (value: string) => void
  onClearCurrentLocale: () => void
  locale: string
  clearButton: JSX.Element
  localeSelector: JSX.Element
}) => JSX.Element

export const TranslatableTextWrapper = ({
  label,
  data,
  handleChange,
  path,
  render,
  renderClear = true,
  renderSelect = true,
  style = undefined,
}: ControlProps & { render: RenderFunc; renderClear?: boolean; renderSelect?: boolean; style?: CSSProperties }) => {
  const [locale, setLocale] = useState<string>('en')
  const value = useMemo(() => {
    return data && data[locale]
  }, [data, locale])

  const onLocaleChange = useCallback((event: React.ChangeEvent<{ value: unknown }>) => {
    const locale = event.target.value as string
    setLocale(locale)
  }, [])

  const onChangeCurrent = useCallback(
    (value: string) => {
      const newData = { ...data, [locale]: value }
      handleChange(path, newData)
    },
    [data, locale]
  )

  const onClearCurrentLocale = useCallback(() => {
    const newData = { ...data }
    delete newData[locale]
    handleChange(path, newData)
  }, [data, locale])

  const labelStr = typeof label === 'string' ? label : undefined

  const clearButton = (
    <IconButton disableRipple onClick={onClearCurrentLocale} size="small" disabled={value === undefined}>
      <ClearIcon />
    </IconButton>
  )

  const localeSelector = (
    <Select value={locale} onChange={onLocaleChange}>
      {Object.keys(LOCALES_SUPPORTED).map((key: string) => (
        <MenuItem key={key} value={key}>
          {doNotTranslate(LOCALES_SUPPORTED[key] === 'English' ? 'Default (Eng)' : LOCALES_SUPPORTED[key])}
        </MenuItem>
      ))}
    </Select>
  )

  return (
    <Container style={style}>
      {render({ label: labelStr, value, onChangeCurrent, onClearCurrentLocale, locale, clearButton, localeSelector })}

      {renderClear && clearButton}

      {renderSelect && localeSelector}
    </Container>
  )
}

const renderer = withJsonFormsControlProps(TranslatableTextRenderer)

export default {
  tester,
  renderer,
}
