import { Typography } from '@material-ui/core'
import { styled } from 'opensolar-ui'
import { useContext } from 'react'
import { OrderContext } from '../..'
import OrderActivity from './OrderActivity'

export const FullWidthWrapper = styled('div', { name: 'FullWidthWrapper' })({
  width: '100%',
})

export const SectionCardTop = styled('div', { name: 'SectionCardTop' })(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: 20,
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
  borderBottom: theme.mixins.borderStandard.border,
}))

export const SectionCardMiddle = styled('div', { name: 'SectionCardMiddle' })(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: 20,
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  borderBottom: theme.mixins.borderStandard.border,
}))

export const SectionCardBottom = styled('div', { name: 'SectionCardBottom' })(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: 20,
  borderBottomLeftRadius: 8,
  borderBottomRightRadius: 8,
}))

const OrderActivitySection = () => {
  const { orderData } = useContext(OrderContext)

  return (
    <FullWidthWrapper>
      <SectionCardTop>
        <Typography variant="h6">Order Activity</Typography>
      </SectionCardTop>
      <SectionCardBottom>
        <OrderActivity orderData={orderData} />
      </SectionCardBottom>
    </FullWidthWrapper>
  )
}

export default OrderActivitySection
