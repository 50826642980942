import { FormControl, FormControlLabel, MenuItem, RadioGroup, Select, Tooltip, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Modal from 'elements/modal/Modal'
import { useSendPvfActivationEmail } from 'hooks/usePremiumProducts'
import { BaseTheme, Button, DialogCloseButton, HelpCircleIcon, Radio } from 'opensolar-ui'
import React, { useMemo, useState } from 'react'
import { useLocale, useNotify } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { useHistory } from 'react-router-dom'
import PremiumProductTermsDialog from 'resources/premiumProducts/PremiumProductTermsDialog'
import { ProjectType } from 'types/projects'
import { doNotTranslate, formatCurrencyWithSymbol } from 'util/misc'
import DiscardDialog from './ConfirmDialog'
import { costReviewFromTeam, MAX_HEIGHT_AND_LENGTH_PVF, Scenario } from './constants'
import useStyles from './scaffoldStyle'
import { validateScaffoldProperties } from './utils'

interface QuoteProps {
  isOpen: boolean
  onClose: () => void
  calculateCost: () => void
  openQuoteModal: () => void
}

const OrderScaffold: React.FunctionComponent<QuoteProps> = ({ isOpen, onClose, calculateCost, openQuoteModal }) => {
  const classes = useStyles()
  const history = useHistory()
  const locale = useLocale()
  const project = useFormState().values as ProjectType
  const projectId = project.id
  const form = useForm()
  const customData = project?.custom_data?.scaffolding
  const [errors, setErrors] = useState({})
  const [enableDialogOpen, setEnableDialogOpen] = useState<boolean>(false)
  const [discardDialogOpen, setDiscardDialogOpen] = useState<boolean>(false)
  const notify = useNotify()

  const closeEnableDialog = () => setEnableDialogOpen(false)

  const sendActivationEmail = useSendPvfActivationEmail()

  const onPvfEnable = () => {
    sendActivationEmail()
    form.change(`custom_data.scaffolding.form_data.scaffold_properties.is_pvf_enabled`, true)
    setEnableDialogOpen(false)
    openQuoteModal()
  }

  const handleQuoteCreation = async (e) => {
    onClose() // close the order modal
    if (!customData.is_pvf_enabled) {
      // check if pvf is enabled or not
      setEnableDialogOpen(true)
      return
    }
    // open create quote modal
    openQuoteModal()
  }
  const handleSaveExit = (e) => {
    onClose()
  }
  const handleInputChange = (e, name: string) => {
    form.change(`custom_data.scaffolding.form_data.scaffold_properties.${name}`, e.target.value)
    setErrors({})
  }
  const handleSubmit = () => {
    const error = validateScaffoldProperties(customData)
    setErrors(error)
    if (Object.keys(error).length > 0) return
    calculateCost()
  }

  const renderDescription = (description: string) => {
    return description.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ))
  }

  const lengthAndHeightOptions = useMemo(() => [...Array(MAX_HEIGHT_AND_LENGTH_PVF * 2 + 1)].map((_, i) => i * 0.5), [])

  return (
    <>
      <Modal
        isOpen={isOpen}
        header={
          <div className={classes.modalHeader}>
            <span className={classes.modalHeaderText}>{doNotTranslate('Order Scaffold')}</span>
            <DialogCloseButton onClick={() => setDiscardDialogOpen(true)} />
          </div>
        }
        body={
          <div>
            <div className={classes.gap8}>
              <div className={classes.modalHeading}>{doNotTranslate('Calculate Scaffolding Cost')}</div>
              <div className={classes.formFont}>
                {doNotTranslate(
                  'Fill the below information to calculate the cost of scaffolding. Please note that the cost calculated is an estimate and may change.'
                )}
              </div>
              <form>
                <div className={`${classes.formContentOrderScaffold} ${classes.formFont} ${classes.marginTop40}`}>
                  <div className={classes.formContainer}>
                    <FormControl className={classes.fullWidth}>
                      <div className={`${classes.gap6} ${classes.displayFlex}`}>
                        <Typography className={classes.marginBottom5}>
                          {doNotTranslate(`Building Height (max ${MAX_HEIGHT_AND_LENGTH_PVF}m)`)}
                        </Typography>
                        <Tooltip
                          title={
                            <div className={classes.tooltipContent}>
                              <img
                                alt="scaffold"
                                src={window.PUBLIC_URL + '/images/scaffold_measure_height.gif'}
                                className={classes.tooltipImageStyle}
                              />
                              <Typography>
                                {doNotTranslate(
                                  'You can measure scaffold length directly on the design page with our Scaffold tool.'
                                )}
                              </Typography>
                            </div>
                          }
                          PopperProps={{
                            style: {
                              zIndex: 2002,
                            },
                          }}
                          interactive
                          placement="bottom"
                          arrow
                          classes={{
                            arrow: classes.tooltipArrow,
                            tooltip: classes.tooltip,
                          }}
                        >
                          <span className={classes.tooltipIconSize}>
                            <HelpCircleIcon size={14} color={BaseTheme.palette.grey[700]} />
                          </span>
                        </Tooltip>
                      </div>
                      <Select
                        labelId="building-height-select-label"
                        id="building-height-select"
                        variant="outlined"
                        value={customData.form_data?.scaffold_properties?.height}
                        displayEmpty={true}
                        renderValue={(value) =>
                          value ? (
                            <div>{`${value} m`}</div>
                          ) : (
                            <div className={classes.selectorColor}>{'Select height'}</div>
                          )
                        }
                        label={doNotTranslate(`Building Height (max ${MAX_HEIGHT_AND_LENGTH_PVF}m)`)}
                        onChange={(e) => handleInputChange(e, 'height')}
                        fullWidth={true}
                        className={classes.customFormControl}
                        IconComponent={ExpandMoreIcon}
                      >
                        {lengthAndHeightOptions?.map((item: number) => {
                          return (
                            <MenuItem key={item} value={item}>
                              {item} m
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                    <FormControl className={classes.fullWidth}>
                      <div className={`${classes.gap6} ${classes.displayFlex}`}>
                        <Typography className={classes.marginBottom5}>
                          {doNotTranslate(`Scaffold Total Length (max ${MAX_HEIGHT_AND_LENGTH_PVF}m)`)}
                        </Typography>
                        <Tooltip
                          title={
                            <div className={classes.tooltipContent}>
                              <img
                                alt="scaffold"
                                src={window.PUBLIC_URL + '/images/scaffold_gif.gif'}
                                className={classes.tooltipImageStyle}
                              />
                              <Typography>
                                {doNotTranslate(
                                  'You can measure scaffold length directly on the design page with our Scaffold tool.'
                                )}
                              </Typography>
                            </div>
                          }
                          PopperProps={{
                            style: {
                              zIndex: 2002,
                            },
                          }}
                          interactive
                          placement="bottom"
                          arrow
                          classes={{
                            arrow: classes.tooltipArrow,
                            tooltip: classes.tooltip,
                          }}
                        >
                          <span className={classes.tooltipIconSize}>
                            <HelpCircleIcon size={14} color={BaseTheme.palette.grey[700]} />
                          </span>
                        </Tooltip>
                      </div>
                      <Select
                        labelId="scaffold-length-select-label"
                        id="scaffold-length-select"
                        label={doNotTranslate(`Scaffold Total Length (max ${MAX_HEIGHT_AND_LENGTH_PVF}m)`)}
                        value={customData.form_data?.scaffold_properties?.length}
                        variant="outlined"
                        onChange={(e) => handleInputChange(e, 'length')}
                        fullWidth={true}
                        displayEmpty={true}
                        renderValue={(value) =>
                          value ? (
                            <div>{`${value} m`}</div>
                          ) : (
                            <div className={classes.selectorColor}>{'Select width'}</div>
                          )
                        }
                        className={classes.customFormControl}
                        IconComponent={ExpandMoreIcon}
                      >
                        {lengthAndHeightOptions?.map((item: number) => {
                          return (
                            <MenuItem key={item} value={item}>
                              {item} m
                            </MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                  </div>
                  <div className={classes.fieldsContainer}>
                    <div className={`${classes.gap6} ${classes.displayFlex}`}>
                      <Typography>
                        {doNotTranslate('Are there multiple properties that require scaffolding?')}
                      </Typography>
                      <Tooltip
                        title={doNotTranslate(
                          'If there are multiple buildings, e.g. a carport and a house, that require separate scaffolding, select "Yes"'
                        )}
                        PopperProps={{
                          style: {
                            zIndex: 2002,
                          },
                        }}
                        interactive
                        placement="bottom"
                        arrow
                        classes={{
                          arrow: classes.tooltipArrow,
                          tooltip: classes.tooltip,
                        }}
                      >
                        <span className={classes.tooltipIconSize}>
                          <HelpCircleIcon size={14} color={BaseTheme.palette.grey[700]} />
                        </span>
                      </Tooltip>
                    </div>
                    <RadioGroup
                      value={customData.form_data?.scaffold_properties?.multiple_properties}
                      onClick={(e) => handleInputChange(e, 'multiple_properties')}
                      className={classes.radioGroupStyle}
                    >
                      <FormControlLabel
                        label={'Yes'}
                        value="Yes"
                        control={
                          <Radio checked={customData.form_data?.scaffold_properties?.multiple_properties === 'Yes'} />
                        }
                      />
                      <FormControlLabel
                        label={'No'}
                        value="No"
                        control={
                          <Radio checked={customData.form_data?.scaffold_properties?.multiple_properties === 'No'} />
                        }
                      />
                    </RadioGroup>
                  </div>
                  <div className={classes.fieldsContainer}>
                    <Typography>{doNotTranslate('Is the scaffold on a pavement or road?')}</Typography>
                    <RadioGroup
                      value={customData.form_data?.scaffold_properties?.scaffold_on_pavement_or_road}
                      onClick={(e) => handleInputChange(e, 'scaffold_on_pavement_or_road')}
                      className={classes.radioGroupStyle}
                    >
                      <FormControlLabel
                        label={'Yes'}
                        value="Yes"
                        control={
                          <Radio
                            checked={customData.form_data?.scaffold_properties?.scaffold_on_pavement_or_road === 'Yes'}
                          />
                        }
                      />
                      <FormControlLabel
                        label={'No'}
                        value="No"
                        control={
                          <Radio
                            checked={customData.form_data?.scaffold_properties?.scaffold_on_pavement_or_road === 'No'}
                          />
                        }
                      />
                    </RadioGroup>
                  </div>
                  <div className={classes.fieldsContainer}>
                    <Typography>
                      {doNotTranslate('Does the scaffold site require access through the house/building?')}
                    </Typography>
                    <RadioGroup
                      value={customData.form_data?.scaffold_properties?.require_access_through_house_building}
                      onClick={(e) => handleInputChange(e, 'require_access_through_house_building')}
                      className={classes.radioGroupStyle}
                    >
                      <FormControlLabel
                        label={'Yes'}
                        value="Yes"
                        control={
                          <Radio
                            checked={
                              customData.form_data?.scaffold_properties?.require_access_through_house_building === 'Yes'
                            }
                          />
                        }
                      />
                      <FormControlLabel
                        label={'No'}
                        value="No"
                        control={
                          <Radio
                            checked={
                              customData.form_data?.scaffold_properties?.require_access_through_house_building === 'No'
                            }
                          />
                        }
                      />
                    </RadioGroup>
                  </div>
                  <div className={classes.fieldsContainer}>
                    <div className={`${classes.gap6} ${classes.displayFlex}`}>
                      <Typography>
                        {doNotTranslate('Are there any obstructions that exceed 7 metres in width?')}
                      </Typography>
                      <Tooltip
                        title={
                          <div className={classes.tooltipContent}>
                            <img
                              alt="scaffold"
                              src={window.PUBLIC_URL + '/images/scaffold_obstruction_gif.gif'}
                              className={classes.tooltipImageStyle}
                            />
                            <div>
                              {doNotTranslate(
                                'Obstructions may include conservatories, non load bearing garage roofs, large sheds, hot tubs etc.'
                              )}
                            </div>
                            <div
                              className={classes.gotoDesign}
                              onClick={() => history.push(`/projects/${projectId}/design`)}
                            >
                              {doNotTranslate('Measure on Design Page ->')}
                            </div>
                          </div>
                        }
                        PopperProps={{
                          style: {
                            zIndex: 2002,
                          },
                        }}
                        interactive
                        placement="bottom"
                        arrow
                        classes={{
                          arrow: classes.tooltipArrow,
                          tooltip: classes.tooltip,
                        }}
                      >
                        <span className={classes.tooltipIconSize}>
                          <HelpCircleIcon size={14} color={BaseTheme.palette.grey[700]} />
                        </span>
                      </Tooltip>
                    </div>
                    <RadioGroup
                      value={customData.form_data?.scaffold_properties?.obstructions_exceeding_7m}
                      onClick={(e) => handleInputChange(e, 'obstructions_exceeding_7m')}
                      className={classes.radioGroupStyle}
                    >
                      <FormControlLabel
                        label={'Yes'}
                        value="Yes"
                        control={
                          <Radio
                            checked={customData.form_data?.scaffold_properties?.obstructions_exceeding_7m === 'Yes'}
                          />
                        }
                      />
                      <FormControlLabel
                        label={'No'}
                        value="No"
                        control={
                          <Radio
                            checked={customData.form_data?.scaffold_properties?.obstructions_exceeding_7m === 'No'}
                          />
                        }
                      />
                    </RadioGroup>
                  </div>
                  <div className={classes.infoContainer}>
                    {(customData.scenario === Scenario.CREATE_QUOTE ||
                      customData.scenario === Scenario.RECALCULATE_COST) &&
                      (customData?.estimated_cost ? (
                        <>
                          <div className={classes.wrapper}>
                            <Typography className={classes.infoContainerTitle}>
                              {doNotTranslate('Scaffolding Cost')}
                            </Typography>
                            <Typography className={classes.infoContainerTitle}>
                              {formatCurrencyWithSymbol(customData.estimated_cost, '£', locale, 2)}
                            </Typography>
                          </div>
                          <div className={classes.divider} />
                          <div className={classes.internalDetails}>
                            <div className={classes.wrapper}>
                              <Typography className={classes.costText}>
                                {doNotTranslate('Building Width and Scaffold Length')}
                              </Typography>
                              <Typography className={classes.costText}>
                                {formatCurrencyWithSymbol(
                                  customData.estimated_cost -
                                    (customData && customData?.access_through_house_cost
                                      ? customData.access_through_house_cost
                                      : 0) -
                                    (customData && customData?.pavement_cost ? customData.pavement_cost : 0),
                                  '£',
                                  locale,
                                  2
                                )}
                              </Typography>
                            </div>
                            <div className={classes.wrapper}>
                              <Typography className={classes.costText}>
                                {doNotTranslate('Access through house')}
                              </Typography>
                              <Typography className={classes.costText}>
                                {formatCurrencyWithSymbol(customData?.access_through_house_cost ?? 0, '£', locale, 2)}
                              </Typography>
                            </div>
                            {customData?.pavement_cost && (
                              <div className={classes.internalInfoContainer}>
                                <div className={classes.wrapper}>
                                  <Typography className={classes.costText}>
                                    {doNotTranslate('Pavement Permit - Non London')}
                                  </Typography>
                                  <Typography className={classes.costText}>
                                    {formatCurrencyWithSymbol(customData?.pavement_cost ?? 0, '£', locale, 2)}
                                  </Typography>
                                </div>
                                <div className={classes.infoContainerSubTitle}>
                                  {doNotTranslate(
                                    'Because the project location is outside London, we’ve included an additional cost.'
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className={classes.internalInfoContainer}>
                            <Typography className={classes.infoContainerTitle}>
                              {doNotTranslate('Scaffolding Cost')}
                            </Typography>
                            <div className={classes.infoContainerSubTitle}>{renderDescription(costReviewFromTeam)}</div>
                          </div>
                        </>
                      ))}
                    {customData.scenario === Scenario.ESTIMATE_COST && (
                      <div className={classes.internalInfoContainer}>
                        <Typography className={classes.infoContainerTitle}>
                          {doNotTranslate('Scaffolding Cost')}
                        </Typography>
                        <div className={classes.infoContainerSubTitle}>
                          {doNotTranslate(
                            'Please click “Calculate Cost” to estimate the cost of scaffolding for this project.'
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div>
                    {Object.keys(errors).length > 0 && (
                      <div className={classes.errorText}>{doNotTranslate(errors['form_data'])}</div>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        }
        footer={
          <div className={classes.modalFooter}>
            <div className={classes.modalFooterButtons}>
              {customData.scenario === Scenario.CREATE_QUOTE ? (
                <Button onClick={handleSaveExit} variant={'outlined'} color={'secondary'}>
                  {'Save & Exit'}
                </Button>
              ) : (
                <Button onClick={() => setDiscardDialogOpen(true)} variant={'outlined'} color={'secondary'}>
                  {'Cancel'}
                </Button>
              )}
              {customData.scenario === Scenario.CREATE_QUOTE && (
                <Button onClick={() => handleSubmit()} color={'primary'} variant="contained" type="submit">
                  {'Recalculate Cost'}
                </Button>
              )}
              {customData.scenario === Scenario.CREATE_QUOTE ? (
                <Button onClick={(e) => handleQuoteCreation(e)} color={'primary'} variant="contained">
                  {customData.estimated_cost ? 'Order Scaffold' : 'Create Quote'}
                </Button>
              ) : (
                <Button onClick={() => handleSubmit()} color={'primary'} variant="contained" type="submit">
                  {customData.scenario === Scenario.RECALCULATE_COST ? 'Recalculate Cost' : 'Calculate Cost'}
                </Button>
              )}
            </div>
          </div>
        }
      />
      <DiscardDialog parentClose={onClose} isOpen={discardDialogOpen} setOpen={setDiscardDialogOpen} />
      <PremiumProductTermsDialog
        open={enableDialogOpen}
        onClose={closeEnableDialog}
        productTitle={'PVF'}
        isEnableAndAccept
        onAccept={onPvfEnable}
      />
    </>
  )
}

export default OrderScaffold
