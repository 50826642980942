import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { UploadMoreStipulationLink, UploadStipulationButton } from 'opensolar-ui';
import { useEffect, useState } from 'react';
var UploadStipulationAction = function (_a) {
    var stipulation = _a.stipulation, linkColor = _a.linkColor, showUploadDialog = _a.showUploadDialog;
    if (stipulation.stipulation_review_status === 'created') {
        return (_jsx(UploadStipulationButton, { description: stipulation.stipulation_description, onClick: function () { return showUploadDialog({ stipulation: stipulation, action: 'uploadStipulation' }); }, stipulationName: stipulation.stipulation_name }));
    }
    if (stipulation.stipulation_review_status === 'submitted') {
        return (_jsx(UploadMoreStipulationLink, { linkColor: linkColor, onClick: function () { return showUploadDialog({ stipulation: stipulation, action: 'uploadMoreStipulation' }); }, stipulationName: stipulation.stipulation_name }));
    }
    return null;
};
var StipulationUpload = function (_a) {
    var onAction = _a.onAction, proposalData = _a.proposalData, options = _a.options, elementData = _a.elementData, translate = _a.translate;
    var _b = useState(false), directToEmail = _b[0], setDirectToEmail = _b[1];
    var actionData = elementData.actionData;
    var availableStips = actionData['created'];
    var submittedStips = actionData['submitted'];
    var highlightColor = proposalData.myeStyles.highlightColor;
    useEffect(function () {
        if (availableStips && availableStips.length > 0) {
            availableStips.forEach(function (stip) {
                var _a, _b;
                var docs = stip.stipulation_acceptable_docs ? JSON.parse(stip.stipulation_acceptable_docs) : {};
                if (((_a = Object.keys(docs)) === null || _a === void 0 ? void 0 : _a.length) === 0 && !directToEmail)
                    setDirectToEmail(true);
                else if (((_b = Object.keys(docs)) === null || _b === void 0 ? void 0 : _b.length) > 0 && directToEmail)
                    setDirectToEmail(false);
            });
        }
    }, [availableStips, directToEmail]);
    var showUploadDialog = function (additionalActionData) {
        onAction({ elementData: elementData, proposalData: proposalData, additionalActionData: additionalActionData });
    };
    if (!actionData || Object.keys(actionData).length < 1)
        return null;
    if (directToEmail)
        return null;
    else
        return (_jsxs("div", { children: [availableStips && (_jsxs("div", { children: [_jsx("p", { children: "To progress with your financing please provide the following documentation" }), _jsx("div", { children: availableStips.map(function (stip) {
                                return (_jsx(UploadStipulationAction, { stipulation: stip, linkColor: highlightColor, showUploadDialog: showUploadDialog }, stip.name));
                            }) })] })), submittedStips && (_jsx("div", { children: _jsx("div", { children: submittedStips.map(function (stip) {
                            return (_jsx(UploadStipulationAction, { stipulation: stip, linkColor: highlightColor, showUploadDialog: showUploadDialog }, stip.name));
                        }) }) }))] }));
};
export default StipulationUpload;
