import { useMediaQuery } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import { viewModeSelectors } from 'ducks/viewMode'
import { BaseThemeType } from 'opensolar-ui'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

export const useToursAvailable = () => {
  const isTouchDevice = useMemo(() => window.Utils?.isTouchDevice(), [])
  const fujiTourEnabled = useSelector(viewModeSelectors.fujiTourEnabled)
  const widthCompatible = useMediaQuery((theme: BaseThemeType) => theme.breakpoints.up('sm'))
  const roleId = useSelector(authSelectors.getCurrentRole)?.id

  return widthCompatible && fujiTourEnabled && !isTouchDevice && roleId !== undefined
}
