import { RootState } from 'types/state'

export const tourSelectors = {
  tour: (state: RootState) => {
    return state.tour.tour
  },
  step: (state: RootState) => {
    return state.tour.step
  },
}

export type FujiTourType = 'main' | 'manage' | 'project_list' | 'payments'

export type ShowTourBanner = {
  [key in FujiTourType]?: {
    shouldShow: boolean
    userHidIt: boolean
  }
}

export interface TourStep {
  step: string
  config: any
  page: string
}
export interface TourConfig {
  name: FujiTourType
  hidden: string
  startStep: string
  finishStep: string
  startBanner: {
    header: string
    subText: string
    imgSrc: string
  }
  confirmSkipText: string
  finishBanner: {
    header: string
    subText: string
  }
  steps: TourStep[]
  handleNext: (nextStepIndex, nextStep, openDrawer, closeDrawer) => void
  handlePrev: (prevStepIndex, prevStep, openDrawer, closeDrawer) => void
  handleCancel: (currStep, stepIndex, closeDrawer) => void
}
