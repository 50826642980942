import { and, ControlProps, rankWith, uiTypeIs } from '@jsonforms/core'
import { withJsonFormsControlProps } from '@jsonforms/react'
import { InputLabel } from '@material-ui/core'
import { EditorField } from 'elements/wysiwyg/EditorComponentWrapper'
import { styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { TranslatableTextWrapper } from './translatableTextRenderer'

const tester = rankWith(100, and(uiTypeIs('HtmlEditor')))

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
})

const HeaderRow = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: 8,
  width: '100%',
  alignItems: 'center',
})

const Label = styled(InputLabel)({
  flexGrow: 1,
  position: 'unset',
})

const HtmlEditorRenderer = (props: ControlProps) => {
  const translate = useTranslate()
  if (props.uischema.options?.translatable) {
    const renderTextField = ({ label, value, onChangeCurrent, clearButton, localeSelector, locale }) => {
      const editor = (
        //@ts-ignore
        <EditorField value={value} translate={translate} handleChange={onChangeCurrent} />
      )

      return (
        // use locale as key to force re-mount of froala editor when locale changes
        <Container key={locale}>
          <HeaderRow>
            <Label>{label}</Label>
            {clearButton}
            {localeSelector}
          </HeaderRow>
          {editor}
        </Container>
      )
    }

    return (
      <TranslatableTextWrapper
        {...props}
        render={renderTextField}
        renderClear={false}
        renderSelect={false}
        style={{ alignItems: 'start' }}
      />
    )
  } else {
    const editor = (
      <EditorField
        //@ts-ignore
        value={value}
        translate={translate}
        handleChange={(value) => props.handleChange(props.path, value)}
      />
    )
    return (
      <Container>
        <HeaderRow>
          <Label>{props.label}</Label>
        </HeaderRow>
        {editor}
      </Container>
    )
  }
}

const renderer = withJsonFormsControlProps(HtmlEditorRenderer)

export default {
  tester,
  renderer,
}
