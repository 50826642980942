import { orgSelectors } from 'ducks/orgs'
import { FormApi } from 'final-form'
import { Alert } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { getBool } from 'storage/appStorage'
import { StudioSystemType } from 'types/global'
import { useIsProjectUsingCashFlow } from '../hooks'
import CashFlowConverter from './CashFlowConverter'

type PropTypes = {
  systems: StudioSystemType[]
  projectId: number
  form?: FormApi<Record<string, any>, Partial<Record<string, any>>>
  cashflowIsActive?: boolean
}

const IGNORE_STORAGE = false

const ConversionWatcher: React.FC<PropTypes> = (props) => {
  // cashflowIsActiveForRole will be populated for pros, not end-customers
  // cashflowIsActive is an optional prop that allows us to know if the org
  // has active cashflow when the user is an end-customer with more sparsely
  // populated redux state
  const cashflowIsActiveForRole = useSelector(orgSelectors.getCashFlowIsActive)
  const isUsingCashFlow = useIsProjectUsingCashFlow(undefined, undefined, undefined)
  const projectIsPendingSave = Boolean(props.form?.mutators?.getFormDirtyFields()?.length)

  const translate = useTranslate()

  const cashflowIsActive = useMemo(() => {
    return props.cashflowIsActive || cashflowIsActiveForRole
  }, [props.cashflowIsActive, cashflowIsActiveForRole])

  const hasCashPmt = useMemo(() => {
    return props.systems?.some((sys) => sys?.payment_options?.some((pmt) => pmt?.payment_type === 'cash'))
  }, [props.systems])

  const shouldAddCashFlow = useMemo(() => {
    if (!cashflowIsActive) return false
    if (!hasCashPmt) return false
    if (isUsingCashFlow) return false
    if (!IGNORE_STORAGE && getBool(`cashflowConversionAttempted-${props.projectId}`)) return false
    return true
  }, [cashflowIsActive, isUsingCashFlow, hasCashPmt])

  if (!shouldAddCashFlow) return null
  else if (projectIsPendingSave) {
    return (
      <Alert severity="info">
        {translate(
          'This project will not be automatically converted to CashFlow until the pending changes are either saved or discarded.'
        )}
      </Alert>
    )
  }
  return <CashFlowConverter projectId={props.projectId} />
}
export default ConversionWatcher
