import { DeleteOutlined } from '@material-ui/icons'
import { authSelectors } from 'ducks/auth'
import { Button, styled } from 'opensolar-ui'
import { updateProjectEvents } from 'projectSections/sections/manage3/workflow/util'
import { useNotify, useTranslate } from 'ra-core'
import React, { ReactNode, useState } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import { EventActions } from 'types/events'
import { StageActionEventType } from 'types/projects'
import DeleteConfirmModal from '../../../common/DeleteConfirmModal'
import EventFormDialog, { EventDialogProps } from './EventFormDialog'
import { EVENT_FORM_VARIATIONS } from './util'

const restClientInstance = restClient(window.API_ROOT + '/api')

const DeleteButtonStyled = styled(Button, { name: 'DeleteButtonStyled' })(({ theme }) => ({
  marginRight: 'auto !important',
  color: theme.palette.error.contrastText,
}))

const StyledMainWrapper = styled('span')(
  {
    height: '100%',
  },
  { name: 'ProjectEventWrapper', slot: 'ProjectEventWrapperMain' }
)

const DeleteButton = (props) => {
  const translate = useTranslate()
  return (
    <DeleteButtonStyled variant="text" color="error" startIcon={<DeleteOutlined />} onClick={props.onClick}>
      {translate('Delete %{object}', { object: props.itemLabel })}
    </DeleteButtonStyled>
  )
}
interface PropTypes {
  children: (props) => ReactNode
  minDisplay?: boolean
  id?: string
}

const ProjectEventWrapper: React.FC<PropTypes> = ({ children, minDisplay = false, id }) => {
  const form = useForm()
  const notify = useNotify()
  const translate = useTranslate()
  const project = useFormState().values
  const orgId = useSelector(authSelectors.getOrgId)
  const [formSaving, setSaving] = useState(false)
  const [editModalProps, setEditModalProps] = useState<EventDialogProps | undefined>(undefined)
  const handleSubmit = (event) => {
    if (event) {
      restClientInstance(`CUSTOM_${event.id ? 'PUT' : 'POST'}`, 'custom', {
        url: `orgs/${orgId}/events/${event.id ? `${event.id}/` : ''}`,
        data: event,
      })
        .then((response) => {
          if (response?.data) {
            updateProjectEvents(response, project, form)
            notify('Event data updated successfully', 'success')
          }
          setSaving(false)
          setEditModalProps(undefined)
        })
        .catch((err) => {
          setSaving(false)
          notify('Error performing action: ' + err, 'warning')
        })
    }
  }
  const toggleModal = (event) => {
    if (event) {
      const formConfig = EVENT_FORM_VARIATIONS[event.form_config]
      const itemLabel = formConfig?.label || 'Event'
      const actionText = translate(event.id ? 'Save' : `Add ${itemLabel}`)
      const headerTitle = translate(`${event.id ? 'Edit' : 'Add'} ${itemLabel}`)
      setEditModalProps({
        open: true,
        headerText: headerTitle,
        initialValues: {
          ...event,
          start: event.start ? new Date(event.start) : undefined,
          end: event.end ? new Date(event.end) : undefined,
          project: event.id ? undefined : project.url,
        },
        validate: formConfig.validate,
        handleSubmit: (values) => {
          setSaving(true)
          handleSubmit(
            event.id
              ? values
              : {
                  ...values,
                  start: values.start ? new Date(values.start) : null,
                  end: values.end ? new Date(values.end) : null,
                }
          )
        },
        submitBtnLabel: actionText,
        content: formConfig?.content || <></>,
        toolbarBtn:
          formConfig?.actions?.includes(EventActions.delete) && event.id ? (
            <DeleteButton
              itemLabel={itemLabel}
              onClick={() => {
                toggleDelete({ ...event, is_archived: true })
              }}
            />
          ) : (
            <></>
          ),
        onClose: () => {
          setEditModalProps(undefined)
        },
      })
    }
  }
  const [forDelete, setForDelete] = useState<StageActionEventType | boolean>(false)
  const toggleDelete = (event) => {
    setForDelete(event)
  }

  const Wrapper = id ? StyledMainWrapper : React.Fragment // Added this line to fix the error related to Manage tour
  return (
    <Wrapper {...(id ? { id } : {})}>
      {children({ toggleModal, toggleDelete })}

      {editModalProps && <EventFormDialog loading={formSaving} {...editModalProps} />}
      <DeleteConfirmModal
        open={!!forDelete}
        setOpen={setForDelete}
        title={
          forDelete && typeof forDelete !== 'boolean'
            ? EVENT_FORM_VARIATIONS[forDelete.form_config]?.label?.toLowerCase() || 'event'
            : 'event'
        }
        handleDelete={() => {
          handleSubmit(forDelete)
          setForDelete(false)
        }}
      />
    </Wrapper>
  )
}

export default ProjectEventWrapper
