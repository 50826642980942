export var FinanceCtaType;
(function (FinanceCtaType) {
    FinanceCtaType["SUBMIT_CREDIT_APPLICATION_IFRAME"] = "submit_credit_application_iframe";
    FinanceCtaType["FETCH_IFRAME_URL"] = "fetch_iframe_url";
    FinanceCtaType["FORM_REDIRECT"] = "form_redirect";
    FinanceCtaType["PROPOSAL_TOOLBAR_DIALOG"] = "proposal_toolbar_dialog";
    FinanceCtaType["SUBMIT_CREDIT_APPLICATION_HOSTED"] = "submit_credit_application_hosted";
    FinanceCtaType["ALERT_INFO"] = "ALERT_INFO";
    FinanceCtaType["ALERT_WARNING"] = "ALERT_WARNING";
    FinanceCtaType["ALERT_ERROR"] = "ALERT_ERROR";
    FinanceCtaType["VIEW_LENDER_DOCS"] = "view_lender_docs";
    FinanceCtaType["DISCLAIMER_BEFORE_REDIRECT"] = "disclaimer_before_redirect";
})(FinanceCtaType || (FinanceCtaType = {}));
