import { logAmplitudeEvent } from 'amplitude/amplitude'
import { markAsSold } from 'ducks/myEnergy'
import { ActionDataStatusCode } from 'myenergy/selectionComponent/loanApplicationButton/types'
import { LoanApplicationType, ProposalDataType } from 'opensolar-checkout'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getCommonCreditDecision } from 'util/misc'

type LoanApplicationEffectProps = {
  elementData: LoanApplicationType
  proposalData: ProposalDataType
}

const BRIGHTE_APPROVED_STATUSES = ['Approved', 'Conditionally approved']
const BRIGHTE_DECISIONS = ['Approved', 'Conditionally approved', 'Pending decision', 'Declined']

const useSpyBrighteApplicationStatusEffect = ({ elementData, proposalData }: LoanApplicationEffectProps) => {
  const actionData = elementData.actionData
  const startedWithoutDecision = useMemo(() => {
    return !actionData.status_title || actionData.status_title === 'created' || actionData.status_title === 'Submitted'
  }, [])
  const [decisionJustGiven, setDecisionJustGiven] = useState<string | undefined>(undefined)
  const isBrighte = actionData.payment_method === 'brighte_application'

  const dispatch = useDispatch()

  useEffect(() => {
    if (!isBrighte) {
      return
    }
    if (startedWithoutDecision && actionData.status_title && actionData.status_title !== 'created') {
      if (BRIGHTE_APPROVED_STATUSES.includes(actionData.status_title)) {
        dispatch(markAsSold(actionData.system_uuid, actionData.payment_option_id))
      }
      if (BRIGHTE_DECISIONS.includes(actionData.status_title) && decisionJustGiven !== actionData.status_title) {
        logAmplitudeEvent('integrated_finance_application_submitted', {
          integration: 'brighte',
          decision: getCommonCreditDecision(actionData.status_title),
          project_id: actionData.project_id,
          payment_option_id: actionData.payment_option_id,
        })
        setDecisionJustGiven(actionData.status_title)
      }
    }
  }, [isBrighte, actionData.status_title, startedWithoutDecision, decisionJustGiven])
}

const useSpyIntegratedFinanceApplicationStatusEffect = ({ elementData, proposalData }: LoanApplicationEffectProps) => {
  const actionData = elementData.actionData
  const initialStatus: ActionDataStatusCode = useMemo(() => actionData.status_code, [])
  const isIntegratedFinance = actionData.finance_integration !== undefined

  useEffect(() => {
    if (!isIntegratedFinance) {
      return
    }

    if (initialStatus === 'available' && actionData?.status_code !== 'available' && actionData?.status_title) {
      // TODO: consider to refactor it and find a more reliable time to log the event
      logAmplitudeEvent('integrated_finance_application_submitted', {
        integration: actionData?.finance_integration,
        decision: getCommonCreditDecision(actionData?.status_title),
        payment_option_id: proposalData.selectedPaymentOptionId, // Potential issue here, dependency never updates
        system_id: actionData?.system_id,
      })
    }
  }, [isIntegratedFinance, initialStatus, actionData.status_code, actionData.status_title])
}

const useSpySunlightApplicationStatusEffect = ({ elementData, proposalData }: LoanApplicationEffectProps) => {
  const actionData = elementData.actionData
  const initialStatus: ActionDataStatusCode = useMemo(() => actionData.status_code, [])
  const isSunlight =
    actionData.payment_method === 'sunlight_loan_application' || actionData.payment_method === 'sunlight_loan_prequal'
  useEffect(() => {
    if (!isSunlight) {
      return
    }

    if (initialStatus === 'available' && actionData.status_code !== 'available') {
      logAmplitudeEvent('integrated_finance_application_submitted', {
        integration: 'sunlight',
        decision: getCommonCreditDecision(actionData.status_title),
        is_prequal: actionData.payment_method === 'sunlight_loan_prequal' ? 'yes' : 'no',
        project_id: actionData.project_id,
      })
    }
  }, [isSunlight, initialStatus, actionData.status_code, actionData.payment_method])
}

const useSpyLoanApplicationStatusEffect = (props: LoanApplicationEffectProps) => {
  useSpyBrighteApplicationStatusEffect(props)
  useSpyIntegratedFinanceApplicationStatusEffect(props)
  useSpySunlightApplicationStatusEffect(props)
}

export default useSpyLoanApplicationStatusEffect
