import { useState } from 'react'
import type { ProjectType } from 'types/projects'
import HostedCreditApplicationDialog from './hostedForms/HostedCreditApplicationDialog'

export type HostedCreditApplicationDialogConfig = {
  orgId: number
  projectId: number
  systemUuid: string
  paymentOptionId: number
  integration: string
  project: ProjectType
  initialValues: object
}

const useHostedCreditApplicationDialog = () => {
  const [state, seState] = useState<
    { open: false; config: undefined } | { open: true; config: HostedCreditApplicationDialogConfig }
  >({
    open: false,
    config: undefined,
  })
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const openDialog = (config: HostedCreditApplicationDialogConfig) => {
    seState({
      open: true,
      config,
    })
  }

  const closeDialog = () => {
    if (state.open === false) {
      return
    }
    seState({
      open: false,
      config: undefined,
    })
  }

  const dialogNode = state.open ? (
    <HostedCreditApplicationDialog
      onClose={closeDialog}
      setIsLoading={setIsLoading}
      isLoading={isLoading}
      orgId={state.config.orgId}
      projectId={state.config.projectId}
      systemUuid={state.config.systemUuid}
      paymentOptionId={state.config.paymentOptionId}
      integration={state.config.integration}
      project={state.config.project}
      initialValues={state.config.initialValues}
    />
  ) : null
  return { isOpen: state.open, dialogNode, openDialog, closeDialog, type: 'HostedCreditApplicationDialog' as const }
}

export default useHostedCreditApplicationDialog
