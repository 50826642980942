import { Popper, Typography } from '@material-ui/core'
import { ArrowDropDownIcon } from 'opensolar-ui'
import {
  getSupplierConfig,
  getSupplierConfigByFilterKey,
  getSupplierEnumByFilterKey,
  HardwareSupplierPresetConfig
} from 'pages/ordering/configs'
import { HardwareSupplierFilterKeyType } from 'pages/ordering/type'
import { useEffect, useMemo, useState } from 'react'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { ComponentLikeType } from './hardwareSelectorV2/types'
import useFetchComponentsData from './hardwareSelectorV2/fetch/useFetchComponentsData'

const useStyles = makeOpenSolarStyles((theme) => ({
  container: {
    marginTop: 8,
  },
  imgSelector: {
    width: 40,
    height: 24,
  },
  wrapper: {
    padding: '14px',
    textAlign: 'left',
    backgroundColor: '#ffffff',
    borderRadius: '6px',
    cursor: 'pointer',
    boxShadow: '0px 4px 10px 0px rgba(0, 0, 0, 0.25)',
    width: 270,
  },
  popperContainer: {
    zIndex: 1500,
  },
  popperOptionLabel: {
    fontSize: 10,
    fontWeight: 600,
    lineHeight: '12px',
    color: '#757575',
    paddingLeft: 20,
  },
  supplierOptionContainer: {
    display: 'inline-flex',
    alignContent: 'center',
    alignItems: 'center',
  },
  supplierOptionImg: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  selector: {
    border: '1px solid #E7E7E7',
    borderRadius: 4,
    display: 'inline-flex',
    alignItems: 'center',
    padding: '4px',
  },
  supplierWrapper: {
    padding: '10px 20px',
  },
  supplierName: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '20px',
  },
  supplierImg: {
    width: 56,
    height: 28,
    marginRight: 10,
    border: '1px solid #E7E7E7',
    borderRadius: 4,
  },
}))

const SupplierOption = ({
  supplier,
  onSupplierSelected,
}: {
  supplier: HardwareSupplierPresetConfig | undefined
  onSupplierSelected: () => void
}) => {
  const classes = useStyles()

  return (
    <div className={classes.supplierWrapper} onClick={onSupplierSelected}>
      <div className={classes.supplierOptionContainer}>
        <div className={classes.supplierImg}>
          <img src={supplier?.logoUrl} className={classes.supplierOptionImg} />
        </div>
        <Typography className={classes.supplierName}>{supplier?.name}</Typography>
      </div>
    </div>
  )
}

const EnabledSupplierSelector = ({
  selectedSupplier,
  handleChangeSupplier,
  code,
  popperClosed,
}: {
  selectedSupplier: HardwareSupplierFilterKeyType | undefined
  handleChangeSupplier: (supplier: HardwareSupplierFilterKeyType) => void
  code: string
  popperClosed?: boolean
}) => {
  const classes = useStyles()
  const [supplierAnchorEl, setSupplierAnchorEl] = useState<HTMLElement | null>(null)
  const [componentsData, setComponentsData] = useState<ComponentLikeType[]>([])
  const { fetchComponentsData } = useFetchComponentsData()
  const selectedSupplierConfig = selectedSupplier
    ? getSupplierConfig(getSupplierEnumByFilterKey(selectedSupplier))
    : undefined

  useEffect(() => {
    fetchComponentsData({
      parameters: { codes: [code], limit: 1 },
      onReady: (data: ComponentLikeType[]) => {
        setComponentsData(data)
      },
    })
  }, [code])

  const sortedDistributors = useMemo(() => {
    if (componentsData.length > 0) {
      const orderingData = componentsData[0].ordering_v2
      const sortedData = orderingData.sort((a, b) =>
        getSupplierEnumByFilterKey(a.distributor) - getSupplierEnumByFilterKey(b.distributor)
      )

      const uniqueDistributors = sortedData.filter((value, index, self) =>
        self.findIndex(item => item.distributor === value.distributor) === index
      )

      return uniqueDistributors
    }
    return []
  }, [componentsData])

  const handlePopperClose = () => {
    setSupplierAnchorEl(null)
  }

  useEffect(() => {
    if (popperClosed) {
      handlePopperClose()
    }
  }, [popperClosed])

  useEffect(() => {
    document.addEventListener("scroll", handlePopperClose, true)
  }, [])

  useEffect(() => () => {
    document.removeEventListener("scroll", handlePopperClose, true)
  },[])

  return (
    <div className={classes.container}>
      <div
        className={classes.selector}
        onClick={(e) => setSupplierAnchorEl(!supplierAnchorEl ? e.currentTarget : null)}
      >
        <img className={classes.imgSelector} src={selectedSupplierConfig?.logoUrl} />
        <ArrowDropDownIcon />
      </div>
      <Popper
        open={Boolean(supplierAnchorEl)}
        anchorEl={supplierAnchorEl}
        className={classes.popperContainer}
        placement="left-start"
      >
        <div className={classes.wrapper}>
          <Typography className={classes.popperOptionLabel}>{`SUPPLIER OPTIONS`}</Typography>
          {sortedDistributors.map((order) => {
            const supplierConfig = getSupplierConfigByFilterKey(order.distributor)
            if (supplierConfig) {
              return (
                <SupplierOption
                  supplier={supplierConfig}
                  onSupplierSelected={() => {
                    handlePopperClose()
                    handleChangeSupplier(supplierConfig?.filterKey)
                  }}
                />
              )
            } else return null
          })}
        </div>
      </Popper>
    </div>
  )
}

export default EnabledSupplierSelector
