import { createContext } from 'react'

type MyEnergyContextState = {
  setWaitingForAction(waiting: boolean): void
}

const MyEnergyContext = createContext<MyEnergyContextState>({
  setWaitingForAction: () => {},
})

export default MyEnergyContext
