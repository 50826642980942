import { MenuItem } from '@material-ui/core'
import lodash from 'lodash'
import { Select, SelectProps, styled } from 'opensolar-ui'
import React, { ReactElement, useMemo } from 'react'
import { useFormState } from 'react-final-form'

const CustomInput = styled(Select, { name: 'CustomInput' })({
  height: 40,
  width: 'unset',
  margin: 'unset',
  fontSize: 14,
})

const MenuItemStyled = styled(MenuItem, { name: 'MenuItemStyled' })<{
  addDivider?: boolean
}>(({ addDivider, theme }) => ({
  fontSize: 14,
  padding: 16,
  '&.MuiListItem-root.Mui-selected': {
    backgroundColor: 'unset',
  },
  '&.MuiListItem-root.Mui-selected:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
  },
  borderTop: addDivider ? theme.mixins.borderStandard.border : undefined,
}))

interface ActionItem {
  label: string | ReactElement
  onClick: (e) => void
  className?: string
}

export interface CustomDropdownInputProps {
  onChange?: (item) => void
  optionValue?: string
  optionText?: string
  source?: string
  choices?: any[]
  actions?: ActionItem[]
  variant?: SelectProps['variant']
  className?: string
  emptyText?: string
  disabled?: boolean
}

//This component allows users to display a Select input field while adding custom buttons at the end of the list popover

const CustomDropdownInput: React.FC<CustomDropdownInputProps> = ({
  source,
  variant,
  className,
  emptyText,
  choices,
  actions,
  optionValue,
  optionText,
  onChange,
  disabled = false,
}) => {
  const formValues = useFormState().values
  const optionVal = useMemo(() => optionValue || 'id', [optionValue])
  const optionDisplay = useMemo(() => optionText || 'title', [optionText])
  const fieldValue = useMemo(() => (source ? lodash.get(formValues, source) : undefined), [source, formValues])
  const actionsOnly = useMemo(() => !!!choices?.length, [choices])
  return (
    <CustomInput
      value={fieldValue}
      className={className}
      variant={variant}
      onChange={(e: any) => {
        if (onChange && !actionsOnly) onChange(e.target.value)
      }}
      displayEmpty={!!emptyText}
      renderValue={(value) => {
        const getSelected = choices?.find((x) => x[optionVal] === value)
        if (!value) return emptyText
        return getSelected ? getSelected[optionDisplay] : ''
      }}
      disabled={disabled}
    >
      {!!emptyText && !actionsOnly && <MenuItemStyled value={undefined}>{emptyText}</MenuItemStyled>}
      {choices?.map((x, i) => (
        <MenuItemStyled key={'choice-' + i} value={x[optionVal]}>
          {x[optionDisplay]}
        </MenuItemStyled>
      ))}
      {actions?.map((x, i) => (
        <MenuItemStyled
          key={'action-' + i}
          value={undefined}
          onClick={x.onClick}
          addDivider={i === 0 && !actionsOnly}
          className={x.className}
        >
          {x.label}
        </MenuItemStyled>
      ))}
    </CustomInput>
  )
}

export default CustomDropdownInput
