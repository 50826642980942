var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CircularProgress, Dialog, DialogContent } from '@mui/material';
export var SunlightApplicationLoadingDialog = function (props) {
    return (_jsx(Dialog, __assign({ open: props.show, style: { textAlign: 'center' } }, { children: _jsxs(DialogContent, __assign({ style: { padding: '10px 40px' } }, { children: [_jsx("img", { alt: "Sunlight Financial", src: props.logoUrl, style: { maxHeight: 84, maxWidth: 220 } }), _jsx("div", __assign({ style: { margin: '20px 0px' } }, { children: _jsx(CircularProgress, { size: 60, 
                        //@ts-ignore
                        left: 10, top: 0, style: {
                            display: 'inline-block',
                            position: 'relative',
                        } }) })), props.message] })) })));
};
