var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { UploadIcon } from '../../icons';
import { styled } from '../../styles/styled';
var MainView = styled('div')(function (_a) {
    var theme = _a.theme;
    return {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '10px 5px',
        margin: '5px 0px',
        backgroundColor: '#ececec',
        cursor: 'pointer',
        '&:hover': {
            opacity: 0.8,
            boxShadow: undefined,
        },
        background: 'linear-gradient(180deg, #46BC41 0%, #34A02F 100%)',
        border: '1px solid #30862C',
        borderRadius: '3px',
        boxShadow: '1px 1px 2px rgba(0, 0, 0, 0.25)',
        color: '#FFFFFF',
        fontSize: 15,
    };
});
var Row = styled('div')(function (_a) {
    var theme = _a.theme;
    return {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        flex: 1,
        width: '100%',
    };
});
var NameView = styled('div')(function (_a) {
    var theme = _a.theme;
    return {
        flex: 3,
    };
});
var IconView = styled('div')(function (_a) {
    var theme = _a.theme;
    return {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    };
});
export var UploadStipulationButton = function (_a) {
    var description = _a.description, onClick = _a.onClick, stipulationName = _a.stipulationName;
    return (_jsxs(MainView, __assign({ onClick: onClick }, { children: [_jsxs(Row, { children: [_jsx(NameView, { children: stipulationName }), _jsx(IconView, { children: _jsx(UploadIcon, {}) })] }), description && (_jsx(Row, { children: _jsx("span", { children: description }) }))] })));
};
