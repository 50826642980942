import { Backdrop, Drawer } from '@material-ui/core'
import CustomField from 'elements/field/CustomField'
import { styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React, { useMemo } from 'react'
import appStorage from 'storage/appStorage'
import CustomToolbar from '../filtering/CustomToolbar'
import { ContentWrapper, FormStyled, Header } from '../styles'
import ColumnSettings from './ColumnSettings'
import { ListColumnBase, ProjectsListColumn } from './utils'
import ViewOptions from './ViewOptions'

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiPaper-root': {
    width: 470,
    [theme.breakpoints.down('sm')]: {
      width: '90%',
    },
  },
}))

export interface ViewSettings {
  isTableView: boolean
  setTableView: (value: boolean) => void
  columns: ProjectsListColumn[]
  setColData: (columns: ListColumnBase[]) => void
}

interface PropTypes extends ViewSettings {
  open: boolean
  onClose: () => void
}

const SettingsDrawer: React.FC<PropTypes> = ({ open, onClose, isTableView, setTableView, columns, setColData }) => {
  const translate = useTranslate()
  const kanbanCols = useMemo(() => columns?.filter((x) => x.hideOnKanban)?.map((x) => x.id), [columns])
  return (
    <>
      <Backdrop open={open} style={{ zIndex: 1100 }} onClick={onClose}></Backdrop>
      <StyledDrawer id={'Project-List-Settings-Section'} variant="persistent" anchor="right" open={open}>
        <FormStyled
          id={'Project-List-Settings-Section-Inner'}
          toolbar={<CustomToolbar onClose={onClose} />}
          initialValues={{
            isTableView: isTableView,
            columns: columns,
          }}
          save={(values) => {
            if (values.isTableView !== undefined) setTableView(values.isTableView)
            if (values.columns) {
              const updatedCols = [...values.columns.map((x) => ({ id: x.id, enabled: x.enabled }))]
              appStorage.setJSON('projects_columns', updatedCols)
              setColData(updatedCols)
            }
            onClose()
          }}
        >
          <ContentWrapper>
            <Header>{translate('View Settings')}</Header>
            <CustomField source="isTableView" name="isTableView" component={ViewOptions} />
            <CustomField source="columns" name="columns" component={ColumnSettings} kanbanCols={kanbanCols} />
          </ContentWrapper>
        </FormStyled>
      </StyledDrawer>
    </>
  )
}

export default SettingsDrawer
