import { logAmplitudeEvent } from 'amplitude/amplitude'
import { CashFlowConfiguration, ConvertToCashFlowSettings } from 'pages/cashFlow/types'
import { useEffect, useState } from 'react'
import restClient from 'restClient'

const restClientInstance = restClient(window.API_ROOT + '/api')

export const getEarliestConfigCreatedDate = (configs: CashFlowConfiguration[]): string | null => {
  let minDate: string | null | undefined = null
  configs?.forEach((config) => {
    config?.payment_milestone_configurations?.forEach((milestone) => {
      if (!minDate || (milestone.created_date && new Date(milestone.created_date) < new Date(minDate))) {
        minDate = milestone.created_date
      }
    })
  })
  return minDate
}

export const saveConvertToCashFlowSetting = (
  orgId: number | undefined,
  enabled: boolean,
  configId: number | undefined
) => {
  return new Promise((resolve, reject) => {
    restClientInstance('CUSTOM_POST', 'custom', {
      url: `orgs/${orgId}/payments/convert_to_cashflow/settings/`,
      data: {
        enabled,
        configuration_id: configId,
      },
    })
      .then((res) => {
        resolve(res)
        if (enabled) {
          logAmplitudeEvent('cashflow_legacy_project_conversion_enabled', { configId })
        } else {
          logAmplitudeEvent('cashflow_legacy_project_conversion_disabled', {})
        }
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const useGetConvertToCashFlowSetting = (orgId: number | undefined) => {
  const [settings, setSettings] = useState<ConvertToCashFlowSettings | null>(null)
  const [isFetching, setIsFetching] = useState<boolean>(true)

  const fetchSettings = () => {
    setIsFetching(true)
    restClientInstance('CUSTOM_GET', 'custom', {
      url: `orgs/${orgId}/payments/convert_to_cashflow/settings/`,
    })
      .then((res) => {
        setSettings(res.data)
      })
      .catch((err) => {
        setSettings(null)
      })
      .finally(() => {
        setIsFetching(false)
      })
  }

  useEffect(() => {
    fetchSettings()
  }, [orgId])

  return { settings, isFetching }
}
