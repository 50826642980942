import { styled } from 'opensolar-ui'

const DotStyles = {
  width: 10,
  height: 10,
  borderRadius: '50%',
  margin: 5,
}

const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexGrow: 1,
  paddingBottom: 8,
})
const DotCurrent = styled('div')(({ theme }) => ({
  ...DotStyles,
  background: theme.palette.primary.main,
}))
const DotOther = styled('div')(({ theme }) => ({
  ...DotStyles,
  background: theme.palette.grey[300],
}))

type Props = {
  activeIndex: number
  count: number
  onClick?: (index: number) => void
}

export const PaginationDots = ({ activeIndex, count, onClick }: Props) => {
  return (
    <Container>
      {Array(count)
        .fill(0)
        .map((_, i) => {
          if (i === activeIndex) {
            return <DotCurrent key={i} onClick={() => onClick?.(i)} />
          } else {
            return <DotOther key={i} onClick={() => onClick?.(i)} />
          }
        })}
    </Container>
  )
}
