import type { CTAConfigJson } from 'myenergy/selectionComponent/loanApplicationButton/generic/DisclaimerRedirectDialog'
import { useState } from 'react'
import DisclaimerRedirectDialog from './DisclaimerRedirectDialog'

export type DisclaimerRedirectDialogConfig = {
  orgId: number
  projectId: number
  systemUuid: string
  paymentOptionId: number
  ctaDialogConfigJson: CTAConfigJson
}

const useDisclaimerRedirectDialog = () => {
  const [state, seState] = useState<
    { open: false; config: undefined } | { open: true; config: DisclaimerRedirectDialogConfig }
  >({
    open: false,
    config: undefined,
  })

  const openDialog = (config: DisclaimerRedirectDialogConfig) => {
    seState({
      open: true,
      config,
    })
  }

  const closeDialog = () => {
    if (state.open === false) {
      return
    }
    seState({
      open: false,
      config: undefined,
    })
  }

  const dialogNode = state.open ? (
    <DisclaimerRedirectDialog
      open={true}
      onClose={closeDialog}
      project_id={state.config.projectId}
      paymentOptionId={state.config.paymentOptionId}
      systemUuid={state.config.systemUuid}
      orgId={state.config.orgId}
      isFullWidth={false}
      ctaDialogConfigJson={state.config.ctaDialogConfigJson}
    />
  ) : null
  return { isOpen: state.open, dialogNode, openDialog, closeDialog, type: 'DisclaimerRedirectDialog' as const }
}

export default useDisclaimerRedirectDialog
