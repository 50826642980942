import { Dialog, DialogContent, DialogTitle, Link, Theme, useMediaQuery } from '@material-ui/core'
import { getAvailableActions, markAsSold } from 'ducks/myEnergy'
import { orgSelectors } from 'ducks/orgs'
import { Button } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import IntegratedCheckoutWrapper from './IntegratedCheckoutWrapper'
import PaymentsPageHeader from './PaymentsPageHeader'

interface IntegratedCheckoutPaymentCTAProps {
  systemUuid: string
  projectId: number
  paymentOptionId: number
  orgId: number
  variation: 'link' | 'button'
  actionTitle: string
}

const useStyles = makeOpenSolarStyles((theme) => ({
  wrapper: {
    padding: '0px 10px',
  },
  linkWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '8px',
  },
  orRow: {
    height: '1px',
    width: '100%',
    borderBottom: `1px solid ${theme.greyLight2}`,
    display: 'flex',
    justifyContent: 'center',
    margin: '6px 0px 10px 0px',
  },
  or: {
    marginTop: '-8px',
    backgroundColor: 'white',
    padding: '0px 8px',
    height: '12px',
  },
  link: {
    textDecoration: 'underline',
    padding: '14px',
    cursor: 'pointer',
  },
}))

export type IntegratedCheckoutPaymentDialogConfig = {
  systemUuid: string
  projectId: number
  paymentOptionId: number
  orgId: number
}

type IntegratedCheckoutPaymentDialogProps = {
  open: boolean
  handleCloseDialog(): void
} & IntegratedCheckoutPaymentDialogConfig

const IntegratedCheckoutPaymentDialog: React.FC<IntegratedCheckoutPaymentDialogProps> = (props) => {
  const isMobile = useMediaQuery((theme: Theme) => theme?.breakpoints?.down('md'))
  const org = useSelector(orgSelectors.getOrg)

  return (
    <Dialog open={props.open} onClose={props.handleCloseDialog} maxWidth="xl" fullScreen={isMobile}>
      <DialogTitle>
        <PaymentsPageHeader
          logoURL={org?.logo_public_url || undefined}
          orgName={org?.name}
          hideShadow={true}
          closeDialog={props.handleCloseDialog}
        />
      </DialogTitle>
      <DialogContent>
        <IntegratedCheckoutWrapper
          orgId={props.orgId}
          systemUuid={props.systemUuid}
          projectId={props.projectId}
          paymentOptionId={props.paymentOptionId}
          showAcceptProposalAccordion={false}
        />
      </DialogContent>
    </Dialog>
  )
}

export const useIntegratedCheckoutPaymentDialog = () => {
  const [state, seState] = useState<
    { open: false; config: undefined } | { open: true; config: IntegratedCheckoutPaymentDialogConfig }
  >({
    open: false,
    config: undefined,
  })

  const dispatch = useDispatch()
  const openDialog = (config: IntegratedCheckoutPaymentDialogConfig) => {
    seState({
      open: true,
      config,
    })
  }

  const closeDialog = () => {
    if (state.open === false) {
      return
    }
    // this component only renders after the user has accepted the proposal. If they choose to not pay yet
    // we need to update proposal state to make sure the proposal reflects the fact that they have selected a system and payment option
    dispatch(markAsSold(state.config.systemUuid, state.config.paymentOptionId))
    dispatch(getAvailableActions(state.config.orgId, state.config.projectId))
    seState({
      open: false,
      config: undefined,
    })
  }

  const dialogNode = state.open ? (
    <IntegratedCheckoutPaymentDialog
      open={state.open}
      handleCloseDialog={closeDialog}
      systemUuid={state.config.systemUuid}
      projectId={state.config.projectId}
      paymentOptionId={state.config.paymentOptionId}
      orgId={state.config.orgId}
    />
  ) : null
  return { isOpen: state.open, dialogNode, openDialog, closeDialog, type: 'CashFlowDeposit' as const }
}

const IntegratedCheckoutPaymentCTA: React.FC<IntegratedCheckoutPaymentCTAProps> = (props) => {
  const { isOpen, dialogNode, openDialog, closeDialog } = useIntegratedCheckoutPaymentDialog()

  const classes = useStyles()
  const translate = useTranslate()
  const handleOpenDialog = () => {
    openDialog({
      systemUuid: props.systemUuid,
      projectId: props.projectId,
      paymentOptionId: props.paymentOptionId,
      orgId: props.orgId,
    })
  }

  return (
    <div className={classes.wrapper}>
      {props.variation === 'link' ? (
        <div className={classes.linkWrapper}>
          <div className={classes.orRow}>
            <span className={classes.or}>or</span>
          </div>
          <div>
            <Link onClick={handleOpenDialog}>
              <span className={classes.link}>{translate(props.actionTitle)}</span>
            </Link>
          </div>
        </div>
      ) : (
        <Button onClick={handleOpenDialog} fullWidth variant="contained" color="secondary" disabled={isOpen}>
          {translate(props.actionTitle)}
        </Button>
      )}
      {dialogNode}
    </div>
  )
}

export default IntegratedCheckoutPaymentCTA
