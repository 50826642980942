import { makeStyles } from '@material-ui/core/styles'
import { Add } from '@material-ui/icons'
import { styled } from 'opensolar-ui'
import HoverEditWrapper from 'projectSections/sections/manage3/common/HoverEditWrapper'
import StyledDateInput from 'projectSections/sections/manage3/common/StyledDateInput'
import { useTranslate } from 'ra-core'
import { useMemo } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { dateParserYYYYMMDD } from 'util/misc'

const useStyles = makeStyles({
  addBtn: {
    margin: '0 !important',
    marginTop: '8px !important',
  },
})

const ContentWrapper = styled('span', { name: 'ContentWrapper' })(({ theme }) => ({
  minHeight: 30,
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.grey[810],
  marginTop: 8,
}))

const TextWrapper = styled('div', { name: 'TextWrapper' })(({ theme }) => ({
  color: theme.palette.info.contrastText,
  '& .MuiSvgIcon-root': {
    height: 18,
  },
}))

const ProjectInstall = () => {
  const form = useForm()
  const classes = useStyles()
  const translate = useTranslate()
  const project = useFormState().values
  const installDate = useMemo(() => project.installation_date, [project.installation_date])

  return (
    <HoverEditWrapper
      modalProps={{
        initialValues: {
          ...project,
        },
        headerText: translate('Installation Date'),
        content: (
          <StyledDateInput
            label={'Installation Date'}
            inputVariant="outlined"
            source={'installation_date'}
            options={{ format: 'MM/DD/YYYY' }}
            parse={dateParserYYYYMMDD}
            onChange={(value) => {
              if (project.project_installed === 1 && value) {
                form.change('project_installed', 2)
              }
            }}
          />
        ),
      }}
      titleElement={<p>{translate('Installation Date')}</p>}
      emptyToggle={
        !!!installDate
          ? {
              label: <>{translate('Add Installation Date')}</>,
              startIcon: <Add />,
              className: classes.addBtn,
            }
          : undefined
      }
    >
      <ContentWrapper>{installDate && <TextWrapper>{installDate}</TextWrapper>}</ContentWrapper>
    </HoverEditWrapper>
  )
}
export default ProjectInstall
