import * as Sentry from '@sentry/react'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { hideFullScreenLoader, markCreditAppAsOpened, showCheckoutDialog } from 'ducks/myEnergy'
import { CheckoutActionCallback } from 'opensolar-checkout'
import MyEnergyContext from 'projectSections/sections/proposal/MyEnergyContext'
import { useCallback, useContext } from 'react'
import { useNotify, useTranslate } from 'react-admin'
import { useDispatch } from 'react-redux'
import restClient from 'restClient'
import { ProjectType } from 'types/projects'

var restClientInstance = restClient(window.API_ROOT + '/api')

const useRequestLoanDocs = () => {
  const notify = useNotify()
  const translate = useTranslate()
  const { setWaitingForAction } = useContext(MyEnergyContext)
  return useCallback(async ({ org_id, project_id }: { org_id: number; project_id: number }) => {
    // Request the email send (which will automatically record an incomplete transaction)
    setWaitingForAction(true)
    await restClientInstance('CUSTOM_GET', 'custom', {
      url: 'orgs/' + org_id + '/projects/' + project_id + '/send_loan_docs/',
    }).then(
      (response) => {
        console.log(response.data)
        notify(translate('Loan Docs Requested'))
        setWaitingForAction(false)
      },
      (reject) => {
        console.log(reject)
        notify(translate('Error Sending Loan Docs'), 'error')
        setWaitingForAction(false)
      }
    )
  }, [])
}

const useOpenApplicationDialog = () => {
  const dispatch = useDispatch()
  const notify = useNotify()
  const translate = useTranslate()
  return useCallback(
    async ({
      org_id,
      project_id,
      system_uuid,
      payment_option_id,
      project,
      isFullWidth,
    }: {
      org_id: number
      project_id: number
      system_uuid: string
      payment_option_id: number
      project: ProjectType
      isFullWidth?: boolean
    }) => {
      await restClientInstance('CUSTOM_GET', 'custom', {
        url: 'orgs/' + org_id + '/projects/' + project_id + '/loanpal_disclosures/',
      })
        .then(
          (response: any) => {
            if (response?.data?.disclosures?.data) {
              dispatch(
                showCheckoutDialog({
                  type: 'LoanpalApplicationDialog',
                  config: {
                    orgId: org_id,
                    systemUuid: system_uuid,
                    paymentOptionId: payment_option_id,
                    project: project,
                    disclosures: response.data.disclosures.data,
                    isFullWidth: isFullWidth,
                  },
                })
              )
            }
          },
          (reject: any) => {
            console.log(reject)
            notify(translate(reject.message), 'error')
            logAmplitudeEvent('integrated_finance_application_open_error', { integration: 'loanpal' })
          }
        )
        .catch((err: any) => {
          if (err?.body?.message) {
            notify(translate(err.body.message), 'error')
            logAmplitudeEvent('integrated_finance_application_open_error', { integration: 'loanpal' })
          }
        })
        .finally(() => {
          dispatch(hideFullScreenLoader())
          dispatch(markCreditAppAsOpened())
        })
    },
    []
  )
}

const useLoanpalApplicationCallback = (): CheckoutActionCallback => {
  const handRequestLoanDocs = useRequestLoanDocs()
  const openApplicationDialog = useOpenApplicationDialog()

  return useCallback(async ({ elementData, proposalData, additionalActionData }) => {
    if (elementData.type !== 'LoanApplication') {
      Sentry.captureException(new Error('Invalid element data for LoanApplication callback'))
      return
    }
    if (elementData.actionData.payment_method !== 'loanpal_application') {
      Sentry.captureException(new Error('Invalid element data for loanpal_application callback'))
      return
    }
    const { actionData } = elementData

    if (additionalActionData?.action === 'requestLoanDocs') {
      await handRequestLoanDocs({ org_id: actionData.org_id, project_id: actionData.project_id })
      return
    }

    await openApplicationDialog({
      org_id: actionData.org_id,
      project_id: actionData.project_id,
      system_uuid: actionData.system_uuid,
      payment_option_id: actionData.payment_option_id,
      project: proposalData.selectedProject as ProjectType,
      isFullWidth: additionalActionData?.showFullWidthDialog,
    })
  }, [])
}

export default useLoanpalApplicationCallback
