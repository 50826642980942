var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import TransactionActionButton from '../TransactionActionButton';
import AcceptProposalWrapper from './AcceptProposalWrapper';
export var AcceptProposalButton = function (_a) {
    var myeStyles = _a.myeStyles, isLoading = _a.isLoading, label = _a.label, disabled = _a.disabled, onAction = _a.onAction;
    return (_jsx(AcceptProposalWrapper, __assign({ textColor: myeStyles.textColor }, { children: _jsx(TransactionActionButton, { isLoading: isLoading, label: label, disabled: disabled, onClick: onAction }) })));
};
