import BasicInput from 'elements/input/BasicInput'
import { Button } from 'opensolar-ui'
import { FC, useCallback, useMemo } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { FAKE_PROPOSAL_V2_SETTINGS } from './ProposalV2.constants'

type PropsType = {
  pvprosell_template_id: string
  api_key_pvsell: string
  disabled: boolean
  record: any
  projectId: number
}

const inputStyle = {
  wrapper: {
    width: '100%',
  },
  textInput: {
    width: 256,
  },
}

// TODO: refine this
export const getProposalV2Settings = (templateSettingsJson: any) => {
  return {
    proposal_v2_config: {
      view_config: {
        url: `${window.PROPOSAL_V2_BASE_URL}/v1/`,
      },
      template_settings: templateSettingsJson,
    },
  }
}

export const getDefaultProposalSettings = () => {
  return JSON.stringify(FAKE_PROPOSAL_V2_SETTINGS)
}

const ProposalV2FromContent: FC<PropsType> = () => {
  const form = useForm()
  const formState = useFormState()
  const updateDefaultSetting = () => {
    form.change('settings', JSON.stringify(getProposalV2Settings(getDefaultProposalSettings())))
  }

  const proposalV2ConfigObject = useMemo(
    () => (formState.values.settings ? JSON.parse(formState.values.settings).proposal_v2_config : {}),
    [formState.values.settings]
  )

  const handleBlur = useCallback((value: string) => {
    form.change(
      'settings',
      JSON.stringify({
        proposal_v2_config: JSON.parse(value),
      })
    )
  }, [])

  console.log('proposalV2ConfigObject', proposalV2ConfigObject)
  console.log('template_settings JSON string', proposalV2ConfigObject?.template_settings)

  return (
    <div style={inputStyle.wrapper}>
      <h2>{'Temporary Proposal Editing Page: '}</h2>
      <Button variant="contained" onClick={updateDefaultSetting}>
        Get Default SM template setting
      </Button>
      <div>
        <BasicInput
          disabled={false}
          value={JSON.stringify(proposalV2ConfigObject)}
          placeholder={'placeholder'}
          onBlur={handleBlur}
          onChange={() => {}}
          onFocus={() => {}}
          fullWidth={true}
          InputProps={{
            multiline: true,
          }}
        />
      </div>
    </div>
  )
}

export default ProposalV2FromContent
