import { logAmplitudeEvent } from 'amplitude/amplitude'
import { LegacyDataProvider, Notify } from 'ra-core'
import appStorage from 'storage/appStorage'

abstract class ConnectService {
  abstract connect(): void
}

class CityPlumbingService implements ConnectService {
  private restClientInstance: LegacyDataProvider
  private notify: Notify

  constructor(restClientInstance: LegacyDataProvider, notify: Notify) {
    this.restClientInstance = restClientInstance
    this.notify = notify
  }

  placeOrderViaEmail = async (orderData: any): Promise<any> => {
    const response = await this.restClientInstance('CUSTOM_POST', 'custom', {
      url: `orgs/${appStorage.getOrgId()}/_orders/city_plumbing/place_order_via_email/`,
      data: orderData,
    }).catch(() => {
      logAmplitudeEvent('hardware_service_failed', {
        distributor: 'city_plumbing',
        source: 'place_order',
      })
    })

    return response?.data?.order_id
  }

  async connect() {
    // empty implementation override on the useDistributorConnect
  }
}

export default CityPlumbingService
