var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { CheckOutlineIcon, ReapplicationConfirm, TransactionActionButton } from 'opensolar-ui';
import { useState } from 'react';
var SunlightApplicationAction = function (_a) {
    var elementData = _a.elementData, translate = _a.translate, proposalData = _a.proposalData, config = _a.config, onAction = _a.onAction;
    var _b = useState(false), isLoading = _b[0], setIsLoading = _b[1];
    var actionData = elementData.actionData;
    var handleOnConfirm = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsLoading(true);
                    return [4 /*yield*/, onAction({ elementData: elementData, proposalData: proposalData })];
                case 1:
                    _a.sent();
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var openDialog = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsLoading(true);
                    return [4 /*yield*/, onAction({ elementData: elementData, proposalData: proposalData })];
                case 1:
                    _a.sent();
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleRequestLoanDocs = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setIsLoading(true);
                    return [4 /*yield*/, onAction({
                            elementData: elementData,
                            proposalData: proposalData,
                            additionalActionData: {
                                action: 'requestLoanDocs',
                            },
                        })];
                case 1:
                    _a.sent();
                    setIsLoading(false);
                    return [2 /*return*/];
            }
        });
    }); };
    if (actionData.payment_method === 'sunlight_loan_application') {
        var buttonLabelText = actionData.status_title === 'InitiatedButIncomplete'
            ? translate('Finish Loan Application')
            : translate('Apply Now');
        var extra_actions = [];
        if (actionData.status_code === 'available') {
            extra_actions.push(_jsx("p", __assign({ className: "small" }, { children: 'Clicking "' +
                    buttonLabelText +
                    '" or "Get Pre-Qualified Instantly"' +
                    " will take you to Sunlight Financial's credit application. OpenSolar does not view or store any of the data you enter in this application." }), extra_actions.length));
        }
        if (['ApprovedPendingDocs', 'Pending Loan Docs'].includes(actionData.status_title)) {
            extra_actions.push(_jsxs("div", __assign({ style: { display: 'flex', flexDirection: 'column' } }, { children: [_jsxs("div", __assign({ style: { display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' } }, { children: [_jsx(CheckOutlineIcon, { color: "rgba(55, 169, 46, 0.9)" }), _jsx("h2", { children: "Approved" })] })), _jsx("div", __assign({ style: { marginBottom: '10px' } }, { children: "You have been approved for financing!" }))] })));
            extra_actions.push(_jsx(TransactionActionButton, { isLoading: false, label: _jsxs("div", { children: [_jsx("div", { children: "Request Loan Docs" }), _jsx("div", __assign({ style: { fontSize: '10px' } }, { children: "(can take up to 2 minutes to send)" }))] }), disabled: false, onClick: handleRequestLoanDocs }));
        }
        else if (actionData.status_title === 'Credit Declined') {
            extra_actions.push(_jsx("div", __assign({ style: { textAlign: 'center', color: proposalData.myeStyles.textColor, margin: '15px 0px 15px 0px' } }, { children: "Your previous application was not approved for financing" }), extra_actions.length));
        }
        else if (actionData.status_description) {
            // Other statuses where we simply print the status_description
            // Including: OtherOrUnknown
            extra_actions.push(_jsx("p", { children: actionData.status_description }, extra_actions.length));
        }
        if (actionData.status_code === 'complete' && extra_actions)
            return _jsx(_Fragment, { children: extra_actions });
        else
            return (_jsxs(_Fragment, { children: [actionData.status_code === 'available' && (_jsxs("div", { children: [_jsx(TransactionActionButton, { isLoading: isLoading, label: _jsxs("div", { children: [buttonLabelText, _jsx("div", __assign({ style: { fontSize: 11 } }, { children: "(Takes less than 5 minutes)" }))] }), disabled: false, onClick: openDialog }), extra_actions] })), actionData.status_title === 'Credit Declined' &&
                        actionData.payment_method === 'sunlight_loan_application' && (_jsx(ReapplicationConfirm, { highlightColor: proposalData.myeStyles.highlightColor, onConfirm: handleOnConfirm, loading: isLoading, extraMessage: 'Your previous application was not approved for financing.' })), ['disabled', 'complete'].includes(actionData.status_code) &&
                        actionData.status_title !== 'Credit Declined' &&
                        (extra_actions === null || extra_actions === void 0 ? void 0 : extra_actions.length) > 0 && _jsx("div", { children: extra_actions })] }));
    }
    return _jsx(_Fragment, {});
};
export default SunlightApplicationAction;
