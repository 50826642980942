import { ar12, monthNames } from 'util/misc'
import { AdjustmentRate, FixedRate, PeriodTier, ScheduledRate, TOUPeriod } from './tariffInputs/pricingTable/types'

export const isReadOnly = (record, org_id: number): Boolean => {
  if (record && record.org && org_id && record.org && record.org.indexOf('/orgs/' + org_id + '/') !== -1) {
    return false
  } else {
    return true
  }
}

export const SECTOR_UNSPECIFIED = 0
export const SECTOR_RESIDENTIAL = 1
export const SECTOR_NON_RESIDENTIAL = 2

export const UTILITY_TARIFF_SECTOR_CHOICES = [
  { id: SECTOR_RESIDENTIAL, name: 'Residential' },
  { id: SECTOR_NON_RESIDENTIAL, name: 'Non-Residential' },
  { id: SECTOR_UNSPECIFIED, name: 'Unspecified' },
]

export const UTILITY_TARIFF_DISTRIBUTED_GENERATION_RULES_CHOICES = [
  {
    id: 'Gross Metering with Feed-in-tariff',
    name: 'Net Billing',
    countryDefaults: [
      'FR',
      'PT',
      'BE',
      'ES',
      'GB',
      'IE',
      'NL',
      'DE',
      'IT',
      'SE',
      'LU',
      'LA',
      'PH',
      'SG',
      'TH',
      'TW',
      'VN',
      'ZA',
      'AU',
      'NZ',
    ],
  },
  { id: 'virtual_storage', name: 'Virtual Storage' },
  { id: 'net_billing_with_credit_carryover', name: 'Net Billing with Credit Carryover' },
  { id: 'Gross Metering', name: 'Buy All, Sell All' },
  {
    id: 'Net Metering',
    name: 'Net Energy Metering (NEM)',
    areaFilter: {
      country_iso2: ['US', 'BD', 'IN', 'ID', 'KR', 'LK', 'MM', 'MY', 'PH', 'TH', 'ZA', 'CA', 'KE', 'TN', 'NA', 'BR'],
    },
    countryDefaults: ['BD', 'IN', 'ID', 'KR', 'LK', 'MM', 'MY', 'US', 'CA', 'SA'],
  },
  {
    id: 'heco_customer_grid_supply',
    name: 'HECO Customer Grid Supply',
    areaFilter: { country_iso2: ['US'] },
  },
  {
    id: 'netherland_nem_phase_out_2031',
    name: 'Netherland Net Metering Phase Out in 2031',
    areaFilter: { country_iso2: ['NL'] },
  },
]

export const UTILITY_TARIFF_BILL_FREQUENCY_CHOICES = [
  { id: 'monthly', name: 'Monthly' },
  { id: 'every_second_month', name: 'Bi-monthly (Every Second Month)' },
  { id: 'quarterly', name: 'Quarterly' },
  { id: 'annually', name: 'Annual' },
]

export const CREDIT_ROLLOVER_TYPES_CHOICES = [
  { id: 'dollar_rollover', name: '$ Credit rollover' },
  { id: 'kwh_rollover', name: 'kWh rollover' },
  { id: 'kwh_rollover_tou_window', name: 'kWh rollover restricted to TOU window' },
]

export const ExtraFieldByDistributionRule = {
  'Gross Metering with Feed-in-tariff': ['minimum_annual_export_credit'],
  net_billing_with_credit_carryover: [
    'nem_true_up_date',
    'net_surplus_compensation',
    'non_bypassable_charges',
    'minimum_annual_export_credit',
    'apply_nem3_export_rates',
    'apply_nem_modified_with_defined_export_rates',
  ],
  virtual_storage: ['annual_rollover_enabled', 'rollover_expiration_years'],
  'Net Metering': [
    'net_surplus_compensation',
    'non_bypassable_charges',
    'nem_true_up_date',
    'credit_rollover_type',
    'nem_credit_percentage_full_rate',
    'nem_bill_settled_annually',
    'nem_duration_in_years',
    'export_rate_when_nem_ends',
  ],
  'Gross Metering': ['minimum_annual_export_credit'],
  netherland_nem_phase_out_2031: [],
  heco_customer_grid_supply: ['nem_true_up_date', 'minimum_annual_export_credit'],
}

export const quoteAcceptanceContentItems = (quote_acceptance_content) => {
  if (quote_acceptance_content && quote_acceptance_content.length > 0) {
    if (quote_acceptance_content.indexOf('\n') !== -1) {
      return quote_acceptance_content.split('\n')
    } else if (quote_acceptance_content.indexOf('\r') !== -1) {
      return quote_acceptance_content.split('\r')
    } else {
      return [quote_acceptance_content]
    }
  } else {
    return []
  }
}

export const NEM_TRUE_UP_DATE_CHOICES = ar12
  .map((i) => ({ id: `annually_in_month_${i}`, name: `Annually in ${monthNames[i]}`, isNemTrueUp: true }))
  .concat({ id: 'monthly', name: 'Monthly', isNemTrueUp: false })
  .concat({ id: 'none', name: 'None (Credits Carryover Indefinitely)', isNemTrueUp: false })

export const isCalifornianNEM3Utility = (utilityName) => {
  const utilityWithNem3 = ['Pacific Gas & Electric Co.', 'Southern California Edison Co', 'San Diego Gas & Electric Co']
  return utilityWithNem3.includes(utilityName)
}

export const UNIT_OPTIONS = {
  export_credits: [{ id: '$/kWh', name: 'kWh' }],
  energy_charges: [
    { id: '$/kWh', name: 'kWh' },
    { id: '$/kWh/kW', name: 'kWh/kW' },
    { id: '$/kWh/kVA', name: 'kWh/kVA' },
  ],
  shipping_charges: [
    { id: '$/kWh', name: 'kWh' },
    { id: '$/kWh/kW', name: 'kWh/kW' },
    { id: '$/kWh/kVA', name: 'kWh/kVA' },
  ],
  controlled_load_charges: [
    { id: '$/kWh', name: 'kWh' },
    { id: '$/kWh/kW', name: 'kWh/kW' },
    { id: '$/kWh/kVA', name: 'kWh/kVA' },
  ],
  fixed_charges: [
    { id: '$/day', name: 'day' },
    { id: '$/month', name: 'month' },
    { id: '$/bimonthly', name: 'bimonthly' },
    { id: '$/quarter', name: 'quarter' },
    { id: '$/year', name: 'year' },
  ],
  demand_charges: [
    { id: '$/kW', name: 'kW' },
    { id: '$/kW/day', name: 'kW/day' },
    { id: '$/kVA', name: 'kVA' },
    { id: '$/kVA/day', name: 'kVA/day' },
    { id: '$/kVAR', name: 'kVAR' },
    { id: '$/kVAR/day', name: 'kVAR/day' },
  ],
  pv_capacity_charges: [
    { id: '$/day', name: 'day' },
    { id: '$/month', name: 'month' },
    { id: '$/bimonthly', name: 'bimonthly' },
    { id: '$/quarter', name: 'quarter' },
    { id: '$/year', name: 'year' },
    { id: '$/day/kW', name: 'day/kW' },
    { id: '$/month/kW', name: 'month/kW' },
    { id: '$/bimonthly/kW', name: 'bimonthly/kW' },
    { id: '$/quarter/kW', name: 'quarter/kW' },
    { id: '$/year/kW', name: 'year/kW' },
  ],
}

export const SCHEDULE_OPTIONS = [
  'energy_charges',
  'demand_charges',
  'export_credits',
  'controlled_load_charges',
  'shipping_charges',
]
export const SINGLE_TIER_OPTIONS = ['fixed_charges', 'pv_capacity_charges']
export const ADJUSTMENT_OPTIONS = ['percentage_adjustments_charges', 'percentage_adjustments_discounts']
export const ADJUSTMENT_TO_OPTIONS = [
  { id: 'energy_charges', name: 'Energy Charges' },
  { id: 'shipping_charges', name: 'Shipping Charges' },
  { id: 'demand_charges', name: 'Demand Charges' },
  { id: 'fixed_charges', name: 'Fixed Charges' },
  { id: 'all_charges', name: 'All Charges' },
]
export const DEFAULT_UNITS = {
  fixed_charges: '$/day',
  pv_capacity_charges: '$/month',
  demand_charges: '$/kW',
  energy_charges: '$/kWh',
  shipping_charges: '$/kWh',
  export_credits: '$/kWh',
  controlled_load_charges: '$/kWh',
}

export const defaultTier: PeriodTier = { value: 0, units: undefined, limit: 0 }
export const defaultPeriod = (tiered: boolean, sourceName: string): TOUPeriod => {
  const periodTier = {
    ...defaultTier,
    units: DEFAULT_UNITS[sourceName],
  }
  return {
    name: '',
    blocks: tiered ? new Array(2).fill(periodTier) : [periodTier],
    schedule: null,
  }
}
export const defaultScheduledRate = (defaultPeriod: TOUPeriod, multiplePeriod: boolean): ScheduledRate => {
  return {
    criteria_name: undefined,
    criteria_option: undefined,
    tou_periods: new Array(multiplePeriod ? 2 : 1).fill(defaultPeriod),
  }
}
export const defaultAdjustmentRate: AdjustmentRate = {
  criteria_name: undefined,
  criteria_option: undefined,
  name: '',
  adjustment_to: undefined,
  value: undefined,
}
export const defaultFixedRate = (defaultUnits: string): FixedRate => {
  return {
    criteria_name: undefined,
    criteria_option: undefined,
    name: undefined,
    units: defaultUnits,
    value: 0,
  }
}

export const DEFAULT_TARIFF_DATA = {
  version: '1.0',
  name: null,
  code: null,
  aliases: null,
  bill_frequency: 'monthly',
  description: '',
  applicability: {
    sector: SECTOR_RESIDENTIAL,
    popularity: 10,
  },
  solar_compensation_settings: {
    distributed_generation_rules: undefined,
  },
  tax_settings: 'inclusive_tax',
  tax_percentage: 0,
  has_minimum_charge: false,
  has_minimum_export_credit: false,
  minimum_charge: null,
  criteria: null,
  price_tiering_period: 'per_billing_period',
  energy_charges: [],
  shipping_charges: [],
  demand_charges: [],
  fixed_charges: [],
  export_credits: [],
  pv_capacity_charges: [],
  controlled_load_charges: [],
  percentage_adjustments_charges: [],
  percentage_adjustments_discounts: [],
}
