import { Divider, LinearProgress } from '@material-ui/core'
import { StyledDialog, StyledDialogActions, StyledDialogContent, StyledDialogTitle } from 'layout/StyledDialog'
import { Button } from 'opensolar-ui'
import useGetOrderDataByTransfer from 'projectSections/sections/manage3/hardware/hooks/useGetOrderDataByTransfer'
import { useMemo } from 'react'
import { Form } from 'react-final-form'
import InventoryTransferFormCore from '../InventoryTransferFormCore'
import type { Transfer, TransferFormType } from '../type'
import AcceptHardwareItems from './AcceptHardwareItems'
import OrderDistributor from './OrderDistributor'

const ViewPurchaseOrderDialog = ({
  isOpen,
  onClose,
  transferType,
  initialValues,
  allowReview,
}: {
  isOpen: boolean
  onClose(): void
  transferType: TransferFormType
  initialValues: Transfer
  allowReview?: boolean
}) => {
  const handleSubmit = async (value, form) => {}
  const isAutoOrder = !!initialValues?.order_id
  const { loadedOrderData, loadingOrder } = useGetOrderDataByTransfer({
    transferId: initialValues.id,
    allowReview: allowReview,
  })
  const orderData = useMemo(() => loadedOrderData, [loadedOrderData])

  if (loadingOrder) {
    return <LinearProgress variant="indeterminate" />
  }

  return (
    <Form
      initialValues={allowReview ? orderData : initialValues}
      onSubmit={handleSubmit}
      render={({ handleSubmit, form }) => (
        <StyledDialog maxWidth="lg" open={isOpen} onClose={onClose}>
          <StyledDialogTitle>View purchase order</StyledDialogTitle>
          <Divider light />
          <StyledDialogContent style={{ padding: 20 }}>
            <OrderDistributor />
            <InventoryTransferFormCore transferType={transferType} intention={'view_po'} />
            <AcceptHardwareItems
              newItemSource={'line_items_new'}
              selectedItemSource={'selected_line_items'}
              recordedItemSource={'line_items'}
              disabled={true}
              transferType={transferType}
              isAutoOrder={isAutoOrder}
              intention="view_po"
            />
          </StyledDialogContent>
          <StyledDialogActions>
            <Button variant="contained" color="default" onClick={onClose}>
              Close
            </Button>
          </StyledDialogActions>
        </StyledDialog>
      )}
    />
  )
}

export default ViewPurchaseOrderDialog
