import { Typography } from '@material-ui/core'
import { List, ListItem } from '@mui/material'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors } from 'ducks/auth'
import { clearLineItems } from 'ducks/orderComponents'
import { Button, ConfirmCircleIcon, styled } from 'opensolar-ui'
import { memo, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getSupplierConfigByFilterKey } from '../configs'
import { HardwareSupplierFilterKeyType } from '../type'

const FullWidthWrapper = styled('div')({
  width: '100%',
  padding: 20,
  boxSizing: 'border-box',
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
})

const Title = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '32px',
})

const Content = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  maxWidth: '64%',
})

const ButtonWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
})

const RedirectButton = styled(Button)({ marginTop: '50px' })

const Text = styled(Typography)<{ isMargin: boolean; isBold: boolean }>(({ theme, isMargin, isBold }) => ({
  marginTop: isMargin ? '35px' : '0',
  fontWeight: isBold ? theme.typography.fontWeightMedium : 'none',
  lineHeight: '28.8px',
  textAlign: 'left',
}))

const PostCheckoutPage = ({
  distributor,
  orderId,
  quoteOrOrderType,
}: {
  distributor: HardwareSupplierFilterKeyType
  orderId?: string | number
  quoteOrOrderType?: 'order' | 'quote'
}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const user = useSelector(authSelectors.getCurrentUser)
  const orderText = quoteOrOrderType === 'order' ? 'order' : 'quote'
  const distributorName = useMemo(() => {
    return getSupplierConfigByFilterKey(distributor)?.name
  }, [distributor])

  useEffect(() => {
    logAmplitudeEvent('hardware_order_placed_success', {
      distributor: distributor,
      type: quoteOrOrderType || '',
    })
    return () => {
      // clear cart when pros leaving the postcheckout page
      dispatch(clearLineItems())
    }
  }, [])

  const onClickCTA = () => {
    history.push('/manage_orders/incoming_transfer')
  }

  return (
    <FullWidthWrapper>
      <Title>
        <ConfirmCircleIcon width={120} height={120} color="rgb(44,157,73)" />
        <Text isMargin={true} isBold={true} variant={'h3'}>
          {`We've received your ${orderText}!`}
        </Text>
      </Title>
      <Content>
        {orderId && (
          <Text isMargin={true} isBold={true} variant={'h6'}>
            {`Here is your order ID: ${orderId}`}
          </Text>
        )}
        <Text isMargin={true} isBold={false} variant={'subtitle1'}>
          {`We have sent your order to ${distributorName} to process the order. A copy of your order has been sent to ${user?.email}.`}
        </Text>
        <Text isMargin={true} isBold={true} variant={'subtitle1'}>
          {`What’s next?`}
          <List sx={{ listStyle: 'decimal', pl: 4, paddingTop: 0 }}>
            <ListItem sx={{ display: 'list-item', padding: 0 }}>
              <Text isMargin={false} isBold={false} variant={'subtitle1'}>
                <Text isMargin={false} isBold={true} display={'inline'}>
                  Processing:
                </Text>
                {distributorName} reviews your order.
              </Text>
            </ListItem>
            <ListItem sx={{ display: 'list-item', padding: 0 }}>
              <Text isMargin={false} isBold={false} variant={'subtitle1'}>
                <Text isMargin={false} isBold={true} display={'inline'}>
                  Billing:
                </Text>
                {distributorName} will contact you to settle your payment order:
              </Text>
            </ListItem>
            <ListItem sx={{ display: 'list-item', padding: 0 }}>
              <Text isMargin={false} isBold={false} variant={'subtitle1'}>
                <Text isMargin={false} isBold={true} display={'inline'}>
                  Delivery:
                </Text>
                Your item will be shipped for delivery!
              </Text>
            </ListItem>
          </List>
        </Text>
        <Text isMargin={true} isBold={false} variant={'subtitle1'}>
          {`All updates will be sent to you by ${distributorName} via email or text.`}
        </Text>
        <Text isMargin={true} isBold={false} variant={'subtitle1'}>
          {`In the meantime, you can navigate to your purchase order on OpenSolar.`}
        </Text>
        <ButtonWrapper>
          <RedirectButton color="primary" onClick={onClickCTA} fullWidth={false} variant={'contained'}>
            Review Incoming PO
          </RedirectButton>
        </ButtonWrapper>
      </Content>
    </FullWidthWrapper>
  )
}

export default memo(PostCheckoutPage)
