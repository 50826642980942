import * as Sentry from '@sentry/react'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { hideFullScreenLoader, markCreditAppAsOpened, showCheckoutDialog } from 'ducks/myEnergy'
import { CheckoutActionCallback } from 'opensolar-checkout'
import { useCallback } from 'react'
import { useNotify, useTranslate } from 'react-admin'
import { useDispatch } from 'react-redux'
import restClient from 'restClient'

var restClientInstance = restClient(window.API_ROOT + '/api')

const useOpenApplicationDialog = () => {
  const dispatch = useDispatch()
  const notify = useNotify()
  const translate = useTranslate()
  return useCallback(
    async ({
      org_id,
      project_id,
      system_uuid,
      payment_option_id,
    }: {
      org_id: number
      project_id: number
      system_uuid: string
      payment_option_id: number
    }) => {
      await restClientInstance('CUSTOM_GET', 'custom', {
        url:
          'orgs/' +
          org_id +
          '/projects/' +
          project_id +
          '/systems/' +
          system_uuid +
          '/' +
          payment_option_id +
          '/brighte/application_url/',
      })
        .then(
          (response: any) => {
            if (response?.data?.success) {
              dispatch(
                showCheckoutDialog({
                  type: 'BrighteApplicationDialog',
                  config: {
                    url: response.data.url,
                  },
                })
              )
              logAmplitudeEvent('integrated_finance_application_opened', { integration: 'brighte' })
            }
          },
          (reject: any) => {
            let message: string = 'Something went wrong and we are unable to create this Brighte application.'
            if (reject.message) {
              message = reject.message
            }
            notify(message, 'warning')

            logAmplitudeEvent('integrated_finance_application_open_error', { integration: 'brighte' })
          }
        )
        .catch((err: any) => {
          console.log('err', err.body)
          if (err?.body?.message) {
            notify(err.body.message, 'warning')
            logAmplitudeEvent('integrated_finance_application_open_error', { integration: 'brighte' })
          }
        })
        .finally(() => {
          dispatch(hideFullScreenLoader())
          dispatch(markCreditAppAsOpened())
        })
    },
    []
  )
}

const useBrighteApplicationCallback = (): CheckoutActionCallback => {
  const openApplicationDialog = useOpenApplicationDialog()
  const notify = useNotify()

  return useCallback(async ({ elementData, proposalData, additionalActionData }) => {
    if (elementData.type !== 'LoanApplication') {
      Sentry.captureException(new Error('Invalid element data for LoanApplication callback'))
      return
    }
    if (elementData.actionData.payment_method !== 'brighte_application') {
      Sentry.captureException(new Error('Invalid element data for brighte application callback'))
      return
    }
    const { actionData } = elementData

    if (actionData.status_code !== 'available') {
      notify('Brighte Application not available', 'error')
      Sentry.captureException(new Error('Handle Brighte Application callback but status is not available'))
      return
    }

    await openApplicationDialog({
      org_id: actionData.org_id,
      project_id: actionData.project_id,
      system_uuid: actionData.system_uuid,
      payment_option_id: actionData.payment_option_id,
    })
  }, [])
}

export default useBrighteApplicationCallback
