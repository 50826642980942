import { FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from '@material-ui/core'
import { authSelectors } from 'ducks/auth'
import {
  generateDocumentSelectors,
  setCountersignerFields,
  setDisableGeneration,
  setIsDocusign,
} from 'ducks/generateDocument'
import CustomField from 'elements/field/CustomField'
import { formSharingParams } from 'projectSections/sections/info/orgSharing/util'
import { useCallback, useEffect, useState } from 'react'
import { ReferenceInput, useDataProvider, useTranslate } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { FinanceQRCodeWarning } from '../FinanceQRCodeWarning'
import { SelectDocTemplateInput } from '../SelectDocTemplateInput'
import ChangeOrderSelection from './ChangeOrderSelection'

const DOCUMENT_TYPES_SELECTION_CONTROLS = {
  contract_only: {
    systems: true,
    payment_options: true,
  },
  proposal_only: {
    systems: true,
    payment_options: true,
    show_finance_qr_warning: true,
  },
  proposal: {
    systems: true,
    payment_options: true,
    show_finance_qr_warning: true,
  },
  owners_manual: {
    systems: true,
    payment_options: false,
  },
  generic_document: {
    systems: true,
    payment_options: true,
  },
  energy_yield_report: {
    systems: true,
    payment_options: false,
  },
  shade_report: {
    systems: true,
    payment_options: false,
  },
  pv_site_plan: {
    systems: true,
    payment_options: false,
  },
  structural_report_mcs: {
    systems: true,
    payment_options: false,
  },
  installation_instructions: {
    systems: true,
    payment_options: false,
  },
  lightreach_sales_design_details: {
    systems: true,
    payment_options: false,
  },
}

export const GenerateDocumentInitialDialog = (props) => {
  const { doc, orgId, projectForm } = props

  const translate = useTranslate()
  const dataProvider = useDataProvider()
  const dispatch = useDispatch()

  const [systems, setSystems] = useState([])
  const [availablePaymentOptions, setAvailablePaymentOptions] = useState([])
  const [isShadeReport, setIsShadeReport] = useState(false)

  const [hasCountersigner, setHasCounterSigner] = useState(false)

  const form = useForm()
  const formState = useFormState()
  const documentType = formState.values.document_type
  const documentTemplateUrl = formState.values.document_template_url
  const documentTemplateId = formState.values.document_template_id
  const documentTemplateOrgId = formState.values.document_template_org_id
  const selectedSystem = formState.values.selected_system
  const selectedPaymentOptionId = formState.values.selected_payment_option_id
  const isDocusign = useSelector((state) => state.generateDocument.dialogOptions.isDocusign)

  const showSystemSelection =
    !!DOCUMENT_TYPES_SELECTION_CONTROLS[documentType]?.systems || (isDocusign && documentType === 'generic_document')
  const showSystemPmtSelection =
    !!DOCUMENT_TYPES_SELECTION_CONTROLS[documentType]?.payment_options ||
    (isDocusign && documentType === 'generic_document')
  const showFinanceWarning = !!DOCUMENT_TYPES_SELECTION_CONTROLS[documentType]?.show_finance_qr_warning

  const currOrgId = useSelector(authSelectors.getOrgId)
  const countersignerFields = useSelector(generateDocumentSelectors.getCountersignerFields)

  const updateCountersignerFields = useCallback(
    (fieldName, fieldValue) => {
      countersignerFields[fieldName] = fieldValue
      dispatch(setCountersignerFields(countersignerFields))
    },
    [countersignerFields?.firstName, countersignerFields?.lastName, countersignerFields?.email]
  )

  useEffect(() => {
    var newIsShadeReport = documentType === 'shade_report' ? true : false
    if (isShadeReport !== newIsShadeReport) {
      setIsShadeReport(newIsShadeReport)
    }

    if (documentTemplateUrl === '') {
      dispatch(setIsDocusign(false))
      setHasCounterSigner(false)
    } else if (
      (documentType === 'generic_document' || documentType === 'change_order') &&
      (documentTemplateOrgId === orgId || documentTemplateOrgId !== 1)
    ) {
      // Only include docusign tags if the template has either been created on the current org, or has been shared to the current org via Teams.
      // Do not include docusign tags if using a generic template on org=1

      // fetch record to see how it should be treated

      dataProvider
        .getOne('document_templates', { id: documentTemplateId })
        .then(({ data }) => {
          if (data?.integration === 1) {
            dispatch(setIsDocusign(true))
            if (data?.docusign_designated_countersigner_first_name)
              updateCountersignerFields('firstName', data?.docusign_designated_countersigner_first_name)
            if (data?.docusign_designated_countersigner_family_name)
              updateCountersignerFields('lastName', data?.docusign_designated_countersigner_family_name)
            if (data?.docusign_designated_countersigner_email)
              updateCountersignerFields('email', data?.docusign_designated_countersigner_email)
            if (
              data?.docusign_require_sales_rep_countersignature !== undefined &&
              data?.docusign_require_sales_rep_countersignature !== null
            )
              updateCountersignerFields(
                'docusign_require_sales_rep_countersignature',
                data?.docusign_require_sales_rep_countersignature
              )
          } else {
            dispatch(setIsDocusign(false))
          }
          try {
            let countersignerTags = [
              '/DS-sigcounter-',
              '/DS-initialscounter-',
              '/DS-datecounter-',
              '/DS-namecounter-',
              '/DS-emailcounter-',
              '/DS-phonecounter-',
              '/DS-checkboxcounter-',
              '/DS-checkboxoptionalcounter-',
              '/DS-textcounter-',
              '/DS-textoptionalcounter-',
            ]
            for (let i = 0, len = countersignerTags.length; i < len; i++) {
              if (data.content.includes(countersignerTags[i])) {
                setHasCounterSigner(true)
                break
              }
            }
          } catch (ex) {}
        })
        .catch((error) => {
          console.log('error', error)
        })
    } else {
      dispatch(setIsDocusign(false))
    }
  }, [documentType])

  useEffect(() => {
    if (showSystemSelection || showSystemPmtSelection) {
      const systemsFromStudio = window.editor.getSystems()?.map((sys) => {
        sys.paymentOptionsData = sys.payment_options
        sys.title = sys.name && sys.name.length > 0 ? sys.name : `${sys.moduleQuantity()} ${translate('Panels System')}`
        return sys
      })
      if (systemsFromStudio && systemsFromStudio.length > 0) {
        setSystems(systemsFromStudio)
        let _selectedSystem = selectedSystem

        if (!selectedSystem || !systemsFromStudio.find((s) => s.uuid === selectedSystem.uuid)) {
          form.change('selected_system', systemsFromStudio[0])
          _selectedSystem = systemsFromStudio[0]
        }
        setAvailablePaymentOptions(_selectedSystem.paymentOptionsData)
      }
    } else {
      setSystems([])
      setAvailablePaymentOptions([])
    }
  }, [documentTemplateUrl, isDocusign, selectedSystem, showSystemSelection, showSystemPmtSelection])

  useEffect(() => {
    if (!availablePaymentOptions || availablePaymentOptions.length === 0) {
      form.change('selected_payment_option_id', undefined)
    } else if (
      availablePaymentOptions?.length > 0 &&
      !availablePaymentOptions.find((po) => po.id === selectedPaymentOptionId)
    ) {
      form.change('selected_payment_option_id', availablePaymentOptions[0].id)
    }
  }, [availablePaymentOptions, selectedSystem])

  useEffect(() => {
    if (doc?.downloadUrl && isDocusign) {
      if (
        hasCountersigner &&
        (!countersignerFields?.firstName || !countersignerFields?.lastName || !countersignerFields?.email)
      ) {
        dispatch(setDisableGeneration(true))
      }
    } else {
      dispatch(setDisableGeneration(false))
    }
  }, [doc])

  const handleSystemChange = (e) => {
    if (systems && systems.length > 0) {
      let uuidMatches = systems.filter((system) => system.uuid === e.target.value)
      if (uuidMatches && uuidMatches.length > 0) form.change('selected_system', uuidMatches[0])
    }
  }

  const handlePmtOptionChange = (e) => form.change('selected_payment_option_id', e.target.value)

  return (
    <>
      <div className={'templateSelectField'}>
        <div>
          <FormLabel>{translate('Select a Document Template')}</FormLabel>
        </div>
        <CustomField
          component={ReferenceInput}
          source="document_template_url"
          name="document_template_url"
          label={false}
          filter={formSharingParams(currOrgId, window.WorkspaceHelper.project, {})}
          reference="document_templates"
          perPage={100}
          onChange={() => console.log('onChange')}
          allowEmpty
        >
          <SelectDocTemplateInput doc={doc} clearDownloadUrl={props.clearDownloadUrl} />
        </CustomField>
      </div>

      {documentType === 'change_order' && <ChangeOrderSelection project={props.projectForm?.getState()?.values} />}
      {documentType && showSystemSelection && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            marginBottom: 20,
          }}
        >
          <div style={{ flex: 1 }}>
            <h2>{translate('Select a System')}</h2>
            <RadioGroup
              style={{ alignItems: 'flex-start' }}
              name="pmt-options"
              value={`${selectedSystem?.uuid}`}
              onChange={handleSystemChange}
            >
              {systems &&
                systems.map((system) => {
                  return (
                    <FormControlLabel
                      key={system.uuid}
                      value={system.uuid}
                      control={<Radio />}
                      label={<span style={{ fontSize: 13 }}>{system.title}</span>}
                      disabled={!!doc}
                    />
                  )
                })}
            </RadioGroup>
          </div>
          {showSystemPmtSelection && !isShadeReport && (
            <div style={{ flex: 1 }}>
              <h2>{translate('Select a Payment Option')}</h2>
              {selectedSystem && availablePaymentOptions?.length === 0 && (
                <div style={{ color: 'red' }}>
                  {translate('You must add a payment option to this system before you can send this document')}
                </div>
              )}

              <RadioGroup value={`${selectedPaymentOptionId}`} onChange={handlePmtOptionChange}>
                {availablePaymentOptions.map((pmtOption) => {
                  return (
                    <FormControlLabel
                      key={pmtOption.id}
                      value={`${pmtOption.id}`}
                      control={
                        <Radio
                          disabled={!selectedSystem}
                          checked={`${pmtOption.id}` === `${selectedPaymentOptionId}`}
                        />
                      }
                      label={<span style={{ fontSize: 13 }}>{pmtOption.title}</span>}
                      disabled={!!doc}
                    />
                  )
                })}
              </RadioGroup>
            </div>
          )}
        </div>
      )}

      {documentType && isDocusign && hasCountersigner && (
        <div style={{ marginBottom: '30px' }}>
          <h2>Countersigner</h2>
          <p>
            {translate(
              'Please enter the name and email address of the user you would like to countersign this document'
            )}
          </p>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}
          >
            <div style={{ margin: '5px', flex: 2 }}>
              <TextField
                value={countersignerFields?.firstName}
                onChange={(e) => updateCountersignerFields('firstName', e.target.value)}
                label="First Name"
                fullWidth
                disabled={!countersignerFields?.docusign_require_sales_rep_countersignature}
              />
            </div>
            <div style={{ margin: '5px', flex: 2 }}>
              <TextField
                value={countersignerFields?.lastName}
                onChange={(e) => updateCountersignerFields('lastName', e.target.value)}
                label="Last Name"
                fullWidth
                disabled={!countersignerFields?.docusign_require_sales_rep_countersignature}
              />
            </div>
            <div style={{ margin: '5px', flex: 3 }}>
              <TextField
                value={countersignerFields?.email}
                onChange={(e) => updateCountersignerFields('email', e.target.value)}
                label="Email Address"
                fullWidth
                disabled={!countersignerFields?.docusign_require_sales_rep_countersignature}
              />
            </div>
          </div>
        </div>
      )}

      <FinanceQRCodeWarning
        projectFormState={projectForm.getState()}
        show={showFinanceWarning}
        location="document_generation_dialog"
      />
    </>
  )
}
