import { CheckoutElementDataType, LoanApplicationType } from 'opensolar-checkout'
import useSpyForceOpenLoanApplicationEffect from './loanApplicationEffect/useSpyForceOpenLoanApplicationEffect'
import useSpyLoanApplicationStatusEffect from './loanApplicationEffect/useSpyLoanApplicationStatusEffect'

const CheckoutActionsEffect = ({
  checkoutElementsData,
  proposalData,
}: {
  checkoutElementsData: CheckoutElementDataType[]
  proposalData
}) => {
  const loanApplication = checkoutElementsData.find((elementData) => elementData.type === 'LoanApplication') as
    | LoanApplicationType
    | undefined
  return (
    <>
      {loanApplication !== undefined && (
        <LoanApplicationCheckoutEffect elementData={loanApplication} proposalData={proposalData} />
      )}
    </>
  )
}

const LoanApplicationCheckoutEffect = ({
  elementData,
  proposalData,
}: {
  elementData: LoanApplicationType
  proposalData
}) => {
  useSpyForceOpenLoanApplicationEffect({ elementData, proposalData })
  useSpyLoanApplicationStatusEffect({ elementData, proposalData })
  return null
}

export default CheckoutActionsEffect
