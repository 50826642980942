import React from 'react'
import { Box, Grid, styled } from 'opensolar-ui'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { HardwareSupplierEnum, HardwareSupplierFilterKeyType } from '../type'
import { DistributorDataTypeV2 } from '../../../elements/hardwareSelectorV2/types'
import { sortByPriceOrderingV2 } from '../utils'
import { getSupplierConfigByFilterKey, getSupplierEnumByFilterKey } from '../configs'

const useStyles = makeOpenSolarStyles((theme: any) => ({
  chip: {
    border: `1px solid rgb(0, 0, 0, 0.3)`,
    borderRadius: '4px',
  },
  selectedChip: {
    border: `2px solid ${theme.blue4}`,
  },
}))

const ChipImage = styled('img')({
  height: '22px',
  width: '35px',
  objectFit: 'contain',
})

const getDistributorImagePath = (distributor: HardwareSupplierFilterKeyType) => {
  if (getSupplierEnumByFilterKey(distributor) === HardwareSupplierEnum.Hdm) {
    // The existing HDM logo uses different from the proposed design.
    return `${window.PUBLIC_URL}/images/hdm-chip.png`
  }

  return getSupplierConfigByFilterKey(distributor).logoUrl
}

interface DistributorChipsProps {
  ordering_v2: DistributorDataTypeV2[]
  onSelect: (event, distributor: DistributorDataTypeV2) => void
  selectedDistributor: DistributorDataTypeV2 | null | undefined
}

const DistributorChips = ({
  ordering_v2,
  onSelect,
  selectedDistributor,
}: DistributorChipsProps) => {
  const classes = useStyles()

  const distributors = sortByPriceOrderingV2(ordering_v2)
  const hasMultipleDistributors = distributors.length > 1

  const handleClick = (event, distributor) => {
    onSelect(event, distributor)
  }

  return (
    <Grid container spacing={1}>
      {distributors.map((order, index) => (
        <Grid item key={index}>
          <Box
            onClick={(event) => handleClick(event, order)}
            className={`${classes.chip} ${
              selectedDistributor?.distributor === order.distributor && hasMultipleDistributors
                ? classes.selectedChip
                : ''
            }`}
          >
            <ChipImage src={getDistributorImagePath(order.distributor)} />
          </Box>
        </Grid>
      ))}
    </Grid>
  )
}

export default DistributorChips
