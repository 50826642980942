import Alert from 'elements/Alert'
import { Button, styled, Typography } from 'opensolar-ui'
import { useTranslate } from 'react-admin'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { projectInfoSelector } from 'reducer/project/projectInfoReducer'

const StyledAlert = styled(Alert)({
  marginBottom: '24px',
})

const StyledButton = styled(Button)({
  marginTop: '12px',
  ' > span': {
    textTransform: 'none',
  },
})

const SystemLimitProposalAlert = () => {
  const translate = useTranslate()
  const projectId = useSelector(projectInfoSelector.getProjectId)

  return (
    <StyledAlert severity="warning">
      <Typography textVariant="subtitle2" colorHex="#BA622E">
        {translate('Too Many Electrification Systems Selected')}
      </Typography>
      <Typography textVariant="helper1" colorHex="#BA622E">
        {translate(
          'You have selected more than six electrification systems. To access proposals, reduce the number of systems selected in the Payments tab to six or fewer.'
        )}
      </Typography>
      <Link to={`/projects/${projectId}/payments`}>
        <StyledButton variant="contained">{translate('Update in Payments')}</StyledButton>
      </Link>
    </StyledAlert>
  )
}

export default SystemLimitProposalAlert
