import { InputLabel, makeStyles } from '@material-ui/core'
import { FormControl, Grid, MenuItem, Select } from 'opensolar-ui'
import AccordionCard from 'projectSections/sections/info/AccordionCard'
import { useTranslate } from 'ra-core'
import { ChangeEvent, FC, memo, useContext, useEffect, useState } from 'react'
import { DealerInfo, DealerType } from 'services/vtac/type'
import useVtacService from 'services/vtac/useVtacService'
import { Theme } from 'types/themes'
import AccordionCardSummaryContent from '../components/AccordionCardSummaryContent'
import AccordionCardTitle from '../components/AccordionCartTitle'
import Alert from '../components/Alert'
import DeliveryInstruction from '../components/DeliveryInstruction'
import DistributorDeliveryDetail from '../components/DistributorDeliveryDetail'
import PartsList from '../components/PartsList'
import { SOURCE_CONTEXT_MAPPING } from '../constants'
import { ContactInfoContext, DeliveryAddressInfoContext } from '../PreOrderProvider'
import { COLOR_PALETTE, getHardwareSectionPreOrderStyles } from '../styles'
import { CartSectionContentPropType, CartSectionPropType } from '../types'

const useStyles = makeStyles((theme: Theme) => ({
  radio: {
    padding: '0px 16px 0px 0px',
    '&.Mui-checked': {
      color: '#4272DD',
    },
  },
  inputLabel: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '20px',
    color: COLOR_PALETTE.grey,
    marginBottom: '4px',
  },
  distributorDisclaimer: {
    fontSize: '14px',
    color: COLOR_PALETTE.grey5,
  },
}))

const VTACCartSection: FC<CartSectionPropType> = ({ sortOrder, distributorKey, checkoutSource }) => {
  const sectionStyles = getHardwareSectionPreOrderStyles()
  const distributorData = useContext(SOURCE_CONTEXT_MAPPING[checkoutSource].distributorDataContext)
  const data = distributorData[distributorKey]
  return (
    <AccordionCard
      name={'distributor-vtac'}
      defaultExpanded={true}
      elevation={0}
      className={sectionStyles.accordionReskin}
      title={<AccordionCardTitle distributorKey={distributorKey} sortOrder={sortOrder} />}
      summaryContent={
        <AccordionCardSummaryContent
          totalItems={data?.totalItems}
          subtotal={data?.subtotalPrice}
          shippingFee={data?.shippingInfo.fee}
        />
      }
      content={VTACCartSectionContent}
      contentProps={{ distributorKey, checkoutSource }}
      lazyRender={true}
    />
  )
}

const VTACCartSectionContent: FC<CartSectionContentPropType> = ({ distributorKey, checkoutSource }) => {
  const classes = useStyles()
  const sectionStyles = getHardwareSectionPreOrderStyles()
  const vtacService = useVtacService()
  const distributorData = useContext(SOURCE_CONTEXT_MAPPING[checkoutSource].distributorDataContext)
  const preOrderPresenter = useContext(SOURCE_CONTEXT_MAPPING[checkoutSource].presenterContext)
  const contactInfo = useContext(ContactInfoContext)
  const deliveryAddressInfo = useContext(DeliveryAddressInfoContext)
  const [dealers, setDealers] = useState<DealerType[]>([])
  const data = distributorData[distributorKey]
  const translate = useTranslate()

  useEffect(() => {
    const fetchDealers = async () => {
      const response = await vtacService?.getDealers(distributorKey)
      if (response) {
        const dealerArray: DealerType[] = Object.entries(JSON.parse(response)).map(([key, value]) => {
          return {
            id: +key,
            ...(value as DealerInfo),
          } as DealerType
        })
        setDealers(dealerArray)
      }
    }
    fetchDealers()
  }, [preOrderPresenter, distributorKey])

  const handleUpdateDeliveryInstructions = (event: React.ChangeEvent<HTMLInputElement>) => {
    preOrderPresenter?.updateDistributorData({
      ...distributorData,
      [distributorKey]: {
        ...data,
        shippingInfo: {
          ...data?.shippingInfo,
          deliveryInstructions: event.target.value,
        },
      },
    })
  }

  const handleUpdateDealer = (event: ChangeEvent<{ name?: string | undefined; value: unknown }>) => {
    preOrderPresenter?.updateDistributorData({
      ...distributorData,
      [distributorKey]: {
        ...data,
        dealerId: `${event.target.value}`,
      },
    })
  }

  return (
    <Grid container style={{ gap: '24px', padding: '8px 8px' }}>
      <Grid item className={`${sectionStyles.column}`} xs={12} md={12} style={{ gap: '8px' }}>
        <Alert
          title={translate('What’s next after sending my order?')}
          subtitle={translate(
            'Once sent, your order will be transferred to the V-TAC’s portal for payment processing and final shipping confirmation. You will then receive a V-TAC order reference ID for tracking purposes.'
          )}
          severity="info"
        />
      </Grid>
      <Grid item className={`${sectionStyles.column}`} xs={12} md={12}>
        <DistributorDeliveryDetail distributorKey={distributorKey} checkoutSource={checkoutSource} />
      </Grid>
      <Grid item className={`${sectionStyles.column}`} xs={12} md={12} style={{ gap: '16px' }}>
        <span className={sectionStyles.headerTitle}>{translate('Select Distributor Location')}</span>
        <span className={classes.distributorDisclaimer}>
          {translate(
            'Choose your preferred distributor to fulfill your order. V-TAC will provide updated delivery estimates and\n' +
              'shipping cost before order is processed.'
          )}
        </span>
        <FormControl fullWidth>
          <InputLabel className={classes.inputLabel}>{translate('Distributor')}</InputLabel>
          <Select value={data?.dealerId} onChange={handleUpdateDealer}>
            {dealers.map((item, index) => (
              <MenuItem id={'dealer' + index} value={item.id} key={item.id}>
                {item.dealer_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item className={`${sectionStyles.column}`} xs={12} md={12} style={{ gap: '8px' }}>
        <DeliveryInstruction
          handleUpdateDeliveryInstructions={handleUpdateDeliveryInstructions}
          value={data?.shippingInfo?.deliveryInstructions}
        />
      </Grid>
      <Grid item className={`${sectionStyles.column}`} xs={12} md={12} style={{ gap: '8px' }}>
        <span className={sectionStyles.headerTitle}>{translate('Review Parts List')}</span>
        <PartsList data={data} />
      </Grid>
      <Grid item className={`${sectionStyles.row}`} xs={12} md={12} style={{ gap: '8px' }}>
        <span>{translate('By placing your order, you agree to V-TAC’s Privacy Policy and Conditions of Use')}</span>
      </Grid>
    </Grid>
  )
}

export default memo(VTACCartSection)
