import BannerAd from 'elements/BannerAd'

export const LeaderboardAd = () => {
  return (
    <BannerAd
      formats={['leaderboard']}
      placement="project_manage"
      requiredWindowSize={{ width: 960 }}
      rounded={true}
      containerStyle={{ width: '100%' }}
    />
  )
}
