import { styled } from 'opensolar-ui'

export const Column = styled('div', { name: 'Column' })({
  display: 'flex',
  flexDirection: 'column',
})

export const RowCentered = styled('div', { name: 'RowCentered' })({
  display: 'flex',
  alignItems: 'center',
})

export const InputRow = styled('div', { name: 'InputRow' })({
  display: 'flex',
  gap: 10,
})

export const InputWrapper = styled('div', { name: 'InputWrapper' })({
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
})

export const ContentBg = styled('div', { name: 'ContentBg' })(({ theme }) => ({
  backgroundColor: theme.palette.info.light,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}))

export const SectionCardTop = styled('div', { name: 'SectionCardTop' })(({ theme }) => ({
  backgroundColor: 'white',
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
  borderBottom: theme.mixins.borderStandard.border,
  display: 'flex',
  alignItems: 'center',
}))

export const iconStyles = {
  paddingLeft: 20,
  paddingRight: 10,
  display: 'flex',
  alignItems: 'center',
}
export const SectionWrapper = styled('div', { name: 'SectionWrapper' })({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignItems: 'stretch',
  padding: 40,
  width: '100%',
  maxWidth: 1440,
  boxSizing: 'border-box',
  gap: 30,

  '& > *': {
    maxWidth: '100%',
  },
})

export const HalfWidthSection = styled('div', { name: 'HalfWidthSection' })({
  display: 'flex',
  flexDirection: 'column',
  width: '40%',
  flexGrow: 1,
  minWidth: 300,
  maxHeight: 400,
})

export const SectionCardBottom = styled('div', { name: 'SectionCardBottom' })({
  backgroundColor: 'white',
  padding: 20,
  borderBottomLeftRadius: 8,
  borderBottomRightRadius: 8,
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  overflowY: 'auto',
})
