import { Dialog, DialogActions, DialogContent } from '@material-ui/core'
import { Button } from 'opensolar-ui'
import { useTranslate } from 'react-admin'
import { bimonthlyNames, formatCurrencyWithSymbol, monthNames, quarterNamesInMonth } from '../../util/misc'
import { billFrequencyQuantity } from '../mainContent/components/billSavings'
import { enhancedFormatCurrency, enhancedFormatInt, formatUsage } from './utils'

const billFrequencyLabels = { quarterly: 'Quarter', monthly: 'Month', every_second_month: 'Every Second Month' }

const getPeriodLabel = (billFrequency) => {
  if (billFrequency === 'monthly') {
    return monthNames
  } else if (billFrequency === 'every_second_month') {
    return bimonthlyNames
  } else {
    return quarterNamesInMonth
  }
}

const getBillVariable = (billData, billFrequency, billIndex, varName) => {
  let variable
  if (billFrequency === 'annually') {
    billFrequency = 'annual'
    variable = billData.bills_yearly[0][billFrequency][varName]
  } else {
    variable = billData.bills_yearly[0][billFrequency][billIndex][varName]
  }

  return variable || 0.0
}

const getBillValue = (billData, billFrequency, billIndex, isIgnoreTax) => {
  const billValue = getBillVariable(billData, billFrequency, billIndex, 'total_utility_bill_only')
  const tax = getBillVariable(billData, billFrequency, billIndex, 'tax')
  const value = isIgnoreTax ? billValue : billValue + tax
  return value || 0.0
}

const getTotalIncentive = (proposedBillData, billFrequency, billIndex, isIgnoreTax) => {
  const incentivesData = getBillVariable(proposedBillData, billFrequency, billIndex, 'incentives')

  if (isIgnoreTax) {
    return incentivesData.reduce((sum, incentive) => sum + incentive.ex_tax, 0)
  } else {
    return incentivesData.reduce((sum, incentive) => sum + incentive.inc_tax, 0)
  }
}

const getBillSavings = (currentBillData, proposedBillData, billFrequency, billIndex, isIgnoreTax) => {
  const currentBill = getBillValue(currentBillData, billFrequency, billIndex, isIgnoreTax)
  const proposedBill = getBillValue(proposedBillData, billFrequency, billIndex, isIgnoreTax)
  const totalIncentive = getTotalIncentive(proposedBillData, billFrequency, billIndex, isIgnoreTax)
  const billSaving = currentBill - proposedBill + totalIncentive
  return billSaving || 0.0
}

const getSolarGeneration = (systemData, billFrequency, billIndex, monthsInPeriod) => {
  const monthlySolarGeneration = systemData.output?.monthly
  const solarGenerationForPeriod = monthlySolarGeneration.slice(
    billIndex * monthsInPeriod,
    (billIndex + 1) * monthsInPeriod
  )
  const solarGeneration = solarGenerationForPeriod.reduce((a, b) => a + b, 0)

  return Math.round(solarGeneration)
}

const BillIncentiveColumns = (proposedBillData, billFrequency, billIndex, isIgnoreTax) => {
  const incentivesData = getBillVariable(proposedBillData, billFrequency, billIndex, 'incentives')

  return incentivesData.map((incentive) => (
    <td>{enhancedFormatInt(isIgnoreTax ? incentive.ex_tax : incentive.inc_tax)}</td>
  ))
}

const BillIncentiveColumnHeader = (proposedBillData, currencySymbol) => {
  return proposedBillData.incentives.map((incentive) => (
    <th>
      <span style={{ display: 'block' }}>{incentive.title}</span>
      <span> ({currencySymbol}) </span>
    </th>
  ))
}

const isNem = (proposedBillData) => {
  const valid_distributed_generation_rules = ['netherland_nem_phase_out_2031', 'Net Metering']
  return valid_distributed_generation_rules.includes(proposedBillData?.distributed_generation_rules)
}

const isVirtualStorage = (proposedBillData) => {
  const valid_distributed_generation_rules = ['virtual_storage']
  return valid_distributed_generation_rules.includes(proposedBillData?.distributed_generation_rules)
}

const hasExportRate = (proposedBillData) => {
  // Note that "Gross Metering with Feed-in Tariff" is just Net Billing
  const rulesWIthExportRate = [
    'net_billing_with_credit_carryover',
    'Gross Metering with Feed-in-tariff',
    'Gross Metering',
  ]
  return proposedBillData?.has_fit && rulesWIthExportRate.includes(proposedBillData?.distributed_generation_rules)
}

const hasDollarCreditRollover = (proposedBillData) => {
  const valid_distributed_generation_rules = ['netherland_nem_phase_out_2031', 'net_billing_with_credit_carryover']

  const nemCreditOverType = proposedBillData?.credit_rollover_type
  const isNemDollarCreditOver = nemCreditOverType
    ? proposedBillData?.distributed_generation_rules === 'Net Metering' && nemCreditOverType === 'dollar_rollover'
    : proposedBillData?.distributed_generation_rules === 'Net Metering'

  return (
    valid_distributed_generation_rules.includes(proposedBillData?.distributed_generation_rules) || isNemDollarCreditOver
  )
}

const showCumulativeCredits = (proposedBillData) => {
  const isTrueUpAnnually = proposedBillData?.nem_true_up_date?.includes('annually_in_month_')
  return hasDollarCreditRollover(proposedBillData) && isTrueUpAnnually
}

const isTrueUpDate = (proposedBillData, billIndex, billFrequency) => {
  const nemTrueUpDate = proposedBillData?.nem_true_up_date
  const isTrueUpAnnually = nemTrueUpDate.includes('annually_in_month_')

  if (isTrueUpAnnually) {
    const trueUpMonth = nemTrueUpDate.replace(/\D/g, '')
    if (billFrequency === 'monthly') {
      return trueUpMonth === billIndex
    } else if (billFrequency === 'every_second_month') {
      return (billIndex + 1) * 2 > trueUpMonth && trueUpMonth >= billIndex * 2
    } else {
      return (billIndex + 1) * 3 > trueUpMonth && trueUpMonth >= billIndex * 3
    }
  }
}

const getSavingDetails = (proposalData, myeStyles, billFrequency, translate) => {
  const currentBillData = proposalData.selectedSystem?.bills?.current
  const proposedBillData = proposalData.selectedSystem?.bills?.proposedSelected
  const isIgnoreTax = proposalData.selectedSystem?.pricing?.ignore_tax_in_financials
  const monthsInPeriod = billFrequencyQuantity[billFrequency]
  const periodNames = getPeriodLabel(billFrequency)
  const isGrossMetering = proposedBillData?.distributed_generation_rules === 'Gross Metering'

  const limitedPeriodNames = billFrequency === 'annually' ? [periodNames[0]] : periodNames

  // Calculate total net_kwh_usage for annually
  const totalNetKwhUsageAnnually =
    billFrequency === 'annually'
      ? currentBillData.bills_yearly[0].monthly.reduce((sum, monthData) => sum + (monthData.net_kwh_usage || 0), 0)
      : null

  return limitedPeriodNames.map((element, index) => {
    const netSurplusCredit = getBillVariable(proposedBillData, billFrequency, index, 'net_surplus_compensation')
    return (
      <tr>
        <td style={{ background: myeStyles.highlightColorLight, color: myeStyles.textColor }}>
          {billFrequency === 'monthly'
            ? translate(monthNames[index])
            : `${translate(monthNames[index * monthsInPeriod])} - ${translate(
                monthNames[(index + 1) * monthsInPeriod - 1]
              )}`}
        </td>
        <td>
          {enhancedFormatInt(getSolarGeneration(proposalData.selectedSystem, billFrequency, index, monthsInPeriod))}
        </td>
        <td>
          {billFrequency === 'annually'
            ? enhancedFormatInt(totalNetKwhUsageAnnually)
            : formatUsage(getBillVariable(currentBillData, billFrequency, index, 'net_kwh_usage'))}
        </td>
        {hasExportRate(proposedBillData) && isGrossMetering && (
          <td>{formatUsage(getBillVariable(proposedBillData, billFrequency, index, 'total_kwh_drawn_from_grid'))}</td>
        )}
        {isVirtualStorage(proposedBillData) && (
          <td>
            {formatUsage(
              getBillVariable(proposedBillData, billFrequency, index, 'total_kwh_drawn_from_grid') -
                getBillVariable(proposedBillData, billFrequency, index, 'total_kwh_drawn_from_virtual_storage')
            )}
          </td>
        )}
        {isVirtualStorage(proposedBillData) && (
          <td>
            {formatUsage(
              getBillVariable(proposedBillData, billFrequency, index, 'total_kwh_drawn_from_virtual_storage')
            )}
          </td>
        )}
        {hasExportRate(proposedBillData) && isGrossMetering && (
          <td>
            {enhancedFormatInt(getSolarGeneration(proposalData.selectedSystem, billFrequency, index, monthsInPeriod))}
          </td>
        )}
        {hasExportRate(proposedBillData) && !isGrossMetering && (
          <td>{formatUsage(getBillVariable(proposedBillData, billFrequency, index, 'total_kwh_drawn_from_grid'))}</td>
        )}
        {hasExportRate(proposedBillData) && !isGrossMetering && (
          <td>{formatUsage(getBillVariable(proposedBillData, billFrequency, index, 'total_kwh_export_to_grid'))}</td>
        )}
        {hasExportRate(proposedBillData) && (
          <td>{formatUsage(getBillVariable(proposedBillData, billFrequency, index, 'feed_in_tariff'))}</td>
        )}
        {isNem(proposedBillData) && (
          <td>{formatUsage(getBillVariable(proposedBillData, billFrequency, index, 'net_kwh_usage'))}</td>
        )}
        <td>{enhancedFormatInt(getBillValue(currentBillData, billFrequency, index, isIgnoreTax))}</td>
        <td>{enhancedFormatInt(getBillValue(proposedBillData, billFrequency, index, isIgnoreTax))}</td>
        {proposedBillData.incentives && BillIncentiveColumns(proposedBillData, billFrequency, index, isIgnoreTax)}
        {showCumulativeCredits(proposedBillData) && (
          <td>
            {enhancedFormatInt(
              Math.abs(getBillVariable(proposedBillData, billFrequency, index, 'nem_credit_dollar_balance'))
            )}
            <br />
            <small>{isTrueUpDate(proposedBillData, index, billFrequency) ? 'True-up Credits' : ''}</small>
            <br />
            <small>
              {netSurplusCredit > 0
                ? `(NSC: ${formatCurrencyWithSymbol(netSurplusCredit, proposalData.currencySymbol, window.locale, 0)})`
                : ''}
            </small>
          </td>
        )}
        <td>
          {enhancedFormatCurrency(
            getBillSavings(currentBillData, proposedBillData, billFrequency, index, isIgnoreTax),
            true
          )}
        </td>
      </tr>
    )
  })
}

export const SavingsDetailDialog = (props) => {
  const translate = useTranslate()
  const proposedBillData = props.proposalData.selectedSystem?.bills?.proposedSelected
  const billFrequency = proposedBillData.bill_frequency

  return (
    <div className="ContainerLetter" id="ContainerLetter">
      {props.proposalData && (
        <Dialog
          open={props.isOpen}
          autoScrollBodyContent={true}
          maxWidth="xl"
          fullWidth={true}
          onBackdropClick={() => props.closeDialog()}
        >
          <DialogContent style={{ padding: 0 }}>
            <div>
              <table className={'mye-detail-table'} style={{ textAlign: 'center', width: '100%' }}>
                <thead>
                  <tr style={{ background: props.myeStyles.highlightColorLight, color: props.myeStyles.textColor }}>
                    <th>{translate(billFrequencyLabels[billFrequency])}</th>
                    <th>
                      <span style={{ display: 'block' }}>{translate('Solar Generation')}</span>
                      <span> (kWh) </span>
                    </th>
                    <th>
                      <span style={{ display: 'block' }}>{translate('Electricity Consumption')}</span>
                      <span>{translate('before solar')}</span>
                      <span> (kWh) </span>
                    </th>
                    {isNem(proposedBillData) && (
                      <th>
                        <span style={{ display: 'block' }}>{translate('Electricity Consumption')}</span>
                        <span>{translate('after solar')}</span>
                        <span> (kWh) </span>
                      </th>
                    )}
                    {hasExportRate(proposedBillData) && (
                      <th>
                        <span style={{ display: 'block' }}>{translate('Electricity Imported')}</span>
                        <span>{translate('after solar')}</span>
                        <span> (kWh) </span>
                      </th>
                    )}
                    {isVirtualStorage(proposedBillData) && (
                      <th>
                        <span style={{ display: 'block' }}>{translate('Electricity Imported')}</span>
                        <span>{translate('from grid')}</span>
                        <span> (kWh) </span>
                      </th>
                    )}
                    {isVirtualStorage(proposedBillData) && (
                      <th>
                        <span style={{ display: 'block' }}>{translate('Electricity Imported')}</span>
                        <span>{translate('from virtual storage')}</span>
                        <span> (kWh) </span>
                      </th>
                    )}
                    {hasExportRate(proposedBillData) && (
                      <th>
                        <span style={{ display: 'block' }}>{translate('Electricity Exported')}</span>
                        <span>{translate('after solar')}</span>
                        <span> (kWh) </span>
                      </th>
                    )}
                    {hasExportRate(proposedBillData) && (
                      <th>
                        <span style={{ display: 'block' }}>{translate('Export Credit')}</span>
                        <span> ({props.proposalData.currencySymbol}) </span>
                      </th>
                    )}
                    <th>
                      <span style={{ display: 'block' }}>{translate('Utility Bill')}</span>
                      <span>{translate('before solar')}</span>
                      <span> ({props.proposalData.currencySymbol}) </span>
                    </th>
                    <th>
                      <span style={{ display: 'block' }}>{translate('Utility Bill')}</span>
                      <span>{translate('after solar')}</span>
                      <span> ({props.proposalData.currencySymbol}) </span>
                    </th>
                    {proposedBillData.incentives &&
                      BillIncentiveColumnHeader(proposedBillData, props.proposalData.currencySymbol)}
                    {showCumulativeCredits(proposedBillData) && (
                      <th>
                        <span style={{ display: 'block' }}>{translate('Cumulative Energy Credit')}</span>
                        <span> ({props.proposalData.currencySymbol}) </span>
                      </th>
                    )}
                    <th>
                      <span style={{ display: 'block' }}>{translate('Estimated Savings')}</span>
                      <span> ({props.proposalData.currencySymbol}) </span>
                    </th>
                  </tr>
                </thead>
                <tbody>{getSavingDetails(props.proposalData, props.myeStyles, billFrequency, translate)}</tbody>
              </table>
            </div>
          </DialogContent>
          <DialogActions style={{ borderTop: '1px solid #c5c5c5' }}>
            <Button style={{ marginRight: 10 }} onClick={() => props.closeDialog()}>
              <span>{translate('Close')}</span>
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  )
}
export default SavingsDetailDialog
