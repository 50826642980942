import { Paper, Typography } from '@material-ui/core'
import React, {useMemo} from 'react'
import { Grid, styled } from 'opensolar-ui'
import BorderProgress from 'pages/ordering/home/DistributorConnection/BorderProgress'
import useGetDistributorConnectionStatusAndConfig from 'projectSections/sections/manage3/hardware/hooks/useGetDistributorConnectionStatusAndConfig'
import { useSelector } from 'react-redux'
import { orgSelectors } from 'ducks/orgs'
import DistributorConnectionCard from 'pages/ordering/home/DistributorConnection/DistributorConnectionCard'
import { getSupplierEnumByFilterKey } from 'pages/ordering/configs'

const ColorPaper = styled(Paper)({
  backgroundColor: '#F5F8FD',
  padding: '12px',
  borderRadius: 8,
})

const StyledTypography = {
  PaperDescription: styled(Typography)({
    color: '#113B98',
    fontSize: 12,
  }),
  DistributorNum: styled(Typography)({
    color: '#113B98',
    fontSize: 10,
    fontWeight: 600,
  }),
  Title: styled(Typography)({
    textAlign: 'center',
    fontSize: 20,
    fontWeight: 475,
    color: '#0A090B',
    marginBottom: 16,
  }),
  Description: styled(Typography)({
    textAlign: 'center',
    color: '#4F4D55',
    lineHeight: '20px',
    padding: '0px 16px',
  }),
}

const SubHeader = styled(Grid)({
  marginTop: 24,
  marginBottom: 24,
})

const BomDistributorConnection = () => {
  const enabledSuppliers = useSelector(orgSelectors.getEnabledHardwareSuppliers)

  const { enabledAndConnectedDistributors, distributorConfigs } = useGetDistributorConnectionStatusAndConfig()
  const progressValue = useMemo(() => {
    if (enabledAndConnectedDistributors) {
      return (enabledAndConnectedDistributors.length / enabledSuppliers.length) * 100
    }
    return 0
  }, [enabledAndConnectedDistributors, enabledSuppliers])

  return (
    <>
      <Grid item xs={12}>
        <ColorPaper variant="outlined">
          <Grid container item xs={12} alignItems="center">
            <Grid item xs={12}>
              <StyledTypography.PaperDescription variant="body2">
                Connect your account to all distributors to view best hardware pricing and item availability.
              </StyledTypography.PaperDescription>
            </Grid>
            <Grid item xs={12}>
              <Grid container item justify="space-between" alignItems="center">
                <BorderProgress value={progressValue} />
                <StyledTypography.DistributorNum variant="caption">
                  {`${enabledAndConnectedDistributors?.length || 0} OF ${enabledSuppliers.length} CONNECTED`}
                </StyledTypography.DistributorNum>
              </Grid>
            </Grid>
          </Grid>
        </ColorPaper>
      </Grid>
      <SubHeader item xs={12} alignItems="center" spacing={4}>
        <StyledTypography.Title>
          Connect to Distributors
        </StyledTypography.Title>
        <StyledTypography.Description>
          Gain access to discounted trade prices and stock availability with our distributors for your projects
        </StyledTypography.Description>
      </SubHeader>
      <Grid container item spacing={2}>
        {distributorConfigs
          ?.filter((config) =>
            config.isVisible && enabledSuppliers.includes(getSupplierEnumByFilterKey(config.filterKey)))
          .map((config) => (
            <Grid item xs={12}>
              <DistributorConnectionCard config={config} location={'design'} />
            </Grid>
          ))}
      </Grid>
    </>
  )
}

export default BomDistributorConnection
