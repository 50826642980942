import { Button, styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React from 'react'

const TextBtn = styled(Button, { name: 'TextBtn' })<{
  isActive: boolean
}>(({ isActive, theme }) => ({
  paddingLeft: 5,
  minWidth: 0,
  color: isActive ? theme.palette.blue[700] : undefined,
}))

interface PropTypes {
  label: string
  isActive?: boolean
  onClick: () => void
}

const SortButton: React.FC<PropTypes> = ({ isActive, label, onClick }) => {
  const translate = useTranslate()
  return (
    <TextBtn isActive={!!isActive} disableRipple variant="text" color="secondary" onClick={onClick}>
      {translate(label)}
    </TextBtn>
  )
}

export default SortButton
