import { makeStyles, Paper, useMediaQuery } from '@material-ui/core'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOffOutlined'
import { myEnergySelectors } from 'ducks/myEnergy'
import React from 'react'
import { useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import { MapDataTypes } from 'types/map'
import DiscardButton from '../../elements/header/actions/DiscardButton'
import SaveButton from '../../elements/header/actions/SaveButton'
import ProposalToolbarDialogCTA from './financeCTAs/proposalToolbar/ToolbarCTAButton'
import SendCreditAppButton from './sendCreditAppCta/SendCreditAppButton'
import SendProposal from './SendProposalButton'
import useViewAsCustomer from './useViewAsCustomer'
import ViewAsCustomerToggle from './ViewAsCustomerToggle'

type PropTypes = {
  availableImageryTypes?: MapDataTypes[] | []
}
const useStyles = makeStyles((theme: any) => ({
  paper: ({ viewAsCustomer, isDesktop }: { viewAsCustomer: boolean; isDesktop: boolean }) => {
    return {
      position: 'fixed',
      width: '100%',
      zIndex: 1,
      height: 50,
      left: 0,
      right: 0,
      boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '0 20px',
      boxSizing: 'border-box',
      bottom: !isDesktop && !viewAsCustomer ? '50px' : '0px',
    }
  },
  paperViewAsCustomer: {
    position: 'fixed',
    width: '100%',
    zIndex: 1,
    padding: 0,
    height: 50,
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    alignItems: 'center',
    background: 'rgba(255,255,255,0.9)',
    justifyContent: 'center',
  },
  formActions: {
    width: 185,
  },
  actionWrapper: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
}))

const ProposalToolbar: React.FC<PropTypes> = () => {
  const translate = useTranslate()
  const form = useForm()
  const disableActions = form.mutators.getFormDirtyFields()?.length === 0
  const smallLayout = useMediaQuery((theme: any) => theme.breakpoints.down('sm'))
  const isMobile = useMediaQuery((theme: any) => theme.breakpoints.down('xs'))
  const isDesktop = useMediaQuery((theme: any) => theme.breakpoints.up('md'))
  const viewAsCustomer = useSelector((state: any) => state.viewAsCustomer)
  const projectCountry = useSelector(myEnergySelectors.getProposalProjectCountry)
  const classes = useStyles({ viewAsCustomer, isDesktop })

  const { toggleViewAsCustomer } = useViewAsCustomer()

  const showFormActions = !disableActions && smallLayout

  if (isMobile && viewAsCustomer) {
    return (
      <Paper id="proposal-bottom-toolbar" elevation={1} classes={{ root: classes.paperViewAsCustomer }}>
        <VisibilityOffIcon
          style={{ cursor: 'pointer', color: '#1976d2', marginRight: 5 }}
          onClick={() => toggleViewAsCustomer(false)}
        />
        {translate('Viewing as Customer')}
      </Paper>
    )
  }

  return (
    <Paper id="proposal-bottom-toolbar" elevation={1} classes={{ root: classes.paper }}>
      <ViewAsCustomerToggle toggleViewAsCustomer={toggleViewAsCustomer} />
      <div className={classes.actionWrapper}>
        <ProposalToolbarDialogCTA />
        {projectCountry === 'US' && <SendCreditAppButton />}
        <SendProposal />
        {showFormActions && (
          <div className={classes.formActions}>
            <DiscardButton />
            <SaveButton />
          </div>
        )}
      </div>
    </Paper>
  )
}

export default ProposalToolbar
