import { makeStyles, Theme, Tooltip, useMediaQuery } from '@material-ui/core'
import { UnsubscribeOutlined } from '@material-ui/icons'
import { PaymentRequestType } from 'pages/cashFlow/types'
import { useTranslate } from 'ra-core'
import React, { useMemo } from 'react'
import { useFormState } from 'react-final-form'
import { OpenSolarThemeType } from 'Themes'
import { currencySymbolForCountry, formatCurrencyWithSymbol } from 'util/misc'
import PaymentRequestStatusChip from '../projectProgress/cashFlow/milestoneOverride/PaymentRequestStatusChip'
import InvoiceActions from './invoiceActions'
import PreviewInvoiceLink from './invoiceActions/PreviewInvoiceLink'

type PropTypes = {
  paymentRequest: PaymentRequestType
  pricePayable: number
  systemUuid: string
  allPaymentRequests: PaymentRequestType[]
  index: number
}

const useStyles = makeStyles<OpenSolarThemeType>((theme) => ({
  row: {
    border: `1px solid ${theme.greyLight1}`,
  },
  cellText: {
    fontWeight: 400,
    padding: '12px 0px',
  },
  pausedBadge: {
    color: '#BC1D1C',
    padding: '5px 0 0',
    background: '#F1D7D7',
    borderRadius: 15,
  },
}))

const CashFlowTransactionRow: React.FC<PropTypes> = (props) => {
  const isXs = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
  const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const classes = useStyles()
  const project = useFormState().values
  const currencySymbol = currencySymbolForCountry(project.country_iso2)
  const translate = useTranslate()

  const dateRequested = useMemo(() => {
    if (props.paymentRequest.date_requested) {
      return new Date(props.paymentRequest.date_requested).toLocaleDateString()
    }
    return null
  }, [])

  const dateDue = useMemo(() => {
    if (props.paymentRequest.date_due) {
      return new Date(props.paymentRequest.date_due).toLocaleDateString()
    }
    return null
  }, [])

  const datePaid = useMemo(() => {
    if (props.paymentRequest.date_completed) {
      return new Date(props.paymentRequest.date_completed).toLocaleDateString()
    }
    return null
  }, [])

  const paymentMethod = useMemo(() => {
    switch (props.paymentRequest.payment_method) {
      case 'local_bank_transfer':
        return translate('Local Bank Transfer')
      case 'credit_card_standard':
        return translate('Credit Card - Standard')
      case 'credit_card_premium':
        return translate('Credit Card - Premium')
      case 'credit_card_international':
        return translate('Credit Card - International')
      case 'debit_card_standard':
        return translate('Debit Card - Standard')
      case 'debit_card_premium':
        return translate('Debit Card - Premium')
      case 'debit_card_international':
        return translate('Debit Card - International')
      case 'ach':
        return 'ACH'
      case 'becs_direct_debit':
        return 'BECS'
      case 'apple_google_pay':
        return translate('Apple/Google Pay')
      case 'sepa_credit':
        return translate('Open Banking')
      default:
        if (props.paymentRequest.date_completed) {
          return translate('Offline')
        }
        return null
    }
  }, [props.paymentRequest.payment_method])

  const remindersAreMuted = useMemo(() => {
    return props.paymentRequest?.invoice_email_reminder_status === 'paused' ? true : false
  }, [props.paymentRequest])

  const showSendReminderBadge = useMemo(() => {
    const { status, id, date_due, date_completed } = props.paymentRequest || {}

    if (status === 'payment failed') return true
    if (status === 'cancelled' || status === 'completed') return false
    return id && date_due && !date_completed
  }, [props.paymentRequest])

  if (!props.pricePayable) return null
  return (
    <tr key={props.paymentRequest.id} className={classes.row}>
      {!isMd && (
        <th className={classes.cellText}>
          <PreviewInvoiceLink paymentRequest={props.paymentRequest} />
        </th>
      )}
      <th className={classes.cellText}>{props.paymentRequest.title}</th>
      {!isMd && (
        <th className={classes.cellText}>
          {props.paymentRequest.is_standalone
            ? 'N/A'
            : `${Math.round((props.paymentRequest.amount_requested / props.pricePayable) * 1000) / 10}%`}
        </th>
      )}
      <th className={classes.cellText}>
        <PaymentRequestStatusChip paymentRequest={props.paymentRequest} />
      </th>
      {!isMd && <th className={classes.cellText}>{paymentMethod}</th>}
      {!isXs && <th className={classes.cellText}>{dateRequested}</th>}
      {!isMd && <th className={classes.cellText}>{dateDue}</th>}
      {!isXs && <th className={classes.cellText}>{datePaid}</th>}
      <th className={classes.cellText}>
        {formatCurrencyWithSymbol(props.paymentRequest.amount_requested, currencySymbol)}
      </th>
      <th className={classes.cellText}>
        {showSendReminderBadge && remindersAreMuted && (
          <div className={classes.pausedBadge}>
            <Tooltip title="Automated email reminders currently stopped.">
              <UnsubscribeOutlined />
            </Tooltip>
          </div>
        )}
      </th>
      <th className={classes.cellText}>
        <InvoiceActions
          allPaymentRequests={props.allPaymentRequests}
          index={props.index}
          paymentRequest={props.paymentRequest}
          projectId={project?.id}
          systemUuid={props.systemUuid}
        />
      </th>
    </tr>
  )
}
export default CashFlowTransactionRow
