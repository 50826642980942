import { myEnergySelectors, updateShowTemplateLibraryModal } from 'ducks/myEnergy'
import { orgSelectors } from 'ducks/orgs'
import find from 'lodash/find'
import get from 'lodash/get'
import { useMemo } from 'react'
import { useGetListFromStore, useTranslate } from 'react-admin'
import { useDispatch, useSelector } from 'react-redux'
import { ProposalTemplateTypeEnum } from 'resources/proposalTemplates/types'
import type { ProposalTemplateType } from 'types/proposalTemplate'
import { useFeatureFlag } from 'util/split'
import ClassicTemplateCard from './ClassicTemplateCard'
import DefaultTemplateCard from './DefaultTemplateCard'

export enum TemplateLibraryTypeEnum {
  ProposalV2 = 'proposalV2',
  Classic = 'classic',
}

const useTemplateLibraryModal = () => {
  const translate = useTranslate()
  const dispatch = useDispatch()
  const open = useSelector(myEnergySelectors.getShowTemplateLibraryModal)
  const org = useSelector(orgSelectors.getOrg)
  const enableProposalV2 = useFeatureFlag('enable_sales_machine', 'on')

  const { data: proposalTemplates, loading } = useGetListFromStore<ProposalTemplateType>(
    'proposal_templates',
    undefined,
    undefined,
    {}
  )

  const groupedProposalTemplates = useMemo(() => {
    if (!proposalTemplates) return {}
    // Group into either default or classic proposal templates
    return Object.values(proposalTemplates).reduce((acc, item) => {
      // Check if the type exists in the accumulator object
      if (item.type === ProposalTemplateTypeEnum.proposalV2) {
        acc[TemplateLibraryTypeEnum.ProposalV2] = [...(acc[TemplateLibraryTypeEnum.ProposalV2] || []), item]
      } else {
        acc[TemplateLibraryTypeEnum.Classic] = [...(acc[TemplateLibraryTypeEnum.Classic] || []), item]
      }

      return acc
    }, {})
  }, [proposalTemplates])

  const defaultProposalV2 = useMemo(() => {
    return get(groupedProposalTemplates, `${TemplateLibraryTypeEnum.ProposalV2}.0`)
  }, [groupedProposalTemplates])
  // TODO change to get previous classic template
  const defaultClassic = useMemo(() => {
    const classicProposals = get(groupedProposalTemplates, TemplateLibraryTypeEnum.Classic, [])
    const defaultClassicTemplate = find(classicProposals, (template) => template.is_default)
    // If there are no classic templates available, use empty object to use default proposal template
    if (classicProposals.length === 0) return {}
    return defaultClassicTemplate || classicProposals[0]
  }, [groupedProposalTemplates])

  const allowShowModal = useMemo(() => {
    // don't show modal while waiting for proposal templates to be fetched OR
    // when SM is disabled
    return !loading || Boolean(enableProposalV2)
  }, [enableProposalV2, loading])

  const TEMPLATE_SECTIONS = useMemo(
    () => [
      {
        key: TemplateLibraryTypeEnum.ProposalV2,
        label: translate('%{orgName} Template', { orgName: org?.name }),
        templateCard: DefaultTemplateCard,
        value: defaultProposalV2,
      },
      {
        key: TemplateLibraryTypeEnum.Classic,
        label: translate('Classic Template'),
        templateCard: ClassicTemplateCard,
        value: defaultClassic,
      },
    ],
    [defaultProposalV2, defaultClassic]
  )

  return {
    loading,
    open: open && allowShowModal,
    allowShowModal,
    templateSections: TEMPLATE_SECTIONS,
    setOpen: () => dispatch(updateShowTemplateLibraryModal(true)),
    setClose: () => dispatch(updateShowTemplateLibraryModal(false)),
  }
}

export default useTemplateLibraryModal
