import { MenuItem } from '@material-ui/core'
import { Search } from '@material-ui/icons'
import { permissionsSelectors } from 'ducks/permissions'
import { Button, Select, styled } from 'opensolar-ui'
import React, { useEffect, useMemo, useState } from 'react'
import { AutocompleteInput, ReferenceInput, useTranslate } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { RoleType } from 'types/roles'
import GroupedInput from './GroupedInput'

export const PROJECT_ROLES = [
  { title: 'Assigned Team Member', id: 'assigned_role' },
  { title: 'Installer', id: 'assigned_installer_role' },
  { title: 'Designer', id: 'assigned_designer_role' },
  { title: 'Site Inspector', id: 'assigned_site_inspector_role' },
  { title: 'Salesperson', id: 'assigned_salesperson_role' },
]

const Wrapper = styled('div', { name: 'Wrapper' })({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 30,
  gap: 10,
})

interface PropTypes {
  roleChoices: { title: string; id: string }[]
  assignedTeamIds: number[]
}

const TeamMemberInput: React.FC<PropTypes> = ({ roleChoices, assignedTeamIds }) => {
  const form = useForm()
  const translate = useTranslate()
  const formValues = useFormState().values
  const [newTeamAssignment, setNewTeam] = useState<RoleType | undefined>(undefined)
  const [newRoleAssignment, setNewRole] = useState<string | undefined>(
    !!roleChoices?.length ? roleChoices[0]?.id : undefined
  )
  const { allowCreate } = useSelector(permissionsSelectors.getProjectPermissionByKey('manage_assigned_users'))

  const handleAssignment = () => {
    if (newRoleAssignment && newTeamAssignment) {
      form.change('internalTeam', [
        ...formValues.internalTeam,
        { ...newTeamAssignment, projectRoleId: newRoleAssignment },
      ])
      form.change('selected_member', undefined)
      const filteredRoles = roleChoices.filter((x) => x.id !== newRoleAssignment)
      setNewRole(!!filteredRoles.length ? filteredRoles[0].id : undefined)
    }
  }

  useEffect(() => {
    if (!newRoleAssignment || (!!roleChoices.length && !roleChoices?.map((x) => x.id)?.includes(newRoleAssignment))) {
      setNewRole(roleChoices[0]?.id)
    }
  }, [newRoleAssignment, roleChoices])

  return (
    <Wrapper>
      <GroupedInput>
        <ReferenceInput
          label={''}
          allowEmpty
          variant="outlined"
          source={'selected_member'}
          reference="roles___aliases"
          resource="roles"
          sort={{ field: 'name', order: 'ASC' }}
        >
          <CustomAutoCompleteInput
            source={'selected_member'}
            optionValue="url"
            optionText="display"
            options={{
              containerStyle: { width: '100%' },
              InputProps: {
                startAdornment: <Search />,
                placeholder: translate('Add a team member'),
                fullWidth: true,
                label: false,
                style: { margin: 0 },
                disabled: !allowCreate,
              },
            }}
            onChange={(value) => {
              setNewTeam(value)
            }}
            assignedTeamIds={assignedTeamIds}
          />
        </ReferenceInput>
        <Select
          value={newRoleAssignment}
          variant="outlined"
          onChange={(e: any) => {
            setNewRole(e.target.value)
          }}
          disabled={!allowCreate}
        >
          {roleChoices?.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.title}
            </MenuItem>
          ))}
        </Select>
      </GroupedInput>

      <Button
        disabled={!newTeamAssignment || !newRoleAssignment}
        variant="contained"
        color="primary"
        onClick={handleAssignment}
      >
        {translate('Assign')}
      </Button>
    </Wrapper>
  )
}
const CustomAutoCompleteInput = (props) => {
  const { choices, input, onChange, optionValue } = props
  const handleChange = (value) => {
    input.onChange(value)
    if (onChange) {
      const findItem = choices.find((item) => (optionValue ? item[optionValue] : item.url) === value)
      if (findItem) onChange(findItem)
    }
  }
  const inputProps = useMemo(() => ({ ...input, onChange: handleChange }), [input])
  return <AutocompleteInput {...props} choices={choices} input={inputProps} />
}

export default TeamMemberInput
