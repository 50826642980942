import { authSelectors } from 'ducks/auth'
import { usePublicFeatureConfig } from 'hooks/usePublicFeatureConfig'
import lodash from 'lodash'
import { ComponentVersionsInherit } from 'opensolar-ui'
import React, { useEffect, useState } from 'react'
import { useNotify } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'
import { ProjectType } from 'types/projects'
import { doNotTranslate } from 'util/misc'
import { Scenario } from '../../../manage/scaffoldTile/constants'
import {
  canCalculateScaffoldCost,
  getTicketDataForPVFZendeskTicket,
  initializeEmptyPvfData,
  initializePvfCustomData,
} from '../../../manage/scaffoldTile/utils'
import ScaffoldCard from './ScaffoldCard'
import {
  calculateScaffoldingCost,
  cancelPvfOrder,
  confirmPvfOrder,
  createPvfQuote,
  fetchWalletData,
  uploadFile,
} from './utils'

const PVFScaffold: React.FunctionComponent = () => {
  const orgId = useSelector(authSelectors.getCurrentOrg)?.id
  const project = useFormState().values as ProjectType
  const projectId = project.id
  const form = useForm()
  const notify = useNotify()
  const customData = project?.custom_data?.scaffolding
  const featureConfig = usePublicFeatureConfig('pvf_scaffolding_cost')

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  useEffect(() => {
    const getWalletData = async () => {
      try {
        const walletData = await fetchWalletData(orgId)
        const pvfItem = walletData.find((walletProduct) => walletProduct.title === 'PVF')
        const isPvfEnabled = pvfItem && pvfItem.is_active

        const initializePvfData = initializePvfCustomData(project)
        initializePvfData.is_pvf_enabled = isPvfEnabled
        if (project?.contacts_data && project?.contacts_data.length > 0) {
          initializePvfData.form_data.customer_first_name = project.contacts_data[0]?.first_name ?? ''
          initializePvfData.form_data.customer_last_name = project.contacts_data[0]?.family_name ?? ''
          initializePvfData.form_data.customer_phone = project.contacts_data[0]?.phone ?? ''
          initializePvfData.form_data.customer_email = project.contacts_data[0]?.email ?? ''
        }

        if (!lodash.isEqual(customData, initializePvfData)) {
          const currentValues = form.getState().values
          const updatedValues = {
            ...currentValues,
            custom_data: {
              ...currentValues.custom_data,
              scaffolding: initializePvfData,
            },
          }

          form.reset(updatedValues) // Reset only with updated fields
        }
      } catch (err) {
        notify(doNotTranslate(`Error: ${err}`))
        console.log('Error:', err)
      }
    }
    getWalletData()
  }, [])
  const calculateCost = async () => {
    // check if the scaffold cost can be calculated on the spot or not
    if (!canCalculateScaffoldCost(customData, featureConfig)) {
      form.batch(() => {
        form.change('custom_data.scaffolding.estimated_cost', null)
        form.change('custom_data.scaffolding.access_through_house_cost', null)
        form.change('custom_data.scaffolding.pavement_cost', null)
        form.change('custom_data.scaffolding.scenario', Scenario.CREATE_QUOTE)
      })
      return null
    }
    try {
      const estimatedCost = await calculateScaffoldingCost(customData)
      let accessThroughHouseCost: number | null = null
      let pavementCost: number | null = null
      if (customData.form_data.scaffold_properties.require_access_through_house_building === 'Yes') {
        accessThroughHouseCost = 100
      }
      if (customData.form_data.scaffold_properties?.scaffold_on_pavement_or_road === 'Yes') {
        pavementCost = 100
      }
      const updatedCustomData = {
        ...customData,
        estimated_cost: estimatedCost,
        scenario: Scenario.CREATE_QUOTE,
        access_through_house_cost: accessThroughHouseCost,
        pavement_cost: pavementCost,
      }
      form.change('custom_data.scaffolding', updatedCustomData)
    } catch (err) {
      notify(doNotTranslate(`${err}`))
      console.log('err', err)
    }
  }

  const createQuote = () => {
    setIsSubmitting(true)
    // 1. upload all the images/attachments
    const uploadedFileTokens: string[] = []
    const files = [
      ...(customData?.form_data?.site_photos || []),
      ...(customData?.form_data?.site_connecting_photos || []),
    ]
    const uploadPromises = files.map((photo, index) =>
      uploadFile(photo, index, orgId, projectId)
        .then((res) => {
          uploadedFileTokens.push(res.token)
        })
        .catch((err) => {
          notify(doNotTranslate(`${err}`))
        })
    )

    // Wait for all uploads to complete
    Promise.all(uploadPromises)
      .then(() => {
        // 2. create a ticket
        if (!featureConfig?.['zendesk_field_mapping']) {
          throw new Error('zendesk_field_mapping is missing in featureConfig')
        }
        return createPvfQuote(
          orgId,
          projectId,
          getTicketDataForPVFZendeskTicket(customData, project, uploadedFileTokens, orgId, featureConfig)
        )
      })
      .then((res) => {
        const updatedCustomData = {
          ...customData,
          rag_status: 'Green',
          order_submission_timestamp: new Date().toISOString(), // timestamp of the order submission
          zendesk_ticket_id: res.ticket_id,
          draft: false,
          scenario: Scenario.MAKE_PAYMENT,
          booked: customData?.estimated_cost > 0 ? 'order_confirmed' : 'quoting',
        }
        form.mutators.updateField('custom_data.scaffolding', updatedCustomData)
        setIsSubmitting(false)
        if (customData?.estimated_cost > 0) {
          notify(doNotTranslate('PVF Scaffold Order Created Successfully.'))
        } else {
          notify(doNotTranslate('PVF Quote Requested Successfully.'))
        }
      })
      .catch((err) => {
        notify(doNotTranslate(`Error creating ticket: ${err}`))
        console.log('Error creating ticket', err)
      })
      .catch((err) => {
        notify(doNotTranslate(`Error uploading images: ${err}`))
        console.log('Error uploading images:', err)
      })
  }

  const cancelOrder = async () => {
    try {
      const result = await cancelPvfOrder(orgId, projectId, customData, featureConfig)
      if (customData) {
        const updatedCustomData = {
          ...customData,
          scenario: Scenario.CANCELLED,
        }
        form.change('custom_data.scaffolding', updatedCustomData)
        if (customData?.estimated_cost > 0) {
          notify(doNotTranslate('PVF Scaffold Order Cancelled Successfully.'))
        } else {
          notify(doNotTranslate('PVF Quote Cancelled Successfully.'))
        }
      }
    } catch (err) {
      notify(doNotTranslate(err))
    }
  }
  const confirmOrder = async () => {
    try {
      const result = await confirmPvfOrder(orgId, projectId, customData, featureConfig)
      if (customData) {
        const updatedCustomData = {
          ...customData,
          booked: 'order_confirmed',
          order_submission_timestamp: new Date().toISOString(), // update the timestamp of the order submission
        }
        form.change('custom_data.scaffolding', updatedCustomData)
        notify(doNotTranslate('PVF Scaffold Order Confirmed Successfully.'))
      }
    } catch (err) {
      notify(doNotTranslate(err))
    }
  }
  const initializeCustomData = () => {
    const isPvfEnabled = customData?.is_pvf_enabled
    const initialCustomData = initializeEmptyPvfData(project)
    initialCustomData.is_pvf_enabled = isPvfEnabled
    if (project?.contacts_data && project?.contacts_data?.length > 0) {
      initialCustomData.form_data.customer_first_name = project?.contacts_data[0]?.first_name ?? ''
      initialCustomData.form_data.customer_last_name = project?.contacts_data[0]?.family_name ?? ''
      initialCustomData.form_data.customer_phone = project?.contacts_data[0]?.phone ?? ''
      initialCustomData.form_data.customer_email = project?.contacts_data[0]?.email ?? ''
    }
    form.change('custom_data.scaffolding', initialCustomData)
  }

  return (
    <>
      {!customData || (customData && Object.keys(customData).length === 0) ? (
        <></>
      ) : (
        <ComponentVersionsInherit versions={{ radio: 2 }}>
          <ScaffoldCard
            calculateCost={calculateCost}
            createQuote={createQuote}
            cancelOrder={cancelOrder}
            confirmOrder={confirmOrder}
            initializeCustomData={initializeCustomData}
            isSubmitting={isSubmitting}
            project={project}
          />
        </ComponentVersionsInherit>
      )}
    </>
  )
}

export default PVFScaffold
