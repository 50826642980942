import AccountBoxIcon from '@material-ui/icons/AccountBoxOutlined'
import DashboardIcon from '@material-ui/icons/DashboardOutlined'
import DomainOutlinedIcon from '@material-ui/icons/DomainOutlined'
import EventNoteIcon from '@material-ui/icons/EventNoteOutlined'
import LocalAtmOutlinedIcon from '@material-ui/icons/LocalAtmOutlined'
import SecurityIcon from '@material-ui/icons/SecurityOutlined'
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasketOutlined'
import ViewComfyOutlinedIcon from '@material-ui/icons/ViewComfyOutlined'
import WidgetsIcon from '@material-ui/icons/WidgetsOutlined'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { permissionsSelectors } from 'ducks/permissions'
import { setQuickStartAction } from 'ducks/quickStart'
import { viewModeSelectors } from 'ducks/viewMode'
import UiContainer from 'elements/UiContainer'
import { HandshakeOutlineIcon } from 'opensolar-ui'
import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import appStorage from 'storage/appStorage'
import { getRoleFromState, toCapitalise } from 'util/misc'
import { useFeatureFlag } from 'util/split'
import CategoryItem from './elements/CategoryItem'
import FoldArrows from './elements/FoldArrows'
import MenuItemLink from './elements/MenuItemLink'
import getDefaultExpanded from './hooks/getDefaultExpanded'

const uiKey = 'control.'

// Bad, to add id in menu data config
const generateIdForMenuItem = (title) => {
  const parts = title.split(' ').map((str) => toCapitalise(str))
  return 'menuItem' + parts.join('')
}

const getMenuData = (orgId, enableSegen, isNearmapOrg, enableCashFlow) => [
  {
    icon: <DomainOutlinedIcon />,
    title: 'Company',
    uiKey: uiKey + 'company',
    cards: [
      // {
      //   title: 'Quick Start Guide',
      //   url: '/quick-start',
      // },
      {
        title: 'Business Info',
        url: '/content/' + orgId,
        uiKey: uiKey + 'company.business_info',
      },
      {
        title: 'Team',
        url: '/roles',
        uiKey: uiKey + 'company.team',
      },
      {
        title: 'Custom Roles',
        url: '/permissions_role',
        requireAdmin: true,
        uiKey: uiKey + 'company.custom_roles',
      },
      {
        title: 'Business Process',
        url: '/business-process',
        uiKey: uiKey + 'company.business_process',
        cards: [
          {
            title: 'Project Workflows',
            url: '/workflows',
          },
          {
            title: 'Project Tags',
            url: '/tags',
          },
          {
            title: 'Custom Fields',
            url: '/custom_fields',
          },
          {
            title: 'Custom Forms',
            url: '/custom_forms',
          },
        ],
      },
      {
        title: 'Settings',
        url: '/orgs/' + orgId,
        uiKey: uiKey + 'company.settings',
      },
      {
        title: 'Teams',
        url: '/connected_orgs_home',
        uiKey: uiKey + 'company.connected_orgs',
        cards: [
          {
            title: 'Connected Orgs',
            url: '/connected_orgs/',
          },
          {
            title: 'Sharing Permissions',
            url: '/connected_orgs_roles',
          },
        ],
      },
    ].concat(
      isNearmapOrg
        ? []
        : [
            {
              title: 'Two-Factor Authentication',
              url: '/configure-mfa',
              requireAdmin: true,
            },
          ]
    ),
  },
  {
    icon: <LocalAtmOutlinedIcon />,
    title: 'Pricing & Payments',
    uiKey: uiKey + 'pricing_and_payments',
    cards: [
      {
        title: 'Price Schemes',
        url: '/pricing_schemes',
        uiKey: uiKey + 'pricing_and_payments.pricing_schemes',
      },
      {
        title: 'Cost Schemes',
        url: '/costings',
        uiKey: uiKey + 'pricing_and_payments.cost_information',
      },
      {
        title: 'Adders Library',
        url: '/adders',
        uiKey: uiKey + 'pricing_and_payments.adders',
      },
      {
        title: 'Payment Options',
        url: '/payment_options',
        uiKey: uiKey + 'pricing_and_payments.payment_options',
      },
      {
        title: 'Commissions',
        url: '/commissions',
        uiKey: uiKey + 'pricing_and_payments.commissions',
      },
    ].concat(
      appStorage.getString('feature_preview')
        ? [
            {
              title: 'Upload & Import CSV',
              url: '/csv_upload?model=projects',
              uiKey: uiKey + 'pricing_and_payments.csv_upload',
            },
          ]
        : []
    ),
  },
  {
    icon: <ViewComfyOutlinedIcon style={{ transform: 'rotate(45deg)' }} />,
    title: 'Design & Hardware',
    uiKey: uiKey + 'design_and_hardware',
    cards: [
      {
        title: 'Modules',
        url: '/component_module_activations',
        uiKey: uiKey + 'design_and_hardware.component_module_activations',
      },
      {
        title: 'Inverters',
        url: '/component_inverter_activations',
        uiKey: uiKey + 'design_and_hardware.component_inverter_activations',
      },
      {
        title: 'Batteries',
        url: '/component_battery_activations',
        uiKey: uiKey + 'design_and_hardware.component_battery_activations',
      },
      {
        title: 'Other Components',
        url: '/component_other_activations',
        uiKey: uiKey + 'design_and_hardware.component_other_activations',
      },
      {
        title: 'Setbacks & Design Settings',
        url: '/project_configurations',
        uiKey: uiKey + 'design_and_hardware.setbacks_and_design_settings',
      },
      {
        title: 'Battery Control Schemes',
        url: '/battery_schemes',
        uiKey: uiKey + 'design_and_hardware.battery_schemes',
      },
    ],
  },
  {
    icon: <ShoppingBasketIcon />,
    title: 'Purchase Experience',
    uiKey: uiKey + 'purchase_experience',
    cards: [
      {
        title: 'Proposal Template',
        url: '/proposal_templates',
        uiKey: uiKey + 'purchase_experience.proposal_templates',
      },
      {
        title: 'Contract Template',
        url: '/contracts',
        uiKey: uiKey + 'purchase_experience.contracts',
      },
      {
        title: 'Checkout Experience',
        url: '/customer_checkout/' + orgId,
        uiKey: uiKey + 'purchase_experience.checkout_exp',
      },
      {
        title: 'Case Studies',
        url: '/testimonials',
        uiKey: uiKey + 'purchase_experience.case_studies',
      },
    ],
  },
  {
    icon: <EventNoteIcon />,
    title: 'Customers',
    uiKey: uiKey + 'customers',
    cards: [
      {
        title: 'Contacts',
        url: '/contacts',
        uiKey: uiKey + 'customers.contacts',
      },
      {
        title: 'Transactions',
        url: '/transactions',
        uiKey: uiKey + 'customers.transactions',
      },
      {
        title: 'Files',
        url: '/private_files',
        uiKey: uiKey + 'customers.private_files',
      },
      {
        title: 'Proposal Viewers',
        url: '/proposal_viewers',
        uiKey: uiKey + 'customers.proposal_viewers',
      },
      {
        title: 'Activities',
        url: '/events',
        uiKey: uiKey + 'customers.activities',
      },
    ],
  },
  {
    icon: <WidgetsIcon />,
    title: 'Other',
    uiKey: uiKey + 'other',
    cards: [
      {
        title: 'Utility Tariffs',
        url: '/utility_tariffs',
        uiKey: uiKey + 'other.utility_tariffs',
      },
      {
        title: 'Incentives',
        url: '/incentives',
        uiKey: uiKey + 'other.incentives',
      },
      {
        title: 'Integrations & API Keys',
        url: '/external_api_keys/' + orgId,
        uiKey: uiKey + 'other.integrations',
      },
      {
        title: 'Your Wallet',
        url: '/wallets',
        uiKey: uiKey + 'other.wallets',
      },
      {
        title: 'Connect',
        url: '/wallet_products',
        uiKey: uiKey + 'other.wallet_products',
      },
      {
        title: 'Document Templates',
        url: '/document_templates',
        uiKey: uiKey + 'other.document_templates',
      },
      {
        title: 'Lead Capture',
        url: '/lead_capture_forms',
        uiKey: uiKey + 'other.lead_capture_forms',
      },
      {
        title: 'Sales Territories',
        url: '/sales_territories',
        uiKey: uiKey + 'other.sales_territories',
      },
      {
        title: 'Public Files',
        url: '/public_files',
        uiKey: uiKey + 'other.public_files',
      },
    ],
  },
  {
    icon: <HandshakeOutlineIcon />,
    title: 'Partner Features',
    uiKey: uiKey + 'partner_features',
    requireExhibit: true,
    cards: [
      {
        title: 'Showcase Content',
        url: '/component_content',
        requireAdmin: true,
        uiKey: uiKey + 'partner_features.showcase_content',
      },
    ],
  },

  {
    icon: <AccountBoxIcon />,
    title: 'Staff',
    requireStaff: true,
    uiKey: uiKey + 'staff',
    cards: [
      {
        title: 'Manufacturers',
        url: '/manufacturers',
      },
      {
        title: 'Modules',
        url: '/component_modules',
      },
      {
        title: 'Inverters',
        url: '/component_inverters',
      },
      {
        title: 'Batteries',
        url: '/component_batteries',
      },
      {
        title: 'Other Components',
        url: '/component_others',
      },
      {
        title: 'Utilities',
        url: '/utilities',
      },
      {
        title: 'Distributors',
        url: '/distributors',
      },
      {
        title: 'CSV Upload History',
        url: '/csv_file_upload_requests',
      },
      {
        title: 'Global Orgs',
        url: '/global_orgs',
      },
      {
        title: 'Global Roles',
        url: '/global_roles',
      },
      {
        title: 'Global Projects',
        url: '/global_projects',
      },
      {
        title: 'Staff Reports',
        url: '/staff_reports',
      },
      {
        title: 'Feature Configs',
        url: '/config_save',
      },
      {
        title: 'Structured Configs',
        url: '/structs',
      },
      {
        title: 'Landing Pages',
        url: '/landing_pages',
      },
      {
        title: 'UI Library',
        url: '/ui_library',
      },
      {
        title: 'Payment Methods',
        url: '/payment_methods',
      },
    ],
  },
  {
    icon: <SecurityIcon />,
    title: 'SuperUser',
    requireSuperUser: true,
    uiKey: uiKey + 'super_user',
    cards: [
      {
        title: 'Global Users',
        url: '/global_users',
      },
      {
        title: 'Global Contacts',
        url: '/global_contacts',
      },
      {
        title: 'Global Wallet Activity',
        url: '/global_wallet_transactions',
      },
      {
        title: 'Wallet Products',
        url: '/superuser_wallet_products',
      },
      {
        title: 'Finance Integrations',
        url: '/finance_partners',
      },
      {
        title: 'Payment Factors',
        url: '/payment_factors',
      },
    ],
  },
]

const styles = {
  main: {
    display: 'flex',
    position: 'relative',
    padding: '10px 0',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    height: '100%',
    color: '#4d4d4d',
    boxShadow: 'rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px',
    backgroundColor: '#ffffff',
    overflow: 'inherit',
  },
  title: {
    fontSize: 16,
    verticalAlign: 'middle',
    paddingLeft: 5,
  },
  quickStart: {
    wrapper: {
      color: '#ffffff',
      background: '#1890ff',
      borderRadius: '4px',
      padding: 5,
    },
  },
}

const DashboardButton = () => {
  const history = useHistory()
  return (
    <UiContainer uiKey={uiKey + 'dashboard'}>
      <CategoryItem
        Icon={<DashboardIcon />}
        title={'Dashboard'}
        id={'menuItemControlDashboard'}
        handleClick={() => history.push('/control')}
      />
    </UiContainer>
  )
}

const MenuList = ({ category, onMenuTap, accessRightsSetting }) => {
  const { pathname } = useLocation()
  const [open, setOpen] = React.useState(() => getDefaultExpanded({ config: category, pathname }))
  const isOsLite = useSelector(viewModeSelectors.isUserLite)

  useEffect(() => {
    if (pathname === '/control' && isOsLite) {
      if (category?.title === 'Company') {
        setOpen(true)
      }
    }
  }, [])

  const handleClick = () => {
    setOpen(!open)
  }
  return (
    <div>
      <CategoryItem
        Icon={category.icon}
        title={category.title}
        id={generateIdForMenuItem(category.title)}
        handleClick={handleClick}
        ArrowIconNode={<FoldArrows open={open} handleClick={handleClick} />}
      />
      {open && <ListItems category={category} onMenuTap={onMenuTap} accessRightsSetting={accessRightsSetting} />}
    </div>
  )
}
const NestedList = ({ category, onMenuTap, accessRightsSetting }) => {
  const { pathname } = useLocation()
  const [open, setOpen] = useState(() => getDefaultExpanded({ config: category, pathname }))

  const handleNestedListToggle = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setOpen((open) => !open)
  }
  return (
    <div>
      <MenuItemLink
        title={category.title}
        url={category.url}
        id={generateIdForMenuItem(category.title)}
        ArrowIconNode={<FoldArrows open={open} handleClick={handleNestedListToggle} />}
      />
      {open && (
        <div style={{ paddingLeft: 30 }}>
          <ListItems category={category} onMenuTap={onMenuTap} accessRightsSetting={accessRightsSetting} />
        </div>
      )}
    </div>
  )
}

const WALLET_ROUTES = ['/wallets', '/wallet_line_items', '/wallet_products']
// bussiness_info, team etc...
const ListItems = ({ category, onMenuTap, accessRightsSetting }) => {
  const isStaff = useSelector(authSelectors.getIsStaff)
  const enableWallet = useFeatureFlag('os_wallet', 'on')
  const enableCustomForms = useFeatureFlag('custom_forms', 'on') || isStaff
  const enableConnectedOrgs = useFeatureFlag('connected_orgs', 'on') || isStaff
  const isAdmin = useSelector((state) => getRoleFromState(state)?.is_admin === true)

  const exhibitIsEnabled = useSelector(orgSelectors.getEnableShowcase)
  const walletIsEnabled = useSelector(authSelectors.getShowWallet) && enableWallet

  if (!hasAssessRight(category.requireAccessRight, accessRightsSetting)) {
    return null
  }
  return (
    <>
      {category.cards.map((childComponent) => {
        // temporary hard code hack to limit wallet pages to AU, NZ, and US
        if (WALLET_ROUTES.includes(childComponent.url) && (!walletIsEnabled || !isAdmin)) {
          return null
        }
        if (
          !enableConnectedOrgs &&
          ['/connected_orgs/', '/connected_orgs_roles', '/connected_orgs_home'].includes(childComponent.url)
        )
          return null
        if (!enableCustomForms && childComponent.url === '/custom_forms') return null
        if (childComponent.requireExhibit && !exhibitIsEnabled) {
          return null
        } else if (childComponent.requireAdmin && !isAdmin) {
          return null
        } else if (!hasAssessRight(childComponent.requireAccessRight, accessRightsSetting)) {
          return null
        } else {
          return childComponent.cards ? (
            <UiContainer uiKey={childComponent?.uiKey} key={childComponent.title}>
              <NestedList
                key={childComponent.title}
                onMenuTap={onMenuTap}
                category={childComponent}
                accessRightsSetting={accessRightsSetting}
              />
            </UiContainer>
          ) : (
            <UiContainer uiKey={childComponent?.uiKey} key={childComponent.title}>
              <MenuItemLink
                key={childComponent.title}
                id={generateIdForMenuItem(childComponent.title)}
                title={childComponent.title}
                url={childComponent.url}
              />
            </UiContainer>
          )
        }
      })}
    </>
  )
}

const filterSectionWithoutCards = (section, accessRightsSetting, isAdmin) => {
  if (section && section.cards) {
    const availableCards = section.cards
      .map(
        (card) =>
          hasAssessRight(card.requireAccessRight, accessRightsSetting) && (!card.requireAdmin || isAdmin === true)
      )
      .filter((val) => !!val)
    return availableCards.length > 0
  } else {
    return true
  }
}

const hasAssessRight = (key, accessRightsSetting) => {
  if (!key) {
    return true
  } else if (accessRightsSetting && accessRightsSetting[key] && accessRightsSetting[key]['view']) {
    return true
  } else {
    return false
  }
}
const ControlMenu = (props) => {
  const accessRightsSetting = useSelector(permissionsSelectors.getPermissionsSetting)
  const { onMenuTap, orgId, exhibitIsEnabled, enableSegen, isNearmapOrg, isStaff, isAdmin, isSuperUser } = props
  const menuData =
    (orgId &&
      getMenuData(orgId, enableSegen, isNearmapOrg)
        .filter((section) => !section.requireExhibit || exhibitIsEnabled)
        .filter((section) => !section.requireStaff || isStaff)
        .filter((section) => filterSectionWithoutCards(section, accessRightsSetting, isAdmin))
        .filter((section) => !section.requireSuperUser || isSuperUser)) ||
    []
  return (
    <UiContainer uiKey={uiKey} style={styles.main}>
      <DashboardButton />
      {
        <div>
          {menuData.map((category, categoryIndex) => (
            <UiContainer uiKey={category?.uiKey} key={category.title}>
              <MenuList
                key={category.title}
                category={category}
                onMenuTap={onMenuTap}
                accessRightsSetting={accessRightsSetting}
              />
            </UiContainer>
          ))}
        </div>
      }
    </UiContainer>
  )
}

ControlMenu.defaultProps = {
  onMenuTap: () => null,
}

const isStaff = (state) => state.auth && state.auth.is_staff === true
const isSuperUser = (state) => state.auth && state.auth.is_superuser === true

const mapStateToProps = (state) => ({
  orgId: state.auth ? state.auth.org_id : null,
  isStaff: isStaff(state),
  isSuperUser: isSuperUser(state),
  exhibitIsEnabled: orgSelectors.getEnableShowcase(state),
  enableSegen: orgSelectors.getEnableSegen(state),
  isNearmapOrg: orgSelectors.getIsNearmapOrg(state),
  isAdmin: getRoleFromState(state) && getRoleFromState(state).is_admin === true,
})

export default connect(mapStateToProps, { updateQuickStartProcess: setQuickStartAction })(ControlMenu)
