import { makeStyles } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { permissionsSelectors } from 'ducks/permissions'
import { useGetFujiTourNextStepQuery } from 'persistentContent/tours/fuji/useGetFUJITourSteps'
import AlertsPopover from 'projectSections/sections/common/AlertsPopover'
import { useCallback } from 'react'
import { useTranslate } from 'react-admin'
import { useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { useFeatureFlag } from 'util/split'

export const Navigation = ({ layout }) => {
  const accessRightsSetting = useSelector(permissionsSelectors.getProjectPermissionsSetting)
  const projectId = useSelector((state) => state.projectId)
  const { tour, step } = useSelector((state) => state.tour)
  const translate = useTranslate()
  const nextStep = useGetFujiTourNextStepQuery(step)
  const fujiTourQuery = tour === 'fuji' ? nextStep : ''
  const enableSingleLineDiagram = useFeatureFlag('single_line_diagram', 'on')
  const paymentsTabEnabled = useFeatureFlag('payments_tab', 'on')
  const hardwareTabEnabled = useFeatureFlag('hardware_3_0_projects_tab', 'on')
  const enableProposalV2 = useFeatureFlag('enable_sales_machine', 'on')

  return (
    <div
      style={{
        margin: '0 auto',
        overflowX: 'visible',
        overflowY: 'visible',
        display: 'flex',
        width: '100%',
        maxWidth: layout === 3 ? 480 : 700,
        minWidth: layout === 3 ? 403 : null,
        justifyContent: 'space-evenly',
        height: '100%',
        alignItems: 'center',
      }}
    >
      <StyledNavLink
        name="info"
        layout={layout}
        label={translate('Info')}
        redirect={`/projects/${projectId}/info` + fujiTourQuery}
      />
      <StyledNavLink
        name="energy"
        layout={layout}
        label={translate('Energy')}
        redirect={`/projects/${projectId}/energy` + fujiTourQuery}
        onClick={() => {
          logAmplitudeEvent('energy_tab_clicked')
        }}
      />
      {!!accessRightsSetting?.design?.view && (
        <StyledNavLink
          name="design"
          layout={layout}
          label={translate('Design')}
          redirect={`/projects/${projectId}/design` + fujiTourQuery}
        />
      )}
      {hardwareTabEnabled && (
        <StyledNavLink
          name="hardware"
          layout={layout}
          label={translate('Hardware')}
          redirect={`/projects/${projectId}/hardware` + fujiTourQuery}
          id="hardware-tab-nav-btn"
        />
      )}
      {paymentsTabEnabled && !!accessRightsSetting?.project_payments?.view && (
        <StyledNavLink
          name="payments"
          layout={layout}
          label={translate('Payments')}
          redirect={`/projects/${projectId}/payments` + fujiTourQuery}
          id="payments-tab-nav-btn"
        />
      )}
      {!!accessRightsSetting?.proposal?.view && (
        <StyledNavLink
          layout={layout}
          name="online-proposal"
          label={translate(enableProposalV2 ? 'Proposal' : 'Online Proposal')}
          redirect={`/projects/${projectId}/proposal` + fujiTourQuery}
        />
      )}
      {!enableProposalV2 && !!accessRightsSetting?.proposal?.view && (
        <StyledNavLink
          name="pdf-proposal"
          layout={layout}
          label={translate('PDF Proposal')}
          redirect={`/projects/${projectId}/preview`}
        />
      )}
      {!!accessRightsSetting?.manage?.view && (
        <StyledNavLink
          name="manage"
          layout={layout}
          label={translate('Manage')}
          redirect={`/projects/${projectId}/manage`}
        />
      )}
      {!!accessRightsSetting?.design?.view && !!accessRightsSetting?.sld?.create && enableSingleLineDiagram && (
        <StyledNavLink name="sld" layout={layout} label={translate('SLD')} redirect={`/projects/${projectId}/sld`} />
      )}
    </div>
  )
}

const useStyles = makeStyles((theme) => ({
  text: {
    '& a:link': {
      color: 'rgba(0,0,0,0.54)',
    },
    '& a:hover': {
      color: 'rgba(0,0,0)',
    },
  },
}))

const StyledNavLink = ({ redirect, disabled, label, name, layout, onClick }) => {
  const form = useForm()
  const classes = useStyles()

  const isPreview = useSelector((state) => state.project?.section === 'preview') && name === 'online-proposal'
  // const projectErrors = useSelector((state) => state.project.errors) || []
  // const { errors, submitErrors } = form.getState()

  // const hasWarning = getWarningsByPage(
  //   projectErrors.filter((x) => x.severity === 'warning'),
  //   name
  // )
  // const hasError = getErrorsByPage(errors, submitErrors, name) || getErrorsForDesignPage(projectErrors, name)
  const handleClick = useCallback(
    (e) => {
      if (disabled) {
        e.preventDefault()
      } else {
        form.mutators.recordFormDirtyFields()
      }
    },
    [disabled]
  )
  return (
    <div style={{ display: 'flex' }}>
      <div style={{ display: 'flex', minHeight: '100%' }} className={classes.text} onClick={handleClick}>
        <NavLink
          style={{ textDecoration: 'none', padding: '10px 0' }}
          id={'subheader-' + name}
          activeStyle={{ fontWeight: 500, color: 'rgb(0,0,0,0.87)', pointerEvents: isPreview ? 'auto' : 'none' }}
          isActive={(match) => isPreview ? true : match}
          to={redirect}
          onClick={onClick}
        >
          <span style={{ fontSize: layout === 3 || layout === 2 ? 12 : 14 }}>{label}</span>
        </NavLink>
      </div>
      <AlertsPopover section={name} />
    </div>
  )
}
