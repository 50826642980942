import { Grid } from '@material-ui/core'
import SavingDiscountTag from 'pages/ordering/elements/SavingDiscountTag'
import { OpenSolarThemeType } from 'Themes'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import { formatCurrencyWithSymbol } from 'util/misc'

const useStyles = makeOpenSolarStyles((theme: OpenSolarThemeType) => ({
  priceAfterDiscount: {
    fontSize: '20px',
    color: theme.secondaryColor,
    marginTop: '2px',
  },
  originalPrice: {
    fontSize: '14px',
    textDecoration: 'line-through',
  },
  tag: {},
}))

const PriceFigureWithDiscount = ({
  currencySymbol,
  priceAfterDiscount,
  originalPrice,
}: {
  currencySymbol: string
  priceAfterDiscount: number
  originalPrice: number
}) => {
  const classes = useStyles()
  return (
    <Grid container xs={12}>
      <Grid container spacing={1}>
        <Grid item className={classes.priceAfterDiscount}>{`${formatCurrencyWithSymbol(
          priceAfterDiscount,
          currencySymbol
        )}`}</Grid>

        <Grid item alignContent="center" alignItems="center">
          <span> Was </span>
          <span className={classes.originalPrice}>
            {` ${formatCurrencyWithSymbol(originalPrice, currencySymbol)}`}{' '}
          </span>
        </Grid>
        <Grid item className={classes.tag}>
          <SavingDiscountTag currencySymbol={currencySymbol} value={originalPrice - priceAfterDiscount} />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PriceFigureWithDiscount
