import { authSelectors } from 'ducks/auth'
import { SponsoredContentType, useSponsoredContentStructs } from 'elements/ads/useSponsoredContentStructs'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'

const useAvailableSponsoredContents = () => {
  const org = useSelector(authSelectors.getCurrentOrg)
  const orgCountry = useSelector(authSelectors.getCurrentOrgCountry) || ''

  const { structs, loadState } = useSponsoredContentStructs()

  return useMemo(() => {
    const sponsoredContents: SponsoredContentType[] = []

    structs.forEach((struct) => {
      sponsoredContents.push(struct.data)
    })

    return {
      structs,
      loadState,
      sponsoredContents,
    }
  }, [org, orgCountry, structs, loadState])
}

export default useAvailableSponsoredContents
