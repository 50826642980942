import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMoreOutlined'
import classNames from 'classnames'
import { styled } from 'opensolar-ui'
import { BooleanInput } from 'ra-ui-materialui'
import React, { ReactNode, useEffect, useMemo, useState } from 'react'
import { useForm, useFormState } from 'react-final-form'

interface StyleProps {
  disableGutters?: boolean
}

const useStyles = makeStyles((theme) => ({
  root: (props: StyleProps) =>
    props.disableGutters
      ? {
          margin: '0px !important',
        }
      : {},
  summaryRoot: (props: StyleProps) =>
    props.disableGutters
      ? {
          padding: '0px',
        }
      : {},
  summaryIcon: (props: StyleProps) =>
    props.disableGutters
      ? {
          marginRight: '0px',
        }
      : {},
  content: (props: StyleProps) => ({
    alignItems: 'center',
    width: '100%',
    ...(props.disableGutters && {
      margin: '0px !important',
      padding: '0px',
    }),
  }),
  headingTitle: {
    margin: '0 10px',
  },
  disabled: {
    pointerEvents: 'none',
    cursor: 'not-allowed',
  },
  hidden: {
    position: 'relative',
  },
  detail: {},
}))

type BasePropTypes = {
  className?: string
  defaultExpanded?: boolean
  detailsClassName?: string
  disableGutters?: boolean
  disabled?: boolean
  expanded?: boolean
  elevation?: number
  lazyRender?: boolean
  name: string
  onChange?: (expanded: boolean) => void
  summaryContent?: ReactNode
  title?: string | ReactNode
  titleIcon?: ReactNode
  variant?: string
  id?: string
  ux3?: boolean
}

type ContentPropTypes<T> = BasePropTypes & {
  content: React.FunctionComponent<T>
  contentProps?: T
}

type RenderPropTypes = BasePropTypes & {
  render: () => ReactNode // won't be reactive, as most often these are defined inline
}

type PropTypes<T> = ContentPropTypes<T> | RenderPropTypes | BasePropTypes

const RestyledAccordion = styled(Accordion)(({ theme }) => ({
  width: '100%',
  borderRadius: 8,
  marginBottom: '-20px !important', // Makes the accordions at the bottom more tightly packed than the elements at the top.
  '& .MuiCollapse-container': {
    borderTop: theme.mixins.borderStandard.border,
  },
  '& .MuiAccordionSummary-content': {
    '& span': {
      fontWeight: 500,
    },
  },
}))

export const AccordionCard: React.FunctionComponent<PropTypes<any>> = (props) => {
  const classes = useStyles({ disableGutters: !!props.disableGutters })

  //@ts-ignore
  const titleEl = useMemo(() => (props.titleIcon ? React.createElement(props.titleIcon) : <></>), [props.titleIcon])
  const contentEl = useMemo(() => {
    if (props['render']) return (props as RenderPropTypes).render()
    else if (props['content']) {
      const { content, contentProps, ...rest } = props as ContentPropTypes<any>
      return React.createElement(content, { ...rest, ...(contentProps || {}) })
    } else return props.children
  }, [props['content'], props['contentProps'], props])
  const [isExpanded, setIsExpanded] = useState<boolean>(!!props.defaultExpanded)

  useEffect(() => {
    if (props.expanded !== undefined) setIsExpanded(props.expanded)
  }, [props.expanded])

  const onChange = () => {
    const newValue = !isExpanded
    setIsExpanded(newValue)
    if (props.onChange) props.onChange(newValue)
  }
  const AccordionComp = useMemo(() => (props.ux3 ? RestyledAccordion : Accordion), [props.ux3])

  return (
    <AccordionComp
      classes={{ root: props.className || classes.root }}
      defaultExpanded={props.defaultExpanded}
      expanded={isExpanded}
      //@ts-ignore
      TransitionProps={{ classes: { hidden: classes.hidden } }}
      key={props.name}
      elevation={props.elevation}
      onChange={onChange}
      id={props.id}
    >
      <AccordionSummary
        aria-controls={`expansion-content-${props.name}`}
        classes={{
          content: classes.content,
          root: classes.summaryRoot,
          expandIcon: classes.summaryIcon,
        }}
        expandIcon={<ExpandMoreIcon />}
        id={`expansion-header-${props.name}`}
      >
        {titleEl}
        {props.title && <span className={classes.headingTitle}>{props.title}</span>}
        {props.summaryContent}
      </AccordionSummary>
      <AccordionDetails
        className={classNames([props.disabled ? classes.disabled : classes.detail, props.detailsClassName])}
      >
        {!isExpanded && props.lazyRender ? null : contentEl}
      </AccordionDetails>
    </AccordionComp>
  )
}

type StoredPropTypes = Omit<PropTypes<any>, 'expanded' | 'title'> & {
  title: string
  resource: string
  expandedSource: string
}

// If the open state should be stored in a form field, use this
export const StoredAccordionCard: React.FunctionComponent<StoredPropTypes> = ({
  title,
  resource,
  expandedSource,
  defaultExpanded,
  onChange,
  ...props
}) => {
  const form = useForm()
  const formState = useFormState()
  const titleWrapped = (
    <BooleanInput
      key={expandedSource}
      label={title}
      source={expandedSource}
      name={expandedSource}
      defaultValue={defaultExpanded}
      disabled={props.disabled}
      labelPlacement="end"
    />
  )

  return (
    <AccordionCard
      {...props}
      title={titleWrapped}
      expanded={formState.values[expandedSource]}
      onChange={(value) => form.change(expandedSource, value)}
    />
  )
}

export default AccordionCard
