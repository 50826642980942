import { orgSelectors } from 'ducks/orgs'
import useFetchComponentsData from 'elements/hardwareSelectorV2/fetch/useFetchComponentsData'
import { ComponentLikeType } from 'elements/hardwareSelectorV2/types'
import { getSupplierConfig } from 'pages/ordering/configs'
import OrderLineItem from 'pages/ordering/OrderLineItem'
import getHardwareDetailFromSystem from 'projectSections/sections/design/systems/tabs/summary/hardware/getHardwareDetailFromSystem'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

type HardwareDetailType = {
  code: string
  priceSource: string
  quantity: number
  type: string
}

const getHardwareDetailFromSystems = (systems: any[], isAU: boolean) => {
  const codes: string[] = []

  let systemHardwareDetails = {}
  systems?.forEach((system) => {
    const hardwareCodes: string[] = []
    const hardwareDetails: HardwareDetailType[] = []
    const overrides = system?.getCogsOverride() || {}
    const hardwareDetailsFromSystems = getHardwareDetailFromSystem(system)
    hardwareDetailsFromSystems.forEach((item, index) => {
      hardwareCodes.push(item.code)
      const activationData = window.AccountHelper.getComponentActivationFromCode(item.code, item.type)
      const override = overrides && activationData ? overrides[activationData.id] : null
      const priceSource = isAU ? 'outlet' : override?.source
      hardwareDetails.push({
        ...item,
        priceSource,
      })
    })
    systemHardwareDetails[system.uuid] = hardwareDetails
    codes.push(...hardwareCodes)
  })

  return { codes, systemHardwareDetails }
}

const useMapSytemToCheckoutLineItems = (systems, selectedSystemUuid) => {
  const isAU = useSelector(orgSelectors.getOrgIso2) === 'AU'
  const { codes, systemHardwareDetails } = getHardwareDetailFromSystems(systems, isAU)
  const enabledDistributorsEnum = useSelector(orgSelectors.getEnabledHardwareSuppliers)
  const enabledDistributors = enabledDistributorsEnum.map((item) => getSupplierConfig(item)?.filterKey)
  const [componentsData, setComponentsData] = useState<ComponentLikeType[]>([])
  const { fetchComponentsData, isFetching } = useFetchComponentsData()

  useEffect(() => {
    fetchComponentsData({
      parameters: { codes: Array.from(new Set(codes)) },
      onReady: (data: ComponentLikeType[]) => {
        setComponentsData(data)
      },
    })
  }, [])

  const componentsDataObj = componentsData.reduce((accumulator, item) => ({ ...accumulator, [item.code]: item }), {})

  const checkoutlineItems = useMemo(() => {
    const lineItems: OrderLineItem[] = []

    systemHardwareDetails[selectedSystemUuid]?.forEach((item) => {
      if (!enabledDistributors.includes(item?.priceSource)) {
        return
      }
      const componentData = componentsDataObj[item.code]
      const orderingData = componentData?.ordering_v2?.find(({ distributor }) => distributor === item?.priceSource)
      if (!componentData || !orderingData) {
        return
      }
      const orderLineItem = new OrderLineItem({
        componentType: item.type,
        componentTypeV2: orderingData?.other_component_type,
        status: 'loaded',
        code: item.code,
        selectedDistributor: item?.priceSource,
        data: {
          code: item.code,
          component_type: item.type,
          description: orderingData?.description,
          short_description: orderingData?.short_description || '',
          distributors: orderingData ? [orderingData] : [],
          data: JSON.stringify({}),
          id: componentData.id,
          manufacturer_name: orderingData?.manufacturer,
          title: orderingData?.title || '',
          image_url: orderingData?.image_url,
        },
        confirmed: true,
        quantity: item.quantity,
      })
      orderLineItem.variantId = orderLineItem.selectedDistributorOrderingData?.distributor_variant_id
      lineItems.push(orderLineItem)
    })

    return lineItems
  }, [selectedSystemUuid, componentsData])

  return { checkoutlineItems }
}

export default useMapSytemToCheckoutLineItems
