import { isValueNumeric } from 'util/misc'
import { Scenario } from './constants'

export const canCalculateScaffoldCost = (customData, featureConfig) => {
  const scaffoldProperties = customData?.form_data?.scaffold_properties
  if (scaffoldProperties?.multiple_properties === 'Yes' || scaffoldProperties?.obstructions_exceeding_7m === 'Yes') {
    return false
  }
  const heightMax = featureConfig.data.length / 2
  if (scaffoldProperties.height > heightMax) {
    return false
  }
  const lengthMax = featureConfig.data[0].length / 2
  if (scaffoldProperties.length > lengthMax) {
    return false
  }
  return true
}

export const getAsNumberOrDefault = (n: unknown, defaultVal: number): number => {
  const strippedString = `${n}`.replace(/\D/g, '')
  return isValueNumeric(strippedString) ? Number(strippedString) : defaultVal
}

export const findClosestValue = (value, options): number | undefined => {
  const filteredOptions = options.filter((option) => option >= value)
  filteredOptions.sort((a, b) => a - b)
  return filteredOptions[0]
}

export const validateScaffoldProperties = (customData) => {
  const errors = {}
  if (
    !customData.form_data ||
    !customData.form_data.scaffold_properties ||
    !customData.form_data.scaffold_properties.height ||
    !customData.form_data.scaffold_properties.length ||
    !customData.form_data.scaffold_properties.multiple_properties ||
    !customData.form_data.scaffold_properties.obstructions_exceeding_7m ||
    !customData.form_data.scaffold_properties.require_access_through_house_building ||
    !customData.form_data.scaffold_properties.scaffold_on_pavement_or_road
  ) {
    errors['form_data'] = 'Please complete the missing fields above'
  }
  return errors
}

export const initializePvfCustomData = (project) => {
  const customData = project?.custom_data?.scaffolding
  const initializedPvfData = {
    ...customData,
    zendesk_ticket_id: customData?.zendesk_ticket_id ?? '',
    updated_cost: customData?.updated_cost ?? null,
    estimated_cost: customData?.estimated_cost ?? null,
    total_settled: null,
    order_status: customData?.order_status ?? '',
    order_status_note: customData?.order_status_note ?? '',
    form_data: {
      ...customData?.form_data,
      scaffold_properties: {
        length: customData?.form_data?.scaffold_properties?.length ?? null,
        height: customData?.form_data?.scaffold_properties?.height ?? null,
        multiple_properties: customData?.form_data?.scaffold_properties?.multiple_properties ?? '',
        scaffold_on_pavement_or_road: customData?.form_data?.scaffold_properties?.scaffold_on_pavement_or_road ?? '',
        require_access_through_house_building:
          customData?.form_data?.scaffold_properties?.require_access_through_house_building ?? '',
        obstructions_exceeding_7m: customData?.form_data?.scaffold_properties?.obstructions_exceeding_7m ?? '',
        require_shrouding: customData?.form_data?.scaffold_properties?.require_shrouding ?? '',
      },
      customer_first_name: customData?.form_data?.customer_first_name ?? '',
      customer_last_name: customData?.form_data?.customer_last_name ?? '',
      customer_email: customData?.form_data?.customer_email ?? '',
      customer_phone: customData?.form_data?.customer_phone ?? '',
      project_installation_date: customData?.form_data?.project_installation_date ?? '',
      site_notes: customData?.form_data?.site_notes ?? '',
      site_photos: customData?.form_data?.site_photos ?? [],
      site_connecting_photos: customData?.form_data?.site_connecting_photos ?? [],
      project_manager_email: project?.assigned_role_email ?? '',
    },
    draft: customData?.draft ?? false,
    scenario: customData?.scenario ?? Scenario.ESTIMATE_COST,
    submit_order_consent: customData?.submit_order_consent ?? false,
    pavement_cost: customData?.pavement_cost ?? null,
    access_through_house_cost: customData?.access_through_house_cost ?? null,
    order_submission_timestamp: customData?.order_submission_timestamp ?? '',
    rag_status: customData?.rag_status ?? '',
    rag_notes: customData?.rag_notes ?? '',
    erect_date: customData?.erect_date ?? '',
    dismantle_date: customData?.dismantle_date ?? '',
    booked: customData?.booked ?? 'not_booked',
  }

  return initializedPvfData
}

export const initializeEmptyPvfData = (project) => {
  const customData = project?.custom_data?.scaffolding
  const initializedPvfData = {
    ...customData,
    zendesk_ticket_id: '',
    updated_cost: null,
    estimated_cost: null,
    total_settled: null,
    order_status: '',
    order_status_note: '',
    form_data: {
      ...customData?.form_data,
      scaffold_properties: initializeScaffoldProperties(),
      customer_first_name: '',
      customer_last_name: '',
      customer_email: '',
      customer_phone: '',
      project_installation_date: '',
      site_notes: '',
      site_photos: [],
      site_connecting_photos: [],
      project_manager_email: project?.assigned_role_email ?? '',
    },
    draft: false,
    scenario: Scenario.ESTIMATE_COST,
    is_pvf_enabled: false,
    submit_order_consent: false,
    pavement_cost: null,
    access_through_house_cost: null,
    order_submission_timestamp: '',
    rag_status: '',
    rag_notes: '',
    erect_date: '',
    dismantle_date: '',
    booked: 'not_booked',
  }

  return initializedPvfData
}

export const initializeScaffoldProperties = () => ({
  length: null,
  height: null,
  multiple_properties: '',
  scaffold_on_pavement_or_road: '',
  require_access_through_house_building: '',
  obstructions_exceeding_7m: '',
  require_shrouding: '',
})

export const getBodyDataForPVFZendeskTicket = (customData, project) => {
  const body = `${customData.estimated_cost ? `Order Confirmed` : `PVF Quote Requested`} for project id: ${project.id}\n
                Project Manager Name: ${project.assigned_role_name || ''}\n
                Project Manager Number: ${project.assigned_role_phone || ''}\n
                Customer's Name: ${customData.form_data.customer_first_name} ${
    customData.form_data.customer_last_name
  }\n
                Customer's Email: ${customData.form_data.customer_email}\n
                Property Latitude: ${project.lat || ''}\n
                Property Longitude: ${project.lon || ''}\n
                Scaffold Length: ${customData.form_data.scaffold_properties.length}\n
                Scaffold Height: ${customData.form_data.scaffold_properties.height}\n
                Site Notes: ${customData.form_data.site_notes}\n`
  return body
}

export const getTicketDataForPVFZendeskTicket = (customData, project, uploadedFileTokens, orgId, featureConfig) => {
  const customerPhoneAsNumber: number = getAsNumberOrDefault(customData?.form_data?.customer_phone, 0)
  const data = {
    ticket: {
      comment: { body: getBodyDataForPVFZendeskTicket(customData, project), uploads: [...uploadedFileTokens] },
      priority: 'high',
      subject: `${customData.estimated_cost ? `Scaffold Order Created` : `PVF Quote Requested`} ${
        customData.postcode ? `- ${customData.postcode}` : ''
      }`,
      custom_fields: [
        {
          id: featureConfig['zendesk_field_mapping']['project_id'],
          value: project.id,
        },
        {
          id: featureConfig['zendesk_field_mapping']['org_id'],
          value: orgId,
        },
        {
          id: featureConfig['zendesk_field_mapping']['os_calculated_cost'],
          value: customData?.estimated_cost ?? '',
        },
        {
          id: featureConfig['zendesk_field_mapping']['property_exceeding_7m'],
          value: customData?.form_data?.scaffold_properties?.obstructions_exceeding_7m ? 'true' : 'false',
        },
        {
          id: featureConfig['zendesk_field_mapping']['multiple_properties'],
          value: customData?.form_data?.scaffold_properties?.multiple_properties ? 'true' : 'false',
        },
        {
          id: featureConfig['zendesk_field_mapping']['pavement_road_check'],
          value:
            customData?.form_data?.scaffold_properties?.scaffold_on_pavement_or_road === 'Yes' ? 'Pavement' : 'Road',
        },
        {
          id: featureConfig['zendesk_field_mapping']['project_type'],
          value: 'residential_type',
        },
        {
          id: featureConfig['zendesk_field_mapping']['RAG'],
          value: 'green',
        },
        {
          id: featureConfig['zendesk_field_mapping']['address'],
          value: project?.address,
        },
        {
          id: featureConfig['zendesk_field_mapping']['postcode'],
          value: project?.zip,
        },
        {
          id: featureConfig['zendesk_field_mapping']['contact'],
          value: customerPhoneAsNumber,
        },
        {
          id: featureConfig['zendesk_field_mapping']['confirmation_status'],
          value: customData?.estimated_cost ? 'order_confirmed' : 'quoting',
        },
      ],
      type: 'task',
      due_at: customData.form_data.project_installation_date,
      requester: customData.form_data.project_manager_email, // This will be the requester who creates the ticket
    },
  }
  return data
}
