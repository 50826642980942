import { Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { authSelectors } from 'ducks/auth'
import { usePublicFeatureConfig } from 'hooks/usePublicFeatureConfig'
import lodash from 'lodash'
import { ComponentVersionsInherit } from 'opensolar-ui'
import { getFileFormData } from 'projectSections/sections/manage3/integrations/PVFIntegration/utils'
import React, { useEffect, useState } from 'react'
import { useNotify } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'
import restClient from 'restClient'
import { ProjectType } from 'types/projects'
import { doNotTranslate } from 'util/misc'
import { booleanMap, Scenario } from './constants'
import ScaffoldCard from './ScaffoldCard'
import {
  canCalculateScaffoldCost,
  getAsNumberOrDefault,
  initializeEmptyPvfData,
  initializePvfCustomData,
} from './utils'
const restClientInstance = restClient(window.API_ROOT + '/api')

const useStyles = makeStyles((theme) => ({
  scaffoldContainer: {
    padding: '24px 16px',
    border: '2px solid #E7E7E7',
    borderRadius: '4px',
    marginBottom: '16px',
  },
}))

const ScaffoldTile: React.FunctionComponent = () => {
  const classes = useStyles()
  const project = useFormState().values as ProjectType
  const orgId = useSelector(authSelectors.getCurrentOrg)?.id
  const projectId = project.id
  const form = useForm()
  const notify = useNotify()
  const customData = project?.custom_data?.scaffolding
  const featureConfig = usePublicFeatureConfig('pvf_scaffolding_cost')

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  useEffect(() => {
    const fetchWalletData = async () => {
      const response = await restClientInstance('CUSTOM_GET', 'custom', {
        url: 'orgs/' + orgId + '/wallet_products/?fieldset=list',
      })
      const pvfItem = response.data.find((walletProduct) => walletProduct.title === 'PVF')
      const isPvfEnabled = pvfItem && pvfItem.is_active

      const initializePvfData = initializePvfCustomData(project)
      initializePvfData.is_pvf_enabled = isPvfEnabled
      if (project?.contacts_data && project?.contacts_data?.length > 0) {
        initializePvfData.form_data.customer_first_name = project?.contacts_data[0]?.first_name ?? ''
        initializePvfData.form_data.customer_last_name = project?.contacts_data[0]?.family_name ?? ''
        initializePvfData.form_data.customer_phone = project?.contacts_data[0]?.phone ?? ''
        initializePvfData.form_data.customer_email = project?.contacts_data[0]?.email ?? ''
      }
      if (!lodash.isEqual(customData, initializePvfData)) {
        const currentValues = form.getState().values
        const updatedValues = {
          ...currentValues,
          custom_data: {
            ...currentValues.custom_data,
            scaffolding: initializePvfData,
          },
        }

        form.reset(updatedValues) // Reset only with updated fields
      }
    }
    fetchWalletData()
  }, [])

  const calculateCost = () => {
    // check if the scaffold cost can be calculated on the spot or not
    if (!canCalculateScaffoldCost(customData, featureConfig)) {
      form.batch(() => {
        form.change('custom_data.scaffolding.estimated_cost', null)
        form.change('custom_data.scaffolding.access_through_house_cost', null)
        form.change('custom_data.scaffolding.pavement_cost', null)
        form.change('custom_data.scaffolding.scenario', Scenario.CREATE_QUOTE)
      })
      return null
    }
    let url = `calculate_scaffolding_cost/`
    if (customData.form_data.scaffold_properties?.height) {
      url = url + `?height=${customData.form_data.scaffold_properties.height}`
    }
    if (customData.form_data.scaffold_properties?.length) {
      url = url + `&length=${customData.form_data.scaffold_properties.length}`
    }
    if (customData.form_data.scaffold_properties?.require_access_through_house_building) {
      url =
        url +
        `&access_through_house=${
          booleanMap[customData.form_data.scaffold_properties.require_access_through_house_building] || 0
        }`
    }
    if (customData.form_data.scaffold_properties?.scaffold_on_pavement_or_road) {
      url =
        url +
        `&pavement_permit_non_london=${
          booleanMap[customData.form_data.scaffold_properties.scaffold_on_pavement_or_road] || 0
        }`
    }
    restClientInstance('CUSTOM_GET', 'custom', {
      url: url,
    })
      .then((res) => {
        let accessThroughHouseCost: number | null = null
        let pavementCost: number | null = null
        if (customData.form_data.scaffold_properties.require_access_through_house_building === 'Yes') {
          accessThroughHouseCost = 100
        }
        if (customData.form_data.scaffold_properties?.scaffold_on_pavement_or_road === 'Yes') {
          pavementCost = 100
        }
        const updatedCustomData = {
          ...customData,
          estimated_cost: res.data,
          scenario: Scenario.CREATE_QUOTE,
          access_through_house_cost: accessThroughHouseCost,
          pavement_cost: pavementCost,
        }
        form.change('custom_data.scaffolding', updatedCustomData)
      })
      .catch((err) => {
        notify(doNotTranslate(`Error calculating cost: ${err}`))
        console.log('err', err)
      })
  }

  const createQuote = () => {
    setIsSubmitting(true)
    // 1. upload all the images/attachments
    const uploadedFileTokens: string[] = []
    const files = [
      ...(customData?.form_data?.site_photos || []),
      ...(customData?.form_data?.site_connecting_photos || []),
    ]

    const uploadPromises = files.map(async (photo, index) => {
      const fileData = getFileFormData(photo)
      const formData = await fileData()
      return fetch(
        `${window.API_ROOT}/api/orgs/${orgId}/projects/${projectId}/upload_pvf_attachments/?filename=scaffold-${
          index + 1
        }-${photo.name}`,
        {
          method: 'POST',
          body: formData,
        }
      )
        .then((res) => res.json())
        .then((res) => {
          uploadedFileTokens.push(res.token)
        })
        .catch((err) => {
          notify(doNotTranslate(`Error uploading files: ${err}`))
          console.log('Error uploading files', err)
        })
    })

    // Wait for all uploads to complete
    Promise.all(uploadPromises)
      .then(() => {
        const customerPhoneAsNumber: number = getAsNumberOrDefault(customData?.form_data?.customer_phone, 0)

        // 2. create a ticket
        const body = `${
          customData.estimated_cost ? `Order Confirmed` : `PVF Quote Requested`
        } for project id: ${projectId}\n
                      Project Manager Name: ${project.assigned_role_name || ''}\n
                      Project Manager Number: ${project.assigned_role_phone || ''}\n
                      Customer's Name: ${customData.form_data.customer_first_name} ${
          customData.form_data.customer_last_name
        }\n
                      Customer's Email: ${customData.form_data.customer_email}\n
                      Property Latitude: ${project.lat || ''}\n
                      Property Longitude: ${project.lon || ''}\n
                      Scaffold Length: ${customData.form_data.scaffold_properties.length}\n
                      Scaffold Height: ${customData.form_data.scaffold_properties.height}\n
                      Site Notes: ${customData.form_data.site_notes}\n`

        const url = `orgs/${orgId}/projects/${projectId}/create_pvf_quote/`
        return restClientInstance('CUSTOM_PATCH', 'custom', {
          url: url,
          data: {
            ticket: {
              comment: { body: body, uploads: [...uploadedFileTokens] },
              priority: 'high',
              subject: `${customData.estimated_cost ? `Scaffold Order Created` : `PVF Quote Requested`} ${
                customData.postcode ? `- ${customData.postcode}` : ''
              }`,
              custom_fields: [
                {
                  id: featureConfig['zendesk_field_mapping']['project_id'],
                  value: projectId,
                },
                {
                  id: featureConfig['zendesk_field_mapping']['org_id'],
                  value: orgId,
                },
                {
                  id: featureConfig['zendesk_field_mapping']['os_calculated_cost'],
                  value: customData?.estimated_cost ?? '',
                },
                {
                  id: featureConfig['zendesk_field_mapping']['property_exceeding_7m'],
                  value: customData?.form_data?.scaffold_properties?.obstructions_exceeding_7m ? 'true' : 'false',
                },
                {
                  id: featureConfig['zendesk_field_mapping']['multiple_properties'],
                  value: customData?.form_data?.scaffold_properties?.multiple_properties ? 'true' : 'false',
                },
                {
                  id: featureConfig['zendesk_field_mapping']['pavement_road_check'],
                  value:
                    customData?.form_data?.scaffold_properties?.scaffold_on_pavement_or_road === 'Yes'
                      ? 'Pavement'
                      : 'Road',
                },
                {
                  id: featureConfig['zendesk_field_mapping']['project_type'],
                  value: 'residential_type',
                },
                {
                  id: featureConfig['zendesk_field_mapping']['RAG'],
                  value: 'green',
                },
                {
                  id: featureConfig['zendesk_field_mapping']['address'],
                  value: project?.address,
                },
                {
                  id: featureConfig['zendesk_field_mapping']['postcode'],
                  value: project?.zip,
                },
                {
                  id: featureConfig['zendesk_field_mapping']['contact'],
                  value: customerPhoneAsNumber,
                },
                {
                  id: featureConfig['zendesk_field_mapping']['confirmation_status'],
                  value: customData?.estimated_cost ? 'order_confirmed' : 'quoting',
                },
              ],
              type: 'task',
              due_at: customData.form_data.project_installation_date,
              requester: customData.form_data.project_manager_email, // This will be the requester who creates the ticket
            },
          },
        })
          .then((res) => {
            const updatedCustomData = {
              ...customData,
              rag_status: 'Green',
              order_submission_timestamp: new Date().toISOString(), // timestamp of the order submission
              zendesk_ticket_id: res.data.ticket_id,
              draft: false,
              scenario: Scenario.MAKE_PAYMENT,
              booked: customData?.estimated_cost > 0 ? 'order_confirmed' : 'quoting',
            }
            form.mutators.updateField('custom_data.scaffolding', updatedCustomData)
            setIsSubmitting(false)
            if (customData?.estimated_cost > 0) {
              notify(doNotTranslate('PVF Scaffold Order Created Successfully.'))
            } else {
              notify(doNotTranslate('PVF Quote Requested Successfully.'))
            }
          })
          .catch((err) => {
            notify(doNotTranslate(`Error creating ticket: ${err}`))
            console.log('Error creating ticket', err)
          })
      })
      .catch((err) => {
        notify(doNotTranslate(`Error uploading images: ${err}`))
        console.log('Error uploading images:', err)
      })
  }

  const cancelOrder = async () => {
    try {
      const changeStatus = {
        ticket: {
          comment: { body: `${customData.estimated_cost ? `Order cancelled.` : `Quote cancelled.`}` },
          priority: 'high',
          custom_fields: [
            {
              id: featureConfig['zendesk_field_mapping']['OSStatus'],
              value: 'os_cancellation',
            },
          ],
        },
      }
      let url = `orgs/${orgId}/projects/${projectId}/update_pvf_zendesk_ticket/?ticket_id=${customData?.zendesk_ticket_id}`
      const response = await restClientInstance('CUSTOM_PUT', 'custom', {
        url: url,
        data: changeStatus,
      })
      if (customData) {
        const updatedCustomData = {
          ...customData,
          scenario: Scenario.CANCELLED,
        }
        form.change('custom_data.scaffolding', updatedCustomData)
        if (customData?.estimated_cost > 0) {
          notify(doNotTranslate('PVF Scaffold Order Cancelled Successfully.'))
        } else {
          notify(doNotTranslate('PVF Quote Cancelled Successfully.'))
        }
      }
    } catch (err) {
      notify(doNotTranslate(`Error cancelling order: ${err}`))
      console.log('Error:', err)
    }
  }
  const confirmOrder = async () => {
    try {
      const changeStatus = {
        ticket: {
          comment: { body: 'Order Confirmed.' },
          priority: 'high',
          custom_fields: [
            {
              id: featureConfig['zendesk_field_mapping']['confirmation_status'],
              value: 'order_confirmed',
            },
          ],
        },
      }
      let url = `orgs/${orgId}/projects/${projectId}/update_pvf_zendesk_ticket/?ticket_id=${customData?.zendesk_ticket_id}`
      const response = await restClientInstance('CUSTOM_PUT', 'custom', {
        url: url,
        data: changeStatus,
      })
      if (customData) {
        const updatedCustomData = {
          ...customData,
          booked: 'order_confirmed',
          order_submission_timestamp: new Date().toISOString(), // update the timestamp of the order submission
        }
        form.change('custom_data.scaffolding', updatedCustomData)
        notify(doNotTranslate('PVF Scaffold Order Confirmed Successfully.'))
      }
    } catch (err) {
      notify(doNotTranslate(`Error confirming order: ${err}`))
      console.log('Error:', err)
    }
  }
  const initializeCustomData = () => {
    const isPvfEnabled = customData?.is_pvf_enabled
    const initialCustomData = initializeEmptyPvfData(project)
    initialCustomData.is_pvf_enabled = isPvfEnabled
    if (project?.contacts_data && project?.contacts_data?.length > 0) {
      initialCustomData.form_data.customer_first_name = project?.contacts_data[0]?.first_name ?? ''
      initialCustomData.form_data.customer_last_name = project?.contacts_data[0]?.family_name ?? ''
      initialCustomData.form_data.customer_phone = project?.contacts_data[0]?.phone ?? ''
      initialCustomData.form_data.customer_email = project?.contacts_data[0]?.email ?? ''
    }
    form.change('custom_data.scaffolding', initialCustomData)
  }

  return (
    <div className={classes.scaffoldContainer}>
      {!customData || (customData && Object.keys(customData).length === 0) ? (
        <Grid container spacing={0}>
          <Grid item xs={12} style={{ padding: '4px 0px' }}>
            <Skeleton width="100%" height="80px" />
          </Grid>
          <Grid item xs={12} style={{ padding: '4px 0px' }}>
            <Skeleton width="100%" height="80px" />
          </Grid>
        </Grid>
      ) : (
        <ComponentVersionsInherit versions={{ radio: 2 }}>
          <ScaffoldCard
            calculateCost={calculateCost}
            createQuote={createQuote}
            cancelOrder={cancelOrder}
            confirmOrder={confirmOrder}
            initializeCustomData={initializeCustomData}
            isSubmitting={isSubmitting}
          />
        </ComponentVersionsInherit>
      )}
    </div>
  )
}

export default ScaffoldTile
