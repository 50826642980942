import { makeStyles } from '@material-ui/core'
import { Button, DeleteOutlineIcon, EditOutlineIcon, IconButton, styled, Tooltip } from 'opensolar-ui'
import { RowCentered } from 'projectSections/sections/manage3/styles'
import { useTranslate } from 'ra-core'
import React from 'react'
import { EventActions } from 'types/events'
import { StageActionEventType } from 'types/projects'
import { Theme } from 'types/themes'
import { EVENT_FORM_VARIATIONS } from '../util'
const useStyles = makeStyles((theme: Theme) => ({
  actionsWrapper: {
    marginLeft: 'auto',
    marginBottom: 'auto',
  },
  baseBtn: {
    margin: '0 !important',
  },
}))

const StartIconRestyle = styled('div', { name: 'StartIconRestyle' })({
  marginRight: -6,
})
interface PropTypes {
  event: StageActionEventType
  toggleModal: (event: StageActionEventType) => void
  toggleDelete: (event: StageActionEventType) => void
  minDisplay?: boolean
  disableEdit?: boolean
  disableDelete?: boolean
}

const ActionButtons: React.FC<PropTypes> = ({
  event,
  toggleModal,
  toggleDelete,
  minDisplay,
  disableEdit,
  disableDelete,
}) => {
  const classes = useStyles()
  const translate = useTranslate()
  const formConfigActions = EVENT_FORM_VARIATIONS[event.form_config]?.actions
  return (
    <RowCentered className={classes.actionsWrapper}>
      {formConfigActions?.includes(EventActions.edit) && (
        <Tooltip title={translate('Edit')}>
          {minDisplay ? (
            <IconButton
              color="secondary"
              size="small"
              onClick={() => {
                toggleModal(event)
              }}
              disabled={disableEdit}
            >
              <EditOutlineIcon variant={2} />
            </IconButton>
          ) : (
            <Button
              onClick={() => {
                toggleModal(event)
              }}
              variant="text"
              size="small"
              color="info"
              className={classes.baseBtn}
              startIcon={
                <StartIconRestyle>
                  <EditOutlineIcon variant={2} />
                </StartIconRestyle>
              }
              disabled={disableEdit}
            >
              {translate('Edit')}
            </Button>
          )}
        </Tooltip>
      )}
      {formConfigActions?.includes(EventActions.edit) && (
        <Tooltip title={translate('Delete')}>
          {minDisplay ? (
            <IconButton
              color="error"
              size="small"
              onClick={() => {
                toggleDelete({ ...event, is_archived: true })
              }}
              style={{ marginLeft: 5 }}
              disabled={disableDelete}
            >
              <DeleteOutlineIcon />
            </IconButton>
          ) : (
            <Button
              onClick={() => {
                toggleDelete({ ...event, is_archived: true })
              }}
              variant="text"
              size="small"
              color="error"
              className={classes.baseBtn}
              startIcon={
                <StartIconRestyle>
                  <DeleteOutlineIcon />
                </StartIconRestyle>
              }
              disabled={disableDelete}
            >
              {translate('Delete')}
            </Button>
          )}
        </Tooltip>
      )}
    </RowCentered>
  )
}

export default ActionButtons
