export const DS_ERROR_CODE_TO_MSG = {
  3: "This contract cannot be accessed because the installation company's account does not have an active connection to DocuSign",
  4: 'This contract uses SMS authorization but no phone number has been provided',
  5: 'A contact with a name and email must be included before a contract can be signed',
  6: "The Customer's Phone number is invalid. Please provide a valid phone number",
  7: "The Customer's email address is invalid. Please provide a valid email address",
  8: 'SMS authentication is required for this envelope but it is not enabled in the Docusign account',
  9: 'Unable to access to envelope',
  10: 'Contract cannot be accessed due to updated details, please reset contract and resend',
}
