import ActionsIconButton from 'elements/button/ActionsIconButton'
import {
  Bag05OutlineIcon,
  BankNoteOutlineIcon,
  BaseTheme,
  DataOutlineIcon,
  LayersIcon,
  LightningIcon,
  SendOutlineIcon,
  styled,
} from 'opensolar-ui'
import ProjectDeleteButton from 'projectSections/sections/manage3/details/elements/ProjectDeleteButton'
import { useTranslate } from 'ra-core'
import React from 'react'
import { ProjectType } from 'types/projects'
import { useFeatureFlag } from 'util/split'
import { ProjectDuplicateButton } from './ProjectDuplicateButton'
import ProjectLinkButton from './ProjectLinkButton'

const MenuSubtitle = styled('span', { name: 'MenuSubtitle' })(({ theme }) => ({
  color: theme.palette.grey[700],
  fontWeight: 500,
  textTransform: 'uppercase',
}))

const MenuWrapper = styled('div', { name: 'MenuWrapper' })({
  padding: 15,
  display: 'flex',
  flexDirection: 'column',
})

const Divider = styled('div', { name: 'Divider' })(({ theme }) => ({
  borderTop: theme.mixins.borderStandard.border,
  width: '100%',
  marginBottom: 10,
}))

interface PropTypes {
  disabled?: boolean
  project: ProjectType
}

const ProjectActionsButton: React.FC<PropTypes> = ({ disabled, project }) => {
  const translate = useTranslate()
  const enableHardwareTab = useFeatureFlag('hardware_3_0_projects_tab', 'on')

  return (
    <ActionsIconButton disabled={disabled || false} btnProps={{ variant: 'rounded', color: 'default' }}>
      <>
        <MenuWrapper>
          <MenuSubtitle>{translate('Go To')}</MenuSubtitle>
          <ProjectLinkButton
            linkTo={`/projects/${project.id}/manage`}
            label={'Manage'}
            startIcon={<DataOutlineIcon variant={2} color={BaseTheme.palette.grey[700]} />}
            permissionToCheck={'manage'}
          />
          <ProjectLinkButton
            linkTo={`/projects/${project.id}/energy`}
            label={'Energy'}
            startIcon={<LightningIcon color={BaseTheme.palette.grey[700]} />}
            permissionToCheck={'project'}
          />
          <ProjectLinkButton
            linkTo={`/projects/${project.id}/design`}
            label={'Design'}
            startIcon={<LayersIcon color={BaseTheme.palette.grey[700]} />}
            permissionToCheck={'design'}
          />
          <ProjectLinkButton
            linkTo={`/projects/${project.id}/payments`}
            label={'Payments'}
            startIcon={<BankNoteOutlineIcon color={BaseTheme.palette.grey[700]} />}
            permissionToCheck={'project'}
          />
          <ProjectLinkButton
            linkTo={`/projects/${project.id}/proposal`}
            label={'Proposal'}
            startIcon={<SendOutlineIcon variant={1} color={BaseTheme.palette.grey[700]} />}
            permissionToCheck={'proposal'}
          />
          {enableHardwareTab && (
            <ProjectLinkButton
              linkTo={`/projects/${project.id}/hardware`}
              label={'Hardware'}
              startIcon={<Bag05OutlineIcon color={BaseTheme.palette.grey[700]} fill="none" />}
              permissionToCheck={'manage'}
            />
          )}
        </MenuWrapper>
        <Divider />
        <MenuWrapper>
          <MenuSubtitle>{translate('Actions')}</MenuSubtitle>
          <ProjectDuplicateButton projectId={project.id} />
          <ProjectDeleteButton project={project} btnType={'list'} />
        </MenuWrapper>
      </>
    </ActionsIconButton>
  )
}

export default ProjectActionsButton
