import { TourConfig } from 'ducks/tour'
import listTourImage from './assets/list.gif'
import manageTourImage from './assets/manage.gif'
import { MANAGE_FUJI_TOUR_STEPS } from './manage/steps'
import { PROJECT_FUJI_TOUR_STEPS } from './project_list/steps'

export const TOUR_CONFIG: {
  [tourType: string]: TourConfig
} = {
  manage: {
    name: 'manage',
    hidden: 'manage_tour_hidden',
    startStep: 'fuji_manage',
    finishStep: 'manage_tour_finish',
    startBanner: {
      header: 'Your new Manage page is here!',
      subText:
        'Take a tour of the new Manage page combining all key details and tools into one view for easier, more effective project handling.',
      imgSrc: manageTourImage,
    },
    confirmSkipText:
      'The tour is the best way for you to quickly learn your way around the new Manage page on OpenSolar.',
    finishBanner: {
      header: 'Manage Page Tour Complete!',
      subText:
        'Need a refresher? Start the tour anytime from the Support button. For more details on the redesigned pages, explore our Help Center.',
    },
    steps: MANAGE_FUJI_TOUR_STEPS,
    handleNext: (nextStepIndex, nextStep, openDrawer, closeDrawer) => {
      if (nextStep === 'history') {
        openDrawer(nextStepIndex)
      } else if (nextStep === 'workflows') {
        closeDrawer(nextStepIndex - 1)
      }
    },
    handlePrev: (prevStepIndex, prevStep, openDrawer, closeDrawer) => {
      if (prevStep === 'history') {
        openDrawer(prevStepIndex)
      } else if (prevStep === 'details') {
        closeDrawer(prevStepIndex + 1)
      }
    },
    handleCancel: (currStep, stepIndex, closeDrawer) => {
      if (currStep === 'history') closeDrawer(stepIndex)
    },
  },
  project_list: {
    name: 'project_list',
    hidden: 'list_tour_hidden',
    startStep: 'fuji_project',
    finishStep: 'list_tour_finish',
    startBanner: {
      header: 'Your projects have a new look!',
      subText:
        'Take a walkthrough with us to discover the user-friendly changes designed to make your projects tracking and filtering more efficient.',
      imgSrc: listTourImage,
    },
    confirmSkipText:
      'The tour is the best way for you to quickly learn your way around the new Projects page on OpenSolar.',
    finishBanner: {
      header: 'Projects Page Tour Complete!',
      subText:
        'Need a refresher? Start the tour anytime from the Support button. For more details on the redesigned pages, explore our Help Center.',
    },
    steps: PROJECT_FUJI_TOUR_STEPS,
    handleNext: (nextStepIndex, nextStep, openDrawer, closeDrawer) => {
      if (nextStep === 'projectListSort') {
        openDrawer(nextStepIndex)
      } else if (nextStep === 'projectListSettings') {
        closeDrawer(nextStepIndex - 1)
        openDrawer(nextStepIndex)
      }
    },
    handlePrev: (prevStepIndex, prevStep, openDrawer, closeDrawer) => {
      if (prevStep === 'projectList') {
        closeDrawer(prevStepIndex + 1)
      } else if (prevStep === 'projectListSort') {
        openDrawer(prevStepIndex)
        closeDrawer(prevStepIndex + 1)
      } else if (prevStep === 'projectListSettings') {
        openDrawer(prevStepIndex)
      }
    },
    handleCancel: (currStep, stepIndex, closeDrawer) => {
      if (currStep === 'projectListSort' || currStep === 'projectListSettings') {
        closeDrawer(stepIndex)
      }
    },
  },
}
