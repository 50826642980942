import restClient from 'restClient'
import { booleanMap } from '../../../manage/scaffoldTile/constants'

const restClientInstance = restClient(window.API_ROOT + '/api')

export const fetchWalletData = async (orgId) => {
  try {
    const response = await restClientInstance('CUSTOM_GET', 'custom', {
      url: `orgs/${orgId}/wallet_products/?fieldset=list`,
    })
    return response.data
  } catch (err) {
    throw new Error(`Error fetching wallet data: ${err}`)
  }
}

export const calculateScaffoldingCost = async (customData) => {
  let url = `calculate_scaffolding_cost/`
  if (customData.form_data.scaffold_properties?.height) {
    url = url + `?height=${customData.form_data.scaffold_properties.height}`
  }
  if (customData.form_data.scaffold_properties?.length) {
    url = url + `&length=${customData.form_data.scaffold_properties.length}`
  }
  if (customData.form_data.scaffold_properties?.require_access_through_house_building) {
    url =
      url +
      `&access_through_house=${
        booleanMap[customData.form_data.scaffold_properties.require_access_through_house_building] || 0
      }`
  }
  if (customData.form_data.scaffold_properties?.scaffold_on_pavement_or_road) {
    url =
      url +
      `&pavement_permit_non_london=${
        booleanMap[customData.form_data.scaffold_properties.scaffold_on_pavement_or_road] || 0
      }`
  }
  try {
    const response = await restClientInstance('CUSTOM_GET', 'custom', { url })
    return response.data
  } catch (err) {
    throw new Error(`Error calculating scaffolding cost: ${err}`)
  }
}

export const getFileFormData = (file) => async () => {
  const formData = new FormData()
  const fileName = file.name
  const isFileObjectInCache = (f) => typeof f?.file?.type === 'string'
  if (isFileObjectInCache(file)) {
    const fileData = file.file
    formData.append('file', fileData, fileName)
    return formData
  } else {
    return fetch(file.url).then(function (t) {
      return t.blob().then((blob) => {
        const fileData = new File([blob], fileName, {
          type: blob.type,
          lastModified: Date.now(),
        })
        formData.append('file', fileData, fileName)
        return formData
      })
    })
  }
}

export const uploadFile = async (photo, index, orgId, projectId) => {
  try {
    const fileData = getFileFormData(photo)
    const formData = await fileData()

    const response = await fetch(
      `${window.API_ROOT}/api/orgs/${orgId}/projects/${projectId}/upload_pvf_attachments/?filename=scaffold-${
        index + 1
      }-${photo.name}`,
      {
        method: 'POST',
        body: formData,
      }
    )

    if (!response.ok) {
      throw new Error(`Error uploading files: ${response.status}`)
    }

    return await response.json()
  } catch (error) {
    console.log('Error uploading files:', error)
    throw error
  }
}

export const createPvfQuote = async (orgId, projectId, data) => {
  const url = `orgs/${orgId}/projects/${projectId}/create_pvf_quote/`
  try {
    const response = await restClientInstance('CUSTOM_PATCH', 'custom', {
      url,
      data: data,
    })
    return response.data
  } catch (err) {
    throw new Error(`Error creating PVF quote: ${err}`)
  }
}

export const cancelPvfOrder = async (orgId, projectId, customData, featureConfig) => {
  const url = `orgs/${orgId}/projects/${projectId}/update_pvf_zendesk_ticket/?ticket_id=${customData?.zendesk_ticket_id}`
  const changeStatus = {
    ticket: {
      comment: { body: `${customData.estimated_cost ? `Order cancelled.` : `Quote cancelled.`}` },
      priority: 'high',
      custom_fields: [
        {
          id: featureConfig['zendesk_field_mapping']['OSStatus'],
          value: 'os_cancellation',
        },
      ],
    },
  }
  try {
    const response = await restClientInstance('CUSTOM_PUT', 'custom', { url, data: changeStatus })
    return response.data
  } catch (err) {
    throw new Error(`Error cancelling order: ${err}`)
  }
}

export const confirmPvfOrder = async (orgId, projectId, customData, featureConfig) => {
  const url = `orgs/${orgId}/projects/${projectId}/update_pvf_zendesk_ticket/?ticket_id=${customData?.zendesk_ticket_id}`
  const changeStatus = {
    ticket: {
      comment: { body: 'Order Confirmed.' },
      priority: 'high',
      custom_fields: [
        {
          id: featureConfig['zendesk_field_mapping']['confirmation_status'],
          value: 'order_confirmed',
        },
      ],
    },
  }
  try {
    const response = await restClientInstance('CUSTOM_PUT', 'custom', { url, data: changeStatus })
    return response.data
  } catch (err) {
    throw new Error(`Error confirming order: ${err}`)
  }
}
