export const syncValidate = (values, translate) => {
  const errors = {}
  return errors
}

export const composeValidators = (...validators) => (value) =>
  validators.reduce((error, validator) => error || validator(value), undefined)

export const required = (value) => (value ? undefined : 'This field is required.')

export const positiveOrZero = (value) =>
  value === undefined || (!isNaN(value) && value >= 0) ? undefined : 'Value should be greater than or equal to 0.'

export const requiredOrZero = (value) => (value || value === '0' || value === 0 ? undefined : 'This field is required.')

export const emailFormat = (value) => {
  const emailRegex = /^[-a-z0-9A-Z~!$%^&*_=+}{'"?]+(\.[-a-z0-9A-Z~!$%^&*_=+}{'"?]+)*@([a-z0-9A-Z_][-a-z0-9A-Z_]*(\.[-a-z0-9A-Z_]+)*\.((?!web)[a-zA-Z]{1,})|(\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/
  if (value && !emailRegex.test(value)) return 'Invalid email address.'
}

export const requiredPositiveOrZero = composeValidators(required, positiveOrZero)

export const validRange = (min = 0, max = 100, required = false, opts) => {
  return (value) => {
    if (value === undefined) {
      if (required) return 'This field is required.'
      else return
    }

    if (value < min || value > max) {
      const fieldName = window.translate(opts?.fieldName || 'Value')
      return window.translate(`%{fieldName} must be between %{min} and %{max} (inclusive).`, { min, max, fieldName })
    }
  }
}
