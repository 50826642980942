import { makeStyles } from '@material-ui/core'
import { BaseTheme, HomeIcon, styled } from 'opensolar-ui'
import { useShowProjectActions } from 'projectSections/hooks/useShowProjectActions'
import { useTranslate } from 'ra-core'
import { useMemo } from 'react'
import { useFormState } from 'react-final-form'
import { Link } from 'react-router-dom'
import { NORMAL_CONTACT } from 'types/contacts'

const useStyles = makeStyles({
  divide: {
    margin: '0 5px',
    flexShrink: 0,
  },
  iconMargin: {
    marginLeft: 5,
    flexShrink: 0,
  },
  truncate: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
})

const Wrapper = styled('div', { name: 'Wrapper' })(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.grey[700],
  '& a': {
    color: theme.palette.grey[700],
  },
  maxWidth: '100%',
  overflow: 'hidden',
}))

interface PropTypes {
  mobile?: boolean
}

const Summary: React.FC<PropTypes> = ({ mobile }) => {
  const classes = useStyles()
  const translate = useTranslate()
  const project = useFormState().values
  const disableActions = !useShowProjectActions()
  const firstContact = useMemo(
    () => (project?.contacts_data ? project.contacts_data?.find((c) => c?.type === NORMAL_CONTACT) : null),
    [project.contacts_data]
  )
  const display = useMemo(() => {
    let name =
      project.is_residential && project.business_name ? project.business_name : firstContact && firstContact?.display
    return [name, project.address].filter((v) => !!v).join(', ')
  }, [project.is_residential, project.business_name, project.address, firstContact])
  const mobileDisplay = display.length > 16 && !disableActions ? `${display.slice(0, 16)}...` : display
  return (
    <Wrapper>
      <Link to="/projects">{translate('Projects')}</Link>
      <span className={classes.divide}>{' / '}</span>
      <HomeIcon size={12} color={BaseTheme.palette.grey[700]} />
      <span className={`${classes.iconMargin} ${classes.truncate}`}>{mobile ? mobileDisplay : display}</span>
    </Wrapper>
  )
}

export default Summary
