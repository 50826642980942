import React, { memo, useCallback } from 'react'
import { ProjectErrorType } from 'reducer/project/projectErrorsReducer'
import DesignWarningBoxBasic from '../../../common/DesignWarningBoxBasic'
import { usePanelGenericWarningFilter } from './PanelGenericWarningBox'

const OthersPanelWarningBox: React.FC<{ showMountingTab: boolean }> = ({ showMountingTab }) => {
  const panelGenericWarningFilter = usePanelGenericWarningFilter()
  const filter = useCallback(
    (error: ProjectErrorType) => {
      return panelGenericWarningFilter(error)
    },
    [panelGenericWarningFilter]
  )
  return (
    <DesignWarningBoxBasic categories={!showMountingTab ? ['other', 'mounting'] : ['other']} extraFilter={filter} />
  )
}

export default memo(OthersPanelWarningBox)
