import { jsx as _jsx } from "react/jsx-runtime";
import { DepositCtaButton, DepositCtaLink } from 'opensolar-ui';
var MakeCashflowDeposit = function (_a) {
    var onAction = _a.onAction, proposalData = _a.proposalData, options = _a.options, elementData = _a.elementData, translate = _a.translate;
    var variant = elementData.variation;
    var label = translate(elementData.actionData.action_title);
    var handleAction = function () {
        onAction({ elementData: elementData, proposalData: proposalData });
    };
    return variant === 'button' ? (_jsx(DepositCtaButton, { label: label, disabled: false, onClick: handleAction })) : (_jsx(DepositCtaLink, { label: label, onClick: handleAction }));
};
export default MakeCashflowDeposit;
