import { TourStep } from 'ducks/tour'
import ProjectList from './steps/ProjectList'
import ProjectListSettings from './steps/ProjectListSettings'
import ProjectListSort from './steps/ProjectListSort'

export const PROJECT_FUJI_TOUR_STEPS: TourStep[] = [
  {
    step: 'projectList',
    config: ProjectList,
    page: 'project',
  },
  {
    step: 'projectListSort',
    config: ProjectListSort,
    page: 'project',
  },
  {
    step: 'projectListSettings',
    config: ProjectListSettings,
    page: 'project',
  },
]
