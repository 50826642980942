import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import { Grid, IconButton } from 'opensolar-ui'
import { ProductDetailNotFound } from '../detail/ProductDetail'
import useHardwareSelectorFetchEngine from '../../../elements/hardwareSelectorV2/fetch/useHardwareSelectorFetchEngine'
import listQueryParamsFormatter from '../../../elements/input/listQueryParamsFormatter'
import { ProductDetailCheckoutMultiDistributor } from '../detail/content/ProductDetailCheckoutMultiDistributor'
import ProductCard from './ProductCard3'
import { useState } from 'react'
import { DistributorDataTypeV2, HardwareComponentType } from '../../../elements/hardwareSelectorV2/types'
import { sortByPriceOrderingV2 } from '../utils'
import TechnicalDetails3 from './TechnicalDetails3'
import ProductDetailDescription3 from '../detail/content/ProductDetailDescription3'
import { mapComponentTypesV2ToV1 } from '../../../util/misc'

const ProductDetail3 = ({ componentCode, goBack }: { componentCode: string; goBack?(): void }) => {
  const componentCodesSet = new Set([componentCode])
  const { componentsData } = useHardwareSelectorFetchEngine({
    componentTypes: [],
    limit: 1,
    defaultFilterValues: {
      codes: listQueryParamsFormatter.format({ value: componentCodesSet }),
    },
  })

  const [selectedDistributor, setSelectedDistributor] = useState<DistributorDataTypeV2 | null>(null)

  if (!componentsData || componentsData.length === 0) {
    return <ProductDetailNotFound />
  }

  const component = componentsData[0] as HardwareComponentType

  const lowestPriceDistributor = sortByPriceOrderingV2<DistributorDataTypeV2>(component.ordering_v2).find(
    (ordering) => ordering.price
  )
  const displayDetailsDistributor = selectedDistributor || lowestPriceDistributor

  let productDetailsDataV2 = undefined

  if (componentsData && componentsData.length > 0) {
    productDetailsDataV2 = componentsData[0]
  }

  return (
    <Grid container xs spacing={3} style={{ padding: 20 }}>
      <Grid item xs={12}>
        <IconButton onClick={goBack} size="small">
          <ArrowBackIosIcon />
          Back
        </IconButton>
      </Grid>
      <Grid item xs>
        <ProductCard
          manufacturerUrl={component.manufacturer_logo}
          selectedDistributorData={displayDetailsDistributor}
          outputPower={(component.rating || 0) * 1000}
        />
      </Grid>
      <Grid item xs={4}>
        <ProductDetailCheckoutMultiDistributor
          component={productDetailsDataV2}
        />
      </Grid>
      <Grid item xs={12}>
        <TechnicalDetails3
          productDetailData={component.data}
          componentType={mapComponentTypesV2ToV1(component.type)}
          quantityPerItem={displayDetailsDistributor?.quantity_per_item || 0}
        />
      </Grid>
      <Grid item xs={12}>
        <ProductDetailDescription3 selectedDistributor={displayDetailsDistributor} />
      </Grid>
    </Grid>
  )
}

export default ProductDetail3
