import Typography from '@material-ui/core/Typography'
import { memo } from 'react'
import { batteryComponentDataConfigs } from 'resources/components/batteries/constants'
import { inverterComponentDataConfigs } from 'resources/components/inverters/constants'
import { moduleComponentDataConfigs } from 'resources/components/modules/constants'
import { otherComponentDataConfigs } from 'resources/components/others/constants'
import type { ComponentConfig, GenericComponentConfig, SelectComponentConfig } from 'resources/components/types'
import { makeOpenSolarStyles } from 'themes/makeOpenSolarStyles'
import type { ComponentTypes, ComponentTypesV2 } from 'types/selectComponent'

const FIXED_VALUE_WIDTH = 100
const KEY_VALUE_GAP = 10

const CUSTOM_DATA_CONFIG__MAP = {
  module: moduleComponentDataConfigs,
  inverter: inverterComponentDataConfigs,
  battery: batteryComponentDataConfigs,
  other: otherComponentDataConfigs,
}
export const getGenericComponentConfigs = (
  componentType: ComponentTypes | ComponentTypesV2,
  productDetailData: { [key in string]: unknown }
) => {
  return CUSTOM_DATA_CONFIG__MAP[componentType].filter((config: ComponentConfig) => {
    if (config.component === 'String' && config.unit === 'JSON') {
      // hide JSON format value, it doesn't make sense to our users
      // need to think about a better UI
      return false
    }

    const value = productDetailData[config.source]
    if (!!value) return true
    return false
  }) as (SelectComponentConfig | GenericComponentConfig)[]
}

const useTechDetailStyles = makeOpenSolarStyles((theme) => {
  return {
    container: {
      borderCollapse: 'collapse',
    },
    labelWrapper: {
      maxWidth: 200,
      textAlign: 'right',
      verticalAlign: 'baseline',
      borderBottom: '1px solid #e7e7e7',
      padding: '5px 0',
    },
    detailRow: {},
    valueWrapper: {
      maxWidth: 350,
      borderBottom: '1px solid #e7e7e7',
      padding: '5px 0',
      textAlign: 'left',
    },
    label: {
      paddingRight: KEY_VALUE_GAP,
    },
    unit: {
      marginLeft: 5,
      opacity: 0.5,
      ...theme.typography.caption,
    },
  }
})

const DetailItem = memo(({ label, value, unit = '' }: { label: string; value: unknown; unit?: string }) => {
  const classes = useTechDetailStyles()
  return (
    <tr className={classes.detailRow}>
      <td className={classes.labelWrapper}>
        <span className={classes.label}>{label}:</span>
      </td>
      <td className={classes.valueWrapper}>
        {value}
        <span className={classes.unit}>{unit}</span>
      </td>
    </tr>
  )
})

const MCSDetailItem = ({ mcs }) => {
  if (!mcs) return null
  return <DetailItem label={'MCS Reference'} value={mcs} />
}

const PalletSizeItem = ({ quantityPerItem }: { quantityPerItem: number | undefined }) => {
  if (!quantityPerItem) return null
  return <DetailItem label={'Pallet Size'} value={quantityPerItem} />
}

const TechnicalDetails = ({
  productDetailData,
  componentType,
  quantityPerItem,
}: {
  productDetailData: { [key in string]: unknown }
  componentType?: ComponentTypes
  quantityPerItem: number | undefined
}) => {
  const classes = useTechDetailStyles()

  if (!componentType) {
    return null
  }
  const customDataConfig = getGenericComponentConfigs(componentType, productDetailData)
  return (
    <div>
      <Typography variant="h6">Technical Details</Typography>
      <table className={classes.container}>
        {customDataConfig.map((config) => {
          const formatter = config.formatter?.format
          let value = productDetailData[config.source]
          if (formatter !== undefined) {
            value = formatter(productDetailData[config.source])
          }

          if (config.component === 'Select') {
            value = config.selectOptions.find((option) => option.id === value)?.name || value
          }

          return <DetailItem key={config.source} label={config.label} value={value} unit={config.unit} />
        })}
        <PalletSizeItem quantityPerItem={quantityPerItem} />
        <MCSDetailItem mcs={productDetailData.mcs} />
      </table>
    </div>
  )
}

export default TechnicalDetails
