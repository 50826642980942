import { useMediaQuery } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { ComponentVersions_3_0 } from 'constants/uxVersions'
import { useAddRemoveStructs } from 'contexts/structs/hooks/useAddRemoveStructs'
import { Struct } from 'contexts/structs/types/types'
import { useUserActionsContext } from 'contexts/userActions/useUserActionsContext'
import { addDevToolShowHideToggle } from 'debug/ostools'
import { removeTourBanner } from 'ducks/auth'
import { permissionsSelectors } from 'ducks/permissions'
import { FujiTourType } from 'ducks/tour'
import { Alert, Button, ComponentVersionsInherit, DialogCloseButton, DialogTitle, styled } from 'opensolar-ui'
import { useToursAvailable } from 'persistentContent/tours/hooks/useToursAvailable'
import ConfirmModal from 'projectSections/sections/manage3/common/ConfirmModal'
import { useTranslate } from 'ra-core'
import { FC, ReactNode, useEffect, useMemo, useState } from 'react'
import Confetti from 'react-confetti'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'types/state'
import { Theme } from 'types/themes'
import useTranslateParse from 'util/useTranslateParse'
import { PromoDialog } from './usePromoDialogStructs'

/**
 * This hook controls adding/removing the Tour start dialogs based on
 * the tour available in redux state.
 * It adds a dialog to the struct context when the tour is available and
 * no step is set yet.
 */
export const useFujiTourPromo = () => {
  // Add devtools to show/hide the dialog
  const [debugTour, setDebugTour] = useState<FujiTourType | undefined>()
  addDevToolShowHideToggle('promos.{showhide}FujiTourPromo', '{showhide} the FUJI Tour Promo', (value) =>
    setDebugTour(value ? 'main' : undefined)
  )

  const shouldShowTours = useSelector((state: RootState) => state?.auth?.user?.show_tour_banner) || {}
  const tourToShow = useMemo(() => {
    if (debugTour) return debugTour
    for (const key in shouldShowTours) {
      const record = shouldShowTours[key]
      if (record.shouldShow && !record.userHidIt) return key
    }
    return undefined
  }, [shouldShowTours, debugTour])

  const toursAvailable = useToursAvailable()

  const structs = useAddRemoveStructs()

  const tourPromo = tourToPromo[tourToShow || '']

  const isOpen = toursAvailable && tourPromo

  useEffect(() => {
    if (isOpen) {
      // Generate a unique key for the promo dialog, so that it is allowed to show again
      tourPromo.key = 'fuji-tour-' + tourToShow + '_' + Date.now()

      structs.add(tourPromo)
      return () => structs.remove(tourPromo)
    }
  }, [isOpen, tourPromo])
}

const checkPermissionsForTour = (rights: any) => {
  if (rights) {
    const {
      project,
      info_contact_info_basic,
      design_pricing,
      proposal,
      energy_usage_tariff,
      design,
      design_payment_options,
    } = rights
    let hasPermissionsToStartTour = true
    if (!project.view || !project.edit || !project.create) {
      hasPermissionsToStartTour = false
    }

    if (!energy_usage_tariff.view) {
      hasPermissionsToStartTour = false
    }

    if (!proposal.view) {
      hasPermissionsToStartTour = false
    }

    if (!info_contact_info_basic.view || !info_contact_info_basic.edit || !info_contact_info_basic.create) {
      hasPermissionsToStartTour = false
    }

    if (!design.view || !design.edit) {
      hasPermissionsToStartTour = false
    }

    if (!design_pricing.view || !design_pricing.edit) {
      hasPermissionsToStartTour = false
    }

    if (!design_payment_options.view || !design_payment_options.edit) {
      hasPermissionsToStartTour = false
    }

    return hasPermissionsToStartTour
  }
  return false
}

const FujiContent = (props: { onNext: () => void }) => {
  const userActions = useUserActionsContext()
  const [showAlertDialog, setAlertDialog] = useState(false)
  const dispatch = useDispatch()
  const accessRightsSetting = useSelector(permissionsSelectors.getPermissionsSetting)
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const isMobileDevice = useMemo(() => window.Utils?.isTouchDevice(), [])

  const translate = useTranslate()
  const translateParse = useTranslateParse()
  let hasPermissionsToStart = useMemo(() => checkPermissionsForTour(accessRightsSetting), [accessRightsSetting])

  const onStartTour = () => {
    logAmplitudeEvent('fuji_tour_started', { tourType: 'fuji' })
    window.location.href = window.location.origin + '/#/home?tour=fuji&step=search_address'
    // dispatch(updateTourStep('fuji', 'search_address'))
    dispatch(removeTourBanner(true, 'main'))
    props.onNext()
  }

  const onAbortTour = () => {
    logAmplitudeEvent('fuji_banner_hidden', { tourType: 'fuji' })
    userActions.recordAction('fuji_tour-tour_hidden')
    dispatch(removeTourBanner(true, 'main'))
    props.onNext()
  }

  if (isSmall || isMobileDevice) {
    return (
      <TourContent
        endDialog={false}
        headerText={translate('Welcome to OPENSOLAR v2.0!')}
        subText={translate(
          "The OpenSolar 2.0 tour is only available on larger screens. Next time you login on a tablet or a computer you'll be asked if you want to take the tour."
        )}
        onClose={() => onAbortTour()}
      />
    )
  }
  return (
    <>
      <TourContent
        endDialog={false}
        headerText={translate('Welcome to OPENSOLAR v2.0!')}
        subText={translateParse(
          "This quick, interactive tour will guide you through creating your first customer proposal in less than <strong>four minutes<strong>. Let's get started!",
          { strong: (label) => <strong>{label}</strong> }
        )}
        onClose={() => (hasPermissionsToStart ? setAlertDialog(true) : onAbortTour())}
        actionLabel={hasPermissionsToStart ? translate('Start Tour') : undefined}
        handleAction={onStartTour}
      />
      {!hasPermissionsToStart && (
        <Alert severity="error" style={{ margin: 20 }}>
          <div>
            Due to your restricted permissions, you would not be able to complete the tour. You can contact your Admin
            to adjust your permissions. If you have questions about how to use the app, you can visit our HelpCenter at{' '}
            <a href="https://support.opensolar.com/hc/en-us" target="_blank" rel="noreferrer">
              https://support.opensolar.com/hc/en-us.
            </a>
          </div>
        </Alert>
      )}
      <ConfirmModal
        open={showAlertDialog}
        setOpen={setAlertDialog}
        title={translate('Are you sure you want to skip?')}
        mainText={translate(
          'The tour will take you less than 4 minutes and is the best way for you to quickly learn your way around OpenSolar.'
        )}
        submitBtnLabel={translate('Skip Tour')}
        handleSubmit={() => onAbortTour()}
        submitBtnProps={{ variant: 'contained' }}
      />
    </>
  )
}

const FujiTourStart: PromoDialog = {
  type: 'tour',
  priority: -5,
  show_again_rule: 'once-per-session',
  conditions: [],
  elements: [
    {
      type: 'react-component',
      render: FujiContent,
    },
  ],
}

const FujiTourStruct: Struct<PromoDialog> = {
  key: 'fuji-tour',
  type: 'promo-dialog',
  data: FujiTourStart,
}

const tourToPromo: { [tourType in FujiTourType]: Struct<PromoDialog> | undefined } = {
  main: FujiTourStruct,
  manage: undefined,
  project_list: undefined,
  payments: undefined,
}

interface TourContentProps {
  onClose?: () => void
  endDialog: boolean
  headerText?: ReactNode
  subText?: ReactNode
  actionLabel?: string
  handleAction?: () => void
  imgSrc?: string
}
const TourContent: FC<TourContentProps> = ({
  endDialog,
  headerText,
  subText,
  imgSrc,
  onClose,
  actionLabel,
  handleAction,
}) => {
  return (
    <ComponentVersionsInherit versions={ComponentVersions_3_0}>
      <StyledCont>
        {endDialog && <Confetti width={510} height={250} numberOfPieces={250} recycle={false} />}
        {(onClose || headerText) && (
          <StyledHeader>
            <span>{headerText}</span>
            {onClose && <DialogCloseButton onClick={onClose} />}
          </StyledHeader>
        )}

        {subText && <Subheader>{subText}</Subheader>}
        {imgSrc && (
          <ModalPreviewContainer>
            <img
              src={imgSrc}
              alt="Preview"
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'contain',
                pointerEvents: 'none',
              }}
            />
          </ModalPreviewContainer>
        )}
        {actionLabel && (
          <ActionBtn onClick={handleAction} variant="contained">
            {actionLabel}
          </ActionBtn>
        )}
      </StyledCont>
    </ComponentVersionsInherit>
  )
}

const StyledCont = styled('div', { name: 'StyledCont' })({
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
})

const StyledHeader = styled(DialogTitle, { name: 'StyledHeader' })({
  width: '100%',
  boxSizing: 'border-box',
})

const Subheader = styled('div', { name: 'Subheader' })({
  marginTop: 20,
  maxWidth: 450,
  lineHeight: '20px',
})

const ActionBtn = styled(Button, { name: 'ActionBtn' })({
  margin: '20px 0 24px',
})

const ModalPreviewContainer = styled('div', { name: 'ModalPreviewContainer' })({
  width: '100%',
  borderRadius: 4,
  overflow: 'hidden',
  pointerEvents: 'none',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: '0px 0px 10px 0px rgb(0 0 0 / 25%)',
})
