import { withWidth } from '@material-ui/core'
import { terminateCurrentTour, updateTourStep } from 'actions/tourActions'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { authSelectors, removeTourBanner } from 'ducks/auth'
import { FujiTourType } from 'ducks/tour'
import { useShowTourBanner } from 'hooks/useShowTourBanner'
import { recordUserAction } from 'persistentContent/tours/tools/common'
import ConfirmModal from 'projectSections/sections/manage3/common/ConfirmModal'
import { useTranslate } from 'ra-core'
import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FujiTour from '.'
import BannerDialog from './BannerDialog'
import { TOUR_CONFIG } from './util'

interface PropTypes {
  width: string
  tourType: FujiTourType
}

const TourBanner: FC<PropTypes> = ({ width, tourType }) => {
  const dispatch = useDispatch()
  const translate = useTranslate()
  const { tour } = useSelector((state: any) => state.tour)
  const shouldShowBanner = useShowTourBanner(width, tourType)
  const [displayBanner, setDisplayBanner] = useState(true)
  const [confirmDialog, showConfirm] = useState(false)
  const [endDialog, showEndDialog] = useState(false)
  const userId = useSelector(authSelectors.getCurrentUserId)
  const tourConfig = TOUR_CONFIG[tourType] || {}
  const { startBanner, confirmSkipText, finishBanner } = tourConfig
  useEffect(() => {
    if (shouldShowBanner.show) logAmplitudeEvent('fuji_banner_shown', { tourType })
    return () => {
      handleHide() // Add this to prevent re opening the tour after user navigates to another page while the tour is still active
    }
  }, [])

  const handleHide = () => {
    if (userId) recordUserAction(userId, tourConfig.hidden)
    logAmplitudeEvent('fuji_banner_hidden', { tourType })
    dispatch(removeTourBanner(true, tourType))
    dispatch(terminateCurrentTour(tour))
    showConfirm(false)
    setDisplayBanner(false)
  }

  const handleStart = () => {
    logAmplitudeEvent('fuji_tour_started', { tourType })
    setDisplayBanner(false)
    dispatch(updateTourStep(tourConfig.startStep, 'details'))
  }

  const handleDialogCloseClick = () => {
    logAmplitudeEvent('fuji_confirm_cancel_dialog_shown', { tourType })
    showConfirm(true) // Show the confirmation dialog when the close button is clicked
  }

  const handleEndTour = () => {
    if (userId) recordUserAction(userId, tourConfig.finishStep)
    dispatch(removeTourBanner(true, tourType))
    dispatch(terminateCurrentTour(tour))
    showEndDialog(false)
  }

  if (!shouldShowBanner.show) return null
  return (
    <>
      <BannerDialog
        endDialog={false}
        open={displayBanner}
        onClose={handleDialogCloseClick}
        headerText={startBanner.header}
        subText={startBanner.subText}
        actionLabel="Get Started"
        handleAction={handleStart}
        imgSrc={startBanner.imgSrc}
      />
      <ConfirmModal
        open={confirmDialog}
        setOpen={showConfirm}
        title={translate('Are you sure you want to skip?')}
        mainText={translate(confirmSkipText)}
        submitBtnLabel={translate('Skip Tour')}
        handleSubmit={handleHide}
        submitBtnProps={{ variant: 'contained' }}
      />
      <BannerDialog
        open={endDialog}
        endDialog={endDialog}
        onClose={handleEndTour}
        headerText={finishBanner.header}
        subText={finishBanner.subText}
        actionLabel="Learn more"
        handleAction={() => {
          window.open('https://support.opensolar.com/hc/en-us/sections/11007475981839-New-Manage-UI', '_blank')
          handleEndTour()
        }}
      />
      <FujiTour
        tourConfig={tourConfig}
        toggleEndDialog={() => {
          showEndDialog(true)
        }}
      />
    </>
  )
}
export default withWidth()(TourBanner)
