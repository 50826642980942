import { Chip, styled } from 'opensolar-ui'
import Skeleton from 'react-loading-skeleton'

interface PropTypes {
  isSelected: boolean
  count?: number | null
}

const StyledChip = styled(Chip)<{
  isSelected: boolean
}>(({ isSelected, theme }) => ({
  height: 18,
  fontSize: 10,
  borderRadius: 3,
  color: isSelected ? undefined : theme.palette.grey[700],
}))

const ViewButtonCount: React.FC<PropTypes> = ({ count, isSelected }) => {
  return (
    <>
      {count !== undefined ? (
        <StyledChip isSelected={isSelected} label={count} color={isSelected ? 'info' : 'default'} />
      ) : (
        <Skeleton width={18} />
      )}
    </>
  )
}
export default ViewButtonCount
