// TODO: replace existing use case in main app
export var getIsPhoenixV2 = function (integationExternalReference) {
    if (!integationExternalReference)
        return false;
    else
        return integationExternalReference.includes('finance_id');
};
// TODO: replace existing use case in main app
export var getAcceptProposalText = function (actionTitle, quoteAcceptanceContent, paymentAmount) {
    if (actionTitle)
        return actionTitle;
    if ((!quoteAcceptanceContent || quoteAcceptanceContent.length === 0) && paymentAmount > 0)
        return 'Make Payment';
    return 'Accept Proposal';
};
