import { useUserActionsContext } from 'contexts/userActions/useUserActionsContext'
import { addTourBanner, removeTourBanner } from 'ducks/auth'
import { FujiTourType, tourSelectors } from 'ducks/tour'
import { useUxVersion } from 'hooks/useUxVersion'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { RootState } from 'types/state'
import { useToursAvailable } from './useToursAvailable'

const TOUR_BANNER_CRITERIA: { [tourType in FujiTourType]: TourBannerCriteria } = {
  main: {
    actions: ['fuji_tour-finish', 'fuji_tour-tour_hidden'],
    pages: ['/home'],
  },
  manage: { actions: ['fuji_tour-manage_tour_finish', 'fuji_tour-manage_tour_hidden'], pages: [], ux3_only: true },
  project_list: { actions: ['fuji_tour-list_tour_finish', 'fuji_tour-list_tour_hidden'], pages: [], ux3_only: true },
  payments: { actions: [], pages: [], ux3_only: true },
}

type TourBannerCriteria = {
  actions: string[]
  pages: string[]
  ux3_only?: boolean
}

/**
 * This hook auto-shows tour banners based on whether the finish/hidden actions have been taken.
 */
export const useTourAutoShow = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { loaded: actionsLoaded, checkAction } = useUserActionsContext()
  const shouldShowTours = useSelector((state: RootState) => state?.auth?.user?.show_tour_banner) || {}
  const toursAvailable = useToursAvailable()
  const ux3 = useUxVersion() === 3

  // Auto-show tour banner criteria met
  useEffect(() => {
    if (!toursAvailable || !actionsLoaded) return
    for (const i in TOUR_BANNER_CRITERIA) {
      const tour = i as FujiTourType
      const shouldShowTour = shouldShowTours[tour]
      const bannerCriteria = TOUR_BANNER_CRITERIA[tour]
      const hideTourActions = bannerCriteria.actions.filter((x) => checkAction(x))
      const onPage = bannerCriteria.pages.includes(location.pathname)
      let shouldShow = onPage && !hideTourActions.length
      if (bannerCriteria.ux3_only && !ux3) shouldShow = false
      if (shouldShowTour === undefined || (shouldShow && !shouldShowTour.shouldShow && !shouldShowTour.userHidIt)) {
        dispatch(!shouldShow ? removeTourBanner(true, tour) : addTourBanner(tour))
      }
    }
  }, [toursAvailable, shouldShowTours, actionsLoaded, location.pathname, checkAction, ux3])

  // Auto-hide tour banner when tour starts
  const tour = useSelector(tourSelectors.tour)
  useEffect(() => {
    //TODO: clean this up
    const tourFixed: FujiTourType = tour === 'fuji' ? 'main' : (tour as FujiTourType)
    if (tour && shouldShowTours[tourFixed]?.shouldShow) {
      dispatch(removeTourBanner(false, tourFixed))
    }
  }, [tour, shouldShowTours])
}
