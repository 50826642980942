import { CircularProgress } from '@material-ui/core'
import { styled } from 'opensolar-ui'

export const InputContainer = styled('div', { name: 'InputContainer' })({
  width: '100%',
  margin: '10px 5px',
  display: 'flex',
  flexDirection: 'column',
  gap: 10,
})

export const InputRow = styled('div', { name: 'InputRow' })(({ theme }) => ({
  display: 'flex',
  gap: 10,
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}))

export const BlueWrapper = styled('div', { name: 'BlueWrapper' })(({ theme }) => ({
  backgroundColor: theme.palette.grey[50],
  borderRadius: 4,
  padding: 16,
}))

export const SectionHeader = styled('h3', { name: 'SectionHeader' })(({ theme }) => ({
  fontSize: 16,
  fontWeight: 600,
  color: theme.palette.grey[810],
  marginBottom: 10,
}))

export const Progress = styled(CircularProgress, { name: 'Progress' })({
  marginTop: 15,
})

export const SolarOutlet = styled('div', { name: 'SolarOutlet' })(({ theme }) => ({
  alignSelf: 'flex-start',
  '& .subtext': {
    margin: 0,
    marginTop: 10,
    fontSize: 12,
    color: theme.palette.grey[700],
  },
}))
