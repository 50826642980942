import { Typography } from '@material-ui/core'
import { Button, styled } from 'opensolar-ui'
import { HardwareSupplierFilterKeyType } from 'pages/ordering/type'
import { useFormState } from 'react-final-form'

const Container = styled('div', { name: 'Container' })({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
})

const OrderDistributor = () => {
  const formState = useFormState()
  const { values } = formState
  const isOrder = values?.is_order
  const status = values?.status
  const distributor = values?.distributor

  if (!distributor) return null

  const getMsg = () => {
    switch (status) {
      case 'Quote Submitted':
        return 'To convert this quote to an order, make changes, or cancel, view your quote in the '
      case 'Ordered':
        return 'To review order in more detail, view it here in the '
      case 'Processing':
        return 'For live updates and tracking, view your order in the '
      case 'Delivered':
        return 'This order has been marked as delivered, for more details, view your order in the '
      default:
        return ''
    }
  }

  const getUrl = () => {
    if (distributor === 'segen') {
      if (!isOrder) return 'https://portal.segen.co.uk/Reseller/MyAccount/Quotes'
      if (isOrder || status === 'Processing' || status === 'Delivered')
        return 'https://portal.segen.co.uk/Reseller/OrderSummary/Summary'
    }
    if (distributor === 'city_plumbing')
      return 'https://www.cityplumbing.co.uk/job-list/0d4ee136-3a89-aa3c-3b50-25df58ab41c5'
    if (distributor === 'outlet') return 'https://solaroutlet.com.au/account'
    if (distributor === 'hdm') return ''

    return ''
  }

  return (
    <Container>
      <Typography variant="subtitle1">{getMsg()}</Typography>
      <DistributorPortalButton distributor={distributor} url={getUrl()} color="info" textDecoration="underline" />
    </Container>
  )
}

export const DistributorPortalButton = ({
  distributor,
  url,
  label,
  variant = 'text',
  color = 'default',
  textDecoration = 'underline',
}: {
  distributor: HardwareSupplierFilterKeyType
  url: string
  label?: string
  variant?: 'text' | 'outlined' | 'contained'
  color?: 'info' | 'error' | 'warning' | 'success' | 'default'
  textDecoration?: 'underline' | 'none'
}) => {
  const getDistributorName = () => {
    if (distributor === 'segen') return 'Segen'
    if (distributor === 'city_plumbing') return 'City Plumbing'
    if (distributor === 'outlet') return 'Solar Outlet'
    if (distributor === 'hdm') return 'HDM'

    return ''
  }

  return (
    <Button
      size="small"
      variant={variant}
      color={color}
      onClick={() => window.open(`${url}`, '_blank')}
      disabled={url === ''}
      style={{ textDecoration: textDecoration }}
    >
      {label ? label : `${getDistributorName()} Portal`}
    </Button>
  )
}

export default OrderDistributor
