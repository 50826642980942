import { useStructs } from 'contexts/structs/hooks/useStructs'
import { Struct } from 'contexts/structs/types/types'
import { createSystemCondition, SystemConditionDef } from 'Designer/designRules/condition'
import { createSystemEffect, SystemEffectDef } from 'Designer/designRules/effect'
import {
  CustomSignal,
  CustomSignalCallback,
  DesignRuleRuntime,
  DesignRuleSetDef,
  DesignRuleStateMachine,
  SignalPayload,
  SystemCondition,
  SystemContext,
  SystemEffect,
  SystemEffectRuntime,
  SystemTriggerInfo,
} from 'Designer/designRules/types'
import { authSelectors } from 'ducks/auth'
import { orgSelectors } from 'ducks/orgs'
import { useLoadExhibitors } from 'hooks/useLoadExhibitors'
import RefParser from 'json-schema-ref-parser'
import lodash from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { useLoadPremiumProducts } from 'Studio/hooks/useLoadPremiumProducts'
import { useStudioSignalsCollate } from 'Studio/signals/useStudioSignalsCollate'
import { useStudioSignalsLazy } from 'Studio/signals/useStudioSignalsLazy'
import { StudioItem, StudioSystemType } from 'types/global'
import { OrgType } from 'types/orgs'
import { ProjectType } from 'types/projects'
import { RootState } from 'types/state'

function usePropMapper<RecordType>() {
  const callbacksMap: Record<string, RecordType[]> = useMemo(() => ({}), [])

  const register = useCallback((keys: string[], callback: RecordType) => {
    keys.forEach((key) => {
      let list = callbacksMap[key]
      if (!list) list = callbacksMap[key] = []
      if (list.indexOf(callback) === -1) {
        list.push(callback)
      }
    })
  }, [])

  const clear = useCallback(() => {
    Object.keys(callbacksMap).forEach((key) => {
      delete callbacksMap[key]
    })
  }, [])

  const forEach = useCallback((key: string, callback: (value: RecordType) => void) => {
    const list = callbacksMap[key]
    if (list) list.forEach(callback)
  }, [])

  return { register, clear, forEach }
}

const useDesignRulesEngine = () => {
  const structsUnresolved = useStructs<DesignRuleSetDef>('design-rule').structs
  const [structs, setStructs] = useState<Struct<DesignRuleSetDef>[] | undefined>(undefined)

  const org = useSelector(orgSelectors.getOrg) as OrgType
  const form = useForm()
  const project = useFormState().values as ProjectType
  const [system, setSystem] = useState(window.editor.selectedSystem)
  const componentSpecsReady = window.AccountHelper.isLoaded()
  const dispatch = useDispatch()
  const { exhibitors, exhibitorLoading } = useLoadExhibitors()
  const role = useSelector(authSelectors.getCurrentRole)
  const contextRef = useRef<SystemContext>({ dispatch, sessionData: {} } as SystemContext)

  const studioSignals = usePropMapper<CustomSignalCallback>()
  const premiumProducts = useLoadPremiumProducts()
  const reduxValueCache = useRef<ReduxValueCache[]>([])

  const recheckReduxCache = useCallback((state: RootState) => {
    for (let i = 0; i < reduxValueCache.current.length; i++) {
      const cache = reduxValueCache.current[i]
      const values = cache.paths.map((p) => lodash.get(state, p))
      const changed = cache.paths.filter((p, i) => values[i] !== cache.values[i])
      // Shallow comparison
      if (changed.length) {
        cache.values = values
        cache.callback({ statePathsChanged: changed })
      }
    }
  }, [])

  const reduxState = useSelector((state: RootState) => {
    recheckReduxCache(state)
    return state
  })

  useEffect(() => {
    if (!structsUnresolved) {
      setStructs(undefined)
      return
    }
    const promises = structsUnresolved.map(async (struct) => {
      const resolved = (await RefParser.dereference(struct.data, RefOptions)) as DesignRuleSetDef
      return {
        ...struct,
        data: resolved,
      }
    })
    Promise.allSettled(promises).then((results) => {
      const resolved: Struct<DesignRuleSetDef>[] = []
      for (const i in results) {
        const result = results[i]
        const struct = structsUnresolved[i]
        if (result.status === 'fulfilled') {
          resolved.push(result.value)
        } else {
          console.error(`DesignRules: Error parsing rule: ${struct.key}`, result.reason)
        }
      }
      if (window.debugDesignRules) console.log('DesignRules: Rules parsed', resolved)
      setStructs(resolved)
    })
  }, [structsUnresolved])

  // Keep context updated in a way that doesn't require re-running all rules
  useEffect(() => {
    contextRef.current.project = project
  }, [project])
  useEffect(() => {
    contextRef.current.org = org
  }, [org])
  useEffect(() => {
    contextRef.current.system = system
  }, [system])
  useEffect(() => {
    contextRef.current.exhibitors = exhibitors
  }, [exhibitors])
  useEffect(() => {
    contextRef.current.premiumProducts = premiumProducts || []
  }, [premiumProducts])
  useEffect(() => {
    contextRef.current.state = reduxState
  }, [reduxState])
  useEffect(() => {
    contextRef.current.role = role
  }, [role])

  const handleStudioSignal = useCallback(
    (item: StudioItem, type: 'added' | 'removed' | 'changed', signalPayload: SignalPayload) => {
      let customSignal: CustomSignal | undefined
      switch (item.type) {
        case 'OsBattery': {
          customSignal = ('battery-' + type) as CustomSignal
          break
        }
        case 'OsInverter': {
          customSignal = ('inverter-' + type) as CustomSignal
          break
        }
        case 'OsOther': {
          customSignal = ('other-component-' + type) as CustomSignal
          break
        }
        case 'OsModule': {
          customSignal = ('panel-' + type) as CustomSignal
          break
        }
        case 'OsSystem': {
          customSignal = ('system-' + type) as CustomSignal
          break
        }
        default: {
          break
        }
      }
      if (customSignal) {
        studioSignals.forEach(customSignal, (cb) => cb(customSignal as CustomSignal, item, signalPayload))
      }
    },
    []
  )

  useStudioSignalsCollate((allArgs) => allArgs.forEach(([item]) => handleStudioSignal(item, 'added', {})), [
    'objectAdded',
  ])
  useStudioSignalsCollate((allArgs) => allArgs.forEach(([item]) => handleStudioSignal(item, 'removed', {})), [
    'objectRemoved',
  ])
  useStudioSignalsCollate(
    (allArgs) =>
      allArgs.forEach(([item, attributeName, signalPayload]) =>
        handleStudioSignal(item, 'changed', { attributeName, ...signalPayload })
      ),
    ['objectChanged']
  )

  useEffect(() => {
    contextRef.current.activeComponents = {
      getModules: () => window.AccountHelper.getComponentModuleSpecsAvailable(),
      getInverters: () => window.AccountHelper.getComponentInverterSpecsAvailable(),
      getBatteries: () => window.AccountHelper.getComponentBatterySpecsAvailable(),
      getOthers: () => window.AccountHelper.getComponentOtherSpecsAvailable(),
      getAll: () => {
        return [
          ...window.AccountHelper.getComponentModuleSpecsAvailable(),
          ...window.AccountHelper.getComponentInverterSpecsAvailable(),
          ...window.AccountHelper.getComponentBatterySpecsAvailable(),
          ...window.AccountHelper.getComponentOtherSpecsAvailable(),
        ]
      },
    }
  }, [componentSpecsReady])

  const checkDesignRuleBehaviour = useCallback(async (rule: DesignRuleRuntime, trigger: SystemTriggerInfo) => {
    const stateMachine = rule.stateMachine
    if (stateMachine) {
      // abort if already checking
      if (stateMachine.checking) return

      // abort the call if the rule is after the current match
      if (
        stateMachine.behaviour === 'exclusive-trigger-once' &&
        stateMachine.currentMatch !== -1 &&
        stateMachine.currentMatch < stateMachine.allRuntimes.indexOf(rule)
      )
        return

      stateMachine.checking = true

      let currentMatch = -1
      for (let i = 0; i < stateMachine.allRuntimes.length; i++) {
        const runtime = stateMachine.allRuntimes[i]
        const matched = await checkDesignRule(
          runtime,
          trigger,
          stateMachine.behaviour === 'exclusive-trigger-once' && i === stateMachine.currentMatch
        )
        if (matched) {
          currentMatch = i
          break
        }
      }
      stateMachine.currentMatch = currentMatch
      stateMachine.checking = false
    } else {
      checkDesignRule(rule, trigger)
    }
  }, [])

  const checkDesignRule = useCallback(
    async (rule: DesignRuleRuntime, trigger: SystemTriggerInfo, suppressEffects?: boolean) => {
      if (rule.checking) {
        if (window.debugDesignRules) console.debug(`DesignRules: Rule '${rule.id}' is already checking, skipping check`)
        return
      }

      // Important to copy the context here to avoid design rules contaminating each other's context
      const context = { ...contextRef.current }

      if (!context.system) return //TODO change for non-system scoped conditions

      // Prevent recursion
      rule.checking = true

      context.systemRuleKey = `DesignRules:${rule.ruleId}:${context.system.uuid}`
      context.trigger = trigger

      context.runCondition = (condition: SystemCondition, def: SystemConditionDef): boolean => {
        const ret = !!condition.check(context) === !def.negate
        if (window.debugDesignRules) console.debug(`\tDesignRules: Checking condition: `, ret, def, context)
        return ret
      }
      context.executeEffect = (effect: SystemEffect, def: SystemEffectDef) => {
        if (window.debugDesignRules) console.debug(`\tDesignRules: Executing effect: `, def)
        return effect.execute(context)
      }

      let matched = context.runCondition(rule.condition, rule.conditionDef)
      if (matched) {
        if (window.debugDesignRules) console.debug(`DesignRules: PASSED '${rule.id}': `, context)
        if (!suppressEffects) {
          for (const e of rule.effects) {
            await context.executeEffect(e, e.def)
          }
        } else {
          if (window.debugDesignRules) console.debug(`DesignRules: Suppressing effects '${rule.id}': `, context)
        }
      } else {
        if (window.debugDesignRules) console.debug(`DesignRules: NOT PASSED '${rule.id}': `, context)
      }

      rule.checking = false
      return matched
    },
    []
  )

  const handleObjectSelected = useCallback((object: StudioItem) => {
    // detect when the currently selected system is changed
    if (object?.type === 'OsSystem') {
      setSystem(() => object as StudioSystemType)
      studioSignals.forEach('system-selected', (cb) => cb('system-selected', object))
    }
  }, [])

  useStudioSignalsLazy(handleObjectSelected, ['objectSelected']) // is the scope arg really necessary? it doesn't work otherwise...

  useEffect(() => {
    if (structs && componentSpecsReady && premiumProducts && !exhibitorLoading) {
      studioSignals.clear()
      const r: DesignRuleRuntime[] = []
      const unsubs: (() => void)[] = []

      for (const struct of structs) {
        let stateMachine: DesignRuleStateMachine | undefined
        if (struct.data.behaviour) {
          stateMachine = {
            checking: false,
            behaviour: struct.data.behaviour,
            currentMatch: -1,
            allRuntimes: [],
          }
        }
        for (let i = 0; i < struct.data.rules.length; i++) {
          const rule = struct.data.rules[i]
          let condition: SystemCondition
          try {
            condition = createSystemCondition(rule.condition)
          } catch (e) {
            console.error(`DesignRules: Error creating condition for rule ${struct.key}[${i}]`, e)
            continue
          }
          let effects: SystemEffectRuntime[]

          try {
            effects = rule.effects.map((e) => {
              return {
                def: e,
                ...createSystemEffect(e),
              }
            })
          } catch (e) {
            console.error(`DesignRules: Error creating effect for rule ${struct.key}[${i}]`, e)
            continue
          }
          const runtime: DesignRuleRuntime = {
            id: `${struct.key}[${i}]`,
            ruleId: struct.key,
            condition,
            effects,
            conditionDef: rule.condition,
            checking: false,
            stateMachine,
          }
          if (stateMachine) stateMachine.allRuntimes.push(runtime)
          r.push(runtime)

          // Register redux paths
          if (condition.getReduxPaths) {
            const paths = lodash.uniq(condition.getReduxPaths())
            if (paths.length) {
              reduxValueCache.current.push({
                callback: (trigger) => checkDesignRuleBehaviour(runtime, trigger),
                paths,
                values: paths.map((p) => lodash.get(reduxState, p)),
              })
            }
          }

          // Register signals
          if (condition.getStudioSignals) {
            const signals = lodash.uniq(condition.getStudioSignals())
            if (signals.length) {
              studioSignals.register(
                signals,
                (studioSignal: string, studioItem?: StudioItem, signalPayload?: SignalPayload) => {
                  checkDesignRuleBehaviour(runtime, { studioSignal, studioItem, signalPayload })
                }
              )
            }
          }

          // Register project fields
          if (condition.getProjectFields) {
            const fields = lodash.uniq(condition.getProjectFields())
            fields.forEach((f) => {
              unsubs.push(
                form.registerField(
                  f,
                  (update) =>
                    setTimeout(
                      () => checkDesignRuleBehaviour(runtime, { projectField: f, projectFieldValue: update.value }),
                      0
                    ),
                  {
                    value: true,
                  }
                )
              )
            })
          }

          if (struct.data.behaviour) {
            checkDesignRuleBehaviour(runtime, { initial: true }) // check for the first time
          }
        }
        if (stateMachine && stateMachine.allRuntimes.length) {
          // check for the first time for state-machine rules
          checkDesignRuleBehaviour(stateMachine.allRuntimes[0], { initial: true })
        }
      }

      return () => unsubs.forEach((u) => u())
    }
  }, [structs, componentSpecsReady, premiumProducts, exhibitorLoading])
}

export default useDesignRulesEngine

type ReduxValueCache = {
  callback: (trigger: SystemTriggerInfo) => void
  paths: string[]
  values: any[]
}

const RefOptions = {
  resolve: {
    external: false,
  },
}
