import {
  addOrderLineItem,
  deleteLineItems,
  orderSelectors,
  updateLineItemSupplier,
  updateOrderQuantity,
  updateOrderQuantityByCode,
} from 'ducks/orderComponents'
import OrderLineItem from 'pages/ordering/OrderLineItem'
import { HardwareSupplierFilterKeyType } from 'pages/ordering/type'
import { useDispatch, useSelector } from 'react-redux'
import { ComponentLikeType, DistributorDataTypeV2 } from '../elements/hardwareSelectorV2/types'
import { mapComponentTypesV2ToV1 } from '../util/misc'

export const useGlobalCart = () => {
  const dispatch = useDispatch()
  const orderableLineItems = useSelector(orderSelectors.getOrderLineItems)

  const addLineItem = (lineItem: OrderLineItem) => {
    dispatch(addOrderLineItem(lineItem))
  }

  const addLineItemFromV2Component = (data: ComponentLikeType, orderingDetails: DistributorDataTypeV2) => {
    // Create new OrderLineItem instance
    const lineItem = new OrderLineItem({
      code: data.code,
      selectedDistributor: orderingDetails.distributor,
      selectedDistributorOrderingData: orderingDetails,
      quantity: 1,
      pricePerUnit: orderingDetails.price_detail.price_ex_vat,
      variantId: orderingDetails.variant_id,
      variantDiscounts: orderingDetails.volume_discounts,
      stockLevels: orderingDetails.stock_levels,
      componentType: mapComponentTypesV2ToV1(data.type),
      componentTypeV2: data.type
    })

    dispatch(addOrderLineItem(lineItem))
  }

  const updateLineItemQty = (uuid: string, quantity: number) => {
    dispatch(updateOrderQuantity({ uuid, quantity }))
  }

  const updateLineItemQtyByCode = (code: string, quantity: number) => {
    dispatch(updateOrderQuantityByCode({ code, quantity }))
  }

  const changeLineItemSupplier = (orderingData: any, supplier: HardwareSupplierFilterKeyType, code: string) => {
    dispatch(updateLineItemSupplier({ data: orderingData, code, supplier }))
  }

  const removeLineItem = (uuid) => {
    dispatch(deleteLineItems({ source: 'uuid', identifiers: [uuid] }))
  }

  return {
    orderableLineItems,
    addLineItem,
    addLineItemFromV2Component,
    updateLineItemQty,
    updateLineItemQtyByCode,
    changeLineItemSupplier,
    removeLineItem,
  }
}
