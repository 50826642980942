import UserIcon from 'elements/UserIcon'
import { styled } from 'opensolar-ui'
import React from 'react'
import { StageActionEventType } from 'types/projects'
import { getEventIcon } from '../util'

const EventIconStyle = styled('div', { name: 'EventIconStyle' })(({ theme }) => ({
  backgroundColor: theme.palette.secondary.main,
  fill: theme.palette.grey[810],
  borderRadius: 20,
  height: 20,
  width: 20,
  padding: 6,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

interface PropTypes {
  event: StageActionEventType
  minDisplay?: boolean
}

const EventIcon: React.FC<PropTypes> = ({ event, minDisplay }) => {
  const EventIcon = getEventIcon(event.event_icon, minDisplay) || null
  return (
    <div style={{ marginBottom: 'auto' }}>
      {EventIcon === null && event.who && <UserIcon user={event.who} size={minDisplay ? 32 : undefined} />}
      {EventIcon !== null && <EventIconStyle>{EventIcon}</EventIconStyle>}
    </div>
  )
}

export default EventIcon
