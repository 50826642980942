import { TextField, Typography } from '@material-ui/core'

import { countryMapping } from 'constants/countries'
import { isEmpty, values } from 'lodash'
import { styled } from 'opensolar-ui'
import { useNotify, useTranslate } from 'ra-core'
import { ChangeEvent, memo, useContext, useState } from 'react'
import { useSelector } from 'react-redux'
import AddressAutocomplete from 'resources/projects/elements/AddressAutocomplete.js'
import { RootState } from 'types/state'
import AddressCompletionForm from '../AddressCompletionForm'
import { CheckoutPresenterContext, ShippingAddressContext } from './HdmCheckoutProvider'

const MainContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
})

const AddressFieldContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
})

const ClickableLink = styled(Typography)({
  textDecoration: 'underline',
  color: '#1751D0',
  cursor: 'pointer',
})

const NameSectionContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  gap: '10px',
})

const AddressField = ({ handleUpdateShippingAddress }) => {
  const notify = useNotify()

  const hdmCheckoutPresenter = useContext(CheckoutPresenterContext)
  const address = useContext(ShippingAddressContext)
  const { contactName, companyName, country, county, postCode, address1, town } = address || {}
  const [editing, setEditing] = useState<boolean>(false)
  const [isValidAddress, setIsValidAddress] = useState<boolean>(true)
  const apiKeyGoogle = useSelector((state: RootState) =>
    state.auth && state.auth.api_key_google ? state.auth.api_key_google : ''
  )

  const handleClick = () => {
    setEditing(!editing)
    setIsValidAddress(true)
  }

  const onPlaceSelected = (placeAsFields) => {
    const newShippingAddress = {
      ...(address || {}),
      address1: placeAsFields.address,
      town: placeAsFields.locality,
      county: placeAsFields.state,
      postCode: placeAsFields.zip,
      country: countryMapping[placeAsFields.country_iso2]?.name,
    }
    const isValidShippingAddress = hdmCheckoutPresenter?.validateShippingAddress(newShippingAddress)
    if (!isValidShippingAddress) {
      notify(
        'Your preferred address was not able to be automatically populated. Please complete your address manually',
        'warning'
      )
    }

    setEditing(!isValidShippingAddress)
    setIsValidAddress(!!isValidShippingAddress)
    handleUpdateShippingAddress(placeAsFields)
  }

  return (
    <AddressFieldContainer>
      {editing ? (
        <div style={{ flexGrow: 1, paddingRight: 50 }}>
          {isValidAddress ? (
            <AddressAutocomplete onPlaceSelected={onPlaceSelected} api_key_google={apiKeyGoogle} types={['address']} />
          ) : (
            <AddressCompletionForm
              handleUpdateAddress={onPlaceSelected}
              initialValues={{
                address: address1 || '',
                locality: town || '',
                state: county || '',
                zip: postCode || '',
                country_iso2: values(countryMapping)?.find((item) => item.name === country)?.iso2 || 'GB',
              }}
              requiredFields={['address', 'locality', 'state', 'zip', 'country_iso2']}
            />
          )}
        </div>
      ) : (
        <div>
          {contactName && (
            <Typography variant="body1" gutterBottom>
              {contactName}
            </Typography>
          )}
          {companyName && (
            <Typography variant="body1" gutterBottom>
              {companyName}
            </Typography>
          )}
          <Typography variant="body1" gutterBottom>
            {address1}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {county} {town} {postCode} {country}
          </Typography>
        </div>
      )}
      <ClickableLink variant="body1" gutterBottom onClick={handleClick}>
        {editing ? 'cancel' : 'enter/update address'}
      </ClickableLink>
    </AddressFieldContainer>
  )
}

const ShippingAddress = () => {
  const hdmCheckoutPresenter = useContext(CheckoutPresenterContext)
  const address = useContext(ShippingAddressContext)
  const translate = useTranslate()
  const isValidContactDetails = !isEmpty(address.contactFirstName) && !isEmpty(address.contactLastName)

  const validate = (updatedAddress) => {
    const isValidShippingAddress = hdmCheckoutPresenter?.validateShippingAddress(updatedAddress)
    const isValidContactDetails = !isEmpty(updatedAddress.contactFirstName) && !isEmpty(updatedAddress.contactLastName)
    return isValidShippingAddress && isValidContactDetails
  }

  const handleUpdateShippingAddress = (placeAsFields) => {
    const newShippingAddress = {
      ...(address || {}),
      address1: placeAsFields.address,
      town: placeAsFields.locality,
      county: placeAsFields.state,
      postCode: placeAsFields.zip,
      country: countryMapping[placeAsFields.country_iso2]?.name,
    }
    hdmCheckoutPresenter?.updateShippingAddress(newShippingAddress)
    const isValidShippingAddress = hdmCheckoutPresenter?.validateShippingAddress(newShippingAddress)

    hdmCheckoutPresenter?.updateOrderState({ key: 'isReady', value: validate(newShippingAddress) })
  }

  const handleUpdateContactPhone = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    hdmCheckoutPresenter?.updateShippingAddress({
      ...address,
      contactPhone: event.target.value,
    })
  }

  const handleUpdateContactFirstName = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const updatedAddress = {
      ...address,
      contactName: `${event.target.value} ${address?.contactLastName}`,
      contactFirstName: event.target.value,
    }
    hdmCheckoutPresenter?.updateShippingAddress(updatedAddress)
    hdmCheckoutPresenter?.updateOrderState({ key: 'isReady', value: validate(updatedAddress) })
  }

  const handleUpdateContactLastName = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const updatedAddress = {
      ...address,
      contactName: `${address.contactFirstName} ${event.target.value}`,
      contactLastName: event.target.value,
    }
    hdmCheckoutPresenter?.updateShippingAddress(updatedAddress)
    hdmCheckoutPresenter?.updateOrderState({ key: 'isReady', value: validate(updatedAddress) })
  }

  return (
    <MainContainer>
      <AddressField handleUpdateShippingAddress={handleUpdateShippingAddress} />
      <div>
        <TextField
          required
          label={'Mobile Number'}
          name="mobileNumber"
          value={address.contactPhone}
          onChange={handleUpdateContactPhone}
          error={isEmpty(address.contactPhone)}
          helperText={isEmpty(address.contactPhone) && translate('This field is required.')}
        />
      </div>
      <NameSectionContainer>
        <TextField
          required
          label={'First Name'}
          name="firstName"
          value={address.contactFirstName}
          onChange={handleUpdateContactFirstName}
          error={isEmpty(address.contactFirstName)}
          helperText={isEmpty(address.contactFirstName) && translate('This field is required.')}
        />
        <TextField
          required
          label={'Last Name'}
          name="lansName"
          value={address.contactLastName}
          onChange={handleUpdateContactLastName}
          error={isEmpty(address.contactLastName)}
          helperText={isEmpty(address.contactLastName) && translate('This field is required.')}
        />
      </NameSectionContainer>
    </MainContainer>
  )
}

export default memo(ShippingAddress)
