import { BarChartSquareIcon, BaseTheme, Button, styled, TableIcon } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import React, { useMemo } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { SectionHeader } from '../styles'

const MidSection = styled('div', { name: 'MidSection' })(({ theme }) => ({
  marginBottom: 25,
  paddingBottom: 15,
  borderBottom: '1px solid ' + theme.palette.secondary.light,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
}))

const ButtonStyled = styled(Button, { name: 'ButtonStyled' })<{
  isActive: boolean
}>(({ isActive, theme }) => ({
  justifyContent: 'flex-start',
  color: isActive ? theme.palette.blue[700] : undefined,
}))

const ViewOptions: React.FC = (props) => {
  const form = useForm()
  const translate = useTranslate()
  const formValues = useFormState().values
  const isTableView = useMemo(() => formValues.isTableView, [formValues.isTableView])

  const setTableView = (status) => {
    form.change('isTableView', status)
  }
  return (
    <>
      <SectionHeader>{translate('View Options')}</SectionHeader>
      <MidSection>
        <ButtonStyled
          isActive={!!isTableView}
          startIcon={<TableIcon />}
          disableRipple
          variant="text"
          color="secondary"
          onClick={() => {
            setTableView(true)
          }}
        >
          {translate('Table View')}
        </ButtonStyled>
        <Button
          startIcon={<BarChartSquareIcon transform={'rotate(180deg)'} />}
          disableRipple
          variant="text"
          color="secondary"
          onClick={() => {
            setTableView(false)
          }}
          style={{ color: isTableView ? undefined : BaseTheme.palette.blue[700] }}
        >
          {translate('Kanban View')}
        </Button>
      </MidSection>
    </>
  )
}

export default ViewOptions
