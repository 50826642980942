import restClient from 'restClient'
import { ExternalWorkflow } from 'types/workflows'
import { urlToId } from 'util/misc'

export const updateProjectWorkflows = (selectedWorkflow, newWorkflow, project, form, source) => {
  const prevWorkflow = project.workflows?.find((x) => x.workflow_id === selectedWorkflow?.workflow_id)
  form.change(source, {
    org_id: newWorkflow?.org_id,
    workflow_id: newWorkflow.workflow_id,
    active_stage_id: newWorkflow.active_stage_id,
  })

  const projWorkflow = project.workflows?.find((x) => x.workflow_id === newWorkflow.workflow_id)
  let updatedWorkflows = [...project.workflows]
  if (prevWorkflow && prevWorkflow !== projWorkflow) {
    updatedWorkflows[project.workflows?.indexOf(prevWorkflow)] = {
      ...prevWorkflow,
      is_selected: false,
    }
  }

  if (projWorkflow) {
    updatedWorkflows[project.workflows?.indexOf(projWorkflow)] = {
      ...projWorkflow,
      is_selected: true,
      active_stage_id: newWorkflow.active_stage_id,
    }
    form.change('workflows', updatedWorkflows)
  } else {
    form.change('workflows', [
      ...updatedWorkflows,
      { ...newWorkflow, id: 'new-workflow-' + project.workflows?.length, is_selected: true },
    ])
  }
}

export const getCurrentDate = () => {
  const dateNow = new Date()
  return `${dateNow.getFullYear()}-${dateNow.getMonth() + 1}-${dateNow.getDate()}`
}

export const isPricingLockedForStage = (project, workflows) => {
  const selectedWorkflow = project?.workflow
  const workflow = workflows?.find((x) => x.id === selectedWorkflow?.workflow_id)
  if (workflow) {
    const activeStage = workflow?.workflow_stages?.find((x) => x.id === selectedWorkflow?.active_stage_id)
    if (activeStage) return activeStage?.milestone > 0
  }
  return false
}

export const handleImplicitToggles = (workflow, project, form) => {
  const dirtyFields = form.mutators.getFormDirtyFields()
  const initialValues = form.getState().initialValues
  const workflow_stages = workflow.workflow_stages
  const findStage = workflow_stages?.find((x) => x.id === workflow.active_stage_id)
  if (findStage?.milestone !== undefined && (project.org === workflow.org || workflow.org_id === project.org_id)) {
    const milestone = findStage?.milestone
    if (milestone <= 1) {
      if (project.project_sold === 1) {
        form.change('project_sold', null)
        if (dirtyFields.includes('contract_date')) form.change('contract_date', initialValues.contract_date)
      }
      if (project.project_installed === 1) {
        form.change('project_installed', null)
        if (dirtyFields.includes('installation_date')) form.change('installation_date', initialValues.installation_date)
      }
    } else if (milestone > 1 && milestone < 3) {
      //if milestone is past SOLD
      if (project.project_sold === null) {
        form.change('project_sold', 1)
        if (!project.contract_date) form.change('contract_date', getCurrentDate())
      }
      if (project.project_installed === 1) {
        form.change('project_installed', null)
        if (dirtyFields.includes('installation_date')) form.change('installation_date', initialValues.installation_date)
      }
    } else if (milestone > 2 && milestone < 4) {
      //if milestone is past INSTALLED
      if (project.project_sold === null) {
        form.change('project_sold', 1)
        if (!project.contract_date) form.change('contract_date', getCurrentDate())
      }
      if (project.project_installed === null) {
        form.change('project_installed', 1)
        if (!project.installation_date) form.change('installation_date', getCurrentDate())
      }
    }
  }
}

export const getSharedWorkflows = (project, excludeOrgId: number | undefined) => {
  if (!!!project.shared_with?.length) return []
  const sharedWithIds = project?.shared_with?.map((x) => x.org_id)
  if (excludeOrgId === project?.org_id) {
    return project?.workflows?.filter((x) => x.org_id !== excludeOrgId && sharedWithIds.includes(x.org_id))
  } else {
    return project?.workflows.filter((x) => x.org_id === project?.org_id)
  }
}

const restClientInstance = restClient(window.API_ROOT + '/api')
export const retrieveExternalWorkflows = (project, orgId, notify, setWorkflows) => {
  const sharedWorkflows = getSharedWorkflows(project, orgId)
  if (!!sharedWorkflows?.length) {
    let externalOrgs: number[] = []
    sharedWorkflows?.forEach((x) => {
      if (!externalOrgs?.includes(x.org_id)) externalOrgs.push(x.org_id)
    })
    restClientInstance('CUSTOM_GET', 'custom', {
      url: `orgs/${orgId}/workflows/?owned_by=${externalOrgs.toString()}`,
    })
      .then((response) => {
        if (response.data && !!response.data.length) {
          let newWorkflows: ExternalWorkflow[] = []

          externalOrgs.forEach((org_id) => {
            if (!!!newWorkflows.filter((x) => x.org_id === org_id).length) {
              const orgWorkflows = response.data.filter((x) => urlToId(x.org) === org_id)
              newWorkflows.push({
                org_id: org_id,
                selectedWorkflow: sharedWorkflows.find((x) => x.is_selected && x.org_id === org_id),
                workflows: orgWorkflows,
              })
            }
          })
          setWorkflows(newWorkflows)
        }
      })
      .catch((error) => {
        const errMsg = typeof error === 'string' ? error : error.message || 'ra.notification.http_error'
        notify('There was an issue trying to retrieve shared workflows: ' + errMsg, 'warning')
      })
  }
}

export const updateProjectEvents = (response, project, form) => {
  const newEvent = response.data
  let updatedEvents = [...project.events_data]
  updatedEvents = updatedEvents.filter((x) => x.id !== newEvent.id)
  updatedEvents.unshift(newEvent)
  form.change(
    'events_data',
    updatedEvents
      .filter((x) => !x.is_archived)
      .sort((a, b) => {
        const firstDate = new Date(a.created_date)
        const secondDate = new Date(b.created_date)
        return secondDate.getTime() - firstDate.getTime()
      })
  )
  const eventAction = project.actions?.find((x) => x.url === newEvent.action)
  if (eventAction) {
    let indexUpdate = eventAction?.events?.length
    const eventToUpdate = eventAction?.events?.find((x) => x.id === newEvent.id)
    if (eventToUpdate) {
      indexUpdate = eventAction?.events?.indexOf(eventToUpdate)
    }
    let updatedEvents = [...(eventAction.events || [])]
    updatedEvents[indexUpdate] = newEvent

    let currActions = [...project.actions]
    currActions[project.actions?.indexOf(eventAction)] = { ...eventAction, events: updatedEvents }

    form.change('actions', currActions)
  }
}

export const deleteProjectEvent = (project, form, eventId, action) => {
  let updatedEvents = [...project.events_data]
  updatedEvents = updatedEvents.filter((x) => x.id !== eventId)
  form.change('events_data', updatedEvents)

  const eventAction = project.actions?.find((x) => x.id === action.id)
  if (eventAction) {
    let updatedEvents = [...(eventAction.events || [])]
    updatedEvents = updatedEvents.filter((x) => x.id !== eventId)
    let currActions = [...project.actions]
    currActions[project.actions?.indexOf(eventAction)] = { ...eventAction, events: updatedEvents }
    form.change('actions', currActions)
  }
}

export const idToUrl = (id, resource) => {
  return `${window.API_ROOT}/api/${resource}/${id}/`
}

export const getIsProjectSold = (project) => {
  const soldSystemURL = project?.system_sold
  const soldPmtURL = project?.payment_option_sold
  const soldSystem = project?.systems?.find((sys) => sys.url === soldSystemURL)

  return soldPmtURL !== null && soldSystem != null
}
