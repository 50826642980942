// @ts-nocheck
import React, { useState } from 'react'
// import Button from 'elements/proUXButtons/ProUXButton'
import { makeStyles } from '@material-ui/core'
import Button from 'elements/button/Button'
import BrighteAccountDialog from './BrighteAccountDialog'

type PropTypes = {
  buttonLabel?: string
  studioMode?: boolean
  editRoleMode?: boolean
  onTabOpened?: () => void
  roleId?: string | number
  disabled?: boolean
}

const useStyles = makeStyles((theme) => ({
  label: {
    textTransform: 'none',
  },
}))

const ConnectBrighteButton: React.FunctionComponent<PropTypes> = (props) => {
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const classes = useStyles()
  const onClick = () => {
    setLoading(true)
    setShowDialog(true)
  }
  return (
    <div>
      <Button
        classes={classes}
        variant="contained"
        color="default"
        onClick={onClick}
        label={props.buttonLabel || 'Connect Your Brighte Account'}
        loading={loading}
        disabled={props.disabled}
      />
      {showDialog && (
        <BrighteAccountDialog
          isOpen={showDialog}
          onClose={() => setShowDialog(false)}
          studioMode={props.studioMode}
          editRoleMode={props.editRoleMode}
          onTabOpened={props.onTabOpened}
          roleId={props.roleId}
          setLoading={setLoading}
        />
      )}
    </div>
  )
}
export default ConnectBrighteButton
