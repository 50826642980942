import { ProposalDataType } from 'types/proposals'

/**
 * Determines if the provided proposal section should be hidden for the provided payment option
 * @param {ProposalDataType} proposalData - The full proposalData object that is used to populate the online proposal.
 * @param {string} sectionName - the particular section name that we are trying to assess.
 * @param {number | null} paymentOptionId - the ID of the payment option that is to be shown to the user
 * @returns {boolean} returns True if this section should be hidden for the provided payment option
 */
export const getSectionShouldBeHidden = (
  proposalData: ProposalDataType,
  sectionName: string,
  paymentOptionId: number | null
) => {
  if (!paymentOptionId) return false
  return proposalData?.hidden_sections?.[sectionName]?.includes(paymentOptionId)
}
