import { setLoanDecisionRendered } from 'ducks/myEnergy'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { ProjectType } from 'types/projects'
import DividendApplicationDialog from './DividendApplicationDialog'
import { DisclosureType } from './types'

export type DividendApplicationDialogConfig = {
  orgId: number
  system_uuid: string
  paymentOptionId: number
  project: ProjectType
  disclosureText: DisclosureType
  isFullWidth?: boolean
}

const useDividendApplicationDialog = () => {
  const [state, seState] = useState<
    { open: false; config: undefined } | { open: true; config: DividendApplicationDialogConfig }
  >({
    open: false,
    config: undefined,
  })
  const dispatch = useDispatch()

  const openDialog = (config: DividendApplicationDialogConfig) => {
    seState({
      open: true,
      config,
    })
  }

  const closeDialog = () => {
    if (state.open === false) {
      return
    }
    seState({
      open: false,
      config: undefined,
    })
  }

  const dialogNode = state.open ? (
    <DividendApplicationDialog
      open={true}
      onClose={closeDialog}
      disclosureText={state.config.disclosureText}
      project={state.config.project}
      paymentOptionId={state.config.paymentOptionId}
      systemUuid={state.config.system_uuid}
      orgId={state.config.orgId}
      onDecisionRendered={() => dispatch(setLoanDecisionRendered())}
      isFullWidth={state.config.isFullWidth !== undefined ? state.config.isFullWidth : false}
    />
  ) : null
  return { isOpen: state.open, dialogNode, openDialog, closeDialog, type: 'DividendApplicationDialog' as const }
}

export default useDividendApplicationDialog
