import { useCallback } from 'react'
import { ProjectErrorType, SharingErrorMsg } from 'reducer/project/projectErrorsReducer'
import { filterShareMessage } from './filterShareMessage'

export const useSharingErrorFilter = (otherFilter?: (error: ProjectErrorType) => boolean, sharingSection?: string) => {
  return useCallback(
    (error: ProjectErrorType): boolean => {
      if (otherFilter && !otherFilter(error)) return false
      if (
        sharingSection &&
        error.source === 'sharing' &&
        !filterShareMessage(error.message as SharingErrorMsg, sharingSection)
      )
        return false
      return true
    },
    [otherFilter, sharingSection]
  )
}
