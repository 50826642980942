import { makeStyles } from '@material-ui/core/styles'
import SharedEntityIcon from 'elements/connectedOrgs/SharedEntityIcon'
import { styled } from 'opensolar-ui'
import { useTranslate } from 'ra-core'
import { ReactNode } from 'react'
import { useFormState } from 'react-final-form'
import { Theme } from 'types/themes'
import HoverEditWrapper from '../../common/HoverEditWrapper'
import AddressFormContent from './AddressFormContent'

const useStyles = makeStyles((theme: Theme) => ({
  address: {
    '& h1': {
      margin: '8px 0',
      fontSize: 20,
    },
    '& button': {
      display: 'none',
    },
    '&:hover': {
      '& button': {
        display: 'flex',
      },
    },
  },
}))

const GreyText = styled('p', { name: 'GreyText' })(({ theme }) => ({
  fontSize: 16,
  margin: 8,
  marginLeft: 0,
  color: theme.palette.grey[810],
}))

const ProjectAddress = () => {
  const classes = useStyles()
  const translate = useTranslate()
  const project = useFormState().values
  const isProjectShared = (project.shared_with ?? []).length > 0

  let titleIcon: ReactNode | null = null

  if (isProjectShared) {
    titleIcon = (
      <SharedEntityIcon
        owner={project.org_id}
        owner_name={project.org}
        shared_with={project.shared_with}
        margin="wide"
        version={3}
      />
    )
  }

  return (
    <HoverEditWrapper
      enableFlexGrow={false}
      modalProps={{
        headerText: translate('Address'),
        content: <AddressFormContent />,
      }}
      className={classes.address}
      titleElement={<h1>{project.address || project.org_name}</h1>}
      titleIcon={titleIcon}
    >
      <GreyText>
        {project.locality && `${project.locality}, `} {project.state} {project.zip}
      </GreyText>
    </HoverEditWrapper>
  )
}
export default ProjectAddress
