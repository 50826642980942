import { Divider, LinearProgress } from '@material-ui/core'
import { logAmplitudeEvent } from 'amplitude/amplitude'
import { StyledDialog, StyledDialogActions, StyledDialogContent, StyledDialogTitle } from 'layout/StyledDialog'
import moment from 'moment'
import { Button } from 'opensolar-ui'
import usePartiallyAcceptTransfer from 'pages/inventory/transfer/hooks/usePartiallyAcceptTransfer'
import ProjectOrderPresenter from 'pages/ordering/ProjectOrderPresenter/projectOrderPresenter'
import { OrderContext } from 'projectSections/sections/hardware'
import useGetOrderDataByTransfer from 'projectSections/sections/manage3/hardware/hooks/useGetOrderDataByTransfer'
import { useContext, useMemo, useState } from 'react'
import { refreshView, useNotify } from 'react-admin'
import { Form } from 'react-final-form'
import { useDispatch } from 'react-redux'
import InventoryTransferFormCore from '../InventoryTransferFormCore'
import type { Transfer, TransferFormIntentionType, TransferFormType } from '../type'
import AcceptHardwareItems from './AcceptHardwareItems'
import AcceptItemsButton from './AcceptItemsButton'
import OrderDistributor from './OrderDistributor'

const AcceptPurchaseOrderDialog = ({
  isOpen,
  onClose,
  transferType,
  initialValues,
  allowReview,
}: {
  isOpen: boolean
  onClose(): void
  transferType: TransferFormType
  initialValues: Transfer
  allowReview?: boolean
}) => {
  const { partiallyAcceptTransfer } = usePartiallyAcceptTransfer()
  const dispatch = useDispatch()
  const notify = useNotify()
  const { reloadOrderData } = useContext(OrderContext)
  const [intention, setIntention] = useState<TransferFormIntentionType>('accept_po')

  const acceptTransfer = async (value) => {
    const acceptedTransferData = { ...value }
    acceptedTransferData['line_items'] = Array.from(acceptedTransferData['selected_line_items'] || [])
    acceptedTransferData['selected_line_items'] = undefined
    acceptedTransferData['realistic_delivery_date'] = moment(acceptedTransferData['realistic_delivery_date']).format(
      'YYYY-MM-DD'
    )
    acceptedTransferData['line_items_new'] = null
    acceptedTransferData['line_items'] = ProjectOrderPresenter.aggregateLineItems(acceptedTransferData['line_items'])

    await partiallyAcceptTransfer({
      transferId: initialValues.id,
      data: acceptedTransferData,
    })
    dispatch(refreshView())
    onClose()
    reloadOrderData?.()
  }
  const isAutoOrder = !!initialValues?.order_id
  const { loadedOrderData, loadingOrder } = useGetOrderDataByTransfer({
    transferId: initialValues.id,
    allowReview: allowReview,
  })
  const orderData = useMemo(() => loadedOrderData, [loadedOrderData])

  if (loadingOrder) {
    return <LinearProgress variant="indeterminate" />
  }

  const splitPOIntention = () => {
    setIntention('split_po')
  }

  const confirmSplitPO = () => {
    setIntention('accept_po')
  }

  return (
    <Form
      initialValues={allowReview ? orderData : initialValues}
      onSubmit={acceptTransfer}
      render={({ handleSubmit, form }) => (
        <StyledDialog maxWidth="lg" open={isOpen} onClose={onClose}>
          <StyledDialogTitle>Receive purchase order</StyledDialogTitle>
          <Divider light />
          <StyledDialogContent style={{ padding: 20 }}>
            <OrderDistributor />
            <InventoryTransferFormCore transferType={transferType} intention={'accept_po'} />
            <AcceptHardwareItems
              selectedItemSource={'selected_line_items'}
              recordedItemSource={'line_items'}
              newItemSource={'line_items_new'}
              transferType={transferType}
              isAutoOrder={isAutoOrder}
              splitPOIntention={splitPOIntention}
              intention={intention}
            />
          </StyledDialogContent>
          <StyledDialogActions>
            <Button variant="contained" color="default" onClick={onClose}>
              Cancel
            </Button>
            {intention === 'split_po' && (
              <Button variant="contained" color="primary" onClick={() => confirmSplitPO()}>
                Confirm split
              </Button>
            )}
            {intention !== 'split_po' && (
              <AcceptItemsButton
                selectedItemSource={'selected_line_items'}
                recordedItemSource={'line_items'}
                newItemSource={'line_items_new'}
                handleClick={(event) => {
                  const promise = handleSubmit(event)
                  if (promise) promise.finally(() => notify('Items checked in', 'success'))
                  logAmplitudeEvent('generic_button_clicked', {
                    source: 'save',
                    context: 'accept_incoming_purchase_order_dialog',
                  })
                }}
              />
            )}
          </StyledDialogActions>
        </StyledDialog>
      )}
    />
  )
}

export default AcceptPurchaseOrderDialog
