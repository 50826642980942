import { makeStyles } from '@material-ui/core'
import { orgSelectors } from 'ducks/orgs'
import LoadingDots from 'layout/widgets/LoadingDots'
import { Alert } from 'opensolar-ui'
import DocumentTable from 'pages/cashFlow/configuration/documents/DocumentTable'
import { DocumentPageContext, useGetCashFlowDocuments } from 'pages/cashFlow/configuration/documents/utils'
import useInterval from 'projectSections/hooks/useInterval'
import { useTranslate } from 'ra-core'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import appStorage from 'storage/appStorage'

type PropTypes = {}

const useStyles = makeStyles(() => ({
  alert: {
    margin: '15px 15px',
  },
}))

const DocumentRequestSection: React.FC<PropTypes> = (props) => {
  const translate = useTranslate()
  const classes = useStyles()
  const org = useSelector(orgSelectors.getOrg)
  const pollingNumber = useRef(0)
  const orgHasCashFlow = useSelector(orgSelectors.getCashFlowIsActive)
  const [documents, isDocumentsLoading, refreshDocuments] = useGetCashFlowDocuments()
  const [isPolling, setIsPolling] = useState(false)

  const isProactiveDocumentRequest = useMemo(() => {
    // Assumption that document request is a proactive document request
    const isBankDocOrId =
      !!documents?.length &&
      documents.every(({ docType }) => ['bankDocument', 'companyRepresentativeId', 'owner1Id'].includes(docType))
    const hasNoUnderwriterNotes = documents.every(({ docUnderwriterNotes }) => !docUnderwriterNotes)

    const result = isBankDocOrId && hasNoUnderwriterNotes && !orgHasCashFlow

    return result
  }, [documents, orgHasCashFlow])

  useEffect(() => {
    const hasJustSubmitted = appStorage.getBool('bluesnap_onboarding_just_submitted') !== undefined
    if (hasJustSubmitted) {
      setIsPolling(true)
      appStorage.clear('bluesnap_onboarding_just_submitted')
    }
  }, [])

  const { clear: clearInterval } = useInterval((clearInterval) => {
    if (isPolling) {
      if (pollingNumber.current > 12 || !!documents?.length) {
        // stop polling after 1 minute or if there's already documents fetched
        setIsPolling(false)
        clearInterval()
      } else {
        refreshDocuments()
        pollingNumber.current += 1
      }
    }
  }, 5000)

  useEffect(() => {
    return () => {
      clearInterval()
    }
  }, [])

  const isUSProactiveDocRequest = isProactiveDocumentRequest && org?.country?.iso2 === 'US'

  if (isPolling && pollingNumber.current < 6)
    return (
      <LoadingDots
        text={translate(
          'Please wait while we check to see what documentation you will need to upload. This usually takes around 30 seconds...'
        )}
        style={{ margin: '50px 0px' }}
      />
    )

  // After 30 seconds, we'll show the text below but we'll keep on polling for 30 seconds more just in case.
  if (isPolling && pollingNumber.current >= 6)
    return (
      <LoadingDots
        text={translate('This is taking a bit more time than usual. Please check again in a while.')}
        style={{ margin: '50px 0px' }}
      />
    )

  if (isDocumentsLoading)
    return <LoadingDots text={translate('Checking for document requests...')} style={{ margin: '50px 0px' }} />

  return (
    <>
      {/* Show this text section only for US Proactive or no doc request yet*/}
      {(!documents?.length || isUSProactiveDocRequest) && (
        <p>
          {translate(
            "Selling solar and getting paid is about to be easier. Your application is being reviewed. You'll hear back in 1-2 business days. Be ready to quickly provide any additional documents we request to activate CashFlow."
          )}
        </p>
      )}
      {!!documents?.length && (
        <>
          {isUSProactiveDocRequest ? (
            <Alert severity="info" className={classes.alert}>
              {/* Optional document upload message (only for US). AU and UK proactive document uploads are required */}
              <span>
                {translate(
                  'To speed up the review process, you can proactively upload the following documents commonly requested:'
                )}
              </span>
            </Alert>
          ) : (
            <Alert severity="warning" className={classes.alert}>
              <span>
                {translate(
                  'The following documents are required to complete your application. Please upload them as soon as possible to avoid delays in your approval.'
                )}
              </span>
            </Alert>
          )}
          <DocumentPageContext.Provider value={{ refreshDocuments }}>
            <DocumentTable documents={documents} isLoading={isDocumentsLoading} />
          </DocumentPageContext.Provider>
        </>
      )}
    </>
  )
}

export default DocumentRequestSection
