import { logAmplitudeEvent } from 'amplitude/amplitude'
import { HardwareSupplierFilterKeyType } from 'pages/ordering/type'
import { LegacyDataProvider, Notify } from 'ra-core'
import appStorage from 'storage/appStorage'
import { VtacConnectCustomerResultType, VtacOrderResultType, VtacOrderType } from './type'

class VtacService {
  private restClientInstance: LegacyDataProvider
  private notify: Notify

  constructor(restClientInstance: LegacyDataProvider, notify: Notify) {
    this.restClientInstance = restClientInstance
    this.notify = notify
  }
  createCustomer = async ({
    email,
    firstname,
    lastname,
    password,
    taxvat,
    distributor,
  }: {
    email: string
    firstname: string
    lastname: string
    password: string
    taxvat: string
    distributor: HardwareSupplierFilterKeyType
  }): Promise<VtacConnectCustomerResultType> => {
    const response = await this.restClientInstance('CUSTOM_POST', 'custom', {
      url: `orgs/${appStorage.getOrgId()}/vtac_api/${distributor}/customer_create/`,
      data: { email, firstname, lastname, password, taxvat },
    }).catch(() => {
      logAmplitudeEvent('hardware_vtac_service_failed', {
        context: 'connect_user_create_customer',
      })
    })

    if (!response || !response.data) {
      return {
        status_code: 500,
        content: {
          message: 'Something went wrong with V-TAC customer creation!',
        },
      }
    }

    const { data } = response
    if (data.status_code === 500) {
      return {
        status_code: data.status_code,
        content: {
          message: 'V-TAC customer email address already exists. Login to connect your account.',
        },
      }
    } else {
      return {
        status_code: data.status_code,
        token: data,
      }
    }
  }

  getToken = async ({
    username,
    password,
    distributor,
  }: {
    username: string
    password: string
    distributor: HardwareSupplierFilterKeyType
  }): Promise<VtacConnectCustomerResultType> => {
    const response = await this.restClientInstance('CUSTOM_POST', 'custom', {
      url: `orgs/${appStorage.getOrgId()}/vtac_api/${distributor}/token/`,
      data: { username, password },
    }).catch(() => {
      logAmplitudeEvent('hardware_vtac_service_failed', {
        context: 'connect_user_create_customer',
      })
    })

    if (!response || !response.data) {
      return {
        status_code: 500,
        content: {
          message: 'Something went wrong with V-TAC customer connection!',
        },
      }
    }

    const { data } = response
    if (data.status_code === 500 || data.status_code === 401) {
      return {
        status_code: data.status_code,
        content: data.content,
      }
    } else {
      return {
        status_code: data.status_code,
        token: data,
      }
    }
  }

  syncPrices = async ({ distributor }: { distributor: HardwareSupplierFilterKeyType }): Promise<void> => {
    try {
      const response = await this.restClientInstance('CUSTOM_GET', 'custom', {
        url: `orgs/${appStorage.getOrgId()}/${distributor}/sync_prices`,
      })
    } catch (error) {
      logAmplitudeEvent('hardware_vtac_service_failed', {
        context: 'sync_pricing',
      })
      this.notify('Could not sync prices')
      console.warn('sync prices error, ', error)
    }
  }

  placeOrder = async (
    order: VtacOrderType,
    distributor: HardwareSupplierFilterKeyType
  ): Promise<VtacOrderResultType> => {
    try {
      const response = await this.restClientInstance('CUSTOM_POST', 'custom', {
        url: `orgs/${appStorage.getOrgId()}/vtac_api/${distributor}/place_order/`,
        data: order,
      })

      if (!response?.data) {
        logAmplitudeEvent('hardware_vtac_service_failed', {
          context: 'place_order',
        })
        throw new Error('Failed to place the order')
      }

      return response.data
    } catch (error: any) {
      logAmplitudeEvent('hardware_vtac_service_failed', {
        context: 'place_order',
      })
      throw new Error(error.message || 'Failed to place the order.')
    }
  }

  getDealers = async (distributor: HardwareSupplierFilterKeyType) => {
    const response = await this.restClientInstance('CUSTOM_GET', 'custom', {
      url: `orgs/${appStorage.getOrgId()}/vtac_api/${distributor}/dealers/`,
    }).catch(() => {
      logAmplitudeEvent('hardware_vtac_service_failed', {
        context: 'get_dealers',
      })
    })

    return response?.data
  }
}

export default VtacService
