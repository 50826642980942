import { useLocale } from 'ra-core'
import { useCallback } from 'react'

export const useTranslatableText = (opts?: { locale?: string }) => {
  const defaultLocale = useLocale()
  const actualLocale = opts?.locale || defaultLocale || 'en'

  return useCallback(
    (data: string | Record<string, string>, subs?: Record<string, string | number>) => {
      if (!data) return ''
      if (typeof data === 'string') return data
      let ret = data[actualLocale] || data['en'] || ''
      if (subs) {
        Object.keys(subs).forEach((key) => {
          ret = ret.replace('${' + key + '}', subs[key].toString())
        })
        return ret
      } else {
        return ret
      }
    },
    [actualLocale]
  )
}
