import lodash from 'lodash'
import { FilterCondition, FilterField, Op } from '../types'

export const checkOp = (scope: any, prop: string, op: Op, to: any): boolean => {
  const value = lodash.get(scope, prop)

  switch (op) {
    case 'neq':
      return !lodash.isEqual(value, to)
    case 'gt':
      return value > to
    case 'lt':
      return value < to
    case 'gte':
      return value >= to
    case 'lte':
      return value <= to
    case 'in':
      return checkIn(to, value)
    case 'nin':
      return !checkIn(to, value)
    case 'ends-with':
      return (value + '').endsWith(to)
    case 'starts-with':
      return (value + '').startsWith(to)
    case 'eq':
    default:
      return lodash.isEqual(value, to)
  }
}

const checkIn = (collection: any, value: any) => {
  return Array.isArray(collection)
    ? collection.find((v) => lodash.isEqual(value, v))
    : collection.includes?.(value) || false
}

export const opToSymbol = (op: Op): string => {
  switch (op) {
    case 'neq':
      return '!='
    case 'gt':
      return '>'
    case 'lt':
      return '<'
    case 'gte':
      return '>='
    case 'lte':
      return '<='
    case 'in':
      return 'in list'
    case 'nin':
      return 'not in list'
    case 'ends-with':
      return 'ends with'
    case 'starts-with':
      return 'starts with'
    case 'eq':
    default:
      return '=='
  }
}

export const createDefaultCondition = (field: FilterField, op: Op): FilterCondition => {
  return { path: field.path, op, value: '' }
}
