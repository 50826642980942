import { JsonSchema, UISchemaElement } from '@jsonforms/core'
import { useDataProvider } from 'ra-core'
import { useEffect, useState } from 'react'

// store statically to avoid multiple requests
let staticStructTypes: undefined | StructType[] = undefined

export const useStructTypes = () => {
  const dataProvider = useDataProvider()
  const [structTypes, setStructTypes] = useState<undefined | StructType[]>(undefined)

  useEffect(() => {
    if (staticStructTypes) {
      setStructTypes(staticStructTypes)
    }
    // @ts-ignore
    dataProvider.getList('struct-types', { filter: { include: 'schema,uischema' } }).then((response) => {
      staticStructTypes = response.data as StructType[]
      setStructTypes(staticStructTypes)
    })
  }, [])

  return structTypes
}
export type StructType = {
  id: string
  title: string
  schema?: JsonSchema
  uischema?: UISchemaElement
  versioning?: object
}
