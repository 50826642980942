import { orgSelectors } from 'ducks/orgs'
import { Alert, styled } from 'opensolar-ui'
import { CashFlowConfiguration } from 'pages/cashFlow/types'
import React, { useEffect, useMemo, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useSelector } from 'react-redux'
import BannerButton from './BannerButton'
import { getEarliestConfigCreatedDate, useGetConvertToCashFlowSetting } from './utils'

const MainWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})

const TextBlock = styled('div')({
  flex: 10,
})

const ButtonBlock = styled('div')({
  flex: 2,
  display: 'flex',
  justifyContent: 'flex-end',
})

type PropTypes = {
  configs: CashFlowConfiguration[]
}

const ConvertExistingProjectsBanner: React.FC<PropTypes> = (props) => {
  const [startDate, setStartDate] = useState<string | null>(null)

  const orgId = useSelector(orgSelectors.getOrg)?.id

  useEffect(() => {
    let unformattedStateDate = getEarliestConfigCreatedDate(props.configs)
    if (unformattedStateDate) {
      let date = new Date(unformattedStateDate)
      let formattedDate = date.toLocaleDateString(window.locale, {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      })
      setStartDate(formattedDate)
    } else {
      setStartDate(unformattedStateDate)
    }
  }, [props.configs])

  const { settings, isFetching } = useGetConvertToCashFlowSetting(orgId)

  const message = useMemo(() => {
    if (!settings?.enabled) {
      return `Projects created before ${startDate} will not automatically have CashFlow functionality. Would you like to convert all existing projects to CashFlow so you can easily send invoices?`
    } else {
      return 'All existing projects will be converted to CashFlow. You can now easily send invoices from existing projects.'
    }
  }, [startDate, settings?.enabled])

  if (isFetching) return <Skeleton height="45px" width="100%" />
  return (
    <Alert severity={settings?.enabled ? 'info' : 'warning'}>
      <MainWrapper>
        <TextBlock>{message}</TextBlock>
        <ButtonBlock>
          <BannerButton configs={props.configs} existingSettings={settings} />
        </ButtonBlock>
      </MainWrapper>
    </Alert>
  )
}
export default ConvertExistingProjectsBanner
