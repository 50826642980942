// @ts-nocheck
import { getIsPhoenixV2 } from '../util';
// This method copied directly from original customerActionsData method
// Copy the latest customerActionsData and remove the original customerActionsData before merging
var customerActionsData = function (project_id, system_uuid, payment_option_id, available_customer_actions, myeStyles, acceptButtonStyle, acceptButtonStyleDisabled, paymentOptionData, pricing, quotationConfiguration, systemSize, contacts, showDialog, setWaitingForAction, isPro, translate, additional_costs, selectedPaymentOptionName, docusignKBAFailures, docusignContractEnabled, contractTemplate, hasAvailableDocusignAction, selectedProject, selectedPaymentOptionId, attachedPhoenixPaymentOption) {
    /*
      We inject id into each actionData but this is just a temporary id which can be used to identify a specific action
      instead of needing to use its index in the list which may not be reliable (e.g. if we filter the actions)
      This was added to allow Bespoke proposals to identify and trigger a particular action.
      */
    var _a, _b, _c;
    var actionsData = [];
    // If two options are the same but differ by the payment_method (e.g. credit_card_stripe and offline)
    // Only show first button for a particular payment option, others can be accessed inside the payment form
    // Refactor this so its easy to compose actions based on payment type
    // Elements include:
    //    Action buttons (Enabled=Green, Disabled=Grey, can have a status message underneath)
    //    Action links
    //    Custom element (e.g. "OR" divider, etc)
    //    System/Payment summary description
    var actionsForSystemAndPaymentOption = [];
    var hasAvailablePhoenixApplication = false;
    available_customer_actions
        .filter(function (aca) { return aca.system_uuid === system_uuid; })
        .forEach(function (available_customer_action) {
        available_customer_action.actions_available
            .filter(function (aca) { return aca.system_uuid === system_uuid; })
            .filter(function (actionData) {
            return actionData.status_code !== 'hidden' &&
                (actionData.payment_option_id === null ||
                    actionData.payment_option_id === payment_option_id ||
                    actionData.payment_method === 'phoenix_application');
        })
            .forEach(function (actionData, i) {
            if (actionData.payment_method === 'phoenix_application')
                hasAvailablePhoenixApplication = true;
            actionsForSystemAndPaymentOption.push(actionData);
        });
    });
    // only used for finance integrations on the new structure
    var genericIntegratedLoanOptions = actionsForSystemAndPaymentOption.filter(function (a) {
        if (!a.is_pro_facing && isPro)
            return false;
        else if (!a.is_customer_facing && !isPro)
            return false;
        return a.finance_integration && !a.document_type && !a.stipulation_name;
    });
    if (hasAvailablePhoenixApplication && getIsPhoenixV2(attachedPhoenixPaymentOption === null || attachedPhoenixPaymentOption === void 0 ? void 0 : attachedPhoenixPaymentOption.integration_external_reference)) {
        hasAvailablePhoenixApplication = false;
    }
    // don't use docusign actions if they just signed, and don't use cashflow actions since those are always separate
    var firstAcceptProposalAction = actionsForSystemAndPaymentOption.filter(function (a) {
        return a !== 'hidden' &&
            (a.payment_method === 'offline' ||
                a.payment_method === 'none' ||
                a.payment_method === 'credit_card_stripe' ||
                (a.payment_method === 'docusign' &&
                    (actionsForSystemAndPaymentOption.length === 1 ||
                        !window.location.href.includes('event=signing_complete') ||
                        !window.location.href.includes('all_signed=true'))));
    })[0];
    var unpaidCashFlowDepositAction = (_a = actionsForSystemAndPaymentOption === null || actionsForSystemAndPaymentOption === void 0 ? void 0 : actionsForSystemAndPaymentOption.filter(function (a) { return a.payment_method === 'cashflow' && a.status_code === 'available'; })) === null || _a === void 0 ? void 0 : _a[0];
    var elements = [];
    if (paymentOptionData.payment_type === 'cash') {
        // Use the first proposal acceptance action for this system and payment option
        elements = [{ type: 'AcceptProposal', actionData: firstAcceptProposalAction }];
    }
    else {
        // make a map of stips where the key is review status and the value is an array of all stips with that status
        var stipulationMap_1 = {};
        var stipulationActions = actionsForSystemAndPaymentOption.filter(function (action) {
            return action === null || action === void 0 ? void 0 : action.stipulation_review_status;
        });
        if (stipulationActions && stipulationActions.length > 0) {
            stipulationActions.forEach(function (action) {
                if (!stipulationMap_1[action.stipulation_review_status])
                    stipulationMap_1[action.stipulation_review_status] = [action];
                else
                    stipulationMap_1[action.stipulation_review_status].push(action);
            });
        }
        // Detect the type of loan
        // only used for finance integrations that are still on the original structure
        var actionIntegratedLoanOptions = actionsForSystemAndPaymentOption.filter(function (a) {
            return (a.finance_integration ||
                [
                    'sunlight_loan_application',
                    'sunlight_loan_prequal',
                    'loanpal_application',
                    'mosaic_application',
                    'sungage_application',
                    'brighte_application',
                    'dividend_application',
                    'phoenix_application',
                ].indexOf(a.payment_method) !== -1) &&
                !a.document_type &&
                !a.stipulation_name;
        });
        var loanAgreementActionsByPartner_1 = {};
        actionsForSystemAndPaymentOption.forEach(function (action) {
            if (action.document_type === 'mosaic_loan_agreement') {
                if (!loanAgreementActionsByPartner_1['mosaic'])
                    loanAgreementActionsByPartner_1['mosaic'] = action;
            }
            else if (action.document_type === 'loanpal_loan_agreement') {
                if (!loanAgreementActionsByPartner_1['loanpal'])
                    loanAgreementActionsByPartner_1['loanpal'] = action;
            }
            else if (action.document_type === 'dividend_loan_agreement') {
                if (!loanAgreementActionsByPartner_1['dividend'])
                    loanAgreementActionsByPartner_1['dividend'] = action;
            }
        });
        // Special case: If sunlight_loan_application is available then ensure we use it to prevent accidentally choosing
        // sunlight_loan_prequal which does not show the full loan application option
        // Otherwise, just choose the first available loan application option.
        var actionIntegratedLoan = actionIntegratedLoanOptions.indexOf('sunlight_loan_application') !== -1
            ? 'sunlight_loan_application'
            : actionIntegratedLoanOptions[0];
        var loanType = actionIntegratedLoan ? actionIntegratedLoan === null || actionIntegratedLoan === void 0 ? void 0 : actionIntegratedLoan.payment_method : 'standard';
        // a hack to say even though the application is done we still have stips to deal with so keep this in integrated finance action mode
        if (loanType === 'standard' &&
            paymentOptionData.integration === 'loanpal' &&
            Object.keys(stipulationMap_1).length > 0) {
            loanType = 'loanpal_application';
        }
        var actionPrequal = actionsForSystemAndPaymentOption.filter(function (a) { return a.payment_method === 'sunlight_loan_prequal'; })[0];
        elements = {
            sunlight_loan_application: [
                { type: 'LoanApplication', actionData: actionIntegratedLoan, loanType: loanType },
                // Toggle type for Divider based on other actions
                {
                    type: 'Divider',
                    hide: !firstAcceptProposalAction,
                    showOr: actionIntegratedLoan &&
                        (actionIntegratedLoan === null || actionIntegratedLoan === void 0 ? void 0 : actionIntegratedLoan.status_code) === 'available' &&
                        firstAcceptProposalAction &&
                        firstAcceptProposalAction.status_code === 'available',
                    loanType: loanType,
                },
                {
                    type: 'AcceptProposal',
                    actionData: firstAcceptProposalAction,
                    variation: actionIntegratedLoan && ['complete', 'disabled'].includes(actionIntegratedLoan === null || actionIntegratedLoan === void 0 ? void 0 : actionIntegratedLoan.status_code)
                        ? 'button'
                        : 'link',
                    loanType: loanType,
                },
                { type: 'Prequal', actionData: actionPrequal, isPrimaryAction: false, loanType: loanType },
            ],
            sunlight_loan_prequal: [
                { type: 'Prequal', actionData: actionPrequal, isPrimaryAction: true, loanType: loanType },
                // Toggle type for Divider based on other actions
                {
                    type: 'Divider',
                    hide: !firstAcceptProposalAction,
                    showOr: firstAcceptProposalAction && firstAcceptProposalAction.status_code === 'available',
                    loanType: loanType,
                },
                {
                    type: 'AcceptProposal',
                    actionData: firstAcceptProposalAction,
                    variation: actionIntegratedLoan && (actionIntegratedLoan === null || actionIntegratedLoan === void 0 ? void 0 : actionIntegratedLoan.status_code) === 'complete' ? 'button' : 'link',
                    loanType: loanType,
                },
            ],
            phoenix_application: [
                { type: 'LoanApplication', actionData: actionIntegratedLoan, loanType: loanType },
                {
                    type: 'Divider',
                    showOr: actionIntegratedLoan &&
                        (actionIntegratedLoan === null || actionIntegratedLoan === void 0 ? void 0 : actionIntegratedLoan.status_code) === 'available' &&
                        firstAcceptProposalAction &&
                        firstAcceptProposalAction.status_code === 'available',
                    loanType: loanType,
                },
                {
                    type: 'AcceptProposal',
                    actionData: firstAcceptProposalAction,
                    variation: (actionIntegratedLoan === null || actionIntegratedLoan === void 0 ? void 0 : actionIntegratedLoan.status_code) === 'complete' || isPro ? 'button' : 'link',
                    loanType: loanType,
                },
            ],
            loanpal_application: [
                { type: 'LoanApplication', actionData: actionIntegratedLoan, loanType: loanType },
                { type: 'FinanceStipulations', actionData: stipulationMap_1, variation: 'button', loanType: loanType },
                { type: 'LoanAgreement', actionData: loanAgreementActionsByPartner_1['loanpal'], loanType: loanType },
                {
                    type: 'Divider',
                    hide: !firstAcceptProposalAction,
                    showOr: actionIntegratedLoan &&
                        (actionIntegratedLoan === null || actionIntegratedLoan === void 0 ? void 0 : actionIntegratedLoan.status_code) === 'available' &&
                        !(actionIntegratedLoan === null || actionIntegratedLoan === void 0 ? void 0 : actionIntegratedLoan.stipulation_review_status) &&
                        firstAcceptProposalAction &&
                        firstAcceptProposalAction.status_code === 'available',
                    loanType: loanType,
                },
                {
                    type: 'AcceptProposal',
                    actionData: firstAcceptProposalAction,
                    variation: (actionIntegratedLoan === null || actionIntegratedLoan === void 0 ? void 0 : actionIntegratedLoan.status_code) === 'available' ||
                        ((_b = stipulationMap_1['created']) === null || _b === void 0 ? void 0 : _b.length) > 0 ||
                        (loanAgreementActionsByPartner_1['loanpal'] &&
                            loanAgreementActionsByPartner_1['loanpal'].status_code !== 'complete')
                        ? 'link'
                        : 'button',
                    loanType: loanType,
                },
            ],
            mosaic_application: [
                { type: 'LoanApplication', actionData: actionIntegratedLoan, loanType: loanType },
                {
                    type: 'Divider',
                    hide: !stipulationMap_1,
                    showOr: false,
                    loanType: loanType,
                },
                { type: 'FinanceStipulations', actionData: stipulationMap_1, variation: 'button', loanType: loanType },
                {
                    type: 'Divider',
                    hide: !stipulationMap_1,
                    showOr: false,
                    loanType: loanType,
                },
                { type: 'LoanAgreement', actionData: loanAgreementActionsByPartner_1['mosaic'], loanType: loanType },
                {
                    type: 'Divider',
                    hide: (!loanAgreementActionsByPartner_1['mosaic'] && (actionIntegratedLoan === null || actionIntegratedLoan === void 0 ? void 0 : actionIntegratedLoan.status_code) === 'complete') ||
                        !firstAcceptProposalAction,
                    showOr: actionIntegratedLoan &&
                        (((_c = loanAgreementActionsByPartner_1['mosaic']) === null || _c === void 0 ? void 0 : _c.status_code) === 'available' ||
                            (actionIntegratedLoan === null || actionIntegratedLoan === void 0 ? void 0 : actionIntegratedLoan.status_code) === 'available') &&
                        firstAcceptProposalAction &&
                        firstAcceptProposalAction.status_code === 'available',
                    loanType: loanType,
                },
                {
                    type: 'AcceptProposal',
                    actionData: firstAcceptProposalAction,
                    variation: (actionIntegratedLoan === null || actionIntegratedLoan === void 0 ? void 0 : actionIntegratedLoan.status_code) === 'complete' &&
                        (!loanAgreementActionsByPartner_1['mosaic'] ||
                            loanAgreementActionsByPartner_1['mosaic'].status_code === 'complete')
                        ? 'button'
                        : 'link',
                    loanType: loanType,
                },
            ],
            sungage_application: [
                { type: 'LoanApplication', actionData: actionIntegratedLoan, loanType: loanType },
                {
                    type: 'Divider',
                    hide: (actionIntegratedLoan === null || actionIntegratedLoan === void 0 ? void 0 : actionIntegratedLoan.status_code) === 'complete' || !firstAcceptProposalAction,
                    showOr: actionIntegratedLoan &&
                        (actionIntegratedLoan === null || actionIntegratedLoan === void 0 ? void 0 : actionIntegratedLoan.status_code) === 'available' &&
                        firstAcceptProposalAction &&
                        firstAcceptProposalAction.status_code === 'available',
                    loanType: loanType,
                },
                {
                    type: 'AcceptProposal',
                    actionData: firstAcceptProposalAction,
                    variation: (actionIntegratedLoan === null || actionIntegratedLoan === void 0 ? void 0 : actionIntegratedLoan.status_code) === 'complete' ? 'button' : 'link',
                    loanType: loanType,
                },
            ],
            dividend_application: [
                { type: 'LoanApplication', actionData: actionIntegratedLoan, loanType: loanType },
                { type: 'LoanAgreement', actionData: loanAgreementActionsByPartner_1['dividend'], loanType: loanType },
                {
                    type: 'Divider',
                    hide: (actionIntegratedLoan === null || actionIntegratedLoan === void 0 ? void 0 : actionIntegratedLoan.status_code) === 'complete' || !firstAcceptProposalAction,
                    showOr: actionIntegratedLoan &&
                        (actionIntegratedLoan === null || actionIntegratedLoan === void 0 ? void 0 : actionIntegratedLoan.status_code) === 'available' &&
                        firstAcceptProposalAction &&
                        firstAcceptProposalAction.status_code === 'available',
                    loanType: loanType,
                },
                {
                    type: 'AcceptProposal',
                    actionData: firstAcceptProposalAction,
                    variation: (actionIntegratedLoan === null || actionIntegratedLoan === void 0 ? void 0 : actionIntegratedLoan.status_code) === 'complete' ? 'button' : 'link',
                    loanType: loanType,
                },
            ],
            brighte_application: [
                { type: 'LoanApplication', actionData: actionIntegratedLoan, loanType: loanType },
                {
                    type: 'Divider',
                    hide: (actionIntegratedLoan === null || actionIntegratedLoan === void 0 ? void 0 : actionIntegratedLoan.status_code) === 'complete' || !firstAcceptProposalAction,
                    showOr: actionIntegratedLoan &&
                        (actionIntegratedLoan === null || actionIntegratedLoan === void 0 ? void 0 : actionIntegratedLoan.status_code) === 'available' &&
                        firstAcceptProposalAction &&
                        firstAcceptProposalAction.status_code === 'available',
                    loanType: loanType,
                },
                {
                    type: 'AcceptProposal',
                    actionData: firstAcceptProposalAction,
                    variation: (actionIntegratedLoan === null || actionIntegratedLoan === void 0 ? void 0 : actionIntegratedLoan.status_code) === 'complete' ? 'button' : 'link',
                    loanType: loanType,
                },
            ],
            standard: [{ type: 'AcceptProposal', actionData: firstAcceptProposalAction, variation: 'button', loanType: loanType }],
        }[loanType];
    }
    if (hasAvailablePhoenixApplication) {
        elements.push({ type: 'PhoenixApplication', actionData: firstAcceptProposalAction });
    }
    // Iterate over initial elements to make some adjustments to elements and build the final actionData list
    elements === null || elements === void 0 ? void 0 : elements.forEach(function (element, index) {
        if (!element.actionData && element.type !== 'Divider') {
            console.warn('Missing element.actionData from customer action', element);
            // skip to next, not possible to render an action without actionData
            return;
        }
        //hack to deal with the fact that the user will be returned from Docusign after signing before our db reflects the fact that they have signed
        if (element.actionData &&
            element.actionData.status_code === 'available' &&
            window.location.href.includes('event=signing_complete') &&
            window.location.href.includes('all_signed=true')) {
            if (element.actionData.payment_method === 'offline') {
                // if they just signed a DS contract and all they have left is an offline payment don't show that offline payment CTA
                return;
            }
            if (element.actionData.payment_method === 'docusign') {
                // Beware this modifies actionData, it would be nicer to handle above
                element.actionData.status_code = 'complete';
            }
            else if (element.type === 'AcceptProposal') {
                // Beware this modifies actionData, it would be nicer to handle above
                element.actionData.collect_signature = false;
                if (element.actionData.payment_method === 'none') {
                    // Beware this modifies actionData, it would be nicer to handle above
                    element.actionData.status_code = 'complete';
                }
            }
        }
        // @TODO: Consolidate actionData.eror and actionData.messages (errors and warnings) into a single place
        if (element.actionData && element.actionData.error && element.actionData.error.length > 0) {
            actionsData.push({
                type: 'ActionError',
                error: element.actionData.error,
                id: actionsData.length + 1,
            });
        }
        // Only show actionData validation errors/warnings for Pros. Don't show to a customer.
        // If the action will fail for the customer, let them attempt it first.
        if (isPro && element.actionData && element.actionData.messages && element.actionData.messages.length) {
            actionsData.push({
                type: 'ActionMessages',
                messages: element.actionData.messages,
                id: actionsData.length + 1,
            });
        }
        // Refactor to make this standard for all actions
        if (element.type === 'AcceptProposal') {
            element.actionData.transactionRequestData = Object.assign({}, element.actionData, {
                paymentOptionData: paymentOptionData,
                pricing: pricing,
                quotationConfiguration: quotationConfiguration,
                systemSize: systemSize,
                additional_costs: additional_costs,
            });
        }
        var elementTypesToAddAction = [
            'LoanApplication',
            'AcceptProposal',
            'Prequal',
            'FinanceStipulations',
            'LoanAgreement',
            'PhoenixApplication',
        ];
        if (elementTypesToAddAction.includes(element.type) || (element.type === 'Divider' && !element.hide)) {
            element.id = actionsData.length + 1;
            actionsData.push(element);
        }
    });
    genericIntegratedLoanOptions === null || genericIntegratedLoanOptions === void 0 ? void 0 : genericIntegratedLoanOptions.forEach(function (loanOption) {
        // keeping this simple for now since we're only supporting one finance CTA and accept proposal
        var prioritizeApplication = (loanOption === null || loanOption === void 0 ? void 0 : loanOption.priority) === 0 || (loanOption === null || loanOption === void 0 ? void 0 : loanOption.priority) === 1;
        var justFinishedDocusign = window.location.href.includes('event=signing_complete') && window.location.href.includes('all_signed=true');
        if (firstAcceptProposalAction && justFinishedDocusign)
            firstAcceptProposalAction.status_code = 'complete';
        var tempActions = [];
        var actionType = 'LoanApplication';
        if (['ALERT_WARNING', 'ALERT_INFO', 'ALERT_ERROR'].includes(loanOption.cta_type)) {
            actionType = 'AlertCTA';
        }
        tempActions.push({ type: actionType, actionData: loanOption, loanType: loanOption.payment_method });
        if (firstAcceptProposalAction) {
            if (actionType !== 'AlertCTA') {
                tempActions.push({
                    type: 'Divider',
                    hide: (loanOption === null || loanOption === void 0 ? void 0 : loanOption.status_code) === 'complete',
                    showOr: loanOption &&
                        (loanOption === null || loanOption === void 0 ? void 0 : loanOption.status_code) === 'available' &&
                        firstAcceptProposalAction &&
                        firstAcceptProposalAction.status_code === 'available',
                    loanType: loanOption.payment_method,
                });
            }
            tempActions.push({
                type: 'AcceptProposal',
                actionData: firstAcceptProposalAction,
                variation: (loanOption === null || loanOption === void 0 ? void 0 : loanOption.status_code) === 'complete' || !prioritizeApplication ? 'button' : 'link',
                loanType: loanOption.payment_method,
            });
        }
        if (!prioritizeApplication && firstAcceptProposalAction)
            tempActions.reverse();
        actionsData.push.apply(actionsData, tempActions);
    });
    // if after giong through the above scenarios we don't have any actions in actionsData but we do
    // have a value in firstAcceptProposalAction, then just add firstAcceptProposalAction directly
    if (!(actionsData === null || actionsData === void 0 ? void 0 : actionsData.length) && firstAcceptProposalAction) {
        actionsData.push({
            type: 'AcceptProposal',
            actionData: firstAcceptProposalAction,
            variation: 'button',
        });
    }
    if (unpaidCashFlowDepositAction) {
        var variation = (firstAcceptProposalAction === null || firstAcceptProposalAction === void 0 ? void 0 : firstAcceptProposalAction.status_code) === 'available' ? 'link' : 'button';
        if (variation === 'button') {
            actionsData.unshift({
                type: 'CashFlowDeposit',
                actionData: unpaidCashFlowDepositAction,
                variation: variation,
            });
        }
        else {
            actionsData.push({
                type: 'CashFlowDeposit',
                actionData: unpaidCashFlowDepositAction,
                variation: variation,
            });
        }
    }
    return actionsData;
};
/*
  This method consolidate actionData.eror and actionData.messages into a new element type and inject to the result of customerActionsData
  This aim to handle the logic outside of type switch within customerActionButtons function

  Refactor and move logic to populateCheckoutElementsData once fully transition to opensolar-checkout.
*/
var injectMessageElement = function (fn) {
    return function () {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var elements = fn.apply(void 0, args);
        var finalElements = [];
        elements.forEach(function (element) {
            var _a, _b;
            var error = (_a = element.actionData) === null || _a === void 0 ? void 0 : _a.error;
            var alertMessages = (_b = element.actionData) === null || _b === void 0 ? void 0 : _b.messages;
            // The following logic replicating the existing logic in customerActionButtons to avoid any unexpected behavior
            // Please further consolidate the "message" and "alert" for the BE response and UI
            if (error) {
                finalElements.push({ type: 'Message', actionData: { message: error } });
            }
            if (alertMessages) {
                finalElements.push({ type: 'Alert', actionData: { messages: alertMessages } });
            }
            finalElements.push(element);
        });
        return finalElements;
    };
};
export var populateCheckoutElementsData = injectMessageElement(customerActionsData);
