import { useState } from 'react'
import ApplicationIframeDialog from './ApplicationIframeDialog'

export type GenericFinanceIframeDialogConfig = {
  iframeURL?: string
  iframeContents?: string
  selectedPaymentOptionName: string
}

const useGenericFinanceIframeDialog = () => {
  const [state, seState] = useState<
    { open: false; config: undefined } | { open: true; config: GenericFinanceIframeDialogConfig }
  >({
    open: false,
    config: undefined,
  })

  const openDialog = (config: GenericFinanceIframeDialogConfig) => {
    seState({
      open: true,
      config,
    })
  }

  const closeDialog = () => {
    if (state.open === false) {
      return
    }
    seState({
      open: false,
      config: undefined,
    })
  }

  const dialogNode = state.open ? (
    <ApplicationIframeDialog
      url={state.config.iframeURL}
      contents={state.config.iframeContents}
      isOpen={true}
      onClose={closeDialog}
      paymentOptionTitle={state.config.selectedPaymentOptionName}
    />
  ) : null
  return { isOpen: state.open, dialogNode, openDialog, closeDialog, type: 'GenericFinanceIframeDialog' as const }
}

export default useGenericFinanceIframeDialog
