import { Link, makeStyles } from '@material-ui/core'
import { ArrowUpRightSquareOutlineIcon, BaseTheme, PaperClipIcon, styled } from 'opensolar-ui'
import { ComplianceFormGroupType } from 'projectSections/sections/info/permitsAndServices/types'
import { useTranslate } from 'ra-core'
import { Theme } from 'types/themes'
import { Column, RowCentered } from '../styles'

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    width: '100%',
    marginBottom: 20,
  },
  marginLeft: {
    marginLeft: 8,
  },
  docWrapper: {
    margin: '10px 0',
    marginRight: 50,

    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
  itemWrapper: {
    flexWrap: 'wrap',
    maxHeight: 150,
    [theme.breakpoints.down('sm')]: {
      maxHeight: 'unset',
    },
  },
}))

const DarkGreyText = styled('p', { name: 'DarkGreyText' })(({ theme }) => ({
  color: theme.palette.grey[810],
}))

const StyledLink = styled(Link, { name: 'StyledLink' })({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
})

interface PropTypes {
  item: ComplianceFormGroupType
  filterByGeo: (items) => any[]
  countFiles: (file_tag) => number
}
const FormDisplay: React.FC<PropTypes> = ({ item, filterByGeo, countFiles }) => {
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <div className={classes.wrapper}>
      <DarkGreyText>{translate(item.heading)}</DarkGreyText>
      <Column className={classes.itemWrapper}>
        {filterByGeo(item.items).map((doc) => (
          <div className={classes.docWrapper}>
            {doc.url && (
              <StyledLink href={doc.url} target="_blank" rel="noopener noreferrer">
                {doc.icon === 'launch' && <ArrowUpRightSquareOutlineIcon color={BaseTheme.palette.grey[700]} />}
                <span className={classes.marginLeft}>{translate(doc.title)}</span>
              </StyledLink>
            )}
            {!doc.url && doc.file_tag && (
              <RowCentered>
                {doc.icon === 'file' && <PaperClipIcon color={BaseTheme.palette.grey[700]} />}
                <span className={classes.marginLeft}>{`${translate(doc.title)} (${countFiles(doc.file_tag)})`}</span>
              </RowCentered>
            )}
            {!doc.url && !doc.file_tag && <span>{translate(doc.title)}</span>}
          </div>
        ))}
      </Column>
    </div>
  )
}
export default FormDisplay
