import { styled } from 'opensolar-ui'

export const StyledSectionWrapper = styled('div', { name: 'StyledSectionWrapper' })<{
  last: boolean
}>(({ last, theme }) => ({
  backgroundColor: 'white',
  padding: 20,
  ...(last
    ? { borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }
    : { borderRadius: 0, borderBottom: theme.mixins.borderStandard.border }),
}))
