import { orgSelectors } from 'ducks/orgs'
import { User, WebStorageStateStore } from 'oidc-client-ts'
import { HardwareSupplierEnum } from 'pages/ordering/type'
import React, { memo, useEffect, useState } from 'react'
import { AuthProvider } from 'react-oidc-context'
import { useSelector } from 'react-redux'
import { useFeatureFlag } from 'util/split'

const cityPlumbingConfig = window.getCityPlumbingConfig?.()

const onSigninCallback = (_user: User | void): void => {
  // This clears the state URL params. We will continue with the actual redirect after this is called.
  window.history.replaceState({}, document.title, window.location.pathname)

  if (_user && _user?.refresh_token) {
    window.cityPlumbingTokens = { token: _user.access_token, refreshToken: _user.refresh_token }
  }
}

const oidcConfig = cityPlumbingConfig
  ? {
      authority: `${cityPlumbingConfig.url}/realms/${cityPlumbingConfig.realm}`,
      client_id: cityPlumbingConfig.clientId,
      realm: cityPlumbingConfig.realm,
      redirect_uri: window.location.origin, // TODO better value? Or rely on our own redirects?
      scope: 'offline_access',
      loadUserInfo: false,
      userStore: new WebStorageStateStore({
        store: localStorage,
      }),
      automaticSilentRenew: true,
    }
  : undefined

export const OidcContext = React.createContext({ isWrapped: false })

/**
 * This is quite City Plumbing specific. We could genericise if we add another OAuth integration.
 */
const OidcWrapper = ({ children }) => {
  const enableHardwareOrdering = useFeatureFlag('hardware_ordering', 'on') || false
  const enabledDistributors = useSelector(orgSelectors.getEnabledHardwareSuppliers)
  const [wrapWithOidc, setWrapWithOidc] = useState(false)

  // This handles both login and org switch.
  useEffect(() => {
    const wrap = enableHardwareOrdering && enabledDistributors.includes(HardwareSupplierEnum.CityPlumbing)
    setWrapWithOidc(wrap)
  }, [enableHardwareOrdering, enabledDistributors])

  return (
    <OidcContext.Provider value={{ isWrapped: wrapWithOidc }}>
      {wrapWithOidc && oidcConfig ? (
        <AuthProvider {...oidcConfig} onSigninCallback={onSigninCallback}>
          {children}
        </AuthProvider>
      ) : (
        children
      )}
    </OidcContext.Provider>
  )
}

export default memo(OidcWrapper)
