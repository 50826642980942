import { orgSelectors } from 'ducks/orgs'
import { styled } from 'opensolar-ui'
import { useGetCashFlowConfigurations } from 'pages/cashFlow/configuration/utils'
import { useGetIsCashFlowVisible } from 'pages/cashFlow/utils'
import { useTranslate } from 'ra-core'
import { BooleanInput, SelectInput } from 'ra-ui-materialui'
import React, { useEffect, useState } from 'react'
import { useFormState } from 'react-final-form'
import { useSelector } from 'react-redux'

const PaymentsConfigurationDiv = styled('div')({
  inputContainer: {
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
  },
})

type PropTypes = {}
type ChoiceType = { id: string | null; name: string }

const PaymentsConfigurationSelection: React.FC<PropTypes> = (props) => {
  const [configChoices, setConfigChoices] = useState<ChoiceType[]>([])

  const orgId = useSelector(orgSelectors.getOrg)?.id
  const configs = useGetCashFlowConfigurations(orgId, 1, 100)
  const isCashFlowVisible = useGetIsCashFlowVisible()
  const cashFlowActiveOnOrg = useSelector(orgSelectors.getCashFlowIsActive)
  const translate = useTranslate()

  const formState = useFormState()
  const isCash = formState.values['payment_type'] === 'cash'
  const resource = 'payment_options'

  useEffect(() => {
    if (configs) {
      let asChoices: ChoiceType[] = configs
        ?.filter((config) => !config.is_archived)
        ?.map((config) => {
          return { id: config.url, name: config.title }
        })
      asChoices.push({ id: null, name: translate('Auto Apply Configuration') })
      setConfigChoices(asChoices)
    }
  }, [configs])

  if (!isCashFlowVisible || !cashFlowActiveOnOrg) return null
  return (
    <PaymentsConfigurationDiv>
      <SelectInput
        source="payments_configuration"
        choices={configChoices}
        label="CashFlow Configuration Override"
      ></SelectInput>
      {isCash && (
        <BooleanInput
          resource={resource}
          label={translate('Disable CashFlow')}
          name={'disable_cashflow'}
          source={'disable_cashflow'}
        />
      )}
    </PaymentsConfigurationDiv>
  )
}
export default PaymentsConfigurationSelection
