import Typography from '@material-ui/core/Typography'
import { styled } from 'opensolar-ui'
import { DistributorDataTypeV2 } from '../../../../elements/hardwareSelectorV2/types'
import ProductDetailDescription from './ProductDetailDescription'

const FullWidthWrapper = styled('div')({
  width: '100%',
})

const SectionCardTop = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: 25,
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
  borderBottom: '1px solid ' + theme.palette.grey[130],
}))

const SectionCardBottom = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: 25,
  borderBottomLeftRadius: 8,
  borderBottomRightRadius: 8,
}))

const Actions = styled('div')({
  padding: '15px 15px 15px 0',
})

const isHTML = (str) => {
  const htmlRegex = /<[^>]*>/
  return htmlRegex.test(str)
}

const getDescription = (description: string | undefined) => {
  if (!description) {
    return 'No description available'
  }

  if (isHTML(description)) {
    return <div dangerouslySetInnerHTML={{ __html: description }} />
  }

  return <div>{description}</div>
}

const ProductDetailDescription3 = ({ selectedDistributor }: { selectedDistributor?: DistributorDataTypeV2 }) => {
  const displayDescription = getDescription(selectedDistributor?.description)

  // TODO: On the figma design Product details 3.0 have documents like warranties and specification dat.
  //  but the backend isn't ready to provide this request yet
  //  resource conversation https://www.figma.com/design/QLfSqfUsOarA4f7D23buhH?node-id=5374-202151#1023504751
  return (
    <FullWidthWrapper>
      <SectionCardTop>
        <Typography variant="h6">Product Description</Typography>
      </SectionCardTop>
      <SectionCardBottom>
        <div>{displayDescription != null && <ProductDetailDescription description={displayDescription} />}</div>
        {/*<Actions>{productDocuments && <ProductDetailDescriptionActions productDocuments={productDocuments} />}</Actions>*/}
      </SectionCardBottom>
    </FullWidthWrapper>
  )
}

export default ProductDetailDescription3
